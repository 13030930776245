import React, { useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import styles from '../styles';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import CheckNumberDialog from '../../../modals/checkNumberDialog/CheckNumberDialog';
import withUserCheck from '../../../utils/withUserCheck';

export function ActionButtons({
  simcard,
  classes,
  clientId,
  openEditSimcard,
  openShowHistory,
  isNonClientAdminUser
}) {
  const onEditClick = useCallback(
    () => {
      openEditSimcard({
        simcard,
        simcardId: simcard.id,
        clientId: simcard.client_id
      });
    },
    // eslint-disable-next-line
    [simcard, clientId]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: simcard.id,
        itemType: 'SimCard',
        historyObject: simcard,
        titleText: simcard.icc,
        showAllValuesCheckbox: true
      });
    },
    // eslint-disable-next-line
    [simcard.id]
  );

  return (
    <div className={classes.actionButtons}>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.SHOW_HISTORY"
        onClick={onHistoryClick}
        iconClassName="far fa-history"
      />
      {isNonClientAdminUser && (
        <CheckNumberDialog numbers={[simcard.msisdn]}>
          <CustomIconButton
            iconTooltipIntlKey="MENU.NUMBER_LOOKUP"
            iconClassName="far fa-info-square"
          />
        </CheckNumberDialog>
      )}
      {can('update', createSubject('SimCard', simcard)) && (
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.EDIT_SIMCARD"
          onClick={onEditClick}
          iconClassName="far fa-edit"
        />
      )}
    </div>
  );
}

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openShowHistory: showHistoryReduxActions.openShowHistory
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withUserCheck,
  withStyles(styles)
)(ActionButtons);
