import { createReduxActionTypes } from '../../../utils/actionFactory';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mapClient from '../actions/mapClient';

const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
const RESET_SELECT_CLIENT = 'RESET_SELECT_CLIENT';
const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
const TOOGLE_DIALOG = 'TOOGLE_DIALOG';
const SET_HISTORY = 'SET_HISTORY';

export const actionTypes = createReduxActionTypes('SELECT_CLIENT', [
  SET_SELECTED_CLIENT,
  RESET_SELECT_CLIENT,
  TOOGLE_DIALOG,
  SET_INITIAL_STATE,
  SET_HISTORY
]);

const DEFAULT_STATE = {
  selectedClient: { attributes: {} },
  selectClientsList: [],
  open: false,
  history: []
};

export const reducer = persistReducer(
  {
    storage,
    key: 'selectClient',
    whitelist: ['selectedClient', 'selectClientsList']
  },
  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case actionTypes[SET_INITIAL_STATE]: {
        const { selectClientsList } = action;

        return {
          ...state,
          selectClientsList: [...selectClientsList]
        };
      }
      case actionTypes[TOOGLE_DIALOG]: {
        return { ...state, open: !state.open };
      }
      case actionTypes[SET_SELECTED_CLIENT]: {
        const { selectedClient } = action;
        return { ...state, selectedClient: mapClient(selectedClient) };
      }
      case actionTypes[SET_HISTORY]: {
        const { history } = action;
        return { ...state, history };
      }
      case actionTypes[RESET_SELECT_CLIENT]: {
        return { ...DEFAULT_STATE };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  toggleDialog: () => ({ type: actionTypes[TOOGLE_DIALOG] }),
  setInitialState: selectClientsList => ({
    type: actionTypes[SET_INITIAL_STATE],
    selectClientsList
  }),
  resetSelectClient: () => ({
    type: actionTypes[RESET_SELECT_CLIENT]
  }),
  setSelectedClient: selectedClient => ({
    type: actionTypes[SET_SELECTED_CLIENT],
    selectedClient
  }),
  setHistory: history => ({
    type: actionTypes[SET_HISTORY],
    history
  })
};

export const getters = {
  getAllClientsList: state => state.selectClient.selectClientsList
};
