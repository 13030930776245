import React, { useMemo } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { withStyles } from '@material-ui/styles';
import { Table, TableBody } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/scheduleDetails';
import mapDataForDetalView from '../actions/mapDataForDetalView';
import SchedulesDetailHeader from './ScheduleDetailHeader';
import ScheduleDetailRow from './ScheduleDetailRow';
import { setSchedulesOrder } from '../actions/updateSchedulesOrder';
import mapSchedulesForUpdate from '../actions/mapScheduleForUpdate';

function ScheduleDetailView({
  classes,
  data,
  group,
  connectClientId,
  setSchedulesOrder
}) {
  const { sortableSchedules, defaultSchedule } = useMemo(() => {
    return mapDataForDetalView(data);
  }, [data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setSchedulesOrder({
        connectClientId,
        schedules: mapSchedulesForUpdate({
          tableSchedules: [
            ...defaultSchedule,
            ...arrayMoveImmutable(
              sortableSchedules,
              oldIndex,
              newIndex
            ).reverse()
          ]
        }),
        number: group.number
      });
    }
  };

  return (
    <Table className={classes.tableRoot}>
      <SchedulesDetailHeader group={group} />
      <SortableContainer
        onSortEnd={onSortEnd}
        useDragHandle
        lockAxis="y"
        lockToContainerEdges={true}
      >
        {sortableSchedules.map((schedule, index) => (
          <SortableItem
            key={`item-${schedule.name}`}
            index={index}
            data={schedule}
            group={group}
          />
        ))}
      </SortableContainer>
      {defaultSchedule.map(data => (
        <TableBody key={data.name}>
          <ScheduleDetailRow data={data} group={group} />
        </TableBody>
      ))}
    </Table>
  );
}
const mapStatesToProps = ({ selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  setSchedulesOrder
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ScheduleDetailView);

const SortableItem = sortableElement(({ data, group }) => {
  return <ScheduleDetailRow data={data} group={group} />;
});

const SortableContainer = sortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});
