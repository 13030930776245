import React from 'react';
import { withStyles } from '@material-ui/styles';

import DataBox from '../../../partials/dataBox/DataBox';
import { translate } from '../../../i18n/I18nProvider';
import styles from '../styles/datalimitInfo';

const DatalimitInfo = ({ datalimit = {}, classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <DataBox
          label={translate('CLIENT')}
          value={datalimit?.clientName}
          width={200}
        />
        <DataBox label={translate('NAME')} value={datalimit.name} width={200} />
        <DataBox
          label={translate('TABLE.NUMBER')}
          value={datalimit?.numbers}
          width={150}
        />
      </div>
      <div className={classes.row}>
        <DataBox
          label={`${translate('DATA_LIMITS.DATA_LIMIT')} GB`}
          value={datalimit?.block_limit_mb}
          width={120}
        />
        <DataBox
          label={`${translate('DATA_LIMITS.ROAMING_EU_LIMIT')} GB`}
          value={datalimit?.fair_usage_limit}
          width={120}
        />
        <DataBox
          label={`${translate('DATA_LIMITS.FUTURE_LIMIT')} GB`}
          value={datalimit?.future_limit}
          width={120}
        />
        <DataBox
          label={translate('DATA_LIMITS.FUTUR_LIMIT_AT')}
          value={datalimit?.future_limit_at}
          width={150}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(DatalimitInfo);
