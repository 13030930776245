import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_CONNECTIONS } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isArray, isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';

export function getConnections({ clientId }) {
  return {
    type: sagaActions[GET_CONNECTIONS],
    clientId
  };
}

export function* fetchConnections({ clientId }) {
  yield put(loaderReduxActions.startSpinLoader());

  let connections = [],
    accounts = [],
    selectOptions = [],
    selectedAccount = {};

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/accounts`
    );

    accounts = data;
    selectOptions = yield call(createSelectOptions, data);

    selectedAccount = Optional(selectOptions[0]).or({});
  } catch {}

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/connections`
    );

    if (isArray(data)) {
      connections = data
        .map(el => el.subscription)
        .map(el => {
          const account = accounts.find(
            account => account.id === el.parentAccountId
          );

          if (isUndefined(account)) {
            return el;
          }

          const accountName = `${Optional(account.name).or('')} - ${Optional(
            account.accountNo
          ).or('')}`;

          return { ...el, accountName };
        });
    }
  } catch {}

  yield put(reduxActions.setAccounts(accounts, selectOptions, selectedAccount));
  yield put(reduxActions.setConnections(connections));
  yield put(loaderReduxActions.stopSpinLoader());
}

function* watchGetConnections() {
  yield takeLatest(sagaActions[GET_CONNECTIONS], fetchConnections);
}

export default watchGetConnections();

function createSelectOptions(data = []) {
  const options = data.map(el => {
    const { id, name, accountNo } = el;
    return { value: id, label: `${name} - ${accountNo}`, ...el };
  });

  return [
    { value: 'ALL', label: translate('INVOICES.ALL_ACCOUNTS') },
    ...options
  ];
}
