import { JSONAPI_URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import { getData } from '../../../utils/http';

export default async function getClientAddresses({
  id,
  idAsValue,
  splitLines
}) {
  const QUERY_PARAMS = {
    paginate: false,
    filter: { client_id: { eq: id } }
  };
  try {
    const { data: addresses } = await getData(
      urlCreator(JSONAPI_URLS.ADDRESSES, QUERY_PARAMS)
    );

    let addressesOptions = mapAddresses(addresses, splitLines);

    if (idAsValue) {
      addressesOptions = addressesOptions.map(address => {
        const { id, label } = address;
        return { value: id, label };
      });
    }

    return addressesOptions;
  } catch {
    return [];
  }
}

export function mapAddresses(addresses, splitLines) {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const mappedAddresses = addresses.map(address => {
    const fullAddress = mapAddress(address, regionNames, splitLines);
    return {
      ...address,
      value: fullAddress,
      label: fullAddress
    };
  });

  return [{ value: '', label: '' }, ...mappedAddresses];
}

export function mapAddress(address, regionNames, splitLines) {
  if (isUndefined(address)) {
    return '';
  }

  const elArray = [
    'street',
    'house_number',
    'stairway',
    'floor',
    'sidedoor',
    'zip_code',
    'city',
    'country'
  ];

  return elArray.reduce((text, item) => {
    if (isDefined(address[item])) {
      const textToAdd = mapTextToAdd({
        item,
        address,
        regionNames,
        splitLines
      });
      if (isDefined(text)) {
        return `${text}${textToAdd}`;
      }
      return textToAdd;
    }
    return text;
  }, null);
}

function mapTextToAdd({ item, address, regionNames, splitLines }) {
  switch (item) {
    case 'house_number': {
      return `${address[item]}${
        isDefined(address.floor) || isDefined(address.sidedoor)
          ? ','
          : `,${splitLines ? '\n' : ''}`
      }`;
    }
    case 'stairway': {
      return `${address[item]},`;
    }
    case 'floor': {
      return `${address[item]}${
        isDefined(address.sidedoor) ? '.' : `.,${splitLines ? '\n' : ''}`
      }`;
    }
    case 'sidedoor': {
      return `${address[item]},${splitLines ? '\n' : ''}`;
    }
    case 'city': {
      return `${address[item]}${splitLines ? '\n' : ''}`;
    }
    case 'country': {
      return `${regionNames.of(address[item].toUpperCase())}`;
    }

    default: {
      return `${address[item]} `;
    }
  }
}
