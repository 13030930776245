export default function mapSimcardHistory(history) {
  return history.map(item => {
    const keysToHide = ['Imsi', 'Puk2', 'Pin2', 'Pin3'];
    
    const { diff = {} } = item;

    const diffKeys = Object.keys(diff);

    const mappedDiff = diffKeys.reduce((obj, key) => {
      const isElementHidden = keysToHide.find(el => el === key);

      return isElementHidden
        ? { ...obj, [key]: [...diff[key], true] }
        : { ...obj, [key]: [...diff[key]] };
    }, {});

    return { ...item, diff: { ...mappedDiff } };
  });
}
