import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isUndefined } from '../../../utils/isDefined';
import { createPromptsSelectOptions } from '../../editSchedule/actions/getAllPrompts';
import { actions as reduxActions } from '../reducers';

const getPrompts = ({ connectClientId }) => {
  return async dispatch => {
    if (isUndefined(connectClientId)) {
      dispatch(reduxActions.setPrompts([]));
    } else {
      let prompts = [];
      try {
        prompts = await getData(
          `${OUTSIDE_API.CLIENTS}/${connectClientId}/prompts`
        );
      } catch {
      } finally {
        dispatch(reduxActions.setPrompts(createPromptsSelectOptions(prompts)));
      }
    }
  };
};

export default getPrompts;
