import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { setRole } from '../../../modals/editRole/actions/setRole';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import DeleteRoleConfimation from './DeleteRoleConfimation';
import { removeRole } from '../actions/deleteRole';

export function TableActionButtons({
  data: role,
  classes,
  openEditRole,
  removeRole
}) {
  const onEditClick = useCallback(
    () => {
      openEditRole({ role });
    },
    // eslint-disable-next-line
    [role]
  );

  return (
    <div className={classes.actionButtons}>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.EDIT_ROLE"
        onClick={onEditClick}
        iconClassName="far fa-edit"
      />
      <ConfirmDialog
        onConfirmClick={() => removeRole(role.id)}
        dialogContentComponent={<DeleteRoleConfimation />}
        dialogTitleIntlId={'TOOLTIP.DELETE_ROLE'}
      >
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.DELETE_ROLE"
          iconClassName="far fa-trash-alt"
        />
      </ConfirmDialog>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientConnectId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = { openEditRole: setRole, removeRole };

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
