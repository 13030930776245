import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';

import DataRow from '../../../partials/Datarow/DataRow';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';
import { getLink } from '../actions/getLink';
import styles from '../styles/mitelSettingsRow';

function MitelSettingsRow({ classes, intl, user, settings }) {
  const onIconClick = useCallback(async () => {
    const response = await getLink();

    isDefined(response) && window.open(response, '_blank');
  }, []);

  const datarowCustomStyles = useMemo(() => {
    return {
      root: {
        color: settings.colors.primary
      },
      label: { width: '180px' }
    };
  }, [settings.colors]);

  const customClasses = useMemo(() => {
    return {
      root: classes.datarowRoot,
      value: classes.datarowValue
    };
  }, [classes]);

  return (
    <div className={classes.root}>
      <DataRow
        customClasses={customClasses}
        label={`${intl.formatMessage({
          id: 'USER.LINKED_TO_DOMAIN'
        })}`}
        value={`${user.connect30_user_id}@${Optional(user.client)
          .map(client => client.connect30_domain)
          .or('no_domain')}`}
        dense
        mobileFlexColumn
        customStyles={datarowCustomStyles}
      />
      {isDefined(user.connect30_user_id) && (
        <Tooltip
          title={intl.formatMessage({
            id: 'TOOLTIP.GO_TO_PROFILE_PAGE'
          })}
        >
          <i
            className={clsx('fas fa-external-link-alt', classes.icon)}
            style={{ color: settings.colors.links }}
            onClick={onIconClick}
          ></i>
        </Tooltip>
      )}
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(MitelSettingsRow);
