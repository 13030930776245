import RESPONSE_CODES from '../consts/responseCodes';
import { OUTSIDE_API } from '../consts/endpoints';
import { getData } from './http';
import { isString } from './isDefined';

export default async function fetchUserPhotoFromMitel({
  userId,
  connectDomain,
  domainUser = false
}) {
  try {
    const { data: photo } = await getData({
      options: {
        url: domainUser
          ? `${OUTSIDE_API.USERS}/${userId}/photo`
          : `${OUTSIDE_API.CLIENTS}/${connectDomain}/users/${userId}/photo`,
        responseType: 'blob'
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });
    
    if (isString(photo?.type) && (photo?.type).indexOf('image') === -1) {
      return { photo: null };
    }
    
    return { photo };
  } catch {
    return { photo: null };
  }
}
