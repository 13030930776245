import { createReduxActionTypes } from '../../../utils/actionFactory';

export const OPEN_DETAIL_VIEW = 'OPEN_DETAIL_VIEW';
const CLOSE_DETAIL_VIEW = 'CLOSE_DETAIL_VIEW';

export const actionTypes = createReduxActionTypes('DETAIL_VIEW', [
  OPEN_DETAIL_VIEW,
  CLOSE_DETAIL_VIEW
]);

const DEFAULT_STATE = {
  open: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DETAIL_VIEW]: {
      return {
        ...state,
        open: true,
        title: action.title,
        clientId: action.clientId,
        submitFunction: action.submitFunction,
        data: { ...action.data },
        fields: [...action.fields]
      };
    }
    case actionTypes[CLOSE_DETAIL_VIEW]: {
      return {
        ...DEFAULT_STATE
      };
    }
    default:
      return state;
  }
};

export const actions = {
  openDetailView: ({ title, fields, data, clientId, submitFunction }) => ({
    type: actionTypes[OPEN_DETAIL_VIEW],
    title,
    fields,
    data,
    clientId,
    submitFunction
  }),
  closeDetailView: () => ({
    type: actionTypes[CLOSE_DETAIL_VIEW]
  })
};
