import React, { useState, cloneElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import { translate } from '../../i18n/I18nProvider';
import EditClientForClientAdmin from '../../modals/editClientForClientAdmin/EditClientForClientAdmin';

const ClientMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <EditClientForClientAdmin closeMenu={handleClose}>
            <MenuItem>{translate('TOOLTIP.EDIT_CLIENT')}</MenuItem>
          </EditClientForClientAdmin>
        </div>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    client: selectClient.selectedClient
  };
};

export default flowRight(connect(mapStatesToProps))(ClientMenu);
