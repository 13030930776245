import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import Specification from './Specification';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';

const TableActionButtons = ({ data: order, classes }) => {
  return (
    <div className={classes.actionButtons}>
      <Specification specification={order?.specification}>
        <CustomIconButton
          iconTooltipIntlKey="ORDERS.SPECIFICATION"
          iconClassName="far fa-list"
        />
      </Specification>
    </div>
  );
};

export default withStyles(styles)(TableActionButtons);
