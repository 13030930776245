import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import withSettings from '../../../utils/withSettings';
import styles from '../styles';

function ScheduleStatusCell({ status, settings, classes }) {
  const statusValue = setIcon(status, settings);
  return (
    <div className={classes.statusRoot}>
      {statusValue.icon}
      <div className={classes.statusLabel}>{statusValue.label}</div>
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(ScheduleStatusCell);

function setIcon(status, settings) {
  switch (status) {
    case 'OPEN': {
      return {
        label: 'Open',
        icon: (
          <i
            className="fas fa-check-circle"
            style={{ color: settings.colors.success }}
          ></i>
        )
      };
    }
    case 'CLOSED': {
      return {
        label: 'Closed',
        icon: (
          <i
            className="fas fa-minus-circle"
            style={{ color: settings.colors.danger }}
          ></i>
        )
      };
    }
    default:
      return {
        label: `Forward (${status})`,
        icon: (
          <i
            className="fas fa-long-arrow-alt-right"
            style={{ color: settings.colors.success }}
          ></i>
        )
      };
  }
}
