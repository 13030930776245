import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as reduxActions } from './reducers';
import SchedulesTable from './components/SchedulesTable';
import EditSchedule from '../../modals/editSchedule/EditSchedule';
import AddingSchedule from '../../modals/addingSchedules/AddingSchedule';
import Summary from '../../modals/addingSchedules/components/Summary';

function Schedules({ resetSchedules }) {
  useEffect(() => {
    return resetSchedules;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SchedulesTable />
      <EditSchedule />
      <AddingSchedule />
      <Summary />
    </>
  );
}

const mapDispatchToProps = {
  resetSchedules: reduxActions.resetSchedules
};

export default connect(null, mapDispatchToProps)(Schedules);
