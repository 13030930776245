import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { isDefined } from '../../utils/isDefined';
import styles from './styles';
import ShowMoreDataDialog from './components/ShowMoreDataDialog';
import CopyToClipbaordTooltip from '../copyToClipbaordTooltip/CopyToClipbaordTooltip';
import { translate } from '../../i18n/I18nProvider';

function CellNumbersCell({
  data,
  classes,
  mapNumbers,
  showMore = false,
  length = 1
}) {
  const numbers = isDefined(mapNumbers) ? mapNumbers(data) : data;

  const numbersToShow = showMore ? numbers.slice(0, length) : numbers;

  return (
    <div className={classes.root}>
      {isDefined(numbers) &&
        numbersToShow.map((number, i) => (
          <CopyToClipbaordTooltip value={number} key={i} isPhoneNumber={true}>
            {number}
          </CopyToClipbaordTooltip>
        ))}
      {numbers.length > length && showMore && (
        <ShowMoreDataDialog data={data}>
          <Tooltip placement="bottom" title={translate('SHOW_MORE')}>
            <i className={clsx('fal fa-ellipsis-h', classes.icon)}></i>
          </Tooltip>
        </ShowMoreDataDialog>
      )}
    </div>
  );
}

export default withStyles(styles)(CellNumbersCell);
