const styles = theme => {
  return {
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    titleText: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#595D6E',
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
        fontSize: '14px',
        padding: 0
      }
    },
    actions: {
      display: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
      '& i': {
        fontSize: '16px',
        color: '#8589A1'
      }
    }
  };
};

export default styles;
