export default {
  tableRoot: {
    backgroundColor: '#FAFAFA'
  },
  tableDragColumn: {
    width: '30px',
    fontSize: '18px'
  },
  tableActions: { width: '80px', fontSize: '18px' },
  voicePrompt: { width: '330px' },
  crossLine: {
    width: 'calc(100% + 40px)',
    height: '1px',
    background: 'red',
    marginTop: '-10px',
    marginLeft: '-20px'
  },
  cellRoot: {
    height: '18px'
  }
};
