import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles/optionLabel';
import withSettings from '../../../utils/withSettings';
import { toAbsoluteUrl } from '../../../../_metronic';
import { isDefined } from '../../../utils/isDefined';

function ShortcutLabel({ name, classes, size }) {
  return (
    <div className={classes.root}>
      <img
        src={toAbsoluteUrl(`/media/shortcuts/${name}.png`)}
        alt="icon"
        className={classes.icon}
        style={{ width: isDefined(size) ? `${size}px` : '15px' }}
      ></img>
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(ShortcutLabel);
