import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { doesArrayHasLength } from '../../../utils/isDefined';

const fetchClientsList = async () => {
  let allClients = [];

  try {
    let { data } = await getData({
      options: {
        url: urlCreator(URLS.CLIENT_LIST, {})
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.FORBIDDEN]
    });
    const { data: clients } = data;
    allClients = doesArrayHasLength(clients)
      ? mapClientsForSelect(clients)
      : [];
  } catch (e) {
  } finally {
    return allClients;
  }
};

export default fetchClientsList;

const mapClientsForSelect = clients => {
  const mappedclients = clients.map(client => {
    return { value: client.id, label: client.name };
  });

  return [{ value: 'empty', label: '' }, ...mappedclients];
};
