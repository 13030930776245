const styles = () => {
  return {
    root: {
      marginBottom: '5px'
    },
    content: { maxWidth: '500px' },
    summaryTextContainer: {
      margin: '10px 0'
    },
    numberContainer: {
      display: 'flex',
      margin: '5px',
      borderBottom: '1px solid #EEEFF5',
      paddingBottom: '5px',
      flexDirection: 'column'
    },
    row: { display: 'flex' },
    label: {
      marginRight: '10px',
      width: '100px'
    },
    error: {
      fontSize: '10px'
    }
  };
};

export default styles;
