import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_ADDRESS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import { getAllAddresses } from './getAddresses';
import showSnackbar from '../../../utils/showSnackbar';

export function removeAddress(id, clientId) {
  return {
    type: sagaActions[DELETE_ADDRESS],
    id,
    clientId
  };
}

export function* deleteAddress({ id, clientId }) {
  yield put(loaderReducActions.startSpinLoader());
  
  const response = yield call(deleteData, {
    url: `${URLS.ADDRESSES}/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.DELETE.ADDRESS',
      messageType: 'success'
    });

    yield put(getAllAddresses(clientId));
  }
}

function* watchDeleteAddress() {
  yield takeLatest(sagaActions[DELETE_ADDRESS], deleteAddress);
}

export default watchDeleteAddress();
