import { isArray } from '../../../utils/isDefined';

export default function mapRolesSelectOptions(roles) {
  if (!isArray(roles)) {
    return [];
  }
  return roles.map(role => {
    return {
      value: role.id,
      label: role.name
    };
  });
}
