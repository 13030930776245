import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isStringDefined } from '../../../utils/isDefined';

export default function({ isEdit }) {
  return isEdit
    ? yup.object().shape({
        och_inactive_at: yup
          .string()
          .when(['future_status'], {
            is: future_status => isStringDefined(future_status),
            then: yup.string().required(translate('VALIDATION.INACTIVE_DATE'))
          })
          .nullable()
      })
    : yup.object().shape({
        och_inactive_at: yup
          .string()
          .when(['future_status'], {
            is: future_status => isStringDefined(future_status),
            then: yup.string().required(translate('VALIDATION.INACTIVE_DATE'))
          })
          .nullable(),
        range_from: yup
          .string()
          .matches(/^[0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
          .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
        range_up_to_incl: yup
          .string()
          .matches(/^[0-9]+$/, translate('VALIDATION.ONLY_DIGITS')),
        number_type: yup
          .string()
          .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      });
}
