import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import tableColumns from './tableColumns';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { fetchSubscriptions } from '../actions/getSubscriptions';
import GroupedTable from './GroupedTable';
import Optional from '../../../utils/optional';
import { filterSubs, mapSubscriptions } from '../actions/tableActions';
import { actions as reduxActions } from '../reducers';
import { translate } from '../../../i18n/I18nProvider';
import { CSV_HEADERS } from '../../../consts/csvHeaders';
import { ExportCsv } from '@material-table/exporters';
import { mapDataforExport } from '../actions/exportActions';
import SubscriptionsTableHeader from './SubscriptionsTableHeader';
import getValueFromLocation from '../../../utils/getValueFromLocation';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

const SubscriptionsTable = ({
  intl,
  isLoading,
  selectedAccount,
  fetchSubscriptions,
  clientId,
  lang,
  expandAll,
  subscriptions,
  groupByCategory,
  filterBy,
  updateValue,
  selectOptions,
  setSelectedAccount,
  location
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [subs, setSubs] = useState(subscriptions);
  const [refresh, setRefresh] = useState(false);

  const tableRef = useRef();
  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ lang, groupByCategory });
  }, [lang, groupByCategory]);

  useEffect(() => {
    const account = getValueFromLocation(location, 'accountId');

    if (isDefined(account) && doesArrayHasLength(selectOptions)) {
      const preselectedAccount = selectOptions.find(
        option => option.value === account
      );

      if (isDefined(preselectedAccount)) {
        setSelectedAccount(preselectedAccount);
      }
    }
    // eslint-disable-next-line
  }, [location, selectOptions]);

  useEffect(() => {
    if (Object.keys(selectedAccount).length > 0) {
      fetchSubscriptions({
        id: selectedAccount.id,
        clientId,
        expandAll
      });
    }
    // eslint-disable-next-line
  }, [selectedAccount, clientId]);

  useEffect(() => {
    if (refresh && Object.keys(selectedAccount).length > 0) {
      fetchSubscriptions({
        id: selectedAccount.id,
        clientId,
        expandAll
      });
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    updateValue('filterBy', 'ACTIVE');
    return setSubs(
      mapSubscriptions({
        tableRef,
        expandAll,
        subscriptions
      })
    );
    // eslint-disable-next-line
  }, [subscriptions]);

  useEffect(() => {
    if (filterBy === 'ALL') {
      return setSubs(
        mapSubscriptions({
          tableRef,
          expandAll,
          subscriptions
        })
      );
    }

    const filteredSubs = filterSubs({ filterBy, subscriptions });

    return setSubs(
      mapSubscriptions({
        tableRef,
        expandAll,
        subscriptions: filteredSubs
      })
    );
    // eslint-disable-next-line
  }, [filterBy, expandAll, subscriptions]);

  const actions = useMemo(
    () => [
      {
        icon: () => <RefreshButton tableHeader={true} />,
        onClick: () => setRefresh(true),
        isFreeAction: true
      }
    ],
    // eslint-disable-next-line
    []
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: true }),
      selection: false,
      columnsButton: false,
      padding: 'default',
      exportMenu: [
        {
          label: `${translate('TABLE.EXPORT_AS')} CSV`,
          exportFunc: () => {
            const data = groupByCategory
              ? mapDataforExport({
                  data: subs.groupedByCategory,
                  lang,
                  selectedAccount,
                  selectOptions
                })
              : mapDataforExport({
                  data: subs.groupedBySku,
                  lang,
                  selectedAccount,
                  selectOptions
                });

            let headers = CSV_HEADERS.SUBSCRIPTIONS;

            if (selectedAccount?.value === 'ALL') {
              headers = [
                {
                  title: translate('ACCOUNT'),
                  field: 'account'
                },
                ...headers
              ];
            }

            return ExportCsv(
              headers,
              data,
              `${translate('MENU.SUBSCRIPTIONS')} - ${selectedAccount?.label}`,
              ';'
            );
          }
        }
      ]
    };
  }, [subs, groupByCategory, lang, selectedAccount, selectOptions]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const detailPanel = [
    rowData => {
      return {
        render: () => (
          <GroupedTable searchTerm={searchTerm} grouped={rowData.subs} />
        )
      };
    }
  ];

  return (
    <MaterialTable
      title={<SubscriptionsTableHeader />}
      icons={customTableIcons}
      columns={tableDefaultColumns}
      data={groupByCategory ? subs.groupedByCategory : subs.groupedBySku}
      options={options}
      detailPanel={detailPanel}
      localization={localization}
      isLoading={isLoading}
      tableRef={tableRef}
      actions={actions}
      onSearchChange={() => {
        setSearchTerm(tableRef.current.dataManager.searchText);
      }}
    />
  );
};

const mapStatesToProps = ({ subscriptions, loader, selectClient, auth }) => {
  return {
    ...subscriptions,
    lang: Optional(auth.lang).or('default'),
    isLoading: loader.isSpinVisible,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  fetchSubscriptions,
  updateValue: reduxActions.updateValue,
  setSelectedAccount: reduxActions.setSelectedAccount
};

export default flowRight(
  withRouter,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(SubscriptionsTable);
