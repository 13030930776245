export default {
  presenceRow: {
    display: 'flex',
    alignItems: 'center'
  },

  presence: {
    width: '130px',
    fontWeight: 500,
    fontSize: '12px'
  }
};
