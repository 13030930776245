import React, { useEffect } from 'react';
import { Dialog, DialogContent, withStyles } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import styles from './styles';
import { actions as reduxActions } from './reducers';
import { isDefined } from '../../utils/isDefined';
import fetchLogs from './actions/fetchLogs';
import LogsTable from './components/LogsTable';

const DatalimitLogs = ({
  classes,
  open,
  closeDatalimitLogs,
  datalimitId,
  fetchLogs
}) => {
  useEffect(() => {
    if (open === true && isDefined(datalimitId)) {
      fetchLogs({ datalimitId });
    }
    // eslint-disable-next-line
  }, [datalimitId, open]);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDatalimitLogs();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeDatalimitLogs}>
        Datalimit action logs
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <LogsTable />
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ datalimitLogs }) => {
  return {
    ...datalimitLogs
  };
};

const mapDispatchToProps = {
  closeDatalimitLogs: reduxActions.closeDatalimitLogs,
  fetchLogs
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DatalimitLogs);
