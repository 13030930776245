import Optional from './optional';
import orderBy from 'lodash.orderby';

export default function findLowestIdRole(roles = []) {
  return Optional(roles)
    .map(roles => {
      const mappedRoles = roles.map(role => {
        return { ...role, id: Number(role.id) };
      });
      return orderBy(mappedRoles, ['id'], ['desc'])[0];
    })
    .or({});
}
