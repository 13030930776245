import React from 'react';
import IconLabel from '../components/IconLabel';

export function iconSelectsOptions() {
  const iconsNames = [
    'ic_shortcut_big_green',
    'ic_shortcut_big_red',
    'ic_shortcut_car',
    'ic_shortcut_coffee',
    'ic_shortcut_home',
    'ic_shortcut_office',
    'ic_shortcut_pizza',
    'ic_shortcut_plane',
    'ic_shortcut_watch'
  ];

  return iconsNames.map(icon => {
    return { value: icon, label: <IconLabel name={icon} size={20} /> };
  });
}
