const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0 5px 30px'
    },
    label: {
      width: '120px',
      marginRight: '20px'
    },
    timeIcon: { fontSize: '18px' },
    errorMessage: {
      fontSize: '8px',
      color: '#FD397A'
    },
  };
};

export default styles
