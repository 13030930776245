import Select from 'react-select';
import flowRight from 'lodash.flowright';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import getTemplates from './actions/getTemplates';
import CustomForm from '../../partials/customForm/CustomForm';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import styles, { customSelectStyles } from './styles';
import withSettings from '../../utils/withSettings';
import withUserCheck from '../../utils/withUserCheck';
import { isDefined } from '../../utils/isDefined';
import { translate } from '../../i18n/I18nProvider';

function CustomSettings({
  intl,
  classes,
  clientId,
  settings,
  isNonClientAdminUser,
  hasClientConnectId
}) {
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    (async function() {
      if (hasClientConnectId) {
        await getTemplates({
          setTemplate,
          setTemplates,
          setInfo,
          clientId,
          isNonClientAdminUser
        });
      }
    })();
  }, [intl, clientId, isNonClientAdminUser, hasClientConnectId]);

  const handleChange = selected => {
    setTemplate(selected);
  };

  return hasClientConnectId ? (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      {info && <div className={classes.info}>{info}</div>}
      {template && (
        <div className={classes.selectContainer}>
          <div>
            {intl.formatMessage({
              id: 'CLIENT.SELECT_TEMPLATE'
            })}
          </div>
          <Select
            customStyles={customSelectStyles}
            options={templates}
            styles={reactSelectDefaultStyles}
            value={template}
            onChange={handleChange}
          />
        </div>
      )}
      {template && (
        <CustomForm
          fromDialog={false}
          templateName={template.value}
          clientId={clientId}
        />
      )}
    </div>
  ) : (
    <div className={classes.noDomainRoot} style={{ color: settings.colors.primary }}>
      <div>{translate('ONLY_TELEPO_CLIENTS')}</div>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id,
    hasClientConnectId: isDefined(selectClient.selectedClient?.connect30_domain)
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(CustomSettings);
