import * as utils from '../../_metronic/utils/utils';
import { isDefined, isStringEmpty, isUndefined } from '../utils/isDefined';

const localStorageLastLocationKey = 'lastLocation';

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== '/' &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname !== '/logout' &&
    lastLocation.pathname !== '/logged' &&
    lastLocation.pathname !== '/teams_login' &&
    lastLocation.pathname.indexOf('userSettings') === -1
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation, user) {
  const userLocationKey = isDefined(user)
    ? `${localStorageLastLocationKey}_${user}`
    : localStorageLastLocationKey;

  const localStorageLocations = utils.getStorage(userLocationKey);

  let _useLocations = isDefined(userLocationKey)
    ? JSON.parse(localStorageLocations)
    : '';

  if (acceptLocation(lastLocation)) {
    const locationArray = lastLocation.pathname.split('/');
    const lastEl = locationArray.pop();
    if (lastEl !== 'user') {
      locationArray.push(lastEl);
    }

    _useLocations = locationArray.join('/');

    utils.setStorage(userLocationKey, JSON.stringify(_useLocations), 120);
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation(user, clientId) {
  const userLocationKey = isDefined(user)
    ? `${localStorageLastLocationKey}_${user}`
    : localStorageLastLocationKey;

  const localStorageLocations = utils.getStorage(userLocationKey);

  if (
    isUndefined(localStorageLocations) ||
    isStringEmpty(localStorageLocations)
  ) {
    return `/${clientId}`;
  }

  const _userLocations = JSON.parse(localStorageLocations);
  const result = _userLocations.length > 0 ? _userLocations : '/';
  return result;
}
