import React from 'react';
import { Radio } from '@material-ui/core';

import withSettings from '../../../utils/withSettings';

function CustomRadioButton({ settings, dispatch, ...props }) {
  return (
    <Radio
      icon={
        <i
          className="far fa-square"
          style={{ color: settings.colors.primary }}
        ></i>
      }
      checkedIcon={
        <i
          className="far fa-check-square"
          style={{ color: settings.colors.primary }}
        ></i>
      }
      {...props}
    />
  );
}

export default withSettings(CustomRadioButton);
