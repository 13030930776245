import XMLParser from 'react-xml-parser';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';

export default function agentEventListener({
  mitelToken,
  baseUrl,
  clientDomain,
  userId
}) {
  const sse = new EventSource(
    `${baseUrl}/api/contacts/v1/info/v1/${clientDomain}/${userId}/presence?t=${mitelToken}`
  );

  sse.onmessage = e =>
    store.dispatch(reduxActions.setUpdatedQueues(getRealtimeData(e.data)));
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
  return () => {
    sse.close();
  };
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);

  const updatedActivityId = Optional(
    xml.getElementsByTagName('telepo:activity')[0]
  )
    .map(data => data.value)
    .or(void 0);

  const updatedActivityAttributes = Optional(
    xml.getElementsByTagName('rpid:activities')[0]
  )
    .map(activities => activities.attributes)
    .or(void 0);

  const entity = Optional(xml?.attributes?.entity)
    .map(data => data.split(':')[1])
    .or(void 0);

  const queues = store.getState().queues.queues;
  const activities = store.getState().queues.activities;

  const updatedActivity = activities.find(el => el.id === updatedActivityId);

  const available = Optional(updatedActivityAttributes?.available)
    .map(available => (available === 'true' ? true : false))
    .or(updatedActivity?.available);

  const mappedQueues = queues.map(queue => {
    const { agents = [] } = queue;

    const mappedAgents = agents.map(agent => {
      if (agent.id !== entity) {
        return agent;
      }

      return {
        ...agent,
        activity: Optional(updatedActivity?.name).or(''),
        isAvailable: Optional(available).or(false)
      };
    });

    return { ...queue, agents: mappedAgents };
  });

  const queuesGroupedByUser = store.getState().queues.queuesGroupedByUser;

  const updatedMappedQueues = queuesGroupedByUser.map(el => {
    if (el.connectId)
      if (el.connectId !== entity) {
        return el;
      }

    return {
      ...el,
      activity: Optional(updatedActivity?.name).or(''),
      isAvailable: Optional(available).or(false)
    };
  });

  return { queues: mappedQueues, queuesGroupedByUser: updatedMappedQueues };
}
