export default {
  dialogTitle: {
    backgroundColor: '#FFFFFF',
    height: '50px',
    color: '#5D78FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogContent: {
    overflow: 'visible',
    width: '450px'
  },
  scrollPaper: {
    alignItems: 'start'
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldLabel: {
    width: '180px',
    fontWeight: 500
  },
  field: {
    width: '220px'
  },
  smallField: {
    width: '60px',
    marginRight: '10px'
  },
  cityField: {
    width: '150px'
  },
  footer: {
    marginTop: '20px',
    width: '100%',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-around'
  },
  saveButton: {
    color: '#fff',
    backgroundColor: '#5D78FF',
    '&:hover': { backgroundColor: '#0ABB87' }
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    width: '220px',
    marginTop: '10px',
    borderRadius: '4px',
    border: '1px solid #EEF1FF'
  },
  inputField: { color: '#000' }
};

export const customStyles = {
  checkbox: {
    root: { alignItems: 'flex-start' },
    label: {
      width: '150px'
    }
  },
  inputRow: {
    field: { width: '380px' },
    label: {
      width: '160px'
    }
  }
};
