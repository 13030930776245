import countries from 'i18n-iso-countries';
import orderBy from 'lodash.orderby';
import { isObject } from '../../../utils/isDefined';

const createCountryOptions = ({ lang, setCountriesOptions }) => {
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/da.json'));

  const translatedCountries = countries.getNames(lang);

  if (isObject(translatedCountries)) {
    const countriesKeys = Object.keys(translatedCountries);

    return setCountriesOptions([
      { value: '', label: '' },
      ...orderBy(
        countriesKeys.map(key => {
          return { value: key, label: translatedCountries[key] };
        }),
        'label'
      )
    ]);
  }

  return [];
};

export default createCountryOptions;
