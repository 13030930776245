import { withStyles } from '@material-ui/core';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import styles from '../styles/lastNumbers';
import { doesArrayHasLength } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

const LastNumbers = ({
  history,
  classes,
  onSubmit,
  fromRightPanel,
  historyPanel
}) => {
  return (
    <div className={classes.root}>
      {(doesArrayHasLength(history) || doesArrayHasLength(historyPanel)) && (
        <div className={classes.label}>{`${translate('LAST_SEARCHES')} : `}</div>
      )}
      <div className={classes.container}>
        {fromRightPanel
          ? historyPanel.map(el => (
              <div
                key={el}
                className={classes.element}
                onClick={() => onSubmit({ number: el }, true)}
              >
                {el}
              </div>
            ))
          : history.map(el => (
              <div
                key={el}
                className={classes.element}
                onClick={() => onSubmit({ number: el }, true)}
              >
                {el}
              </div>
            ))}
      </div>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    history: checkNumber.history,
    historyPanel: rightPanel.history
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(LastNumbers);
