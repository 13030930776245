import { jsPDF } from 'jspdf';
import { translate } from '../../i18n/I18nProvider';


const createPdf = ({ number, src }) => {
  const doc = new jsPDF();
  const img = new Image();
  img.src = src;

  doc.text(`${translate('SWAP_SIMCARD.ESIM_SUMMARY_2')} ${number}`, 10, 50);
  doc.text(translate('SWAP_SIMCARD.ESIM_SUMMARY_4'), 10, 70);
  doc.text(translate('SWAP_SIMCARD.ESIM_SUMMARY_5'), 10, 80);
  doc.text(translate('SWAP_SIMCARD.ESIM_SUMMARY_7'), 10, 100);
  doc.addImage(img, 'png', 80, 120, 45, 45);
  doc.text(translate('SWAP_SIMCARD.ESIM_5'),10,200,{maxWidth:180})
  doc.save(`QR code for number ${number}`);
};

export default createPdf;
