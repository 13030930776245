import React from 'react';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import StatusCell from '../../../partials/statusCell/StatusCell';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';

export default function tableColumns() {
  const columns = [
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'GROUPS.ACD_SUPERVISOR',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.acdSupervisor
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'acdSupervisor'
    },
    {
      title: 'GROUPS.BARRING',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.barring
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'barring'
    },
    {
      title: 'GROUPS.FEDERATED',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.federated
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'federated'
    },
    {
      title: 'GROUPS.MODERATOR',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.moderator
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'moderator'
    },
    {
      title: 'GROUPS.REPORT_ADMIN',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.reportAdmin
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'reportAdmin'
    },
    {
      title: 'GROUPS.SCHEDULES_ADMIN',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.schedulesAdmin
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'schedulesAdmin'
    },
    {
      title: 'LOCKED',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.locked
          )}
          alignLeft={true}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      field: 'locked'
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}
