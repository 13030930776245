import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Formik, Form, Field } from 'formik';

import styles, { customStyles } from './styles';
import DawaAutocomplete from './components/DawaAutocomplete';
import CustomTextField from './components/CustomTextField';
import Optional from '../../utils/optional';
import addNewAddress from './actions/addNewAddress';
import PrimaryButton from '../customButtons/PrimaryButton';
import CustomDialogTitle from '../customDialogTitle/CustomDialogTitle';
import SecondaryButton from '../customButtons/SecondaryButton';
import CheckboxRow from '../dataRows/CheckboxRow';
import DraggableDialog from '../DraggableDialog';
import withUserCheck from '../../utils/withUserCheck';
import { isDefined, isFunction } from '../../utils/isDefined';
import createCountryOptions from './actions/createCountryOptions';
import SelectRow from '../dataRows/SelectRow';
import { translate } from '../../i18n/I18nProvider';

const EMPTY_ADDRESS = {
  country: '',
  vejnavn: '',
  husnr: '',
  indgang: '',
  etage: '',
  dør: '',
  supplerendebynavn: '',
  postnr: '',
  postnrnavn: '',
  isDanishAddress: true
};

const CreateAddress = ({
  classes,
  clientId,
  isClientUser,
  customAction,
  children,
  lang
}) => {
  const [open, setOpen] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState([]);

  useEffect(() => {
    if (open) {
      createCountryOptions({ lang, setCountriesOptions });
    }
  }, [lang, open, setCountriesOptions]);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    async values => {
      const addressId = await addNewAddress({
        address: values,
        clientId,
        isClientUser
      });

      if (isFunction(customAction) && isDefined(addressId)) {
        customAction(addressId);
      }

      return onCloseDialog();
      // eslint-disable-next-line
    },
    [customAction, clientId, isClientUser]
  );

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        classes={{
          paper: classes.dialogContent,
          scrollPaper: classes.scrollPaper
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('ADDRESSES.ADD_ADDRESS')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={{ ...EMPTY_ADDRESS }}
            enableReinitialize
            onSubmit={values => {
              onSubmit(values);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setValues,
              resetForm,
              setFieldValue
            }) => {
              const fieldProps = {
                values,
                handleChange,
                handleBlur,
                handleSubmit
              };

              return (
                <Form className={classes.content} onSubmit={handleSubmit}>
                  <CheckboxRow
                    label="ADDRESS.IS_DANISH"
                    name="isDanishAddress"
                    customStyles={customStyles.checkbox}
                    {...fieldProps}
                  />
                  {values.isDanishAddress ? (
                    <DawaAutocomplete
                      isDanishAddress={values.isDanishAddress}
                      setValues={setValues}
                      resetForm={resetForm}
                    />
                  ) : (
                    <div className={classes.fieldContainer}>
                      <SelectRow
                        isEditModal={false}
                        label="TABLE.COUNTRY"
                        name="country"
                        options={countriesOptions}
                        disabled={values.isDanishAddress}
                        setFieldValue={setFieldValue}
                        customStyles={customStyles.inputRow}
                        {...fieldProps}
                      />
                    </div>
                  )}
                  <div className={classes.fieldContainer}>
                    <div className={classes.fieldLabel}>
                      {translate('TABLE.STREET')}
                    </div>
                    <Field
                      customClasses={classes.field}
                      readOnly={values.isDanishAddress}
                      name="vejnavn"
                      component={CustomTextField}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.fieldLabel}>
                      {`${translate('ADDRESS.HOUSE_NO')} /
                      ${translate('ADDRESS.ENTRANCE')}`}
                    </div>
                    <Field
                      customClasses={classes.smallField}
                      readOnly={values.isDanishAddress}
                      name="husnr"
                      component={CustomTextField}
                    />
                    <Field
                      customClasses={classes.smallField}
                      readOnly={values.isDanishAddress}
                      name="indgang"
                      component={CustomTextField}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.fieldLabel}>
                      {`${translate('ADDRESS.FLOOR')} /
                      ${translate('ADDRESS.APARTMENT')}`}
                    </div>
                    <Field
                      customClasses={classes.smallField}
                      readOnly={values.isDanishAddress}
                      name="etage"
                      component={CustomTextField}
                    />
                    <Field
                      customClasses={classes.smallField}
                      readOnly={values.isDanishAddress}
                      name="dør"
                      component={CustomTextField}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.fieldLabel}>
                      {`${translate('ADDRESS.DISTRICT')}`}
                    </div>
                    <Field
                      customClasses={classes.field}
                      readOnly={values.isDanishAddress}
                      name="supplerendebynavn"
                      component={CustomTextField}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.fieldLabel}>
                      {`${translate('ADDRESS.POSTAL_CODE')} /
                      ${translate('ADDRESS.TOWN')}`}
                    </div>
                    <Field
                      customClasses={classes.smallField}
                      readOnly={values.isDanishAddress}
                      name="postnr"
                      component={CustomTextField}
                    />
                    <Field
                      customClasses={classes.cityField}
                      readOnly={values.isDanishAddress}
                      name="postnrnavn"
                      component={CustomTextField}
                    />
                  </div>
                  <footer className={classes.footer}>
                    <PrimaryButton
                      onClick={handleSubmit}
                      disabled={!values.vejnavn}
                    >
                      {translate('BUTTON.SAVE')}
                    </PrimaryButton>
                    <SecondaryButton onClick={onCloseDialog}>
                      {translate('BUTTON.CANCEL')}
                    </SecondaryButton>
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ auth, selectClient }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id)
      .or(auth.defaultClient?.id),
    lang: auth.lang
  };
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(CreateAddress);
