import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_INVOICE_DETAILS = 'OPEN_INVOICE_DETAILS';
const RESET_INVOICE_DETAILS = 'RESET_INVOICE_DETAILS';
const RESET_DETAILS = 'RESET_DETAILS';

export const actionTypes = createReduxActionTypes('INVOICE_DETAILS', [
  OPEN_INVOICE_DETAILS,
  RESET_INVOICE_DETAILS,
  RESET_DETAILS
]);

const DEFAULT_STATE = {
  open: false,
  details: null,
  invoiceNumber: null,
  account: ''
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_INVOICE_DETAILS]: {
      const { details, invoiceNumber, account, invoiceAccount } = action;
      return {
        ...state,
        details,
        invoiceNumber,
        account,
        invoiceAccount,
        open: true
      };
    }
    case actionTypes[RESET_DETAILS]: {
      return {
        ...state,
        details: []
      };
    }
    case actionTypes[RESET_INVOICE_DETAILS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openInvoiceDetails: (details, invoiceNumber, account, invoiceAccount) => ({
    type: actionTypes[OPEN_INVOICE_DETAILS],
    details,
    invoiceNumber,
    account,
    invoiceAccount
  }),
  resetDetails: () => ({ type: actionTypes[RESET_DETAILS] }),
  closeInvoiceDetails: () => ({ type: actionTypes[RESET_INVOICE_DETAILS] })
};
