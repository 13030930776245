import React, { useCallback } from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/colorRow';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';
import ColorPicker from './ColorPicker';

function ColorRow({
  classes,
  intl,
  name,
  values,
  handleBlur,
  setFieldValue,
  handleChange,
  defaultSettings
}) {
  const handlePickerChange = useCallback(
    color => {
      setFieldValue(name, color);
    },
    // eslint-disable-next-line
    []
  );

  const handleColorReset = useCallback(
    () => {
      setFieldValue(name, Optional(defaultSettings.colors[name]).or(''));
    },
    // eslint-disable-next-line
    [setFieldValue, name]
  );

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <div className={classes.label}>{name.replaceAll('_', ' ')}</div>
        <ColorPicker
          color={values[name]}
          handlePickerChange={handlePickerChange}
        >
          <Tooltip
            placement="bottom"
            title={intl.formatMessage({
              id: 'TOOLTIP.CLICK_TO_PICK_COLOR'
            })}
          >
            <div
              className={classes.colorBox}
              style={{ backgroundColor: values[name] }}
            ></div>
          </Tooltip>
        </ColorPicker>
      </div>
      <div className={classes.secondRow}>
        <div>
          <TextField
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={handleChange}
            value={Optional(values[name]).or('')}
            name={name}
            inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
          />
        </div>
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({
            id: `TOOLTIP.COLORS_${name.toUpperCase()}`
          })}
        >
          <i className={clsx(classes.resetIcon, 'fas fa-info-circle')}></i>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({
            id: 'TOOLTIP.RESET_TO_DEFAULT'
          })}
        >
          <i
            className={clsx(classes.resetIcon, 'far fa-undo')}
            onClick={handleColorReset}
          ></i>
        </Tooltip>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(ColorRow);
