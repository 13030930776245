import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import store from '../../../store/store';
import { getUserPhoto } from '../../../partials/userAvatar/actions/getPhoto';
import fetchUserPhotoFromMitel from '../../../utils/fetchUserPhotoFromMitel';
import { actions as userReduxActions } from '../../../pages/user/reducers';

export default async function updatePhoto({
  selectedFile,
  clientDomain,
  baseUrl,
  mitelToken,
  userConnectId,
  resetChangePhoto
}) {
  resetChangePhoto();
  const response = await updateData({
    url: `${baseUrl}/api/user/info/${clientDomain}/${userConnectId}/photo?t=${mitelToken}`,
    data: selectedFile,
    config: { headers: { 'Content-Type': 'image/jpeg' } },
    successSnackbar: { intlTextKey: 'MESSAGE.PHOTO_CHANGED' }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    const loggedUserId = store.getState().auth.user.connect30_user_id;

    const { photo } = await fetchUserPhotoFromMitel({
      userId: userConnectId,
      connectDomain: clientDomain,
      domainUser: true
    });

    store.dispatch(userReduxActions.setUserPhoto(photo));

    if (userConnectId === loggedUserId) {
      await store.dispatch(
        getUserPhoto({ userId: userConnectId, domainUser: true })
      );
    }
  }
}
