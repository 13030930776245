import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import FrontendSettings from './components/FrontendSettings';
import ActionsButtons from './components/ActionsButtons';
import OtherSettings from './components/OtherSettings';
import { updateOnSubmit } from './actions/updateSettings';
import { fetchSettings } from './actions/getSettings';
import { withRouter } from 'react-router-dom';
import getValueFromLocation from '../../utils/getValueFromLocation';
import changeObjectValues from '../../utils/changeObjectValues';
import MsSettings from './components/MsSettings';
import { isUndefined } from '../../utils/isDefined';

export function AppSettings({
  classes,
  settings,
  updateOnSubmit,
  resetSettings,
  fetchSettings,
  location,
  partnerId,
  userPartnerId
}) {
  useEffect(() => {
    let id = getValueFromLocation(location, 'id');
    if (isUndefined(id)) {
      id = userPartnerId;
    }
    fetchSettings(id);
    return resetSettings;
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={changeObjectValues(settings, null, '')}
      onSubmit={values => updateOnSubmit(values, partnerId)}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {({ handleSubmit, dirty }) => {
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            <Field component={OtherSettings} />
            <Field component={FrontendSettings} />
            <Field component={MsSettings} />
            <footer className={classes.footer}>
              <ActionsButtons handleSubmit={handleSubmit} dirty={dirty} />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ appSettings, auth }) => {
  return {
    ...appSettings,
    userPartnerId: auth.user.partner_id
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  fetchSettings,
  updateOnSubmit
};

export default flowRight(
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AppSettings);
