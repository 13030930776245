import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import Optional from '../../../utils/optional';
import DataRow from '../../Datarow/DataRow';
import EditableTextField from '../../EditableTextField/EditableTextField';
import styles from '../styles';
import CustomCheckboxRow from '../../EditableTextField/CustomCheckboxRow';
import withUserCheck from '../../../utils/withUserCheck';

function Reporting({ intl, classes, datalimit = {}, isAdminUser }) {
  const [checkboxValue, setCheckboxValue] = useState(datalimit.report_only_to);

  const editableTextFieldOptions = {
    isDisabled: !isAdminUser,
    id: Number(Optional(datalimit.id).or(null)),
    type: 'data_limits',
    urlKey: 'DATA_LIMITS'
  };

  return (
    <div className={classes.reporting}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'REPORTING'
          })}
        </div>
      </div>
      <div className={classes.columnContainer}>
        <CustomCheckboxRow
          value={checkboxValue}
          disabled={false}
          label={'DATA_LIMITS.REPORT_TO'}
          fieldName={'report_only_to'}
          setValue={value => setCheckboxValue(value)}
          mobileRightAllignValue
          {...editableTextFieldOptions}
        />
        {!checkboxValue && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'DATA_LIMITS.DATALIMITS_NUMBERS'
            })} `}
            value={Optional(datalimit.cell_numbers)
              .map(cellNumbers => cellNumbers.map(el => el.number).join(', '))
              .or('')}
            dense
            customClasses={{
              root: classes.reportingAllNumbersRoot,
              value: classes.reportingAllNumbersValue
            }}
            mobileFlexColumn={true}
          />
        )}
        <EditableTextField
          label={`${intl.formatMessage({
            id: 'DATA_LIMITS.REPORT_EMAIL'
          })} `}
          value={datalimit.report_to_email}
          fieldName={'report_to_email'}
          tooltipIntlId="DATA_LIMITS.TOOLTIP.REPORT_EMAIL"
          errorIntlId="MESSAGE.INVALI_EMAIL"
          {...editableTextFieldOptions}
        />
        <EditableTextField
          label={`${intl.formatMessage({
            id: 'DATA_LIMITS.REPORT_SMS'
          })} `}
          value={datalimit.report_to_sms}
          fieldName={'report_to_sms'}
          tooltipIntlId="DATA_LIMITS.TOOLTIP.REPORT_SMS"
          {...editableTextFieldOptions}
        />
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withUserCheck,
  withStyles(styles)
)(Reporting);
