import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { fetchUserLogs } from '../actions/getUserCallLog';
import { fetchUserVoicemails } from '../actions/getUserVoicemails';
import styles from '../styles/showMore';

function ShowMore({
  classes,
  intl,
  fromVoicemails = false,
  fetchUserVoicemails,
  fetchUserLogs,
  areMissedCalls
}) {
  const onButtonClick = useCallback(
    () => {
      fromVoicemails
        ? fetchUserVoicemails({ showMore: true })
        : fetchUserLogs({ showMore: true, areMissedCalls });
    },
    // eslint-disable-next-line
    [areMissedCalls, fromVoicemails]
  );

  return (
    <div className={classes.root}>
      <PrimaryButton onClick={onButtonClick}>
        {intl.formatMessage({
          id: 'SHOW_MORE'
        })}
      </PrimaryButton>
    </div>
  );
}

const mapStatesToProps = ({ userContacts }) => {
  return {
    areMissedCalls: userContacts.areMissedCalls
  };
};

const mapDispatchToProps = {
  fetchUserLogs,
  fetchUserVoicemails
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ShowMore);
