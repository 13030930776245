import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

import Optional from '../../../app/utils/optional';
import withSettings from '../../../app/utils/withSettings';

function MenuSection({ item, intl, defaultSettings }) {
  return (
    <li className="kt-menu__section">
      <h4
        className="kt-menu__section-text"
        style={{
          color: defaultSettings.colors.primary
        }}
      >
        {Optional(item.translate)
          .map(translate => intl.formatMessage({ id: translate }))
          .or(item.section)}
      </h4>
      <i
        className="kt-menu__section-icon flaticon-more-v2"
        style={{
          color: defaultSettings.colors.primary
        }}
      />
    </li>
  );
}

export default flowRight(injectIntl, withSettings)(MenuSection);
