import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_RANGE } from './types';

import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import Optional from '../../../utils/optional';
import prepareSelectsOptions from './prepareSelectsOptions';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';

const SELECTS_ARRAY_PARAMS = [
  {
    endpoint: 'PROVIDERS',
    queryParams: {},
    ignoreNoneOption: true
  }
];

export function setRange({
  range,
  resetValues,
  isEdit,
  lookupNumber,
  fromRightPanel,
  fromLookup,
  rangeId,
  isBeTable
}) {
  return {
    type: sagaActions[SET_RANGE],
    range,
    resetValues,
    isEdit,
    lookupNumber,
    fromRightPanel,
    fromLookup,
    rangeId,
    isBeTable
  };
}

export function* getRange({
  range,
  resetValues,
  isEdit = true,
  lookupNumber,
  fromRightPanel,
  fromLookup,
  rangeId,
  isBeTable
}) {
  let selectsOptions = {};
  try {
    const response = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);

    selectsOptions = response;
  } catch {}

  const completeSelectsOptions = yield call(
    prepareSelectsOptions,
    selectsOptions
  );

  let fetchedRange;

  if (isDefined(rangeId)) {
    const queryParams = {
      include: ['provider', 'address']
    };

    try {
      const { data } = yield call(
        getData,
        urlCreator(`${URLS.EXTERNAL_NO_RANGES}/${rangeId}.json`, queryParams)
      );

      fetchedRange = data;
    } catch {
      fetchedRange = {};
    }
  }

  const { mappedRanges, clientId } = yield call(
    mapRangesForEdit,
    isDefined(rangeId) ? fetchedRange : range
  );

  yield put(
    reduxActions.openEditRange({
      range: mappedRanges,
      selectOptions: completeSelectsOptions,
      resetValues,
      isEdit,
      lookupNumber,
      fromRightPanel,
      fromLookup,
      clientId,
      isBeTable
    })
  );
}

function* watchGetRange() {
  yield takeLatest(sagaActions[SET_RANGE], getRange);
}

export default watchGetRange();

function mapRangesForEdit(range) {
  const {
    id,
    och_active_at,
    och_inactive_at,
    csbc_routing,
    provider,
    address,
    range_from,
    range_up_to_incl,
    status,
    future_status,
    description,
    client
  } = range;

  const clientId = Optional(client?.id).or(void 0);

  return {
    clientId,
    mappedRanges: {
      och_active_at,
      och_inactive_at,
      csbc_routing,
      id,
      address: Optional(address?.id).or(''),
      range_from,
      range_up_to_incl,
      status,
      future_status,
      description: Optional(description).or(''),
      network: Optional(provider)
        .map(provider => provider.id)
        .or('')
    }
  };
}
