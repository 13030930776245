import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import tableColumns from './groupedTableColumns';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import Problems from './Problems';
import styles from '../styles';
import mapGrouped from '../actions/mapGrouped';
import Projections from './Projections';
import { isArrayEmpty } from '../../../utils/isDefined';
import withUserCheck from '../../../utils/withUserCheck';
import Optional from '../../../utils/optional';

export function GroupedTable({
  isLoading,
  grouped,
  classes,
  searchTerm,
  isSuperAdmin,
  groupByCategory,
  lang,
  selectedAccount,
  selectOptions
}) {
  const intl = useIntl();

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({
      groupByCategory,
      lang,
      selectedAccount,
      selectOptions
    });
  }, [groupByCategory, lang, selectedAccount, selectOptions]);

  const dataToShow = useMemo(() => mapGrouped(grouped, searchTerm), [
    searchTerm,
    grouped
  ]);

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      search: false,
      selection: false,
      columnsButton: false,
      showTitle: false,
      toolbar: false,
      paging: false,
      maxBodyHeight: '100vh',
      padding: 'default',
      toolbarButtonAlignment: 'left'
    };
  }, []);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const detailPanel = [
    rowData => ({
      disabled: isArrayEmpty(rowData.projection),
      icon: () => (
        <i
          className="fas fa-chevron-right"
          style={{
            display: isArrayEmpty(rowData.projection) ? 'none' : '',
            fontSize: '12px'
          }}
        ></i>
      ),
      openIcon: () => (
        <i
          className="fas fa-chevron-down"
          style={{
            fontSize: '12px'
          }}
        ></i>
      ),
      render: () => <Projections projection={rowData.projection} />
    }),
    rowData => ({
      disabled: !isSuperAdmin || isArrayEmpty(rowData.problems),
      tooltip:
        rowData.problems.length !== 0 &&
        intl.formatMessage({ id: 'TOOLTIP.SHOW_PROBLEMS' }),
      icon: () => (
        <i
          className="fas fa-exclamation-triangle"
          style={{
            display:
              !isSuperAdmin || isArrayEmpty(rowData.problems) ? 'none' : '',
            fontSize: '12px',
            color: '#FF5F1F'
          }}
        ></i>
      ),
      openIcon: () => (
        <i
          className="fas fa-exclamation-triangle"
          style={{
            fontSize: '12px',
            color: '#FF5F1F'
          }}
        ></i>
      ),
      render: () => <Problems problems={rowData.problems} />
    })
  ];

  return (
    <div className={classes.tableRoot}>
      <MaterialTable
        icons={customTableIcons}
        columns={tableDefaultColumns}
        data={dataToShow}
        options={options}
        detailPanel={detailPanel}
        localization={localization}
        isLoading={isLoading}
      />
    </div>
  );
}

const mapStatesToProps = ({ subscriptions, auth }) => {
  const { groupByCategory, selectedAccount, selectOptions } = subscriptions;
  return {
    groupByCategory,
    lang: Optional(auth.lang).or('default'),
    selectedAccount,
    selectOptions
  };
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(GroupedTable);
