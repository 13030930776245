import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { Link } from 'react-router-dom';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/typeCell';

const ITEM_TYPES = {
  Client: 'clients',
  DataLimit: 'dataLimits',
  SimCard: 'simCards',
  ExternalNumberRange: 'externalNumber',
  User: 'telephonyUsers'
};

const TypeCell = ({ rowData, settings, classes, selectedClient }) => {
  const page = findPage(rowData.item_type);

  return isDefined(page) ? (
    <Link
      className={classes.root}
      style={{ color: settings.colors.links }}
      to={`/${page === 'clients' ? 'admin' : selectedClient.value}/${page}`}
    >
      {rowData.item_type}
    </Link>
  ) : (
    <div>{rowData.item_type}</div>
  );
};

export default flowRight(withSettings, withStyles(styles))(TypeCell);

const findPage = itemType => {
  return Optional(ITEM_TYPES[itemType]).or(void 0);
};
