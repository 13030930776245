import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { connect } from 'react-redux';

import styles from '../styles/userDetail';
import Optional from '../../../utils/optional';
import CallerIdSelect from './CallerIdSelect';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import UserConnectRole from '../../../partials/userConnectRole/UserConnectRole';
import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import TextFieldRow from '../../../partials/Datarow/TextFieldRow';
import CustomAccordion from '../../../partials/customAccordion/CustomAccordion';

const BasicInfo = ({ classes, user, isPartnerAdmin }) => {
  const { department, linesOptions, mitelData = {}, mitelRoles } = user;
  
  return (
    <div className={clsx(classes.row, classes.thirdRow)}>
      {doesArrayHasLength(linesOptions) && !isPartnerAdmin && (
        <CallerIdSelect />
      )}
      {isDefined(mitelRoles) && mitelRoles.length > 1 && !isPartnerAdmin && (
        <UserConnectRole />
      )}
      <CustomAccordion>
        {isDefined(mitelData.name) && (
          <div className={classes.connectDataContainer}>
            <TextFieldRow
              label={translate('USER.DOMAIN_NAME')}
              value={Optional(mitelData.user_id).or('')}
              disabled={true}
            />
          </div>
        )}
        <div className={classes.connectDataContainer}>
          <TextFieldRow
            label={translate('USER.CUSTOM_ID')}
            value={Optional(mitelData.custom_id).or('')}
            disabled={true}
          />
        </div>
        <div className={classes.connectDataContainer}>
          <TextFieldRow
            label={translate('TABLE.DEPARTMENT')}
            value={
              isDefined(mitelData.organization)
                ? Optional(mitelData.organization.department).or('')
                : Optional(department)
                    .map(department => department.name)
                    .or('')
            }
            disabled={true}
          />
        </div>
      </CustomAccordion>
    </div>
  );
};

const mapStatesToProps = ({ user }) => {
  return {
    user
  };
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(BasicInfo);
