import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
export function SimCardMenu({
  children,
  intl,
  openShowHistory,
  simcard,
  openEditSimcard,
  fromRightPanel
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = useCallback(
    () => {
      openEditSimcard({
        simcard,
        simcardId:simcard.id,
        clientId: simcard.client_id,
        fromLookup: true,
        fromRightPanel
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [simcard]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: simcard.id,
        itemType: 'SimCard',
        historyObject: simcard,
        titleText: simcard.icc,
        showAllValuesCheckbox: true
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [simcard.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {can(
          'update',
          createSubject('SimCard', {
            client_id: Number(simcard.client_id)
          })
        ) && (
          <MenuItem onClick={onEditClick}>
            {intl.formatMessage({
              id: 'TOOLTIP.EDIT_SIMCARD'
            })}
          </MenuItem>
        )}

        <MenuItem onClick={onHistoryClick}>
          {intl.formatMessage({
            id: 'TOOLTIP.SHOW_HISTORY'
          })}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapDispatchToProps = {
  openShowHistory: showHistoryReduxActions.openShowHistory,
  openEditSimcard: editSimcardReduxActions.openEditSimcard
};

export default flowRight(
  injectIntl,
  connect(null, mapDispatchToProps)
)(SimCardMenu);
