import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_DATALIMIT } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import showSnackbar from '../../../utils/showSnackbar';
import mapDataToUpdate from './mapDataToUpdate';
import { fetchDataLimits } from '../../../pages/dataLimits/actions/getDataLimits';
import { fetchDatalimit } from '../../../partials/dataLImitsStats/actions/getDatalimit';
import { submitNumber } from '../../../pages/checkNumber/actions/submitNumber';
import { isDefined } from '../../../utils/isDefined';

export function updateOnSubmit({
  datalimit,
  client,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  return {
    type: sagaActions[UPDATE_DATALIMIT],
    datalimit,
    client,
    fromUserDetails,
    fromLookup,
    fromRightPanel,
    lookupNumber,
    datalimitId
  };
}

export function* updateDatalimit({
  datalimit,
  client,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  const mappedData = mapDataToUpdate(datalimit);

  const response = yield call(updateData, {
    url: `${URLS.DATA_LIMITS}/${datalimit.id}`,
    data: {
      data: mappedData
    }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.UPDATE_DATALIMIT',
      messageType: 'success',
      time: 2000
    });

    yield put(reduxActions.resetEditDatalimit());

    if (fromLookup) {
      return yield call(submitNumber, {
        number: lookupNumber,
        fromRightPanel
      });
    }

    if (fromUserDetails) {
      return yield call(fetchDatalimit, { dataLimitId: datalimit.id });
    }

    if (isDefined(datalimitId)) {
      return;
    }

    return yield call(fetchDataLimits, { clientId: client });
  }
}

function* watchUpdateDatalimit() {
  yield takeLatest(sagaActions[UPDATE_DATALIMIT], updateDatalimit);
}

export default watchUpdateDatalimit();
