import React, { cloneElement, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withStyles } from "@material-ui/styles";
import { Dialog, DialogContent } from "@material-ui/core";
import Select from "react-select";

import styles, { customStyles } from "./styles";
import CustomDialogTitle from "../../partials/customDialogTitle/CustomDialogTitle";
import DraggableDialog from "../../partials/DraggableDialog";
import NumbersDataContainer from "../../pages/checkNumber/components/NumbersDataContainer";
import { fetchNumberData } from "../../pages/checkNumber/actions/submitNumber";
import { actions as checkNumberReduxActions } from "../../pages/checkNumber/reducers";
import { reactSelectDefaultStyles } from "../../consts/reactSelectDefaultStyles";
import { doesArrayHasLength } from "../../utils/isDefined";
import withUserCheck from "../../utils/withUserCheck";
import withSettings from "../../utils/withSettings";
import { translate } from "../../i18n/I18nProvider";

const CheckNumberDialog = ({
  classes,
  children,
  numbers = [],
  fetchNumberData,
  resetNumberLookup,
  isNonClientAdminUser,
  settings,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState({
    value: numbers[0],
    label: numbers[0],
  });

  const numbersOptions = useMemo(() => mapNumbersOptions(numbers), [numbers]);

  const onOpenDialog = useCallback(() => {
    if (doesArrayHasLength(numbers)) {
      fetchNumberData({
        number: numbers[0],
        action: () => setOpen(true),
        isNonClientAdminUser,
      });
    }
  }, [fetchNumberData, numbers, isNonClientAdminUser]);

  const onCloseDialog = useCallback(() => {
    setOpen(false);
    resetNumberLookup();
  }, [resetNumberLookup]);

  const handleChange = useCallback(
    selected => {
      if (selected.value !== selectedNumber) {
        resetNumberLookup();
        setSelectedNumber(selected);
        fetchNumberData({ number: selected.value });
      }
    },
    [resetNumberLookup, fetchNumberData, selectedNumber]
  );

  const title = useMemo(() => translate("MENU.NUMBER_LOOKUP"), []);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        PaperComponent={DraggableDialog}
        classes={{
          paper: classes.content,
        }}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          <div className={classes.title}>
            {title}
            {numbersOptions.length > 1 && (
              <div className={classes.selectRowContainer}>
                <Select
                  customStyles={{
                    ...customStyles,
                    control: {
                      ...customStyles.control,
                      backgroundColor: settings.colors.modal_header,
                    },
                    singleValue: {
                      color: settings.colors.modal_header_text,
                    },
                    indicatorContainer: {
                      color: settings.colors.modal_header_text,
                    },
                  }}
                  styles={reactSelectDefaultStyles}
                  value={selectedNumber}
                  onChange={handleChange}
                  options={numbersOptions}
                  hideSelectedOptions={true}
                />
              </div>
            )}
          </div>
        </CustomDialogTitle>
        <DialogContent>
          <NumbersDataContainer fromPopup={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  resetNumberLookup: checkNumberReduxActions.resetCheckNumber,
  fetchNumberData,
};

export default flowRight(
  withSettings,
  withUserCheck,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(CheckNumberDialog);

const mapNumbersOptions = (numbers = []) => {
  return numbers.map(number => {
    return {
      value: number,
      label: number,
    };
  });
};
