import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const SET_LOGS = 'SET_LOGS';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const actionTypes = createReduxActionTypes('DATALIMIT_LOGS', [
  OPEN_DIALOG,
  SET_LOGS,
  CLOSE_DIALOG
]);

const DEFAULT_STATE = {
  open: false,
  datalimitId: void 0,
  logs: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { datalimitId } = action;
      return {
        ...state,
        datalimitId,
        open: true
      };
    }
    case actionTypes[SET_LOGS]: {
      const { logs } = action;
      return {
        ...state,
        logs
      };
    }
    case actionTypes[CLOSE_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDatalimitLogs: datalimitId => ({
    type: actionTypes[OPEN_DIALOG],
    datalimitId
  }),
  setDatalimitLogs: logs => ({
    type: actionTypes[SET_LOGS],
    logs
  }),
  closeDatalimitLogs: () => ({ type: actionTypes[CLOSE_DIALOG] })
};
