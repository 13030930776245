import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles
} from '@material-ui/core';
import React from 'react';

import styles from './styles';
import { translate } from '../../i18n/I18nProvider';

const CustomAccordion = ({ classes, children }) => {
  return (
    <Accordion className={classes.accordion} square={true} elevation={0}>
      <AccordionSummary
        classes={{ expanded: classes.expanded }}
        className={classes.accordionSummary}
        expandIcon={<i className="fa fa-angle-down" />}
      >
        <div>{translate('MORE_INFO')}</div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(CustomAccordion);
