import { toAbsoluteUrl } from '../../_metronic';

export default [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/260-united-kingdom.svg')
  },
  {
    lang: 'da',
    name: 'Dansk',
    flag: toAbsoluteUrl('/media/flags/174-denmark.svg')
  }
];
