import React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import * as msTeams from '@microsoft/teams-js';

import styles from './styles/logged';
import { injectIntl } from 'react-intl';
import { isDefined } from '../../utils/isDefined';

function Logged({ location, classes, intl }) {
  const channel = new BroadcastChannel('ms-login');
  const msg = queryString.parse(location.search);

  if (isDefined(msg.token)) {
    msTeams.authentication.notifySuccess(msg.token);
  }

  if (isDefined(msg.error)) {
    msTeams.authentication.notifyFailure({
      error: msg.error,
      email: msg.email
    });
  }
  
  channel.postMessage(msg);
  window.close();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        {intl.formatMessage({
          id: 'MESSAGE.CLOSE_WINDOW'
        })}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withRouter, withStyles(styles))(Logged);
