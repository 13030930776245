import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_RANGES } from './types';

import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import prepareSelectsOptions from './prepareSelectsOptions';

const SELECTS_ARRAY_PARAMS = [
  {
    endpoint: 'PROVIDERS',
    queryParams: {},
    ignoreNoneOption: true
  }
];

export function openRangesMultiedit({
  ranges,
  resetValues,
  isBeTable
}) {
  return {
    type: sagaActions[SET_RANGES],
    ranges,
    resetValues,
    isBeTable
  };
}

export function* setRanges({ ranges, resetValues, isBeTable }) {
  let selectsOptions = {};
  try {
    const response = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);

    selectsOptions = response;
  } catch {}

  const completeSelectsOptions = yield call(
    prepareSelectsOptions,
    selectsOptions
  );

  yield put(
    reduxActions.openDialog({
      ranges,
      selectOptions: completeSelectsOptions,
      resetValues,
      isBeTable
    })
  );
}

function* watchSetRanges() {
  yield takeLatest(sagaActions[SET_RANGES], setRanges);
}

export default watchSetRanges();
