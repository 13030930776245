const styles = theme => {
  return {
    root: {
      fontSize: '18px',
      cursor: 'pointer',
      margin: '0 20px'
    },
    hideOnMobile: {
      [theme.breakpoints.down(theme.breakpoints.values.md + 5)]: {
        display: 'none'
      }
    }
  };
};

export default styles;
