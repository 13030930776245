import { isDefined, isUndefined } from './isDefined';
import { translateColumnsTitles } from './tableUtils';
import store from '../store/store';

const LOCALSTORAGE_TABLE_VERSION = '1.1';

export function setTableColumns({
  setColumns,
  tableName,
  tableDefaultColumns
}) {
  const userId = store.getState().auth.user.id;
  const localColumns = checkLocalColumnsValues({
    userId,
    tableName,
    tableDefaultColumns
  });

  setColumns(
    translateColumnsTitles(mapColumns(tableDefaultColumns, localColumns))
  );
}

export function updateTableColumns({
  setColumns,
  tableName,
  tableDefaultColumns,
  columnData,
  hidden
}) {
  const userId = store.getState().auth.user.id;

  const localColumns = checkLocalColumnsValues({
    userId,
    tableName,
    tableDefaultColumns
  });

  const foundIndex = localColumns.findIndex(column => {
    return column.id === columnData.tableData.id;
  });

  if (isDefined(foundIndex)) {
    localColumns[foundIndex] = { ...localColumns[foundIndex], hidden };
  }

  localStorage.setItem(
    `${userId}-${tableName}-${LOCALSTORAGE_TABLE_VERSION}`,
    JSON.stringify(localColumns)
  );

  setColumns(
    translateColumnsTitles(mapColumns(tableDefaultColumns, localColumns))
  );
}

function mapColumns(tableDefaultColumns = [], localColumns = []) {
  return tableDefaultColumns.map(column => {
    const localColumn = localColumns.find(
      localColumn => localColumn.id === column.id
    );
    if (isUndefined(localColumn)) {
      return column;
    }

    return { ...column, hidden: localColumn.hidden };
  });
}

function checkLocalColumnsValues({ userId, tableName, tableDefaultColumns }) {
  if (
    isUndefined(
      localStorage.getItem(
        `${userId}-${tableName}-${LOCALSTORAGE_TABLE_VERSION}`
      )
    )
  ) {
    localStorage.setItem(
      `${userId}-${tableName}-${LOCALSTORAGE_TABLE_VERSION}`,
      JSON.stringify(tableDefaultColumns)
    );
  }

  return JSON.parse(
    localStorage.getItem(`${userId}-${tableName}-${LOCALSTORAGE_TABLE_VERSION}`)
  );
}

export const setSearchHistory = ({ name, value }) => { 
  const userId = store.getState().auth.user.id;

  const currentValues = getCurrentValues({ name });

  const valueAlreadyExist = currentValues.find(el => el === value);

  if (isDefined(valueAlreadyExist)) {
    return void 0;
  }

  const newHistoryArray = [value, ...currentValues].slice(0, 5);

  localStorage.setItem(`${userId}-${name}`, JSON.stringify(newHistoryArray));

  return newHistoryArray;
};

export const getCurrentValues = ({ name }) => {
  const userId = store.getState().auth.user.id;

  let currentValues = localStorage.getItem(`${userId}-${name}`);

  if (isUndefined(currentValues)) {
    localStorage.setItem(`${userId}-${name}`, JSON.stringify([]));
  }

  currentValues = localStorage.getItem(`${userId}-${name}`);

  return JSON.parse(currentValues);
};

export const forgotSearchHistory = () => {
  const userId = store.getState().auth.user.id;

  localStorage.removeItem(`${userId}-check_number_Number`)
  localStorage.removeItem(`${userId}-check_number_ICC`)
}

