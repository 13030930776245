import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import flowRight from 'lodash.flowright';
import * as builder from '../../ducks/builder';
import KTToggle from '../../_assets/js/toggle';
import compareRolesInArray from '../../../app/utils/compareRolesInArray';
import { ROLES_GROUPS } from '../../../app/consts/rolesIds';
import withSettings from '../../../app/utils/withSettings';
import ErrorsPopover from '../../../app/partials/errorsPanel/ErrorsPopover';
import SearchIcon from '../../../app/partials/searchIcon/SearchIcon';
import createSubject from '../../../app/utils/createSubject';
import can from '../../../app/utils/can';

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
      settings,
      userRole,
      systemErrorsQty,
      selectedClientId
    } = this.props;

    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        style={{ paddingLeft: 0 }}
        {...headerMobileAttributes}
      >
        <div
          className="kt-header-mobile__logo"
          style={{
            backgroundColor: settings.colors.aside_left_brand,
            padding: '0 10px'
          }}
        >
          <img alt="logo" src={settings.logos.small} />
        </div>

        <div className="kt-header-mobile__toolbar">
          {systemErrorsQty > 0 && <ErrorsPopover />}

          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          {headerMenuSelfDisplay &&
            (this.props.clientList.length > 1 ||
              compareRolesInArray(
                userRole,
                ROLES_GROUPS.NON_CLIENT_ADMINS
              )) && (
              <button
                className="kt-header-mobile__toggler"
                id="kt_header_mobile_toggler"
              >
                <span />
              </button>
            )}
          {can(
            'read',
            createSubject('SimCard', {
              client_id: Number(selectedClientId)
            })
          ) && <SearchIcon />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    'aside.self.display'
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'header.menu.self.display') ===
    true,
  toggleOptions: {
    target: 'body',
    targetState: 'kt-header__topbar--mobile-on',
    togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: 'header_mobile',
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: 'aside_menu'
  }),
  userRole: store.auth.role,
  clientList: store.selectClient.selectClientsList,
  systemErrorsQty: store.errorsPanel.errors.length,
  selectedClientId: store.selectClient.selectedClient.id
});

export default flowRight(withSettings, connect(mapStateToProps))(HeaderMobile);
