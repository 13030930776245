import { URLS } from '../consts/endpoints';
import { getData } from './http';
import Optional from './optional';
import urlCreator from './urlCreator';

const checkIfIoTShouldBeShown = async clientId => {
  const queryParams = {
    page: { size: 1 },
    product_category: 'IoT',
    execute_fetch_events: false,
    return_projections: false
  };
  const response = await getData({
    options: {
      url: urlCreator(
        `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions`,
        queryParams
      )
    }
  });

  const count = Number(Optional(response?.headers?.['x-total-count']).or(0));

  return count > 0 ? count : false;
};

export default checkIfIoTShouldBeShown;
