import { OUTSIDE_API } from '../../../consts/endpoints';
import { mitelRequest } from '../../../utils/mitelRequest';
import mapDiversions from './mapDiversions';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isDefined } from '../../../utils/isDefined';

const getDiversions = async (userConnectId, canFetch) => {
  const { data: diversions } = canFetch
    ? await mitelRequest({
        options: {
          url: `${OUTSIDE_API.USERS}/${userConnectId}/diversions`
        },
        errorsToPass: [RESPONSE_CODES.FORBIDDEN],
        hideSnackbarOnPassErrors: true
      })
    : [];

  const mappedDiversions = isDefined(diversions)
    ? mapDiversions(diversions)
    : {};

  return { diversions, mappedDiversions };
};

export default getDiversions;
