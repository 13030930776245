import watchGetSelectsOptions from './getSelectsOptions';
import updateSimcard from './updateSimcard';
import watchFetchSimcard from './getSimcard';

const watchEditSimcard = [
  watchGetSelectsOptions,
  updateSimcard,
  watchFetchSimcard
];

export default watchEditSimcard;
