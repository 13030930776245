import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import getActualValue from './actions/getActualValue';
import styles from './styles';
import EditDialog from './components/EditDialog';
import Optional from '../../utils/optional';
import { isDefined, isStringEmpty } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';

export function TextFieldWithEditDialog({
  requestId,
  classes,
  intl,
  labelIntlKey,
  tooltipIntlKey,
  editDialogLabelIntlKey,
  updateSuccessIntlKey,
  placeholderIntlKey = 'EMPTY',
  field,
  customStyles = {},
  customClasses = {},
  maxTextLength = 17,
  editEnabled = true,
  isLabel = true,
  editOnValueClick = false,
  updatedValue,
  settings
}) {
  const [text, setText] = useState('');
  const [openDialog, setOPenDialog] = useState(false);

  const editDialogProps = {
    updateSuccessIntlKey,
    requestId,
    editDialogLabelIntlKey,
    text,
    field
  };

  useEffect(() => {
    (async function fetchActualValue() {
      const data = await getActualValue(field, requestId);
      setText(data[field]);
    })();
    // eslint-disable-next-line
  }, [requestId, field]);

  useEffect(() => {
    if (isDefined(updatedValue)) {
      setText(updatedValue);
    }
    // eslint-disable-next-line
  }, [updatedValue]);

  const isTextToLong = Optional(text)
    .map(text => text.length > maxTextLength)
    .or(false);

  return (
    <>
      {isDefined(text) && (
        <>
          <div
            className={clsx(classes.root, customClasses.root)}
            style={customStyles.root}
          >
            {isLabel && (
              <div
                className={clsx(classes.label, customClasses.label)}
                style={customStyles.label}
              >
                <div>
                  {intl.formatMessage({
                    id: labelIntlKey
                  })}
                </div>
                {editEnabled && (
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({
                      id: tooltipIntlKey
                    })}
                  >
                    <i
                      className={clsx('fas fa-edit', classes.editIcon)}
                      style={{ color: settings.colors.links }}
                      onClick={() => setOPenDialog(true)}
                    ></i>
                  </Tooltip>
                )}
              </div>
            )}
            <Tooltip placement="bottom" title={isTextToLong ? text : ''}>
              <div
                className={clsx(classes.text, customClasses.text)}
                style={customStyles.text}
                onClick={editOnValueClick ? () => setOPenDialog(true) : null}
              >
                {isStringEmpty(text)
                  ? `${intl.formatMessage({
                      id: placeholderIntlKey
                    })}`
                  : isTextToLong
                  ? `${text.substring(0, maxTextLength - 3)}...`
                  : text}
              </div>
            </Tooltip>
          </div>
          <EditDialog
            open={openDialog}
            onSubmit={text => {
              setOPenDialog(false);
              setText(text);
            }}
            onClose={() => {
              setOPenDialog(false);
            }}
            {...editDialogProps}
          />
        </>
      )}
    </>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(TextFieldWithEditDialog);
