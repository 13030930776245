import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import mapDataForChart from './mapDataForChart';
import findDatalimitOwner from '../../../utils/findDataLimitOwner';
import findDatalimitNetwork from '../../../utils/findDatalimitNetwork';
import searchInArray from '../../../utils/searchInArray';

const BRAND_ARRAY = ['TDC', 'Telenor'];

const DATA_LIMITS_QUERY_PARAMS = {
  extra_fields: { data_limits: 'accumulated_data_usage_month' },
  include: ['cell_numbers.sim_card.owner', 'client', 'cell_numbers'],
  fields: { cell_numbers: 'number', client: 'name' }
};

const getDatalimit = async datalimitId => {
  const { data: dataLimit } = await getData(
    urlCreator(
      `${URLS.DATA_LIMITS}/${datalimitId}.json`,
      DATA_LIMITS_QUERY_PARAMS
    )
  );

  const network = findDatalimitNetwork(dataLimit);

  const showCharts = searchInArray({
    array: BRAND_ARRAY,
    term: network
  });

  const chartData = showCharts ? mapDataForChart(dataLimit) : {};

  const owners = findDatalimitOwner(dataLimit);

  return { ...dataLimit, chartData, owners, showCharts };
};

export default getDatalimit;
