export default {
  root: {
    width: '860px',
    minHeight: '440px',
    maxHeight: '80vh',
    padding: '20px',
    fontSize: '12px'
  },
  formContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column'
  },

  selectContainer: {
    marginBottom: '10px'
  },
  info: {
    width: '100%',
    padding: '20px',
    textAlign: 'center'
  }
};

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '250px'
  },
  menu: {
    width: '250px'
  }
};
