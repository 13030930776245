import { deleteData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';

export default async function deleteShortcut(shortcutId) {
  const {
    mitelToken,
    baseUrl,
    clientDomain,
    mitelUserId
  } = getMitelRequestProps();

  try {
    const response = await deleteData({
      url: `${baseUrl}/api/presence/shortcut/${clientDomain}/${mitelUserId}/${shortcutId}?t=${mitelToken}`
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.DELETE_SHORTCUT',
        messageType: 'success'
      });

      return true;
    }
  } catch {
    return false;
  }
}
