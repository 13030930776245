import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    '& span': {
      padding: '0 5px'
    }
  }
};

export function ShowHideButton({ classes, show }) {
  return (
    <div className={classes.root}>
      {!show ? (
        <i className="fas fa-eye"></i>
      ) : (
        <i className="fas fa-eye-slash"></i>
      )}
    </div>
  );
}

export default withStyles(styles)(ShowHideButton);
