import moment from 'moment';

import Optional from '../../../utils/optional';
import { isArray } from '../../../utils/isDefined';

const mapAccountDetails = data => {
  const { features } = data;

  if (!isArray(features)) {
    return {};
  }

  const billingFeature = features.find(el => el.key === 'account-billing');

  if (!isArray(billingFeature?.featureAttributes)) {
    return {};
  }

  const attributes = billingFeature.featureAttributes;

  return {
    ean: mapFeatureAttribute('invoice-delivery-ean-number', attributes),
    invoiceRefference: mapFeatureAttribute('invoice-reference', attributes),
    invoiceDeliveryType: mapFeatureAttribute(
      'invoice-delivery-type',
      attributes
    ),
    invoiceEmail: mapFeatureAttribute('invoice-delivery-email', attributes),
    orderRef: mapFeatureAttribute('order-reference', attributes),
    orderIssueDate: mapDateAttribute('order-issue-date', attributes)
  };
};

export default mapAccountDetails;

const mapFeatureAttribute = (key, attributes = []) => {
  const attribute = attributes.find(attr => attr.key === key);

  return Optional(attribute?.value).or('');
};

const mapDateAttribute = (key, attributes = []) => {
  const attribute = attributes.find(attr => attr.key === key);

  const isValidDate = moment(attribute?.value).isValid();

  return isValidDate ? moment(attribute?.value).format('YYYY-MM-DD') : '';
};
