const styles = theme => ({
  container: {
    display: 'flex',
    width: '700px',
    paddingLeft: '30px',
    justifyContent: 'space-between',
    color: '#74788D',
    [theme.breakpoints.down('xs')]: {
      width: '330px'
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm - 340)]: {
      width: 'unset',
      paddingLeft: '10px'
    }
  },
  textFieldContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  textField: {
    width: '410px',
    opacity: 1,
    borderBottom: 0,
    '&:before': {
      borderBottom: 0
    },
    '& i': {
      cursor: 'pointer',
      marginRight: '5px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '300px'
    }
  },
  textFieldInput: {
    color: '#74788D',
    fontSize: '13px',
    fontWeight: 500
  },
  disabled: {
    borderBottom: 0,
    '&:before': {
      borderBottom: 0
    }
  },
  label: { display: 'flex', alignItems: 'center' },

  infoIcon: {
    fontSize: '13px',
    marginLeft: '3px'
  },
  checkboxValue: {
    width: '420px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '90px'
    }
  },
  mobileRightAllignValue: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end'
    }
  }
});

export default styles;
