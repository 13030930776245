export default {
  dialogTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '180px',
    justifyContent: 'space-around'
  },
  appendixLink: {
    height: '19px',
    fontSize: '12px',
    '&:hover': { filter: 'brightness(1.5)' },
    cursor: 'pointer'
  },
  unlockDialog: {
    height: '50px',
    alignItems: 'center',
    textAlign: 'center'
  },
  dialogTextContainerBigger: {
    minHeight: '240px'
  }
};
