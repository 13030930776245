import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import LicensesTable from './components/LicensesTable';
import { actions as reduxActions } from './reducers';

const Licenses = ({ resetLicenses }) => {
  useEffect(() => {
    return resetLicenses;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <LicensesTable />
    </div>
  );
};

const mapDispatchToProps = {
  resetLicenses: reduxActions.resetLicenses
};

export default connect(null, mapDispatchToProps)(Licenses);
