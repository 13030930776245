import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import zxcvbn from 'zxcvbn';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from './styles';

const PASS_STRENGTH_TEXT = ['VERY_WEAK', 'WEAK', 'FAIR', 'GOOD', 'EXCELLENT'];

export function PassStrengthMeter({ classes, pass,intl }) {
  const passStrength = zxcvbn(pass).score;
  const strengthClass = `strengthMeterFillStrength${passStrength}`;
  return (
    <div
      className={clsx(classes.strengthMeterContainer, {
        [classes.invisible]: !Boolean(pass.length)
      })}
    >
      <div className={classes.strengthMeter}>
        <div
          className={clsx(classes.strengthMeterFill, classes[strengthClass])}
        ></div>
      </div>
      <div className={classes.passStrengthText}>
        {intl.formatMessage({
        id: `VALIDATION.${[PASS_STRENGTH_TEXT[passStrength]]}`
      })}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(PassStrengthMeter);
