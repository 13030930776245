export default {
  dataContainer: { display: 'flex' },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    padding: '20px 0',
    '& button': {
      marginRight: '10px'
    }
  },
  saveButton: {
    color: '#fff',
    backgroundColor: '#5D78FF',
    '&:hover': { backgroundColor: '#0ABB87' }
  }
};
