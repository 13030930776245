import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';

import styles from '../styles/infoSection';
import findTranslatedValues from '../../../utils/findTranslatedValues';
import { NUMBER_STATUSES, NUMBER_TYPES } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';
import clsx from 'clsx';

function InfoNumberRangeSection({ classes, data, intl }) {
  const { number_type, status, provider } = data;
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div
          className={clsx(classes.element, classes.smallElement)}
        >{`${intl.formatMessage({
          id: 'TABLE.TYPE'
        })} : ${findTranslatedValues(number_type, NUMBER_TYPES)}`}</div>
        <div
          className={clsx(classes.element, classes.smallElement)}
        >{`${intl.formatMessage({
          id: 'STATUS'
        })} : ${findTranslatedValues(status, NUMBER_STATUSES)}`}</div>
      </div>

      <div className={classes.row}>
        <div className={classes.element}>{`${intl.formatMessage({
          id: 'TABLE.NETWORK'
        })} : ${Optional(provider)
          .map(provider => Optional(provider.sane_name).or(provider.name))
          .or('')}`}</div>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withStyles(styles)
)(InfoNumberRangeSection);
