import React from 'react';

function TabPanel({ children, value, index }) {
  return (
    <div>
      {value === index &&  children}
    </div>
  );
}

export default TabPanel;
