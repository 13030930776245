const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      padding: '10px 50px 10px 30px',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#74788D',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: '260px'
      }
    },
    valueContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'flex-end',
        paddingRight: '5px'
      }
    },
    value: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center'
    },
    dense: {
      padding: '3px 0'
    },
    transferContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '160px'
    },
    mobile: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    mobileFlexColumn: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    label: {
      width: '100px'
    },
    link: { '&:hover': { filter: 'brightness(1.5)' } },
    error: {
      fontSize: '8px',
      color: '#FD397A'
    },
    hidden: { visibility: 'hidden' }
  };
};

export default styles;
