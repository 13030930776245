const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '400px',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '10px 0',
      [theme.breakpoints.down('xs')]: {
        width: '260px'
      }
    },
    label: { fontSize: 'inherit' },
    menuItem: { display: 'flex', alignItems: 'center' },
    flag: {
      height: '15px',
      width: '15px'
    }
  };
};

export default styles;
