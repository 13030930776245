import watchGetAllPrompts from './getAllPrompts';
import watchSetSchedule from './setSchedule';
import watchUpdateSchedule from './updateSchedule';
import watchGetAllVacationDays from './getVacations';

const watchEditSchedule = [
  watchGetAllPrompts,
  watchSetSchedule,
  watchUpdateSchedule,
  watchGetAllVacationDays
];

export default watchEditSchedule;
