export default {
  root: {
    padding: '10px 20px'
  },
  label: { marginBottom: '15px' },
  dataContainer: {
    display: 'flex'
  },
  links: { cursor: 'pointer' },
  dataKey: {
    margin: '0 20px',
    width: '180px'
  },
  dotsIcon: { cursor: 'pointer' },
  lookupRowValue: { display: 'flex', width: '200px', alignItems: 'center' },
  holderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%'
  },
  markerIcon: { marginLeft: '10px', fontSize: 'medium' },
  bold: { fontWeight: 600 }
};
