import { translate } from '../i18n/I18nProvider';
import capitaliseFirstLetter from '../utils/capitaliseFirstLetter';

export const CSV_HEADERS = {
  SIM_CARDS: [
    { label: translate('EDIT.USER'), key: 'owner' },
    { label: translate('TABLE.MOBILE_NUMBER'), key: 'msisdn' },
    { label: translate('TABLE.NETWORK'), key: 'mobile_network' },
    { label: translate('SIMCARD_NUMBER'), key: 'icc' },
    { label: 'PIN1', key: 'pin1' },
    { label: 'PUK1', key: 'puk1' },
    { label: translate('TABLE.PRIMARY_CARD'), key: 'primary_card' },
    { label: translate('TABLE.DESCRIPTION'), key: 'description' },
    { label: translate('CARD_TYPE'), key: 'card_type' },
    { label: translate('STATUS'), key: 'active' },
    { label: translate('CREATED_AT'), key: 'created_at' },
    { label: translate('UPDATED_AT'), key: 'updated_at' }
  ],
  NUMBER_RANGES: [
    { label: translate('TABLE.RANGE_FROM'), key: 'range_from' },
    { label: translate('TABLE.RANGE_TO'), key: 'range_up_to_incl' },
    { label: translate('TABLE.NETWORK'), key: 'provider' },
    { label: translate('TABLE.NO_OF_NUMBERS'), key: 'interval' },
    { label: translate('STATUS'), key: 'status' },
    { label: translate('TABLE.TYPE'), key: 'number_type' },
    { label: translate('TABLE.ACTIVE_AT'), key: 'och_active_at' },
    { label: translate('TABLE.INACTVE_AT'), key: 'och_inactive_at' },
    { label: translate('TABLE.DESCRIPTION'), key: 'description' },
    { label: translate('CREATED_AT'), key: 'created_at' },
    { label: translate('UPDATED_AT'), key: 'updated_at' }
  ],
  USERS: [
    { label: translate('TABLE.NAME'), key: 'name' },
    { label: translate('TABLE.EMAIL'), key: 'email' },
    { label: translate('TABLE.USERNAME'), key: 'username' },
    {
      label: translate('USERS_TABLE.PLATFORM_USER_ID'),
      key: 'connect30_user_id'
    },
    { label: translate('TABLE.DEPARTMENT'), key: 'department' },
    {
      label: translate('CELL_NUMBERS'),
      key: 'cellNumbers'
    },
    { label: translate('TABLE.USER_ROLE'), key: 'roles' },
    {
      label: translate('USERS_TABLE.SUPER_ADMIN'),
      key: 'is_super_admin'
    },
    { label: translate('USERS_TABLE.CONNECT_LINK'), key: 'mitel_profile_link' },
    {
      label: translate('LANGUAGE'),
      key: 'language'
    },
    { label: translate('USER.ACCES_TO_SELFCARE'), key: 'accessToSelfcare' },
    { label: translate('CREATED_AT'), key: 'created_at' },
    { label: translate('UPDATED_AT'), key: 'updated_at' }
  ],
  DATA_LIMITS: [
    { label: translate('TABLE.NAME'), key: 'name' },
    { label: translate('CELL_NUMBERS'), key: 'cellNumbers' },
    { label: translate('EDIT.USER'), key: 'user' },
    { label: translate('STATUS'), key: 'status' },
    { label: translate('DATA_LIMITS.DATA_LIMIT'), key: 'block_limit_mb' },
    {
      label: translate('DATA_LIMITS.ROAMING_DISABLED'),
      key: 'roaming_disabled'
    },
    {
      label: translate('DATA_LIMITS.FAIR_USAGE_LIMIT'),
      key: 'fair_usage_limit'
    },
    { label: translate('DATA_LIMITS.AUTO_RECHARGE'), key: 'allow_recharge' },
    { label: translate('CLIENT'), key: 'client' },
    { label: translate('DATA_LIMITS.REPORT_EMAIL'), key: 'report_to_email' },
    { label: translate('DATA_LIMITS.REPORT_SMS'), key: 'report_to_sms' },
    { label: translate('DATA_LIMITS.REPORT_TO'), key: 'report_only_to' },
    { label: translate('DATA_LIMITS.MAX_NO'), key: 'max_numbers' },
    { label: translate('DATA_LIMITS.FUTURE_LIMIT'), key: 'future_limit' },
    { label: translate('DATA_LIMITS.FUTUR_LIMIT_AT'), key: 'future_limit_at' },
    { label: translate('DATA_LIMITS.WARNED'), key: 'warned_at' },
    { label: translate('DATA_LIMITS.BLOCKED'), key: 'blocked_at' },
    { label: 'Update MDU', key: 'update_mdu' },
    { label: translate('DATA_LIMITS.RECHARGED_AT'), key: 'recharged_at' },
    { label: translate('DATA_LIMITS.RECHARGE_COUNT'), key: 'recharge_count' },
    { label: 'TMP open', key: 'tmp_open' },
    { label: 'Manual block', key: 'manual_block' },
    { label: 'No limit', key: 'no_limit' },
    { label: translate('CREATED_AT'), key: 'created_at' },
    { label: translate('UPDATED_AT'), key: 'updated_at' }
  ],
  SUBSCRIPTIONS: [
    {
      title: capitaliseFirstLetter(translate('SUBSCRIPTIONS.GROUP_BY_SKU')),
      field: 'skuTranslations'
    },
    {
      title: translate('SUBSCRIPTIONS.ITEM_NUMBER'),
      field: 'sku'
    },
    {
      title: translate('SUBSCRIPTIONS.PRODUCT_CATEGORY'),
      field: 'productCategory'
    },
    { title: translate('TABLE.NAME'), field: 'name' },
    { title: translate('STATUS'), field: 'status' },
    { title: translate('ACTIVATION_DATE'), field: 'activationDate' },
    { title: translate('EXPIRE_DATE'), field: 'expiryDate' },
    { title: translate('QUANTITY'), field: 'quantity' },
    { title: translate('TABLE.DESCRIPTION'), field: 'description' }
  ]
};
