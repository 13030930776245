import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    name: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    durationMinutes: yup.string().when('durationType', {
      is: 'MINUTES',
      then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
    }),
    diversionPhoneNumber: yup.string().when('applyDiversionNumber', {
      is: true,
      then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
    })
  });
}
