import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { isDefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';

import styles from './styles';

function PresenceDot({
  classes,
  actualAvailability,
  settings,
  isContactView = false,
  inCall = false,
  customClasses = {},
  customStyles = {},
}) {
  const innerStyle = useMemo(() => {
    return {
      backgroundColor:
        actualAvailability && !inCall
          ? settings.colors.success
          : settings.colors.danger
    };
    // eslint-disable-next-line
  }, [actualAvailability, inCall]);
  return (
    isDefined(actualAvailability) && (
      <div
        className={clsx(classes.root, customClasses.root, {
          [classes.contactView]: isContactView
        })}
        style={customStyles.root}
      >
        <div className={classes.inner} style={innerStyle}>
          {inCall && (
            <i className={clsx('fas fa-phone-alt', classes.phoneIcon)} />
          )}
        </div>
      </div>
    )
  );
}

export default flowRight(withSettings, withStyles(styles))(PresenceDot);
