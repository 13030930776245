import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { cloneElement, useEffect, useState } from 'react';

import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../../partials/DraggableDialog';
import mapDataObject from '../actions/mapDataObject';
import styles from '../styles/infoView';
import Optional from '../../../utils/optional';

const InfoView = ({ children, dataObject, classes, type }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (open) {
      const mappedData = mapDataObject({ dataObject });

      if (mappedData) {
        setData(mappedData);
      }
    }
  }, [open, dataObject]);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="md"
        PaperComponent={DraggableDialog}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {`${type} ${Optional(dataObject?.name)
            .map(name => `- ${name}`)
            .or('')}`}
        </CustomDialogTitle>
        <DialogContent className={classes.root}>
          {data.map((el, i) => {
            return (
              <div key={i} className={classes.dataContainer}>
                <div className={classes.label}>{el.key}</div>
                <TextField
                  className={classes.fieldValue}
                  margin="dense"
                  variant="outlined"
                  value={Optional(el.value).or('')}
                  inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
                />
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(InfoView);
