import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_PERMISSIONS = 'SET_PERMISSIONS';
const RESET_PERMISSIONS = 'RESET_PERMISSIONS';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('PERMISSIONS', [
  SET_PERMISSIONS,
  RESET_PERMISSIONS,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  permissions: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_PERMISSIONS]: {
      return { ...state, permissions: [...action.permissions] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_PERMISSIONS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setPermissions: permissions => ({
    type: actionTypes[SET_PERMISSIONS],
    permissions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetPermissions: () => ({ type: actionTypes[RESET_PERMISSIONS] })
};
