import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';

import styles from '../styles/userRoles';
import { isDefined } from '../../../utils/isDefined';

export function Groups({
  intl,
  classes,
  arrayHelpers: {
    form: {
      values: { groups = [] }
    },
    remove,
    push
  },
  options
}) {
  const availableOptions = useMemo(
    () => [...mapOptions(options, groups)],
    // eslint-disable-next-line
    [options, groups]
  );

  return (
    <div className={classes.groupsRoot}>
      <div className={classes.groupColumn}>
        {intl.formatMessage({
          id: 'USER.AVAILABLE_GROUPS'
        })}
        <div className={clsx(classes.rolesContainer, classes.groupsContainer)}>
          {availableOptions
            .filter(group => !group.isUserGroup)
            .map((group) => (
              <div className={classes.roleContainer} key={group.label}>
                {group.label}
                <i
                  className={clsx('far fa-chevron-double-right', classes.icon)}
                  onClick={() => push(group.label)}
                ></i>
              </div>
            ))}
        </div>
      </div>
      <div className={classes.groupColumn}>
        {intl.formatMessage({
          id: 'USER.ASSIGNED_GROUPS'
        })}
        <div className={clsx(classes.rolesContainer, classes.groupsContainer)}>
          {groups.map((group, i) => (
            <div className={classes.roleContainer} key={group}>
              {group}
              <i
                className={clsx('far fa-times-circle', classes.icon)}
                onClick={() => remove(i)}
              ></i>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Groups);

function mapOptions(options, groups) {
  return options.map(option => {
    const isUserGroup = groups.find(group => group === option.label);
    return { ...option, isUserGroup: isDefined(isUserGroup) };
  });
}
