const styles = theme => {
  return {
    root: {},
    dialogContent: {
      overflow: 'visible'
    },
    sectionContainer: { marginTop: '20px', minHeight: '200px', width: '500px' },
    loaderOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      '& button': {
        margin: '10px'
      }
    },
    editContainer: {
      margin: '20px 0',
      minHeight: '180px'
    },
    datarowRoot: { width: '450px', padding: '10px 0' },
    datarowValue: { width: '320px', justifyContent: 'end', fontWeight: 600 },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '10px'
    },
    row: { display: 'flex', marginLeft: '10px 30px', alignItems: 'center' },
    pricesContainer: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    infoIcon: { marginRight: '25px', fontSize: '14px' },
    hidden: { visibility: 'hidden' },
    error: { width: '100%', textAlign: 'center' }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '450px'
  },
  menu: {
    width: '450px'
  }
};

export const customInputStules = {
  root: { width: '450px', alignItems: 'flex-start', marginRight: 0 },
  label: { width: '200px' },
  textfield: {
    width: '250px',
    fontSize: '15px',
    fontWeight: 600
  }
};
