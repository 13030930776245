import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import { translate } from '../../../i18n/I18nProvider';
import { formatDateForSchedule } from '../../../utils/formatDate';
import Optional from '../../../utils/optional';
import {
  customSearchByProduct,
  findSkuTranslation
} from '../actions/customSearchActions';
import { isUndefined } from '../../../utils/isDefined';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { findAccountName } from '../actions/exportActions';

export default function tableColumns({
  groupByCategory,
  lang,
  selectedAccount,
  selectOptions
}) {
  let columns = [
    {
      title: translate('TABLE.NAME'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name',
      cellStyle: { maxWidth: '450px', minWidth: '450px', width: 'unset' },
      headerStyle: { maxWidth: '450px', minWwidth: '450px', width: 'unset' }
    },
    {
      title: capitaliseFirstLetter(translate('SUBSCRIPTIONS.GROUP_BY_SKU')),
      field: 'skuTranslations',
      cellStyle: { maxWidth: '450px', minWidth: '450px', width: 'unset' },
      headerStyle: { maxWidth: '450px', minWwidth: '450px', width: 'unset' },
      customFilterAndSearch: (term, rowData) => {
        return customSearchByProduct({ rowData, term, lang });
      },
      render: rowData => {
        return findSkuTranslation({ rowData, lang });
      },
      hideForProductGrouping: true
    },
    {
      title: translate('STATUS'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.status, term);
      },
      field: 'status',
      width: '50px'
    },
    {
      title: translate('ACTIVATION_DATE'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.activationDate, term);
      },
      render: rowData => {
        return Optional(formatDateForSchedule(rowData.activationDate)).or('-');
      },
      field: 'activationDate',
      align: 'right',
      width: '80px'
    },
    {
      title: translate('EXPIRE_DATE'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.expiryDate, term);
      },
      render: rowData => {
        return Optional(formatDateForSchedule(rowData.expiryDate)).or('-');
      },
      field: 'expiryDate',
      align: 'right',
      width: '80px'
    },
    {
      title: translate('QUANTITY'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.quantity, term);
      },
      field: 'quantity',
      align: 'right',
      width: '20px'
    },
    {
      title: translate('TABLE.DESCRIPTION'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.description, term);
      },
      field: 'description'
    }
  ];

  let mappedColumns = [...columns];

  if (!groupByCategory) {
    mappedColumns = mappedColumns.filter(column =>
      isUndefined(column.hideForProductGrouping)
    );
  }

  if (selectedAccount?.value === 'ALL') {
    mappedColumns = [
      {
        title: translate('ACCOUNT'),
        field: 'accountParentId',
        render: rowData => {
          return findAccountName({ sub: rowData, selectOptions });
        }
      },
      ...mappedColumns
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
