import uniqBy from 'lodash.uniqby';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function createNetworkOptions(externalNo) {
  if (isUndefined(externalNo)) {
    return {};
  }

  const rangesWithProvider = externalNo
    .filter(no => isDefined(no.provider))
    .map(no => {
      return no.provider;
    });

  return uniqBy(rangesWithProvider, 'name').reduce((obj, el) => {
    return { ...obj, [el.name]: Optional(el.sane_name).or(el.name) };
  }, {});
}
