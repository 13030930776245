import { createReduxActionTypes } from '../../../utils/actionFactory';

const START_LINEAR_LOADER = 'START_LINEAR_LOADER';
const STOP_LINEAR_LOADER = 'STOP_LINEAR_LOADER';
const START_SPIN_LOADER = 'START_SPIN_LOADER';
const STOP_SPIN_LOADER = 'STOP_SPIN_LOADER';
const RESET_LOADER = 'RESET_LOADER';

export const actionTypes = createReduxActionTypes('LOADER', [
  START_LINEAR_LOADER,
  STOP_LINEAR_LOADER,
  START_SPIN_LOADER,
  STOP_SPIN_LOADER,
  RESET_LOADER
]);

const DEFAULT_STATE = {
  isLinearVisible: false,
  isSpinVisible: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[START_LINEAR_LOADER]: {
      return { ...state, isLinearVisible: true };
    }
    case actionTypes[STOP_LINEAR_LOADER]: {
      return { ...state, isLinearVisible: false };
    }
    case actionTypes[START_SPIN_LOADER]: {
      return { ...state, isSpinVisible: true };
    }
    case actionTypes[STOP_SPIN_LOADER]: {
      return { ...state, isSpinVisible: false };
    }
    case actionTypes[RESET_LOADER]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  startLinearLoader: () => ({
    type: actionTypes[START_LINEAR_LOADER]
  }),
  stopLinearLoader: () => ({
    type: actionTypes[STOP_LINEAR_LOADER]
  }),
  startSpinLoader: () => ({
    type: actionTypes[START_SPIN_LOADER]
  }),
  stopSpinLoader: () => ({
    type: actionTypes[STOP_SPIN_LOADER]
  }),
  resetLoader: () => ({
    type: actionTypes[RESET_LOADER]
  })
};
