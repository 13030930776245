import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import styles, { dataRowCustomStyles } from '../styles/additionslData';
import DataRow from '../../../partials/Datarow/DataRow';
import { translate } from '../../../i18n/I18nProvider';
import { mapAddress } from '../../../partials/customAddressSelect/actions/getClientAddresses';
const PhoneBookInfo = ({ classes, phonebookEntry = {} }) => {
  const dataRowCustomClasses = { value: classes.datarowValue };

  const address = useMemo(() => {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    if (phonebookEntry?.use_default_address) {
      return mapAddress(
        phonebookEntry?.client?.default_address,
        regionNames,
        true
      );
    }

    return mapAddress(phonebookEntry?.address, regionNames, true);
  }, [phonebookEntry]);

  return (
    <div className={classes.phoneBookRoot}>
      <div className={classes.label}>Phonebook registration</div>
      <div className={classes.columnsContainer}>
        {phonebookEntry?.noEntry && (
          <div className={classes.noEntry}>
            No phonebook registration entry found
          </div>
        )}
        <div className={classes.columnContainer}>
          <DataRow
            label={translate('STATUS')}
            value={phonebookEntry?.protection_code}
            customClasses={dataRowCustomClasses}
            dense
          />
          <DataRow
            label={translate('NAME')}
            value={phonebookEntry?.name}
            customClasses={dataRowCustomClasses}
            customStyles={dataRowCustomStyles}
            dense
          />
          <DataRow
            label={translate('COMPANY')}
            value={phonebookEntry?.organization_name}
            customClasses={dataRowCustomClasses}
            dense
          />
          <DataRow
            labelText="C/O"
            value={phonebookEntry?.care_of_name}
            customClasses={dataRowCustomClasses}
            customStyles={dataRowCustomStyles}
            dense
          />
          <DataRow
            label={translate('ADDRESS')}
            value={address}
            customClasses={dataRowCustomClasses}
            dense
          />
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  const { phonebookEntry } = checkNumber;
  return {
    phonebookEntry
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(PhoneBookInfo);
