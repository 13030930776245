export default {
  root: {
    width: 'fit-content',
    backgroundColor: '#FFFFFF'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #EBEDF2',
    padding: '10px',
    '& button': {
      padding: '5px'
    }
  },
  dialogContent: { padding: 0 }
};
