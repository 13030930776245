import React, { useState, cloneElement, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { translate } from '../../../i18n/I18nProvider';
import { actions as activationMessageDialogReduxActions } from '../../../partials/activationMessageDialog/reducers';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import DeleteRangeConfimation from './DeleteRangeConfimation';
import { isDefined, isObject } from '../../../utils/isDefined';
import customParseNumber from '../../../utils/customParseNumber';

const MoreActionsMenu = ({
  children,
  numberRange = {},
  onDeleteClick,
  onHistoryClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDanishNumber, setIsDanishNumber] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isDefined(numberRange?.range_from)) {
      let parsedNumber = customParseNumber(`+${numberRange?.range_from}`);

      if (
        isObject(parsedNumber) &&
        parsedNumber.country === 'DK' &&
        parsedNumber.isValid() === true
      ) {
        setIsDanishNumber(true);
      }
    }
  }, [numberRange.range_from]);

  const showHistoryClick = () => {
    handleClose();
    onHistoryClick();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={showHistoryClick}>
          {translate('TOOLTIP.SHOW_HISTORY')}
        </MenuItem>
        {can(
          'delete',
          createSubject('ExternalNumberRange', {
            client_id: Number(numberRange.client?.id)
          })
        ) && (
          <div>
            <ConfirmDialog
              closeMenu={handleClose}
              onConfirmClick={onDeleteClick}
              dialogContentComponent={<DeleteRangeConfimation />}
              dialogTitleIntlId={'TOOLTIP.DELETE_RANGE'}
            >
              <MenuItem
                disabled={
                  numberRange.status !== 'inactive' &&
                  numberRange.status !== 'outported'
                }
              >
                {translate('TOOLTIP.DELETE_RANGE')}
              </MenuItem>
            </ConfirmDialog>
          </div>
        )}
        {isDanishNumber &&
          can(
            'update',
            createSubject('PhoneBookRegistration', {
              external_number_range_id: Number(numberRange.id)
            })
          ) && (
            <div>
              <EditPhoneBookStatus
                number={numberRange?.range_from}
                clientId={numberRange?.client?.id}
                numberRangeId={numberRange?.id}
                closeMenuOnDialogOpen={handleClose}
                registrationData={numberRange?.phone_book_registrations}
                numberRangeForSelect={
                  numberRange?.interval > 1
                    ? [numberRange?.range_from, numberRange?.range_up_to_incl]
                    : void 0
                }
              >
                <MenuItem>{translate('PHONE_BOOK.TITLE')}</MenuItem>
              </EditPhoneBookStatus>
            </div>
          )}
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openActivationMessageDialog: activationMessageDialogReduxActions.openDialog
};

export default flowRight(
  withUserCheck,
  connect(null, mapDispatchToProps)
)(MoreActionsMenu);
