import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const RESET_DIALOG = 'RESET_DIALOG';

export const actionTypes = createReduxActionTypes('DATALIMIT_MULTIEDIT', [
  OPEN_DIALOG,
  RESET_DIALOG
]);

const DEFAULT_STATE = {
  open: false,
  datalimits: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { datalimits } = action;
      return {
        ...state,
        datalimits: [...datalimits],
        open: true
      };
    }
    case actionTypes[RESET_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDialog: datalimits => ({
    type: actionTypes[OPEN_DIALOG],
    datalimits
  }),
  closeDialog: () => ({ type: actionTypes[RESET_DIALOG] })
};
