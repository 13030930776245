import { Checkbox, TextField, withStyles } from '@material-ui/core';
import React from 'react';

import styles from '../styles/editQuota';
import { isUndefined } from '../../../utils/isDefined';
import clsx from 'clsx';

const EditQuota = ({ classes, props }) => {
  const { value, onChange } = props;

  const onInputChange = e => onChange(e.target.value);

  const onCheckBoxChange = e =>
    e.target.checked ? onChange(null) : onChange('');

  return (
    <div className={classes.root}>
      <TextField
        value={isUndefined(value) ? '' : value}
        type="number"
        disabled={isUndefined(value)}
        className={classes.input}
        onChange={onInputChange}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
      <i className={clsx(' fas fa-infinity', classes.icon)}></i>
      <Checkbox checked={isUndefined(value)} onChange={onCheckBoxChange} />
    </div>
  );
};

export default withStyles(styles)(EditQuota);
