import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import getSettings from './actions/getSettings';
import styles from './styles';
import { withStyles } from '@material-ui/styles';

function LoadingScreen({ getSettings, classes }) {
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <>
      <div className={classes.root}>
        SELFCARE Loading....
        <CircularProgress />
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getSettings
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(LoadingScreen);
