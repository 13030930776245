import watchGetUser from './getUser';
import watchSetSelectedLine from './setSelectedLine';
import watchLockUser from './lockUser';

const watchUser = [
  watchGetUser,
  watchSetSelectedLine,
  watchLockUser
];

export default watchUser;
