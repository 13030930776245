import {
  createTheme,
  ThemeProvider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import clsx from 'clsx';

import styles from './styles';
import Optional from '../../utils/optional';
import getSelectValuefromOptions from '../../utils/getSelectValueFromOptions';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import { translate } from '../../i18n/I18nProvider';

const DataSelect = ({
  label,
  name,
  classes,
  width,
  values,
  isDisabled,
  infoIcon = false,
  infoIconTooltipComponent = void 0,
  infoIconText = '',
  options = [],
  handleBlur,
  setFieldValue,
  maxMenuHeight = 140,
  minMenuHeight = 100,
  isLoading = false,
  menuPlacement = 'bottom',
  customStyles = { value: { width: '260px' } },
  errors
}) => {
  const theme = createTheme({
    overrides: {
      muiFormControl: {
        margin_dense: {
          marginTop: 0
        }
      }
    }
  });

  const customSelectStyles = useMemo(() => {
    const mappedCustomWidth = Optional(customStyles.value)
      .map(value => value.width)
      .or('200px');
    return {
      container: {
        width: mappedCustomWidth
      },
      valueContainer: {
        width: mappedCustomWidth
      },
      option: { fontSize: '12px' },
      control: {
        width: mappedCustomWidth,
        borderColor: errors[name] ? '#FD397A' : '#D3D3D3',
        borderRadius: '5px',
        height: '46px'
      }
    };
    // eslint-disable-next-line
  }, [errors]);

  const value = getSelectValuefromOptions(options, values[name]);

  const handleChange = useCallback(
    selected => {
      setFieldValue(name, selected.value);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div
      className={classes.selectRoot}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={Optional(infoIconTooltipComponent).or(infoIconText)}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <ThemeProvider theme={theme}>
        <Select
          className={classes.fieldValue}
          styles={reactSelectDefaultStyles}
          customStyles={customSelectStyles}
          isSearchable
          options={options}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          isDisabled={Optional(isDisabled).or(false)}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          maxMenuHeight={maxMenuHeight}
          isLoading={isLoading}
          loadingMessage={() => translate('LOADING')}
        />
      </ThemeProvider>
    </div>
  );
};

export default withStyles(styles)(DataSelect);
