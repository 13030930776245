import { translate } from '../i18n/I18nProvider';
import {
  isUndefined,
  isDefined,
  isFunction,
  isArrayEmpty,
  isString
} from './isDefined';

export function mapFilters(filtersArray, mapCustomFilters) {
  return filtersArray.reduce((obj, filter) => {
    let { column, value } = filter;

    if (isString(value)) {
      value = value.trim();
    }

    if (isArrayEmpty(value) || value === '') {
      return obj;
    }

    return isDefined(mapCustomFilters) && isFunction(mapCustomFilters)
      ? mapCustomFilters({ filter, obj, value })
      : { ...obj, [column.field]: { match: value } };
  }, {});
}

export function mapSorting(sortObj, mapCustomSorting) {
  const { orderBy, orderDirection } = sortObj;

  if (isUndefined(orderBy.field)) {
    return isDefined(mapCustomSorting) && isFunction(mapCustomSorting)
      ? mapCustomSorting(orderBy, orderDirection)
      : '';
  }

  return orderDirection === 'asc' ? orderBy.field : `-${orderBy.field}`;
}

export function mapFilterValue(value) {
  if (!isString(value)) {
    return value;
  }

  return mapValue(value);
}

export function mapSearchValue(value) {
  const regex = /[a-z0-9]/g;
  if (!isString(value) || !regex.test(value)) {
    return '';
  }
  return mapValue(value);
}

export function translateColumnsTitles(columns) {
  return columns.map(column => {
    if (isUndefined(column.title)) {
      return column;
    }
    return {
      ...column,
      title: column.untranslatedTitle ? column.title : translate(column.title)
    };
  });
}

export function idx(array, idx) {
  if (idx < 0) {
    idx += array.length;
  }
  return array[idx];
}

function mapValue(value) {
  const numberRegexp = new RegExp(/^[0-9+\s]*$/g);
  return value.match(numberRegexp) ? value.replace(/ |\+/g, '') : value;
}
