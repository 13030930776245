import { getData } from './http';
import { URLS } from '../consts/endpoints';
import RESPONSE_CODES from '../consts/responseCodes';

export default async function checkICC(number) {
  try {
    const response = await getData({
      options: {
        url: `${URLS.SIM_CARDS}/${number}/validate`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.FORBIDDEN]
    });

    return response;
  } catch (e) {
    return e.response;
  }
}
