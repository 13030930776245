import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_DATA_LIMITS_WITH_USAGE } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import mapDatalimits from './mapDatalimits';
import selectOptionsCreator from '../../../utils/selectOptionsCreator';

const SELECTS_ARRAY_PARAMS = [
  {
    endpoint: 'monthsCount',
    queryParams: {},
    number: 6,
    startFrom: 1
  }
];

export function getDataLimitsWithUsage(clientId) {
  return {
    type: sagaActions[GET_DATA_LIMITS_WITH_USAGE],
    clientId
  };
}

export function* fetchDataLimitsWithUsage({ clientId }) {
  yield put(loaderReduxActions.startLinearLoader());
  const QUERY_PARAMS = yield call(mapQueryParams, { clientId });
  const selectsOptions = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);
  let datalimits = [];

  try {
    const { data } = yield call(
      getData,
      urlCreator(JSONAPI_URLS.DATA_LIMITS, QUERY_PARAMS)
    );
    datalimits = data;
  } catch {
  } finally {
    const mappedDatLimits = yield call(mapDatalimits, datalimits);

    yield put(reduxActions.setDatalimits(mappedDatLimits, selectsOptions));
    yield put(loaderReduxActions.stopLinearLoader());
  }
}

function* watchFetchDataLimitsWithUsage() {
  yield takeLatest(
    sagaActions[GET_DATA_LIMITS_WITH_USAGE],
    fetchDataLimitsWithUsage
  );
}

export default watchFetchDataLimitsWithUsage();

export function mapQueryParams({ clientId }) {
  let params = {
    paginate: false,
    include: ['cell_numbers.sim_card.owner'],
    sort: 'name',
    extra_fields: { data_limits: 'accumulated_data_usages' },
    filter: {
      client_id: { eq: clientId }
    }
  };

  return params;
}
