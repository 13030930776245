const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 20px)',
        justifyContent: 'space-between',
        paddingRight: '5px'
      }
    },
    label: {
      width: '130px',
      padding: '0 20px 0px 10px',
      color: '#595D6E',
      fontWeight: 500
    },
    select: {
      width: '250px'
    }
  };
};

export default styles;

export const customSelectStyles = {
  control: {
    borderRadius: '5px',
    boxShadow: 'none'
  },
  indicatorSeparator: { display: 'none' }
};
