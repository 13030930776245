import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { Checkbox, TextField } from '@material-ui/core';

import styles from '../styles/transferRow';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

const TransferRow = ({
  label,
  valueKey,
  classes,
  settings,
  customClasses = {},
  isTransferAllCheckbox = false,
  checkboxLabel = '',
  values,
  setFieldValue,
  errors
}) => {
  const phoneNumber = useMemo(() => values[valueKey]?.lineNumber, [
    values,
    valueKey
  ]);

  const handleCheckboxChange = useCallback(
    e => {
      setFieldValue(`${valueKey}.isForwarded`, e.target.checked);
      setFieldValue(`${valueKey}.forwardNumber`, '');
    },
    [setFieldValue, valueKey]
  );

  const handleTransformAllCheckbox = useCallback(
    e => {
      const { transferAll, ...restValues } = values;
      const restValuesKeys = Object.keys(restValues);

      restValuesKeys.map(value => {
        setFieldValue(`${value}.isForwarded`, false);
        setFieldValue(`${value}.forwardNumber`, '');
        return value;
      });

      setFieldValue(`${valueKey}.isForwarded`, e.target.checked);
    },
    [valueKey, values, setFieldValue]
  );

  const handleTextfieldChange = useCallback(
    e => {
      setFieldValue(`${valueKey}.forwardNumber`, e.target.value);
    },
    [setFieldValue, valueKey]
  );

  return (
    <div className={clsx(classes.root, classes.dense, customClasses.root)}>
      <div className={classes.label}>
        <div>{Optional(label).or('')}</div>
      </div>
      <div className={classes.valueContainer}>
        {isTransferAllCheckbox ? (
          <div>{checkboxLabel}</div>
        ) : (
          <div
            className={classes.link}
            style={{ color: settings.colors.links }}
          >
            {phoneNumber}
          </div>
        )}
        <div
          className={clsx(classes.transferContainer, {
            [classes.hidden]:
              values.transferAll.isForwarded && !isTransferAllCheckbox
          })}
        >
          <div className={classes.value}>
            <Checkbox
              checked={values[valueKey].isForwarded}
              onChange={
                isTransferAllCheckbox
                  ? handleTransformAllCheckbox
                  : handleCheckboxChange
              }
              disabled={
                !isTransferAllCheckbox && values.transferAll.isForwarded
              }
            />
          </div>
          <div>
            <TextField
              margin="dense"
              placeholder={`< ${translate('NUMBER.ENTER_NUMBER')} >`}
              value={values[valueKey].forwardNumber}
              disabled={
                !values[valueKey].isForwarded ||
                (!isTransferAllCheckbox && values.transferAll.isForwarded)
              }
              onChange={handleTextfieldChange}
              error={Boolean(errors[valueKey]?.forwardNumber)}
              inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
            />
            {isDefined(errors[valueKey]?.forwardNumber) && (
              <div className={classes.error}>
                {errors[valueKey].forwardNumber}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default flowRight(
  withSettings,
  withStyles(styles)
)(TransferRow);
