import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_CLIENT_USERS } from './types';
import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import { OUTSIDE_API } from '../../../consts/endpoints';

export function changeClientUsersOptions(clientId, isDomainProvided) {
  return {
    type: sagaActions[SET_CLIENT_USERS],
    clientId,
    isDomainProvided
  };
}

export function* setClientUserOptions({ clientId, isDomainProvided }) {
  const SELECTS_ARRAY_PARAMS = [
    {
      customEndpoint: `${OUTSIDE_API.CLIENTS}/${clientId}/users`,
      queryParams: {},
      key: 'TELEPHONY_USERS',
      ignore: !clientId,
      isDomainProvided
    }
  ];

  const selectsOptions = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);

  yield put(reduxActions.setClientUsersOptions(selectsOptions));
}

function* watchSetClientUsers() {
  yield takeLatest(sagaActions[SET_CLIENT_USERS], setClientUserOptions);
}

export default watchSetClientUsers();
