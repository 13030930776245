import { actions as reduxActions } from '../reducers';
import store from '../../../store/store';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import {
  checkEndDate,
  checkForwardNumber,
  checkName
} from './validateFunctions';
import checkReccurenceErrors from './checkReccurenceErrors';

export default async function validateForm({ values, group, initialValues }) {
  store.dispatch(reduxActions.setErrors({}));
  let errorsArray = [];
  let errors = {};
  if (values.name !== 'default') {
    const nameError = checkName({ name: values.name, group, initialValues });

    if (isDefined(nameError)) {
      errorsArray = [...errorsArray, nameError];
      errors = { ...errors, name: ' ' };
    }

    const { reccurenceErrors, reccurenceErrorsArray } = checkReccurenceErrors(
      values
    );
    errorsArray = [...errorsArray, ...reccurenceErrorsArray];
    errors = { ...errors, ...reccurenceErrors };

    if (values.reccurenceEndType === 'DATE' && isDefined(values.endDate)) {
      const endDateError = checkEndDate(
        values.reccurenceEndDate,
        values.endDate
      );

      if (isDefined(endDateError)) {
        errorsArray = [...errorsArray, endDateError.value];
        errors = { ...errors, ...endDateError.errors };
      }
    }
  }

  if (values.type === 'FORWARD') {
    const forwardNumberError = checkForwardNumber(values.forwardNumber);

    if (isDefined(forwardNumberError)) {
      errorsArray = [...errorsArray, forwardNumberError];
      errors = { ...errors, forwardNumber: ' ' };
    }
  }

  if (doesArrayHasLength(errorsArray)) {
    store.dispatch(reduxActions.setErrors({ ...errors, errorsArray }));
    return false;
  }

  return true;
}
