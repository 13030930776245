import { actions as reduxActions } from '../reducers';
import { actions as simcardsReduxActions } from '../../../pages/simCards/reducers';
import { actions as userReduxActions } from '../../../pages/user/reducers';
import { actions as editSimcardReduxActions } from '../../editSimcard/reducers';

export default function closeSwapCard() {
  return (dispatch, getState) => {
    const { fromEdit, isIoTCard } = getState().swapSimcard;

    if (isIoTCard) {
      return dispatch(reduxActions.reset());
    }

    if (!fromEdit) {
      dispatch(userReduxActions.setSimcardReload(true));
    } else {
      dispatch(editSimcardReduxActions.setRefresh(true));

      dispatch(simcardsReduxActions.setRefresh(true));
    }

    return dispatch(reduxActions.reset());
  };
}
