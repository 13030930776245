const styles = theme => {
  return {
    container: {
      width: '500px',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px 0',
      paddingBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    title: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
      fontSize: '18px',
      borderBottom: '1px solid #EBEDF2'
    },
    panelTitle: { borderBottom: 'unset' },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px'
    },
    customPhoto: {
      width: '400px',
      height: '400px',
      borderRadius: 'unset',
      marginBottom: '20px'
    },
    noPhotoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '32px'
    },
    infoContainer: {
      margin: '10px'
    },
    infoFirstRow: {
      fontWeight: 'bold'
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center'
      }
    },
    fileName: {
      width: '200px',
      margin: '0 10px',
      [theme.breakpoints.down('xs')]: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    footer: {
      margin: '20px'
    }
  };
};

export default styles;
