export const ROLES = {
  IPV_SUPERVISOR: 'Ipvision administrator',
  PARTNER_ADMIN: 'Partner administrator',
  CLIENT_ADMIN: 'Kunde administrator',
  STANDARD_USER: 'Standard bruger'
};
export const ROLES_IDS = {
  IPV_SUPERVISOR: 9000,
  PARTNER_ADMIN: 8000,
  CLIENT_ADMIN: 5000,
  STANDARD_USER: 1000
};

export const ROLES_GROUPS = {
  ADMINS: [ROLES.IPV_SUPERVISOR, ROLES.PARTNER_ADMIN, ROLES.CLIENT_ADMIN],
  CLIENT_USERS: [ROLES.CLIENT_ADMIN, ROLES.STANDARD_USER],
  NON_CLIENT_ADMINS: [ROLES.IPV_SUPERVISOR, ROLES.PARTNER_ADMIN]
};
