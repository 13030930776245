import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

import { translate } from '../../i18n/I18nProvider';
import Optional from '../../utils/optional';
import clsx from 'clsx';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    marginLeft: '20px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'lightBlue' }
  },
  tableHeader: {
    marginLeft: 0,
    width: '20px',
    fontSize: '16px',
    '&:hover': { backgroundColor: 'unset' }
  }
};

export function RefreshButton({
  classes,
  onClick,
  customTitle,
  tableHeader = false
}) {
  return (
    <Tooltip title={Optional(customTitle).or(translate('TOOLTIP.REFRESH'))}>
      <div
        className={clsx(classes.root, { [classes.tableHeader]: tableHeader })}
        onClick={onClick}
      >
        <i className="fas fa-redo-alt"></i>
      </div>
    </Tooltip>
  );
}

export default withStyles(styles)(RefreshButton);
