import getFormData from './getFormData';
import getSchema from './getSchema';

export default async function getFormValues({
  setTitle,
  setSchema,
  setFormData,
  setUiSchema,
  clientId,
  templateName
}) {
  await getSchema({ setTitle, setSchema, templateName, setUiSchema, clientId });
  await getFormData({ setFormData, clientId, templateName });
  return true;
}
