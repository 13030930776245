const styles = theme => {
  return {
    root: { width: '1000px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      overflow: 'visible',
      width: '1000px'
    },
    scrollPaper: {
      alignItems: 'start'
    },
    content: { display: 'flex', overflow: 'hidden' },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      padding: '20px 0',
      '& button': {
        marginRight: '10px'
      }
    }
  };
};

export default styles;
