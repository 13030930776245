import { URLS } from '../../../consts/endpoints';
import { deleteData, postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export default async function tempUserAction(id, action) {
  const isCreateAction = action === 'create';

  const url = `${URLS.CLIENTS}/${id}/temp_support_user`;
  try {
    const response = isCreateAction
      ? await postData({
          url
        })
      : await deleteData({
          url
        });

    if (response.status === RESPONSE_CODES.CREATED) {
      showSnackbar({
        intlTextKey: 'MESSAGE.TEMP_USER_CREATED',
        messageType: 'success'
      });
    }

    if (response.status === RESPONSE_CODES.NO_CONTENT) {
      showSnackbar({
        intlTextKey: 'MESSAGE.TEMP_USER_DELETED',
        messageType: 'success'
      });
    }
  } catch {}
}
