import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    number: yup
      .string()
      .when('searchType', {
        is: 'Number',
        then: yup
          .string()
          .matches(/^[0-9|+| ]+$/, translate('VALIDATION.ONLY_DIGITS'))
          .test(null, 'number is not valid', function(value) {
            const mappedNumber = value.replace(/\s|\+/g, '');

            return mappedNumber.length < 8
              ? this.createError({
                  message: `${translate('VALIDATION.TOO_SHORT_NUMBER')}
                ${translate('VALIDATION.DIGITS_BETWEEN')} 8-11`
                })
              : mappedNumber.length > 12
              ? this.createError({
                  message: `${translate('VALIDATION.TOO_LONG_NUMBER')}
                  ${translate('VALIDATION.DIGITS_BETWEEN')} 8-11`
                })
              : true;
          })
      })
      .when('searchType', {
        is: 'ICC',
        then: yup
          .string()
          .matches(/^[0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
          .min(
            18,
            `${translate('VALIDATION.TOO_SHORT_NUMBER')}
          ${translate('VALIDATION.DIGITS_BETWEEN')} 18-20`
          )
          .max(
            20,
            `${translate('VALIDATION.TOO_LONG_NUMBER')}
        ${translate('VALIDATION.DIGITS_BETWEEN')} 18-20`
          )
      })
  });
}
