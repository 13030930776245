const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      height: 'auto',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      margin: '1vh 1vw 1vh 0',
      [theme.breakpoints.down('xs')]: {
        width: '270px'
      }
    },
    chartTitle: { fontWeight: 500, fontSize: '15px', margin: '10px' }
  };
};

export default styles;
