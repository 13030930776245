export default {
  root: {
    width: 'fit-content',
    maxWidth: '500px',
    padding: '20px',
    fontSize: '12px',
    backgroundColor: '#FFF'
  },
  noDomainRoot: {
    width: '100%',
    padding: '20px',
    fontSize: '14px',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center'
  },
  selectContainer: {
    marginBottom: '10px'
  },
  info: {
    width: '100%',
    padding: '20px',
    textAlign: 'center'
  }
};

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '250px'
  },
  menu: {
    width: '250px'
  }
};
