import React, { useCallback, useMemo } from 'react';
import { RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';

import styles, { customStyles } from '../styles';
import { actions as reduxActions } from '../reducers';
import withSettings from '../../../utils/withSettings';
import CustomFormControlLabel from '../../../partials/customFormControlLabel';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';

function MainSelector({
  classes,
  mainSelected,
  setMainSelected,
  intl,
  settings
}) {
  const selectOptions = useMemo(
    () => [
      {
        value: 'all',
        label: intl.formatMessage({
          id: 'BUTTON.ALL'
        })
      },
      {
        value: 'assigned',
        label: intl.formatMessage({
          id: 'ASSIGNED'
        })
      },
      {
        value: 'unassigned',
        label: intl.formatMessage({
          id: 'UNASSIGNED'
        })
      }
    ],
    [intl]
  );

  const onRadioButtonChange = e => {
    setMainSelected(e.target.value);
  };

  const handleSelectChange = useCallback(
    selected => {
      if (selected.value !== mainSelected) {
        setMainSelected(selected.value);
      }
    },
    [mainSelected, setMainSelected]
  );

  return (
    <>
      <div className={classes.selectContainer}>
        <Select
          customStyles={customStyles}
          styles={reactSelectDefaultStyles}
          value={selectOptions.filter(option => option.value === mainSelected)}
          onChange={handleSelectChange}
          options={selectOptions}
        />
      </div>
      <RadioGroup
        row
        className={classes.radioButtons}
        value={mainSelected}
        onChange={onRadioButtonChange}
      >
        <CustomFormControlLabel
          value="all"
          label={intl.formatMessage({
            id: 'BUTTON.ALL'
          })}
        />
        <CustomFormControlLabel
          value="assigned"
          label={intl.formatMessage({
            id: 'ASSIGNED'
          })}
        />
        <CustomFormControlLabel
          value="unassigned"
          label={intl.formatMessage({
            id: 'UNASSIGNED'
          })}
        />
      </RadioGroup>
    </>
  );
}

const mapStatesToProps = ({ externalNoRanges }) => {
  return {
    mainSelected: externalNoRanges.mainSelected
  };
};

const mapDispatchToProps = {
  setMainSelected: reduxActions.setMainSelected
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(MainSelector);
