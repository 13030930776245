import { actions as errorReduxActions } from '../partials/errorDialog/reducers';
import store from '../store/store';
import RESPONSE_CODES from '../consts/responseCodes';
import history from '../../history';
import { isArrayEmpty, isDefined, isUndefined } from './isDefined';
import showSnackbar, { showErrorSnackbar } from './showSnackbar';
import { actions as authReduxActions } from '../pages/auth/reducers';
import { actions as errorsPanelReduxActions } from '../partials/errorsPanel/reducers';
import { loginMsUser } from '../pages/auth/actions/otherLogin';
import { mapErrorMessage } from './mapErrorMessage';

export default function errorsHandler({
  error,
  errorsToPass,
  url,
  addToList = false
}) {
  if (error.message === 'Network Error') {
    const isCustomTokenRequest = url.indexOf('?t=') !== -1;
    if (isCustomTokenRequest) {
      showSnackbar({
        text: 'Connect error',
        messageType: 'error',
        time: 8000
      });
      const {
        auth: { authToken }
      } = store.getState();
      store.dispatch(loginMsUser({ auth: authToken }));
    } else {
      history.push('/logout');
      store.dispatch(
        errorReduxActions.openDialog({
          errorMessage: {
            name: 'Network error',
            messageIntl: 'MESSAGE.CONNECTION_PROBLEM'
          }
        })
      );
      throw error;
    }
  }

  if (
    isDefined(errorsToPass) &&
    errorsToPass.findIndex(code => code === error.response.status) !== -1
  ) {
    throw error;
  }

  if (isUndefined(error.response)) {
    showSnackbar({
      text: 'Connect error',
      messageType: 'error',
      time: 8000
    });
    throw error;
  }

  const isUnauthorized =
    RESPONSE_CODES.UNAUTHORIZED.findIndex(
      code => code === error.response.status
    ) !== -1;

  const errorMessage = mapErrorMessage(error.response);

  if (isUnauthorized) {
    history.push('/logout');
    store.dispatch(authReduxActions.setLoginError({ error: errorMessage }));
    throw error;
  }

  if (
    error.response.status === RESPONSE_CODES.INTERNAL_ERROR &&
    isArrayEmpty(errorMessage?.errorTextArray)
  ) {
    return;
  }

  return !addToList
    ? showErrorSnackbar({
        errorMessage,
        messageType: 'error',
        time: 8000
      })
    : store.dispatch(errorsPanelReduxActions.updateErrors(errorMessage));
}
