import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import checkNumberData from '../../../utils/checkNumberData';
import { isArray } from '../../../utils/isDefined';
import styles from '../styles/serviceInfos';
import CustomTitle from './CustomTitle';

function ServiceInfos({
  classes,
  serviceInfo = {},
  intl,
  fromRightPanel = false,
  rightPanelData = {}
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: serviceInfo,
      rightPanelData,
      fromRightPanel
    });
  }, [serviceInfo, rightPanelData, fromRightPanel]);

  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.SERVICES" isDataExist={isDataExist}>
        {isDataExist &&
          data.map((service, i) => {
            const isIdToLong = service.serviceTypeId.length > 9;
            return (
              <div key={i}>
                <div
                  className={clsx(classes.service, {
                    [classes.darkerRow]: i % 2 !== 0
                  })}
                >
                  <Tooltip title={isIdToLong ? service.serviceTypeId : ''}>
                    <div className={classes.serviceId}>
                      {isIdToLong
                        ? `${service.serviceTypeId.slice(0, 6)}...`
                        : service.serviceTypeId}
                    </div>
                  </Tooltip>
                  <div>{service.serviceDesc}</div>
                </div>
                {service.activationDate && (
                  <div className={classes.featureInfos}>{`${intl.formatMessage({
                    id: 'ACTIVATION_DATE'
                  })} : ${service.activationDate}`}</div>
                )}
                {isArray(service.featureInfos) &&
                  service.featureInfos.map((feature, i) => {
                    return (
                      <div className={classes.featureInfos} key={i}>
                        <div>{`${feature.featureDesc} (${feature.featureTypeId})`}</div>
                        {feature.serviceFeatureParameterInfos &&
                          feature.serviceFeatureParameterInfos.map(
                            (info, i) => {
                              return (
                                <div className={classes.parameter} key={i}>
                                  <div className={classes.parameterId}>
                                    {info.parameterName}
                                  </div>
                                  <div>{`: ${info.parameterValue}`}</div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.service_infos,
    serviceInfo: checkNumber.service_infos
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(ServiceInfos);
