import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isArray, isDefined, isUndefined } from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';

const QUERY_PARAMS = {
  include_usage: true,
  paginate: false
};

const findUserData = async ({ connectClientId, number }) => {
  let numberToSearch = number.toString();

  const response = isDefined(connectClientId)
    ? await getData(
        urlCreator(
          `${OUTSIDE_API.CLIENTS}/${connectClientId}/number_ranges`,
          QUERY_PARAMS
        )
      )
    : [];

  if (numberToSearch.length > 8) {
    numberToSearch = numberToSearch.slice(-8);
  }

  const foundedRange = findMitelRange(response, numberToSearch);

  if (isUndefined(foundedRange)) {
    return void 0;
  }

  const foundedUser = isArray(foundedRange?.assigned_numbers)
    ? foundedRange.assigned_numbers.find(el => {
        const assignedNumber = el.number.replace('+45', '').trim();

        return assignedNumber === numberToSearch;
      })
    : void 0;

  if (isUndefined(foundedUser)) {
    return void 0;
  }

  return {
    ...foundedUser,
    range: `${foundedRange.range_from} - ${foundedRange.range_up_to_incl}`
  };
};

export default findUserData;

function findMitelRange(mappedMitelData, number) {
  const foundedRange = mappedMitelData.find(
    range =>
      Number(number) >= Number(range.range_from.replace('+45', '').trim()) &&
      Number(number) <= Number(range.range_up_to_incl.replace('+45', '').trim())
  );

  if (isUndefined(foundedRange)) {
    return null;
  }

  return foundedRange;
}
