import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import LinkCell from './LinkCell';

export default function tableColumns({ from, clientId }) {
  return [
    {
      title: translate('NAME'),
      render: rowdata => (
        <LinkCell from={from} clientId={clientId} user={rowdata} />
      )
    },
    {
      title: translate('EMAIL'),
      field: 'email'
    }
  ];
}
