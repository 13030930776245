const styles = theme => {
  return {
    dialogContent: {
      overflow: 'visible',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px 0',
      '& button': {
        marginRight: '10px'
      }
    }
  };
};

export default styles;

export const customStyles = {
  inputRow: {
    root: { marginRight: 0, width: '440px' },
    field: { width: '100%' },
    label: { fontWeight: '600', width: '80px' },
    textfield: { width: '350px' }
  },
  checkbox: {
    root: {
      alignItems: 'flex-start'
    },
    label: { fontWeight: '600', width: '60px' },
    value: { width: '300px' }
  },
  typeSelect: {
    root: { alignItems: 'unset' },
    label: { fontWeight: '600', width: '70px', marginLeft: '0' },
    value: { width: '160px' }
  }
};
