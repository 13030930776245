import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import styles from '../styles/orderESimcard';
import OrderStep from './OrderStep';
import OrderSummary from './OrderSummary';
import eSimCodeRequest from '../actions/eSimCodeRequest';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

const OrderESimcard = ({ simcard }) => {
  const [isComplete, setIsComplete] = useState(false);
  const [activationResponse, setActivationResponse] = useState({});
  const [isProblem, setIsProblem] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(null);

  const handleConfirm = async () => {
    const response = await eSimCodeRequest({
      number: simcard.msisdn,
      clientId: simcard.client_id
    });

    if (response?.error) {
      setIsProblem(true);
    }

    if (response) {
      setActivationResponse(response);
      setSubscriptionId(response?.subscriptionId);
      setIsComplete(true);
    }
  };

  return (
    <>
      {isComplete ? (
        <OrderSummary activationResponse={activationResponse} />
      ) : (
        <OrderStep
          handleConfirm={handleConfirm}
          isProblem={isProblem}
          subscriptionId={subscriptionId}
        />
      )}
    </>
  );
};

const mapStatesToProps = ({ swapSimcard }) => {
  return {
    ...swapSimcard
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(OrderESimcard);
