import { URLS } from '../../../consts/endpoints';
import store from '../../../store/store';
import checkIfIoTShouldBeShown from '../../../utils/checkIfIoTShouldBeShown';
import checkIfSimcardsShouldBeShown from '../../../utils/checkIfSimcardsShouldBeShown';
import { getData } from '../../../utils/http';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import { actions as reduxActions } from '../reducers';

const reloadClient = async id => {
  let client = void 0;
  try {
    const response = await getData(
      `${URLS.CLIENTS}/${id}.json?include=partner`
    );

    client = response?.data;

    const hasCientSimcards = await checkIfSimcardsShouldBeShown(id);

    let hasCientIoTSubs = false;

    if (doesArrayHasLength(response?.data?.metadata?.account_ids)) {
      hasCientIoTSubs = await checkIfIoTShouldBeShown(id);
    }

    client = { ...client, hasCientSimcards, hasCientIoTSubs };
  } finally {
    if (isDefined(client)) {
      store.dispatch(reduxActions.setSelectedClient(client));
    }
  }
};

export default reloadClient;
