import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import validateJSON from '../../../utils/validateJSON';

export default function() {
  return yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-z._]+$/g, translate('VALIDATION.ONLY_SMALL_CHARS_AND_DOT'))
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    conditions: yup
      .string()
      .nullable()
      .test(null, translate('VALIDATION.FIELD_JSON'), value => {
        return validateJSON({ string: value, nullable: true });
      })
  });
}
