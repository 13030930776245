import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import React, { useEffect, useMemo } from 'react';

import { isDefined } from '../../../utils/isDefined';
import createInitials from '../../../utils/createUserInitials';
import Optional from '../../../utils/optional';
import styles from '../styles/userDetail';
import withUserCheck from '../../../utils/withUserCheck';
import getPhoto from '../actions/getPhoto';
import PresenceDot from '../../../partials/presenceDot/PresenceDot';

const UserPhoto = ({
  classes,
  photo,
  name,
  mitelData,
  userId,
  currentUserClientId,
  isCurrentUserSelectedClientUser,
  connectClientId,
  currentLoggedUserId,
  actualAvailability
}) => {
  useEffect(() => {
    (async () =>
      await getPhoto({
        userId,
        isCurrentUserSelectedClientUser,
        currentUserClientId,
        connectClientId,
        currentLoggedUserId
      }))();
  }, [
    isCurrentUserSelectedClientUser,
    currentUserClientId,
    connectClientId,
    userId,
    currentLoggedUserId
  ]);

  const initials = useMemo(() => {
    if (isDefined(name)) {
      const [firstName, lastName] = name.split(' ');
      return createInitials(firstName, lastName);
    } else {
      return Optional(mitelData.name)
        .map(name => createInitials(name.givenName, name.familyName))
        .or('');
    }
  }, [name, mitelData.name]);
  
  return (
    <div className={classes.userContainer}>
      <div className={classes.photo}>
        {isDefined(photo) && photo.constructor.name === 'Blob' ? (
          <img
            src={URL.createObjectURL(photo)}
            className={classes.photo}
            alt="Empty"
          ></img>
        ) : (
          <div className={classes.initials}>{initials}</div>
        )}
      </div>
      {isDefined(actualAvailability) && (
        <PresenceDot
          actualAvailability={actualAvailability}
          customClasses={{ root: classes.presentDotRoot }}
        />
      )}
    </div>
  );
};
const mapStatesToProps = ({ user, auth, selectClient }) => {
  return {
    photo: user.photo,
    name: user.name,
    userId: user.connect30_user_id,
    mitelData: Optional(user.mitelData).or({}),
    currentUserClientId: auth.defaultClient.connect30_domain,
    currentLoggedUserId: auth.user.id,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withUserCheck,
  withStyles(styles)
)(UserPhoto);
