import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_DATALIMIT = 'SET_DATALIMIT';
const RESET_DATALIMIT = 'RESET_DATALIMIT';

export const actionTypes = createReduxActionTypes('DATALIMIT_STATS', [
  SET_DATALIMIT,
  RESET_DATALIMIT
]);

const DEFAULT_STATE = {
    datalimit: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_DATALIMIT]: {
      const { datalimit } = action;

      return { ...state, datalimit};
    }
    case actionTypes[RESET_DATALIMIT]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setDatalimit: datalimit => ({ type: actionTypes[SET_DATALIMIT], datalimit }),
  resetDatalimitStats: () => ({ type: actionTypes[RESET_DATALIMIT] })
};