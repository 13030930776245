import XMLParser from 'react-xml-parser';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';

export default function eventListener({
  mitelToken,
  baseUrl,
  clientDomain,
  userId
}) {
  const sse = new EventSource(
    `${baseUrl}/api/contacts/v1/info/v1/${clientDomain}/${userId}/presence?t=${mitelToken}`
  );

  sse.onmessage = e =>
    store.dispatch(reduxActions.setUserContacts(getRealtimeData(e.data)));
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
  return () => {
    sse.close();
  };
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);

  const updatedActivityId = Optional(
    xml.getElementsByTagName('telepo:activity')[0]
  )
    .map(data => data.value)
    .or(void 0);

  const updatedActivityAttributes = Optional(
    xml.getElementsByTagName('rpid:activities')[0]
  )
    .map(activities => activities.attributes)
    .or(void 0);

  const entity = Optional(xml?.attributes?.entity)
    .map(data => data.split(':')[1])
    .or(void 0);

  const updatedNote = Optional(xml.getElementsByTagName('dm:note')[0])
    .map(data => data.value)
    .or(void 0);

  const contacts = store.getState().userContacts.contacts;
  const activities = store.getState().userContacts.activities;

  const updatedActivity = activities.find(el => el.id === updatedActivityId);

  const available = Optional(updatedActivityAttributes?.available)
    .map(available => (available === 'true' ? true : false))
    .or(updatedActivity?.available);

  const updatedContacts = contacts.map(contact => {
    if (contact.id !== entity) {
      return contact;
    }

    const { presence = {} } = contact;

    return {
      ...contact,
      presence: {
        ...presence,
        note: updatedNote,
        available: Optional(available).or(presence.available),
        activity: {
          ...Optional(updatedActivity).or(presence.activity),
          expiration: Optional(updatedActivityAttributes?.until).or(
            presence.activity?.expiration
          )
        }
      }
    };
  });

  return { activities, contacts: updatedContacts };
}
