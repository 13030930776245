import { withStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';

import CustomAddressSelect from '../../partials/customAddressSelect/CustomAddressSelect';
import InputRow from '../../partials/dataRows/InputRow';
import SelectRow from '../../partials/dataRows/SelectRow';
import styles, { customStyles, customCheckboxeStyles } from './styles';
import { getPhoneBookStatuses } from '../../consts/selectsOptions';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import { translate } from '../../i18n/I18nProvider';
import CreateAddress from '../../partials/createAddress/CreateAddress';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import SecondaryButton from '../../partials/customButtons/SecondaryButton';
import editPhoneBookRegistration from './action/editPhoneBookRegistration';
import getPhoneBookRegistration from './action/getPhoneBookRegistration';
import { DEFAULT_BOOK_DATA } from '../../consts/initialValues';
import { doesArrayHasLength, isArray, isDefined } from '../../utils/isDefined';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import Select from 'react-select';
import flowRight from 'lodash.flowright';
import withSettings from '../../utils/withSettings';
import { getData } from '../../utils/http';
import { JSONAPI_URLS, URLS } from '../../consts/endpoints';
import urlCreator from '../../utils/urlCreator';
import CheckboxRow from '../../partials/dataRows/CheckboxRow';
import { connect } from 'react-redux';
import clsx from 'clsx';
import validateSchema from './action/validateSchema';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';
import ConfirmDialog from '../../partials/confirmationDialog/ConfirmDialog';
import deletePhonebookStatus from '../../pages/phoneBookRegistration/actions/deletePhonebookStatus';
import DeletePhonebookStatusConfimation from '../../pages/phoneBookRegistration/components/DeletePhonebookStatusConfimation';
import Optional from '../../utils/optional';
import { mapAddresses } from '../../partials/customAddressSelect/actions/getClientAddresses';

const ALL_NUMBERS_VALUE = 'all';

const EditPhoneBookStatus = ({
  classes,
  number,
  children,
  setReload = () => {},
  closeMenuOnDialogOpen = () => {},
  reload,
  numberRangeId,
  numberRangeForSelect = void 0,
  clientId,
  settings,
  shouldFetchClient,
  isMultiedit = false,
  numbersForMultiedit = void 0,
  inProgress,
  registrationData,
  query,
  allSelected
}) => {
  const [open, setOpen] = useState(false);
  const [numbersOptions, setNumbersOptions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState({
    value: number,
    label: number
  });
  const [phoneBookData, setPhoneBookData] = useState(DEFAULT_BOOK_DATA);
  const [customerId, setCustomerId] = useState(clientId);
  const [multieditNumbers, setMultieditNumbers] = useState(void 0);
  const [defaultClientAddress, setDefaultClientAddress] = useState(void 0);
  const [defaultClientName, setDefaultClientName] = useState(void 0);
  
  useEffect(() => {
    setMultieditNumbers(numbersForMultiedit);
    if (shouldFetchClient && open) {
      (async function() {
        const response = await getData(
          urlCreator(`${URLS.EXTERNAL_NO_RANGES}/${numberRangeId}.json`, {
            include: 'client',
            fields: { client: 'id' }
          })
        );
        if (isDefined(response?.data?.client?.id)) {
          setCustomerId(response?.data?.client?.id);
        }
      })();
    }
    if (allSelected && open) {
      (async function() {
        const response = await getData(
          urlCreator(JSONAPI_URLS.PHONE_BOOK_REGISTRATION, {
            filter: { client_id: clientId },
            page: { size: query.totalCount, number: 1 }
          })
        );
        if (isDefined(response?.data)) {
          setMultieditNumbers(response?.data);
        }
      })();
    }
  }, [
    shouldFetchClient,
    numberRangeId,
    open,
    allSelected,
    numbersForMultiedit,
    clientId,
    query
  ]);

  const onOpenDialog = useCallback(async () => {
    closeMenuOnDialogOpen();
    if (isArray(numberRangeForSelect)) {
      const options = mapNumberRange(numberRangeForSelect);

      setNumbersOptions(options);
    }

    if (isMultiedit) {
      await fetchPhoneBookData(null, customerId, true);
    } else {
      await fetchPhoneBookData(number, customerId);
    }

    setOpen(true);
    // eslint-disable-next-line
  }, [closeMenuOnDialogOpen, number, customerId]);

  const onCloseDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(
    async values => {
      const isRequestDone = await editPhoneBookRegistration({
        values,
        numberRangeId,
        phoneNumber: phoneNumber.value,
        phoneBookData,
        isMultiedit,
        numbersForMultiedit: multieditNumbers,
        registrationData,
        defaultClientAddress,
        allNumbersArray:
          isArray(numberRangeForSelect) &&
          phoneNumber?.value === ALL_NUMBERS_VALUE
            ? numberRangeForSelect
            : void 0
      });

      if (isRequestDone) {
        setReload(!reload);
        onCloseDialog();
      }
    },
    [
      numberRangeId,
      phoneNumber,
      phoneBookData,
      onCloseDialog,
      setReload,
      reload,
      numberRangeForSelect,
      multieditNumbers,
      isMultiedit,
      registrationData,
      defaultClientAddress
    ]
  );

  const handleChange = useCallback(
    async selected => {
      setPhoneNumber(selected);

      if (
        selected.value !== phoneNumber &&
        selected.value !== ALL_NUMBERS_VALUE
      ) {
        setPhoneBookData(DEFAULT_BOOK_DATA);
        await fetchPhoneBookData(selected.value, customerId);
      }
    },
    // eslint-disable-next-line
    [phoneNumber, customerId]
  );

  const fetchPhoneBookData = async (value, customerId, onlyClient) => {
    const response = await getPhoneBookRegistration(
      value,
      customerId,
      onlyClient
    );

    if (isDefined(response)) {
      const { status, defaultAddress, defaultName } = response;

      if (isDefined(status)) {
        setPhoneBookData(status);
      }

      if (isDefined(defaultAddress)) {
        setDefaultClientAddress(defaultAddress);
      }

      if (isDefined(defaultName)) {
        setDefaultClientName(defaultName);
      }
    }
  };

  const onDeleteClick = useCallback(async () => {
    const requestComplete = await deletePhonebookStatus(phoneBookData.id);

    if (requestComplete) {
      setPhoneBookData(DEFAULT_BOOK_DATA);
    }
  }, [phoneBookData.id]);

  const defaultAddress = useMemo(() => {
    if (isDefined(defaultClientAddress)) {
      const mappedAddress = mapAddresses([defaultClientAddress], true);
      if (isDefined(mappedAddress[1])) {
        return mappedAddress[1].label;
      }

      return '';
    }
    return '';
  }, [defaultClientAddress]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        {inProgress && (
          <div className={classes.tableOverlay}>
            <CircularProgress />
          </div>
        )}
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{translate('PHONE_BOOK.TITLE')}</div>
            {doesArrayHasLength(numbersOptions) ? (
              <Select
                customStyles={{
                  ...customStyles,
                  control: {
                    ...customStyles.control,
                    backgroundColor: settings.colors.modal_header
                  },
                  singleValue: {
                    color: settings.colors.modal_header_text
                  },
                  indicatorContainer: {
                    color: settings.colors.modal_header_text
                  }
                }}
                styles={reactSelectDefaultStyles}
                value={phoneNumber}
                onChange={handleChange}
                options={numbersOptions}
                maxMenuHeight="150px"
                hideSelectedOptions={true}
              />
            ) : (
              phoneNumber.value
            )}
          </div>
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isMultiedit && isArray(multieditNumbers) && (
            <div className={classes.numbersList}>
              <div>
                {`${multieditNumbers.length} ${translate('NUMBERS_SELECTED')}`}{' '}
              </div>
              <Tooltip
                placement="bottom"
                title={multieditNumbers.map(el => el.number).join(', ')}
                className={classes.tooltip}
              >
                <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
              </Tooltip>
            </div>
          )}
          <Formik
            initialValues={{
              ...phoneBookData,
              organization_name: isDefined(phoneBookData?.id)
                ? phoneBookData.organization_name
                : Optional(defaultClientName).or('')
            }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}
            validationSchema={() => validateSchema(isMultiedit)}
          >
            {({
              handleSubmit,
              setFieldValue,
              errors,
              values,
              handleBlur,
              handleChange,
              dirty
            }) => {
              const fieldProps = {
                errors,
                values,
                handleBlur,
                handleChange,
                setFieldValue
              };

              const setAddresId = id => {
                setFieldValue('address', id);
                setFieldValue('reload', true);
                setFieldValue('reload', false);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.dataContainer}>
                    <div>
                      {false && (
                        <CheckboxRow
                          label="PHONE_BOOK.ALL_NUMBERS"
                          name="allNumbers"
                          customStyles={customCheckboxeStyles}
                          {...fieldProps}
                        />
                      )}
                      <SelectRow
                        name="protection_code"
                        label="STATUS"
                        isEditModal={false}
                        options={getPhoneBookStatuses()}
                        {...fieldProps}
                      />
                      <InputRow
                        name="person_given_name"
                        label="FIRST_NAME"
                        {...fieldProps}
                      />

                      <InputRow
                        name="person_middle_name"
                        label="MIDDLE_NAME"
                        {...fieldProps}
                      />
                      <InputRow
                        name="person_surname_name"
                        label="LAST_NAME"
                        {...fieldProps}
                      />
                      <InputRow
                        name="organization_name"
                        label="COMPANY_NAME"
                        {...fieldProps}
                      />
                      <InputRow
                        name="care_of_name"
                        labelText="C/O"
                        isMultiline={true}
                        maxRows={2}
                        {...fieldProps}
                      />
                    </div>
                    <div className={classes.column}>
                      <div>
                        <div className={classes.defaultAddressContainer}>
                          <div>{translate('PHONE_BOOK.DEFAULT_ADDRESS')}</div>
                          <div className={classes.defaultAddress}>
                            {defaultAddress}
                          </div>
                        </div>
                        <div>
                          <CheckboxRow
                            label="PHONE_BOOK.USE_DEFAULT_ADDRESS"
                            name="use_default_address"
                            customStyles={customCheckboxeStyles}
                            {...fieldProps}
                          />
                        </div>
                      </div>
                      {isDefined(customerId) && (
                        <CustomAddressSelect
                          selectName="address"
                          customLabel="ORDER_SIMCARD.SELECT_ADDRESS"
                          fieldProps={fieldProps}
                          canAddAddress={false}
                          idAsValue={true}
                          splitLines={true}
                          clientId={customerId}
                          reload={values.reload}
                          disableReload={true}
                          additionalValues={[]}
                          disabled={values.use_default_address}
                        />
                      )}
                      <CreateAddress
                        fromTable={false}
                        customAction={id => setAddresId(id)}
                      >
                        <PrimaryButton
                          maxWidth="200px"
                          disabled={values.use_default_address}
                        >
                          {translate('BUTTON.NEW_ADDRESS')}
                        </PrimaryButton>
                      </CreateAddress>
                      <div className={classes.infoContainer}>
                        {translate('PHONE_BOOK.INFO')}
                      </div>
                    </div>
                  </div>
                  <footer className={classes.footer}>
                    <PrimaryButton
                      onClick={handleSubmit}
                      disabled={
                        isDefined(phoneBookData?.id) &&
                        !dirty &&
                        phoneNumber?.value !== ALL_NUMBERS_VALUE
                      }
                    >
                      {translate('BUTTON.SAVE')}
                    </PrimaryButton>
                    <SecondaryButton onClick={onCloseDialog}>
                      {translate('BUTTON.CANCEL')}
                    </SecondaryButton>
                    {isDefined(phoneBookData?.id) &&
                      can(
                        'delete',
                        createSubject('PhoneBookRegistration', {
                          external_number_range_id:
                            phoneBookData.external_number_range_id
                        })
                      ) && (
                        <ConfirmDialog
                          onConfirmClick={onDeleteClick}
                          dialogContentComponent={
                            <DeletePhonebookStatusConfimation />
                          }
                          dialogTitleIntlId={'TOOLTIP.DELETE_PHONEBOOK'}
                        >
                          <PrimaryButton
                            disabled={phoneNumber?.value === ALL_NUMBERS_VALUE}
                          >
                            {translate('TOOLTIP.DELETE')}
                          </PrimaryButton>
                        </ConfirmDialog>
                      )}
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ loader }) => {
  return {
    inProgress: loader.isSpinVisible
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(EditPhoneBookStatus);

const mapNumberRange = (numbers = []) => {
  const [start, end] = numbers;

  let numberOptions = [];

  for (let i = start; i <= end; i++) {
    numberOptions = [...numberOptions, { value: i, label: i }];
  }

  return [...numberOptions, { value: ALL_NUMBERS_VALUE, label: 'All numbers' }];
};
