import React, { useRef, useEffect, useMemo } from 'react';
import * as dawaAutocomplete2 from 'dawa-autocomplete2';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/dawaAutocomplete';
import CustomIconButton from '../../tableCustomButtons/CustomIconButton';
import withSettings from '../../../utils/withSettings';
import { isDefined } from '../../../utils/isDefined';

export function DawaAutocomplete({
  classes,
  setValues,
  intl,
  resetForm,
  isDanishAddress,
  settings
}) {
  const inputElem = useRef(null);

  useEffect(() => {
    if (inputElem.current) {
      dawaAutocomplete2.dawaAutocomplete(inputElem.current, {
        select: selected => {
          const mappedAddress = mapAddress(selected.data);
          setValues({ ...mappedAddress, isDanishAddress: true });
        },
        autoFocus: true
      });
    }
  }, [setValues]);

  const iconCustomStyles = useMemo(() => {
    return {
      color: settings.colors.primary,
      fontSize: '24px'
    };
  }, [settings]);

  const onClearClick = () => {
    inputElem.current.value = '';
    resetForm();
  };

  return (
    <div
      className={clsx(classes.autocompleteContainer, {
        [classes.hidden]: !isDanishAddress
      })}
    >
      <input
        placeholder={intl.formatMessage({ id: 'ADDRESS.ADD_PLACEHOLDER' })}
        ref={inputElem}
        type="text"
      />
      <CustomIconButton
        onClick={onClearClick}
        iconClassName="far fa-window-close"
        customStyles={iconCustomStyles}
      />
    </div>
  );
}

export default flowRight(
  withSettings,
  injectIntl,
  withStyles(styles)
)(DawaAutocomplete);

function mapAddress(address) {
  let newAddress = { ...address };

  if (isDefined(address.etage)) {
    newAddress.etage = `${address.etage}.`;
  }

  return newAddress;
}
