export default {
  root: {
    position: 'fixed',
    top: '0',
    right: '-500px',
    zIndex: '100',
    width: '420px',
    height: '100vh',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '0 10px',
    boxShadow:
      ' 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    transition: 'right 1s ease-out 3s'
  },
  header: {
    backgroundColor: '#FFFFFF',
    height: '50px',
    color: '#5D78FF',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '3px solid #EEF1FF',
    alignItems: 'center'
  },
  headerText: { width: '90%', textAlign: 'center', fontWeight: '600' },
  content: {
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    flex: 'none',
    flexWrap: 'wrap',
    height: '80vh'
  },
  footer: {
    marginTop: '20px',
    width: '100%',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-around'
  },
  saveButton: {
    color: '#fff',
    backgroundColor: '#5D78FF',
    '&:hover': { backgroundColor: '#0ABB87' }
  },
  fieldContainer: {
    margin: '0 5px',
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  fieldLabel: {
    color: '#6C7293',
    fontSize: '11px'
  },
  fieldValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #EEF1FF'
  },
  checkbox: { border: 'none' },
  openedView: {
    right: '0'
  },
  textfield: {
    border: 'none'
  },
  closeIcon: {
    fontSize: '1.5rem',
    marginLeft: '20px',
    cursor: 'pointer',
    color: '#A7ABC3',
    '&:hover': {
      color: '#5D78FF'
    }
  }
};
