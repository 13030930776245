import { OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

const deleteCdrHooks = async ({ connectClientId, id }) => {
  const response = await deleteData({
    url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/cdr_hooks/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.DELETE_CDR_HOOK',
      messageType: 'success'
    });

    return true;
  }

  return false;
};

export default deleteCdrHooks;
