const styles = theme => {
  return {
    root: {
      width: '600px',
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        width: '100%'
      }
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '13px',
      minHeight: '475px',
      paddingTop: '5px',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        padding: '5px'
      }
    },
    oneOption: { justifyContent: 'center' },
    buttonsRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      margin: '20px 0'
    },
    smallView: {
      minHeight: 'fit-content'
    },
    loaderOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;
