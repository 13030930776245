import React from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/queue';
import QueuesPieChart from './QueuesPieChart';
import { isDefined } from '../../../utils/isDefined';
import QueueStatRow from '../../../partials/queueStatRow/QueueStatRow';
import { mapCellNumber } from '../actions/tableColumnsActions';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';

const CUSTOM_ROW_STYLES = {
  root: { width: '220px' },
  value: { width: '140px' }
};

export function Queue({ classes, queue = {}, intl }) {
  return (
    <div className={classes.root}>
      <div className={clsx(classes.stats, classes.info)}>
        <QueueStatRow
          label="NAME"
          value={queue.name}
          customStyles={CUSTOM_ROW_STYLES}
          infoIcon={false}
        />
        <QueueStatRow
          label="QUEUES.NUMBER"
          value={
            <CopyToClipbaordTooltip value={mapCellNumber(queue.number)}>
              {mapCellNumber(queue.number)}
            </CopyToClipbaordTooltip>
          }
          customStyles={CUSTOM_ROW_STYLES}
          infoIcon={false}
        />
        <QueueStatRow
          label="QUEUES.DOMAIN"
          value={queue.domain}
          customStyles={CUSTOM_ROW_STYLES}
          infoIcon={false}
        />
      </div>

      <div className={classes.stats}>
        <QueueStatRow
          label="QUEUES.QUEUE_LENGTH"
          value={queue.queueLength}
          helpTooltipIntlKey="QUEUES.TOOLTIP.QUEUE_LENGTH"
        />
        <QueueStatRow
          label="QUEUES.AVAILABLE"
          value={queue.availableAgents}
          helpTooltipIntlKey="QUEUES.TOOLTIP.AVAILABLE_AGENTS"
        />
        <QueueStatRow
          label="QUEUES.ENROLLED"
          value={queue.loggedInAgents}
          helpTooltipIntlKey="QUEUES.TOOLTIP.LOGGED_IN_AGENTS"
        />
        <QueueStatRow
          label="QUEUES.LONGEST_QUEUE"
          helpTooltipIntlKey="QUEUES.TOOLTIP.LONGEST_WAITING"
          value={
            isDefined(queue.longestWaiting)
              ? `${queue.longestWaiting} sec.`
              : ''
          }
        />
        <QueueStatRow
          label="QUEUES.LAST_WAIT"
          helpTooltipIntlKey="QUEUES.TOOLTIP.LAST_WAIT"
          value={isDefined(queue.lastWait) ? `${queue.lastWait} sec.` : ''}
        />
        <QueueStatRow
          label="QUEUES.AVG_WAIT"
          helpTooltipIntlKey="QUEUES.TOOLTIP.AVERAGE_WAIT"
          value={
            isDefined(queue.averageWait) ? `${queue.averageWait} sec.` : ''
          }
        />
      </div>
      <QueuesPieChart queue={queue} />
      <div className={classes.stats}>
        <QueueStatRow
          label="QUEUES.TOTAL_CALLS_LAST_24"
          helpTooltipIntlKey="QUEUES.TOOLTIP.TOTAL_CALLS_24H"
          value={queue.totalCallsLast24h}
        />

        <QueueStatRow
          label="QUEUES.ANSWERED_CALLS_LAST_24"
          helpTooltipIntlKey="QUEUES.TOOLTIP.ANSWERED_CALLS_24H"
          value={queue.answeredCallsLast24h}
        />
        <QueueStatRow
          label="QUEUES.UNANSWERED_CALLS_LAST_24"
          helpTooltipIntlKey="QUEUES.TOOLTIP.DROPPED_CALLS_24H"
          value={queue.droppedCallsLast24h}
        />
      </div>
    </div>
  );
}

const mapStatesToProps = ({ queueStats }) => {
  return {
    queue: queueStats.queue
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(Queue);
