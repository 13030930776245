import { withStyles } from '@material-ui/styles';
import React from 'react';
import { Tooltip } from '@material-ui/core';

import styles from '../styles/deliveries';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import withSettings from '../../../utils/withSettings';
import { formatDateUTC } from '../../../utils/formatDate';

const Deliveries = ({ classes, deliveries = [], settings }) => {
  return (
    <div className={classes.root}>
      {deliveries.map(el => {
        const icon = checkType(el.deliveryType);

        const { statusIcon, statusColor, tooltipText } = checkStatus({
          el,
          settings
        });

        return (
          <Tooltip title={tooltipText} key={el.id}>
            <div className={classes.deliveryRoot}>
              <i className={clsx(classes.icon, icon)} />
              <div className={classes.statusContainer}>
                <i
                  className={clsx(classes.status, statusIcon)}
                  style={{
                    color: statusColor
                  }}
                />
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(Deliveries);

const checkStatus = ({ el = {}, settings }) => {
  const { deliveryStatus, exceptionMessage, deliveryDate } = el;

  switch (deliveryStatus) {
    case 'DELIVERED': {
      return {
        statusIcon: 'fas fa-check-circle',
        statusColor: settings.colors.success,
        tooltipText: formatDateUTC(deliveryDate)
      };
    }
    case 'FAILED': {
      return {
        statusIcon: 'fas fa-times-circle',
        statusColor: settings.colors.danger,
        tooltipText: exceptionMessage
      };
    }
    default: {
      return {
        statusIcon: 'fas fa-exclamation-circle',
        statusColor: settings.colors.warning,
        tooltipText: deliveryStatus.replaceAll('_', ' ')
      };
    }
  }
};

const checkType = type => {
  switch (type) {
    case 'EMAIL': {
      return 'fal fa-envelope';
    }
    case 'EAN': {
      return 'fal fa-money-check';
    }
    case 'BETALINGS_SERVICE': {
      return 'fal fa-credit-card';
    }
    default: {
      return 'fal fa-file-alt';
    }
  }
};
