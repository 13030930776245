import { call } from 'redux-saga/effects';
import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import { takeLatest } from '../../../utils/reduxSaga';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';

import { actions as sagaActions, SYNC_ACTION } from './types';

export function syncJob({ clientId, action }) {
  return {
    type: sagaActions[SYNC_ACTION],
    clientId,
    action
  };
}

export function* syncAction({ clientId, action }) {
  const response = yield call(postData, {
    url: `${URLS.CLIENTS}/${clientId}/${action}`,
    data: { id: Number(clientId) }
  });

  if (response.status === RESPONSE_CODES.ACCEPTED) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.SYNC_SUCCESFULL',
      messageType: 'success'
    });
  }
}

function* watchSyncAction() {
  yield takeLatest(sagaActions[SYNC_ACTION], syncAction);
}

export default watchSyncAction();
