const styles = theme => {
  return {
    actionButtons: {
      display: 'flex',
      '& i': {
        fontSize: '18px',
        margin: '5px 10px',
        cursor: 'pointer'
      }
    },
    actionCell: { padding: '0 5px' }
  };
};

export default styles;
