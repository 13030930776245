import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL';
const CLOSE_RIGHT_PANEL = 'CLOSE_RIGHT_PANEL';
const SET_NUMBER_DATA = 'SET_NUMBER_DATA';
const RESET_RIGHT_PANEL = 'RESET_RIGHT_PANEL';
const RESET_RIGHT_PANEL_DATA = 'RESET_RIGHT_PANEL_DATA';
const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
const SET_HISTORY = 'SET_HISTORY';

export const actionTypes = createReduxActionTypes('RIGHT_PANEL', [
  OPEN_RIGHT_PANEL,
  CLOSE_RIGHT_PANEL,
  SET_NUMBER_DATA,
  RESET_RIGHT_PANEL_DATA,
  RESET_RIGHT_PANEL,
  SET_SEARCH_TYPE,
  SET_HISTORY
]);

const DEFAULT_STATE = {
  open: false,
  number: {},
  history: [],
  searchType: 'Number'
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_RIGHT_PANEL]: {
      return {
        ...state,
        open: true
      };
    }
    case actionTypes[SET_NUMBER_DATA]: {
      const { number } = action;

      return { ...state, number: { ...state.number, ...number } };
    }
    case actionTypes[CLOSE_RIGHT_PANEL]: {
      return {
        ...state,
        open: false
      };
    }
    case actionTypes[RESET_RIGHT_PANEL_DATA]: {
      return {
        ...DEFAULT_STATE,
        open: state.open,
        history: state.history,
        searchType : state.searchType
      };
    }
    case actionTypes[SET_HISTORY]: {
      const { history } = action;

      return { ...state, history };
    }
    case actionTypes[SET_SEARCH_TYPE]: {
      const { searchType } = action;

      return { ...state, searchType };
    }
    case actionTypes[RESET_RIGHT_PANEL]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openRightPanel: () => ({
    type: actionTypes[OPEN_RIGHT_PANEL]
  }),
  setNumberData: number => ({
    type: actionTypes[SET_NUMBER_DATA],
    number
  }),
  closeRightPanel: () => ({
    type: actionTypes[CLOSE_RIGHT_PANEL]
  }),
  resetRightPanelData: () => ({
    type: actionTypes[RESET_RIGHT_PANEL_DATA]
  }),
  setHistory: history => ({
    type: actionTypes[SET_HISTORY],
    history
  }),
  setSearchType: searchType => ({
    type: actionTypes[SET_SEARCH_TYPE],
    searchType
  }),
  resetRightPanel: () => ({
    type: actionTypes[RESET_RIGHT_PANEL]
  })
};
