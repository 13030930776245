import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_ROLES } from './types';
import { actions as reduxActions, getters } from '../reducers';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { isArrayEmpty, isDefined } from '../../../utils/isDefined';
import getFullTableData from '../../../utils/getFullTableData';

export function getAllRoles() {
  return {
    type: sagaActions[GET_ROLES]
  };
}

export function* getRoles() {
  yield put(loaderReduxActions.startSpinLoader());
  const QUERY_PARAMS = {
    include: 'permissions'
  };

  const roles = yield call(getFullTableData, JSONAPI_URLS.ROLES, QUERY_PARAMS);

  const prevRoles = yield select(getters.getRoles);

  const mappedRoles = yield call(mergeRoles, { roles, prevRoles });

  yield put(reduxActions.setRoles(mappedRoles));
  yield put(loaderReduxActions.stopSpinLoader());
}

function* watchGetRoles() {
  yield takeLatest(sagaActions[GET_ROLES], getRoles);
}

export default watchGetRoles();

export function mergeRoles({ roles, prevRoles }) {
  if (isArrayEmpty(prevRoles)) {
    return roles;
  }

  return roles.map(item => {
    const oldData = prevRoles.find(elem => elem.id === item.id);
    return {
      ...item,
      tableData: isDefined(oldData) ? { ...oldData.tableData } : {}
    };
  });
}
