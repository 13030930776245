const styles = () => {
  return {
    root: {
      width: '100%',
      borderTop: '1px solid #F3F3F3',
      marginTop: '10px',
      paddingTop: '10px'
    }
  };
};

export default styles;
