import React, { useState, cloneElement } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { connect } from 'react-redux';

import styles from '../styles';
import ChangePhoto from '../../../partials/changePhoto/ChangePhoto';
import DraggableDialog from '../../../partials/DraggableDialog';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import { translate } from '../../../i18n/I18nProvider';

const ChangePhotoDialog = ({
  classes,
  children,
  onOpenClick,
  photo,
  connect30_user_id
}) => {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    onOpenClick();
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('CHANGE_PHOTO')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ChangePhoto
            fromDialog={true}
            dialogPhoto={photo}
            dialogConnectId={connect30_user_id}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ user }) => {
  const { photo, connect30_user_id } = user;
  return {
    photo,
    connect30_user_id
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(ChangePhotoDialog);
