import camelToUnderscore from './camelToUndescore';
import { isArray, isObject } from './isDefined';

export default function changeObjectKeysCamelToUnderscore(obj) {
  const objectKeys = Object.keys(obj);

  return objectKeys.reduce((newObj, key) => {
    return { ...newObj, [`${camelToUnderscore(key)}`]: mapValue(obj[key]) };
  }, {});
}

function mapValue(value) {
  if (isObject(value)) {
    return changeObjectKeysCamelToUnderscore(value);
  }

  if (isArray(value)) {
    return value.map(el => mapValue(el));
  }

  return value;
}
