import { isArray, isNumber, isObject, isUndefined } from './isDefined';

export default function customTableColumnSort(
  obj = {},
  nextObj = {},
  objKeysString = ''
) {
  const valueA = getValue(obj, objKeysString);
  const valueB = getValue(nextObj, objKeysString);

  if (isUndefined(valueA) || isObject(valueA) || isArray(valueA)) {
    return -1;
  }

  if (isUndefined(valueB) || isObject(valueB) || isArray(valueB)) {
    return 1;
  }

  return (isNumber(valueA) ? valueA : valueA.toLowerCase()) >
    (isNumber(valueB) ? valueB : valueB.toLowerCase())
    ? 1
    : -1;
}

function getValue(dataObj = {}, objKeysString = '') {
  if (!isObject(dataObj)) {
    return void 0;
  }

  return objKeysString.split('.').reduce((obj, key) => {
    return obj[key];
  }, dataObj);
}
