import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import InputRow from '../../../partials/dataRows/InputRow';

export function AddressData({ classes, form, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <InputRow label="TABLE.STREET" name="street" {...form} />
          <InputRow label="ADDRESS.POSTAL_CODE" name="postalcode" {...form} />
          <InputRow label="TABLE.CITY" name="city" {...form} />
          <InputRow label="TABLE.COUNTRY" name="country" {...form} />
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(AddressData);
