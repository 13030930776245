import React, { useState, cloneElement } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { translate } from "../../../i18n/I18nProvider";
import can from "../../../utils/can";
import createSubject from "../../../utils/createSubject";

const MoreActionsMenu = ({ children, clientId, data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShowClick = () => {
    handleClose();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          {can(
            "read",
            createSubject("Quote::Subscription", {
              client_id: Number(clientId),
            })
          ) && (
            <Link
              to={{
                pathname: `/${clientId}/subscriptions`,
                state: { accountId: data?.id },
              }}
            >
              <MenuItem style={{ color: "black" }} onClick={onShowClick}>
                {translate("MENU.SUBSCRIPTIONS")}
              </MenuItem>
            </Link>
          )}
          {can(
            "read",
            createSubject("Quote::Invoice", {
              client_id: Number(clientId),
            })
          ) && (
            <Link
              to={{
                pathname: `/${clientId}/invoices`,
                state: { accountId: data?.id },
              }}
            >
              <MenuItem style={{ color: "black" }} onClick={onShowClick}>
                {translate("MENU.INVOICES")}
              </MenuItem>
            </Link>
          )}
        </div>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient?.selectedClient?.id,
  };
};

export default connect(mapStatesToProps)(MoreActionsMenu);
