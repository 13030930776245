const styles = theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    container: {
      width: '500px',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px',
      paddingBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '310px'
      }
    },
    title: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 10px',
      fontSize: '18px',
      borderBottom: '1px solid #EBEDF2'
    },
    settingsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      height: '100%'
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
      padding: '5px',
      textAlign: 'center'
    },
    passButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px'
    },
    message: {
      display: 'none',
      height: '20px',
      width: '100%',
      padding: '0 10%',
      textAlign: 'center'
    },
    messageVisible: {
      display: 'block'
    },
    customPhoto: {
      width: '90%',
      height: 'auto',
      borderRadius: 'unset',
      [theme.breakpoints.down('xs')]: {
        width: '200px',
        height: '200px'
      }
    }
  };
};

export default styles;
