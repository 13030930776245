import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import { Checkbox, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';

import styles from './styles';
import Optional from '../../utils/optional';
import { isDefined } from '../../utils/isDefined';

export function CheckboxRow({
  classes,
  name,
  label = 'EMPTY',
  labelText,
  intl,
  handleBlur,
  handleChange,
  customCheckFunction,
  values = {},
  customStyles = {},
  disabled = false,
  infoIcon = false,
  infoIconTooltipIntlKey = 'EMPTY',
  infoIconTooltipComponent
}) {
  const title = useMemo(
    () =>
      Optional(labelText).or(
        intl.formatMessage({
          id: label
        })
      ),
    // eslint-disable-next-line
    [labelText, label]
  );

  return (
    <div
      className={clsx(classes.root, classes.checkboxRoot)}
      style={customStyles.root}
    >
      <div className={classes.field} style={customStyles.field}>
        <div className={classes.label} style={customStyles.label}>
          {title}
          {infoIcon && (
            <Tooltip
              title={Optional(infoIconTooltipComponent).or(
                intl.formatMessage({
                  id: infoIconTooltipIntlKey
                })
              )}
            >
              <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
            </Tooltip>
          )}
        </div>
        <Checkbox
          name={name}
          checked={Optional(values[name]).or(false)}
          onBlur={handleBlur}
          onChange={
            isDefined(customCheckFunction) ? customCheckFunction : handleChange
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CheckboxRow);
