const styles = theme => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '260px',
      padding: '10px 0',
      color: '#000000'
    },
    labelContainer: { display: 'flex', alignItems: 'center' },
    value: {
      fontWeight: 700
    },
    infoIcon: {
      fontSize: '11px',
      marginLeft: '4px'
    }
  };
};

export default styles;
