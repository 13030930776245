import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import styles from '../styles/infoBox';
import { translate } from '../../../i18n/I18nProvider';

const InfoBox = ({ classes, customErrors = {} }) => {
  const { errorsArray = [] } = customErrors;

  return (
    <div className={classes.root}>
      {errorsArray.map(error => {
        return (
          <div key={error} className={classes.container}>
            {translate(error)}
          </div>
        );
      })}
    </div>
  );
};

const mapStatesToProps = ({ addingSchedules }) => {
  const { customErrors } = addingSchedules;
  return {
    customErrors
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(InfoBox);
