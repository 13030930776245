import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { LayoutSplashScreen } from "../../../_metronic";
import { logoutUser } from "./actions/logout";

function Logout({ hasAuthToken, logoutUser }) {
  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
}

const mapStatesToProps = ({ auth }) => ({
  hasAuthToken: Boolean(auth.authToken)
});

const mapDispatchToProps = {
  logoutUser
};

export default connect(mapStatesToProps, mapDispatchToProps)(Logout);
