import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

export default async function deleteCustomJob(id) {
  try {
    const response = await deleteData({
      url: `${URLS.CUSTOM_JOBS}/${id}`
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.CUSTOM_JOB_DELETED',
        messageType: 'success'
      });
    }
    return true;
  } catch {
    return false;
  }
}
