import React, { useState, useCallback, useEffect } from 'react';
import { TablePagination } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';

import { isDefined } from '../../../utils/isDefined';
import { withStyles } from '@material-ui/styles';
import { fetchChargeLines } from '../actions/getChargeLines';
import usePagination from '../../../utils/usePagination';
import styles from '../styles';
import LineGrouped from './LineGrouped';

function ChargeLines({
  chargeLineRowStyle,
  indentLvl = 1,
  details,
  intl,
  fetchChargeLines,
  clientId,
  classes,
  invoiceGrouping
}) {
  const [chargeLinesFetched, setChargeLineesFetched] = useState(false);
  const [chargeLines, setChargeLinees] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const paginated = usePagination(chargeLines, page, rowsPerPage);
  const onChangePage = useCallback((e, page) => setPage(page), [setPage]);
  const onChangeRowsPerPage = useCallback(e => setRowsPerPage(e.target.value), [
    setRowsPerPage
  ]);

  useEffect(() => {
    if (isDefined(details) && !chargeLinesFetched) {
      fetchChargeLines({
        clientId,
        details,
        setChargeLinees,
        setChargeLineesFetched,
        invoiceGrouping: invoiceGrouping.value
      });
    }
    // eslint-disable-next-line
  }, [clientId, details, invoiceGrouping]);

  return (
    <div>
      {paginated.map((line, idx) => (
        <LineGrouped
          chargeLineRowStyle={chargeLineRowStyle}
          intl={intl}
          key={idx}
          line={line}
          indentLvl={indentLvl}
        />
      ))}
      {chargeLines.length > 10 ? (
        <TablePagination
          className={classes.chargeLineRowPagination}
          component="div"
          count={chargeLines.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  fetchChargeLines
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ChargeLines);
