import Optional from '../../../utils/optional';

export default function mapSettingsToStore(settings = {}) {
  const frontendUrl = Optional(settings.frontend_url).or('');
  const displayName = Optional(settings.display_name).or('');
  const letterFooter = Optional(settings.letter_footer).or('');
  const helpdeskURL = Optional(settings.helpdesk_url).or('');
  const websiteURL = Optional(settings.website_url).or('');
  const supportPhoneNumber = Optional(settings.support_phone_number).or('');
  const customerServiceEmail = Optional(settings.customer_service_email).or('');
  const frontendSettings = Optional(settings.frontend_settings).or({});
  const microsoftSettings = Optional(settings.microsoft_graph_config).or({});

  const { logos = {}, colors = {}, ...restFrontendSettings } = frontendSettings;

  return {
    ...restFrontendSettings,
    ...microsoftSettings,
    frontendUrl,
    customerServiceEmail,
    displayName,
    letterFooter,
    helpdeskURL,
    supportPhoneNumber,
    websiteURL,
    ...logos,
    ...colors
  };
}
