import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import { getData } from '../../../utils/http';

export async function getLink() {
  const {
    mitelToken,
    baseUrl,
    clientDomain,
    mitelUserId
  } = getMitelRequestProps();

  let url = null;
  try {
    const { value } = await getData(
      `${baseUrl}/api/config/v1/user/userprofile/${clientDomain}/${mitelUserId}?t=${mitelToken}`
    );

    url = value;
  } catch {}

  return url;
}
