import React from 'react';
import { findPhonebookStatuses } from '../../../consts/initialValues';
import { translate } from '../../../i18n/I18nProvider';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import Optional from '../../../utils/optional';
import PhoneBookActionButtons from './PhoneBookActionButtons';
import styles from '../styles';
import { withStyles } from '@material-ui/styles';

const ConnectedPhonebookRegistrationTable = ({ data = [], classes, refresh, setRefresh }) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.actionButtoins}></TableCell>
            <TableCell>{translate('TABLE.NUMBER')}</TableCell>
            <TableCell>{translate('STATUS')}</TableCell>
            <TableCell>{translate('NAME')}</TableCell>
            <TableCell>{translate('COMPANY')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(el => {
            return (
              <TableRow key={el.id}>
                <TableCell className={classes.actionButtons}>
                  <PhoneBookActionButtons
                    data={el}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </TableCell>
                <TableCell>{el.number}</TableCell>
                <TableCell>
                  {findPhonebookStatuses(el.protection_code)}
                </TableCell>
                <TableCell>
                  {`${Optional(el.person_given_name).or('')} ${Optional(
                    el.person_middle_name
                  ).or('')} ${Optional(el.person_surname_name).or('')}`}
                </TableCell>
                <TableCell>{el.organization_name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(ConnectedPhonebookRegistrationTable);
