import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

import calculateMbToGb from '../../../utils/calculateMbToGb';
import Optional from '../../../utils/optional';
import DataRow from '../../Datarow/DataRow';
import styles, { CUSTOM_STYLES } from '../styles';

function Info({ intl, classes, datalimit = {} }) {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'INFO'
          })}
        </div>
      </div>
      <div className={classes.columnContainer}>
        <DataRow
          label={`${intl.formatMessage({
            id: 'NUMBER.DATA_LIMIT'
          })} `}
          value={
            datalimit.no_limit
              ? intl.formatMessage({
                  id: 'DATA_LIMITS.NO_DATALIMIT'
                })
              : Optional(datalimit.block_limit_mb)
                  .map(value => `${calculateMbToGb(value, true)} GB`)
                  .or(null)
          }
          customStyles={CUSTOM_STYLES.infoDataRow}
          dense
          mobileRightAllignValue
        />
        {datalimit.roaming_disabled === false && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'DATA_LIMITS.ROAMING_EU_LIMIT'
            })} `}
            value={
              datalimit.no_limit
                ? intl.formatMessage({
                    id: 'DATA_LIMITS.NO_DATALIMIT'
                  })
                : Optional(datalimit.fair_usage_limit)
                    .map(value => `${calculateMbToGb(value, true)} GB`)
                    .or(null)
            }
            customStyles={CUSTOM_STYLES.infoDataRow}
            dense
            mobileRightAllignValue
          />
        )}
        {!datalimit.no_limit && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'NUMBER.AUTO_REFILL'
            })} `}
            value={datalimit.allow_recharge}
            dense
            customStyles={CUSTOM_STYLES.infoDataRow}
            isValueBoolean
            mobileRightAllignValue
          />
        )}
        <DataRow
          label={`${intl.formatMessage({
            id: 'NUMBER.ROAMING_BLOCK'
          })} `}
          value={datalimit.roaming_disabled}
          dense
          customStyles={CUSTOM_STYLES.infoDataRow}
          isValueBoolean
          mobileRightAllignValue
        />
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Info);
