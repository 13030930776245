import orderBy from 'lodash.orderby';

import createInitials from '../../../utils/createUserInitials';

export default function mapAgents(agents = []) {
  return [
    ...orderBy(
      agents.map(agent => {
        const { firstName = '', lastName = '' } = agent;
        const initials = createInitials(firstName, lastName);
        return { ...agent, initials };
      })
    ,['firstName'])
  ];
}

// function sortAgents(agents) {
//   const loggedAgents = [
//     ...orderBy(
//       agents.filter(agent => agent.isLoggedIn === true),
//       ['firstName']
//     )
//   ];
//   const notLoggedAgents = [
//     ...orderBy(
//       agents.filter(agent => agent.isLoggedIn === false),
//       ['firstName']
//     )
//   ];

//   return [...loggedAgents, ...notLoggedAgents];
// }
