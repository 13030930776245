import moment from 'moment';

import { updateData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';
import { findFirstAvailableDay } from '../../../utils/dateUtils';

export default async function updatePresence({
  activities,
  userId,
  activity = {},
  fromDropdown = false
}) {
  const data = mapDataforUpdate({
    activities,
    activity,
    fromDropdown
  });

  if (isUndefined(data)) {
    return void 0;
  }

  try {
    const response = await updateData({
      url: `${OUTSIDE_API.USERS}/${userId}/${OUTSIDE_API.PRESENCE}`,
      data
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.UPDATE_USER_PRESENCE',
        messageType: 'success'
      });
      const { activity } = response.data;
      return {
        activity: {
          ...activity,
          expiration: Optional(activity.expiration)
            .map(expiration =>
              expiration !== 'never'
                ? moment(expiration).format('YYYY-MM-DD HH:mm')
                : ''
            )
            .or('')
        }
      };
    }
  } catch {}
}

function mapDataforUpdate({ activities, activity, fromDropdown }) {
  if (!fromDropdown) {
    const { stateName, id, ...restOfActivity } = activity;
    return {
      ...restOfActivity,
      activity: id
    };
  }

  if (isUndefined(activity.activityId)) {
    const selectedActivity = activities.find(el => el.id === activity.value);

    if (isUndefined(selectedActivity)) {
      return void 0;
    }

    const { id, ...restOfActivity } = selectedActivity;

    return {
      ...restOfActivity,
      expiration: 'never',
      activity: id
    };
  } else {
    const selectedActivity = activities.find(
      el => el.id === activity.activityId
    );

    if (isUndefined(selectedActivity)) {
      return void 0;
    }

    const { id, ...restOfActivity } = selectedActivity;

    const expiration = mapExpiration(activity);

    return {
      ...restOfActivity,
      available: activity.available,
      expiration,
      activity: id
    };
  }
}

function mapExpiration(activity) {
  switch (activity.durationType) {
    case 'UNTIL_NEXT_WORKING_DAY': {
      return moment(findFirstAvailableDay(1, 'dk')).format(
        'YYYY-MM-DDT08:00:00'
      );
    }
    case 'MINUTES': {
      return moment()
        .add(Optional(activity.durationMinutes).or(0), 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    default: {
      return 'never';
    }
  }
}
