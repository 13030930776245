import Optional from '../../../utils/optional';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isUndefined } from '../../../utils/isDefined';

export default async function getPartner(id) {
  if (isUndefined(id)) {
    return '';
  }
  let text = '';
  try {
    const { data } = await getData(`${URLS.PARTNERS}/${id}`, {});
    text = Optional(data.attributes.settings)
      .map(settings => settings.website_url)
      .or('');
  } finally {
    return text;
  }
}
