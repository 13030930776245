import { actions as reduxActions } from '../reducers';
import store from '../../../store/store';
import {
  doesArrayHasLength,
  isArrayEmpty,
  isDefined
} from '../../../utils/isDefined';
import {
  checkEndDate,
  checkForwardNumber
} from '../../editSchedule/actions/validateFunctions';
import checkReccurenceErrors from '../../editSchedule/actions/checkReccurenceErrors';

const validateForm = async ({ values }) => {
  store.dispatch(reduxActions.setErrors({}));

  const { vacations = [] } = values;
  let errorsArray = [];
  let errors = {};
  if (isArrayEmpty(vacations)) {
    const { reccurenceErrors, reccurenceErrorsArray } = checkReccurenceErrors(
      values
    );
    errorsArray = [...errorsArray, ...reccurenceErrorsArray];
    errors = { ...errors, ...reccurenceErrors };

    if (values.reccurenceEndType === 'DATE' && isDefined(values.endDate)) {
      const endDateError = checkEndDate(
        values.reccurenceEndDate,
        values.endDate
      );

      if (isDefined(endDateError)) {
        errorsArray = [...errorsArray, endDateError.value];
        errors = { ...errors, ...endDateError.errors };
      }
    }
  }

  if (values.type === 'FORWARD') {
    const forwardNumberError = checkForwardNumber(values.forwardNumber);

    if (isDefined(forwardNumberError)) {
      errorsArray = [...errorsArray, forwardNumberError];
      errors = { ...errors, forwardNumber: ' ' };
    }
  }

  if (doesArrayHasLength(errorsArray)) {
    store.dispatch(reduxActions.setErrors({ ...errors, errorsArray }));
    return false;
  }

  return true;
};

export default validateForm;
