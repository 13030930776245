import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_CONTACT } from './types';
import { deleteData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export function removeContact({ cid }) {
  return {
    type: sagaActions[DELETE_CONTACT],
    cid
  };
}

export function* deleteContact({ cid }) {
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );

  const response = yield call(deleteData, {
    url: `${baseUrl}/api/personal_contacts/user/${clientDomain}/${mitelUserId}/${cid}?t=${mitelToken}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.CONTACT_DELETED',
      messageType: 'success'
    });
  }

  yield put(reduxActions.setRefresh());
}

function* watchDeleteContact() {
  yield takeLatest(sagaActions[DELETE_CONTACT], deleteContact);
}

export default watchDeleteContact();
