import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';

export function ActionsButtons({ handleSubmit, dirty, intl }) {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {intl.formatMessage({
          id: 'BUTTON.SAVE'
        })}
      </PrimaryButton>
    </>
  );
}

export default flowRight(injectIntl)(ActionsButtons);
