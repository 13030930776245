import findDatalimitOwner from '../../../utils/findDataLimitOwner';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import removeWhiteSpaces from '../../../utils/removeWhiteSpaces';

export function mapCellNumbersToFilter(data = {}) {
  return data.cell_numbers.map(cellNumber =>
    removeWhiteSpaces(cellNumber.number)
  );
}

export function mapOwners(data = {}) {
  return findDatalimitOwner(data).map(owner => owner.name);
}

export function mapCellNumbersToDisplay(data = {}, showPlus = true) {
  if (isUndefined(data.cell_numbers)) {
    return [];
  }

  return data.cell_numbers
    .map(cellNumber => {
      if (isUndefined(cellNumber.number)) {
        return null;
      }

      const number = cellNumber.number;
      return showPlus ? mapNumberToDisplay(number) : number;
    })
    .filter(number => isDefined(number));
}

export function mapCellNumbersForLookup(data = {}) {
  return [...mapCellNumbersToDisplay(data)].map(number =>
    number.replace(/\s|\+/g, '')
  );
}
