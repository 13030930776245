import { withStyles } from '@material-ui/styles';
import { flowRight, isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

import styles from '../styles/infoBox';

function AdminPresence({
  settings,
  activityId,
  activities,
  classes,
  presence
}) {
  const mapName = id => {
    const activity = activities.find(el => el.id === id);
    return Optional(activity?.name).or(id);
  };

  const color = useMemo(
    () => {
      if (isUndefined(presence.available)) {
        const activity = activities.find(el => el.id === activityId);

        return activity?.available
          ? settings.colors.success
          : settings.colors.danger;
      }
      return presence.available === 'true'
        ? settings.colors.success
        : settings.colors.danger;
    },
    // eslint-disable-next-line
    [presence]
  );

  return (
    <div
      className={classes.presence}
      style={{
        color,
        backgroundColor: settings.colors.white
      }}
    >
      {capitaliseFirstLetter(
        Optional(activityId)
          .map(id => mapName(id))
          .or('')
      )}
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(AdminPresence);
