import store from '../store/store';
import { actions as changeClientReduxActions } from '../partials/selectClient/reducers';
import checkIfSimcardsShouldBeShown from './checkIfSimcardsShouldBeShown';
import checkIfIoTShouldBeShown from './checkIfIoTShouldBeShown';
import { doesArrayHasLength } from './isDefined';

const changeClient = async ({ client }) => {
  const hasCientSimcards = await checkIfSimcardsShouldBeShown(client.id);

  let hasCientIoTSubs = false;

  if (doesArrayHasLength(client?.metadata?.account_ids)) {
    hasCientIoTSubs = await checkIfIoTShouldBeShown(client.id);
  }

  return store.dispatch(
    changeClientReduxActions.setSelectedClient({
      ...client,
      hasCientSimcards,
      hasCientIoTSubs
    })
  );
};

export default changeClient;
