import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_EDIT_ROLE = 'OPEN_EDIT_ROLE';
const RESET_EDIT_ROLE = 'RESET_EDIT_ROLE';

export const actionTypes = createReduxActionTypes('EDIT_ROLE', [
  OPEN_EDIT_ROLE,
  RESET_EDIT_ROLE
]);

const DEFAULT_STATE = {
  open: false,
  allPermissions: [],
  role: {},
  isEdit: true
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_EDIT_ROLE]: {
      const { role, allPermissions, isEdit } = action;
      return {
        ...state,
        open: true,
        allPermissions,
        role,
        isEdit
      };
    }
    case actionTypes[RESET_EDIT_ROLE]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditRole: ({ role, allPermissions, isEdit }) => ({
    type: actionTypes[OPEN_EDIT_ROLE],
    role,
    allPermissions,
    isEdit
  }),
  closeEditRole: () => ({ type: actionTypes[RESET_EDIT_ROLE] })
};
