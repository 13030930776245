import orderBy from 'lodash.orderby';

import { isString } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import calculateMbToGb from '../../../utils/calculateMbToGb';

export default function mapDataForChart({
  accumulated_data_usages = [],
  block_limit_mb = 0
}) {
  const shortenUsageArray = orderBy(
    accumulated_data_usages,
    ['month'],
    ['desc']
  )
    .slice(0, 7)
    .reverse();

  const calculatedUsageLimit = calculateMbToGb(block_limit_mb);
  
  return shortenUsageArray.reduce(
    (obj, elem) => {
      if (!isString(elem.month)) {
        return obj;
      }
      const [year, month] = elem.month.split('-');

      const data = elem.data + elem.data_roam_home
  
      return {
        dataUsage: [
          [
            ...obj.dataUsage[0],
            Optional(calculateMbToGb(data)).or(0)
          ],
          [
            ...obj.dataUsage[1],
            Optional(calculateMbToGb(elem.data_roam_home)).or(0)
          ],
          [
            ...obj.dataUsage[2],
            Optional(calculateMbToGb(elem.data_roam_others)).or(0)
          ],
          [...obj.dataUsage[3], calculatedUsageLimit]
        ],
        chartLabels: [...obj.chartLabels, `${month}'${year}`]
      };
    },
    {
      dataUsage: [[], [], [], []],
      chartLabels: []
    }
  );
}
