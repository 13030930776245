import React, { useMemo } from 'react';
import MaterialTable from '@material-table/core';
import { injectIntl } from 'react-intl';

import { TABLE_OPTIONS } from '../../../consts/tableOptions';

const OPTIONS = {
  ...TABLE_OPTIONS.DEFAULT,
  ...TABLE_OPTIONS.SUBTABLE
};

export function RolesPermissions({ row, intl }) {
  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'TABLE.NAME'
        }),
        field: 'name'
      },
      {
        title: intl.formatMessage({
          id: 'TABLE.DESCRIPTION'
        }),
        field: 'description'
      },
      {
        title: intl.formatMessage({
          id: 'TABLE.CONDITIONS'
        }),
        field: 'conditions'
      },
      {
        title: intl.formatMessage({
          id: 'TABLE.REMOTE'
        }),
        field: 'remote',
        type: 'boolean'
      }
    ],
    // eslint-disable-next-line
    []
  );

  return <MaterialTable columns={columns} data={row} options={OPTIONS} />;
}

export default injectIntl(RolesPermissions);
