export default function mapSettingsToUpdate(settings = {}, parterId) {
  const {
    footer,
    favicon,
    title,
    frontendUrl,
    customerServiceEmail,
    big,
    small,
    aside_menu,
    aside_menu_active,
    aside_menu_active_text,
    aside_menu_brand,
    aside_menu_text,
    buttons,
    buttons_text,
    danger,
    dashboard_button,
    dashboard_icon,
    dashboard_text,
    links,
    login_left,
    login_left_text,
    login_right,
    login_right_text,
    modal_header,
    modal_header_text,
    primary,
    success,
    warning,
    white,
    letterFooter,
    displayName,
    helpdeskURL,
    supportPhoneNumber,
    websiteURL,
    app_id,
    app_secret
  } = settings;

  return {
    data: {
      id: parterId,
      type: 'partners',
      attributes: {
        settings: {
          frontend_url: frontendUrl,
          letter_footer: letterFooter,
          display_name: displayName,
          helpdesk_url: helpdeskURL,
          support_phone_number: supportPhoneNumber,
          website_url: websiteURL,
          customer_service_email: customerServiceEmail,
          frontend_settings: {
            footer,
            favicon,
            title,
            logos: { big, small },
            colors: {
              aside_menu,
              aside_menu_active,
              aside_menu_active_text,
              aside_menu_brand,
              aside_menu_text,
              buttons,
              buttons_text,
              danger,
              dashboard_button,
              dashboard_icon,
              dashboard_text,
              links,
              login_left,
              login_left_text,
              login_right,
              login_right_text,
              modal_header,
              modal_header_text,
              primary,
              success,
              warning,
              white
            }
          },
          microsoft_graph_config: {
            app_id,
            app_secret
          }
        }
      }
    }
  };
}
