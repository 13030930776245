import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_CLIENT } from './types';
import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import SELECTS_OPTIONS from '../../../consts/selectsOptions';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import urlCreator from '../../../utils/urlCreator';

const SELECTS_ARRAY_PARAMS = [
  {
    endpoint: 'PARTNERS',
    queryParams: { fields: { partners: 'name' } }
  },
  {
    endpoint: 'VIP',
    queryParams: {},
    number: 10
  }
];

export function setClient(client, fromMenu) {
  return {
    type: sagaActions[SET_CLIENT],
    client,
    fromMenu
  };
}

export function* getClient({ client, fromMenu = false }) {
  let clientData = { ...client };
  const selectsOptions = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);

  const completeSelectsOptions = yield call(
    prepareSelectsOptions,
    selectsOptions
  );
  try {
    if (fromMenu) {
      const queryParams = { extra_fields: { clients: 'default_address' } };
      const { data } = yield call(
        getData,
        urlCreator(`${URLS.CLIENTS}/${client.id}.json`, queryParams)
      );

      clientData = data;
    }
  } catch {}

  const mappedClient = yield call(mapClient, clientData);

  yield put(reduxActions.setSelectsOptions(completeSelectsOptions));
  yield put(reduxActions.setClient(mappedClient));
}

function* watchSetClient() {
  yield takeLatest(sagaActions[SET_CLIENT], getClient);
}

export default watchSetClient();

export function prepareSelectsOptions(selectsOptions) {
  return {
    ...selectsOptions,
    platform: [...SELECTS_OPTIONS.PLATFORM_OPTIONS]
  };
}

export const mapClient = client => {
  const { metadata, default_address } = client;

  let mappedClient = { ...client };

  if (isDefined(metadata?.display_name)) {
    mappedClient.display_name = metadata?.display_name;
  }

  return {
    ...mappedClient,
    default_address: Optional(default_address?.id)
      .map(id => id.toString())
      .or('')
  };
};
