import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isArray } from '../../../utils/isDefined';

export default async function getConnectCount({ url }) {
  try {
    const { data: response } = await getData({
      options: { url },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND, ...RESPONSE_CODES.MITEL_TO_PASS]
    });

    const count = Optional(response)
      .map(res => {
        if (!isArray(res)) {
          return '-';
        }

        return res.length;
      })
      .or(0);

    return count;
  } catch (e) {
    return '-';
  }
}
