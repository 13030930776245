import { isObject, isDefined } from './isDefined';

export default function urlCreator(url, options, startChar = '?') {
  return `${url}${startChar}${optionsStringCreator(options)}`;
}

function optionsStringCreator(options, queryParams) {
  const optionsString = Object.keys(options).map(option => {
    if (!isObject(options[option])) {
      if (!isDefined(options[option])) {
        return null;
      }
      return !queryParams
        ? `${option}=${options[option]}`
        : options[option] && option === 'any'
        ? `${queryParams}[${option}]`
        : `${queryParams}[${option}]=${options[option]}`;
    }

    return optionsStringCreator(
      options[option],
      !queryParams ? `${option}` : `${queryParams}[${option}]`
    );
  });

  return optionsString.join('&');
}
