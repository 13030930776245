import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import InputRow from '../../../partials/dataRows/InputRow';
import withSettings from '../../../utils/withSettings';
import styles from '../styles/frontendSettings';
import ColorRow from './ColorRow';

function FrontendSettings({ form, intl, defaultSettings, classes }) {
  const customInputStyles = useMemo(() => {
    return {
      root: { width: 'unset', fontSize: '14px' },
      label: {
        color: defaultSettings.colors.primary,
        width: '200px'
      },
      textfield: { width: '300px' }
    };
  }, [defaultSettings.colors.primary]);

  const { values, handleBlur, setFieldValue, handleChange } = form;
  const rowProps = { values, handleBlur, setFieldValue, handleChange };

  return (
    <div
      className={classes.root}
      style={{ color: defaultSettings.colors.primary }}
    >
      <div className={classes.title}>
        {intl.formatMessage({
          id: 'APP_SETTINGS_FRONTEND_SETTINGS'
        })}
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          {intl.formatMessage({
            id: 'COLORS'
          })}
        </div>
        <div className={classes.colorsContainer}>
          <ColorRow name="primary" {...rowProps} />
          <ColorRow name="buttons" {...rowProps} />
          <ColorRow name="buttons_text" {...rowProps} />
          <ColorRow name="login_left" {...rowProps} />
          <ColorRow name="login_left_text" {...rowProps} />
          <ColorRow name="login_right" {...rowProps} />
          <ColorRow name="login_right_text" {...rowProps} />
          <ColorRow name="aside_menu" {...rowProps} />
          <ColorRow name="aside_menu_text" {...rowProps} />
          <ColorRow name="aside_menu_active" {...rowProps} />
          <ColorRow name="aside_menu_active_text" {...rowProps} />
          <ColorRow name="aside_menu_brand" {...rowProps} />
          <ColorRow name="modal_header" {...rowProps} />
          <ColorRow name="modal_header_text" {...rowProps} />
          <ColorRow name="dashboard_button" {...rowProps} />
          <ColorRow name="dashboard_icon" {...rowProps} />
          <ColorRow name="dashboard_text" {...rowProps} />
          <ColorRow name="success" {...rowProps} />
          <ColorRow name="warning" {...rowProps} />
          <ColorRow name="danger" {...rowProps} />
          <ColorRow name="white" {...rowProps} />
          <ColorRow name="links" {...rowProps} />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          {intl.formatMessage({
            id: 'LOGOS'
          })}
        </div>
        <div>
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.SMALL_LOGO"
            name="small"
            {...form}
          />
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.BIG_LOGO"
            name="big"
            {...form}
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          {intl.formatMessage({
            id: 'OTHER'
          })}
        </div>
        <div>
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="FOOTER"
            name="footer"
            isMultiline={true}
            minRows={6}
            maxRows={4}
            {...form}
          />
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="FAVICON"
            name="favicon"
            {...form}
          />
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="TITLE"
            name="title"
            {...form}
          />
        </div>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(FrontendSettings);
