import {
  Checkbox,
  createTheme,
  ThemeProvider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React from 'react';

import styles from './styles';
import Optional from '../../utils/optional';
import flowRight from 'lodash.flowright';
import withSettings from '../../utils/withSettings';
import clsx from 'clsx';

const DataCheckboxRow = ({
  label,
  name,
  classes,
  width,
  values,
  handleBlur,
  handleChange,
  disabled,
  settings,
  infoIcon = false,
  infoIconTooltipComponent = void 0,
  infoIconText = '',
  checkBoxTooltip
}) => {
  const theme = createTheme({
    overrides: {
      MuiSvgIcon: { root: { fontSize: '25px' } },
      MuiCheckbox: {
        root: {
          fontSize: '20px',
          '&$disabled': {
            pointerEvents: 'auto'
          }
        },
        colorSecondary: {
          color: settings.colors.modal_header,
          '&$checked': { color: settings.colors.modal_header }
        }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
          width: '200px'
        }
      }
    }
  });

  return (
    <div
      className={classes.checkboxRoot}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <ThemeProvider theme={theme}>
        <Tooltip title={Optional(checkBoxTooltip).or('')}>
          <Checkbox
            name={name}
            checked={Optional(values[name]).or(false)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </Tooltip>
      </ThemeProvider>
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={Optional(infoIconTooltipComponent).or(infoIconText)}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(DataCheckboxRow);
