import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import UsersTable from './components/UsersTable';
import RequestUserWizard from '../../modals/requestUserWizard/RequestUserWizard';
import { actions as reduxActions } from './reducers';
import getCustomJobs from '../../utils/getCustomJobs';

function TelephonyUsers({ resetUsers }) {
  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
    return resetUsers;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <UsersTable />
      <RequestUserWizard />
    </>
  );
}

const mapDispatchToProps = {
  resetUsers: reduxActions.resetUsers
};

export default flowRight(connect(null, mapDispatchToProps))(TelephonyUsers);
