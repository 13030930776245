import { getData } from './http';
import showSnackbar from './showSnackbar';

export async function mitelRequest({
  options = {},
  errorsToPass = [],
  errorResponseReturnValue = {},
  hideSnackbarOnPassErrors = false
}) {
  let returnData = errorResponseReturnValue;

  try {
    const response = await getData({
      options,
      errorsToPass: [...errorsToPass]
    });

    returnData = response;
  } catch (e) {
    const shouldShow =
      !hideSnackbarOnPassErrors ||
      (hideSnackbarOnPassErrors &&
        !(errorsToPass.findIndex(code => code === e.response.status) !== -1));

    shouldShow &&
      showSnackbar({
        text: `${e.response.status} : Mitel connection problem.`,
        messageType: 'error',
        time: 3000
      });
  } finally {
    return returnData;
  }
}
