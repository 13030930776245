import React, { useMemo } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { flowRight } from 'lodash';
import locale from 'date-fns/locale/en-US';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import styles from '../styles/elements';
import { customStyles } from '../styles';
import SelectRow from '../../../partials/dataRows/SelectRow';
import SELECTS_OPTIONS from '../../../consts/selectsOptions';
import translateOptionslLabels from '../../../utils/translateOptionsLabels';
import { materialEndDatePickerTheme } from '../styles/datePickers';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';
import { DATE_FORMAT } from '../../../consts/initialValues';

function EndElement({ form, classes, settings, customErrors, language,intl }) {
  const { values, handleBlur, handleChange, setFieldValue } = form;
  const fieldProps = useMemo(() => {
    return {
      values,
      handleBlur,
      handleChange,
      setFieldValue
    };
  }, [values, handleBlur, handleChange, setFieldValue]);

  const endOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_END_OPTIONS);
  }, []);

  const materialDateTheme = useMemo(() => {
    return materialEndDatePickerTheme(settings);
  }, [settings]);

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }

  const onDateChange = selectedDate => {
    const date = moment(selectedDate).format('x');
    setFieldValue('reccurenceEndDate', Number(date));
  };

  const errorMessage = Optional(customErrors.reccurenceEndDate).or(null);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <div className={classes.endRoot}>
        <SelectRow
          name="reccurenceEndType"
          label="END"
          customStyles={customStyles.select.typeSelect}
          options={endOptions}
          isEditModal={false}
          {...fieldProps}
        />
        {values.reccurenceEndType === 'DATE' && (
          <div>
            <ThemeProvider theme={materialDateTheme}>
              <KeyboardDatePicker
                inputVariant="outlined"
                ampm={false}
                value={values.reccurenceEndDate}
                onChange={onDateChange}
                format={DATE_FORMAT[language]}
                autoOk={true}
                showTodayButton
                todayLabel={intl.formatMessage({
                  id: 'TODAY'
                })}
                error={Boolean(customErrors.reccurenceEndDate)}
              />
            </ThemeProvider>
            <div className={classes.errorMessage}>{errorMessage}</div>
          </div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}

const mapStatesToProps = ({ editSchedule, auth }) => {
  const { customErrors } = editSchedule;
  return {
    customErrors,
    language: auth.lang
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(EndElement);
