import * as yup from 'yup';

export default function(intl) {
  return yup.object().shape({
    current_password: yup.string().when('isNotDialog', {
      is: true,
      then: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
    }),
    password: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(
        /^(?=.*\d).{8,20}$/gm,
        intl.formatMessage({
          id: 'VALIDATION.PASS_WRONG_INFO'
        })
      ),
    password_confirmation: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });
}
