const styles = theme => {
  return {
    content: { display: 'flex', justifyContent: 'center' },
    container: {
      width: '500px',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px',
      padding: '10px 0',
      [theme.breakpoints.down('xs')]: {
        width: '310px'
      }
    },
    clientDataContainer: {
      flexDirection: 'row'
    },
    submitNumberRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px'
    },
    messageContainer: {
      fontSize: '10px',
      visibility: 'hidden',
      textAlign: 'center',
      color: '#F00'
    },
    messageVisible: {
      visibility: 'visible'
    },
    elementContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px'
    },
    valueContainer: {
      marginLeft: '10px'
    },
    links: {
      '&:hover': { filter: 'brightness(1.5)' }
    },
    radioButtons: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      flexDirection: 'row'
    }
  };
};

export default styles;
