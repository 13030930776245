import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/multieditForm';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { actions as reduxActions } from '../reducers';
import { NUMBER_RANGES_MULTIEDIT } from '../../../consts/initialValues';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import { updateOnSubmit } from '../actions/updateNumberRanges';
import validateSchema from '../actions/validateSchema';
import DatepickerRow from '../../../partials/dataRows/DatepickerRow';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

const MultieditForm = ({
  classes,
  intl,
  ranges,
  closeDialog,
  updateOnSubmit,
  resetValues,
  selectOptions,
  clientId,
  isBeTable
}) => {
  const onSubmit = useCallback(
    values =>
      updateOnSubmit({ ranges, formValues: values, resetValues, isBeTable }),
    // eslint-disable-next-line
    [ranges, resetValues]
  );

  return (
    <Formik
      initialValues={NUMBER_RANGES_MULTIEDIT}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        dirty,
        setFieldValue
      }) => {
        const fieldProps = {
          errors,
          values,
          handleBlur,
          handleChange,
          setFieldValue
        };
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            <DatepickerRow
              label="EDIT_RANGE.ACTIVE_AT"
              name="och_active_at"
              showOnlyAvailable={false}
              disabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'och_active_at'
                )
              }
              disableWeekends={false}
              {...fieldProps}
            />
            <DatepickerRow
              label="EDIT_RANGE.INACTIVE_AT"
              name="och_inactive_at"
              showOnlyAvailable={false}
              disabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'och_inactive_at'
                )
              }
              disableWeekends={false}
              {...fieldProps}
            />
            <SelectRow
              name="network"
              label="TABLE.NETWORK"
              isEditModal={false}
              options={selectOptions.providers}
              isDisabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'network'
                )
              }
              {...fieldProps}
            />
            <SelectRow
              name="csbc_routing"
              label="EDIT.PLATFORM"
              isEditModal={false}
              options={selectOptions.platform}
              isDisabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'csbc_routing'
                )
              }
              {...fieldProps}
            />
            <SelectRow
              name="status"
              label="STATUS"
              isEditModal={false}
              options={selectOptions.status}
              isDisabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'status'
                )
              }
              {...fieldProps}
            />
            <SelectRow
              name="future_status"
              label="FUTURE_STATUS"
              isEditModal={false}
              options={selectOptions.futureStatus}
              isDisabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'future_status'
                )
              }
              {...fieldProps}
            />
            <InputRow
              name="description"
              label="DESCRIPTION"
              placeholder={intl.formatMessage({
                id: 'NO_CHANGES'
              })}
              disabled={
                !can(
                  'update',
                  createSubject('ExternalNumberRange', {
                    client_id: Number(clientId)
                  }),
                  'description'
                )
              }
              {...fieldProps}
            />
            <footer className={classes.footer}>
              <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
                {intl.formatMessage({
                  id: 'BUTTON.SAVE'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={closeDialog}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
};
const mapStatesToProps = ({ numberRangesMultiedit, selectClient }) => {
  return {
    ranges: numberRangesMultiedit.ranges,
    resetValues: numberRangesMultiedit.resetValues,
    selectOptions: numberRangesMultiedit.selectOptions,
    clientId: selectClient.selectedClient.id,
    isBeTable: numberRangesMultiedit.isBeTable
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(MultieditForm);
