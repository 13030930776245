import uniqBy from 'lodash.uniqby';
import { isDefined } from './isDefined';
import Optional from './optional';

export default function findDatalimitOwner(datalimit) {
  return Optional(datalimit.cell_numbers)
    .map(cellNumbers => {
      return uniqBy(
        cellNumbers
          .map(cellNumber => {
            if (
              isDefined(cellNumber.sim_card) &&
              isDefined(cellNumber.sim_card.owner)
            ) {
              return cellNumber.sim_card.owner;
            }
            return null;
          })
          .filter(owner => isDefined(owner)),
        'name'
      );
    })
    .or([]);
}
