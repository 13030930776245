import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';

import styles from '../styles/photo';
import { isDefined } from '../../../utils/isDefined';

function Photo({
  classes,
  photo,
  intl,
  tempPhoto,
  showTempPhoto = false,
  closePreview,
  fromDialog,
  dialogPhoto
}) {
  const photoToShow = useMemo(() => {
    return fromDialog ? dialogPhoto : photo;
  }, [fromDialog, dialogPhoto, photo]);

  return (
    <div className={classes.root}>
      <div className={classes.photo}>
        {showTempPhoto ? (
          <div>
            <img src={tempPhoto} className={classes.photo} alt="Empty"></img>
            <i
              className={clsx('far fa-window-close', classes.closeIcon)}
              onClick={closePreview}
            ></i>
          </div>
        ) : isDefined(photoToShow) && photoToShow.constructor.name === 'Blob' ? (
          <img
            src={URL.createObjectURL(photoToShow)}
            className={classes.photo}
            alt="Empty"
          ></img>
        ) : (
          <div className={classes.noPhotoContainer}>
            <i className="fas fa-camera"></i>
            {intl.formatMessage({
              id: 'NO_PHOTO_FOUND'
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ auth }) => {
  return {
    photo: auth.photo,
    userConnectId: auth.user.connect30_user_id
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(Photo);
