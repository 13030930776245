export default {
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    minWidth: '150px'
  },
  icon: { margin: '0 5px' }
};
