import { translate } from '../i18n/I18nProvider';
import { isDefined } from './isDefined';
import Optional from './optional';

export default function createErrorText(errorMessage) {
  if (isDefined(errorMessage)) {
    const customMessage = Optional(errorMessage.messageIntl)
      .map(message => translate(message))
      .or(errorMessage.message);

    return errorMessage.message
      ? `${errorMessage.name} : ${customMessage}`
      : `${errorMessage.name} : ${errorMessage.statusText}`;
  } else {
    return null;
  }
}
