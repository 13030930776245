import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_ROLE } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import { getRoles } from './getRoles';

export function removeRole(id) {
  return {
    type: sagaActions[DELETE_ROLE],
    id
  };
}

export function* deleteRole({ id }) {
  yield put(loaderReducActions.startSpinLoader());

  const response = yield call(deleteData, {
    url: `${URLS.ROLES}/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.DELETE_ROLE',
      messageType: 'success'
    });

    yield call(getRoles);
    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchDeleteRole() {
  yield takeLatest(sagaActions[DELETE_ROLE], deleteRole);
}

export default watchDeleteRole();
