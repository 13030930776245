import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { RadioGroup } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import { Field, Formik, Form } from 'formik';
import { connect } from 'react-redux';

import withSettings from '../../utils/withSettings';
import styles, { customSelectStyles } from './styles';
import CustomFormControlLabel from '../../partials/customFormControlLabel';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import { isArrayEmpty, isStringEmpty } from '../../utils/isDefined';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import ConfirmDialog from '../../partials/confirmationDialog/ConfirmDialog';
import editCustomJob from './actions/editCustomJob';
import getCustomJobs from './actions/getCustomJobs';
import GeneralData from './components/GeneralData';
import validateSchema from './actions/validateSchema';
import DeleteCustomJobConfimation from './components/DeleteCustomJobConfimation';
import CustomIconButton from '../../partials/tableCustomButtons/CustomIconButton';
import deleteCustomJob from './actions/deleteCustomJob';
import createCustomJob from './actions/createCustomJob';
import Schemas from './components/Schemas';
import getCustomJob from './actions/getCustomJob';
import { actions as reduxActions } from './reducers';
import { EMPTY_JOB } from '../../consts/initialValues';

function EditCustomJobs({
  settings,
  classes,
  intl,
  getCustomJobs,
  setValue,
  customJobs,
  selected,
  customJob,
  schema,
  uiSchema,
  getCustomJob,
  resetSchemas
}) {
  const [schemaError, setSchemaError] = useState(false);

  useEffect(() => {
    getCustomJobs();
  }, [getCustomJobs]);

  const onRadioButtonChange = useCallback(
    e => {
      resetSchemas();
      if (e.target.value === 'create') {
        setValue('customJob', EMPTY_JOB);
      } else {
        getCustomJob(customJobs[0].id);
      }
      setValue('selected', e.target.value);
    },
    // eslint-disable-next-line
    [setValue, customJobs, getCustomJob]
  );

  const handleChange = useCallback(
    async choosen => {
      resetSchemas();
      getCustomJob(choosen.id);
    },
    [getCustomJob, resetSchemas]
  );

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      const { isSuccess, responseJob } =
        selected === 'create'
          ? await createCustomJob({
              customJob: { ...values, form_schema: schema, ui_schema: uiSchema }
            })
          : await editCustomJob({
              customJob: {
                ...values,
                form_schema: schema,
                ui_schema: uiSchema
              },
              initialValues: customJob
            });

      if (isSuccess) {
        getCustomJobs();

        if (selected === 'create') {
          resetForm();
          setValue('customJob', EMPTY_JOB);
        } else if (isArrayEmpty(customJobs)) {
          setValue('customJob', EMPTY_JOB);
          setValue('selected', 'create');
        } else {
          setValue('customJob', responseJob);
        }
      }
    },
    [selected, customJob, schema, uiSchema, setValue, getCustomJobs, customJobs]
  );

  const onDeleteClick = useCallback(async () => {
    const response = await deleteCustomJob(customJob.id);

    if (response) {
      const { mappedCustomJobs } = getCustomJobs();
      setValue('customJobs', mappedCustomJobs);

      if (isArrayEmpty(mappedCustomJobs)) {
        setValue('customJob', EMPTY_JOB);
        setValue('selected', 'create');
      } else {
        setValue('customJob', mappedCustomJobs[0]);
      }
    }
  }, [setValue, customJob, getCustomJobs]);

  const primaryColor = useMemo(() => settings.colors.primary, [settings]);

  return (
    <div className={classes.root} style={{ color: primaryColor }}>
      <Formik
        initialValues={customJob}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        validationSchema={validateSchema}
      >
        {({ handleSubmit, values, errors }) => {
          return (
            <Form>
              <div className={classes.radioRow}>
                <PrimaryButton
                  onClick={handleSubmit}
                  disabled={
                    isStringEmpty(values.name) ||
                    Object.keys(errors).length > 0 ||
                    schemaError
                  }
                >
                  {intl.formatMessage({
                    id: 'BUTTON.SAVE'
                  })}
                </PrimaryButton>

                <RadioGroup
                  row
                  className={classes.radioButtons}
                  value={selected}
                  onChange={onRadioButtonChange}
                >
                  <CustomFormControlLabel
                    value="create"
                    label={intl.formatMessage({
                      id: 'EDIT.CREATE'
                    })}
                  />
                  <CustomFormControlLabel
                    disabled={isArrayEmpty(customJobs)}
                    value="edit"
                    label={intl.formatMessage({
                      id: 'EDIT.EDIT'
                    })}
                  />
                </RadioGroup>
                {selected === 'edit' && !isArrayEmpty(customJobs) && (
                  <>
                    <Select
                      customStyles={customSelectStyles}
                      options={customJobs}
                      styles={reactSelectDefaultStyles}
                      value={customJob}
                      onChange={handleChange}
                    />
                    <ConfirmDialog
                      onConfirmClick={onDeleteClick}
                      dialogContentComponent={<DeleteCustomJobConfimation />}
                      dialogTitleIntlId={'TOOLTIP.DELETE_CUSTOM_JOB'}
                    >
                      <CustomIconButton
                        iconTooltipIntlKey="TOOLTIP.DELETE_CUSTOM_JOB"
                        iconClassName="far fa-trash-alt"
                      />
                    </ConfirmDialog>
                  </>
                )}
                {selected === 'edit' &&
                  isArrayEmpty(customJobs) &&
                  intl.formatMessage({
                    id: 'CLIENT.NO_TEMPLATE'
                  })}
              </div>
              <Field component={GeneralData} />
            </Form>
          );
        }}
      </Formik>
      <Schemas setSchemaError={setSchemaError} />
    </div>
  );
}

const mapStatesToProps = ({ editCustomJob }) => {
  return {
    ...editCustomJob
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  getCustomJobs,
  getCustomJob
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditCustomJobs);
