import Optional from '../../../utils/optional';

export default function mapTelephonyDataForForm({
  userData = {},
  groups,
  languageId
}) {
  const {
    name = {},
    user_groups,
    email,
    user_id: mitelUserId,
    dir_fields,
    organization,
    custom_id,
    cost_center
  } = userData;

  const allGroupsOptions = mapGroups(groups);

  const department = Optional(organization)
    .map(organization => organization.department)
    .or('');

  const dirFields = mapFields(dir_fields);

  return {
    allGroupsOptions,
    groups: user_groups,
    generalData: {
      email,
      mitelUserId,
      firstName: name.given_name,
      lastName: name.family_name,
      dirFields,
      language: languageId,
      department,
      custom_id,
      cost_center
    }
  };
}

function mapGroups(groups = []) {
  return groups.map(group => {
    return { isUserGroup: false, label: group.name };
  });
}

function mapFields(fields) {
  return fields.reduce((obj, field) => {
    const { id, value, label } = field;
    return { ...obj, [id]: { value, label } };
  }, {});
}
