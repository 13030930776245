import Optional from '../utils/optional';
import store from '../store/store';

export const reactSelectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    borderRadius: 'unset',
    boxShadow: 'unset',
    borderColor: state.isFocused
      ? store.getState().builder.settings.colors.aside_menu_active
      : 'hsl(0,0%,80%);',
    '&:hover': {
      borderColor: store.getState().builder.settings.colors.aside_menu
    },
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.control)
      .or({})
  }),
  option: (styles, state) => ({
    ...styles,
    '&:hover': {
      backgroundColor: store.getState().builder.settings.colors
        .aside_menu_active,
      color: store.getState().builder.settings.colors.modal_header_text
    },
    backgroundColor:
      state.isSelected || state.isFocused
        ? store.getState().builder.settings.colors.aside_menu
        : 'transparent',
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.option)
      .or({})
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.valueContainer)
      .or({})
  }),
  container: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.container)
      .or({})
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.indicatorSeparator)
      .or({})
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.indicatorsContainer)
      .or({})
  }),
  menu: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.menu)
      .or({})
  }),
  singleValue: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.singleValue)
      .or({})
  }),
  group: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.group)
      .or({})
  }),
  input: (styles, state) => ({
    ...styles,
    ...Optional(state.selectProps.customStyles)
      .map(styles => styles.input)
      .or({})
  })
};
