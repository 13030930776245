import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles/optionLabel';
import withSettings from '../../../utils/withSettings';

function OptionLabel({ activity, classes, settings }) {
  const dotStyles = useMemo(() => {
    return {
      backgroundColor: activity.available
        ? settings.colors.success
        : settings.colors.danger
    };
    // eslint-disable-next-line
  }, [activity.available]);

  return (
    <div className={classes.root}>
      <div className={classes.dot} style={dotStyles}></div>
      {activity.name}
    </div>
  );
}

export default flowRight(
  withSettings,
  withStyles(styles)
)(OptionLabel);
