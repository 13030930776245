import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { NUMBER_LOOKUP_GENERAL_DATA_KEYS } from '../../../consts/initialValues';
import checkNumberData from '../../../utils/checkNumberData';
import { createValueLabel, mapValue } from '../actions/dataActions';
import styles from '../styles/generalData';
import CustomTitle from './CustomTitle';

function GeneralData({
  classes,
  generalData = {},
  fromRightPanel = false,
  rightPanelData = {}
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: generalData,
      rightPanelData,
      fromRightPanel
    });
  }, [generalData, rightPanelData, fromRightPanel]);

  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.BASIC_INFO" isDataExist={isDataExist}>
        {isDataExist &&
          NUMBER_LOOKUP_GENERAL_DATA_KEYS.map((key, i) => {
            const value = mapValue(data[key]);
            const label = createValueLabel(key);
            return (
              value && (
                <div
                  className={classes.dataContainer}
                  key={i}
                  style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA' }}
                >
                  <div className={classes.dataKey}>{label}</div>
                  <div>{value}</div>
                </div>
              )
            );
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    generalData: checkNumber.generalData,
    rightPanelData: rightPanel.number?.generalData
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(GeneralData);
