import React from 'react';
import { Checkbox, createTheme, ThemeProvider } from '@material-ui/core';
import withSettings from '../../../utils/withSettings';

const CustomCheckbox = ({ checked, onChange, settings }) => {
  const theme = createTheme({
    overrides: {
      MuiSvgIcon: { root: { fontSize: '20px' } },
      MuiCheckbox: {
        root: { fontSize: '18px' },
        colorSecondary: {
          color: settings.colors.modal_header,
          '&$checked': { color: settings.colors.modal_header }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Checkbox checked={checked} onChange={onChange} />
    </ThemeProvider>
  );
};

export default withSettings(CustomCheckbox);
