const styles = theme => {
  return {
    root: { width: '100%', marginBottom: '2px', display: 'flex' },
    tableRoot: {
      marginLeft: '40px'
    },
    expandSwitchLabel: { fontSize: '12px', color: '#000' },
    headerRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    icon: {
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    },
    title: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    label: {
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent:'center'
      }
    }
  };
};

export default styles;
