import React from "react";
import flowRight from "lodash.flowright";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";

import withSettings from "../../../utils/withSettings";
import styles from "../styles/simcardsTableHeader";
import { translate } from "../../../i18n/I18nProvider";

const SimcardsTableHeader = ({ classes, settings }) => {
  return (
    <div
      className={classes.headerRoot}
      style={{ color: settings.colors.primary }}
    >
      <div className={classes.label}>
        <i className={clsx("fas fa-sim-card", classes.icon)}></i>
        <div className={classes.title}>
          {translate("MENU.IOT_SUBSCRIPTIONS")}
        </div>
      </div>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(SimcardsTableHeader);
