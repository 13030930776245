import React, { useCallback } from "react";
import flowRight from "lodash.flowright";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";

import styles from "../styles";
import { actions as editSimcardReduxActions } from "../../../modals/editSimcard/reducers";
import { actions as showHistoryReduxActions } from "../../../modals/showHistory/reducers";
import { isDefined, isUndefined } from "../../../utils/isDefined";
import getSimcard from "../actions/getSimcard";
import { actions as swapSimcardReduxActions } from "../../../modals/swapSimcard/reducers";
import Optional from "../../../utils/optional";
import withSettings from "../../../utils/withSettings";
import MoreActionsMenu from "./MoreActionsMenu";
import { translate } from "../../../i18n/I18nProvider";
import { actions as loaderReduxActions } from "../../../partials/loader/reducers";
import { actions as errorDialogReduxActions } from "../../../partials/errorDialog/reducers";

const ActionButtons = ({
  subscription,
  classes,
  openSwapSimcard,
  clientId,
  settings,
  startLoading,
  stopLoading,
  openErrorDialog,
}) => {
  const onSwapClick = useCallback(
    async () => {
      startLoading();
      const response = await getSimcard({
        subscriptionId: subscription.id,
        clientId,
      });
      stopLoading();

      if (isDefined(response?.error)) {
        openErrorDialog({
          directMessage: JSON.stringify(response?.error),
          dialogTitle: "",
        });
      }

      if (isUndefined(response?.simcard) && isUndefined(response?.error)) {
        openErrorDialog({
          directMessage: translate("SUBSCRIPTIONS.NO_CARD_FOUND", {
            icc: response?.icc,
          }),
          dialogTitle: "",
        });
      }

      if (isDefined(response?.simcard)) {
        const { id, msisdn, mobile_network, client_id } = response?.simcard;

        return openSwapSimcard({
          simcard: { id, msisdn, mobile_network, client_id },
          fromEdit: true,
          problems: response?.subscription?.problems,
          isIoTCard: true,
        });
      }
    },
    // eslint-disable-next-line
    [subscription]
  );

  return (
    <div className={classes.actionButtons}>
      <MoreActionsMenu subscription={subscription} onSwapClick={onSwapClick}>
        <Tooltip title={translate("TOOLTIP.MORE_ACTION")}>
          <i
            className={clsx("fas fa-ellipsis-h", classes.actionIcon)}
            style={{
              color: settings.colors.links,
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient, iotProducts }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id).or(void 0),
    selectedAccount: iotProducts.selectedAccount,
  };
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openShowHistory: showHistoryReduxActions.openShowHistory,
  setIsIoTCard: editSimcardReduxActions.setIsIoT,
  openSwapSimcard: swapSimcardReduxActions.openDialog,
  startLoading: loaderReduxActions.startSpinLoader,
  stopLoading: loaderReduxActions.stopSpinLoader,
  openErrorDialog: errorDialogReduxActions.openDialog,
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withSettings,
  withStyles(styles)
)(ActionButtons);
