const styles = theme => ({
  root: {
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#fff',
    margin: '5px',
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },
  addIcon: {
    fontSize: '20px',
    cursor: 'pointer'
  }
});

export default styles;
