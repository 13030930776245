export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    '& span': {
      padding: '0 5px'
    },
    '& a': {
      color: 'inherit'
    },
    link: {
      display: 'none'
    }
  }
};
