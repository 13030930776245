import React, { useState, cloneElement, useEffect, useRef } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv6';

import styles from '../styles';
import PrimaryButton from '../../customButtons/PrimaryButton';
import SecondaryButton from '../../customButtons/SecondaryButton';
import CustomDialogTitle from '../../customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../DraggableDialog';
import { runCustomJob } from '../actions/runCustomJob';
import CustomArrayFieldTemplate from '../../customForm/components/CustomArrayFieldTemplate';
import { getCustomJob } from '../actions/getCustomJob';
import { isDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';

export function CustomJobsRunDialog({
  classes,
  intl,
  customJobId,
  children,
  onOpenDialog,
  settings
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [customJob, setCustomJob] = useState({});
  const [schema, setSchema] = useState(void 0);
  const [uiSchema, setUiSchema] = useState({});

  const descriptionRef = useRef();

  useEffect(() => {
    if (open) {
      (async function() {
        const { job, uiSchema, schema } = await getCustomJob(customJobId);
        setCustomJob(job);
        setSchema(schema);
        setUiSchema(uiSchema);
        descriptionRef.current.innerHTML = job.description;
      })();
    }
  }, [open, customJobId]);

  const onOpen = async () => {
    onOpenDialog();
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    const isRunStarted = await runCustomJob(customJob, formData);

    if (isRunStarted) {
      setOpen(false);
    }
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      {customJob && (
        <Dialog
          maxWidth="lg"
          classes={{
            paper: classes.root
          }}
          PaperComponent={DraggableDialog}
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              onCloseDialog();
            }
          }}
        >
          <CustomDialogTitle onCloseClick={onCloseDialog}>
            {intl.formatMessage({
              id: 'CUSTOM_JOBS.RUN_CUSTOM_JOB'
            })}
          </CustomDialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.name}>{customJob.name}</div>
            <div
              className={classes.description}
              ref={descriptionRef}
              style={{ '& a': { color: settings.colors.links } }}
            ></div>
            <div className={classes.formContainer}>
              {isDefined(schema) ? (
                <Form
                  uiSchema={uiSchema}
                  schema={schema}
                  formData={formData}
                  validator={validator}
                  templates={{
                    ArrayFieldTemplate: CustomArrayFieldTemplate
                  }}
                  onChange={e => setFormData(e.formData)}
                >
                  <DialogActions className={classes.footer}>
                    <PrimaryButton onClick={handleConfirm}>
                      {intl.formatMessage({
                        id: 'START'
                      })}
                    </PrimaryButton>
                    <SecondaryButton onClick={onCloseDialog}>
                      {intl.formatMessage({
                        id: 'BUTTON.CANCEL'
                      })}
                    </SecondaryButton>
                  </DialogActions>
                </Form>
              ) : (
                <DialogActions className={classes.footer}>
                  <PrimaryButton onClick={handleConfirm}>
                    {intl.formatMessage({
                      id: 'START'
                    })}
                  </PrimaryButton>
                  <SecondaryButton onClick={onCloseDialog}>
                    {intl.formatMessage({
                      id: 'BUTTON.CANCEL'
                    })}
                  </SecondaryButton>
                </DialogActions>
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(CustomJobsRunDialog);
