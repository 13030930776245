import React, { useState, cloneElement, useMemo } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { saveAs } from 'file-saver';

import { fetchDetails } from '../../../modals/invoiceDetails/actions/getDetails';
import getExcel from '../actions/getExcel';
import { isDefined } from '../../../utils/isDefined';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

function DotsMenu({
  children,
  intl,
  clientId,
  data,
  fetchDetails,
  selectedAccount
}) {
  const invoiceId = useMemo(() => data.invoiceNumber, [data.invoiceNumber]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShowDetailsClick = () => {
    fetchDetails({
      clientId,
      invoiceId,
      invoiceAccount: data.account,
      account: selectedAccount
    });
    setAnchorEl(null);
  };
  const onDownloadClick = async () => {
    setAnchorEl(null);
    const { data, fileName } = await getExcel({
      invoiceId,
      clientId
    });

    if (isDefined(data) && isDefined(fileName)) {
      saveAs(data, fileName);
    }
  };
  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onShowDetailsClick}>
          {intl.formatMessage({
            id: 'INVOICES.SHOW_DETAILS'
          })}
        </MenuItem>
        {can(
          'export',
          createSubject('Quote::Invoice', {
            client_id: Number(clientId)
          })
        ) && (
          <MenuItem onClick={onDownloadClick}>
            {intl.formatMessage({
              id: 'INVOICES.DOWNLOAD_EXCEL'
            })}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

const mapStatesToProps = ({ invoices, selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id,
    selectedAccount: invoices.selectedAccount
  };
};

const mapDispatchToProps = {
  fetchDetails
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(DotsMenu);
