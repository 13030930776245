import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/completeStep';
import closeSwapCard from '../actions/closeSwapCard';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { translate } from '../../../i18n/I18nProvider';

const CompleteStep = ({
  classes,
  closeSwapCard,
  simcard = {},
  new_sim_card_icc = '',
  supportNumber
}) => {
  const is3Network = useMemo(() => simcard.mobile_network?.brand === '3', [
    simcard
  ]);

  return is3Network ? (
    <div className={classes.root}>
      <p>{translate('SWAP_SIMCARD.INFO_TEXT_2_3_NETWORK')}</p>
      <p>{translate('SWAP_SIMCARD.INFO_TEXT_3_3_NETWORK')}</p>
      <div className={classes.footer}>
        <PrimaryButton onClick={closeSwapCard}>
          {translate('BUTTON.CLOSE')}
        </PrimaryButton>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <p>
        {translate('SWAP_SIMCARD.COMPLETED_FIRST_PART_1')}{' '}
        <span>{simcard.msisdn}</span>{' '}
        {translate('SWAP_SIMCARD.COMPLETED_FIRST_PART_2')}{' '}
        <span>{new_sim_card_icc}</span>.
      </p>
      <p>{translate('SWAP_SIMCARD.COMPLETED_SECOND_PART')}</p>
      <p>
        {translate('SWAP_SIMCARD.COMPLETED_THIRD_PART')}{' '}
        <span>
          <a href={`tel:${supportNumber}`}>{supportNumber}</a>
        </span>
        .
      </p>
      <div className={classes.footer}>
        <PrimaryButton onClick={closeSwapCard}>
          {translate('BUTTON.CLOSE')}
        </PrimaryButton>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ swapSimcard, builder }) => {
  return {
    ...swapSimcard,
    supportNumber: builder.settings.supportNumber
  };
};

const mapDispatchToProps = {
  closeSwapCard
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(CompleteStep);
