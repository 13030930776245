import { isDefined } from '../../../utils/isDefined';
import changeObjectKeysCamelToUnderscore from '../../../utils/changeObjectKeysCamelToUnderscore';

const FORWARD_ALL_KEY = 'CFWD_ALL';

export default function mapDiversionData({ values, diversions }) {
  const { transferAll } = values;
  if (transferAll.isForwarded) {
    return forwardAllNumbers(transferAll, diversions);
  }

  return mapSeparateNumbers(values, diversions);
}

function forwardAllNumbers(transferAll, diversions) {
  return diversions.map(diversion => {
    const { userDiversionRules, ...restDiversion } = diversion;

    const userDiversionRule = {
      diversion_routing_rules_type: FORWARD_ALL_KEY,
      phone_number: transferAll.forwardNumber
    };

    return {
      ...changeObjectKeysCamelToUnderscore(restDiversion),
      user_diversion_rules: [userDiversionRule]
    };
  });
}

function mapSeparateNumbers(values, diversions) {
  const valuesKeys = Object.keys(values);

  return diversions.map(diversion => {
    const diversionChanges = valuesKeys.find(
      key => values[key].lineNumber === diversion.lineNumber
    );

    if (isDefined(diversionChanges)) {
      const { userDiversionRules, ...restDiversion } = diversion;

      const userDiversionRule = {
        diversion_routing_rules_type: FORWARD_ALL_KEY
      };

      if (values[diversionChanges].isForwarded) {
        userDiversionRule.phone_number = values[diversionChanges].forwardNumber;
      }

      return {
        ...changeObjectKeysCamelToUnderscore(restDiversion),
        user_diversion_rules: [userDiversionRule]
      };
    }

    return changeObjectKeysCamelToUnderscore(diversion);
  });
}
