import { URLS } from '../../../consts/endpoints';
import { postData, updateData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isUndefined } from 'lodash';

const phoneBookRegistrationRequest = async (mappedData, hideSnackbars) => {
  if (isDefined(mappedData)) {
    try {
      const response = isDefined(mappedData?.data?.id)
        ? await updateData({
            url: `${URLS.PHONE_BOOK_REGISTRATION}/${mappedData.data.id}`,
            data: mappedData
          })
        : await postData({
            url: URLS.PHONE_BOOK_REGISTRATION,
            data: mappedData
          });

      if (response.status === RESPONSE_CODES.SUCCESS && !hideSnackbars) {
        showSnackbar({
          intlTextKey: 'MESSAGE.STATUS_UPDATED',
          messageType: 'success'
        });
      }

      if (response.status === RESPONSE_CODES.CREATED && !hideSnackbars) {
        showSnackbar({
          intlTextKey: 'MESSAGE.STATUS_CREATED',
          messageType: 'success'
        });
      }

      if (isUndefined(response?.status)) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  }

  return false;
};

export default phoneBookRegistrationRequest;
