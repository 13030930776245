import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/informationSection';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import { isDefined } from '../../../utils/isDefined';
import withUserCheck from '../../../utils/withUserCheck';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';

export function TelephonyUsersMainSection({
  classes,
  form,
  selectsOptions = {},
  intl,
  generaldata = {},
  settings,
  defaultSettings
}) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'EDIT.INFO'
        })}
      </div>
      <div className={classes.container}>
        <div>
          <InputRow
            label="TABLE.USERNAME"
            name="mitelUserId"
            disabled={isDefined(generaldata.mitelUserId) ? true : false}
            infoIcon={true}
            infoIconTooltipIntlKey={'TOOLTIP.USERNAME_INFO'}
            mobileCustom
            {...form}
          />
          <InputRow
            label="USER.CUSTOM_ID"
            name="custom_id"
            infoIcon={true}
            infoIconText={`${intl.formatMessage({
              id: 'TOOLTIP.CUSTOM_USER_ID'
            })} ${Optional(settings?.title).or(defaultSettings?.title)}`}
            mobileCustom
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.FIRST_NAME"
            name="firstName"
            mobileCustom
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.LAST_NAME"
            name="lastName"
            mobileCustom
            {...form}
          />
          

          <InputRow name="email" label="TABLE.EMAIL" mobileCustom {...form} />

          <InputRow name="department" label="TABLE.DEPARTMENT" mobileCustom {...form} />
          <InputRow
            label="REQUEST_NEW_USER.COST_CENTER"
            name="cost_center"
            mobileCustom
            {...form}
          />
          <SelectRow
            name="language"
            label="LANG"
            options={selectsOptions.languages}
            menuPlacement="top"
            mobileCustom
            {...form}
          />
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ editUser }) => {
  return {
    selectsOptions: editUser.selectsOptions,
    generaldata: editUser.user.generalData
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(TelephonyUsersMainSection);
