export default {
  root: {
    width: '100%',
    fontWeight: 500,
    fontSize: '13px',
    color: '#6C7293',
    backgroundColor: '#F3F3F3',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
