const styles = () => {
  return {
    root: {
      marginBottom: '5px'
    },
    container: {
      padding: '10px 20px',
      backgroundColor: '#FDCCCC',
      color: '#FF0042',
      fontWeight: 700
    }
  };
};

export default styles;
