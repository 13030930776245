import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { mapDataForCreate, mapResponse } from './mapDataForRequest';

export default async function createCustomJob({ customJob }) {
  const mappedData = mapDataForCreate({ customJob });

  try {
    const response = await postData({
      url: `${URLS.CUSTOM_JOBS}`,
      data: { data: { ...mappedData } }
    });

    if (response.status === RESPONSE_CODES.CREATED) {
      showSnackbar({
        intlTextKey: 'MESSAGE.CUSTOM_JOB_CREATED',
        messageType: 'success'
      });
    }

    return {
      isSuccess: true,
      responseJob: mapResponse(response.data, customJob)
    };
  } catch {
    return { isSuccess: false };
  }
}
