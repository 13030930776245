import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_REFRESH = 'SET_REFRESH';
const RESET = 'RESET';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('CLIENTS', [
  SET_REFRESH,
  RESET,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  refresh: false,
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_REFRESH]: {
      return { ...state, refresh: action.refresh };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setRefresh: refresh => ({ type: actionTypes[SET_REFRESH], refresh }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  reset: () => ({ type: actionTypes[RESET] })
};

export const getters = {
  getClients: state => state.clients.clientsList
};
