import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

import styles from '../styles/problems';

function Problems({ problems = [], classes, intl }) {
  return (
    <table className={classes.root}>
      <tbody>
        <tr>
          <th>{intl.formatMessage({ id: 'SUBSCRIPTIONS.SEVERITY' })}</th>
          <th>{intl.formatMessage({ id: 'SUBSCRIPTIONS.EXCEPTION' })}</th>
          <th>{intl.formatMessage({ id: 'MESSAGE' })}</th>
        </tr>
        {problems.map(problem => (
          <tr key={problem.id}>
            <td className={classes.severity}>{problem.severity}</td>
            <td className={classes.cell}>{problem.exceptionMessage}</td>
            <td className={classes.cell}>{problem.message}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Problems);
