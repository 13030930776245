import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback
} from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import {
  mapTableOptions,
  setLocalization,
  TABLE_OPTIONS
} from '../../../consts/tableOptions';
import withDetailsView from '../../../utils/withDetailsView';
import Optional from '../../../utils/optional';
import styles from '../styles';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import tableColumns from './tableColumns';
import { actions as orderSimcardReduxActions } from '../../../modals/orderNewSimcard/reducers';
import { actions as reduxActions } from '../reducers';
import fetchSimcards, { mapQueryParams } from '../actions/fetchSimcards';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import DownloadCsv from '../../../partials/downloadCsv/DownloadCsv';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { CSV_HEADERS } from '../../../consts/csvHeaders';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import SimcardsTableHeader from './SimcardsTableHeader';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

function SimCards({
  intl,
  isLoading,
  orderSimcard,
  location,
  refresh,
  setRefresh,
  columns,
  setColumns,
  customJobs,
  active,
  classes,
  hasClientAccounts
}) {
  const [filtering, setFiltering] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);
  const client = useMemo(() => {
    return getIdFromUrl(location);
  }, [location]);

  const tableRef = useRef();

  const tableDefaultColumns = useMemo(
    () =>
      tableColumns({
        client
      }),
    // eslint-disable-next-line
    [client]
  );

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: isDefined(client) ? 'Simcards-1' : 'Simcards-Admin-1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  useEffect(() => {
    if (refresh) {
      tableRef.current.onQueryChange();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    tableRef.current.onQueryChange();
    // eslint-disable-next-line
  }, [client, active]);

  const fetchData = useCallback(
    query => {
      setPageSize(query.pageSize);
      return fetchSimcards({ query, client, active });
      // eslint-disable-next-line
    },
    [setPageSize, client, active]
  );

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'SimCards');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => (
            <DownloadCsv
              name="simCards"
              requestUrl={JSONAPI_URLS.SIM_CARDS}
              headers={CSV_HEADERS.SIM_CARDS}
              queryParams={mapQueryParams({ client })}
            />
          ),
          isFreeAction: true,
          onClick: () => {},
          tooltip: intl.formatMessage({ id: 'BUTTON.DOWNLOAD' })
        },
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      if (doesArrayHasLength(mappedJobs) && isDefined(client)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      if (isDefined(client)) {
        buttons = [
          ...buttons,
          {
            icon: () => <AddButton buttonText="BUTTON.NEW_SIM_CARD" />,
            onClick: () => orderSimcard(),
            isFreeAction: true,
            hidden:
              !hasClientAccounts ||
              !can(
                'update',
                createSubject('SimCard', { client_id: Number(client) })
              )
                ? true
                : false
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const localization = useMemo(() => {
    return {
      ...setLocalization(intl)
    };
    // eslint-disable-next-line
  }, [intl]);

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      pageSize,
      filtering
    };
  }, [filtering, pageSize]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: isDefined(client) ? 'Simcards-1' : 'Simcards-Admin-1'
      });
    },
    [setColumns, tableDefaultColumns, client]
  );

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <MTableToolbar
            classes={{
              title: classes.toolbarTitle,
              spacer: classes.toolbarSpacer,
              actions: classes.toolbarActions,
              highlight: classes.toolbarHighlight
            }}
            {...props}
          />
        )
      }}
      title={<SimcardsTableHeader isLoading={isLoading} />}
      icons={customTableIcons}
      actions={actions}
      localization={localization}
      columns={columns}
      data={fetchData}
      options={tableOptions}
      tableRef={tableRef}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ simCards, selectClient, loader, auth }) => {
  return {
    ...simCards,
    isLoading: loader.isSpinVisible,
    clientName: Optional(selectClient.selectedClient)
      .map(client => client.name)
      .or(''),
    customJobs: auth.customJobs,
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false)
  };
};

const mapDispatchToProps = {
  orderSimcard: orderSimcardReduxActions.openOrderSimcard,
  setRefresh: reduxActions.setRefresh,
  setColumns: reduxActions.setColumns
};

export default flowRight(
  withRouter,
  injectIntl,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SimCards);
