import differenceBy from 'lodash.differenceby';
import { isArray, isUndefined } from '../../../utils/isDefined';
import sortQueues from './sortQueues';

export default function mapQueuesForStore({
  queuesStats = [],
  queues,
  prevQueues,
  getOnlyStats,
  isAdminUser,
  sortQueues: shouldSortQueues
}) {
  if (isUndefined(queues) || !isArray(queues)) {
    return [];
  }
  const onlyStatsQueues = differenceBy(queuesStats, queues, 'id');
  const newQueues = queues.map(queue => {
    const statsForQueue = queuesStats.find(stats => stats.id === queue.id);
    const droppedOnTotalCalls = createCallsRatioString(statsForQueue);
    return {
      ...queue,
      showAgents: true,
      ...statsForQueue,
      droppedOnTotalCalls
    };
  });

  let completeQueues;

  if (!isAdminUser) {
    completeQueues = [...newQueues];
  } else {
    if (!getOnlyStats) {
      completeQueues = [...newQueues, ...mapAdminQueues(onlyStatsQueues)];
    } else {
      completeQueues = [...mapAdminQueues(queuesStats)];
    }
  }

  return prevQueues.length !== 0
    ? shouldSortQueues
      ? sortQueues(mergeQueues(prevQueues, completeQueues, shouldSortQueues))
      : mergeQueues(prevQueues, completeQueues, shouldSortQueues)
    : shouldSortQueues
    ? sortQueues(completeQueues)
    : completeQueues;
}

function mergeQueues(prev, next, shouldSortQueues) {
  if (shouldSortQueues) {
    return next.map(item => {
      const oldTableData = prev.find(elem => elem.id === item.id).tableData;
      return { ...item, tableData: { ...oldTableData } };
    });
  }
  
  return prev.map(item => {
    const newData = next.find(elem => elem.id === item.id);
    return { ...newData, tableData: { ...item.tableData } };
  });
}

function mapAdminQueues(queues) {
  if (!queues) {
    return [];
  }

  return queues.map(queue => {
    const droppedOnTotalCalls = createCallsRatioString(queue);
    return {
      ...queue,
      droppedOnTotalCalls
    };
  });
}

function createCallsRatioString(stats = {}) {
  return `${stats.droppedCallsForPeriod}/${stats.totalCallsForPeriod}`;
}
