import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import styles from '../styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

export function ActionsButtons({
  onCloseClick,
  handleSubmit,
  dirty,
  classes,
  intl
}) {
  return (
    <>
      <Button variant="outlined" onClick={onCloseClick}>
        {intl.formatMessage({
          id: 'BUTTON.CANCEL'
        })}
      </Button>
      <Button
        className={classes.saveButton}
        color="primary"
        variant="outlined"
        onClick={handleSubmit}
        disabled={!dirty}
      >
        {intl.formatMessage({
          id: 'BUTTON.SAVE'
        })}
      </Button>
      <Button
        className={classes.saveButton}
        color="primary"
        variant="outlined"
        onClick={value => {
          handleSubmit(value);
          return onCloseClick();
        }}
        disabled={!dirty}
      >
        {intl.formatMessage({
          id: 'BUTTON.SAVE_CLOSE'
        })}
      </Button>
    </>
  );
}

export default flowRight(injectIntl, withStyles(styles))(ActionsButtons);
