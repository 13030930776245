import React from 'react';
import objectPath from 'object-path';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';

import MenuItemText from './MenuItemText';
import MenuSubmenu from './MenuSubmenu';
import Optional from '../../../app/utils/optional';
import withSettings from '../../../app/utils/withSettings';

export class MenuItem extends React.Component {
  state = { mouseOver: false };

  asideLeftLIRef = React.createRef();
  asideLeftLinkRef = React.createRef();
  isDropdown = document.body.classList.contains('kt-aside-menu--dropdown');

  submenuToggle =
    this.props.item.toggle === 'click'
      ? 'click'
      : objectPath.get(this.props.item, 'submenu.type') === 'tabs'
      ? 'tabs'
      : 'hover';

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  mouseEnter = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu) {
      this.asideLeftLIRef.current.classList.add('kt-menu__item--hover');
      this.asideLeftLIRef.current.setAttribute(
        'data-ktmenu-submenu-toggle',
        this.submenuToggle
      );
    }
  };

  mouseEnterLink = event => {
    this.setState({ mouseOver: true });
    this.asideLeftLinkRef.current.style.backgroundColor = this.props.settings.colors.aside_menu_active;
  };

  mouseLeaveLink = event => {
    const isActive = this.isMenuItemIsActive(this.props.item);
    this.setState({ mouseOver: false });
    if (!isActive) {
      this.asideLeftLinkRef.current.style.backgroundColor = this.props.settings.colors.aside_menu;
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu && this.submenuToggle === 'hover') {
      this.asideLeftLIRef.current.classList.remove('kt-menu__item--hover');
      this.asideLeftLIRef.current.removeAttribute('data-ktmenu-submenu-toggle');
    }
  };

  isMenuItemIsActive = item => {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.props.currentUrl.indexOf(item.page) !== -1;
  };

  isMenuRootItemIsActive = item => {
    for (const subItem of item.submenu) {
      if (this.isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      item,
      currentUrl,
      parentItem,
      layoutConfig,
      clientId,
      settings
    } = this.props;
    const isActive = this.isMenuItemIsActive(item);
    const customLinkStyles = Optional(item.customStyles)
      .map(styles => styles.link)
      .or({});

    return (
      <li
        ref={this.asideLeftLIRef}
        aria-haspopup="true"
        data-placement="right"
        data-ktmenu-submenu-mode={item.mode}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        className={clsx(
          'kt-menu__item',
          {
            'kt-menu__item--submenu': item.submenu,
            'kt-menu__item--open kt-menu__item--here': isActive && item.submenu,
            'kt-menu__item--active kt-menu__item--here':
              isActive && !item.submenu,
            'kt-menu__item--icon-only': item['icon-only']
          },
          item['custom-class']
        )}
        data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
      >
        {!item.submenu && (
          <Link
            ref={this.asideLeftLinkRef}
            to={
              item.isUserLink
                ? `/user/${item.page}`
                : `/${clientId}/${item.page}`
            }
            className="kt-menu__link kt-menu__toggle"
            style={{
              ...customLinkStyles,
              backgroundColor: isActive
                ? settings.colors.aside_menu_active
                : settings.colors.aside_menu
            }}
            onClick={this.props.onLinkClick}
            onMouseEnter={this.mouseEnterLink}
            onMouseLeave={this.mouseLeaveLink}
          >
            <MenuItemText
              item={item}
              parentItem={parentItem}
              isActive={isActive}
              mouseOver={this.state.mouseOver}
            />
          </Link>
        )}

        {item.submenu && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            ref={this.asideLeftLinkRef}
            className="kt-menu__link kt-menu__toggle"
            style={{
              ...customLinkStyles,
              backgroundColor: isActive
                ? settings.colors.aside_menu_active
                : settings.colors.aside_menu
            }}
            onMouseEnter={this.mouseEnterLink}
            onMouseLeave={this.mouseLeaveLink}
          >
            <MenuItemText
              item={item}
              parentItem={parentItem}
              isActive={isActive}
              mouseOver={this.state.mouseOver}
            />
          </a>
        )}

        {item.submenu && (
          <div className="kt-menu__submenu">
            <span className="kt-menu__arrow" />

            {item['custom-class'] === 'kt-menu__item--submenu-fullheight' && (
              <div className="kt-menu__wrapper">
                <MenuSubmenu
                  item={item}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              </div>
            )}

            {item['custom-class'] !== 'kt-menu__item--submenu-fullheight' && (
              <MenuSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                onLinkClick={this.props.onLinkClick}
              />
            )}
          </div>
        )}
      </li>
    );
  }
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    clientId: Optional(selectClient.selectedClient)
      .map(client => client.id)
      .or(auth.defaultClient.id)
  };
};

export default flowRight(withSettings, connect(mapStatesToProps))(MenuItem);
