import React, { useEffect, useState } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import { connect } from 'react-redux';

import styles, { customSelectStyles } from './styles';
import updateCurrentRole from './actions/updateCurrentRole';
import getCurrentRole from './actions/getCurrentRole';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import Optional from '../../utils/optional';
import { isDefined } from '../../utils/isDefined';

const UserConnectRole = ({
  classes,
  intl,
  rolesOptions,
  connectId,
  updatedRole
}) => {
  const [currentRole, setCurentRole] = useState({});

  useEffect(() => {
    (async function fetchCurrentRole() {
      const currentRole = await getCurrentRole(connectId, rolesOptions);
      setCurentRole(currentRole);
    })();
    // eslint-disable-next-line
  }, [connectId]);

  useEffect(() => {
    if (isDefined(updatedRole)) {
      const mappedRole = rolesOptions.find(
        option => option.value === updatedRole
      );
      if (isDefined(mappedRole)) {
        setCurentRole(mappedRole);
      }
    }
    // eslint-disable-next-line
  }, [updatedRole]);

  const handleChange = async value => {
    if (value.value !== currentRole.value) {
      const updatedRole = await updateCurrentRole({
        currentRole: value,
        connectId,
        rolesOptions
      });

      setCurentRole(updatedRole);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'EDIT.ROLE'
        })}
      </div>
      <Select
        styles={reactSelectDefaultStyles}
        customStyles={customSelectStyles}
        className={classes.select}
        value={currentRole}
        onChange={handleChange}
        options={rolesOptions}
      />
    </div>
  );
};

const mapStatesToProps = ({ user }) => {
  return {
    rolesOptions: Optional(user.rolesOptions).or([]),
    connectId: user.connect30_user_id,
    updatedRole: user.updatedUserData.updatedRole
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserConnectRole);
