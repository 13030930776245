import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_ITEM } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { deleteData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { getUserCallLog } from './getUserCallLog';
import showSnackbar from '../../../utils/showSnackbar';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import { getUserVoicemails } from './getUserVoicemails';
import { getters } from '../reducers';
import { isDefined } from '../../../utils/isDefined';

export function deleteItem({
  itemId,
  deleteAll,
  allAction,
  action,
  isCallLog
}) {
  return {
    type: sagaActions[DELETE_ITEM],
    itemId,
    deleteAll,
    action,
    allAction,
    isCallLog
  };
}

export function* deleteCallLogItem({
  itemId,
  deleteAll = false,
  allAction = 'CLEAR_LOG',
  action = 'DELETE_LOG',
  isCallLog = true
}) {
  yield put(loaderReducActions.startSpinLoader());
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );

  let itemType = [];
  let untilItemId = null;

  if (deleteAll) {
    const firstElemetId = isCallLog
      ? yield select(getters.getFirstCallLogId)
      : yield select(getters.getFirstVoicemailsId);
    const itemTypes = isCallLog
      ? ['MISSED_CALL', 'INCOMING_CALL', 'OUTGOING_CALL']
      : ['VOICEMAIL'];

    const itemTypeString = itemTypes.reduce(
      (str, item) => {
        return `${str}&itemTypes=${item}`;
      },
      ['']
    );

    itemType = itemTypeString;
    untilItemId = firstElemetId;
  }

  try {
    const response = deleteAll
      ? yield call(deleteData, {
          url: `${baseUrl}/api/communicationlog/${clientDomain}/${mitelUserId}?t=${mitelToken}${
            isDefined(untilItemId) ? `&untilItemId=${untilItemId}` : ''
          }${itemType}`
        })
      : yield call(deleteData, {
          url: `${baseUrl}/api/communicationlog/${clientDomain}/${mitelUserId}/${itemId}?t=${mitelToken}`
        });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(showSnackbar, {
        intlTextKey: deleteAll ? `MESSAGE.${allAction}` : `MESSAGE.${action}`,
        messageType: 'success'
      });
    }
  } catch (e) {
    console.warn(e);
  } finally {
    isCallLog
      ? yield call(getUserCallLog, {
          mitelToken,
          baseUrl,
          clientDomain,
          mitelUserId
        })
      : yield call(getUserVoicemails, {
          mitelToken,
          baseUrl,
          clientDomain,
          mitelUserId
        });

    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchDeleteCallLogItem() {
  yield takeLatest(sagaActions[DELETE_ITEM], deleteCallLogItem);
}

export default watchDeleteCallLogItem();
