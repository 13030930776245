import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_SELECTS_OPTIONS } from './types';
import { actions as reduxActions } from '../reducers';
import selectsOptionsCreator from '../../../utils/selectOptionsCreator';

export function setSelectsOptions(clientId) {
  return {
    type: sagaActions[GET_SELECTS_OPTIONS],
    clientId
  };
}

export function* getSelectsOptions({ clientId }) {
  let selectsArray = [
    {
      endpoint: 'USERS',
      queryParams: {
        paginate: false,
        fields: { users: 'name' },
        filter: { client_id: { eq: clientId } }
      }
    }
  ];

  const selectOptions = yield call(selectsOptionsCreator, selectsArray);

  yield put(reduxActions.setSelectOptions(selectOptions));
}

function* watchGetSelectsOptions() {
  yield takeLatest(sagaActions[GET_SELECTS_OPTIONS], getSelectsOptions);
}

export default watchGetSelectsOptions();
