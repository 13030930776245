import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

import GeneralData from './GeneralData';
import ServiceInfos from './ServiceInfos';
import PricePlanInfo from './PricePlanInfo';
import styles from '../styles/networkData';
import { translate } from '../../../i18n/I18nProvider';

const NetworkData = ({ classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div
        className={classes.labelContainer}
        onClick={() => (open ? setOpen(false) : setOpen(true))}
      >
        {open ? (
          <i className="fas fa-chevron-down" onClick={() => setOpen(false)}></i>
        ) : (
          <i className="fas fa-chevron-right" onClick={() => setOpen(true)}></i>
        )}
        <div className={classes.label}>{`${translate('MOBILE')} ${translate('TABLE.NETWORK')}`}</div>
      </div>

      {open && (
        <div className={classes.content}>
          <div className={classes.container}>
            <GeneralData />
          </div>
          <div className={classes.container}>
            <ServiceInfos />
          </div>
          <div className={classes.container}>
            <PricePlanInfo />
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(NetworkData);
