import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { translate } from '../../../i18n/I18nProvider';
import { isUndefined } from '../../../utils/isDefined';
import styles from '../styles/differenceCell';

function DifferencesCell({ data = {}, classes, showAllValues }) {
  const { diff = {} } = data;

  const diffKeys = Object.keys(diff);
  return diffKeys.map(key => {
    if (diff[key][2] && !showAllValues) {
      return null;
    }

    return (
      <div className={classes.root} key={key}>
        <div className={classes.label}>{key}</div>
        <div>{mapValue(diff[key][0])}</div>
        <i className={clsx('fal fa-long-arrow-right', classes.icon)}></i>
        <div>{mapValue(diff[key][1])}</div>
      </div>
    );
  });
}

const mapStatesToProps = ({ showHistory }) => {
  return {
    showAllValues: showHistory.showAllValues
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(DifferencesCell);

function mapValue(value) {
  if (isUndefined(value)) {
    return translate('EMPTY_WORD');
  }

  switch (value) {
    case true: {
      return 'true';
    }
    case false: {
      return 'false';
    }
    default:
      return value;
  }
}
