import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isUndefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';

export default async function getCurrentRole(userId, rolesOptions) {
  if (isUndefined(userId)) {
    return {};
  }
  let currentRole = {};
  try {
    const { data } = await getData({
      options: {
        url: `${OUTSIDE_API.USERS}/${userId}/roles/current`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    currentRole = { ...data };
  } finally {
    const mappedRole = rolesOptions.find(
      option => option.value === currentRole.role
    );
    return Optional(mappedRole).or({});
  }
}
