export default {
  actionButtons: {
    display: 'flex',
    '& i': {
      margin: '5px 10px',
      cursor: 'pointer'
    }
  },
  actionCell: { padding: '0 5px', color: '#93A2DD' },
  icon: {
    margin: '5px 10px',
    cursor: 'pointer',
    fontSize: '18px',
    '&:hover': { filter: 'brightness(1.5)' }
  }
};
