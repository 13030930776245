import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/problems';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';
import { doesArrayHasLength, isArray } from '../../../utils/isDefined';

const Problems = ({ problems = [], classes, settings }) => {
  const mappedProblems = useMemo(() => {
    if (!isArray(problems)) {
      return [];
    }

    return problems;
  }, [problems]);
  
  return (
    doesArrayHasLength(mappedProblems) && (
      <div className={classes.root}>
        {mappedProblems.map(problem => {
          const icon =
            problem.severity === 'MAJOR' ? (
              <i
                className={clsx('fa fa-info-circle', classes.icon)}
                style={{ color: settings.colors.danger }}
              ></i>
            ) : (
              <i className={clsx('fa fa-exclamation-circle', classes.icon)}></i>
            );
          return (
            <div key={problem.id}>
              {icon}
              {problem.message}
            </div>
          );
        })}
      </div>
    )
  );
};

export default flowRight(withSettings, withStyles(styles))(Problems);
