import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_PARTNERS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import Optional from '../../../utils/optional';
import getFullTableData from '../../../utils/getFullTableData';

export function getPartners() {
  return {
    type: sagaActions[GET_PARTNERS]
  };
}

export function* fetchPartners() {
  yield put(loaderReducActions.startSpinLoader());
  const QUERY_PARAMS = {
    include: 'clients',
    fields: { clients: ['id'] }
  };

  const data = yield call(
    getFullTableData,
    JSONAPI_URLS.PARTNERS,
    QUERY_PARAMS
  );

  const partners = yield call(mapPartners, data);

  yield put(reduxActions.setPartners(partners));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetPartners() {
  yield takeLatest(sagaActions[GET_PARTNERS], fetchPartners);
}

export default watchGetPartners();

export function mapPartners(partners) {
  return partners.map(partner => {
    const { clients } = partner;
    const clientsCount = Optional(clients)
      .map(clients => clients.length)
      .or(0);
    return { ...partner, clientsCount };
  });
}
