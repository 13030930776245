import { withStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import styles, { dataRowCustomStyles } from "../styles/additionslData";
import DataRow from "../../../partials/Datarow/DataRow";
import { translate } from "../../../i18n/I18nProvider";
import Optional from "../../../utils/optional";
import {
  isDefined,
  isNumber,
  isStringDefined,
  isUndefined,
} from "../../../utils/isDefined";
import withSettings from "../../../utils/withSettings";
import DatalimitMenu from "./DatalimitMenu";
import clsx from "clsx";

const DatalimitUsage = ({ datalimitData, classes, settings }) => {
  const dataRowCustomClasses = {
    value: classes.datarowUsageValue,
    root: classes.datarowRot,
  };

  const { usage, percentage, usageColor } = findUsageData(
    datalimitData?.accumulated_data_usage_month,
    datalimitData?.block_limit_mb
  );

  const {
    usage: fairUsage,
    percentage: fairPercentage,
    usageColor: fairUsageColor,
  } = findFairUsageData(
    datalimitData?.accumulated_data_usage_month,
    datalimitData?.fair_usage_limit
  );

  return (
    <div className={classes.phoneBookRoot}>
      <div className={classes.label}>
        {translate("DATA_LIMIT.CURRENT_MONTH_USAGE")}
        {isDefined(datalimitData) && (
          <DatalimitMenu datalimit={datalimitData}>
            <i className={clsx("fas fa-ellipsis-h", classes.dotsIcon)} />
          </DatalimitMenu>
        )}
      </div>
      <div className={classes.columnsContainer}>
        <div className={classes.columnContainer}>
          <DataRow
            label={translate("USAGE_GB")}
            value={
              <div className={classes.usageRow}>
                {datalimitData?.no_limit ? (
                  <div>{`${usage} GB`}</div>
                ) : (
                  isDefined(datalimitData) && (
                    <div className={classes.barContainer}>
                      <div
                        className={classes.usageBar}
                        style={{
                          backgroundColor: settings.colors[usageColor],
                          width: percentage,
                        }}
                      ></div>
                      <div className={classes.usage}>
                        {isDefined(usage) ? `${usage} GB` : ""}
                      </div>
                    </div>
                  )
                )}
                <div className={classes.limitValue}>
                  {datalimitData?.no_limit
                    ? "No limit"
                    : Optional(datalimitData?.block_limit_mb)
                        .map(limit =>
                          isNumber(limit)
                            ? `${calculateMbToGb(limit, true)} GB`
                            : limit
                        )
                        .or("")}
                </div>
              </div>
            }
            customClasses={dataRowCustomClasses}
            dense
          />
          <DataRow
            label={translate("DATA_LIMITS.ROAMING_EU_GB")}
            value={
              <div className={classes.usageRow}>
                {datalimitData?.no_limit ? (
                  <div>{`${fairUsage} GB`}</div>
                ) : (
                  isDefined(datalimitData) && (
                    <div className={classes.barContainer}>
                      <div
                        className={classes.usageBar}
                        style={{
                          backgroundColor: settings.colors[fairUsageColor],
                          width: fairPercentage,
                        }}
                      ></div>
                      <div className={classes.usage}>
                        {" "}
                        {isStringDefined(fairUsage) ? `${fairUsage} GB` : ""}
                      </div>
                    </div>
                  )
                )}
                <div className={classes.limitValue}>
                  {datalimitData?.no_limit
                    ? "No limit"
                    : Optional(datalimitData?.fair_usage_limit)
                        .map(limit =>
                          isNumber(limit)
                            ? `${calculateMbToGb(limit, true)} GB`
                            : limit
                        )
                        .or("")}
                </div>
              </div>
            }
            customClasses={dataRowCustomClasses}
            customStyles={dataRowCustomStyles}
            dense
          />
          <DataRow
            label={translate("DATA_LIMITS.ROAMING_WORLD")}
            value={Optional(
              datalimitData?.accumulated_data_usage_month?.data_roam_others
            )
              .map(usage =>
                isNumber(usage) ? `${calculateMbToGb(usage)} GB` : usage
              )
              .or("")}
            customClasses={dataRowCustomClasses}
            dense
          />
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  const { datalimitData } = checkNumber;
  return {
    datalimitData,
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(DatalimitUsage);

const findUsageData = (monthUsage, limit) => {
  const standard = monthUsage?.data;
  const fair = monthUsage?.data_roam_home;

  if (isUndefined(standard) && isUndefined(fair)) {
    return createUsageParams(void 0, limit);
  }

  const usage = Optional(standard).or(0) + Optional(fair).or(0);

  return createUsageParams(usage, limit);
};

const findFairUsageData = (monthUsage, limit) => {
  const usage = monthUsage?.data_roam_home;

  if (isUndefined(usage)) {
    return { fairUsage: "", fairPercentage: 0 };
  }

  return createUsageParams(usage, limit);
};

const createUsageParams = (usage, limit) => {
  if (isUndefined(usage)) {
    return { usage: "", percentage: 0 };
  }

  const percentage = ((usage * 100) / limit).toFixed(0);

  return {
    usage: calculateMbToGb(usage),
    percentage: percentage > 100 ? 100 : Number(percentage),
    usageColor:
      percentage <= 80 ? "success" : percentage >= 100 ? "danger" : "warning",
  };
};

const calculateMbToGb = (number, inGB = false) => {
  if (!isNumber(number)) {
    return "";
  }

  const result = (number / 1000).toFixed(2);

  if (inGB) {
    return Number(result).toFixed(0);
  }

  return result !== 0 ? result.toString().replace(".", ",") : "0,00";
};
