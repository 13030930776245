import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

const mapDefaultAddress = values => {
  let additionalValues = null;
  let shipping_address = '';

  if (isDefined(values.account?.primaryContact?.address)) {
    const { address } = values.account?.primaryContact;
    const addresText = `${address.lineOne} \n${address.zipCode} ${
      address.city
    } ${Optional(address.country)
      .map(country =>
        country.toLowerCase() !== 'denmark' &&
        country.toLowerCase() !== 'danmark'
          ? `\n ${country}`
          : ''
      )
      .or('')}`;
    additionalValues = [{ label: addresText, value: addresText }];
    shipping_address = addresText;
  }

  return {
    shipping_address,
    name: Optional(values.account?.primaryContact?.company).or(''),
    att: `${Optional(values.account?.primaryContact?.firstName).or(
      ''
    )} ${Optional(values.account?.primaryContact?.lastName).or('')}`,
    additionalValues
  };
};

export default mapDefaultAddress;
