import { withStyles } from '@material-ui/styles';
import React from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { SortableHandle } from 'react-sortable-hoc';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import styles from '../styles/scheduleDetails';
import VoicePrompt from './VoicePrompt';
import TableActionButtons from './TableActionButtons';
import ScheduleStatusCell from './ScheduleStatusCell';
import { mapStatus } from '../actions/scheduleDetailsUtils';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { isDefined } from '../../../utils/isDefined';
import DetailCell from './DetailCell';
import checkIfExpired from '../actions/checkIfExpired';

function ScheduleDetailRow({ classes, data, group, clientId, intl }) {
  const expired = checkIfExpired(data);
  return (
    <TableRow className={classes.row}>
      {can(
        'update',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) && (
        <TableCell className={classes.tableDragColumn}>
          {data.name !== 'default' && (
            <DragHandle
              tooltip={intl.formatMessage({
                id: 'SCHEDULES.TOOLTIP_DRAG_HANDLE'
              })}
            />
          )}
        </TableCell>
      )}
      {(can(
        'update',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) ||
        can(
          'delete',
          createSubject('Mitel::Schedule', { client_id: Number(clientId) })
        )) && (
        <TableCell className={classes.tableActions}>
          <TableActionButtons data={data} group={group} />
        </TableCell>
      )}
      <TableCell>
        <DetailCell data={data.name} expired={expired} />
      </TableCell>
      <TableCell>
        <DetailCell data={data.duration} expired={expired} />
      </TableCell>
      <TableCell>
        <DetailCell
          data={
            <ScheduleStatusCell
              status={mapStatus(data.type, data.forwardNumber)}
            />
          }
          expired={expired}
        />
      </TableCell>
      <TableCell>
        <DetailCell
          data={data.promptText}
          expired={expired && isDefined(data.promptText)}
        />
      </TableCell>
      <TableCell className={classes.voicePrompt}>
        <VoicePrompt prompt={data.prompt} />
      </TableCell>
    </TableRow>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(ScheduleDetailRow);

const DragHandle = SortableHandle(({ tooltip = ' ' }) => {
  return (
    <Tooltip title={tooltip}>
      <i className="fal fa-arrows" style={{ cursor: 'pointer' }}></i>
    </Tooltip>
  );
});