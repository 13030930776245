import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import { actions as reduxActions } from '../reducers';
import GroupUsers from './GroupUsers';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import { doesArrayHasLength } from '../../../utils/isDefined';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import { fetchGroups } from '../actions/getGroups';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

export function GroupsTable({
  intl,
  isLoading,
  columns,
  setColumns,
  groups,
  customJobs,
  fetchGroups,
  connectClientId
}) {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchGroups(connectClientId);
    // eslint-disable-next-line
  }, [connectClientId]);

  useEffect(() => {
    if (refresh) {
      fetchGroups(connectClientId);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Groups'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Groups');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Groups'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const detailPanel = useCallback(({ rowData }) => {
    return <GroupUsers name={rowData.name} />;
  }, []);

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="MENU.GROUPS"
          icon={<i className="fas fa-users"></i>}
          isLoading={isLoading}
        />
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={groups}
      options={options}
      localization={localization}
      detailPanel={detailPanel}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ groups, loader, auth, selectClient }) => {
  return {
    ...groups,
    isLoading: loader.isSpinVisible,
    customJobs: auth.customJobs,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  fetchGroups
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(GroupsTable);
