import { isDefined, isUndefined } from './isDefined';
import customParseNumber from './customParseNumber';

const NUMBER_LENGTH_TO_CHANGE = 8;

export default function mapNumberToDisplay(number) {
  if (isUndefined(number)) {
    return '';
  }

  const stringNumber = number.toString();

  if (stringNumber.length <= NUMBER_LENGTH_TO_CHANGE) {
    return stringNumber;
  }

  let phoneNumber = customParseNumber(`+${stringNumber}`);

  if (isDefined(phoneNumber)) {
    return phoneNumber.formatInternational();
  }

  return stringNumber;
}
