import { OUTSIDE_API } from '../../../consts/endpoints';
import { mitelRequest } from '../../../utils/mitelRequest';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';

const getCdrHooks = connectClientId => {
  return async dispatch => {
    const { data } = await mitelRequest({
      options: {
        url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/cdr_hooks`
      },
      errorsToPass: [RESPONSE_CODES.FORBIDDEN],
      hideSnackbarOnPassErrors: true
    });

    dispatch(reduxActions.setCdrHooks(data));
  };
};

export default getCdrHooks;
