import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_ADMINS = 'SET_ADMINS';
const RESET_ADMINS = 'RESET_ADMINS';

export const actionTypes = createReduxActionTypes('DASHBOARD', [
  SET_ADMINS,
  RESET_ADMINS
]);

const DEFAULT_STATE = {
  admins: [],
  activities: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ADMINS]: {
      const { admins, activities } = action;
      return { ...state, admins, activities };
    }
    case actionTypes[RESET_ADMINS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setAdmins: ({ admins, activities }) => ({
    type: actionTypes[SET_ADMINS],
    admins,
    activities
  }),
  resetAdmins: () => ({ type: actionTypes[RESET_ADMINS] })
};
