import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_EDIT_RANGE = 'OPEN_EDIT_RANGE';
const RESET_EDIT_RANGE = 'RESET_EDIT_RANGE';

export const actionTypes = createReduxActionTypes('EDIT_RANGE', [
  OPEN_EDIT_RANGE,
  RESET_EDIT_RANGE
]);

const DEFAULT_STATE = {
  open: false,
  range: {},
  selectOptions: {},
  isEdit: true
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_EDIT_RANGE]: {
      const {
        range,
        selectOptions,
        resetValues,
        isEdit,
        lookupNumber,
        fromRightPanel,
        fromLookup,
        clientId,
        isBeTable,
        fromPhoneBook
      } = action;
      return {
        ...state,
        open: true,
        range,
        selectOptions,
        resetValues,
        isEdit,
        lookupNumber,
        fromRightPanel,
        fromLookup,
        clientId,
        isBeTable,
        fromPhoneBook
      };
    }
    case actionTypes[RESET_EDIT_RANGE]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditRange: ({
    range,
    selectOptions,
    resetValues,
    isEdit,
    lookupNumber,
    fromRightPanel,
    fromLookup,
    clientId,
    isBeTable,fromPhoneBook
  }) => ({
    type: actionTypes[OPEN_EDIT_RANGE],
    range,
    selectOptions,
    resetValues,
    isEdit,
    lookupNumber,
    fromRightPanel,
    fromLookup,
    clientId,
    isBeTable,
    fromPhoneBook
  }),
  closeEditRange: () => ({ type: actionTypes[RESET_EDIT_RANGE] })
};
