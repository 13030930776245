import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_USERS = 'GET_USERS';
export const LOCK_USER = 'LOCK_USER';
export const DELETE_USER = 'DELETE_USER';

export const actions = createSagaActionTypes('USERS', [
  GET_USERS,
  LOCK_USER,
  DELETE_USER
]);
