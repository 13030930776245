import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, OPEN_DATALIMIT } from './types';
import { actions as reduxActions } from '../reducers';
import mapDataToEdit from './mapDataToEdit';
import { isDefined } from '../../../utils/isDefined';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';

export function openEditDatalimit({
  datalimit,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  return {
    type: sagaActions[OPEN_DATALIMIT],
    datalimit,
    fromUserDetails,
    fromLookup,
    fromRightPanel,
    lookupNumber,
    datalimitId
  };
}

export function* setDatalimit({
  datalimit,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  let fetchedDatalimit;

  if (isDefined(datalimitId)) {
    const queryParams = {
      include: ['client']
    };
    try {
      const { data } = yield call(
        getData,
        urlCreator(`${URLS.DATA_LIMITS}/${datalimitId}.json`, queryParams)
      );

      fetchedDatalimit = data;
    } catch {
      fetchedDatalimit = {};
    }
  }

  const { mappedDatalimit, clientId } = yield call(
    mapDataToEdit,
    isDefined(datalimitId) ? fetchedDatalimit : datalimit
  );

  yield put(
    reduxActions.openEditDatalimit({
      datalimit: mappedDatalimit,
      fromUserDetails,
      fromLookup,
      fromRightPanel,
      lookupNumber,
      datalimitId,
      clientId
    })
  );
}

function* watchSetDatalimit() {
  yield takeLatest(sagaActions[OPEN_DATALIMIT], setDatalimit);
}

export default watchSetDatalimit();
