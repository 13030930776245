import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import { formatDate } from '../../../modals/showHistory/actions/getHistory';
import ActionButtons from './ActionButtons';
import DifferencesCell from './DifferencesCell';
import TypeCell from './TypeCell';

const tableColumns = ({ selectedClient }) => {
  return [
    {
      title: translate('TABLE.ACTIONS'),
      render: rowData => (
        <ActionButtons rowData={rowData} clientId={selectedClient.value} />
      ),
      disableClick: true,
      width: '40px',
      align: 'center',
      sorting: false,
      filtering: false
    },
    {
      title: translate('TABLE.TYPE'),
      field: 'item_type',
      render: rowData => (
        <TypeCell rowData={rowData} selectedClient={selectedClient} />
      )
    },
    {
      title: translate('ID'),
      field: 'item_id'
    },
    {
      title: translate('TIME'),
      field: 'created_at',
      render: rowData => formatDate(rowData.created_at),
      filtering: false
    },
    {
      title: translate('ACTION'),
      field: 'event'
    },
    {
      title: translate('RESPONSIBLE'),
      field: 'whodunnit'
    },
    {
      title: translate('DIFFERENCES'),
      render: rowData => <DifferencesCell data={rowData} />,
      sorting: false,
      filtering: false
    }
  ];
};

export default tableColumns;
