import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/informationSection';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import withUserCheck from '../../../utils/withUserCheck';
import ActionsButtons from './ActionsButtons';
import { isDefined } from '../../../utils/isDefined';
import CustomAddressSelect from '../../../partials/customAddressSelect/CustomAddressSelect';

export function InformationSection({
  classes,
  form,
  selectsOptions = {},
  isSuperAdmin,
  isExistingClient,
  isNonClientAdminUser,
  clientId
}) {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <InputRow
            label="EDIT.CUSTOMER_NAME"
            name="name"
            {...form}
            disabled={!isNonClientAdminUser || isExistingClient}
          />
          <InputRow label="CLIENT.DISPLAY_NAME" name="display_name" {...form} />
          <InputRow label="EDIT.DESCRIPTION" name="comment" {...form} />
          <InputRow label="NOTES" name="notes" {...form} />
          <InputRow
            label="EDIT.PRIMARY_CONTACT"
            name="Primary contact"
            {...form}
          />
          <SelectRow
            name="partner_id"
            label="PARTNER"
            options={selectsOptions.partners}
            isDisabled={!isSuperAdmin}
            {...form}
          />

          <InputRow name="admin_contact" label="EDIT.ADMIN_NAME" {...form} />
          <InputRow name="admin_phone" label="EDIT.ADMIN_PHONE" {...form} />
        </div>
        <div>
          <InputRow name="admin_email" label="EDIT.ADMIN_EMAIL" {...form} />
          <SelectRow
            name="vip_status"
            label="VIP_STATUS"
            options={selectsOptions.vip}
            isDisabled={!isNonClientAdminUser}
            {...form}
          />
          <SelectRow
            name="csbc_routing"
            label="PLATFORM"
            options={selectsOptions.platform}
            isDisabled={!isSuperAdmin}
            {...form}
          />
          <InputRow
            name="mitel_prov_url"
            label="EDIT.CONNECT_MITEL"
            disabled={!isNonClientAdminUser}
            {...form}
          />
          <InputRow
            name="snom_prov_url"
            label="EDIT.CONNECT_SNOM"
            disabled={!isNonClientAdminUser}
            {...form}
          />
          <InputRow
            name="connect30_domain"
            label="EDIT.CONNECT_DOMAIN"
            disabled={!isNonClientAdminUser}
            {...form}
          />
          {clientId && (
            <CustomAddressSelect
              selectName="default_address"
              customLabel="ADDRESS"
              canAddAddress={false}
              idAsValue={true}
              splitLines={true}
              clientId={clientId}
              disableReload={true}
              fieldProps={form}
              menuPlacement="top"
              additionalValues={[]}
            />
          )}
        </div>
      </div>
      <footer className={classes.footer}>
        <ActionsButtons
          handleSubmit={form.handleSubmit}
          dirty={form.dirty}
          errors={form.errors}
        />
      </footer>
    </div>
  );
}

const mapStatesToProps = ({ editClient }) => {
  return {
    isExistingClient: isDefined(editClient.client.id)
  };
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(InformationSection);
