import React, { useCallback, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';

import styles from './styles';
import flowRight from 'lodash.flowright';
import history from '../../../history';
import { connect } from 'react-redux';
import { isDefined } from '../../utils/isDefined';
import createInitials from '../../utils/createUserInitials';
import { getUserPhoto } from './actions/getPhoto';
import clsx from 'clsx';
import withSettings from '../../utils/withSettings';
import Optional from '../../utils/optional';

function UserAvatar({
  classes,
  photo,
  name,
  getUserPhoto,
  userConnectId,
  mouseEntered,
  defaultSettings,
  onLinkClick,
  connectDomain,
  settings
}) {
  useEffect(() => {
    getUserPhoto({ userId: userConnectId, connectDomain });
  }, [userConnectId, getUserPhoto, connectDomain]);

  const onAvatarClick = useCallback(() => {
    history.push('/admin/userSettings');
    onLinkClick();
  }, [onLinkClick]);

  const initials = useMemo(() => {
    if (isDefined(name)) {
      const [firstName, lastName] = name.split(' ');
      return createInitials(firstName, lastName);
    } else {
      return 'Photo';
    }
  }, [name]);

  return (
    <div className={classes.root} onClick={onAvatarClick}>
      <div className={classes.photo}>
        {isDefined(photo) && photo.constructor.name === 'Blob' ? (
          <img
            src={URL.createObjectURL(photo)}
            className={classes.photo}
            alt="Empty"
          ></img>
        ) : (
          <div className={classes.initials}>{initials}</div>
        )}
      </div>
      <div
        className={clsx(classes.name, { [classes.visible]: mouseEntered })}
        style={{
          color: Optional(settings.colors.aside_menu_text).or(
            defaultSettings.colors.aside_menu_text
          )
        }}
      >
        {name}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ auth }) => {
  return {
    photo: auth.photo,
    name: auth.user.name,
    userConnectId: auth.user.connect30_user_id,
    connectDomain: auth.defaultClient.connect30_domain
  };
};

const mapDispatchToProps = {
  getUserPhoto
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserAvatar);
