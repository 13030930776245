import XMLParser from 'react-xml-parser';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';

export default function agentInCallListener({
  mitelToken,
  baseUrl,
  clientDomain,
  userId
}) {
  const sse = new EventSource(
    `${baseUrl}/api/contacts/v1/info/v1/${clientDomain}/${userId}/incall?t=${mitelToken}`
  );

  sse.onmessage = e =>
    store.dispatch(reduxActions.setUpdatedQueues(getRealtimeData(e.data)));
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
  return () => {
    sse.close();
  };
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);
  const state = Optional(xml.getElementsByTagName('state')[0])
    .map(state => state.value)
    .or(void 0);

  const entity = Optional(xml?.attributes?.entity)
    .map(data => data.split(':')[1])
    .or(void 0);

  const queues = store.getState().queues.queues;
  const queuesGroupedByUser = store.getState().queues.queuesGroupedByUser;

  if (isUndefined(state)) {
    return { queues, queuesGroupedByUser };
  }

  const updatedQueues = queues.map(queue => {
    const { agents = [] } = queue;

    const updatedAgents = agents.map(agent => {
      if (agent.id !== entity) {
        return agent;
      }

      return {
        ...agent,
        incall: state === 'confirmed' ? true : false
      };
    });
    return {
      ...queue,
      agents: updatedAgents
    };
  });

  const updatedMappedQueues = queuesGroupedByUser.map(el => {
    if (el.connectId)
      if (el.connectId !== entity) {
        return el;
      }

    return { ...el, incall: state === 'confirmed' ? true : false };
  });

  return { queues: updatedQueues, queuesGroupedByUser: updatedMappedQueues };
}
