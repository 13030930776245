import { URLS } from '../../../consts/endpoints';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { getData } from '../../../utils/http';
import { doesArrayHasLength, isStringDefined } from '../../../utils/isDefined';

export default async function getTemplates() {
  try {
    const templates = await getData(URLS.CONFIG_TEMPLATES);

    if (doesArrayHasLength(templates)) {
      const mappedTemplates = templates.reduce((arr, template) => {
        const { name } = template;

        if (isStringDefined(name)) {
          return [
            ...arr,
            {
              ...template,
              value: name,
              label: capitaliseFirstLetter(name).replaceAll('_', ' ')
            }
          ];
        }

        return arr;
      }, []);

      return mappedTemplates;
    }
  } catch {
    return void 0;
  }
}
