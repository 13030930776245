import { Dialog } from '@material-ui/core';
import React, { cloneElement, useState } from 'react';
import { PhotoshopPicker } from 'react-color';

export default function ColorPicker({ color, children, handlePickerChange }) {
  const [open, setOpen] = useState(false);
  const [pickerColor, setPickerColor] = useState(color);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const closeColorPicker = () => {
    setPickerColor(color);
    setOpen(false);
  };

  const onChange = color => {
    setPickerColor(color.hex);
  };

  const handleChangeComplete = () => {
    handlePickerChange(pickerColor);
    setOpen(false);
  };
  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog open={open} onClose={closeColorPicker}>
        <PhotoshopPicker
          color={pickerColor}
          onCancel={closeColorPicker}
          onAccept={handleChangeComplete}
          onChange={onChange}
        />
      </Dialog>
    </>
  );
}
