import { createSagaActionTypes } from '../../../utils/actionFactory';
export const ATTORNEY_REQUEST = 'ATTORNEY_REQUEST';
export const NEW_USER_REQUEST = 'NEW_USER_REQUEST';
export const GET_SELECT_OPTIONS = 'GET_SELECT_OPTIONS';
export const GET_SUBSRIPTIONS = 'GET_SUBSRIPTIONS';

export const actions = createSagaActionTypes('REQUEST_USER_WIZARD', [
  ATTORNEY_REQUEST,
  NEW_USER_REQUEST,
  GET_SELECT_OPTIONS,
  GET_SUBSRIPTIONS
]);
