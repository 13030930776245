import flowRight from 'lodash.flowright';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import styles from '../styles';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { setClient } from '../../../modals/editClient/actions/setClient';
import { openEditDatalimit } from '../../../modals/editDatalimit/actions/setDatalimit';
import { setTelephonyUser } from '../../../modals/editUser/actions/setTelephonyUser';
import { setUser } from '../../../modals/editUser/actions/setUser';
import { setRange } from '../../../modals/editRange/actions/setRange';
import fetchUser from '../actions/fetchUser';
import { isDefined } from '../../../utils/isDefined';
import { withStyles } from '@material-ui/styles';

const EditIcon = ({
  classes,
  clientId,
  openEditSimcard,
  rowData,
  openEditClient,
  openEditDatalimit,
  openEditRange,
  openEditTelephonyUser,
  openEditUser
}) => {
  const onEditSimcard = useCallback(
    () => {
      openEditSimcard({
        simcardId: rowData.item_id,
        clientId,
        fromChangelog: true
      });
    },
    // eslint-disable-next-line
    [clientId, rowData.item_id]
  );

  const onEditClient = useCallback(
    () => {
      openEditClient({ id: clientId }, true);
    },
    // eslint-disable-next-line
    [clientId, rowData.item_id]
  );

  const onEditDatalimit = useCallback(
    () => {
      openEditDatalimit({ datalimitId: rowData.item_id });
    },
    // eslint-disable-next-line
    [rowData.item_id]
  );

  const onEditRange = useCallback(
    () => {
      openEditRange({ rangeId: rowData.item_id });
    },
    // eslint-disable-next-line
    [rowData.item_id]
  );

  const onEditUser = useCallback(
    async () => {
      const { user } = await fetchUser({ userId: rowData.item_id });

      if (isDefined(user)) {
        return isDefined(user.connect30_user_id)
          ? openEditTelephonyUser({
              userId: user.connect30_user_id,
              clientConnectId: user.client?.connect30_domain,
              isTelephonyUser: true,
              roles: user.roles,
              id: rowData.item_id
            })
          : openEditUser({
              user,
              clientId,
              isDomainProvided: isDefined(user.client?.connect30_domain),
              isTelephonyUser: false
            });
      }
    },
    // eslint-disable-next-line
    [rowData.item_id]
  );

  switch (rowData?.item_type) {
    case 'SimCard':
      return (
        <div>
          {can(
            'update',
            createSubject(rowData?.item_type, { client_id: clientId })
          ) && (
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.EDIT_SIMCARD"
              onClick={onEditSimcard}
              iconClassName="far fa-edit"
            />
          )}
        </div>
      );
    case 'Client':
      return (
        <div>
          {can(
            'update',
            createSubject(rowData?.item_type, { client_id: clientId })
          ) && (
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.EDIT_CLIENT"
              onClick={onEditClient}
              iconClassName="far fa-edit"
            />
          )}
        </div>
      );
    case 'DataLimit':
      return (
        <div>
          {can(
            'update',
            createSubject(rowData?.item_type, { client_id: clientId })
          ) && (
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.EDIT_DATALIMIT"
              onClick={onEditDatalimit}
              iconClassName="far fa-edit"
            />
          )}
        </div>
      );
    case 'ExternalNumberRange':
      return (
        <div className={classes.actionButtons}>
          {can(
            'update',
            createSubject('ExternalNumberRange', {
              client_id: clientId
            })
          ) && (
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.EDIT_RANGE"
              onClick={onEditRange}
              iconClassName="far fa-edit"
            />
          )}
        </div>
      );
    case 'User':
      return (
        <div className={classes.actionButtons}>
          {can(
            'update',
            createSubject('User', {
              client_id: clientId
            })
          ) && (
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.EDIT_USER"
              onClick={onEditUser}
              iconClassName="far fa-edit"
            />
          )}
        </div>
      );
    default:
      return null;
  }
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openEditClient: setClient,
  openEditDatalimit,
  openEditRange: setRange,
  openEditTelephonyUser: setTelephonyUser,
  openEditUser: setUser
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(EditIcon);
