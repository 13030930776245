import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_ACCOUNTS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import { translate } from '../../../i18n/I18nProvider';

export function fetchAccounts(clientId) {
  return {
    type: sagaActions[GET_ACCOUNTS],
    clientId
  };
}

export function* getAccounts({ clientId }) {
  yield put(loaderReducActions.startSpinLoader());

  let accounts = [],
    selectOptions = [],
    selectedAccount = {};

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/accounts`
    );

    accounts = data;
    selectOptions = yield call(createSelectOptions, data);

    selectedAccount = Optional(selectOptions[0]).or({});
  } catch {
  } finally {
    yield put(
      reduxActions.setAccounts(accounts, selectOptions, selectedAccount)
    );
    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchGetAccounts() {
  yield takeLatest(sagaActions[GET_ACCOUNTS], getAccounts);
}

export default watchGetAccounts();

function createSelectOptions(data = []) {
  const options = data.map(el => {
    const { id, name, accountNo } = el;
    return { value: id, label: `${name} - ${accountNo}`, ...el };
  });

  if (options.length > 1) {
    return [
      {
        externalReference: 'all',
        value: 'all',
        label: translate('INVOICES.ALL_ACCOUNTS'),
        name: translate('INVOICES.ALL_ACCOUNTS')
      },
      ...options
    ];
  }

  return options;
}
