import { URLS } from '../../../consts/endpoints';
import { translate } from '../../../i18n/I18nProvider';
import store from '../../../store/store';
import { postData } from '../../../utils/http';
import { actions as errorReduxActions } from '../../../partials/errorDialog/reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import mapSwapErrorResponse from './mapSwapErrorResponse';

const eSimCodeRequest = async ({ number, clientId }) => {
  try {
    const response = await postData({
      url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/change_sim_card`,
      data: {
        phone_number: number
      },
      passErrors: [
        RESPONSE_CODES.BAD_REQUEST,
        RESPONSE_CODES.UNPROCESSABLE_ENTITY
      ]
    });
    return response;
  } catch (e) {
    const messageText = mapSwapErrorResponse(e.response);

    store.dispatch(
      errorReduxActions.openDialog({
        directMessage: `${translate(
          'ORDER_ESIM_ERROR_1'
        )} ${number}. ${translate('CONTACT_SUPPORT')} \n ${messageText}`
      })
    );
    return void 0;
  }
};

export default eSimCodeRequest;
