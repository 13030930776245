import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import Optional from '../utils/optional';

function PageHelmet({ title, favicon }) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    </Helmet>
  );
}

const mapStatesToProps = ({ builder }) => {
  return {
    title: Optional(builder.settings)
      .map(settings => settings.title)
      .or(''),
    favicon: Optional(builder.settings)
      .map(settings => settings.favicon)
      .or('')
  };
};

export default connect(mapStatesToProps)(PageHelmet);
