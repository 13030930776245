import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

import formatDate from '../../utils/formatDate';
import { isDefined, isUndefined } from '../../utils/isDefined';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import ConfirmDialog from '../confirmationDialog/ConfirmDialog';
import LockText from './components/LockText';
import styles from './styles';

function LockUserTableCell({
  data,
  classes,
  lockUserAction,
  settings,
  defaultSettings,
  intl
}) {
  const { locked, id, locked_at } = data;
  if (isUndefined(id)) {
    return '';
  }

  const onLockClick = action => {
    lockUserAction(id, action);
  };

  return (
    <div className={classes.root}>
      {locked || isDefined(locked_at) ? (
        <div className={classes.container}>
          <Tooltip
            title={Optional(locked_at)
              .map(date => formatDate(date))
              .or('')}
          >
            <i
              className={clsx('far fa-lock-alt', classes.icon)}
              style={{ color: settings.colors.danger, marginRight: '5px' }}
            ></i>
          </Tooltip>
          <ConfirmDialog
            dialogTitleIntlId={'USER.UNLOCK_USER'}
            dialogContentComponent={<LockText isLocking={false} />}
            onConfirmClick={() => onLockClick('unlock')}
          >
            <Tooltip
              title={`${intl.formatMessage({
                id: 'USER.BUTTON_UNLOCK'
              })} ${Optional(settings?.title).or(defaultSettings?.title)}`}
            >
              <Button
                className={classes.button}
                style={{ color: settings.colors.links }}
                size="small"
              >
                {intl.formatMessage({
                  id: 'UNLOCK'
                })}
              </Button>
            </Tooltip>
          </ConfirmDialog>
        </div>
      ) : (
        <div className={classes.container}>
          <i className={clsx('far fa-lock-open-alt', classes.icon)}></i>
          <ConfirmDialog
            dialogTitleIntlId={'USER.LOCK_USER'}
            dialogContentComponent={<LockText isLocking={true} />}
            onConfirmClick={() => onLockClick('lock')}
          >
            <Tooltip
              title={`${intl.formatMessage({
                id: 'USER.BUTTON_LOCK'
              })} ${Optional(settings?.title).or(defaultSettings?.title)}`}
            >
              <Button
                className={classes.button}
                style={{ color: settings.colors.links }}
                size="small"
              >
                {intl.formatMessage({
                  id: 'LOCK'
                })}
              </Button>
            </Tooltip>
          </ConfirmDialog>
        </div>
      )}
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  // connect(null, mapDispatchToProps),
  withStyles(styles)
)(LockUserTableCell);
