const styles = () => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      '& span': {
        padding: '0 5px'
      },
      '& a': {
        color: 'inherit'
      }
    },
    link: {
      display: 'none'
    },
    linkIcon: {
      fontSize: '16px',
      cursor: 'pointer',
      '&:hover': { filter: 'brightness(1.5)' }
    }
  };
};

export default styles;
