import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import styles from './styles/successButton';

const CustomColorButton = ({
  children,
  onClick,
  classes,
  color,
  backgroundColor
}) => {
  return (
    <Button
      className={classes.root}
      style={{
        backgroundColor,
        color
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default withStyles(styles)(CustomColorButton);
