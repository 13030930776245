import { URLS } from '../../../consts/endpoints';
import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export default async function updateFormData({
  event,
  clientId,
  templateName
}) {
  try {
    const response = await updateData({
      url: `${URLS.CLIENTS}/${clientId}/configs/${templateName}`,
      data: {
        config: {
          name: templateName,
          config: event.formData
        }
      }
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.SETTINGS_UPDATED',
        messageType: 'success'
      });
    }
    return true;
  } catch {
    return false;
  }
}
