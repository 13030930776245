import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import mapCustomFilters from './mapCustomFilters';

export default async function fetchClients(query, partnerId) {
  const queryParams = {
    include: 'partner',
    fields: { partner: 'name' },
    filter: partnerId ? { partner_id: { eq: partnerId } } : void 0,
    extra_fields: { clients: 'default_address' }
  };

  const requestUrl = JSONAPI_URLS.CLIENTS;

  return await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: 'name',
    mapCustomFilters
  });
}
