import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { CircularProgress } from '@material-ui/core';

import styles from '../styles/accountDetailView';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import mapAccountDetails from '../actions/mapAccountDetails';
import { translate } from '../../../i18n/I18nProvider';
import DataBox from '../../../partials/dataBox/DataBox';

const AccountDetailView = ({ classes, account, clientId }) => {
  const [accountDetails, setAccountDetail] = useState(void 0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let details = {};

      try {
        const data = await getData(
          `${URLS.QUOTE_CLIENTS}/${clientId}/accounts/${account.id}`
        );

        details = mapAccountDetails(data);
      } catch {
      } finally {
        setAccountDetail(details);
        setIsLoading(false);
      }
    })();
  }, [account, clientId]);

  return isLoading ? (
    <div className={classes.loaderOverlay}>
      <CircularProgress />
    </div>
  ) : isDefined(accountDetails) ? (
    <div className={classes.root}>
      <div className={classes.dataRow}>
        <DataBox
          label={translate('ACCOUNT.INVOICE_DELIVERY_TYPE')}
          value={accountDetails.invoiceDeliveryType}
        />
        <DataBox
          label={translate('ACCOUNT.INVOICE_EMAIL')}
          value={accountDetails.invoiceEmail}
        />
      </div>
      <div className={classes.dataRow}>
        <DataBox
          label={translate('ACCOUNT.INVOICE_REFERENCE')}
          value={accountDetails.invoiceRefference}
        />
        <DataBox label="EAN" value={accountDetails.ean} />
      </div>
      <div className={classes.detailsInfo}>
        {translate('ACCOUNT.DETAIL_INFO')}
      </div>
    </div>
  ) : (
    <div className={classes.noData}>
      There are no account billing details found
    </div>
  );
};

export default flowRight(withStyles(styles))(AccountDetailView);
