import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../consts/tableOptions';
import { getPermissions } from './actions/getPermissions';
import { actions as reduxActions } from './reducers';
import CustomTableTitle from '../../partials/CustomTableTitle';
import withDetailsView from '../../utils/withDetailsView';
import FilterButton from '../../partials/tableCustomButtons/FilterButton';
import tableColumns from './components/tableColumns';
import { doesArrayHasLength, isArrayEmpty } from '../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../utils/localstorageUtils';
import customTableIcons from '../../partials/tableIcons/customTableIcons';
import AddButton from '../../partials/tableCustomButtons/AddButton';
import { actions as addPermissionReduxActions } from '../../modals/addPermission/reducers';
import AddPermission from '../../modals/addPermission/AddPermission';
import { Tooltip } from '@material-ui/core';
import RolesTable from './components/RolesTable';
import RefreshButton from '../../partials/tableCustomButtons/RefreshButton';

export function Permissions({
  permissions,
  getPermissions,
  intl,
  resetPermissions,
  isLoading,
  columns,
  setColumns,
  openCreatePermission
}) {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getPermissions();
    return resetPermissions;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh) {
      getPermissions();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Permissions-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => [
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
      },
      {
        icon: () => <AddButton buttonText="BUTTON.ADD" />,
        onClick: () => openCreatePermission(),
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'PERMISSIONS.ADD_PERMISSION' })
      },
      {
        icon: () => <RefreshButton tableHeader={true} />,
        onClick: () => setRefresh(true),
        isFreeAction: true
      }
    ],
    // eslint-disable-next-line
    [filtering]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering,
      header: doesArrayHasLength(permissions)
    };
  }, [filtering, permissions]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Permissions-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const detailPanel = [
    rowData => ({
      disabled: isArrayEmpty(rowData.roles),
      icon: () => (
        <Tooltip
          title={intl.formatMessage({
            id: 'TOOLTIP.CONNECTED_ROLES'
          })}
        >
          <i
            className="fas fa-chevron-right"
            style={{
              display: isArrayEmpty(rowData.roles) ? 'none' : '',
              fontSize: '12px'
            }}
          ></i>
        </Tooltip>
      ),
      openIcon: () => (
        <i
          className="fas fa-chevron-down"
          style={{
            fontSize: '12px'
          }}
        ></i>
      ),
      render: () => <RolesTable roles={rowData.roles} />
    })
  ];

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.PERMISSIONS"
            icon={<i className="fas fa-unlock-alt"></i>}
            isLoading={isLoading}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={permissions}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
        detailPanel={detailPanel}
      />
      <AddPermission />
    </>
  );
}

const mapStatesToProps = ({ permissions, loader }) => {
  return {
    ...permissions,
    isLoading: loader.isSpinVisible
  };
};

const mapDispatchToProps = {
  getPermissions,
  resetPermissions: reduxActions.resetPermissions,
  setColumns: reduxActions.setColumns,
  openCreatePermission: addPermissionReduxActions.openAddPermission
};

export default flowRight(
  injectIntl,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps)
)(Permissions);
