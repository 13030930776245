import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import styles from './styles';
import { actions as reduxActions } from './reducers';
import NewSimcardStepper from './components/NewSimcardStepper';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';

export function OrderNewSimcard({ classes, open, intl, resetOrderSimcard }) {
  const customClasses = useMemo(() => {
    return {
      root: classes.dialogContent
    };
  }, [classes.dialogContent]);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          resetOrderSimcard();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={resetOrderSimcard}>
        {intl.formatMessage({
          id: 'ORDER_SIMCARD.DIALOG_TITLE'
        })}
      </CustomDialogTitle>

      <DialogContent classes={customClasses}>
        <NewSimcardStepper />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ orderNewSimcard, selectClient }) => {
  return {
    open: orderNewSimcard.open,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(OrderNewSimcard);
