export default {
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    padding: '20px 0',
    '& button': {
      margin: '0 5px'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  }
};
