import { InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import styles from '../styles/permissions';

function CustomsSearch({ classes, setFunction, term }) {
  return (
    <TextField
      margin="dense"
      variant="outlined"
      className={classes.input}
      value={term}
      onChange={event => setFunction(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <i className={clsx('fas fa-search', classes.searchIcon)}></i>
          </InputAdornment>
        )
      }}
      inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
    />
  );
}

export default withStyles(styles)(CustomsSearch);
