export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fontWeight: 500,
    fontSize: '10px'
  },
  input: { width: '40px' }
};
