import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';

import styles from '../styles/numberRow';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';

const NumberRow = ({
  label,
  number,
  classes,
  settings,
  customClasses = {}
}) => {
  const { isForwarded, lineNumber, forwardNumber } = number;
  return (
    <div className={clsx(classes.root, classes.dense, customClasses.root)}>
      <div className={classes.label}>
        <div>{Optional(label).or('')}</div>
      </div>
      <div className={classes.valueContainer}>
        {isForwarded && (
          <div>
            <a
              className={classes.link}
              style={{ color: settings.colors.links }}
              href={`tel:${lineNumber}`}
            >
              {lineNumber}
            </a>
            <i className={clsx('fal fa-angle-double-right', classes.icon)}></i>
          </div>
        )}
        <a
          className={classes.link}
          style={{ color: settings.colors.links }}
          href={`tel:${isForwarded ? forwardNumber : lineNumber}`}
        >
          {isForwarded ? forwardNumber : lineNumber}
        </a>
      </div>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(NumberRow);
