const styles = theme => {
  return {
    select: {
      marginTop: '10px',
      width: '400px',
      [theme.breakpoints.down('xs')]: {
        width: '320px'
      }
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dialogContent: {
      overflow: 'visible',
      maxWidth: '450px'
    },
    scrollPaper: {
      alignItems: 'start'
    },
    accountNo: {
      display: 'flex',
      fontSize: '10px',
      alignItems: 'center'
    },
    platform: {
      padding: '2px 4px',
      borderRadius: '8px',
      backgroundColor: 'green',
      marginRight: '5px',
      color: '#FFF'
    }
  };
};

export default styles;
