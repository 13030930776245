import { createReduxActionTypes } from '../../../utils/actionFactory';
import changeObjectValuesinArray from '../../../utils/changeObjectValuesInArray';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import findSelectedQueue from '../actions/findSelectedQueue';

const SET_QUEUES = 'SET_QUEUES';
const UPDATE_GROUP = 'UPDATE_GROUP';
const RESET_QUEUES = 'RESET_QUEUES';
const SET_SELECTED_QUEUES = 'SET_SELECTED_QUEUES';
const SET_QUEUE_AGENTS = 'SET_QUEUE_AGENTS';
const CHANGE_REFRESH_TIME = 'CHANGE_REFRESH_TIME';
const RESET_TIME = 'RESET_TIME';
const SET_COLUMNS = 'SET_COLUMNS';
const SET_UPDATED_QUEUES = 'SET_UPDATED_QUEUES';
const UPDATE_IS_USERS_VIEW = 'UPDATE_IS_USERS_VIEW';
const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';

export const actionTypes = createReduxActionTypes('QUEUES', [
  SET_QUEUES,
  UPDATE_GROUP,
  SET_UPDATED_QUEUES,
  CHANGE_REFRESH_TIME,
  SET_SELECTED_QUEUES,
  SET_QUEUE_AGENTS,
  RESET_QUEUES,
  RESET_TIME,
  SET_COLUMNS,
  UPDATE_IS_USERS_VIEW,
  UPDATE_SUBSCRIPTIONS
]);

const DEFAULT_STATE = {
  queues: [],
  queuesStats: [],
  queuesAgents: {},
  refreshTime: 0,
  columns: [],
  isUsersView: false,
  queuesGroupedByUser: [],
  activities: [],
  subscriptions: []
};

export const reducer = persistReducer(
  {
    storage,
    key: 'queues',
    whitelist: ['refreshTime']
  },
  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case actionTypes[SET_QUEUES]: {
        const {
          queues,
          queuesStats,
          selectOptions,
          queuesToken,
          queuesGroupedByUser,
          activities
        } = action;
        return {
          ...state,
          queues: [...queues],
          queuesStats: [...queuesStats],
          selectOptions: [...selectOptions],
          selectedQueue: findSelectedQueue({ state, queuesStats, queues }),
          queuesToken,
          queuesGroupedByUser,
          activities
        };
      }
      case actionTypes[UPDATE_GROUP]: {
        const { group } = action;
        const newQueues = changeObjectValuesinArray([...state.queues], group);
        return {
          ...state,
          queues: [...newQueues]
        };
      }
      case actionTypes[SET_UPDATED_QUEUES]: {
        const { queues, queuesGroupedByUser } = action;
        return {
          ...state,
          queues: [...queues],
          queuesGroupedByUser: [...queuesGroupedByUser]
        };
      }
      case actionTypes[UPDATE_SUBSCRIPTIONS]: {
        const { subscription } = action;
        return {
          ...state,
          subscriptions: [...state.subscriptions, subscription]
        };
      }
      case actionTypes[CHANGE_REFRESH_TIME]: {
        const { refreshTime } = action;
        return {
          ...state,
          refreshTime
        };
      }
      case actionTypes[SET_SELECTED_QUEUES]: {
        return {
          ...state,
          selectedQueue: state.queuesStats.find(
            queue => queue.id === action.selectedId
          )
        };
      }
      case actionTypes[SET_QUEUE_AGENTS]: {
        const { queueAgents } = action;
        return {
          ...state,
          queuesAgents: {
            ...state.queuesAgents,
            [queueAgents.id]: { ...queueAgents }
          }
        };
      }
      case actionTypes[RESET_TIME]: {
        return { ...state, refreshTime: DEFAULT_STATE.refreshTime };
      }
      case actionTypes[SET_COLUMNS]: {
        return { ...state, columns: [...action.columns] };
      }
      case actionTypes[UPDATE_IS_USERS_VIEW]: {
        return { ...state, isUsersView: action.isUsersView };
      }
      case actionTypes[RESET_QUEUES]: {
        return { ...DEFAULT_STATE, refreshTime: state.refreshTime };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setQueues: ({
    queues,
    queuesStats,
    selectOptions,
    queuesToken,
    queuesGroupedByUser,
    activities
  }) => ({
    type: actionTypes[SET_QUEUES],
    queues,
    queuesStats,
    selectOptions,
    queuesToken,
    queuesGroupedByUser,
    activities
  }),
  updateDistgroup: group => ({
    type: actionTypes[UPDATE_GROUP],
    group
  }),
  changeRefreshTime: refreshTime => ({
    type: actionTypes[CHANGE_REFRESH_TIME],
    refreshTime
  }),
  setSelectedQueue: selectedId => ({
    type: actionTypes[SET_SELECTED_QUEUES],
    selectedId
  }),
  setQueueAgents: queueAgents => ({
    type: actionTypes[SET_QUEUE_AGENTS],
    queueAgents
  }),
  resetTime: () => ({
    type: actionTypes[RESET_TIME]
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  updateIsUsersView: isUsersView => ({
    type: actionTypes[UPDATE_IS_USERS_VIEW],
    isUsersView
  }),
  setUpdatedQueues: ({ queues, queuesGroupedByUser }) => ({
    type: actionTypes[SET_UPDATED_QUEUES],
    queues,
    queuesGroupedByUser
  }),
  updateSubscriptions: subscription => ({
    type: actionTypes[UPDATE_SUBSCRIPTIONS],
    subscription
  }),
  resetQueues: () => ({ type: actionTypes[RESET_QUEUES] })
};

export const getters = {
  getQueues: state => state.queues.queues
};
