import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import TabPanel from '../../../partials/TabPanel';
import styles from '../styles';
import CallLogContainer from './CallLogContainer';
import SmsContainer from './SmsContainer';
import VoicemailsContainer from './VoicemailsContainer';

function TwoPanelsView({ classes, intl, permissionToSendSms }) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        centered
        className={classes.twoTabsView}
      >
        <Tab
          classes={{ root: classes.tabRoot }}
          label={intl.formatMessage({
            id: 'CONTACTS.CALL_LOG'
          })}
        />
        <Tab classes={{ root: classes.tabRoot }} label="Voicemail" />
        {permissionToSendSms && (
          <Tab classes={{ root: classes.tabRoot }} label="Sms" />
        )}
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <CallLogContainer />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <VoicemailsContainer />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <SmsContainer />
      </TabPanel>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(TwoPanelsView);
