import watchAttorneyRequest from './attorneyRequest';
import watchNewUserRequest from './newUserRequest';
import watchGetSelectOptions from './getSelectOptions';
import watchGetSupscriptions from './getSupscriptions';

const watchRequestUserWizard = [
  watchAttorneyRequest,
  watchNewUserRequest,
  watchGetSelectOptions,
  watchGetSupscriptions
];

export default watchRequestUserWizard;
