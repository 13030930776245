import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import clsx from 'clsx';
import Optional from '../../../utils/optional';


export function DetailsCheckbox({ field, classes, fieldOptions, intl }) {
  const { onBlur, name, value, onChange } = field;
  return (
    <div
      className={classes.fieldContainer}
      style={{ width: fieldOptions.width }}
    >
      <div className={classes.fieldLabel}>
        {Optional(fieldOptions.intlKey)
          .map(key =>
            intl.formatMessage({
              id: key
            })
          )
          .or(fieldOptions.label)}
      </div>
      <Checkbox
        className={clsx(classes.fieldValue, classes.checkbox)}
        checked={Optional(value).or('')}
        disabled={fieldOptions.disabled}
        value="primary"
        onBlur={onBlur}
        onChange={onChange}
        name={name}
      />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DetailsCheckbox);
