import { URLS } from '../../../consts/endpoints';
import { postData, updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { isObjectEmpty } from '../../../utils/isDefined';

export default async function editTemplate({
  config,
  name,
  selected,
  uiSchema
}) {
  const mappedData = mapData({ config, name, uiSchema });

  try {
    const response =
      selected === 'create'
        ? await postData({
            url: `${URLS.CONFIG_TEMPLATES}`,
            data: mappedData
          })
        : await updateData({
            url: `${URLS.CONFIG_TEMPLATES}/${name}`,
            data: mappedData
          });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey:
          selected === 'create'
            ? 'MESSAGE.TEMPLATE_CREATED'
            : 'MESSAGE.TEMPLATE_UPDATED',
        messageType: 'success'
      });
    }

    return true;
  } catch {
    return false;
  }
}

function mapData({ config, name, uiSchema }) {
  return {
    config: {
      name: name.trim().replace(' ', '_'),
      config,
      ui_schema: isObjectEmpty(uiSchema) ? null : uiSchema
    }
  };
}
