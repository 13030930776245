const styles = theme => {
  return {
    root: {
      fontSize: '14px',
      padding: '20px',
      '& p': {
        margin: '20px 0',
        '& span,a': {
          color: '#008DFE'
        }
      }
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      }
    }
  };
};

export default styles;
