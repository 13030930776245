export default {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  icon: {
    cursor: 'pointer'
  }
};
