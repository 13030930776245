import {
  TOP_MENU_ITEMS,
  ASIDE_MENU_ITEMS,
  ASIDE_MENU_SECTIONS,
  TOP_MENU_BUTTON
} from '../../app/consts/menuItems';
import { ROLES_IDS } from '../../app/consts/rolesIds';

const ASIDE_MENU = {
  main: [
    ASIDE_MENU_ITEMS.DASHBOARD,
    ASIDE_MENU_ITEMS.USER_PROFILE,
    ASIDE_MENU_ITEMS.USER_CONTACTS
  ],
  telephony: [
    ASIDE_MENU_ITEMS.SIM_CARDS,
    ASIDE_MENU_ITEMS.SIM_CARDS_IOT,
    ASIDE_MENU_ITEMS.NUMBER_RANGE,
    ASIDE_MENU_ITEMS.QUEUES,
    ASIDE_MENU_ITEMS.SCHEDULES,
    ASIDE_MENU_ITEMS.DATA_LIMITS,
    ASIDE_MENU_ITEMS.TELEPHONY_USERS,
    ASIDE_MENU_ITEMS.GROUPS
  ],
  administration: [
    ASIDE_MENU_ITEMS.INVOICES,
    ASIDE_MENU_ITEMS.SUBSCRIPTIONS,
    ASIDE_MENU_ITEMS.CONNECTIONS,
    ASIDE_MENU_ITEMS.ORDERS,
    ASIDE_MENU_ITEMS.ACCOUNTS
  ],
  clientSettings: [
    ASIDE_MENU_ITEMS.USERS,
    ASIDE_MENU_ITEMS.ADDRESSES,
    ASIDE_MENU_ITEMS.LICENSES,
    ASIDE_MENU_ITEMS.APP_SETTINGS,
    ASIDE_MENU_ITEMS.PHONEBOOK_REGISTRATION,
    ASIDE_MENU_ITEMS.CDR_HOOKS
  ],
  sections: {
    telephony: ASIDE_MENU_SECTIONS.TELEPHONY,
    administration: ASIDE_MENU_SECTIONS.ADMINISTRATION,
    clientSettings: ASIDE_MENU_SECTIONS.CLIENTS_SETTINGS
  }
};

export default function menuConfig(roleId) {
  if (roleId >= ROLES_IDS.IPV_SUPERVISOR) {
    return {
      header: {
        self: {},
        items: [
          {
            ...TOP_MENU_BUTTON.ADMINISTRATION,
            submenu: [
              TOP_MENU_ITEMS.CLIENTS,
              TOP_MENU_ITEMS.USERS,
              TOP_MENU_ITEMS.SIM_CARDS,
              TOP_MENU_ITEMS.NUMBER_RANGE,
              TOP_MENU_ITEMS.NUMBER_LOOKUP,
              TOP_MENU_ITEMS.CHANGELOG,
              TOP_MENU_ITEMS.PHONEBOOK_REGISTRATION,
              TOP_MENU_ITEMS.ORDERS
            ]
          },
          {
            ...TOP_MENU_BUTTON.SYSTEM_SETUP,
            submenu: [
              TOP_MENU_ITEMS.PARTNERS,
              TOP_MENU_ITEMS.ROLES,
              TOP_MENU_ITEMS.PERMISSIONS,
              TOP_MENU_ITEMS.EDIT_SCHEMA,
              TOP_MENU_ITEMS.CUSTOM_JOBS,
              TOP_MENU_ITEMS.VACATIONS
            ]
          },
          TOP_MENU_ITEMS.SWITCH_CLIENT,
          TOP_MENU_ITEMS.DEFAULT_CLIENT
        ]
      },
      aside: {
        self: {},
        items: {
          ...ASIDE_MENU
        }
      }
    };
  }
  if (roleId >= ROLES_IDS.PARTNER_ADMIN && roleId < ROLES_IDS.IPV_SUPERVISOR) {
    return {
      header: {
        self: {},
        items: [
          {
            ...TOP_MENU_BUTTON.ADMINISTRATION,
            submenu: [
              TOP_MENU_ITEMS.CLIENTS,
              TOP_MENU_ITEMS.USERS,
              TOP_MENU_ITEMS.SIM_CARDS,
              TOP_MENU_ITEMS.NUMBER_RANGE,
              TOP_MENU_ITEMS.NUMBER_LOOKUP,
              TOP_MENU_ITEMS.APP_SETTINGS,
              TOP_MENU_ITEMS.CHANGELOG,
              TOP_MENU_ITEMS.ORDERS
            ]
          },
          TOP_MENU_ITEMS.SWITCH_CLIENT,
          TOP_MENU_ITEMS.DEFAULT_CLIENT
        ]
      },
      aside: {
        self: {},
        items: {
          ...ASIDE_MENU
        }
      }
    };
  }

  return DEFAULT_MENU_CONFIG;
}

export const DEFAULT_MENU_CONFIG = {
  header: {
    self: {},
    items: [TOP_MENU_ITEMS.SWITCH_CLIENT, TOP_MENU_ITEMS.DEFAULT_CLIENT]
  },
  aside: {
    self: {},
    items: {
      ...ASIDE_MENU
    }
  }
};
