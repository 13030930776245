import React from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { actions as reduxActions } from '../reducers';
import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';

export function ActionsButtons({ closeDialog, handleSubmit, classes }) {
  return (
    <div className={classes.footer}>
      <PrimaryButton onClick={handleSubmit}>
        {translate('BUTTON.SAVE')}
      </PrimaryButton>
      <SecondaryButton onClick={closeDialog}>
        {translate('BUTTON.CANCEL')}
      </SecondaryButton>
    </div>
  );
}

const mapDispatchToProps = {
  closeDialog: reduxActions.closeEditQueue
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ActionsButtons);
