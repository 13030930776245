import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { translate } from '../../i18n/I18nProvider';
import UsersTable from './components/UsersTable';

const TestUsersSync = ({ classes, open, closeTestUserSync }) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeTestUserSync();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeTestUserSync}>
        {translate('TEST_AZURE_SYNC')}
      </CustomDialogTitle>
      <DialogContent >
        <UsersTable />
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ testUserSync }) => {
  return {
    open: testUserSync.open
  };
};

const mapDispatchToProps = {
  closeTestUserSync: reduxActions.closeTestUserSync
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TestUsersSync);
