import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import InputRow from '../../../partials/dataRows/InputRow';
import styles from '../styles/frontendSettings';

function MsSettings({ classes,  intl, form }) {
  const customInputStyles = useMemo(() => {
    return {
      root: { width: 'unset', fontSize: '14px' },
      label: {  width: '200px' },
      textfield: { width: '300px' }
    };
  }, []);

  return (
    <div className={classes.root} >
      <div
        className={classes.title}
      >
        {intl.formatMessage({
          id: 'APP_SETTINGS.MS_SETTINGS'
        })}
      </div>

      <div className={classes.section}>
        <div className={classes.sectionTitle}></div>
        <div>
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.MS_APP_ID"
            name="app_id"
            {...form}
          />
          <InputRow
            mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.MS_APP_SECRET"
            name="app_secret"
            isValueHidden={true}
            {...form}
          />
        </div>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withStyles(styles)
)(MsSettings);
