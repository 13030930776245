import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import styles from '../styles/customTitle';

function CustomTitle({
  intl,
  title,
  children,
  classes,
  isDataExist = false,
  isDefaultOpen = false,
  dotsMenu = false,
  menuComponent = null
}) {
  const [open, setOpen] = useState(isDefaultOpen ? true : false);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div
          className={classes.labelContainer}
          onClick={() => isDataExist ? (open ? setOpen(false) : setOpen(true)) : {}}
          style={{ cursor: isDataExist ? 'pointer' : 'unset' }}
        >
          {open ? (
            <i
              className={clsx('fas fa-chevron-down', classes.icon, {
                [classes.hidden]: !isDataExist
              })}
              onClick={() => setOpen(false)}
            ></i>
          ) : (
            <i
              className={clsx('fas fa-chevron-right', classes.icon, {
                [classes.hidden]: !isDataExist
              })}
              onClick={() => setOpen(true)}
            ></i>
          )}
          <div className={classes.label}>
            {intl.formatMessage({
              id: title
            })}
          </div>
        </div>
        {isDataExist && dotsMenu && menuComponent}
      </div>
      <div
        className={clsx(classes.content, { [classes.contentVisible]: open })}
      >
        {open && children}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CustomTitle);
