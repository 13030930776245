import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';

function DeleteRoleConfimation({ classes, intl }) {
  return (
    <div className={classes.dialogTextContainer}>
      {intl.formatMessage({
        id: 'ROLE.DELETE_CONFIRMATION'
      })}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DeleteRoleConfimation);
