import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions as reduxActions } from './reducers';
import AccountsTable from './components/AccountsTable';

const Accounts = ({ resetAccounts }) => {
  useEffect(() => {
    return resetAccounts;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <AccountsTable />
    </div>
  );
};

const mapDispatchToProps = {
  resetAccounts: reduxActions.resetAccounts
};

export default connect(null, mapDispatchToProps)(Accounts);
