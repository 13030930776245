import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import TabPanel from '../../../partials/TabPanel';
import styles from '../styles';
import CallLogContainer from './CallLogContainer';
import Contacts from './Contacts';
import SmsContainer from './SmsContainer';
import VoicemailsContainer from './VoicemailsContainer';

function FourPanelView({ classes, intl, permissionToSendSms }) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={classes.mobileView}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        centered
        className={classes.fourTabsView}
      >
        <Tab
          label={intl.formatMessage({
            id: 'MENU.USER_CONTACTS'
          })}
        />
        <Tab
          label={intl.formatMessage({
            id: 'CONTACTS.CALL_LOG'
          })}
        />
        <Tab label="Voicemail" />
        {permissionToSendSms && <Tab label="Sms" />}
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Contacts fromPanel={true} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <CallLogContainer />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <VoicemailsContainer />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <SmsContainer />
      </TabPanel>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(FourPanelView);
