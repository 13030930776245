const styles = theme => {
  return {
    root: { backgroundColor: '#FFFFFF', height: 'calc(100% - 64px)' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '16px',
      padding: 'unset'
    },

    titleRoot: { borderBottom: 'unset' },
    tableBody: { width: '100%', fontSize: '14px', fontWeight: 500 },
    row: {
      position: 'relative',
      minHeight: '1px',
      borderTop: '1px solid #fff'
    },
    rowContent: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '50px',
      textAlign: 'center'
    },
    cell: { justifyContent: 'center', display: 'flex' },
    groupedIndent: { justifyContent: 'flex-end' },
    idDescCell: {
      justifyContent: 'flex-start',
      width: 'auto !important',
      flexGrow: 1,
      fontWeight: 'normal'
    },
    idFlexCell: { width: 'auto !important', flexGrow: 1 },
    idCell: {
      justifyContent: 'flex-end'
    },
    description: {
      fontSize: '12px',
      fontWeight: 300
    },
    chargeLineRowPagination: { borderBottom: 0 },
    idChargeLineDescCell: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    idFlexCellDesc: {
      width: 'auto !important',
      flexGrow: 1,
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    loader: {
      widht: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    infoBox: { padding: '10px', backgroundColor: '#EAF0F3', fontSize: '14px' },
    selectContainer: {
      display: 'flex',
      padding: '10px',
      flexDirection: 'column'
    },
    selectLabel: {
      fontWeight: 600,
      marginBottom: '5px'
    },
    tableOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;

export const customSelectStyles = {
  control: {
    width: '220px',
    boxShadow: 'none'
  },
  valueContainer: {},
  menu: { width: '220px' },
  indicatorSeparator: { display: 'none' }
};
