import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';

import styles from '../styles/dirFieldsElement';

function DirFieldRow({ field, classes, changeValue }) {
  const onChange = useCallback(
    e => {
      return changeValue({ ...field, value: e.target.value });
    },
    [field, changeValue]
  );

  return (
    <div className={classes.rowRoot}>
      <div className={classes.label}>{field.name}</div>
      <TextField
        className={classes.fieldValue}
        margin="dense"
        variant="outlined"
        value={field.value}
        onChange={onChange}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
    </div>
  );
}

export default withStyles(styles)(DirFieldRow);
