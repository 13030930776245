import { Tooltip, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Optional from '../../../utils/optional';
import styles from '../styles';
import CustomIconButton from '../../tableCustomButtons/CustomIconButton';
import getPhoneBookRegistration from '../../../modals/editPhoneBookStatus/action/getPhoneBookRegistration';
import { translate } from '../../../i18n/I18nProvider';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import { isDefined } from '../../../utils/isDefined';
import {
  DEFAULT_BOOK_DATA,
  findPhonebookStatuses
} from '../../../consts/initialValues';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

const PhoneBookStatus = ({ classes, infoText, numberData, number }) => {
  const [phoneBookData, setPhoneBookData] = useState(DEFAULT_BOOK_DATA);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getPhoneBookRegistration(number);

      if (isDefined(data)) {
        setPhoneBookData(data);
      }
    })();
  }, [reload, number]);

  return (
    <div className={classes.row}>
      <div className={classes.serviceName}>{`${translate('PHONE_BOOK.TITLE')} ${
        isDefined(phoneBookData?.id)
          ? `- ${findPhonebookStatuses(phoneBookData?.protection_code)}`
          : ''
      }`}</div>
      <Tooltip title={Optional(infoText).or('test')}>
        <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
      </Tooltip>
      <div className={classes.actionsContainer}>
        {isDefined(numberData?.numberRange?.id) &&
          can(
            'update',
            createSubject('PhoneBookRegistration', {
              external_number_range_id: Number(numberData?.numberRange?.id)
            })
          ) && (
            <EditPhoneBookStatus
              number={number.toString().replace('+', '')}
              numberData={numberData}
              setReload={setReload}
              reload={reload}
              numberRangeId={numberData?.numberRange?.id}
            >
              <CustomIconButton
                iconTooltipIntlKey="EDIT"
                iconClassName="far fa-edit"
              />
            </EditPhoneBookStatus>
          )}
      </div>
    </div>
  );
};

export default withStyles(styles)(PhoneBookStatus);
