import {
  call,
  takeLatest as _takeLatest,
  takeEvery as _takeEvery,
  throttle as _throttle,
  debounce as _debounce
} from 'redux-saga/effects';

export function tryCatch(saga) {
  const wrapped = function* wrappedTryCatch() {
    try {
      yield call(saga, ...arguments);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Error caught in saga: ', error);
      }
    }
  };
  wrapped._original = saga;
  return wrapped;
}
export function takeLatest(pattern, worker, ...args) {
  return _takeLatest(pattern, tryCatch(worker), ...args);
}
export function takeEvery(pattern, worker, ...args) {
  return _takeEvery(pattern, tryCatch(worker), ...args);
}
export function throttle(ms, pattern, worker, ...args) {
  return _throttle(ms, pattern, tryCatch(worker), ...args);
}
export function debounce(ms, pattern, worker, ...args) {
  return _debounce(ms, pattern, tryCatch(worker), ...args);
}
