const styles = theme => {
  return {
    root: {
      width: 'calc(100% - 80px)',
      margin: '10px 20px 10px 40px'
    },
    severity: {
      width: '100px',
      margin: '5px 10px'
    },
    cell: { width: '250px' }
  };
};

export default styles;
