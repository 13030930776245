import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_PROMPTS = 'GET_PROMPTS';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const GET_VACATIONS = 'GET_VACATIONS';

export const actions = createSagaActionTypes('EDIT_SCHEDULE', [
  GET_PROMPTS,
  SET_SCHEDULE,
  UPDATE_SCHEDULE,
  GET_VACATIONS
]);
