import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_ADD_CONTACTS = 'OPEN_ADD_CONTACTS';
const RESET_ADD_CONTACTS = 'RESET_ADD_CONTACTS';

export const actionTypes = createReduxActionTypes('ADD_EXTERNAL_CONTACT', [
  OPEN_ADD_CONTACTS,
  RESET_ADD_CONTACTS
]);

const DEFAULT_STATE = {
  open: false,
  number: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_ADD_CONTACTS]: {
      const { number, isEdit, contact } = action;
      return {
        ...state,
        number,
        isEdit,
        contact,
        open: true
      };
    }
    case actionTypes[RESET_ADD_CONTACTS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openAddContact: ({ number, isEdit, contact }) => ({
    type: actionTypes[OPEN_ADD_CONTACTS],
    number,
    isEdit,
    contact
  }),
  closeAddContact: () => ({ type: actionTypes[RESET_ADD_CONTACTS] })
};
