const styles = theme => {
  return {
    dialogRoot: { paddingBottom: '10px' },
    dialogContent: {
      overflow: 'visible',
      backgroundColor: '#FAFAFA',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        padding: '6px 0'
      }
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    scrollPaper: {
      alignItems: 'start'
    },
    lastRowContainer: {
      display: 'flex',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      justifyContent: 'space-between',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        flexDirection: 'column'
      }
    },
    footer: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginRight: '10px'
      }
    },
    loaderOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;
