import { withStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { flowRight } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import ActionsButtons from './ActionsButtons';
import styles from '../styles';
import changeObjectValues from '../../../utils/changeObjectValues';
import { actions as reduxActions } from '../reducers';
import TelephonyUsersMainSection from './TelephonyUsersMainSection';
import UserDirFields from './UserDirFields';
import { updateTelephonyUserOnSubmit } from '../actions/updateTelephonyUser';
import { mapGeneralData } from '../actions/mapDataToUpdate';
import validateSchema from '../actions/validateSchema';

function UserGeneralDataForm({
  classes,
  isDomainProvided,
  clientConnectId,
  selectsOptions = {},
  updateTelephonyUserOnSubmit,
  generalData = {},
  mitelUserId,
  clientId,
  resetUser,
  fromUsersTable
}) {
  const onSubmit = useCallback(
    values => {
      const mappedValues = mapGeneralData({
        values,
        languages: selectsOptions.languages
      });
      updateTelephonyUserOnSubmit({
        values,
        userData: mappedValues,
        clientConnectId,
        mitelUserId,
        clientId,
        isGeneralData: true,
        fromUsersTable
      });
    },
    // eslint-disable-next-line
    [updateTelephonyUserOnSubmit, selectsOptions]
  );
  return (
    <Formik
      initialValues={{
        ...changeObjectValues(generalData, null, '')
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema().telephonyUser}
    >
      {({ handleSubmit, dirty, errors, initialValues }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={classes.content}>
              <Field
                component={TelephonyUsersMainSection}
                selectsOptions={selectsOptions}
                initialValues={initialValues}
                isDomainProvided={isDomainProvided}
              />
              <Field component={UserDirFields} initialValues={initialValues} />
            </div>

            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={resetUser}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ editUser, selectClient }) => {
  return {
    ...editUser,
    generalData: editUser.user.generalData,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateTelephonyUserOnSubmit
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserGeneralDataForm);
