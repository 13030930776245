import Holidays from 'date-holidays';
import moment from 'moment';
import { isDefined } from './isDefined';
import Optional from './optional';

const SUNDAY_INDEX = 0;
const SATURDAY_INDEX = 6;
const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function findFirstAvailableDay(numberOfDays, countryCode) {
  const firstAvailableDay = new Date();

  for (let i = 1; i <= numberOfDays; i++) {
    firstAvailableDay.setDate(firstAvailableDay.getDate() + 1);
    if (
      checkNonWorkingDays({ date: firstAvailableDay, countryCode }) === true
    ) {
      numberOfDays++;
    }
  }

  return firstAvailableDay;
}

export function checkNonWorkingDays({
  date,
  countryCode,
  disableWeekends = true
}) {
  const holidays = findCountryHolidays(date, countryCode);
  const { dayOfWeek, dateString } = createDateObject(date);

  if (
    disableWeekends &&
    (dayOfWeek === SUNDAY_INDEX || dayOfWeek === SATURDAY_INDEX)
  ) {
    return true;
  } else if (holidays.findIndex(holiday => holiday === dateString) !== -1) {
    return true;
  }
  return false;
}

function findCountryHolidays(date, countryCode) {
  const actualYear = date.getFullYear();

  return new Holidays(countryCode)
    .getHolidays(actualYear)
    .filter(holiday => (holiday.type = 'public'))
    .map(holiday =>
      holiday.date
        .split(' ')[0]
        .split('-')
        .splice(1)
        .join('.')
    );
}

function createDateObject(date) {
  const dayOfWeek = date.getDay();
  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();

  const dateString = `${month.length === 1 ? `0${month}` : month}.${
    day.length === 1 ? `0${day}` : day
  }`;
  return { dayOfWeek, dateString };
}

export function format(date, format) {
  const momentDate = Optional(date)
    .map(date => moment(date))
    .or(null);
  if (isDefined(momentDate) && momentDate.isValid()) {
    return momentDate.format(format);
  }
  return null;
}

export function formatDateTime(date) {
  return format(date, DEFAULT_DATETIME_FORMAT);
}
