import { MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import languages from '../../../i18n/languages';
import withSettings from '../../../utils/withSettings';
import { actions as authReduxActions } from '../../auth/reducers';
import styles from '../styles/languageRow';
import Optional from '../../../utils/optional';

function LanguageRow({
  lang,
  setLanguage,
  classes,
  intl,
  settings,
  defaultSettings
}) {
  const handleChange = e => {
    setLanguage(e.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.label} style={{ color: settings.colors.primary }}>
        {`${Optional(settings?.title).or(defaultSettings?.title)} ${intl
          .formatMessage({
            id: 'EDIT.LANG'
          })
          .toLowerCase()}`}
      </div>
      <Select value={lang} onChange={handleChange} disableUnderline>
        {languages.map(language => (
          <MenuItem
            className={classes.menuItem}
            value={language.lang}
            key={language.lang}
          >
            <div>{language.name}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
const mapStatesToProps = ({ auth, builder }) => {
  return {
    lang: auth.lang
  };
};

const mapDispatchToProps = {
  setLanguage: authReduxActions.setLanguage
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(LanguageRow);
