import { URLS } from '../../../consts/endpoints';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';

const deletePhonebookStatus = async id => {
  const response = await deleteData({
    url: `${URLS.PHONE_BOOK_REGISTRATION}/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.DELETE.ADDRESS',
      messageType: 'success'
    });

    return true;
  }

  return false;
};

export default deletePhonebookStatus;
