import axios from 'axios';

import { actions as loaderReduxActions } from '../partials/loader/reducers';
import store from '../store/store';
import errorsHandler from './errorsHandler';
import Optional from './optional';
import { isDefined, isString } from './isDefined';
import showSnackbar from './showSnackbar';
import RESPONSE_CODES from '../consts/responseCodes';

export async function getData(params) {
  try {
    store.dispatch(loaderReduxActions.startLinearLoader());

    if (!isString(params)) {
      const response = await axios({ method: 'get', ...params.options });

      return response;
    }

    const { data } = await axios.get(params);

    return data;
  } catch (error) {
    const errorsToPass = Optional(params.errorsToPass).or(null);
    store.dispatch(loaderReduxActions.resetLoader());

    const url = !isString(params) ? params.options.url : params;

    return errorsHandler({ error, errorsToPass, url, addToList: true });
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
}

export async function postData({
  url,
  data,
  auth,
  errorsToPass,
  passErrors = false
}) {
  try {
    store.dispatch(loaderReduxActions.startLinearLoader());
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    store.dispatch(loaderReduxActions.resetLoader());
    if (auth || passErrors) {
      throw error;
    }
    return errorsHandler({ error, errorsToPass, url });
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
}

export async function patchData({ url, data, auth, errorsToPass }) {
  try {
    store.dispatch(loaderReduxActions.startLinearLoader());
    const response = await axios.patch(url, data);
    return response;
  } catch (error) {
    store.dispatch(loaderReduxActions.resetLoader());
    if (auth) {
      throw error;
    }
    return errorsHandler({ error, errorsToPass, url });
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
}

export async function updateData({
  url,
  data,
  errorsToPass,
  successSnackbar,
  config = {}
}) {
  try {
    store.dispatch(loaderReduxActions.startLinearLoader());

    const response = await axios.put(url, data, config);
    if (isDefined(successSnackbar)) {
      if (response.status === RESPONSE_CODES.SUCCESS) {
        showSnackbar({
          messageText: successSnackbar.messageText,
          intlTextKey: successSnackbar.intlTextKey,
          messageType: 'success',
          time: 2000
        });
      }
    }
    return response;
  } catch (error) {
    store.dispatch(loaderReduxActions.resetLoader());
    return errorsHandler({ error, errorsToPass, url });
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
}

export async function deleteData({ url, errorsToPass, successSnackbar }) {
  try {
    store.dispatch(loaderReduxActions.startLinearLoader());
    const response = await axios.delete(url);

    if (isDefined(successSnackbar)) {
      if (response.status === RESPONSE_CODES.SUCCESS) {
        showSnackbar({
          messageText: successSnackbar.messageText,
          intlTextKey: successSnackbar.intlTextKey,
          messageType: 'success',
          time: 2000
        });
      }
    }
    return response;
  } catch (error) {
    store.dispatch(loaderReduxActions.resetLoader());
    return errorsHandler({ error, errorsToPass, url });
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
}
