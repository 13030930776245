import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { OUTSIDE_API } from '../../../consts/endpoints';
import { isUndefined } from '../../../utils/isDefined';

const styles = {
  audio: {
    height: '20px'
  },
  icon: { cursor: 'pointer', margin: '0 5px' },
  hidden: { display: 'none' }
};

function VoicePrompt({
  prompt,
  classes,
  clientId,
  token,
  prompts = [],
  customStyles = {},
  onlyPlay = false
}) {
  const audioRef = useRef();
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const isPromptExist = checkPrompt(prompt, prompts);
    if (isPromptExist) {
      setSrc(
        `${OUTSIDE_API.CLIENTS}/${clientId}/prompts/${prompt}/default?t=${token}`
      );
      audioRef.current.load();
    } else {
      setSrc(null);
      audioRef.current.load();
    }
  }, [prompt, prompts, clientId, token]);

  const onPlayClick = () => {
    audioRef.current.play();
  };

  const onPauseClick = () => {
    audioRef.current.pause();
  };

  const onStopClick = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  return !onlyPlay ? (
    <audio
      ref={audioRef}
      controls
      preload="none"
      className={clsx(classes.audio, { [classes.hidden]: isUndefined(src) })}
      style={customStyles.audio}
    >
      <source src={src} />
    </audio>
  ) : (
    <>
      <i
        className={clsx('fas fa-play', classes.icon, {
          [classes.hidden]: isUndefined(src)
        })}
        onClick={onPlayClick}
      ></i>
      <i
        className={clsx('fas fa-pause', classes.icon, {
          [classes.hidden]: isUndefined(src)
        })}
        onClick={onPauseClick}
      ></i>
      <i
        className={clsx('fas fa-square', classes.icon, {
          [classes.hidden]: isUndefined(src)
        })}
        onClick={onStopClick}
      ></i>
      <audio ref={audioRef} preload="none">
        <source src={src} />
      </audio>
    </>
  );
}

const mapStatesToProps = ({ selectClient, auth, schedules }) => {
  return {
    prompts: schedules.prompts,
    token: auth.authToken.slice(7),
    clientId: selectClient.selectedClient.connect30_domain
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(VoicePrompt);

function checkPrompt(prompt, prompts) {
  if (isUndefined(prompt)) {
    return false;
  }

  const promptData = prompts.find(el => el.id === prompt);

  if (isUndefined(promptData)) {
    return false;
  }

  const isAudioFileExist = promptData.language.find(
    el => el.locale === 'default'
  );

  if (isUndefined(isAudioFileExist)) {
    return false;
  }

  return true;
}
