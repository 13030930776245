import { translate } from '../../../i18n/I18nProvider';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapBreadcrumbsItems(location, user) {
  const [, client, ...pages] = location.pathname.split('/');
  let urls = [];

  pages.reduce((startUrl, page) => {
    let title = '';
    let isUserPage = false;
    let url = null;

    if (page === 'user') {
      title = mapUserName(user);
      isUserPage = true;
    } else if (page === 'settings') {
      title = translate(`MENU.${page.toUpperCase()}`);
      isUserPage = true;
    } else {
      title = page
        .split(/(?=[A-Z])/)
        .join('_')
        .toUpperCase();
      url = `${startUrl}/${page}`;
    }

    urls = [...urls, { title, url, isUserPage }];
    return url;
  }, `/${client}`);

  return urls;
}

export function mapUserName(user = {}) {
  const mitelName = Optional(user.mitelData)
    .map(data => {
      const { name } = data;
      if (isUndefined(name)) {
        return '';
      }

      return `${name.givenName} ${name.familyName}`;
    })
    .or('');

  return Optional(user.name).or(mitelName);
}
