import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { syncJob } from '../actions/syncAction';

import styles from '../styles/actionsSection';

function ActionsSection({ classes, intl, clientId, syncJob }) {
  const onSyncClick = useCallback(
    action => {
      syncJob({ clientId, action });
    },
    [clientId, syncJob]
  );

  return (
    <div className={classes.root}>
      <PrimaryButton onClick={() => onSyncClick('job_sync_users')}>
        {intl.formatMessage({
          id: 'CLIENT.SYNC_USERS'
        })}
      </PrimaryButton>
      <PrimaryButton onClick={() => onSyncClick('job_sim_cards_clean_up')}>
        {intl.formatMessage({
          id: 'CLIENT.SYNC_SIMCARDS'
        })}
      </PrimaryButton>
    </div>
  );
}

const mapStatesToProps = ({ editClient }) => {
  return {
    clientId: editClient.client.id
  };
};

const mapDispatchToProps = { syncJob };

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ActionsSection);
