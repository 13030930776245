import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';

import Optional from '../../../utils/optional';
import { isDefined, isObject } from '../../../utils/isDefined';
import styles from '../styles';

const CreatedBy = ({ data, classes }) => {
  return (
    <>
      <Tooltip
        title={Optional(data)
          .map(createdBy => {
            if (!isObject(createdBy)) {
              return '';
            }

            const { username, email } = createdBy;

            return (
              <div className={classes.createdBy}>
                <div>{`username : ${Optional(username).or('')}`}</div>
                <div>{`email : ${Optional(email).or('')}`}</div>
              </div>
            );
          })
          .or('')}
      >
        <div className={classes.createdByValue}>
          {Optional(data?.name).or('')} {isDefined(data?.name) && '...'}
        </div>
      </Tooltip>
    </>
  );
};

export default withStyles(styles)(CreatedBy);
