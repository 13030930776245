import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { actions as builderReduxActions } from '../../_metronic/ducks/builder';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, thunk))
);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const domain = window.location.hostname;

store.dispatch(builderReduxActions.setDomainName(domain));

export default store;
