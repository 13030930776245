import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const SET_MESSAGE = 'SET_MESSAGE';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const actionTypes = createReduxActionTypes('RESET_PASS', [
  OPEN_DIALOG,
  SET_MESSAGE,
  CLOSE_DIALOG
]);

const DEFAULT_STATE = {
  open: false,
  username: ''
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      return {
        ...state,
        open: true
      };
    }
    case actionTypes[SET_MESSAGE]: {
      return {
        ...state,
        message: action.message
      };
    }
    case actionTypes[CLOSE_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDiaog: () => ({
    type: actionTypes[OPEN_DIALOG]
  }),
  setMessage: message => ({
    type: actionTypes[SET_MESSAGE],
    message
  }),
  closeDialog: () => ({ type: actionTypes[CLOSE_DIALOG] })
};
