import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import withSettings from '../utils/withSettings';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'inherit',
    height: '30px',
    justifyContent: 'flex-start',
    marginLeft: '20px',
    minWidth: '120px'
  },
  available: {
    color: '#41C197'
  }
};

export function AvailabilityBox({
  available,
  classes,
  text,
  incall,
  intl,
  settings
}) {
  return (
    <div
      className={clsx(classes.root, {
        [classes.available]: !incall && available
      })}
      style={{
        color:
          !incall && available
            ? settings.colors.success
            : settings.colors.danger
      }}
    >
      {incall ? intl.formatMessage({ id: 'IN_CALL' }) : text}
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(AvailabilityBox);
