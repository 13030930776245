export default {
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '900px',
    justifyContent: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    padding: '20px 0',
    marginTop: '60px',
    '& button': {
      marginRight: '10px'
    }
  }
};
