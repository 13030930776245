import React from 'react';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import TableActionButtons from './TableActionButtons';

export default function tableColumns() {
  const columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      width: '10%x'
    },
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name',
      width: '33%'
    },
    {
      title: 'TABLE.CONTEXT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.context, term);
      },
      field: 'context',
      width: '32%'
    },
    {
      title: 'TABLE.MAIN',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.main, term);
      },
      field: 'main',
      type: 'boolean',
      width: '32%'
    },
    {
      title: 'ID',
      field: 'id',
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}
