import React, { useState, cloneElement, useEffect, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, FormControlLabel, RadioGroup } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';

import styles, { customStyles } from '../styles/activityExpiration';
import PrimaryButton from '../../customButtons/PrimaryButton';
import SecondaryButton from '../../customButtons/SecondaryButton';
import CustomDialogTitle from '../../customDialogTitle/CustomDialogTitle';
import SetDate from './SetDate';
import updateActivity from '../actions/updateActivity';
import SelectRow from '../../dataRows/SelectRow';
import getAllActivities from '../actions/getAllActivities';
import { trueFalseSelectOptions } from '../../../consts/selectsOptions';
import { isDefined, isStringDefined } from '../../../utils/isDefined';
import CustomRadioButton from './CustomRadioButton';
import DraggableDialog from '../../DraggableDialog';
import Optional from '../../../utils/optional';

export function ActivityExpiration({
  closeDialog,
  classes,
  intl,
  children,
  activity,
  userId,
  setUpdatedActivity,
  setActualAvailability,
  closeMenuOnDialogOpen = () => {}
}) {
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    (async function getActivities() {
      const { options } = await getAllActivities(userId, true);
      setActivities(Optional(options?.activitiesOptions).or([]));
    })();
  }, [userId]);

  const onOpenDialog = () => {
    closeMenuOnDialogOpen();
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirm = async values => {
    const updatedActivity = await updateActivity({ values, userId });
    if (isDefined(updatedActivity)) {
      setUpdatedActivity(updatedActivity);
      setActualAvailability(updatedActivity.available);
    }
    setOpen(false);
  };

  const availabilityOptions = useMemo(() => {
    return trueFalseSelectOptions({
      trueLabel: intl.formatMessage({
        id: 'AVAILABLE'
      }),
      falseLabel: intl.formatMessage({
        id: 'BUSY'
      })
    });
  }, [intl]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="md"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {intl.formatMessage({
            id: 'USER.SET_EXPIRATION'
          })}
        </CustomDialogTitle>
        <Formik
          initialValues={{
            picked: 'never',
            expiration: activity.expiration,
            activity: activity.id,
            available: activity.available
          }}
          onSubmit={handleConfirm}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            handleBlur,
            handleChange,
            errors
          }) => {
            const fieldProps = {
              setFieldValue,
              values,
              handleBlur,
              handleChange,
              columnDirection: true,
              customStyles: customStyles.selectRow
            };

            return (
              <Form className={classes.dialogContent} onSubmit={handleSubmit}>
                <SelectRow
                  name="activity"
                  label="ACTIVITY"
                  options={activities}
                  isEditModal={false}
                  {...fieldProps}
                />
                <SelectRow
                  name="available"
                  label="AVAILABILITY"
                  options={availabilityOptions}
                  isEditModal={false}
                  {...fieldProps}
                />
                <RadioGroup
                  className={classes.radioGroup}
                  name="picked"
                  value={values.picked}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className={classes.radioRow}
                    value="never"
                    control={<CustomRadioButton />}
                    label={intl.formatMessage({
                      id: 'USER.RESET_TIME'
                    })}
                  />
                  <FormControlLabel
                    className={classes.radioRow}
                    value="set"
                    control={<CustomRadioButton />}
                    label={intl.formatMessage({
                      id: 'USER.ACTIVITY_SPECIFY_END_TIME'
                    })}
                  />
                  <Field component={SetDate} name="expiration" />
                  <FormControlLabel
                    className={classes.radioRow}
                    value="nextDay"
                    control={<CustomRadioButton />}
                    label={intl.formatMessage({
                      id: 'USER.UNTIL_NEXT_DAY'
                    })}
                  />
                </RadioGroup>
                <footer className={classes.footer}>
                  <PrimaryButton
                    onClick={handleSubmit}
                    disabled={
                      isStringDefined(errors.expiration) &&
                      values.picked === 'set'
                    }
                  >
                    {intl.formatMessage({
                      id: 'BUTTON.CONFIRM'
                    })}
                  </PrimaryButton>
                  <SecondaryButton onClick={onCloseDialog}>
                    {intl.formatMessage({
                      id: 'BUTTON.CANCEL'
                    })}
                  </SecondaryButton>
                </footer>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}

export default flowRight(injectIntl, withStyles(styles))(ActivityExpiration);
