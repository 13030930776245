import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_ADDRESSES = 'SET_ADDRESSES';
const RESET_ADDRESSES = 'RESET_ADDRESSES';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('ADDRESSES', [
  SET_ADDRESSES,
  RESET_ADDRESSES,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  addresses: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ADDRESSES]: {
      return { ...state, addresses: [...action.addresses] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_ADDRESSES]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setAddresses: addresses => ({
    type: actionTypes[SET_ADDRESSES],
    addresses
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetAddresses: () => ({ type: actionTypes[RESET_ADDRESSES] })
};
