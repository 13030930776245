import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/description';
import { translate } from '../../../i18n/I18nProvider';
import DataInputBox from '../../../partials/dataBox/DataInputBox';

const Description = ({ classes, field, form }) => {
  const { name } = field;
  return (
    <div className={classes.root}>
      <DataInputBox
        label={translate('DESCRIPTION')}
        name={name}
        width={280}
        rows={6}
        {...form}
      />
    </div>
  );
};

export default withStyles(styles)(Description);
