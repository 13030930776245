const styles = theme => {
  return {
    content: {
      [theme.breakpoints.down('xs')]: {
        margin: '5px'
      }
    },
    element: {
      margin: '5px'
    },
    standardValuesContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    bigElement: { width: '280px' },
    checkboxContainer: {
      marginLeft: '5px'
    }
  };
};

export default styles;
