import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_SETTINGS } from './types';
import { actions as reduxActions } from '../reducers';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { selectors as builderGetters } from '../../../../_metronic/ducks/builder';
import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import mapSettingsToStore from './mapSettingsToStore';
import mapSettings from '../../loadingScreen/actions/mapSettings';

export function fetchSettings(partnerId) {
  return {
    type: sagaActions[GET_SETTINGS],
    partnerId
  };
}

export function* getSettings({ partnerId }) {
  const defaultSettings = yield select(builderGetters.getDefaultSettings);

  yield put(loaderReducActions.startLinearLoader());
  try {
    const {
      data: { settings }
    } = yield call(
      getData,
      urlCreator(`${URLS.PARTNERS}/${partnerId}.json`, {})
    );

    const updatedSettings = yield call(
      mapSettings,
      settings.frontend_settings,
      defaultSettings
    );

    yield put(
      reduxActions.setSettings(
        mapSettingsToStore({
          ...settings,
          frontend_settings: { ...updatedSettings }
        }),
        partnerId
      )
    );
  } catch {
    reduxActions.setSettings({});
  }
  yield put(loaderReducActions.stopLinearLoader());
}

function* watchGetAppSettings() {
  yield takeLatest(sagaActions[GET_SETTINGS], getSettings);
}

export default watchGetAppSettings();
