import React, { cloneElement, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';

import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import TransferNumbersForm from './components/TransferNumbersForm';
import { translate } from '../../i18n/I18nProvider';

const TransferNumbers = ({ classes, children }) => {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="md"
        classes={{
          paper: classes.root
        }}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('NUMBERS.FORWARD_NUMBERS')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TransferNumbersForm onCloseDialog={onCloseDialog} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(TransferNumbers);
