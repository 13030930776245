import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_USER_VOICEMAILS } from './types';
import { actions as reduxActions, getters } from '../reducers';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import mapCallLog from './mapCallLog';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import { isDefined } from '../../../utils/isDefined';

export function fetchUserVoicemails({ showMore }) {
  return {
    type: sagaActions[GET_USER_VOICEMAILS],
    showMore
  };
}

export function* getUserVoicemails({ showMore = false }) {
  yield put(loaderReducActions.startSpinLoader());
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );

  let olderThan = null;

  if (showMore) {
    const lastLogId = yield select(getters.getLastVoicemailsId);
    olderThan = JSON.stringify(lastLogId);
  }

  const itemType = ['VOICEMAIL'];

  const itemTypeString = itemType.reduce(
    (str, item) => {
      return `${str}&itemType=${item}`;
    },
    ['']
  );

  let log = [];
  let count = 0;

  try {
    const { historyItems } = yield call(
      getData,
      `${baseUrl}/api/communicationlog/${clientDomain}/${mitelUserId}?t=${mitelToken}${itemTypeString}&limit=8${
        isDefined(olderThan) ? `&olderThan=${olderThan}` : ''
      }`
    );

    if (!showMore) {
      const hintCount = yield call(
        getData,
        `${baseUrl}/api/communicationlog/${clientDomain}/${mitelUserId}?t=${mitelToken}${itemTypeString}&count=true`
      );

      count = hintCount;
    }

    const mappedLog = yield call(mapCallLog, historyItems);

    log = mappedLog;
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(reduxActions.setUserVoicemails(log, count));
    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchGetUserVoicemails() {
  yield takeLatest(sagaActions[GET_USER_VOICEMAILS], getUserVoicemails);
}

export default watchGetUserVoicemails();
