import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_GROUPS = 'SET_GROUPS';
const RESET_GROUPS = 'RESET_GROUPS';
const SET_COLUMNS = 'SET_COLUMNS';
const RESET = 'RESET';

export const actionTypes = createReduxActionTypes('GROUPS', [
  SET_GROUPS,
  RESET_GROUPS,
  SET_COLUMNS,
  RESET
]);

const DEFAULT_STATE = {
  groups: [],
  columns: [],
  reset: true
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_GROUPS]: {
      return { ...state, groups: [...action.groups] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET]: {
      return { ...state, reset: !state.reset };
    }
    case actionTypes[RESET_GROUPS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setGroups: groups => ({
    type: actionTypes[SET_GROUPS],
    groups
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetGroupUsers: () => ({
    type: actionTypes[RESET]
  }),
  resetGroups: () => ({ type: actionTypes[RESET_GROUPS] })
};
