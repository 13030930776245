import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Formik, Form } from 'formik';

import { actions as reduxActions } from './reducers';
import styles, { customStyles } from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import InputRow from '../../partials/dataRows/InputRow';
import ActionsButtons from './components/ActionsButtons';
import validateSchema from './actions/validateSchema';
import { translate } from '../../i18n/I18nProvider';
import SelectRow from '../../partials/dataRows/SelectRow';
import SELECT_OPTIONS from '../../consts/selectsOptions';
import editCdrHook from './actions/editCdrHook';
import getCdrHooks from '../../pages/cdrHooks/actions/getCdrHooks';
import CheckboxRow from '../../partials/dataRows/CheckboxRow';
import Info from './components/Info';
import withUserCheck from '../../utils/withUserCheck';

const EditCdrHook = ({
  classes,
  open,
  closeEditCdrHook,
  isEdit,
  cdrHook = {},
  getCdrHooks,
  connectClientId,
  isSuperAdmin
}) => {
  const onSubmit = useCallback(
    async values => {
      const requestDone = await editCdrHook({
        values,
        isEdit,
        id: cdrHook.id,
        connectClientId,
        isSuperAdmin
      });

      if (requestDone) {
        getCdrHooks(connectClientId);
        return closeEditCdrHook();
      }
    },
    // eslint-disable-next-line
    [isEdit, cdrHook.id]
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditCdrHook();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditCdrHook}>
        {translate(
          isEdit ? 'CDR_HOOKS.EDIT_CDR_HOOK' : 'CDR_HOOKS.ADD_CDR_HOOK'
        )}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Info />
        <Formik
          initialValues={
            isEdit
              ? {
                  ...cdrHook,
                  target_url: isSuperAdmin
                    ? cdrHook.target_url
                    : cdrHook.target_url.replace('https://', '')
                }
              : {
                  target_url: '',
                  type: '',
                  ongoing: false,
                  public: isSuperAdmin ? false : true
                }
          }
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            dirty,
            setFieldValue
          }) => {
            const fieldProps = { errors, values, handleBlur, handleChange };
            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                <InputRow
                  label="CDR_HOOKS.TARGET_URL"
                  name="target_url"
                  customStyles={customStyles.inputRow}
                  inputAdornmentText={isSuperAdmin ? null : 'https://'}
                  {...fieldProps}
                />
                <SelectRow
                  name="type"
                  label="TABLE.TYPE"
                  customStyles={customStyles.typeSelect}
                  options={SELECT_OPTIONS.CDR_HOOK_TYPE}
                  isEditModal={false}
                  setFieldValue={setFieldValue}
                  {...fieldProps}
                />
                <CheckboxRow
                  label="ONGOING"
                  name="ongoing"
                  customStyles={customStyles.checkbox}
                  {...fieldProps}
                />
                {isSuperAdmin && (
                  <CheckboxRow
                    label="PUBLIC"
                    name="public"
                    customStyles={customStyles.checkbox}
                    {...fieldProps}
                  />
                )}
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeEditCdrHook}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ editCdrHook, selectClient }) => {
  return {
    ...editCdrHook,
    connectClientId: selectClient.selectedClient?.connect30_domain
  };
};

const mapDispatchToProps = {
  closeEditCdrHook: reduxActions.closeEditCdrHook,
  getCdrHooks
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditCdrHook);
