import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from '../styles/errorTab';

function ErrorTab({ classes, error }) {
  return (
    <div className={classes.errorContainer}>
      <div className={classes.endpoint}>{error.endpoint}</div>
      <div>{error.status}</div>
      {error.errorTextArray.map((el, i) => {
        return <div key={i}>{el}</div>;
      })}
    </div>
  );
}

export default withStyles(styles)(ErrorTab);
