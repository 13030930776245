import React from 'react';
import { Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';

import { actions as reduxActions } from './reducers';
import CheckNumber from '../../pages/checkNumber/CheckNumber';
import styles from './styles';

function RightPanel({ open, closePanel, classes }) {
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={closePanel}
      transitionDuration={200}
    >
      <i
        className={clsx('far fa-chevron-double-right', classes.closeIcon)}
        onClick={closePanel}
      ></i>
      <CheckNumber fromRightPanel={true} />
    </Drawer>
  );
}

const mapStatetoProps = ({ rightPanel }) => {
  return {
    open: rightPanel.open
  };
};

const mapDispatchToProps = {
  closePanel: reduxActions.closeRightPanel
};

export default flowRight(
  connect(mapStatetoProps, mapDispatchToProps),
  withStyles(styles)
)(RightPanel);
