import { isDefined, isObject, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapScheduleForEdit(schedule) {
  const {
    end = {},
    start = {},
    recurrence,
    recurrenceEnd,
    tableData,
    ...restSchedule
  } = schedule;

  const mappedRecurrence = Optional(recurrence)
    .map(reccurence =>
      reccurence.reduce((obj, el, i) => {
        return { ...obj, [`reccurence_${i}`]: el === 1 ? true : false };
      }, {})
    )
    .or([]);

  const { reccurenceEndType, reccurenceEndDate } = mapReccurenceEnd(
    recurrenceEnd
  );

  return {
    ...restSchedule,
    ...mappedRecurrence,
    reccurenceEndType,
    reccurenceEndDate,
    startDateSet: isDefined(start.date),
    endDateSet: isDefined(end.date),
    endDate: end.date,
    endTime: formatTime(end.time),
    startDate: start.date,
    startTime: formatTime(start.time)
  };
}

function mapReccurenceEnd(recurrenceEnd) {
  let reccurenceEndType = 'NEVER';
  let reccurenceEndDate = null;

  if (isObject(recurrenceEnd)) {
    reccurenceEndType = 'DATE';
    reccurenceEndDate = Optional(recurrenceEnd.value)
      .map(value => Number(value))
      .or(null);
  }

  return { reccurenceEndType, reccurenceEndDate };
}

function formatTime(time) {
  if (isUndefined(time)) {
    return time;
  }
  return time-3600000;
}
