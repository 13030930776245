import { isNumber } from './isDefined';

export default function calculateMbToGb(number, changeDotToComma = false) {
  if (!isNumber(number)) {
    return '';
  }

  const restOfDivision = number % 1000;
  let result =
    restOfDivision !== 0 ? (number / 1000).toFixed(2) : number / 1000;

  if (changeDotToComma === true) {
    result = result !== 0 ? result.toString().replace('.', ',') : '0,00';
  }

  return result;
}
