import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from './styles';
import { actions as rightPanelReduxActions } from '../rightPanel/reducers';

const SearchIcon = ({ openRightPanel, hideOnMobile = false, classes }) => {
  return (
    <i
      className={clsx('fas fa-search', classes.root, {
        [classes.hideOnMobile]: hideOnMobile
      })}
      onClick={openRightPanel}
    ></i>
  );
};

const mapDispatchToProps = {
  openRightPanel: rightPanelReduxActions.openRightPanel
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(SearchIcon);
