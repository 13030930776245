import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { isObject } from '../../../utils/isDefined';
import EditIcon from './EditIcon';
import InfoView from './InfoView';

const ActionButtons = ({ classes, clientId, rowData }) => {
  return (
    <div className={classes.actionButtons}>
      <InfoView
        dataObject={rowData.object}
        id={rowData.id}
        type={rowData.item_type}
      >
        <CustomIconButton
          iconTooltipIntlKey="EDIT.SHOW_ELEMENT"
          iconClassName="far fa-info-circle"
          disabled={!isObject(rowData.object)}
        />
      </InfoView>
      <EditIcon rowData={rowData} clientId={clientId} />
    </div>
  );
};

export default flowRight(withStyles(styles))(ActionButtons);
