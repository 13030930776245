import { getData } from '../../../utils/http';
import { actions as builderReduxActions } from '../../../../_metronic/ducks/builder';
import RESPONSE_CODES from '../../../consts/responseCodes';
import DEFAULT_SETTINGS from '../../../consts/frontendSettings';
import { URLS } from '../../../consts/endpoints';
import mapSettings from './mapSettings';
import { isObject } from '../../../utils/isDefined';

const getSettings = () => {
  const domain = window.location.hostname;
  const isAppleDevice = checkIfAppleDevice();
  return async dispatch => {
    let defaultSettings = DEFAULT_SETTINGS;
    try {
      const response = await getData(`${URLS.FRONTEND_SETTINGS}/default`);
      defaultSettings = isObject(response)
        ? mapSettings(response)
        : DEFAULT_SETTINGS;

      dispatch(builderReduxActions.setDefaultSettings(defaultSettings));
    } catch {
      dispatch(builderReduxActions.setDefaultSettings(DEFAULT_SETTINGS));
    }

    try {
      const { data: settings } = await getData({
        options: {
          url: `${URLS.FRONTEND_SETTINGS}/${domain}`
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND]
      });

      dispatch(
        builderReduxActions.setSettings(mapSettings(settings, defaultSettings))
      );
    } catch (e) {
      dispatch(builderReduxActions.setSettings(defaultSettings));
    } finally {
      dispatch(builderReduxActions.setIsApple(isAppleDevice));
    }
  };
};

export default getSettings;

const checkIfAppleDevice = () => {
  return /^(iPhone|iPad|iPod|Mac)/.test(navigator.platform);
};
