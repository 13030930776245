import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { actions as reduxActions } from '../reducers';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import getOrders from '../actions/getOrders';
import { translate } from '../../../i18n/I18nProvider';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { isDefined } from '../../../utils/isDefined';
import OrdersTableHeader from './OrdersTableHeader';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

const OrdersTable = ({
  orders,
  columns,
  setColumns,
  getOrders,
  location,
  filteredBy
}) => {
  const [filtering, setFiltering] = useState(false);
  const client = useMemo(() => getIdFromUrl(location), [location]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getOrders(client);
    // eslint-disable-next-line
  }, [client]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns(client);
  }, [client]);

  useEffect(() => {
    if (refresh) {
      getOrders(client);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: isDefined(client) ? 'Orders-1.2' : 'Order-admin-1.1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  const actions = useMemo(
    () => [
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: translate('BUTTON.FILTER')
      },
      {
        icon: () => <RefreshButton tableHeader={true} />,
        onClick: () => setRefresh(true),
        isFreeAction: true
      }
    ],
    // eslint-disable-next-line
    [filtering]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: isDefined(client) ? 'Orders-1.2' : 'Order-admin-1.1'
      });
    },
    [setColumns, tableDefaultColumns, client]
  );

  const filteredOrders = useMemo(() => {
    if (filteredBy === 'all') {
      return orders;
    }

    return orders.filter(order => order.status === filteredBy);
  }, [orders, filteredBy]);

  return (
    <>
      <MaterialTable
        title={<OrdersTableHeader />}
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={filteredOrders}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </>
  );
};

const mapStatesToProps = ({ orders }) => {
  return {
    ...orders
  };
};

const mapDispatchToProps = {
  getOrders,
  setColumns: reduxActions.setColumns
};

export default flowRight(
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps)
)(OrdersTable);
