import React, { useCallback, useState } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import styles, { customStyles } from '../styles/informationStep';
import { actions as reduxActions } from '../reducers';
import validateSchema from '../actions/validateSchema';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import {
  isStringEmpty,
  isDefined,
  isUndefined,
  isStringDefined
} from '../../../utils/isDefined';
import fetchOperator from '../actions/fetchOperator';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import DirFieldsElement from './DirFieldsElement';
import SelectAccount from '../../../partials/selectAccount/SelectAccount';
import MandatoryField from './MandatoryField';
import numberAvailabilityCheck from '../actions/numberAvailabilityCheck';
import mapDefaultAddress from '../actions/mapDefaultAddress';
import withSettings from '../../../utils/withSettings';

export function InformationStep({
  classes,
  intl,
  resestUserWizard,
  handleNext,
  setUserData,
  firstStepData,
  selectOptions,
  setCheckNumberData,
  settings,
  defaultSettings
}) {
  const [numberError, setNumberError] = useState(null);

  const onSubmit = useCallback(
    async values => {
      let stepSize = 1;

      if (
        isStringEmpty(values.mobile_number) &&
        !values.isNewNumber &&
        (isStringDefined(values.internal_number) ||
          isStringDefined(values.landline_number))
      ) {
        stepSize = 3;
      } else if (isStringEmpty(values.mobile_number) && values.isNewNumber) {
        stepSize = 2;
      } else {
        const {
          isNumberAvailable,
          availabilityError
        } = await numberAvailabilityCheck(values.mobile_number);

        if (!isNumberAvailable) {
          setNumberError(
            intl.formatMessage({
              id: Optional(availabilityError).or(
                'REQUEST_NEW_USER.NUMBER_IN_USE'
              )
            })
          );
          stepSize = 0;
        }

        if (isNumberAvailable) {
          const { data: checkNumberData, error } = await fetchOperator(
            values.mobile_number
          );
          if (isUndefined(error) && isDefined(checkNumberData)) {
            stepSize = checkNumberData.our === true ? 2 : 1;
            setCheckNumberData(checkNumberData);
            setUserData(
              { current_operator: checkNumberData.netoper_N },
              'secondStep'
            );
            setNumberError(null);
          } else {
            if (
              Optional(error.status)
                .map(status => status === RESPONSE_CODES.NOT_FOUND)
                .or(false)
            ) {
              setNumberError(
                intl.formatMessage({
                  id: 'REQUEST_NEW_USER.INCORRECT_NUMBER'
                })
              );
            }
            stepSize = 0;
          }
        }
      }

      const fourthStep = mapDefaultAddress(values);

      handleNext(stepSize);
      setUserData({ ...values }, 'firstStep');
      setUserData(fourthStep, 'fourthStep');
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Formik
      initialValues={{ ...firstStepData }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl).infoStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = {
          errors: {
            ...errors,
            mobile_number: Optional(numberError).or(errors.mobile_number)
          },
          values,
          handleBlur,
          handleChange
        };

        if (values.isNewNumber) {
          values.mobile_number = '';
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={classes.root}>
              <div>
                <SelectRow
                  name="copy_from_user"
                  label="REQUEST_NEW_USER.COPY_USER"
                  isEditModal={false}
                  options={selectOptions.usersSelectOptions}
                  setFieldValue={setFieldValue}
                  customStyles={customStyles.select}
                  isRequired={true}
                  {...fieldProps}
                />
                <InputRow
                  label="REQUEST_NEW_USER.FIRST_NAME"
                  name="first_name"
                  customStyles={customStyles.input}
                  isRequired={true}
                  {...fieldProps}
                />
                <InputRow
                  label="REQUEST_NEW_USER.LAST_NAME"
                  name="last_name"
                  customStyles={customStyles.input}
                  isRequired={true}
                  {...fieldProps}
                />
                <InputRow
                  label="USER.CUSTOM_ID"
                  name="custom_id"
                  infoIcon={true}
                  infoIconText={`${intl.formatMessage({
                    id: 'TOOLTIP.CUSTOM_USER_ID'
                  })} ${Optional(settings?.title).or(defaultSettings?.title)}`}
                  customStyles={customStyles.input}
                  {...fieldProps}
                />
                <InputRow
                  name="department"
                  label="DEPARTMENT"
                  customStyles={customStyles.input}
                  {...fieldProps}
                />
                <InputRow
                  label="REQUEST_NEW_USER.COST_CENTER"
                  name="cost_center"
                  customStyles={customStyles.input}
                  {...fieldProps}
                />
                <Field
                  component={SelectAccount}
                  fullAccount={true}
                  selectOptions={selectOptions.accountsSelectsOptions}
                />
              </div>
              <FieldArray
                name="fields"
                render={arrayHelpers => (
                  <DirFieldsElement arrayHelpers={arrayHelpers} />
                )}
              />
              <div>
                <InputRow
                  name="email"
                  label="EMAIL"
                  customStyles={customStyles.input}
                  isRequired={true}
                  {...fieldProps}
                />
                <InputRow
                  label="REQUEST_NEW_USER.MOBILE"
                  name="mobile_number"
                  customStyles={customStyles.input}
                  placeholder={intl.formatMessage({
                    id: 'REQUEST_NEW_USER.MOBILE_NUMBER_PLACEHOLDER'
                  })}
                  disabled={values.isNewNumber}
                  {...fieldProps}
                />
                <CheckboxRow
                  name="isNewNumber"
                  label="REQUEST_NEW_USER.NEW_NUMBER_CHECKBOX"
                  customStyles={customStyles.checkbox}
                  {...fieldProps}
                />
                <SelectRow
                  name="landline_number"
                  label="REQUEST_NEW_USER.FIXED"
                  isEditModal={false}
                  options={[
                    ...filterSelectOptions(
                      selectOptions.publicNumbersSelectOptions,
                      values.internal_number
                    )
                  ]}
                  setFieldValue={setFieldValue}
                  customStyles={customStyles.select}
                  menuPlacement="top"
                  maxMenuHeight={200}
                  {...fieldProps}
                />
                <SelectRow
                  name="internal_number"
                  label="REQUEST_NEW_USER.LOCAL"
                  isEditModal={false}
                  options={[
                    ...filterSelectOptions(
                      selectOptions.privateNumbersSelectOptions,
                      values.landline_number
                    )
                  ]}
                  setFieldValue={setFieldValue}
                  customStyles={customStyles.select}
                  menuPlacement="top"
                  maxMenuHeight={200}
                  {...fieldProps}
                />
              </div>
            </div>
            <MandatoryField />
            <div className={classes.footer}>
              <PrimaryButton onClick={handleSubmit}>
                {intl.formatMessage({
                  id: 'BUTTON.NEXT'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={resestUserWizard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ requestUserWizard }) => {
  return {
    firstStepData: requestUserWizard.userData.firstStep,
    selectOptions: requestUserWizard.selectOptions
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(InformationStep);

function filterSelectOptions(selectOptions, optionsToFilter) {
  const filteredOptions = Optional(selectOptions)
    .map(options => options.filter(option => option.value !== optionsToFilter))
    .or([]);

  return [{ value: '', label: '' }, ...filteredOptions];
}
