import { createSagaActionTypes } from '../../../utils/actionFactory';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const OTHER_LOGIN = 'OTHER_LOGIN';

export const actions = createSagaActionTypes('AUTH', [
  LOGIN,
  LOGOUT,
  OTHER_LOGIN
]);
