const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '400px',
      height: 'fit-content',
      alignItems: 'center',
      margin: '5px 30px 5px 10px'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      color: '#16374F',
      fontWeight: 500,
      width: '120px',
      marginRight: '18px',
      paddingLeft: '20px'
    },

    labelContainer: {
      fontSize: '12px',
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'column'
    },
    onlyAccount: {
      border: '1px solid #C4C4C4',
      paddingLeft: '10px',
      width: '245px',
      minHeight: '36px',
      justifyContent: 'center'
    }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: { width: '245px' },
  menu: { width: 'fit-content' }
};
