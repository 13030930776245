import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_DATALIMIT } from './types';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import mapDataForChart from './mapDataForChart';
import findDatalimitOwner from '../../../utils/findDataLimitOwner';
import findDatalimitNetwork from '../../../utils/findDatalimitNetwork';
import searchInArray from '../../../utils/searchInArray';
import mapLastMonth from '../../datalimitStats/actions/mapDataForChart';

const BRAND_ARRAY = ['TDC', 'Telenor'];

const DATA_LIMITS_QUERY_PARAMS = {
  extra_fields: {
    data_limits: ['accumulated_data_usages', 'accumulated_data_usage_month']
  },
  include: ['cell_numbers.sim_card.owner', 'client', 'cell_numbers'],
  fields: { cell_numbers: 'number', client: 'name' }
};

export function getDatalimit(dataLimitId) {
  return {
    type: sagaActions[GET_DATALIMIT],
    dataLimitId
  };
}

export function* fetchDatalimit({ dataLimitId }) {
  const { data: dataLimit } = yield call(
    getData,
    urlCreator(
      `${URLS.DATA_LIMITS}/${dataLimitId}.json`,
      DATA_LIMITS_QUERY_PARAMS
    )
  );

  const network = yield call(findDatalimitNetwork, dataLimit);

  const showCharts = yield call(searchInArray, {
    array: BRAND_ARRAY,
    term: network
  });

  const chartData = showCharts ? yield call(mapDataForChart, dataLimit) : {};

  const lastMonthChartData = yield call(mapLastMonth, dataLimit);

  const owners = yield call(findDatalimitOwner, dataLimit);

  yield put(
    reduxActions.setDatalimit({
      ...dataLimit,
      chartData,
      owners,
      showCharts,
      lastMonthChartData
    })
  );
}

function* watchGetDatalimit() {
  yield takeLatest(sagaActions[GET_DATALIMIT], fetchDatalimit);
}

export default watchGetDatalimit();
