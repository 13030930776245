import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_PERMISSION } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import { getPermissions } from './getPermissions';

export function removePermission(id) {
  return {
    type: sagaActions[DELETE_PERMISSION],
    id
  };
}

export function* deletePermission({ id }) {
  yield put(loaderReducActions.startSpinLoader());

  const response = yield call(deleteData, {
    url: `${URLS.PERMISSIONS}/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.DELETE_PERMISSION',
      messageType: 'success'
    });

    yield put(getPermissions());
  }
}

function* watchDeletePermission() {
  yield takeLatest(sagaActions[DELETE_PERMISSION], deletePermission);
}

export default watchDeletePermission();
