import { Ability, AbilityBuilder } from '@casl/ability';
import store from '../store/store';
import { isArray, isDefined, isStringDefined, isUndefined } from './isDefined';

const ability = new Ability();

export default function can(action, subject, field) {
  if (isStringDefined(field)) {
    const { __caslSubjectType__, ...rest } = subject;
    return ability.can(action, __caslSubjectType__, field, rest);
  } else {
    return ability.can(action, subject);
  }
}

store.subscribe(() => {
  const auth = store.getState().auth;
  ability.update(defineRulesFor(auth));
});

function defineRulesFor(auth = {}) {
  const { user } = auth;

  const userRules = user?.rules;
  const { can, rules } = new AbilityBuilder();

  if (isDefined(userRules)) {
    userRules.forEach(rule => {
      if (isUndefined(rule.conditions)) {
        if (isArray(rule.attributes)) {
          can(rule.actions[0], rule.subject[0], rule.attributes);
        } else {
          can(rule.actions[0], rule.subject[0]);
        }
      } else {
        if (isArray(rule.attributes)) {
          can(
            rule.actions[0],
            rule.subject[0],
            rule.attributes,
            rule.conditions
          );
        } else {
          can(rule.actions[0], rule.subject[0], rule.conditions);
        }
      }
    });
  }

  return rules;
}
