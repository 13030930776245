import Optional from './optional';
import { isStringEmpty } from './isDefined';

export default function changeValuesForFormik({ values, fromNull }) {
  if (fromNull) {
    const valuesKeys = Object.keys(values);
    return valuesKeys.reduce((obj, value) => {
      return { ...obj, [value]: Optional(values[value]).or('') };
    }, {});
  }

  return {
    ...values,
    description: isStringEmpty(values.description) ? null : values.description,
    client_id: isStringEmpty(values.client_id)
      ? null
      : Number(values.client_id),
    user_id: isStringEmpty(values.user_id) ? null : Number(values.user_id)
  };
}
