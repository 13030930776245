import { TextField, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles/textFieldRow';

const TextFieldRow = ({ label, value, classes, disabled = false }) => {
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <TextField
        variant="outlined"
        margin="dense"
        value={value}
        className={classes.fieldValue}
        disabled={disabled}
        classes={{
            root: classes.textfieldRoot,
          }}
      />
    </div>
  );
};

export default withStyles(styles)(TextFieldRow);
