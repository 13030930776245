import React from 'react';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';

import Optional from '../../../app/utils/optional';
import withSettings from '../../../app/utils/withSettings';

export function MenuItemText({
  item,
  parentItem,
  intl,
  settings,
  isActive,
  mouseOver,
  fromTopMenu
}) {
  const icon = Optional(item.awesomeIcon).or(item.icon);
  const customIconStyles = Optional(item.customStyles)
    .map(styles => styles.icon)
    .or({});

  return (
    <>
      {icon && (
        <i
          className={clsx('kt-menu__link-icon', icon)}
          style={{
            ...customIconStyles,
            color:
              isActive || mouseOver
                ? settings.colors.aside_menu_active_text
                : settings.colors.aside_menu_text
          }}
        ></i>
      )}

      {parentItem && parentItem.bullet === 'dot' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === 'line' && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span />
        </i>
      )}

      <span
        className="kt-menu__link-text"
        style={{
          whiteSpace: 'nowrap',
          color: fromTopMenu
            ? settings.colors.primary
            : isActive || mouseOver
            ? settings.colors.aside_menu_active_text
            : settings.colors.aside_menu_text
        }}
      >
        {!item.translate
          ? item.title
          : intl.formatMessage({ id: item.translate })}
      </span>

      {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {item.submenu && (
        <i
          className="kt-menu__ver-arrow la la-angle-right"
          style={{
            color: fromTopMenu
              ? settings.colors.primary
              : isActive || mouseOver
              ? settings.colors.aside_menu_active_text
              : settings.colors.aside_menu_text
          }}
        />
      )}
    </>
  );
}

export default flowRight(injectIntl, withSettings)(MenuItemText);
