import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const OPEN_ADD_PERMISSION = 'OPEN_ADD_PERMISSION';
const RESET_ADD_PERMISSION = 'RESET_ADD_PERMISSION';

export const actionTypes = createReduxActionTypes('ADD_PERMISSION', [
  OPEN_ADD_PERMISSION,
  RESET_ADD_PERMISSION
]);

const DEFAULT_STATE = {
  open: false,
  isEdit: false,
  permission: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_ADD_PERMISSION]: {
      const { isEdit, permission } = action;
      return {
        ...state,
        open: true,
        isEdit: Optional(isEdit).or(false),
        permission: Optional(permission).or({})
      };
    }
    case actionTypes[RESET_ADD_PERMISSION]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openAddPermission: (permission, isEdit) => ({
    type: actionTypes[OPEN_ADD_PERMISSION],
    isEdit,
    permission
  }),
  closeAddPermission: () => ({ type: actionTypes[RESET_ADD_PERMISSION] })
};
