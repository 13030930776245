import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { isDefined } from '../../../utils/isDefined';
import usePagination from '../../../utils/usePagination';
import withSettings from '../../../utils/withSettings';
import getAdminInfo from '../actions/getAdminsInfo';
import styles from '../styles/infoBox';
import AdminBox from './AdminBox';
import { actions as reduxActions } from '../reducers';

const ROWS_PER_PAGE = 10;

function InfoBox({
  classes,
  intl,
  selectedClientId,
  admins,
  activities,
  settings,
  resetAdmins
}) {
  const [page, setPage] = useState(0);
  const paginated = usePagination(admins, page, ROWS_PER_PAGE);

  useEffect(() => {
    resetAdmins();
    (async function() {
      if (isDefined(selectedClientId)) {
        await getAdminInfo({ selectedClientId });
      }
    })();

    // eslint-disable-next-line
  }, [selectedClientId]);

  return (
    admins.length > 0 && (
      <div
        className={classes.root}
        style={{
          backgroundColor: settings.colors.aside_menu,
          color: settings.colors.aside_menu_text
        }}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            {intl.formatMessage({
              id: 'DASHBOARD.INFO_TEXT_1'
            })}
          </div>
          <div className={classes.info}>
            {intl.formatMessage({
              id: 'DASHBOARD.INFO_TEXT_2'
            })}
          </div>
        </div>
        {paginated.map((admin, i) => {
          return <AdminBox admin={admin} key={i} activities={activities} />;
        })}
        {admins.length > ROWS_PER_PAGE ? (
          <div className={classes.pagination}>
            <i
              className={clsx('fas fa-chevron-left', {
                [classes.hidden]: page === 0
              })}
              onClick={() => setPage(page - 1)}
            ></i>
            <div className={classes.paginationInfo}>
              {`${page + 1} ${intl.formatMessage({
                id: 'OF'
              })} ${Math.ceil(admins.length / ROWS_PER_PAGE)}`}
            </div>
            <i
              className={clsx('fas fa-chevron-right', {
                [classes.hidden]: admins.length / ROWS_PER_PAGE < page + 1
              })}
              onClick={() => setPage(page + 1)}
            ></i>
          </div>
        ) : null}
      </div>
    )
  );
}

const mapStatesToProps = ({ selectClient, dashboard }) => {
  return {
    ...dashboard,
    selectedClientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  resetAdmins: reduxActions.resetAdmins
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(InfoBox);
