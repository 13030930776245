import { withStyles } from '@material-ui/core';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import styles from '../styles/lastClients';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

const LastClients = ({
  clientsList,
  history,
  classes,
  handleChange,
  settings
}) => {
  return (
    <div className={classes.root}>
      {doesArrayHasLength(history) && (
        <div className={classes.label}>{`${translate(
          'LAST_SEARCHES'
        )} : `}</div>
      )}
      <div className={classes.container}>
        {history.map(client => {
          const onList = clientsList.find(el => el.id === client);
          return (
            isDefined(onList) && (
              <div
                key={client}
                className={classes.element}
                style={{
                  backgroundColor: settings.colors.modal_header,
                  color: settings.colors.modal_header_text
                }}
                onClick={() => handleChange({ value: client })}
              >
                {onList.name}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientsList: Optional(selectClient.selectClientsList).or([]),
    history: selectClient.history
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(LastClients);
