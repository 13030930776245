import { toAbsoluteUrl } from '../../_metronic';

export const partnersValues = {
  default: {
    SUPPORT_EMAIL: '',
    WEBSITE: '',
    SUPPORT_PHONE_NUMBER: '',
    HELPDESK_WEBSITE: '',
    LOGO: toAbsoluteUrl('/media/logos/big_logo.png'),
    SMALL_LOGO: toAbsoluteUrl('/media/logos/small_logo.png'),
    MAIN_COLOR: '#16374f',
    SECONDARY_COLOR: '#AAC2D1'
  }
};

export default {
  colors: {
    primary: '#38344d',
    success: ' #92BC68',
    danger: '#C95454',
    warning: '#D8C85A',
    white: '#ffffff',
    links: '#2D81B5',
    buttons: '#fe9305',
    buttons_text: '#ffffff',
    login_left: '#1ba9b5',
    login_left_text: '#ffffff',
    login_right: '#edf0f1',
    login_right_text: '#38344d',
    aside_menu: '#1ba9b5',
    aside_menu_text: '#ffffff',
    aside_menu_active: '#0699a3',
    aside_menu_active_text: '#ffffff',
    aside_menu_brand: '#0699a3',
    modal_header: '#0699a3',
    modal_header_text: '#ffffff',
    dashboard_button: '#ffffff',
    dashboard_icon: '#1ba9b5',
    dashboard_text: '#38344d'
  },
  logos: {
    big: toAbsoluteUrl('/media/logos/big_logo.png'),
    small: toAbsoluteUrl('/media/logos/small_logo.png')
  },
  footer:
    'Dstny A/S | Skodsborgvej 305 D | 2850 N&aelig;rum | Tlf.:  <href="tel:+4588887777">+45 88 88 77 77</a> | Teknisk Support: <a  href="mailto:support@ipvision.dk">support@ipvision.dk</a> | Kundeservice: <a href="mailto:kundeservice@dstny.dk">kundeservice@dstny.dk</a> | CVR: 28313160',
  title: 'Dstny DK selfcare',
  favicon: toAbsoluteUrl('/media/logos/destiny.ico'),
  website_url: '',
  support_phone_number: ''
};
