import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Checkbox, Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import { fetchHistory, formatDate } from './actions/getHistory';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import HistoryTable from './components/HistoryTable';
import { isDefined } from '../../utils/isDefined';
import InfoSimCardSection from './components/InfoSimCardSection';
import InfoDatalimitSection from './components/InfoDatalimitSection';
import InfoNumberRangeSection from './components/InfoNumberRangeSection';
import Optional from '../../utils/optional';
import clsx from 'clsx';

export function QueueStats({
  classes,
  open,
  intl,
  itemType,
  id,
  resetShowHistory,
  fetchHistory,
  history,
  titleText,
  itemTypeKey,
  historyObject = {},
  isSimcardHistory = false,
  isDatalimitHistory = false,
  isNumberRangeHistory = false,
  showStandardValues = true,
  showAllValuesCheckbox,
  showAllValues,
  setShowAllValues
}) {
  useEffect(() => {
    if (open === true) {
      fetchHistory({ id, itemType });
    }
    // eslint-disable-next-line
  }, [itemType, open, id]);

  const title = useMemo(() => {
    const titlePageName = Optional(itemTypeKey)
      .map(key =>
        intl.formatMessage({
          id: key
        })
      )
      .or(itemType);
    return `${intl.formatMessage({
      id: 'HISTORY'
    })} ${titlePageName} : ${titleText ? titleText : id}`;
  }, [itemTypeKey, itemType, titleText, id, intl]);

  const handleCheckboxChange = useCallback(
    e => {
      setShowAllValues(e.target.checked);
    },
    [setShowAllValues]
  );

  return (
    isDefined(history) && (
      <Dialog
        maxWidth="lg"
        PaperComponent={DraggableDialog}
        classes={{
          paper: classes.content
        }}
        open={open}
        onClose={resetShowHistory}
      >
        <CustomDialogTitle onCloseClick={resetShowHistory}>
          {title}
        </CustomDialogTitle>
        <DialogContent>
          {isSimcardHistory && <InfoSimCardSection data={historyObject} />}
          {isDatalimitHistory && <InfoDatalimitSection data={historyObject} />}
          {isNumberRangeHistory && (
            <InfoNumberRangeSection data={historyObject} />
          )}
          <div className={classes.standardValuesContainer}>
            {showStandardValues && (
              <div className={clsx(classes.element, classes.bigElement)}>
                {isDefined(historyObject.created_at) &&
                  `${intl.formatMessage({
                    id: 'CREATED_AT'
                  })} : ${Optional(historyObject.created_at)
                    .map(createdAt => formatDate(createdAt))
                    .or('')}`}
              </div>
            )}
            {showAllValuesCheckbox && (
              <div className={classes.checkboxContainer}>
                {intl.formatMessage({
                  id: 'SHOW_ALL_VALUES'
                })}
                <Checkbox
                  checked={showAllValues}
                  onChange={handleCheckboxChange}
                />
              </div>
            )}
          </div>
          <HistoryTable showAllValues={showAllValues} />
        </DialogContent>
      </Dialog>
    )
  );
}

const mapStatesToProps = ({ showHistory }) => {
  const isSimcardHistory = showHistory.itemType === 'SimCard';
  const isDatalimitHistory = showHistory.itemType === 'DataLimit';
  const isNumberRangeHistory = showHistory.itemType === 'ExternalNumberRange';

  return {
    ...showHistory,
    isSimcardHistory,
    isDatalimitHistory,
    isNumberRangeHistory
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  fetchHistory
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(QueueStats);
