import { URLS } from '../../../consts/endpoints';
import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default async function updatePassword(data) {
  const { isNotDialog, ...restData } = data;
  try {
    const response = await updateData({
      url: URLS.PASS,
      data: { user: { ...restData } },
      errorsToPass: [RESPONSE_CODES.UNPROCESSABLE_ENTITY]
    });

    if (response.status === RESPONSE_CODES.NO_CONTENT) {
      return { intlText: 'RESET_PASS.SUCCESS', type: 'success' };
    }
  } catch (e) {
    const errors = Optional(e.response.data.errors).or({});
    if (isDefined(errors.current_password)) {
      return {
        intlText: 'MESSAGE.INVALID_CURRENT_PASSWORD',
        type: 'error'
      };
    }
    return {
      text: JSON.stringify(errors),
      type: 'error'
    };
  }
}
