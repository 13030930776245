import React, { useState, useEffect, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import Select from 'react-select';
import clsx from 'clsx';

import styles, { customStyles } from './styles';
import getPresence from './actions/getPresence';
import getAllActivities from './actions/getAllActivities';
import updatePresence from './actions/updatePresence';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import ActivityExpiration from './components/ActivityExpiration';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import { isArrayEmpty, isDefined } from '../../utils/isDefined';
import PresenceMenu from './components/PresenceMenu';

export function UserPresence({
  classes,
  userId,
  intl,
  settings,
  setActualAvailability,
  updatedActivityId,
  updatedActivityAttributes,
  loggedUserId
}) {
  const [activity, setActivity] = useState({});
  const [activities, setActivities] = useState({
    activities: [],
    activitiesOptions: []
  });
  const [shortcuts, setShortcuts] = useState([]);
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    (async function fetchPresence() {
      const { activity } = await getPresence(userId);
      const { options, shortcutsOptions } = await getAllActivities(
        userId,
        false,
        loggedUserId
      );
      setActivities(options);
      setShortcuts(shortcutsOptions);
      setActivity(activity);
      setActualAvailability(activity.available);
    })();
    // eslint-disable-next-line
  }, [updatedActivityId, updatedActivityAttributes]);

  const rootStyles = useMemo(() => {
    return {
      color: activity.available
        ? settings.colors.success
        : settings.colors.danger
    };
    // eslint-disable-next-line
  }, [activity]);

  const onClick = async () => {
    setIsSelect(true);
  };

  const mappedOptions = useMemo(() => {
    if (isArrayEmpty(shortcuts)) {
      return [...activities.activitiesOptions];
    } else {
      return [
        { label: 'Shortcuts', options: [...shortcuts] },
        { label: 'Default', options: [...activities.activitiesOptions] }
      ];
    }
  }, [activities, shortcuts]);

  const onChange = async selected => {
    const choosenActivity = await updatePresence({
      activities: Optional(activities.activities).or([]),
      activity: selected,
      userId,
      fromDropdown: true
    });

    if (isDefined(choosenActivity)) {
      setActivity(choosenActivity.activity);
      setActualAvailability(choosenActivity.activity.available);
    }

    setIsSelect(false);
  };

  return (
    <div>
      {isSelect && (
        <div className={classes.selectConatiner}>
          <Select
            options={mappedOptions}
            onChange={onChange}
            styles={reactSelectDefaultStyles}
            customStyles={customStyles}
            menuIsOpen={true}
            autoFocus={true}
            onBlur={() => setIsSelect(false)}
            placeholder={intl.formatMessage({
              id: 'EDIT_PRESENCE.SELECT_ACTIVITY'
            })}
          />
        </div>
      )}
      {!isSelect && (
        <div className={classes.root} style={rootStyles}>
          <div className={classes.presence}>
            <div className={classes.data}>{activity.name}</div>
            <Tooltip
              placement="bottom"
              title={intl.formatMessage({
                id: 'TOOLTIP.EDIT_PRESENCE'
              })}
            >
              <i className="fas fa-chevron-down" onClick={onClick}></i>
            </Tooltip>
          </div>
          <div className={classes.rightPreseneceRowMeuContainer}>
            {Optional(activity.expiration)
              .map(
                expiration =>
                  expiration !== 'never' &&
                  expiration !== '' && (
                    <Tooltip placement="bottom" title={expiration}>
                      <i
                        className="far fa-clock"
                        style={{ color: settings.colors.primary }}
                      ></i>
                    </Tooltip>
                  )
              )
              .or('')}
            {isArrayEmpty(shortcuts) ? (
              Object.keys(activity).length > 0 && (
                <ActivityExpiration
                  activity={activity}
                  activities={activities.activitiesOptions}
                  userId={userId}
                  setUpdatedActivity={setActivity}
                  setActualAvailability={setActualAvailability}
                >
                  <i
                    className={clsx('fas fa-ellipsis-h', classes.dotsMenuIcon)}
                    style={{ color: settings.colors.primary }}
                  ></i>
                </ActivityExpiration>
              )
            ) : (
              <PresenceMenu
                shortcuts={shortcuts}
                activity={activity}
                activities={activities.activitiesOptions}
                userId={userId}
                setUpdatedActivity={setActivity}
                setActualAvailability={setActualAvailability}
                setShortcuts={setShortcuts}
              >
                <i
                  className={clsx('fas fa-ellipsis-h', classes.dotsMenuIcon)}
                  style={{ color: settings.colors.primary }}
                ></i>
              </PresenceMenu>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStatesToProps = ({ user, auth }) => {
  return {
    updatedActivityId: user.updatedUserData.updatedActivityId,
    updatedActivityAttributes: user.updatedUserData.updatedActivityAttributes,
    userId: user.connect30_user_id,
    loggedUserId: auth.user.connect30_user_id
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserPresence);
