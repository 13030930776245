import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { Formik, Form } from 'formik';

import { actions as reduxActions } from './reducers';
import styles, { CUSTOM_STYLES } from './styles';
import InputRow from '../../partials/dataRows/InputRow';
import { resetPassRequest } from './actions/resetPass';
import validateSchema from './actions/validateSchema';
import { isUndefined } from '../../utils/isDefined';
import messageColors from '../../consts/messageColors';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../partials/customButtons/SecondaryButton';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';

export function ForgotPass({
  closeDialog,
  classes,
  open,
  intl,
  clientName,
  message,
  resetPassRequest
}) {
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'FORGET_PASS.TITLE'
      }),
    // eslint-disable-next-line
    [clientName, intl]
  );

  const isItemVisible = useMemo(
    () => isUndefined(message) || message !== 'success',
    [message]
  );

  const onSubmit = useCallback(
    values => {
      resetPassRequest(values.username);
    },
    // eslint-disable-next-line
    []
  );

  const customMessage = useMemo(
    () => createMessage(message, intl),
    // eslint-disable-next-line
    [message]
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeDialog}>{title}</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {intl.formatMessage({
          id: 'FORGET_PASS.TEXT'
        })}

        <Formik
          initialValues={{ username: '' }}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validateSchema(intl)}
        >
          {({ handleSubmit, values, handleBlur, handleChange, errors }) => {
            const isButtonDisabled = values.username.length === 0;

            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                {isItemVisible ? (
                  <>
                    <InputRow
                      labelText={`${intl.formatMessage({
                        id: 'TABLE.USERNAME'
                      })} / ${intl.formatMessage({
                        id: 'TELEPHONE'
                      })}`}
                      name="username"
                      values={values}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      mobile={true}
                      customStyles={CUSTOM_STYLES}
                    />
                    <div className={classes.infoText}>
                      (e.g. username@domain.tld // +4511223344 )
                    </div>
                  </>
                ) : null}
                <div
                  className={clsx(classes.messageContainer, {
                    [classes.messageVisible]:
                      Boolean(message) && values.username.length !== 0
                  })}
                  style={{ color: messageColors[message] }}
                >
                  {customMessage}
                </div>
                <footer className={classes.footer}>
                  {isItemVisible ? (
                    <PrimaryButton
                      onClick={handleSubmit}
                      disabled={isButtonDisabled}
                    >
                      {intl.formatMessage({
                        id: 'BUTTON.SUBMIT'
                      })}
                    </PrimaryButton>
                  ) : null}
                  <SecondaryButton onClick={closeDialog}>
                    {intl.formatMessage({
                      id: 'BUTTON.CLOSE'
                    })}
                  </SecondaryButton>
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ forgottenPass }) => {
  return {
    ...forgottenPass
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  resetPassRequest
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ForgotPass);

function createMessage(message, intl) {
  if (isUndefined(message)) {
    return null;
  }

  if (message === 'success') {
    return intl.formatMessage({
      id: 'FORGET_PASS.SUCCESS'
    });
  }

  if (message === 'error') {
    return intl.formatMessage({
      id: 'FORGET_PASS.ERROR'
    });
  }
}
