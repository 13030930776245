const styles = theme => ({
  root: {
    width: '400px',
    height: 'fit-content',
    margin: '5px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },
  titleContainer: { display: 'flex', alignItems: 'center' },
  titleActions: { display: 'flex', alignItems: 'center' },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: '#48465B',
    borderBottom: '1px solid #F2F3F8',
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  },
  dotsMenuIcon: {
    cursor: 'pointer',
    marginLeft: '5px'
  }
});

export default styles;