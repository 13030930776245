import { URLS } from '../../../consts/endpoints';
import { patchData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';

export default async function setNewPass(pass, token) {
  try {
    const response = await patchData({
      url: URLS.PASS,
      errorsToPass: [
        RESPONSE_CODES.UNPROCESSABLE_ENTITY,
        RESPONSE_CODES.BAD_REQUEST
      ],
      data: {
        user: {
          password: pass,
          reset_password_token: token
        }
      }
    });

    if (response.status === RESPONSE_CODES.NO_CONTENT) {
      return { intlText: 'RESET_PASS.SUCCESS', type: 'success' };
    }
  } catch (e) {
    const errorMessage = Optional(e.response.data?.error).or(
      e.response.data?.errors?.reset_password_token
    );

    return {
      text: Optional(errorMessage)
        .map(message => JSON.stringify(message))
        .or(null),
      intlText: 'RESET_PASS.ERROR',
      type: 'error'
    };
  }
}
