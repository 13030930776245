import { isDefined } from '../../../utils/isDefined';
import mapMitelPhoneNumbers from '../../../utils/mapMitelPhoneNumbers';
import Optional from '../../../utils/optional';

const FORWARD_ALL_KEY = 'CFWD_ALL';

export default function mapDiversions(diversions) {
  
  const mappedDiversions = mapMitelPhoneNumbers(diversions);

  const mappedDiversionsKeys = Object.keys(mappedDiversions);

  return mappedDiversionsKeys.reduce((obj, key) => {
    const firstNumberObject = mappedDiversions[key][0];
    const { lineNumber, userDiversionRules = [] } = firstNumberObject;

    const forwardAll = userDiversionRules.find(
      rule => rule.diversionRoutingRulesType === FORWARD_ALL_KEY
    );
    const forwardNumber = forwardAll.phoneNumber;

    return {
      ...obj,
      [key]: {
        lineNumber,
        isForwarded: isDefined(forwardNumber),
        forwardNumber: Optional(forwardNumber).or('')
      }
    };
  }, {});
}
