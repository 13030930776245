const styles = theme => {
  return {
    root: { width: '1000px', backgroundColor: '#FFFFFF' },
    content: {
      display: 'flex',
      overflow: 'visible',
      [theme.breakpoints.down(theme.breakpoints.values.md - 100)]: {
        flexDirection: 'column'
      }
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      padding: '20px 0',
      marginTop: '20px',
      '& button': {
        marginRight: '10px'
      }
    },
    dialogContent: {
      width: '900px',
      height: '560px',

      [theme.breakpoints.down(theme.breakpoints.values.md - 100)]: {
        width: '100%',
        flexDirection: 'column'
      }
    },
    userDialogContent: { height: 'fit-content' }
  };
};

export default styles;
