export default {
  root: {
    display: 'flex',
    margin: '10px 0'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #EBEDF2',
    padding: '10px',
    '& button': {
      margin: '10px'
    }
  }
};

export const customStyles = {
  input: {
    textfield: {
      width: '245px'
    },
    label: {
      paddingLeft: '20px'
    }
  },
  select: {
    value: {
      width: '245px'
    },
    label: {
      paddingLeft: '20px'
    }
  },
  checkbox: {
    root: {
      width: '400px'
    },
    label: {
      width: '260px'
    }
  }
};
