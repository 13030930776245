import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_SCHEDULE_ORDER } from './types';
import { updateData } from '../../../utils/http';
import { getSchedules } from './getSchedules';

export function setSchedulesOrder({ schedules, number, connectClientId }) {
  return {
    type: sagaActions[UPDATE_SCHEDULE_ORDER],
    schedules,
    number,
    connectClientId
  };
}

export function* updateSchedulesOrder({ schedules, number, connectClientId }) {
  yield call(updateData, {
    url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules/${number}`,
    data: { schedules }
  });

  yield put(getSchedules(connectClientId));
}

function* watchUpdateScheduleOrder() {
  yield takeLatest(sagaActions[UPDATE_SCHEDULE_ORDER], updateSchedulesOrder);
}

export default watchUpdateScheduleOrder();
