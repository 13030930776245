import { translate } from '../i18n/I18nProvider';
import { ExportCsv } from '@material-table/exporters';

export const SEARCH_FIELD_STYLE = {
  border: '1px solid #E2E5EC',
  borderRadius: '4px'
};

export const FILTER_CELL_STYLE = {
  padding: '16px'
};

export const FILTER_CELL_STYLE_RIGHT_ALIGNED = {
  textAlign: 'end'
};

const HEADER_STYLE = {
  fontWeight: 800,
  position: 'sticky',
  top: 0,
  zIndex: 1
};

export const TABLE_COLUMNS = {
  SIMCARDS_DETAILS: [
    { title: 'Pin 1', field: 'attributes.pin1' },
    { title: 'Pin 2', field: 'attributes.pin2' },
    { title: 'Pin 3', field: 'attributes.pin3' },
    { title: 'Puk 1', field: 'attributes.puk1' },
    { title: 'Puk 2', field: 'attributes.puk2' }
  ]
};

export const TABLE_OPTIONS = {
  DEFAULT: {
    columnsButton: true,
    showTitle: true,
    paginationType: 'normal',
    draggable: false,
    maxBodyHeight: 'calc(100vh - 350px)',
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 200, 1000],
    emptyRowsWhenPaging: false,
    toolbarButtonAlignment: 'right',
    searchFieldStyle: SEARCH_FIELD_STYLE,
    filterCellStyle: FILTER_CELL_STYLE,
    rowStyle: () => {
      return {
        fontSize: '14px',
        fontWeight: 500
      };
    },
    exportAllData: true,
    hideFilterIcons: true
  },
  SUBTABLE: {
    search: false,
    sorting: false,
    paging: false,
    toolbar: false,
    selection: false,
    padding: 'dense',
    rowStyle: (rowData, i) => {
      return {
        backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
      };
    }
  },
  QUEUES: {
    showTitle: true,
    draggable: false,
    padding: 'default',
    maxBodyHeight: 'calc(100vh - 350px)',
    emptyRowsWhenPaging: false,
    selection: false,
    pageSizeOptions: [],
    paging: false,
    searchFieldStyle: SEARCH_FIELD_STYLE,
    rowStyle: () => {
      return {
        fontSize: '14px',
        fontWeight: 500
      };
    },
    headerStyle: HEADER_STYLE
  },
  HISTORY: {
    showTitle: true,
    draggable: false,
    padding: 'dense',
    emptyRowsWhenPaging: false,
    search: false,
    maxColumnSort: 0,
    paging: false,
    toolbar: false,
    selection: false,
    searchFieldStyle: SEARCH_FIELD_STYLE,
    rowStyle: () => {
      return {
        height: '40px'
      };
    },
    headerStyle: HEADER_STYLE
  }
};

export function setLocalization() {
  return {
    pagination: {
      labelRowsSelect: translate('TABLE.ROWS'),
      firstTooltip: translate('TABLE.FIRTS_PAGE'),
      previousTooltip: translate('TABLE.PREVIOUS_PAGE'),
      nextTooltip: translate('TABLE.NEXT_PAGE'),
      lastTooltip: translate('TABLE.LAST_PAGE')
    },
    toolbar: {
      exportCSVName: `${translate('TABLE.EXPORT_AS')} CSV`,
      exportPDFName: `${translate('TABLE.EXPORT_AS')} PDF`,
      searchPlaceholder: translate('TABLE.SEARCH'),
      searchTooltip: translate('TABLE.SEARCH'),
      showColumnsTitle: translate('TABLE.SHOW_COLUMNS'),
      addRemoveColumns: translate('TABLE.ADD_REMOVE_COLUMNS'),
      nRowsSelected: `{0} ${translate('TABLE.ROWS_SELECTED')}`
    },
    body: {
      bulkEditApprove: translate('BUTTON.SAVE'),
      bulkEditCancel: translate('BUTTON.CANCEL')
    },
    header: {
      actions: translate('TABLE.ACTIONS')
    }
  };
}

export function mapTableOptions({
  clientName = '',
  tableName = 'table',
  exportData = true
}) {
  let options = {
    exportAllData: true,
    columnsButton: true,
    showTitle: true,
    paginationType: 'normal',
    draggable: false,
    maxBodyHeight: 'calc(100vh - 350px)',
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 200, 1000],
    emptyRowsWhenPaging: false,
    toolbarButtonAlignment: 'right',
    searchFieldStyle: SEARCH_FIELD_STYLE,
    filterCellStyle: FILTER_CELL_STYLE,
    hideFilterIcons: true,
    rowStyle: () => {
      return {
        fontSize: '14px',
        fontWeight: 500
      };
    },
    headerStyle: HEADER_STYLE
  };

  if (exportData) {
    options = {
      ...options,
      exportMenu: [
        {
          label: `${translate('TABLE.EXPORT_AS')} CSV`,
          exportFunc: (cols, datas) =>
            ExportCsv(cols, datas, `${clientName}-${tableName}`, ';')
        }
      ]
    };
  }

  return options;
}
