import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { translate } from '../../i18n/I18nProvider';
import { toast } from 'react-toastify';
import styles from './styles';
import { isStringEmpty, isUndefined } from '../../utils/isDefined';
import { mapDanishNumber } from '../copyToClipboard.js/CustomCopyToClipboard';
import { mapFilterValue } from '../../utils/tableUtils';

const CopyToClipbaordTooltip = ({
  value,
  children,
  classes,
  key,
  isPhoneNumber = false
}) => {
  if (isUndefined(value) || isStringEmpty(value)) {
    return <div>{children}</div>;
  }

  const text = isPhoneNumber ? mapDanishNumber(value) : mapFilterValue(value);

  const handleTooltipClick = event => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(translate('MESSAGE.COPIED_TO_CLIPBOARD'), {
          autoClose: 1000
        });
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div key={key}>
      <Tooltip
        style={{ cursor: 'pointer' }}
        title={
          <span onClick={handleTooltipClick} style={{ cursor: 'pointer' }}>
            <Tooltip title={translate('TOOLTIP.COPY_TO_CLIPBOARD')}>
              <i className="fa fa-clone" aria-hidden="true"></i>
            </Tooltip>
          </span>
        }
        classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}
        placement="right"
        interactive={true}
      >
        <span>{children}</span>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(CopyToClipbaordTooltip);
