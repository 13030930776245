import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_QUEUE_AGENTS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import createInitials from '../../../utils/createUserInitials';

export function getQueueAgents(id, userMitelId) {
  return {
    type: sagaActions[GET_QUEUE_AGENTS],
    id,
    userMitelId
  };
}

export function* fetchQueueAgents({ id, userMitelId }) {
  yield put(loaderReducActions.startSpinLoader());
  const QUERY_PARAMS = {
    mitel_user_id: userMitelId
  };
  const queueAgents = yield call(
    getData,
    urlCreator(`${OUTSIDE_API.QUEUES}/${id}/agents`, QUERY_PARAMS)
  );

  const mappedAgents = yield call(mapAgents, queueAgents.agents);

  yield put(
    reduxActions.setQueueAgents({ ...queueAgents, agents: mappedAgents })
  );
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetQueueAgents() {
  yield takeLatest(sagaActions[GET_QUEUE_AGENTS], fetchQueueAgents);
}

export default watchGetQueueAgents();

function mapAgents(agents = []) {
  return agents.map(agent => {
    const { firstName = '', lastName = '' } = agent;
    const initials = createInitials(firstName, lastName);
    return { ...agent, initials };
  });
}
