import React, { useState, cloneElement, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  withStyles
} from '@material-ui/core';
import QRCode from 'qrcode';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';

import styles from './styles';
import CustomDialogTitle from '../customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../DraggableDialog';
import { translate } from '../../i18n/I18nProvider';
import PrimaryButton from '../customButtons/PrimaryButton';
import SecondaryButton from '../customButtons/SecondaryButton';
import { isDefined } from '../../utils/isDefined';
import createPdf from './createPdf';
import withSettings from '../../utils/withSettings';
import getEsimStatus from './actions/getEsimStatus';
import Optional from '../../utils/optional';
import { findESimStatus } from '../../consts/initialValues';
import RefreshButton from '../tableCustomButtons/RefreshButton';

const ShowQrCode = ({
  classes,
  children,
  number,
  activationCode,
  settings
}) => {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState('');
  const [status, setStatus] = useState(void 0);

  useEffect(() => {
    (async () => {
      const statusResponse = await getEsimStatus(number);
      setStatus(statusResponse);
    })();
  }, [number]);

  useEffect(() => {
    if (isDefined(activationCode)) {
      QRCode.toDataURL(activationCode).then(data => {
        setSrc(data);
      });
    }
  }, [activationCode]);

  const shouldShowCode = useMemo(() => {
    return status === 'CONFIRMED' || status === 'RELEASED';
  }, [status]);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const onCreatePdfClick = () => {
    return createPdf({ number, src });
  };

  const onRefreshClick = async () => {
    const statusResponse = await getEsimStatus(number);
    setStatus(statusResponse);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="md"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          <div className={classes.title}>
            {translate('SWAP_SIMCARD.QR_CODE')}
            <RefreshButton onClick={onRefreshClick} />
          </div>
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.row}>
            {`${translate('SWAP_SIMCARD.ESIM_SUMMARY_2')} ${number}`}
          </div>
          <div className={classes.row}>
            {translate('SWAP_SIMCARD.ESIM_SUMMARY_3')}
          </div>
          <div className={clsx(classes.row, classes.dialogContent)}>
            <div>{translate('SWAP_SIMCARD.ESIM_SUMMARY_4')} </div>
            <div>{translate('SWAP_SIMCARD.ESIM_SUMMARY_5')}</div>
          </div>
          <div className={clsx(classes.row, classes.bold)}>
            {translate('SWAP_SIMCARD.ESIM_SUMMARY_7')}
          </div>
          {isDefined(status) && (
            <div className={classes.boldRow}>{`${translate(
              'CURRENT'
            )} ${translate('ESIM_STATUS')} : ${Optional(
              findESimStatus(status)
            ).or('')}`}</div>
          )}
          {shouldShowCode && (
            <div className={classes.qrCodeContainer}>
              <img src={src} alt="icon" className={classes.icon}></img>
              <div
                className={classes.row}
                style={{ color: settings.colors.modal_header }}
              >
                {`${translate('SWAP_SIMCARD.ESIM_SUMMARY_6')} ${number}`}
              </div>
            </div>
          )}
          <div className={classes.infoRow}>
            {translate('SWAP_SIMCARD.ESIM_5')}
          </div>
        </DialogContent>

        <DialogActions className={classes.footer}>
          {shouldShowCode && (
            <PrimaryButton onClick={onCreatePdfClick}>
              {translate('SWAP_SIMCARD.SAVE_PDF')}
            </PrimaryButton>
          )}

          <SecondaryButton onClick={onCloseDialog}>
            {translate('BUTTON.CLOSE')}
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default flowRight(withSettings, withStyles(styles))(ShowQrCode);
