import { withStyles } from '@material-ui/styles';
import React from 'react';

import styles from '../styles/scheduleDetails';

function DetailCell({ data, expired, classes }) {
  return (
    <div className={classes.cellRoot}>
      {data}
      {expired && <div className={classes.crossLine}></div>}
    </div>
  );
}

export default withStyles(styles)(DetailCell);
