import React, { useState, cloneElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { translate } from '../../../i18n/I18nProvider';
import { actions as activationMessageDialogReduxActions } from '../../../partials/activationMessageDialog/reducers';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import DeletePhonebookStatusConfimation from './DeletePhonebookStatusConfimation';

const MoreActionsMenu = ({ children, onDeleteClick, client,rangeId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {(isUndefined(client) ||
          (isDefined(client) &&
            can(
              'delete',
              createSubject('PhoneBookRegistration', {
                external_number_range_id: rangeId
              })
            ))) && (
          <div>
            <ConfirmDialog
              closeMenu={handleClose}
              onConfirmClick={onDeleteClick}
              dialogContentComponent={<DeletePhonebookStatusConfimation />}
              dialogTitleIntlId={'TOOLTIP.DELETE_PHONEBOOK'}
            >
              <MenuItem>{translate('TOOLTIP.DELETE_PHONEBOOK')}</MenuItem>
            </ConfirmDialog>
          </div>
        )}
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openActivationMessageDialog: activationMessageDialogReduxActions.openDialog
};

export default flowRight(
  withUserCheck,
  connect(null, mapDispatchToProps)
)(MoreActionsMenu);
