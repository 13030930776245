import { isArray, isArrayEmpty, isDefined } from '../../../utils/isDefined';

export function mapSubscriptions({ tableRef, expandAll, subscriptions = {} }) {
  const { groupedByCategory = [], groupedBySku = [] } = subscriptions;

  const panel = expandAll ? tableRef.current.props.detailPanel[0]().render : '';

  return {
    groupedByCategory: groupedByCategory.map(el => {
      return {
        ...el,
        tableData: isDefined(el.tableData)
          ? { ...el.tableData, showDetailPanel: panel }
          : { showDetailPanel: panel }
      };
    }),
    groupedBySku: groupedBySku.map(el => {
      return {
        ...el,
        tableData: isDefined(el.tableData)
          ? { ...el.tableData, showDetailPanel: panel }
          : { showDetailPanel: panel }
      };
    })
  };
}

export const filterSubs = ({ filterBy, subscriptions }) => {
  return {
    groupedByCategory: filterByStatus({
      data: subscriptions.groupedByCategory,
      filterBy
    }),
    groupedBySku: filterByStatus({
      data: subscriptions.groupedBySku,
      filterBy
    })
  };
};

const filterByStatus = ({ data, filterBy }) => {
  const reducedData = data.reduce((arr, el) => {
    const filtered = el.subs.filter(sub => sub.status === filterBy);

    if (isArrayEmpty(filtered)) {
      return arr;
    }

    return [...arr, { ...el, subs: filtered }];
  }, []);

  return reducedData.map(el => {
    return { ...el, quantity: isArray(el.subs) ? el.subs.length : el.quantity };
  });
};
