import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_USER = 'GET_USER';
export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';
export const LOCK_USER = 'LOCK_USER';
export const UPDATE_DIVERSIONS = 'UPDATE_DIVERSIONS';

export const actions = createSagaActionTypes('USER', [
  GET_USER,
  SET_SELECTED_LINE,
  LOCK_USER,
  UPDATE_DIVERSIONS
]);
