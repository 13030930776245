import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import Optional from '../utils/optional';
import clsx from 'clsx';

const styles = {
  root: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #EEF1FF'
  },
  notHashed: {
    cursor: 'default'
  }
};

export function HashedData({
  data,
  classes,
  style,
  dataClassName,
  intl,
  shouldDataBeHashed = true,
  altTooltip
}) {
  const [hashed, setHashed] = useState(shouldDataBeHashed);
  const customClass = Optional(dataClassName).or(
    clsx(classes.root, { [classes.notHashed]: !shouldDataBeHashed })
  );

  return (
    <Tooltip
      title={
        shouldDataBeHashed
          ? hashed
            ? intl.formatMessage({
                id: 'TOOLTIP.CLICK_TO_SEE'
              })
            : intl.formatMessage({
                id: 'TOOLTIP.CLICK_TO_HIDE'
              })
          : Optional(altTooltip).or('')
      }
    >
      <div
        style={style}
        className={customClass}
        onClick={() => (shouldDataBeHashed ? setHashed(!hashed) : () => {})}
      >
        {!hashed ? data : '#'.repeat(data.length)}
      </div>
    </Tooltip>
  );
}

export default flowRight(injectIntl, withStyles(styles))(HashedData);
