const styles = {
  titleClientName: {
    fontSize: '18px',
    fontWeight: 500
  },
  actionButtons: {
    display: 'flex'
  }
};

export default styles;
