const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      paddingBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 20px)',
        justifyContent: 'space-between',
        paddingRight: '5px'
      }
    },
    label: {
      width: '130px',
      paddingLeft: '10px'
    },
    fieldValue: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '11px',
      fontWeight: 'normal',
      borderRadius: '4px',
      width: '250px',
      margin: 0,
      '& p': {
        margin: '2px 0'
      }
    },
    textfieldRoot: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px'
      },
      '&.MuiInputBase-input.Mui-disabled': {
        color: 'red'
      }
    }
  };
};

export default styles;
