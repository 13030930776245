const styles = theme => ({
  root: {
    position: 'relative',
    minWidth: '400px',
    height: 'fit-content',
    margin: '5px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      minWidth: 'unset',
      margin: '5px 0'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  contentContainer: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '10px 0',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#000',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px'
  },
  titleActions: { display: 'flex', alignItems: 'center' },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E',
    borderBottom: '1px solid #F2F3F8',
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  },
  dataRow_root: {
    paddingRight: '30px',
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '10px',
    }
  },
  dataRow_value: {
    width: 'fit-content',
    fontWeight: 600
  },
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    background: '#FFF',
    opacity: '.5',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default styles;
