export default {
  actionButtons: {
    display: 'flex',
    '& i': {
      margin: '5px 10px',
      cursor: 'pointer'
    }
  },
  actionCell: { padding: '0 5px', color: '#93A2DD' },
  dialogTextContainer: {
    display: 'flex',
    margin: '20px',
    justifyContent: 'space-around'
  }
};
