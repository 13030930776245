import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import AppVersion from '../../partials/appVersion/AppVersion';
import withSettings from '../../utils/withSettings';
import { connect } from 'react-redux';
import Optional from '../../utils/optional';
import { isString, isStringDefined } from '../../utils/isDefined';

function AuthPage({ settings, intl, appLTitle }) {
  const year = moment().format('YYYY');

  return (
    <>
      <div
        className="kt-grid kt-grid--ver kt-grid--root"
        style={{
          backgroundColor: settings.colors.login_left
        }}
      >
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
            style={{
              backgroundColor: settings.colors.login_right
            }}
          >
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundColor: settings.colors.login_left
              }}
            >
              <div
                className="kt-grid__item"
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img
                  className="kt-login__logo"
                  alt="Logo"
                  src={settings.logos.big}
                />
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3
                    className="kt-login__title "
                    style={{ color: settings.colors.login_left_text }}
                  >
                    {`${intl.formatMessage({
                      id: 'AUTH.WELCOME_TEXT'
                    })} ${appLTitle}`}{' '}
                  </h3>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div
                    className="kt-login__copyright"
                    style={{ color: settings.colors.login_left_text }}
                  >
                    &copy; {year}
                  </div>
                  <AppVersion
                    customStyles={{ color: settings.colors.login_left_text }}
                  />
                </div>
              </div>
            </div>

            <div
              className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
              style={{
                backgroundColor: settings.colors.login_right
              }}
            >
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStatesToProps = ({ builder }) => ({
  appLTitle: Optional(builder.settings?.title)
    .map(title =>
      isString(title) && isStringDefined(title.trim()) ? title : 'Selfcare'
    )
    .or('Selfcare')
});

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps)
)(AuthPage);
