import React from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent} from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import Queue from './components/Queue';
import DraggableDialog from '../../partials/DraggableDialog';

export function QueueStats({ classes, open, intl, resetQueueStats, queue }) {
  return (
    <Dialog
      maxWidth="sm"
      PaperComponent={DraggableDialog}
      classes={{
        paper: classes.content
      }}
      open={open}
      onClose={resetQueueStats}
    >
      <CustomDialogTitle
        onCloseClick={resetQueueStats}
        
      >
        {intl.formatMessage({
          id: 'QUEUES.STATS'
        })}
      </CustomDialogTitle>
      <DialogContent>
        <Queue />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ queueStats }) => {
  return {
    ...queueStats
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(QueueStats);
