export default {
  row: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    width: '100%'
  },
  elementRow: { display: 'flex', alignItems: 'center', width: '280px' },
  element: {
    margin: '5px'
  },
  bigElement: { width: '280px' },
  smallElement: { width: '150px' },
  status: { justifyContent: 'center' }
};
