import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { STATUSES_ICONS } from '../../../consts/initialValues';

import { isUndefined } from '../../../utils/isDefined';
import styles from '../styles/contactCard';
import StatusMenu from './StatusMenu';

function StatusIcon({ contact, intl, classes }) {
  const { contactStatus } = contact;

  if (isUndefined(contactStatus)) {
    return null;
  }

  return (
    <StatusMenu contact={contact}>
      <Tooltip
        title={intl.formatMessage({
          id: 'TOOLTIP.CHANGE_STATUS'
        })}
      >
        <i className={clsx(STATUSES_ICONS[contactStatus], classes.icon)}></i>
      </Tooltip>
    </StatusMenu>
  );
}

export default flowRight(injectIntl, withStyles(styles))(StatusIcon);
