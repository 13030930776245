import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function createClientsLookup(users = []) {
  return {
    ...getAllClientsNames(users).reduce((obj, client) => {
      return { ...obj, [client]: client };
    }, {}),
    '': 'none'
  };
}

function getAllClientsNames(users = []) {
  return [
    ...new Set(
      users
        .map(user => {
          return Optional(user.client)
            .map(client => client.name)
            .or(void 0);
        })
        .filter(name => isDefined(name))
        .sort()
    )
  ];
}
