import React from 'react';
import { TableRow, TableCell, TableHead } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/scheduleDetails';
import AddSchedule from './AddSchedule';
import { translate } from '../../../i18n/I18nProvider';

const ScheduleDetailHeader = ({ group, classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableDragColumn}></TableCell>
        <TableCell className={classes.tableActions}>
          <AddSchedule group={group} />
        </TableCell>
        <TableCell>{translate('NAME')}</TableCell>
        <TableCell>{translate('SCHEDULES.DURATION')}</TableCell>
        <TableCell>{translate('SCHEDULES.ACTION')}</TableCell>
        <TableCell>{translate('SCHEDULES.PROMPT')}</TableCell>
        <TableCell className={classes.voicePrompt}> </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(ScheduleDetailHeader);
