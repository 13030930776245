import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateSummaryInfo } from './cancelSubscription';
import { translate } from '../../../i18n/I18nProvider';
import moment from 'moment';
import Optional from '../../../utils/optional';

const changeSubscription = async ({
  clientId,
  subscription,
  isMultiedit,
  selectedRows,
  setIsLoading,
  newProduct
}) => {
  setIsLoading(true);
  const mappedDate = moment()
    .add(1, 'months')
    .format('YYYY-MM');

  if (!isMultiedit) {
    let summaryInfo = {};
    const { name, externalReference, skuTranslations } = subscription;
    try {
      const response = await postData({
        url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${externalReference}/change`,
        passErrors: true,
        data: {
          change_date: `${mappedDate}-01`,
          new_sku: newProduct.value
        }
      });

      if (response?.status === RESPONSE_CODES.SUCCESS) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name,
          fromChange: true,
          changeInfo: {
            from: Optional(skuTranslations?.default).or(''),
            to: Optional(newProduct?.name).or('')
          },
          newElement: {
            isError: false
          }
        });
      }
    } catch (e) {
      summaryInfo = updateSummaryInfo({
        summaryInfo,
        name,
        fromChange: true,
        changeInfo: {
          from: Optional(skuTranslations?.default).or(''),
          to: Optional(newProduct?.name).or('')
        },
        newElement: {
          text: translate('MESSAGE.CREATE_ERROR', {
            name
          }),
          isError: true,
          errors: Optional(e.response?.data).or([])
        }
      });
    } finally {
      setIsLoading(false);
      return { requestCompleted: true, summaryInfo };
    }
  } else {
    let summaryInfo = {};

    for (const row of selectedRows) {
      const { name: elName, externalReference: extRef, skuTranslations } = row;

      if (newProduct.value === row.sku) {
        summaryInfo = updateSummaryInfo({
          summaryInfo,
          name: elName,
          fromChange: true,
          changeInfo: {
            from: Optional(skuTranslations?.default).or(''),
            to: Optional(newProduct?.name).or('')
          },
          newElement: {
            text: 'Same product - no change',
            isError: false
          }
        });
      } else {
        try {
          const response = await postData({
            url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${extRef}/change`,
            passErrors: true,
            data: {
              change_date: `${mappedDate}-01`,
              new_sku: newProduct.value
            }
          });

          if (response?.status === RESPONSE_CODES.SUCCESS) {
            summaryInfo = updateSummaryInfo({
              summaryInfo,
              name: elName,
              fromChange: true,
              changeInfo: {
                from: Optional(skuTranslations?.default).or(''),
                to: Optional(newProduct?.name).or('')
              },
              newElement: {
                isError: false
              }
            });
          }
        } catch (e) {
          summaryInfo = updateSummaryInfo({
            summaryInfo,
            name: elName,
            fromChange: true,
            changeInfo: {
              from: Optional(skuTranslations?.default).or(''),
              to: Optional(newProduct?.name).or('')
            },
            newElement: {
              text: translate('MESSAGE.CREATE_ERROR', {
                elName
              }),
              isError: true,
              errors: Optional(e.response?.data).or([])
            }
          });
        }
      }
    }

    setIsLoading(false);
    return { requestCompleted: true, summaryInfo };
  }
};

export default changeSubscription;
