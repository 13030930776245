const styles = theme => {
  return {
    passwordContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      margin: '20px',
      '& input': { margin: '0 10px' },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center'
      }
    },

    passRowContainer: {
      display: 'inherit',
      alignItems: 'center',
      '& i': {
        fontSize: '14px',
        color: '#BABCC7',
        [theme.breakpoints.down('xs')]: {
          margin: '0 5px 0'
        }
      }
    },

    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      padding: '20px 0',
      '& button': {
        marginRight: '10px'
      }
    },
    isNotDialog: { border: 'unset' },
    strengthMeterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '170px',
      width: '270px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0'
      }
    },

    strengthMeter: {
      position: 'relative',
      height: '3px',
      background: '#DDD',
      margin: '3px 0',
      borderRadius: '2px',
      width: '200px'
    },

    strengthMeterFill: {
      background: 'transparent',
      height: 'inherit',
      position: 'absolute',
      width: 0,
      borderRadius: 'inherit',
      transition: 'width 0.5s ease-in-out',
      zIndex: 10
    },

    strengthMeterFillStrength0: { width: '20%', background: 'red' },
    strengthMeterFillStrength1: { width: '40%', background: 'orangered' },
    strengthMeterFillStrength2: { width: '60%', background: 'orange' },
    strengthMeterFillStrength3: { width: '80%', background: 'yellowgreen' },
    strengthMeterFillStrength4: { width: '100%', background: 'green' },

    invisible: {
      visibility: 'hidden'
    },

    passStrengthText: {
      margin: '1px 0',
      fontSize: '10px',
      width: '70px',
      textAlign: 'center'
    }
  };
};

export default styles;
