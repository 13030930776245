import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import Optional from '../../utils/optional';
import updateValue from './actions/updateValue';
import { isDefined } from '../../utils/isDefined';

function EditableTextField({
  isDisabled = false,
  classes,
  intl,
  value = '',
  label = '',
  tooltipIntlId = 'EMPTY',
  errorIntlId = 'EMPTY',
  fieldName,
  id,
  type,
  urlKey
}) {
  const [editMode, setEditMode] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(value);
  const [changeResult, setChangeResult] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setTextFieldValue(value);
  }, [value]);

  const handleChange = event => {
    setTextFieldValue(event.target.value);
  };

  const handleConfirm = async () => {
    const { value: result, error } = await updateValue({
      value: isDefined(textFieldValue) ? textFieldValue.trim() : null,
      label: fieldName,
      id,
      type,
      urlKey,
      errorIntlId
    });

    if (isDefined(error)) {
      setIsError(true);
    } else {
      setIsError(false);
      setChangeResult(result);
      setEditMode(false);
    }
  };

  const handleClose = () => {
    setIsError(false);
    setEditMode(false);
    setTextFieldValue(Optional(changeResult).or(value));
  };

  const handleClick = () => {
    setEditMode(true);
  };

  return (
    <div className={clsx(classes.container, classes.textFieldContainer)}>
      <div className={classes.label}>
        <div>{label}</div>
        <Tooltip
          placement="bottom"
          title={intl.formatMessage({
            id: tooltipIntlId
          })}
        >
          <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
        </Tooltip>
      </div>
      <TextField
        error={isError}
        name="textfield"
        value={Optional(textFieldValue).or('')}
        autoFocus={editMode}
        onChange={handleChange}
        disabled={!editMode || isDisabled}
        className={classes.textField}
        InputProps={{
          classes: {
            disabled: classes.disabled,
            input: classes.textFieldInput
          },
          startAdornment: !isDisabled ? (
            !editMode ? (
              <i className="fas fa-edit" onClick={handleClick}></i>
            ) : (
              <>
                <i className="fas fa-check" onClick={handleConfirm}></i>
                <i className="fas fa-times" onClick={handleClose}></i>
              </>
            )
          ) : null
        }}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(EditableTextField);
