import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAccounts } from './actions/getAccounts';
import SelectAccount from './components/SelectAccount';
import SubscriptionsTable from './components/SubscriptionsTable';

import { actions as reduxActions } from './reducers';
import styles from './styles';

function Subscriptions({
  clientId,
  fetchAccounts,
  resetSubscriptions,
  classes
}) {
  useEffect(() => {
    fetchAccounts(clientId);
    return resetSubscriptions;
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <>
      <div className={classes.root}>
        <SelectAccount />
      </div>
      <SubscriptionsTable />
    </>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  resetSubscriptions: reduxActions.resetSubscriptions,
  fetchAccounts
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Subscriptions);
