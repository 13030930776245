import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_ADDRESSES } from './types';
import { actions as reduxActions } from '../reducers/addressesReducer';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import getFullTableData from '../../../utils/getFullTableData';

export function getAllAddresses(id) {
  return {
    type: sagaActions[GET_ADDRESSES],
    id
  };
}

export function* getAddresses({ id }) {
  yield put(loaderReducActions.startSpinLoader());
  const QUERY_PARAMS = {
    filter: { client_id: { eq: id } },
    include: ['external_number_ranges', 'external_number_ranges.provider','phone_book_registrations'],
    fields: { 'external_number_ranges.provider': ['name', 'sane_name'] }
  };

  const data = yield call(
    getFullTableData,
    JSONAPI_URLS.ADDRESSES,
    QUERY_PARAMS
  );

  const addresses = yield call(mapAddresses, data);

  yield put(reduxActions.setAddresses(addresses));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetAddresses() {
  yield takeLatest(sagaActions[GET_ADDRESSES], getAddresses);
}

export default watchGetAddresses();

export function mapAddresses(addresses) {
  return addresses.map(address => {
    const { street, house_number, stairway, floor, sidedoor } = address;

    const addressArray = [street, house_number, stairway, floor, sidedoor];

    const fullAddress = addressArray.reduce((text, item) => {
      return isDefined(item) ? `${text} ${item}` : text;
    }, '');

    return { ...address, fullAddress };
  });
}
