import * as yup from 'yup';
import checkICC from '../../../utils/checkICC';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { translate } from '../../../i18n/I18nProvider';
import checkAvailability from '../../../utils/checkAvailability';

export default function(numberPrefix) {
  return yup.object().shape({
    new_sim_card_icc: yup
      .string()
      .matches(/^[0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
      .min(
        13,
        `${translate('VALIDATION.TOO_SHORT_NUMBER')}
        ${translate('VALIDATION.DIGITS_LENGTH')} 19-20`
      )
      .max(
        14,
        `${translate('VALIDATION.TOO_LONG_NUMBER')}
        ${translate('VALIDATION.DIGITS_LENGTH')} 19-20`
      )
      .test(null, 'number is not valid', async function(value) {
        const { status, data } = await checkICC(`${numberPrefix}${value}`);
        return [RESPONSE_CODES.SUCCESS].findIndex(code => code === status) !==
          -1
          ? data.Valid && data.Available
            ? true
            : await checkAvailability({
                data,
                createError: this.createError,
                icc: value
              })
          : true;
      })
  });
}
