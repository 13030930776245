import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, LOGOUT } from './types';
import { actions as reduxActions } from '../reducers/index';
import { actions as builderReduxActions } from '../../../../_metronic/ducks/builder';
import { actions as queuesReduxActions } from '../../queues/reducers';
import { actions as selectClientReduxActions } from '../../../partials/selectClient/reducers';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { forgotLastLocation } from '../../../router/RouterHelpers';
import { forgotSearchHistory } from '../../../utils/localstorageUtils';

export function logoutUser() {
  return {
    type: sagaActions[LOGOUT]
  };
}

export function* logout() {
  yield call(forgotSearchHistory)
  yield put(builderReduxActions.resetBuilder());
  yield put(reduxActions.resetUser());
  yield put(queuesReduxActions.resetTime());
  yield put(selectClientReduxActions.resetSelectClient());
  
  yield call(forgotLastLocation);

  yield call(axios.delete, JSONAPI_URLS.LOGOUT);
}

function* watchLogout() {
  yield takeLatest(sagaActions[LOGOUT], logout);
}

export default watchLogout();
