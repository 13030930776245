import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import { actions as showDatalimitReduxActions } from '../../../modals/showDatalimit/reducers';
import { actions as datalimitLogsReduxActions } from '../../../modals/datalimitLogs/reducers';

const MoreActionsMenu = ({
  children,
  dataLimit = {},
  openShowHistory,
  openShowDatalimit,
  openDatalimitLogs
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: dataLimit.id,
        itemType: 'DataLimit',
        titleText: dataLimit.name,
        historyObject: dataLimit,
        itemTypeKey: 'DATA_LIMITS.DATALIMIT'
      });
      handleClose();
    },
    // eslint-disable-next-line
    [dataLimit.id]
  );

  const onShowClick = useCallback(
    () => {
      openShowDatalimit(dataLimit.id, dataLimit.name);
      handleClose();
    },
    // eslint-disable-next-line
    [dataLimit.id, dataLimit.name]
  );

  const onLogsClick = useCallback(
    () => {
      openDatalimitLogs(dataLimit?.id);
      handleClose();
    },
    // eslint-disable-next-line
    [dataLimit.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onHistoryClick}>
          {translate('TOOLTIP.SHOW_HISTORY')}
        </MenuItem>
        <MenuItem onClick={onShowClick}>
          {translate('DATA_LIMITS.SHOW_DATALIMIT')}
        </MenuItem>
        <MenuItem onClick={onLogsClick}>
          {translate('DATA_LIMITS.SHOW_DATALIMIT_LOGS')}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openShowHistory: showHistoryReduxActions.openShowHistory,
  openShowDatalimit: showDatalimitReduxActions.openShowDatalimit,
  openDatalimitLogs: datalimitLogsReduxActions.openDatalimitLogs
};

export default flowRight(
  withUserCheck,
  connect(null, mapDispatchToProps)
)(MoreActionsMenu);
