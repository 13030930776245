import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_CONTACT_DATA } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';

export function getAdditionalData({
  contactId,
  setData,
  setMoreInfo,
  baseUrl,
  clientDomain,
  mitelToken
}) {
  return {
    type: sagaActions[GET_CONTACT_DATA],
    contactId,
    setData,
    setMoreInfo,
    baseUrl,
    clientDomain,
    mitelToken
  };
}

export function* getContactData({
  contactId,
  setData,
  baseUrl,
  clientDomain,
  mitelToken
}) {
  yield put(loaderReducActions.startSpinLoader());

  try {
    const { data } = yield call(getData, {
      options: {
        url: `${baseUrl}/api/contacts/v1/info/${clientDomain}/${contactId}/vcard?t=${mitelToken}`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });
    const mappedData = yield call(mapVCardData, data);
    yield call(setData, mappedData);
  } catch (error) {}
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetContactData() {
  yield takeLatest(sagaActions[GET_CONTACT_DATA], getContactData);
}

export default watchGetContactData();

function mapVCardData(data) {
  const { Description = {} } = data;
  const email = Optional(Description.EMAIL?.value).or('');
  const department = Optional(Description.ORG?.Orgunit).or('');
  const fields = Optional(Description.field).or([]);
  const phones = Optional(Description.TEL)
    .map(tel => mapPhones(tel))
    .or([]);
  const address = Optional(Description.ADR)
    .map(adr => mapAddress(adr))
    .or(void 0);
  return { email, department, fields, phones, address };
}

function mapPhones(phones = []) {
  return phones.map(phone => {
    const { value, type = [] } = phone;
    let numberType = void 0;
    let isShort = false;
    const types = type.map(type => {
      return type.resource.split('#')[1];
    });

    if (isDefined(types.find(type => type.toUpperCase() === 'WORK'))) {
      numberType = 'WORK';
    }

    if (isDefined(types.find(type => type.toUpperCase() === 'HOME'))) {
      numberType = 'HOME';
    }

    if (isDefined(types.find(type => type.toUpperCase() === 'SHORTNUMBER'))) {
      isShort = true;
    }
    return { value, numberType, isShort };
  });
}

function mapAddress(address = []) {
  return `${address.Street}, ${address.Pcode} ${address.Locality}`;
}
