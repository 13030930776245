const styles = theme => {
  return {
    actionCell: {
      display: 'flex'
    },
    toolbarHighlight: {
      backgroundColor: '#E5EAEE',
      color: '#03284F'
    },
    actionIcon: {
      margin: '5px 10px',
      cursor: 'pointer',
      fontSize: '18px',
      '&:hover': { filter: 'brightness(1.5)' }
    }
  };
};

export default styles;
