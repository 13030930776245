import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import ConfirmDialog from '../confirmationDialog/ConfirmDialog';
import PrimaryButton from '../customButtons/PrimaryButton';
import { isUndefined } from '../../utils/isDefined';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import deletePhoto from './actions/deletePhoto';
import updatePhoto from './actions/updatePhoto';
import styles from './styles';
import Photo from './components/Photo';
import clsx from 'clsx';

function ChangePhoto({
  classes,
  settings,
  userConnectId,
  clientDomain,
  baseUrl,
  mitelToken,
  intl,
  fromDialog = false,
  dialogPhoto,
  dialogConnectId
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showTempPhoto, setShowTempPhoto] = useState(false);

  const onFileChange = event => {
    setSelectedFile({
      file: event.target.files[0],
      tempUrl: URL.createObjectURL(event.target.files[0])
    });
  };

  const onFileUpload = async () => {
    await updatePhoto({
      selectedFile: selectedFile?.file,
      clientDomain,
      baseUrl,
      mitelToken,
      userConnectId: fromDialog ? dialogConnectId : userConnectId,
      resetChangePhoto
    });
  };

  const onDelete = async () => {
    await deletePhoto({
      clientDomain,
      baseUrl,
      mitelToken,
      userConnectId: fromDialog ? dialogConnectId : userConnectId,
      resetChangePhoto
    });
  };

  const onViewClick = () => {
    setShowTempPhoto(true);
  };

  const closePreview = () => {
    setShowTempPhoto(false);
  };

  const resetChangePhoto = () => {
    setSelectedFile(null);
    setShowTempPhoto(false);
  };

  return (
    <div
      className={classes.container}
      style={{
        color: settings.colors.primary
      }}
    >
      <div className={clsx(classes.title,{[classes.panelTitle]: fromDialog})}>
        {intl.formatMessage({
          id: 'USER_PHOTO'
        })}
      </div>
      <div className={classes.content}>
        <Photo
          tempPhoto={selectedFile?.tempUrl}
          showTempPhoto={showTempPhoto}
          closePreview={closePreview}
          fromDialog={fromDialog}
          dialogPhoto={dialogPhoto}
        />
        {(!fromDialog || (fromDialog && dialogConnectId === userConnectId)) && (
          <ConfirmDialog
            onConfirmClick={onDelete}
            dialogTextIntlId={'CHANGE_PHOTO.REMOVE_PHOTO_TEXT'}
            dialogTitleIntlId={'TOOLTIP.DELETE'}
          >
            <PrimaryButton>
              {intl.formatMessage({
                id: 'CHANGE_PHOTO.REMOVE_PHOTO'
              })}
            </PrimaryButton>
          </ConfirmDialog>
        )}
        <div className={classes.infoContainer}>
          <div className={classes.infoFirstRow}>
            {intl.formatMessage({
              id: 'CHANGE_PHOTO.INFO_1'
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'CHANGE_PHOTO.INFO_2'
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'CHANGE_PHOTO.INFO_3'
            })}
          </div>
        </div>
        <div className={classes.fileContainer}>
          <PrimaryButton component="label">
            <>
              {intl.formatMessage({
                id: 'BUTTON.CHOOSE_FILE'
              })}
              <input
                accept="image/jpg, image/jpeg, image/jpe"
                type="file"
                hidden
                onChange={onFileChange}
              />
            </>
          </PrimaryButton>
          <div className={classes.fileName}>
            {Optional(selectedFile?.file?.name).or(
              intl.formatMessage({
                id: 'NO_FILE_SELECTED'
              })
            )}
          </div>
          <PrimaryButton
            disabled={isUndefined(selectedFile)}
            onClick={onViewClick}
          >
            <>
              {intl.formatMessage({
                id: 'BUTTON.PREVIEW'
              })}
            </>
          </PrimaryButton>
        </div>
        <div className={classes.footer}>
          <PrimaryButton
            onClick={onFileUpload}
            disabled={isUndefined(selectedFile)}
          >
            {intl.formatMessage({
              id: 'CHANGE_PHOTO.SAVE'
            })}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ auth }) => {
  return {
    userConnectId: auth.user?.connect30_user_id,
    mitelToken: auth.user.mitel_token,
    baseUrl: auth.user.mitel_profile_link,
    clientDomain: auth.defaultClient.connect30_domain
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(ChangePhoto);
