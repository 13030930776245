import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import AddContactForm from './components/AddContactForm';

export function AddExternalContact({
  classes,
  open,
  intl,
  closeAddContact,
  isEdit
}) {
  const title = useMemo(
    () =>
      `${
        isEdit
          ? intl.formatMessage({
              id: 'EDIT'
            })
          : intl.formatMessage({
              id: 'EDIT.ADD'
            })
      } ${intl.formatMessage({
        id: 'ADD_EXTERNAL_CONTACT.EXTERNAL_CONTACT'
      })}`,
    // eslint-disable-next-line
    [isEdit, intl]
  );

  const dialogClasses = useMemo(
    () => {
      return {
        paper: classes.root
      };
    }, // eslint-disable-next-line
    []
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={dialogClasses}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={closeAddContact}
    >
      <CustomDialogTitle onCloseClick={closeAddContact}>
        {title}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <AddContactForm />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ addExternalContact }) => {
  return {
    open: addExternalContact.open,
    isEdit: addExternalContact.isEdit
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddExternalContact);
