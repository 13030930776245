import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_GROUPS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { isUndefined } from '../../../utils/isDefined';
import { OUTSIDE_API } from '../../../consts/endpoints';

export function fetchGroups(connectClientId) {
  return {
    type: sagaActions[GET_GROUPS],
    connectClientId
  };
}

export function* getGroups({ connectClientId }) {
  if (isUndefined(connectClientId)) {
    yield put(reduxActions.setGroups([]));
  } else {
    yield put(loaderReducActions.startSpinLoader());

    let groups = [];
    try {
      const response = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/user_groups`
      );

      groups = response.map(el => {
        return { ...el, id: el.name };
      });
    } catch {
    } finally {
      yield put(reduxActions.setGroups(groups));
      yield put(loaderReducActions.stopSpinLoader());
    }
  }
}

function* watchGetGroups() {
  yield takeLatest(sagaActions[GET_GROUPS], getGroups);
}

export default watchGetGroups();
