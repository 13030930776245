import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import withSettings from '../../utils/withSettings';
import Optional from '../../utils/optional';

const styles = theme => ({
  root: {
    display: 'flex',
    width: 'fit-content',
    padding: '0 5px',
    minWidth: '130px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: '#fff',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      minWidth: 'unset',
      height: '20px',
      padding: 'unset',
      color: '#6C6C6C',
      backgroundColor: '#FFF'
    },
    '& span': {
      padding: '0 5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  plusButton: {
    display: 'none',
    color: '#FFF',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
});

export function AddButton({
  classes,
  buttonText,
  intl,
  settings,
  customStyles = {},
  onClick = () => {}
}) {
  return (
    <div
      onClick={onClick}
      className={classes.root}
      style={{
        backgroundColor: settings.colors.buttons,
        color: settings.colors.buttons_text,
        ...Optional(customStyles.root).or({})
      }}
    >
      <i className={clsx('fas fa-plus', classes.plusButton)}></i>
      <span>
        {intl.formatMessage({
          id: buttonText
        })}
      </span>
    </div>
  );
}

export default flowRight(
  withSettings,
  injectIntl,
  withStyles(styles)
)(AddButton);
