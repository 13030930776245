import { isString, isStringEmpty } from '../../../utils/isDefined';

export default function mapClient(client) {
  let { connect30_domain } = client;

  if (isString(connect30_domain)) {
    connect30_domain = isStringEmpty(connect30_domain.trim())
      ? null
      : connect30_domain;
  }

  return { ...client, connect30_domain };
}
