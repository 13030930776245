import { isDefined, isStringDefined } from '../../../utils/isDefined';
import customParseNumber from '../../../utils/customParseNumber';

const mapDataForSend = ({
  values = {},
  numberRangeId,
  phoneNumber,
  isMultiedit,
  defaultClientAddress
}) => {
  const {
    id,
    created_at,
    updated_at,
    reload,
    address,
    client_id,
    client,
    address_id,
    use_default_address,
    ...restValues
  } = values;

  const parsedPhoneNumber = isMultiedit
    ? phoneNumber
    : customParseNumber(`+${phoneNumber}`);

  if (isDefined(parsedPhoneNumber?.nationalNumber || isMultiedit)) {
    let mappedData = {
      data: {
        type: 'phone_book_registrations',
        attributes: {
          ...restValues,
          use_default_address,
          number: isMultiedit
            ? parsedPhoneNumber
            : Number(parsedPhoneNumber.nationalNumber)
        },
        relationships: {}
      }
    };

    if (isStringDefined(address) && !use_default_address) {
      mappedData.data.relationships.address = {
        data: {
          type: 'addresses',
          id:
            address === 'DEFAULT' && isDefined(defaultClientAddress)
              ? Number(defaultClientAddress)
              : Number(address)
        }
      };
    }

    if (use_default_address) {
      mappedData.data.attributes.address_id = null;
    }

    if (isDefined(numberRangeId)) {
      mappedData.data.relationships.external_number_range = {
        data: {
          type: 'external_number_ranges',
          id: Number(numberRangeId)
        }
      };
    }

    if (isDefined(id)) {
      mappedData.data.id = id;
    }

    return mappedData;
  }

  return void 0;
};

export default mapDataForSend;
