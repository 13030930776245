import { JSONAPI_URLS } from '../consts/endpoints';
import store from '../store/store';
import { getData } from './http';
import urlCreator from './urlCreator';
import { actions as authReduxActions } from '../pages/auth/reducers';
import Optional from './optional';

const CUSTOM_JOBS_QUERY_PARAMS = {
  fields: { custom_jobs: ['id', 'name', 'category'] }
};

const getCustomJobs = async () => {
  try {
    const customJobs = await getData(
      urlCreator(JSONAPI_URLS.CUSTOM_JOBS, CUSTOM_JOBS_QUERY_PARAMS)
    );

    store.dispatch(
      authReduxActions.setCustomJobs(Optional(customJobs?.data).or([]))
    );
  } finally {
  }
};

export default getCustomJobs;
