const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    borderRadius: '10px',
    padding: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      justifyContent: 'space-between'
    }
  },
  chartsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      justifyContent: 'space-between'
    }
  },
  icon: {
    cursor: 'pointer'
  },
  iconContainer: {
    width: '100%',
    paddingRight: '5px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default styles;
