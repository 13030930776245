const styles = () => {
  return {
    root: { width: '550px', backgroundColor: '#FFFFFF', paddingBottom: '10px' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    container: { margin: '10px 0' }
  };
};

export default styles;
