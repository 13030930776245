import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useEffect, useState } from 'react';

import { isDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import withUserCheck from '../../../utils/withUserCheck';
import eventListener from '../actions/eventListener';
import styles from '../styles/infoBox';
import AdminPresence from './AdminPresence';

function AdminBox({
  admin,
  classes,
  settings,
  activities,
  isCurrentUserSelectedClientUser
}) {
  const [open, setOpen] = useState(false);
  const [presence, setPresence] = useState(null);

  useEffect(
    () => open && setOpen(false),
    // eslint-disable-next-line
    [admin]
  );

  useEffect(() => {
    if (isCurrentUserSelectedClientUser && isDefined(admin.user_id)) {
      return eventListener({
        setPresence,
        adminId: admin.user_id,
        activities
      });
    }
    // eslint-disable-next-line
  }, [admin.user_id, isCurrentUserSelectedClientUser]);

  return (
    <div
      className={classes.adminContainer}
      style={{
        backgroundColor: open
          ? settings.colors.aside_menu_active
          : settings.colors.aside_menu,
        color: open
          ? settings.colors.aside_menu_active_text
          : settings.colors.aside_menu_text
      }}
    >
      <div className={classes.nameRow}>
        <div className={clsx(classes.chevronIcon)}>
          {open ? (
            <i
              className={clsx('fas fa-chevron-down')}
              onClick={() => setOpen(false)}
            ></i>
          ) : (
            <i
              className={clsx('fas fa-chevron-right')}
              onClick={() => setOpen(true)}
            ></i>
          )}
        </div>
        <div className={classes.adminName}>{admin.name}</div>
      </div>
      {isDefined(presence) && (
        <AdminPresence
          activities={activities}
          activityId={presence.updatedActivityId}
          presence={presence.updatedActivityAttributes}
        />
      )}
      {open && (
        <div className={classes.infoContainer}>
          <div>
            <a
              href={`mailto:${admin.email}`}
              style={{ color: settings.colors.white }}
            >
              {admin.email}
            </a>
          </div>
          <div>
            {admin.phones.map(phone => {
              return (
                <div key={phone}>
                  <a
                    className={classes.link}
                    style={{ color: settings.colors.white }}
                    href={`tel:${phone}`}
                  >
                    {phone}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default flowRight(
  withUserCheck,
  withSettings,
  withStyles(styles)
)(AdminBox);
