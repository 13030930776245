import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_USER } from './types';
import { fetchUsers } from '../../../pages/users/actions/getUsers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData, postData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import { fetchUsers as fetchTelephonyUsers } from '../../../pages/telephonyUsers/actions/getUsers';
import { isDefined } from '../../../utils/isDefined';
import mapDataToUpdate from './mapDataToUpdate';
import showSnackbar from '../../../utils/showSnackbar';
import { actions as usersReduxActions } from '../../../pages/users/reducers';

export function updateOnSubmit({
  user,
  clientId,
  isAdminView,
  initialUserRoles,
  clientDomain,
  onlyRoles,
  clientConnectId,
  isBeTable
}) {
  return {
    type: sagaActions[UPDATE_USER],
    user,
    clientId,
    isAdminView,
    initialUserRoles,
    clientDomain,
    onlyRoles,
    clientConnectId,
    isBeTable
  };
}

export function* updateUser({
  user,
  clientId,
  isAdminView,
  initialUserRoles,
  clientDomain,
  onlyRoles = false,
  clientConnectId,
  isBeTable
}) {
  const mappedData = yield call(mapDataToUpdate, {
    user,
    clientId,
    initialUserRoles,
    clientDomain,
    onlyRoles
  });

  try {
    const response = yield call(isDefined(user.id) ? updateData : postData, {
      url: isDefined(user.id) ? `${URLS.USERS}/${user.id}` : URLS.USERS,
      data: { ...mappedData }
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.UPDATE.USER',
        messageType: 'success'
      });
    }

    if (response.status === RESPONSE_CODES.CREATED) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.CREATED.USER',
        messageType: 'success'
      });
    }

    if (onlyRoles) {
      yield call(fetchTelephonyUsers, {
        connectClientId: clientConnectId,
        clientId
      });
      yield put(reduxActions.updateRoles(user.roles));
    } else {
      isBeTable
        ? yield put(usersReduxActions.setRefresh(true))
        : yield call(fetchUsers, { client: !isAdminView ? clientId : null });

      yield put(reduxActions.resetUser());
    }
  } finally {}
}

function* watchUpdateUser() {
  yield takeLatest(sagaActions[UPDATE_USER], updateUser);
}

export default watchUpdateUser();
