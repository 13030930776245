export default {
  root: {
    paddingTop: '20px',
    marginLeft: '10px',
    display: 'flex'
  },
  selectColumn: {
    width: '300px'
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: '13px',
    fontWeight: 600,
    margin: '0 20px 0 0',
    width: '120px'
  },
  rolesContainer: {
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    width: '100%',
    height: '180px',
    overflowX: 'auto',
    marginBottom: '25px'
  },
  roleContainer: {
    margin: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ' & i': {
      color: '#B3B3B3'
    }
  },
  fieldValue: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
    borderRadius: '4px',
    margin: '5px 0 14px',
    paddingTop: '8px',
    width: '250px',
    '& p': {
      margin: '2px 0'
    }
  },
  addButton: {
    color: '#fff',
    backgroundColor: '#5D78FF',
    '&:hover': { backgroundColor: '#0ABB87' }
  }
};

export const customSelectStyles = {
  option: { fontSize: '12px' },
  control: {
    width: '250px'
  }
};
