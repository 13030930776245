const styles = {
  root: { display: 'flex', flexDirection: 'column', maxWidth: '800px' },
  label: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '10px',
    backgroundColor: '#0699A3',
    color: 'white',
    width: '100%'
  },
  serviceName: { width: '360px' },
  actions: { width: '60px' }
};

export default styles;
