const styles = theme => {
  return {
    root: { display: 'flex' },
    rechargeLabel: { paddingLeft: '10px' },
    infoIcon: {
      marginLeft: '5px'
    },
    expired: {
      textAlign: 'end',
      fontSize: '12px',
      marginLeft: '10px',
      paddingRight: '50px',
      marginTop: '5px'
    },
    hidden: {
      visibility: 'hidden'
    }
  };
};

export default styles;
