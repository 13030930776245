import React, { useMemo } from 'react';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { setLocalization, TABLE_OPTIONS } from '../../../consts/tableOptions';
import tableColumns from './tableColumns';

export function CellNumbersTable({ intl, data }) {
  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  const options = {
    ...TABLE_OPTIONS.HISTORY
  };

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  return (
    <MaterialTable
      columns={tableDefaultColumns}
      data={data.cell_numbers}
      options={options}
      localization={localization}
    />
  );
}

export default flowRight(injectIntl)(CellNumbersTable);
