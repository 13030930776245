import history from '../../../../history';
import store from '../../../store/store';
import fetchUserPhotoFromMitel from '../../../utils/fetchUserPhotoFromMitel';
import { actions as reduxActions } from '../reducers';
import getUserInfoFromLocation from './getUserInfoFromLocation';

const getPhoto = async ({
  userId,
  isCurrentUserSelectedClientUser,
  currentUserClientId,
  connectClientId,
  currentLoggedUserId
}) => {
  const { isCurrentUser } = getUserInfoFromLocation({
    location: history.location,
    currentLoggedUserId
  });
  const canFetch = isCurrentUserSelectedClientUser || isCurrentUser;

  const { photo } = await fetchUserPhotoFromMitel({
    userId,
    connectDomain: isCurrentUser ? currentUserClientId : connectClientId,
    domainUser: canFetch
  });

  store.dispatch(reduxActions.setUserPhoto(photo));
};

export default getPhoto;
