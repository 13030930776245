import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from './styles';
import withUserCheck from '../../utils/withUserCheck';
import InfoBox from './components/InfoBox';
import withSettings from '../../utils/withSettings';
import NonGroupedButtons from './components/NonGroupedButtons';
import GroupedButtons from './components/GroupedButtons';

function ClientDashboard({ classes, settings, isGrouped = true }) {
  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      {isGrouped ? <GroupedButtons /> : <NonGroupedButtons />}
      <div>
        <InfoBox />
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withUserCheck,
  withSettings,
  withStyles(styles)
)(ClientDashboard);
