import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import RequestForm from './components/RequestForm';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';

export function RequestNewUser({ resetUser, classes, open, intl, clientName }) {
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'REQUEST_NEW_USER.TITLE'
      }),
    // eslint-disable-next-line
    [clientName]
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          resetUser();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={resetUser}>{title}</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <RequestForm />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ requestNewUser }) => {
  return {
    open: requestNewUser
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(RequestNewUser);
