import { call } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, NEW_SIMCARD_REQUEST } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { isStringDefined } from '../../../utils/isDefined';

export function sendOrderData({ simcardsData, nextStep }) {
  return {
    type: sagaActions[NEW_SIMCARD_REQUEST],
    simcardsData,
    nextStep
  };
}

export function* newSimcardsRequest({ simcardsData, nextStep }) {
  const data = yield call(mapSimcartdsData, simcardsData);

  const response = yield call(postData, {
    url: URLS.ORDER_SIMCARDS,
    data
  });

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.NEW_SIMCARD_REQUESTS_SEND',
      messageType: 'success'
    });
  }

  return nextStep();
}

function* watchNewSimcardsRequest() {
  yield takeLatest(sagaActions[NEW_SIMCARD_REQUEST], newSimcardsRequest);
}

export default watchNewSimcardsRequest();

function mapSimcartdsData(data = {}) {
  const {
    shipping_address,
    name,
    additionalValues,
    att,
    provider,
    count,
    ...restData
  } = data;
  const mappedAddress = `${
    isStringDefined(name.trim()) ? `${name.trim()}\n` : ''
  }${shipping_address} ${isStringDefined(att.trim()) ? `\n Att. ${att}` : ''}`;

  return {
    ...restData,
    shipping_address: mappedAddress,
    provider,
    count: provider === 'TDC IoT' ? Number(count) * 10 : count
  };
}
