import React, { useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import styles from './styles';
import Optional from '../../utils/optional';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import { isDefined } from '../../utils/isDefined';
import ConfirmDialog from '../confirmationDialog/ConfirmDialog';
import getSelectValuefromOptions from '../../utils/getSelectValueFromOptions';

export function SelectRow({
  classes,
  label,
  options = [],
  name,
  setFieldValue,
  handleBlur,
  values,
  intl,
  errors = {},
  isDisabled = false,
  isEditModal = true,
  columnDirection = false,
  mobile = false,
  infoIcon = false,
  infoIconTooltipIntlKey = null,
  infoIconTooltipComponent = null,
  isInfoDialog = false,
  infoDialogTextComponent = null,
  menuPlacement = 'bottom',
  maxMenuHeight = 140,
  minMenuHeight = 100,
  customStyles = { value: { width: '200px' } },
  isLoading = false,
  isRequired = false,
  mobileCustom = false
}) {
  const customSelectStyles = useMemo(() => {
    const mappedCustomWidth = Optional(customStyles.value)
      .map(value => value.width)
      .or('200px');
    return {
      container: {
        width: mappedCustomWidth
      },
      valueContainer: {
        width: mappedCustomWidth
      },
      option: { fontSize: '12px' },
      control: {
        width: mappedCustomWidth,
        borderColor: errors[name] ? '#FD397A' : '#D3D3D3'
      }
    };
    // eslint-disable-next-line
  }, [errors]);

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: isEditModal ? `EDIT.${label}` : label
      }),
    // eslint-disable-next-line
    []
  );

  const errorMessage = Optional(errors[name]).or(null);

  const value = getSelectValuefromOptions(options, values[name]);

  const handleChange = useCallback(
    selected => {
      setFieldValue(name, selected.value);
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div
      className={clsx(classes.root, {
        [classes.mobileRootCustom]: mobileCustom
      })}
      style={customStyles.root}
    >
      <div
        className={clsx(classes.field, {
          [classes.mobileField]: mobile,
          [classes.columnDirection]: columnDirection,
          [classes.mobileFieldCustom]: mobileCustom
        })}
        style={customStyles.field}
      >
        <div className={classes.label} style={customStyles.label}>
          {title}
          {infoIcon ? (
            isInfoDialog ? (
              <ConfirmDialog
                dialogTitleIntlId={'INFO'}
                dialogContentComponent={infoDialogTextComponent}
                actionButtonsEnabled={false}
              >
                <Tooltip
                  title={
                    isDefined(infoIconTooltipIntlKey)
                      ? intl.formatMessage({
                          id: infoIconTooltipIntlKey
                        })
                      : ''
                  }
                >
                  <i
                    className={clsx('fas fa-info-circle', classes.infoIcon)}
                  ></i>
                </Tooltip>
              </ConfirmDialog>
            ) : (
              <Tooltip
                title={
                  isDefined(infoIconTooltipComponent)
                    ? infoIconTooltipComponent
                    : isDefined(infoIconTooltipIntlKey)
                    ? intl.formatMessage({
                        id: infoIconTooltipIntlKey
                      })
                    : ''
                }
              >
                <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
              </Tooltip>
            )
          ) : null}
        </div>
        <div className={classes.valueContainer}>
          <div className={classes.requiredIcon}>{isRequired ? '*' : ''}</div>
          <Select
            className={classes.fieldValue}
            styles={reactSelectDefaultStyles}
            customStyles={customSelectStyles}
            isSearchable
            options={options}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            isDisabled={Optional(isDisabled).or(false)}
            menuPlacement={menuPlacement}
            minMenuHeight={minMenuHeight}
            maxMenuHeight={maxMenuHeight}
            isLoading={isLoading}
            loadingMessage={() =>
              intl.formatMessage({
                id: 'LOADING'
              })
            }
          />
        </div>
      </div>
      <div className={classes.errorMessage}>{errorMessage}</div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(SelectRow);
