import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isValidUrl } from '../../../utils/isDefined';

export default function() {
  return yup.object().shape({
    target_url: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      .test(null, translate('INVALID_URL'), value => {
        return isValidUrl(`https://${value}`) ? true : false;
      }),
    type: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    ongoing: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    public: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
  });
}
