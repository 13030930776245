import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmptyArray } from 'formik';
import { flowRight } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { isDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import { deleteItem } from '../actions/deleteCallLogItem';
import { fetchUserVoicemails } from '../actions/getUserVoicemails';
import styles from '../styles/callLog';
import ShowMore from './ShowMore';
import VoicemailCard from './VoicemailCard';

function VoicemailsContainer({
  classes,
  voicemails,
  settings,
  intl,
  deleteItem,
  fetchUserVoicemails,
  voicemailsCount,
  refresh
}) {
  useEffect(
    () => {
      fetchUserVoicemails({});
    },
    // eslint-disable-next-line
    [refresh]
  );

  const onDeleteClick = useCallback(() => {
    deleteItem({
      allAction: 'DELETE_ALL',
      deleteAll: true,
      isCallLog: false
    });
    // eslint-disable-next-line
  }, [deleteItem]);

  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <div className={classes.title}>
        Voicemails{' '}
        <ConfirmDialog
          onConfirmClick={onDeleteClick}
          dialogContentComponent={intl.formatMessage({
            id: 'CONTACTS.DELETE_ALL_VOICEMAIL_CONFIRMATION'
          })}
          dialogTitleIntlId={'BUTTON.DELETE_ALL'}
          diasabledChildren={isEmptyArray(voicemails)}
        >
          <SecondaryButton>
            <div className={classes.deleteAllButton}>
              {intl.formatMessage({
                id: 'BUTTON.DELETE_ALL'
              })}
              <i className={clsx('fal fa-trash', classes.deleteIcon)}></i>
            </div>
          </SecondaryButton>
        </ConfirmDialog>
      </div>

      {isDefined(voicemails) &&
        voicemails.map(voicemail => (
          <VoicemailCard data={voicemail} key={voicemail?.itemId} />
        ))}
      {isDefined(voicemails) && voicemailsCount > voicemails.length && (
        <ShowMore fromVoicemails={true} />
      )}
    </div>
  );
}

const mapStatesToProps = ({ userContacts }) => {
  return {
    voicemails: userContacts.voicemails,
    voicemailsCount: userContacts.voicemailsCount,
    refresh: userContacts.refresh
  };
};

const mapDispatchToProps = {
  deleteItem,
  fetchUserVoicemails
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(VoicemailsContainer);
