import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/agentsDetails';
import mapAgents from '../actions/mapAgents';
import AgentDetails from './AgentDetails';

export function AgentsDetails({ classes, queue }) {
  const isSupervisor = queue.roles.find(role => role.type === 'SUPERVISOR');

  const rows = mapAgents(queue.agents);

  return (
    <table className={classes.agentsRoot}>
      <tbody>
        {rows.map((agent, i) => {
          return (
            <AgentDetails
              isSupervisor={isSupervisor}
              agent={agent}
              key={i}
              queueId={queue.id}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export default withStyles(styles)(AgentsDetails);
