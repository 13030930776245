import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';

const DeleteVacationConfimation = ({ classes }) => {
  return (
    <div className={classes.dialogTextContainer}>
      {translate('VACATIONS.DELETE_CONFIRMATION')}
    </div>
  );
};

export default withStyles(styles)(DeleteVacationConfimation);
