import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent, Tab, Tabs } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import Optional from '../../utils/optional';
import { changeClientUsersOptions } from './actions/changeClientUsersOptions';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import UserGeneralDataForm from './components/UserGeneralDataForm';
import TabPanel from '../../partials/TabPanel';
import TelephonyUserGeneralDataForm from './components/TelephonyUserGeneralDataForm';
import UserGroups from './components/UserGroups';
import Roles from './components/Roles';
import clsx from 'clsx';

export function EditUser({
  resetUser,
  user,
  classes,
  open,
  intl,
  clientName,
  isTelephonyUser,
  tabIndex,
  changeTabsIndex
}) {
  const handleChange = (event, newValue) => {
    changeTabsIndex(newValue);
  };

  const handleClose = () => {
    resetUser();
  };

  const title = useMemo(
    () =>
      `${
        user.id || isTelephonyUser
          ? intl.formatMessage({
              id: 'EDIT.EDIT'
            })
          : intl.formatMessage({
              id: 'EDIT.ADD'
            })
      } ${intl.formatMessage({
        id: 'EDIT.USER'
      })} `,
    // eslint-disable-next-line
    [clientName, user]
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        container: classes.container,
        paper: classes.content,
        scrollPaper: classes.scrollPaper
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={handleClose}>{title}</CustomDialogTitle>
      <DialogContent
        className={clsx(classes.dialogContent, {
          [classes.userDialogContent]: !isTelephonyUser
        })}
      >
        {isTelephonyUser ? (
          <>
            <Tabs value={tabIndex} onChange={handleChange} centered>
              <Tab
                label={intl.formatMessage({
                  id: 'GENERAL'
                })}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'GROUPS'
                })}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'MENU.ROLES'
                })}
              />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <TelephonyUserGeneralDataForm />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <UserGroups />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Roles />
            </TabPanel>
          </>
        ) : (
          <UserGeneralDataForm />
        )}
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ editUser, selectClient }) => {
  return {
    ...editUser,
    clientName: Optional(selectClient.selectedClient)
      .map(client => client.name)
      .or(''),
    clientDomain: Optional(selectClient.selectedClient)
      .map(client => client.connect30_domain)
      .or(null)
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  changeClientUsersOptions
};

export default flowRight(
  withRouter,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditUser);
