import { mapFilterValue } from '../../../utils/tableUtils';

export default function mapCustomFilters({ filter, obj, value }) {
  const mappedValue = mapFilterValue(value);

  switch (filter.column.field) {
    case 'uuid':
      return { ...obj, [filter.column.field]: { eq: mappedValue } };
    case 'vip_status':
      return { ...obj, [filter.column.field]: { eq: mappedValue } };
    default:
      return { ...obj, [filter.column.field]: { match: mappedValue } };
  }
}
