import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_PERMISSIONS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import getFullTableData from '../../../utils/getFullTableData';

const QUERY_PARAMS = {
  include: ['roles'],
  fields: { roles: 'name' }
};

export function getPermissions() {
  return {
    type: sagaActions[GET_PERMISSIONS]
  };
}

export function* fetchPermissions() {
  yield put(loaderReducActions.startSpinLoader());

  const permissions = yield call(
    getFullTableData,
    JSONAPI_URLS.PERMISSIONS,
    QUERY_PARAMS
  );

  yield put(reduxActions.setPermissions(permissions));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetPermissions() {
  yield takeLatest(sagaActions[GET_PERMISSIONS], fetchPermissions);
}

export default watchGetPermissions();
