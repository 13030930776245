const styles = theme => ({
  root: {
    paddingBottom: '10px'
  },
  icon: {
    marginRight: '5px'
  },
  dataRow_root: {
    paddingRight: '30px',
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '10px'
    }
  },
  dataRow_value: {
    width: 'fit-content',
    fontWeight: 600
  }
});

export default styles;
