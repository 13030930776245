import { isDefined, isFunction } from './isDefined';

export default function Optional(value) {
  const me = {
    or: orValue =>
      isDefined(value) ? value : isFunction(orValue) ? orValue() : orValue,
    isPresent: () => isDefined(value),
    get: () => value,
    map: (fn = () => me) => (me.isPresent() ? Optional(fn(value)) : me)
  };
  return me;
}
