import { getData } from './http';
import { isDefined } from './isDefined';
import urlCreator from './urlCreator';

const DATA_COUNT = 1000;

export default async function getFullTableData(url, queryParams) {
  let elements = [];
  try {
    const { data, meta } = await getData(
      urlCreator(url, {
        ...queryParams,
        page: { size: DATA_COUNT },
        stats: { total: 'count' }
      })
    );
    elements = data;
    const count = meta?.stats?.total?.count;

    if (isDefined(count) && count > DATA_COUNT) {
      const pages = Math.floor(count / DATA_COUNT);
      for (let i = 1; i <= pages; i++) {
        const response = await getData(
          urlCreator(url, {
            ...queryParams,
            page: { size: DATA_COUNT, number: i + 1 }
          })
        );
        elements = [...elements, ...response.data];
      }
    }
  } catch {
  } finally {
    return elements;
  }
}
