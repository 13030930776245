import React, { useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import Optional from '../utils/optional';
import { CircularProgress } from '@material-ui/core';
import withSettings from '../utils/withSettings';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& i': {
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    },
    '& span': {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
});

export function CustomTableTitle({
  name,
  text,
  icon,
  classes,
  intl,
  isLoading,
  settings,
  children
}) {
  const titleText = useMemo(
    () =>
      `${Optional(name).or('')} ${intl.formatMessage({
        id: text
      })}`,
    // eslint-disable-next-line
    [name, text]
  );
  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <div className={classes.label}>
        {icon}
        <span>{titleText}</span>
        {children}
      </div>
      {isLoading ? <CircularProgress /> : null}
    </div>
  );
}

export default flowRight(
  withSettings,
  injectIntl,
  withStyles(styles)
)(CustomTableTitle);
