export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px'
  },
  footer: { margin: '10px' },
  row: { display: 'flex', alignItems: 'center', marginLeft: '20px' },
  info: { fontSize: '14px', margin: '10px 0' },
  smallInfo: { fontSize: '10px' },
  errors : {
    padding:'10px 0'
  }
};
