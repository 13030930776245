import { isString } from './isDefined';
import Optional from './optional';

const checkIfIsEsimCard = simcardType => {
  return Optional(simcardType)
    .map(type => {
      if (!isString(type)) {
        return false;
      }

      return type.includes('ESIM');
    })
    .or(false);
};

export default checkIfIsEsimCard;
