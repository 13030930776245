import XMLParser from 'react-xml-parser';

import { isDefined, isUndefined } from '../../../utils/isDefined';
import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import Optional from '../../../utils/optional';
import compareIds from '../../../utils/compareIds';
import store from '../../../store/store';

export default async function getQueuesRealtimeData({ data, userMitelId }) {
  const xml = new XMLParser().parseFromString(data);
  const queues = store.getState().queues.queues;
  const { children = [] } = xml;
  const updatedQueues = children.map(el => {
    const { attributes = {}, children = [] } = el;
    const statistics = findUpdatedStats(children);

    return {
      id: attributes.id,
      isLoggedIn: attributes.active === 'true' ? true : false,
      statistics
    };
  });

  const mappedQueues = await Promise.all(
    queues.map(async queue => {
      const updatedQueue = updatedQueues.find(upQueue =>
        compareIds(upQueue.id, queue.id)
      );

      if (isUndefined(updatedQueue)) {
        return queue;
      }
      let modifiedQueue = {
        ...queue,
        isLoggedIn: updatedQueue.isLoggedIn
      };

      if (isDefined(updatedQueue.statistics)) {
        if (
          !compareIds(
            queue.loggedInAgents,
            updatedQueue.statistics.loggedInAgents
          )
        ) {
          const QUERY_PARAMS = {
            mitel_user_id: userMitelId
          };
          const data = await getData(
            urlCreator(
              `${OUTSIDE_API.QUEUES}/${updatedQueue.id}/agents`,
              QUERY_PARAMS
            )
          );
          modifiedQueue = {
            ...modifiedQueue,
            agents: Optional(data)
              .map(data => data.agents)
              .or(modifiedQueue.agents)
          };
        }

        modifiedQueue = { ...modifiedQueue, ...updatedQueue.statistics };
      }

      return modifiedQueue;
    })
  );

  return mappedQueues;
}

function findUpdatedStats(data) {
  const statsObject = data.find(child => child.name === 'statistics');

  if (isUndefined(statsObject)) {
    return void 0;
  }

  const { attributes = {} } = statsObject;

  return {
    queueLength: attributes.length,
    loggedInAgents: attributes.agents,
    availableAgents: attributes['free-agents']
  };
}
