const styles = theme => {
  return {
    root: {
      minWidth: '100px',
      height: '30px',
      color: '#fff',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': { backgroundColor: theme.palette.primary.medium }
    },
    disabledButton: {
      backgroundColor: '#E0E0E0'
    }
  };
};

export default styles;
