import React from 'react';

import { isObject, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import TableActionButtons from './TableActionButtons';
import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';

const ACCOUNT_TYPES = {
  INVOICE: 'ACCOUNTS.INVOICE_TYPE',
  CUSTOMER: 'ACCOUNTS.CUSTOMER_TYPE'
};

const tableColumns = () => {
  const columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      cellStyle: styles.actionCell,
      disableClick: true,
      width: '40px'
    },
    {
      title: 'ACCOUNT',
      field: 'accountNo'
    },
    {
      title: 'ACCOUNTS.COMPANY_REG_NO',
      field: 'companyRegNo'
    },
    {
      title: 'NAME',
      field: 'name'
    },
    {
      title: 'STATUS',
      field: 'status'
    },
    {
      title: 'Type',
      untranslatedTitle: true,
      render: rowData => mapAccountType(rowData.type),
      field: 'type'
    },
    {
      title: 'TABLE.PRIMARY_CONTACT',
      render: rowData => mapPrimaryContact(rowData.primaryContact),
      field: 'primaryContact'
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;

const mapPrimaryContact = data => {
  if (isUndefined(data) || !isObject(data)) {
    return '';
  }

  const { company, firstName, lastName, address } = data;

  const mappedAddress = mapAddress(address);

  return `${Optional(company).or('')} ${Optional(firstName).or('')} ${Optional(
    lastName
  ).or('')}, ${mappedAddress}`;
};

const mapAddress = address => {
  if (!isObject(address)) {
    return '';
  }

  const { lineOne, lineTwo, zipCode, city } = address;

  return `${Optional(lineOne).or('')} ${Optional(lineTwo).or('')}, ${Optional(
    zipCode
  ).or('')} ${Optional(city).or('')}`;
};

const mapAccountType = type => {
  if (isUndefined(type)) {
    return '';
  }

  return Optional(ACCOUNT_TYPES[type])
    .map(el => translate(el))
    .or('');
};
