import React, { useCallback, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/formDataEditor';
import { isDefined } from '../../../utils/isDefined';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

function SchemaEditor({
  classes,
  onSchemaChange,
  selected,
  template,
  setIsError,
  name,
  customJobId
}) {
  const [errors, setErrors] = useState(void 0);
  const [templateValue, setTemplateValue] = useState('{}');

  useEffect(() => {
    setTemplateValue(null);
    return selected === 'edit' && isDefined(template)
      ? setTemplateValue(JSON.stringify(template, null, 2))
      : setTemplateValue('{}');
  }, [selected, template, customJobId]);

  const onChange = useCallback(
    value => {
      try {
        const parsed = JSON.parse(value);
        onSchemaChange(parsed);
      } catch {}
    },
    [onSchemaChange]
  );

  const handleEditorValidation = useCallback(
    markers => {
      if (markers.length > 0) {
        setErrors(markers);
        setIsError(true);
      } else {
        setErrors(void 0);
        setIsError(false);
      }
    },
    [setErrors, setIsError]
  );

  return (
    <div className={classes.root}>
      {isDefined(errors) && (
        <div className={classes.errorsContainer}>
          {errors.map((error, i) => (
            <div
              key={i}
            >{`${error.endLineNumber}:${error.endColumn} ${error.message}`}</div>
          ))}
        </div>
      )}
      <div className={classes.label}>{name}</div>
      <Editor
        height={name === 'Parameters' ? '200px' : '50vh'}
        width="570px"
        language="json"
        value={templateValue}
        onValidate={handleEditorValidation}
        onChange={onChange}
      />
    </div>
  );
}

const mapStatesToProps = ({ editCustomJob }) => {
  return {
    selected: editCustomJob.selected,
    customJobId: editCustomJob.customJob?.id
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(SchemaEditor);
