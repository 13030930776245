export default {
    root: {
      padding: '10px 20px'
    },
    label: { marginBottom: '15px' },
    dataContainer: {
      display: 'flex'
    },
    links: { cursor: 'pointer' },
    dataKey: {
      margin: '0 20px',
      width: '180px'
    },
  };