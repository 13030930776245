import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import mapCustomFilters from './mapCustomFilters';

export default async function fetchSimcards({ query, client, active }) {
  const queryParams = mapQueryParams({ client, active });
 
  const requestUrl = JSONAPI_URLS.SIM_CARDS;

  return await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: '',
    mapCustomFilters
  });
}

export function mapQueryParams({ client, active }) {
  let queryParams = {
    include: ['owner', 'primary_card'],
    fields: { users: 'name' },
    filter: {
      client_id: isDefined(client) ? { eq: client } : { gt: 0 },
      active: true 
    }
  };

  if (isUndefined(client)) {
    queryParams = {
      ...queryParams,
      fields: { ...queryParams.fields, clients: 'name' },
      include: [...queryParams.include, 'client'],
      filter: { client_id: { gt: 0 }, active:  true }
    };
  }

  return queryParams;
}
