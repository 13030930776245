import React, { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'chart.js';
import { Tooltip, withStyles } from '@material-ui/core';

import styles from '../styles/customChart';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import clsx from 'clsx';

const CHART_OPTIONS = {
  cutoutPercentage: 75,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  title: { display: false },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  }
};

const CustomChart = ({
  label,
  classes,
  color = '#000',
  limilLabel,
  data = {},
  usageType,
  showLimit = true,
  showInfo = false,
  infoText = '',
  customLimit
}) => {
  const ref = useRef();

  const limit = Optional(customLimit).or(100);

  const dataForChart = useMemo(() => {
    return {
      labels: ['Used', 'Available'],
      datasets: [
        {
          data: [
            data.chartUsage,
            data.chartUsage >= limit ? 0 : limit - data.chartUsage
          ],
          backgroundColor: [color, '#F2F3F8'],
          borderWidth: 1
        }
      ]
    };
  }, [color, data.chartUsage, limit]);

  useEffect(() => {
    const myChart = new Chart(ref.current, {
      type: 'doughnut',
      data: dataForChart,
      options: CHART_OPTIONS
    });
    return () => {
      myChart.destroy();
    };
    // eslint-disable-next-line
  }, [data, dataForChart]);

  return (
    <div className={classes.root}>
      <div className={classes.label} style={{ color }}>
        {label}{' '}
        {showInfo && (
          <Tooltip title={infoText}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <div className={classes.chartContainer}>
        <div className={classes.chartInnerText} style={{ color }}>
          {isDefined(data.usageText)
            ? `${data.usageText} ${usageType}`
            : translate('NO_DATA')}
          {data.chartUsage >= limit && isUndefined(customLimit) && (
            <div className={classes.percentageContainer}>
              <i className={clsx('fas fa-arrow-up', classes.icon)}></i>
              <div className={classes.percentageText}>{` ${data.chartUsage -
                limit} %`}</div>
            </div>
          )}
        </div>
        <canvas ref={ref} width="110" height="110" />
      </div>

      {showLimit && (
        <div className={classes.limitContainer}>
          <div>{limilLabel}</div>
          {isDefined(data.limit) && (
            <div className={classes.limitValue}>{`${data.limit} GB`}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(CustomChart);
