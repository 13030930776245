import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import { setUser } from '../../../modals/editUser/actions/setUser';
import { isDefined } from '../../../utils/isDefined';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import DeleteUserConfirmation from './DeleteUserConfirmation';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { removeUser } from '../actions/deleteUser';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

export function TableActionButtons({
  data: user,
  classes,
  openEditUser,
  clientId,
  isDomainProvided,
  openShowHistory,
  removeUser,
  isBeTable
}) {
  const onEditClick = useCallback(
    () => {
      openEditUser({
        user,
        clientId,
        isDomainProvided,
        isTelephonyUser: false,
        isBeTable
      });
    },
    // eslint-disable-next-line
    [user, clientId, isDomainProvided, isBeTable]
  );
  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: user.id,
        itemType: 'User',
        titleText: user.name,
        itemTypeKey: 'MENU.USER',
        historyObject: user
      });
    },
    // eslint-disable-next-line
    [user.id]
  );

  const onDeleteClick = useCallback(
    () => {
      removeUser({ userId: user.id, clientId, isBeTable });
    },
    // eslint-disable-next-line
    [user.id, isBeTable, clientId]
  );
  return (
    <div className={classes.actionButtons}>
      {can('update', createSubject('User', user)) && (
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.EDIT_USER"
          onClick={onEditClick}
          iconClassName="far fa-edit"
        />
      )}
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.SHOW_HISTORY"
        onClick={onHistoryClick}
        iconClassName="far fa-history"
      />
      {can('delete', createSubject('User', user)) && (
        <ConfirmDialog
          onConfirmClick={onDeleteClick}
          dialogContentComponent={<DeleteUserConfirmation name={user.name} />}
          dialogTitleIntlId={'TOOLTIP.DELETE'}
        >
          <CustomIconButton
            iconTooltipIntlKey="TOOLTIP.DELETE"
            iconClassName="far fa-trash-alt"
          />
        </ConfirmDialog>
      )}
    </div>
  );
}
const mapStatesToProps = ({ selectClient }) => {
  return {
    isDomainProvided: isDefined(selectClient.selectedClient.connect30_domain)
  };
};

const mapDispatchToProps = {
  openEditUser: setUser,
  openShowHistory: showHistoryReduxActions.openShowHistory,
  removeUser
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
