import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import Optional from '../../utils/optional';

export function ErrorDialog({
  open,
  closeDialog,
  errorMessage,
  dialogTitle = 'Error',
  classes,
  intl,
  directMessage
}) {
  const handleClose = () => {
    closeDialog();
  };

  if (!errorMessage && !directMessage) {
    return null;
  }

  const customMessage = Optional(errorMessage?.messageIntl)
    .map(message =>
      intl.formatMessage({
        id: message
      })
    )
    .or(errorMessage?.message);

  const messages = directMessage
    ? [directMessage]
    : customMessage
    ? [`${errorMessage.name} : ${customMessage}`]
    : [`${errorMessage?.name} : ${errorMessage?.statusText}`];


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
      <DialogContent className={classes.content}>
        {messages.map((message, i) => (
          <div key={i}>{message}</div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStatesToProps = ({ errorDialog }) => {
  return { ...errorDialog };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ErrorDialog);
