const styles = theme => {
  return {
    headerRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    icon: {
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    },
    title: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    label: {
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    radio: {
      color: 'inherit'
    },
    radioButtons: {
      display: 'flex',
      fontSize: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  };
};

export default styles;
