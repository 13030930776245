import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';

import styles from '../styles/userGroups';
import withUserCheck from '../../../utils/withUserCheck';
import ActionsButtons from './ActionsButtons';
import { actions as reduxActions } from '../reducers';
import { updateTelephonyUserOnSubmit } from '../actions/updateTelephonyUser';
import { mapGroupData } from '../actions/mapDataToUpdate';
import Groups from './Groups';

export function UserGroups({
  classes,
  groups = [],
  updateTelephonyUserOnSubmit,
  clientConnectId,
  clientId,
  mitelUserId,
  resetUser,
  fromUsersTable,
  fromGroupsTable,
  allGroupsOptions
}) {
  const onSubmit = useCallback(
    values => {
      const mappedValues = mapGroupData(values);
      updateTelephonyUserOnSubmit({
        values: values.groups,
        userData: mappedValues,
        clientConnectId,
        clientId,
        mitelUserId,
        fromUsersTable,
        fromGroupsTable
      });
    },
    // eslint-disable-next-line
    [updateTelephonyUserOnSubmit]
  );

  return (
    <Formik
      initialValues={{
        groups: [...groups]
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, dirty, errors, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="groups"
              render={arrayHelpers => (
                <Groups
                  options={allGroupsOptions}
                  arrayHelpers={arrayHelpers}
                />
              )}
            />
            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={resetUser}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ editUser, selectClient }) => {
  return {
    ...editUser,
    allGroupsOptions: editUser.user.allGroupsOptions,
    groups: editUser.user.groups,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateTelephonyUserOnSubmit
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserGroups);
