import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { connect } from 'react-redux';

import styles from '../styles/datacardActions';
import { actions as swapSimcardReduxActions } from '../../swapSimcard/reducers';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import LockNumber from '../../../partials/lockNumber/LockNumber';
import {
  doesArrayHasLength,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import withUserCheck from '../../../utils/withUserCheck';
import ShowQrCode from '../../../partials/showQrCode/ShowQrCode';
import Optional from '../../../utils/optional';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import DataCheckboxRow from '../../../partials/dataBox/DataCheckboxRow';
import { translate } from '../../../i18n/I18nProvider';

export function DatacardActions({
  classes,
  intl,
  form,
  isCheckboxDisabled,
  openSwapSimcard,
  simcard = {},
  activationCode,
  isESimCard,
  hasClientAccounts,
  isLoading,
  subscription,
  isIoTCard
}) {
  const onSwapClick = useCallback(
    () => {
      const { id, msisdn, mobile_network, client_id } = simcard;
      openSwapSimcard({
        simcard: { id, msisdn, mobile_network, client_id, isESimCard },
        fromEdit: true,
        problems: subscription?.problems,
        isIoTCard
      });
    },
    // eslint-disable-next-line
    [simcard]
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.withoutMsisdn]:
          isUndefined(simcard.msisdn) ||
          simcard?.mobile_network?.brand !== 'TDC'
      })}
    >
      <div className={classes.datacard}>
        {!isIoTCard ? (
          <DataCheckboxRow
            label={translate('DATACARD')}
            name="data_card"
            disabled={isCheckboxDisabled}
            width={140}
            {...form}
          />
        ) : (
          <div className={classes.noDatacardCheckboxContainer}></div>
        )}
      </div>
      {hasClientAccounts &&
        simcard?.mobile_network?.brand === 'TDC' &&
        simcard?.active &&
        isESimCard &&
        isDefined(activationCode) && (
          <ShowQrCode activationCode={activationCode} number={simcard.msisdn}>
            <PrimaryButton>
              {intl.formatMessage({
                id: 'SWAP_SIMCARD.SHOW_QR_CODE'
              })}
            </PrimaryButton>
          </ShowQrCode>
        )}
      <div className={classes.buttons}>
        {isDefined(simcard.msisdn) &&
          simcard?.mobile_network?.brand === 'TDC' &&
          can('swap', createSubject('SimCard', simcard)) && (
            <Tooltip
              title={
                isDefined(simcard.primary_card_id)
                  ? intl.formatMessage({
                      id: 'TOOLTIP.CONTACT_SUPPORT'
                    })
                  : ''
              }
            >
              <div>
                <PrimaryButton
                  onClick={onSwapClick}
                  disabled={isDefined(simcard.primary_card_id) || isLoading}
                >
                  {intl.formatMessage({
                    id: 'BUTTON.SWAP_SIMCARD'
                  })}
                </PrimaryButton>
              </div>
            </Tooltip>
          )}
        {isDefined(simcard.msisdn) &&
          isDefined(simcard.active) &&
          simcard?.mobile_network?.brand === 'TDC' && (
            <LockNumber data={simcard} fromTable={true} />
          )}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ editSimcard, selectClient, loader }) => {
  return {
    ...editSimcard,
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false),
    isLoading: loader.isLinearVisible
  };
};

const mapDispatchToProps = {
  openSwapSimcard: swapSimcardReduxActions.openDialog
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DatacardActions);
