import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';

const ExpandIcon = ({ classes }) => {
  return <i className={clsx('fa fa-angle-down', classes.showMoreIcon)} />;
};

export default withStyles(styles)(ExpandIcon);
