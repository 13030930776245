import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { isDefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';
import Optional from '../../utils/optional';

const styles = {
  icon: {
    margin: '5px 10px',
    cursor: 'pointer',
    fontSize: '18px',
    '&:hover': { filter: 'brightness(1.5)' }
  },
  disabled: {
    cursor: 'default',
    '&:hover': { filter: 'unset' }
  }
};

function CustomIconButton({
  customStyles = {},
  iconTooltipIntlKey,
  iconTooltipText,
  disabledIconTooltipIntlKey,
  disabledIconTooltipText,
  onClick = () => {},
  iconClassName,
  intl,
  classes,
  settings,
  disabled = false
}) {
  const tooltipTitle = useMemo(() => {
    return !disabled &&
      (isDefined(iconTooltipIntlKey) || isDefined(iconTooltipText))
      ? Optional(iconTooltipIntlKey)
          .map(tooltip =>
            intl.formatMessage({
              id: tooltip
            })
          )
          .or(iconTooltipText)
      : isDefined(disabledIconTooltipIntlKey) ||
        isDefined(disabledIconTooltipText)
      ? Optional(disabledIconTooltipIntlKey)
          .map(tooltip =>
            intl.formatMessage({
              id: tooltip
            })
          )
          .or(disabledIconTooltipText)
      : '';
  }, [
    disabled,
    iconTooltipIntlKey,
    iconTooltipText,
    intl,
    disabledIconTooltipIntlKey,
    disabledIconTooltipText
  ]);

  const onButtonClick = () => {
    if (!disabled) {
      return onClick();
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <i
        className={clsx(iconClassName, classes.icon, {
          [classes.disabled]: disabled
        })}
        style={{
          color: disabled ? '#E0E0E0' : settings.colors.links,
          ...customStyles
        }}
        onClick={onButtonClick}
      ></i>
    </Tooltip>
  );
}

export default flowRight(
  withSettings,
  injectIntl,
  withStyles(styles)
)(CustomIconButton);
