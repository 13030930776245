import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isStringDefined, isUndefined } from '../../../utils/isDefined';

export default function() {
  return {
    user: yup.object().shape({
      name: yup
        .string()
        .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
        .test(null, translate('VALIDATION.INVALID_NAME'), function(value = '') {
          const valueArray = value
            .split(' ')
            .filter(word => isStringDefined(word));
          return valueArray.length > 1 ? true : false;
        }),
      username: yup.string().when(['id'], {
        is: id => isUndefined(id),
        then: yup
          .string()
          .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
          .matches(
            /^[a-z0-9æøå][a-z0-9 _.\- æøå]{0,24}$/g,
            translate('AUTH.VALIDATION.INVALID_FIELD')
          )
      }),
      language: yup
        .string()
        .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
      email: yup.string().email(translate('AUTH.VALIDATION.INVALID_FIELD')),
      client_id: yup.string().when('fromAdminPage', {
        is: true,
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      }),
      pass: yup
        .string()
        .matches(/^(?=.*\d).{8,20}$/gm, translate('VALIDATION.PASS_WRONG_INFO'))
        .when(['id', 'generate_welcome_email'], {
          is: (id, generate_welcome_email) =>
            isUndefined(id) && !generate_welcome_email,
          then: yup
            .string()
            .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
        })
    }),
    telephonyUser: yup.object().shape({
      email: yup
        .string()
        .email(translate('AUTH.VALIDATION.INVALID_FIELD'))
        .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
      firstName: yup
        .string()
        .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
      lastName: yup
        .string()
        .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
    })
  };
}
