import React from 'react';

import PhoneBookRegistrationTable from './components/PhoneBookRegistrationTable';

const PhoneBookRegistration = ({ fromAddress = false }) => {
  return (
    <>
      <PhoneBookRegistrationTable fromAddress={fromAddress} />
    </>
  );
};

export default PhoneBookRegistration;
