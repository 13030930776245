import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';

export default async function getCount({ url, selectedClientId }) {
  const QUERY_PARAMS = {
    page: { size: 0 },
    filter: { client_id: { eq: selectedClientId } },
    stats: { total: 'count' }
  };

  try {
    const { data: response } = await getData({
      options: { url: urlCreator(url, QUERY_PARAMS) },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    const count = Optional(response?.meta?.stats?.total?.count).or(0);

    return count;
  } catch (e) {
    console.error(e);
  }
}
