import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';

import DataRow from '../../Datarow/DataRow';
import { translate } from '../../../i18n/I18nProvider';
import styles from '../styles/info';
import StatusCell from '../../statusCell/StatusCell';
import pickOptionsForStatusCell from '../../statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';

const MAX_RECHARGE_COUNT = 4;

const Info = ({ classes, data = {} }) => {
  const customClasses = useMemo(() => {
    return {
      dataRow: {
        root: classes.dataRow_root_short,
        value: classes.dataRow_value
      }
    };
  }, [classes]);

  return (
    <div className={classes.root}>
      <div className={classes.label}>Info and status</div>
      <div className={classes.infoContainer}>
        <div>
          <DataRow
            label={translate('CURRENT_STATUS')}
            value={
              <StatusCell
                {...pickOptionsForStatusCell(
                  STATUS_BOX_OPTIONS.DATA_LIMITS,
                  data.status
                )}
              />
            }
            customClasses={customClasses.dataRow}
            dense
          />
          <DataRow
            label={translate('NUMBER.AUTO_REFILL')}
            value={
              <StatusCell
                {...pickOptionsForStatusCell(
                  STATUS_BOX_OPTIONS.SIMCARD_ACTIVE,
                  data.allow_recharge
                )}
              />
            }
            customClasses={customClasses.dataRow}
            dense
          />
        </div>
        <div>
          <DataRow
            label={translate('NUMBER.ROAMING_BLOCK')}
            value={data.roaming_disabled ? translate('YES') : translate('NO')}
            customClasses={customClasses.dataRow}
            dense
          />
          <DataRow
            label={translate('NUMBER.RECHARGE_COUNT')}
            value={`${data.recharge_count}/${MAX_RECHARGE_COUNT}`}
            customClasses={customClasses.dataRow}
            dense
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Info);
