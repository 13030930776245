import React, { useState, cloneElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import ChangeSubscription from './ChangeSubscription';
import Optional from '../../../utils/optional';
import CancelSubscriptionConfimation from './CancelSubscriptionConfimation';
import cancelSubscription from '../actions/cancelSubscription';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { actions as reduxActions } from '../reducers';

const MoreActionsMenu = ({
  children,
  subscription = {},
  onSwapClick,
  clientId,
  openSummary
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSwap = () => {
    handleClose();
    onSwapClick();
  };

  const onCancelConfirmation = async () => {
    const { requestCompleted, summaryInfo } = await cancelSubscription({
      subscriptionId: subscription?.id,
      clientId,
      externalReference: subscription?.externalReference,
      name: subscription?.name
    });

    if (requestCompleted) {
      openSummary({ summaryInfo });
      handleClose();
    }
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem
          onClick={onSwap}
          disabled={
            !can(
              'swap',
              createSubject('SimCard', { client_id: Number(clientId) })
            ) || subscription?.status !== 'ACTIVE'
          }
        >
          {translate('TOOLTIP.SWAP_SIMCARD')}
        </MenuItem>
        <div>
          <ChangeSubscription
            subscription={subscription}
            closeMenu={handleClose}
          >
            <MenuItem
              disabled={
                !can(
                  'update',
                  createSubject('Quote::Subscription', {
                    client_id: Number(clientId)
                  })
                ) || subscription?.status !== 'ACTIVE'
              }
            >
              {translate('SUBSCRIPTIONS.CHANGE_SUBSCRIPTION')}
            </MenuItem>
          </ChangeSubscription>
        </div>
        <div>
          <ConfirmDialog
            onConfirmClick={onCancelConfirmation}
            dialogContentComponent={<CancelSubscriptionConfimation />}
            dialogTitleIntlId={'SUBSCRIPTIONS.CANCEL_SUBSCRIPTION'}
            closeMenu={handleClose}
          >
            <MenuItem
              disabled={
                !can(
                  'update',
                  createSubject('Quote::Subscription', {
                    client_id: Number(clientId)
                  })
                ) || subscription?.status !== 'ACTIVE'
              }
            >
              {translate('SUBSCRIPTIONS.CANCEL_SUBSCRIPTION')}
            </MenuItem>
          </ConfirmDialog>
        </div>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id).or(void 0)
  };
};

const mapDispatchToProps = {
  openSummary: reduxActions.openSummary
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps)
)(MoreActionsMenu);
