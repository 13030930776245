import { updateData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import Optional from '../../../utils/optional';

export default async function updateCurrentRole({
  connectId,
  currentRole = '',
  rolesOptions
}) {
  try {
    const response = await updateData({
      url: `${OUTSIDE_API.USERS}/${connectId}/roles/current`,
      data: { role: currentRole.value }
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.UPDATE_USER_CONNECT_ROLE',
        messageType: 'success'
      });
      const mappedRole = rolesOptions.find(
        option => option.value === response.data.role
      );
      return Optional(mappedRole).or({});
    }
  } catch {}
}
