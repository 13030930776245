export default {
  root: {
    backgroundColor: '#fff',
    margin: '5px',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column',
    width: '500px',
    height: '480px'
  },
  label: {
    fontSize: '16px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  columnsContainer: { display: 'flex', flexDirection: 'column' },
  dotsIcon: { cursor: 'pointer', paddingRight: '20px' },
  datarowValue: { fontWeight: 600 },
  datarowUsageValue: { fontWeight: 600, width: '160px' },
  datarowRot: { paddingRight: '5px' },
  phoneBookRoot: {
    backgroundColor: '#fff',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column'
  },
  noEntry: { display: 'flex', justifyContent: 'center' },
  usageRow: { display: 'flex' },
  usageValue: { marginRight: '4px' },
  barContainer: {
    width: '100px',
    height: '20px',
    borderRadius: '5px',
    backgroundColor: '#E0E0E0',
    textAlign: 'center'
  },
  usageBar: {
    height: '20px',
    borderRadius: '5px'
  },
  usage: {
    marginTop: '-20px'
  },
  limitValue: {
    marginLeft: '10px',
    width: 'fit-content'
  }
};

export const dataRowCustomStyles = {
  root: { backgroundColor: '#FAFAFA' }
};
