import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import PasswordForm from '../../../partials/passwordForm/PasswordForm';
import { isDefined } from '../../../utils/isDefined';
import messageColors from '../../../consts/messageColors';
import history from '../../../../history';
import updatePassword from '../actions/updatePassword';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';
import Optional from '../../../utils/optional';

function UpdatePass({ classes, intl, settings }) {
  const [message, setMessage] = useState({});

  const onChangePass = useCallback(async data => {
    const returnedMessage = await updatePassword(data);
    setMessage(returnedMessage);

    if (returnedMessage.type === 'success') {
      setTimeout(() => history.push('/logout'), 3000);
    }
  }, []);

  return (
    <div className={classes.container}>
      <div
        className={classes.title}
        style={{
          color: settings.colors.primary
        }}
      >
        {intl.formatMessage({
          id: 'BUTTON.CHANGE_PASSWORD'
        })}
      </div>
      <div
        className={classes.centered}
        style={{
          color: settings.colors.primary
        }}
      >
        {intl.formatMessage({
          id: 'USER.CHANGE_PASS_HINT'
        })}
      </div>
      <div
        className={clsx(classes.message, {
          [classes.messageVisible]:
            isDefined(message.text) || isDefined(message.intlText)
        })}
        style={{ color: messageColors[message.type] }}
      >
        {message.text
          ? message.text
          : intl.formatMessage({
              id: Optional(message.intlText).or('EMPTY')
            })}
      </div>
      <PasswordForm isNotDialog={true} changePass={onChangePass} />
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(UpdatePass);
