const styles = theme => {
  return {
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px'
    },
    transfer: {
      paddingLeft: '180px',
      fontSize: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  };
};

export default styles
