import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';

import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import Optional from '../../../utils/optional';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import UserDetailView from './UserDetailView';
import tableColumns from './tableColumns';
import { doesArrayHasLength } from '../../../utils/isDefined';
import { actions as requestUserWizardReduxActions } from '../../../modals/requestUserWizard/reducers';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import { lockUserAction } from '../actions/lockUser';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import withSettings from '../../../utils/withSettings';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { getUsers } from '../actions/getUsers';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

export function UsersTable({
  users,
  intl,
  clientId,
  isLoading,
  openRequestNewUser,
  setColumns,
  columns,
  lockUserAction,
  dirFields,
  filtersOptions,
  clientName,
  customJobs,
  settings,
  hasClientAccounts,
  getUsers,
  connectClientId
}) {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getUsers(connectClientId, clientId);
    // eslint-disable-next-line
  }, [connectClientId, clientId]);

  useEffect(() => {
    if (refresh) {
      getUsers(connectClientId, clientId);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ clientId, lockUserAction, filtersOptions, settings });
  }, [clientId, lockUserAction, filtersOptions, settings]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'TelephonyUsers-1.2'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'TelephonyUsers');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        },
        {
          icon: () => <AddButton buttonText={'BUTTON.REQUEST_USER'} />,
          onClick: () => openRequestNewUser(dirFields),
          isFreeAction: true,
          hidden:
            !hasClientAccounts ||
            !can(
              'update',
              createSubject('User', { client_id: Number(clientId) })
            )
              ? true
              : false
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, clientId, dirFields, customJobs]
  );

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ clientName, tableName: 'telepoUsers' }),
      filtering,
      header: doesArrayHasLength(users)
    };
  }, [filtering, users, clientName]);

  const detailPanel = useCallback(
    ({ rowData }) => <UserDetailView data={rowData} />,
    []
  );

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'TelephonyUsers-1.2'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, []);

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="TABLE.TELEPHONY_USERS"
          icon={<i className="fas fa-user-friends"></i>}
          isLoading={isLoading}
        />
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={users}
      options={tableOptions}
      detailPanel={detailPanel}
      localization={localization}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ telephonyUsers, auth, selectClient, loader }) => {
  return {
    ...telephonyUsers,
    isLoading: loader.isSpinVisible,
    connectClientId: selectClient.selectedClient?.connect30_domain,
    clientName: selectClient.selectedClient?.name,
    filtersOptions: telephonyUsers.filtersOptions,
    customJobs: auth.customJobs,
    clientId: Optional(selectClient.selectedClient?.id).or(
      auth.defaultClient?.id
    ),
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false)
  };
};

const mapDispatchToProps = {
  openRequestNewUser: requestUserWizardReduxActions.openUserWizard,
  setColumns: reduxActions.setColumns,
  lockUserAction,
  getUsers
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps)
)(UsersTable);
