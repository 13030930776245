import {
  Dialog,
  DialogContent,
  RadioGroup,
  withStyles,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import React from 'react';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';

import DraggableDialog from '../../partials/DraggableDialog';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import { actions as reduxActions } from './reducers';
import { translate } from '../../i18n/I18nProvider';
import styles from './styles';
import ActionsButtons from './components/ActionsButtons';
import changePriority from './actions/changePriority';
import withSettings from '../../utils/withSettings';
import AgentsTable from './components/AgentsTable';
import withUserCheck from '../../utils/withUserCheck';

const EditQueue = ({
  classes,
  open,
  closeEditQueue,
  queue,
  settings,
  changeType,
  distributionType,
  resetAgentsToDefaultLevel,
  agents,
  isAdminUser,
  isCurrentUserSelectedClientUser
}) => {
  const handleSubmit = async () => {
    await changePriority({
      agents,
      distributionType,
      queue,
      closeEditQueue,
      isAdminUser,
      isCurrentUserSelectedClientUser
    });
  };

  const onRadioButtonChange = e => {
    if (distributionType !== e.target.value) {
      if (e.target.value === 'SKILLS') {
        resetAgentsToDefaultLevel();
      }
      changeType(e.target.value);
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditQueue();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditQueue}>
        {translate('EDIT_QUEUE')}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <RadioGroup
          value={distributionType}
          onChange={onRadioButtonChange}
          className={classes.radioButtons}
        >
          <FormControlLabel
            value="PRIORITY"
            control={
              <Radio
                icon={<i className="far fa-circle"></i>}
                checkedIcon={
                  <i
                    className="far fa-dot-circle"
                    style={{ color: settings.colors.primary }}
                  ></i>
                }
              />
            }
            label="Distribute calls by priority"
          />
          <FormControlLabel
            value="SKILLS"
            control={
              <Radio
                icon={<i className="far fa-circle"></i>}
                checkedIcon={
                  <i
                    className={'far fa-dot-circle'}
                    style={{ color: settings.colors.primary }}
                  ></i>
                }
              />
            }
            label="Distribute calls by skill level"
          />
        </RadioGroup>
        <AgentsTable />
        <ActionsButtons
          closeDialog={closeEditQueue}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
const mapStatesToProps = ({ editQueue }) => {
  return {
    ...editQueue
  };
};

const mapDispatchToProps = {
  closeEditQueue: reduxActions.closeEditQueue,
  changeType: reduxActions.changeType,
  resetAgentsToDefaultLevel: reduxActions.resetAgentsToDefaultLevel
};

export default flowRight(
  withSettings,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditQueue);
