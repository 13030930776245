import { isUndefined } from 'lodash';
import { JSONAPI_URLS } from '../consts/endpoints';
import { translate } from '../i18n/I18nProvider';
import { getData } from './http';
import urlCreator from './urlCreator';

export default async function checkAvailability({ data, createError, icc }) {
  if (data.Valid && data.Available) {
    return true;
  }

  if (!data.Valid) {
    return createError({
      message: translate('VALIDATION.NOT_VALID_AVAILABLE')
    });
  }

  const queryParams = {
    filter: { icc: { match: icc } },
    fields: {
      sim_cards: ['icc', 'msisdn']
    }
  };

  if (!data.Available) {
    const { data: simcard } = await getData(
      urlCreator(JSONAPI_URLS.SIM_CARDS, queryParams)
    );

    if (isUndefined(simcard[0]?.msisdn)) {
      return createError({
        message: translate('VALIDATION.NOT_VALID_AVAILABLE')
      });
    } else {
      return createError({
        message: `${translate('VALIDATION.ALREADY_ATTACHED')} ${
          simcard[0]?.msisdn
        }`
      });
    }
  }
}
