import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/infoSection';
import StatusCell from '../../../partials/statusCell/StatusCell';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import findDatalimitOwner from '../../../utils/findDataLimitOwner';

function InfoDatalimitSection({ classes, data, intl }) {
  const { no_limit, block_limit_mb, roaming_disabled, allow_recharge } = data;
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div
          className={clsx(classes.element, classes.bigElement)}
        >{`${intl.formatMessage({
          id: 'EDIT.USER'
        })} : ${[...findDatalimitOwner(data)]
          .map(owner => owner.name)
          .join(', ')}`}</div>
        <div className={classes.elementRow}>
          <div
            className={classes.smallElement}
          >{`${intl.formatMessage({
            id: 'DATA_LIMITS.DATA_LIMIT'
          })} : ${
            no_limit
              ? intl.formatMessage({
                  id: 'DATA_LIMITS.NO_DATALIMIT'
                })
              : `${calculateMbToGb(block_limit_mb, true)} GB`
          }`}</div>
        </div>
      </div>
      <div className={classes.row}></div>
      <div className={classes.row}>
        <div className={classes.elementRow}>
          <div className={classes.element}>{`${intl.formatMessage({
            id: 'DATA_LIMITS.ROAMING_DISABLED'
          })} : `}</div>
          <StatusCell
            isTable={false}
            {...pickOptionsForStatusCell(
              STATUS_BOX_OPTIONS.TRUE_FALSE,
              roaming_disabled
            )}
          />
        </div>
        <div className={clsx(classes.element, classes.elementRow)}>
          <div className={classes.element}>{`${intl.formatMessage({
            id: 'DATA_LIMITS.AUTO_RECHARGE'
          })} : `}</div>
          <StatusCell
            isTable={false}
            {...pickOptionsForStatusCell(
              STATUS_BOX_OPTIONS.TRUE_FALSE,
              allow_recharge
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(InfoDatalimitSection);
