import { withStyles } from '@material-ui/core';
import React from 'react';

import styles from '../styles/quotaSwitch';
import CustomSwitch from '../../../partials/CustomSwitch';

const QuotaSwitch = ({ classes, props }) => {
  const { value, onChange } = props;

  return (
    <div className={classes.root}>
      <CustomSwitch
        checked={value === 1}
        handleChange={e => onChange(e.target.checked ? 1 : 0)}
      />
    </div>
  );
};

export default withStyles(styles)(QuotaSwitch);
