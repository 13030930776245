import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import { actions as reduxActions } from './reducers';
import DataLimitsTable from './components/DataLimitsTable';
import DatalimitMultiedit from '../../modals/datalimitMultiedit/DatalimitMultiedit';
import getCustomJobs from '../../utils/getCustomJobs';

function DataLimits({ resetDataLimits }) {
  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
    return resetDataLimits;
    // eslint-disable-next-line
  }, [resetDataLimits]);

  return (
    <div>
      <DataLimitsTable />
      <DatalimitMultiedit />
    </div>
  );
}

const mapDispatchToProps = {
  resetDataLimits: reduxActions.resetDataLimits
};

export default flowRight(connect(null, mapDispatchToProps))(DataLimits);
