import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';

import { actions as reduxActions } from './reducers';
import styles, { customSelectStyles } from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import Group from './components/Group';
import Optional from '../../utils/optional';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import { fetchDetails } from './actions/getDetails';
import { createInvoiceGroupingOptions } from '../../consts/selectsOptions';

function InvoicesDetails({
  classes,
  open,
  intl,
  closeInvoiceDetails,
  details,
  invoiceNumber,
  account,
  invoiceAccount,
  clientId,
  fetchDetails,
  resetDetails,
  isLoading
}) {
  const selectOptions = createInvoiceGroupingOptions();
  const [invoiceGrouping, setInvoiceGrouping] = useState(selectOptions[0]);

  const onGroupingChange = useCallback(
    selected => {
      if (selected.value !== invoiceGrouping.value) {
        resetDetails();
        fetchDetails({
          clientId,
          invoiceId: invoiceNumber,
          account: account,
          invoiceAccount,
          invoiceGrouping: selected.value
        });
        setInvoiceGrouping(selected);
      }
    },
    // eslint-disable-next-line
    [invoiceGrouping.value, invoiceNumber, account]
  );

  const onClose = useCallback(() => {
    closeInvoiceDetails();
    setInvoiceGrouping(selectOptions[0]);
    // eslint-disable-next-line
  }, [selectOptions]);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={onClose}
      fullWidth
    >
      <CustomDialogTitle
        onCloseClick={onClose}
        customClasses={{ root: classes.titleRoot }}
      >
        {`${intl.formatMessage({
          id: 'SPECIFICATION'
        })} - ${intl.formatMessage({
          id: 'INVOICES.INVOICE_NO'
        })} ${invoiceNumber} - ${
          account.value === 'all' ? invoiceAccount : account.label
        }`}
      </CustomDialogTitle>
      <div className={classes.selectContainer}>
        <div className={classes.selectLabel}>
          {intl.formatMessage({
            id: 'INVOICES.INVOICE_GROUPING'
          })}
        </div>
        <Select
          styles={reactSelectDefaultStyles}
          customStyles={customSelectStyles}
          options={selectOptions}
          value={invoiceGrouping}
          onChange={onGroupingChange}
        />
      </div>
      <DialogContent className={classes.dialogContent}>
        {isLoading && (
          <div className={classes.tableOverlay}>
            <CircularProgress />
          </div>
        )}
        {Optional(details)
          .map(group => (
            <Group
              isExpanded
              invoiceDetails={{ invoiceNumber }}
              group={group}
              invoiceGrouping={invoiceGrouping}
            />
          ))
          .or('noChargeLinesAvailable')}
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ invoiceDetails, selectClient, loader }) => {
  return {
    ...invoiceDetails,
    clientId: selectClient.selectedClient.id,
    isLoading: loader.isLinearVisible
  };
};

const mapDispatchToProps = {
  closeInvoiceDetails: reduxActions.closeInvoiceDetails,
  resetDetails: reduxActions.resetDetails,
  fetchDetails
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(InvoicesDetails);
