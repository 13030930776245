import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { isEmptyArray } from 'formik';
import moment from 'moment';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles, { customTagSelectStyles } from '../styles/vacations';
import { isArray } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import Optional from '../../../utils/optional';
import CustomCheckbox from './CustomCheckbox';

const Vacations = ({
  classes,
  arrayHelpers: { push },
  options,
  setFieldValue,
  tagsOptions
}) => {
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const mappedOptions = options.map(option => {
      return { ...option, selected: false };
    });
    setSelected(mappedOptions);
  }, [options]);

  const onChange = selectedOptions => setTags(selectedOptions);

  const availableOptions = useMemo(
    () => [...mapOptions({ options, tags }).filter(el => el.haveTags)],
    // eslint-disable-next-line
    [options, tags]
  );

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      columnsButton: false,
      showTitle: false,
      paging: false,
      maxBodyHeight: '300px',
      minBodyHeight: '300px',
      rowStyle: rowData => ({
        backgroundColor: Optional(selected.find(el => el.id === rowData.id))
          .map(s => (s.selected ? '#F3F3F3EE' : '#FFF'))
          .or('#FFF'),
        fontSize: '13px'
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        fontSize: '13px'
      }
    };
  }, [selected]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, []);

  const onSelectionChange = useCallback(
    (e, id) => {
      const mappedSelected = selected.map(el => {
        if (el.id !== id) {
          return el;
        }

        return { ...el, selected: e.target.checked };
      });

      setFieldValue('vacations', []);

      const dataToPush = mappedSelected.filter(el => el.selected === true);

      dataToPush.forEach(el => push(el));

      setSelected(mappedSelected);
    },
    [selected, setSelected, setFieldValue, push]
  );

  const onAllSelectionChange = useCallback(
    e => {
      if (isEmptyArray(tags)) {
        const mappedSelected = selected.map(el => {
          return { ...el, selected: e.target.checked };
        });

        setFieldValue('vacations', []);

        mappedSelected.forEach(el => push(el));

        setSelected(mappedSelected);
      } else {
        const availableOptionsIds = availableOptions.map(option => option.id);

        const mappedSelected = selected.map(el => {
          const shouldUpdate =
            availableOptionsIds.findIndex(optionId => optionId === el.id) !==
            -1;

          if (!shouldUpdate) {
            return el;
          }

          return { ...el, selected: e.target.checked };
        });

        setFieldValue('vacations', []);

        const dataToPush = mappedSelected.filter(el => el.selected === true);

        dataToPush.forEach(el => push(el));

        setSelected(mappedSelected);
      }
    },
    [selected, setSelected, setFieldValue, push, tags, availableOptions]
  );

  const columns = useMemo(() => {
    let elements = [
      {
        title: (
          <CustomCheckbox
            checked={checkIfAllSelected({ selected, availableOptions })}
            onChange={e => onAllSelectionChange(e)}
          />
        ),
        render: rowData => (
          <CustomCheckbox
            checked={Optional(selected.find(el => el.id === rowData.id))
              .map(el => el.selected)
              .or(false)}
            onChange={e => onSelectionChange(e, rowData.id)}
          />
        ),
        sorting: false,
        width: '50px'
      },
      {
        title: translate('NAME'),
        field: 'holiday'
      },
      {
        title: translate('SCHEDULES.NEXT_OCCURRENCE'),
        render: rowData => moment(rowData.next_occurrence).format('YYYY-MM-DD'),
        field: 'next_occurrence'
      },
      {
        title: translate('DATE'),
        field: 'shortDate'
      },
      {
        title: translate('TABLE.TYPE'),
        field: 'holiday_type'
      }
    ];

    return elements.map(el => {
      return {
        ...el,
        cellStyle: { padding: '0' },
        headerStyle: { padding: '0', fontSize: '13px' }
      };
    });
  }, [selected, onSelectionChange, onAllSelectionChange, availableOptions]);

  return (
    <div>
      <div className={classes.tagsContainer}>
        <Select
          styles={reactSelectDefaultStyles}
          customStyles={customTagSelectStyles}
          isSearchable
          options={tagsOptions}
          value={tags}
          onChange={onChange}
          placeholder={translate('SCHEDULES.SELECT_CATEGORY')}
          isMulti
          isRtl
        />
      </div>

      <MaterialTable
        columns={columns}
        data={availableOptions}
        options={tableOptions}
        localization={localization}
        components={{
          Toolbar: props => (
            <MTableToolbar
              classes={{
                root: classes.toolbarRoot
              }}
              {...props}
            />
          )
        }}
      />
      <div className={classes.line}></div>
      <div>{mapeSlectedText({ selected, availableOptions })}</div>
    </div>
  );
};
const mapStatesToProps = ({ addingSchedules }) => {
  return {
    tagsOptions: addingSchedules.tags,
    options: addingSchedules.vacations
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(Vacations);

const mapOptions = ({ options = [], tags = [] }) => {
  return options.map(option => {
    const tagsArray = tags.map(tag => tag.value);
    const haveTags = haveCommonItems(option.tags, tagsArray);

    return {
      ...option,
      haveTags
    };
  });
};

const haveCommonItems = (optionTags = [], tags = []) => {
  if (isEmptyArray(tags)) {
    return true;
  }
  if (!isArray(optionTags) || !isArray(tags)) {
    return false;
  }
  return optionTags.some(item => tags.includes(item));
};

const checkIfAllSelected = ({ selected = [], availableOptions = [] }) => {
  if (isEmptyArray(availableOptions)) {
    return false;
  }
  const availableOptionsIds = availableOptions.map(option => option.id);

  const isSelected = selected.filter(
    el =>
      availableOptionsIds.findIndex(optionId => optionId === el.id) !== -1 &&
      el.selected === true
  );

  return isSelected.length === availableOptions.length;
};

const mapeSlectedText = ({ selected = [], availableOptions = [] }) => {
  const selectedCount = selected.filter(el => el.selected === true).length;

  const availableOptionsIds = availableOptions.map(option => option.id);

  const availableSelectedCount = selected.filter(
    el =>
      availableOptionsIds.findIndex(optionId => optionId === el.id) !== -1 &&
      el.selected === true
  ).length;

  if (availableSelectedCount === selectedCount) {
    return translate('EDIT_SCHEDULE.SELECTED_DAYS_TEXT_SHORT', {
      count: selectedCount
    });
  } else {
    return translate('EDIT_SCHEDULE.SELECTED_DAYS_TEXT', {
      count: selectedCount,
      otherCount: selectedCount - availableSelectedCount
    });
  }
};
