import uniqBy from 'lodash.uniqby';
import { isDefined } from './isDefined';
import Optional from './optional';

export default function findDatalimitNetwork(datalimit = {}) {
  return Optional(datalimit?.cell_numbers)
    .map(cellNumbers => {
      return uniqBy(
        cellNumbers
          .map(cellNumber => {
            return Optional(cellNumber.sim_card?.mobile_network).or(null);
          })
          .filter(brand => isDefined(brand)),
        'brand'
      ).map(el => el.brand)[0];
    })
    .or(void 0);
}
