import { URLS } from '../../../consts/endpoints';
import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { mapDataForEdit, mapResponse } from './mapDataForRequest';

export default async function editCustomJob({ customJob, initialValues }) {
  const mappedData = mapDataForEdit({ customJob, initialValues });

  try {
    const response = await updateData({
      url: `${URLS.CUSTOM_JOBS}/${customJob.id}`,
      data: { data: { ...mappedData } }
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.CUSTOM_JOB_UPDATED',
        messageType: 'success'
      });
    }

    return {
      isSuccess: true,
      responseJob: mapResponse(response.data, customJob)
    };
  } catch {
    return { isSuccess: false };
  }
}
