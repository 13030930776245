import XMLParser from 'react-xml-parser';
import Optional from '../../../utils/optional';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';

export default async function eventListener({
  adminId,
  setPresence,
  activities
}) {
  try {
    const presenceToken = await getData(
      `${OUTSIDE_API.USERS}/${adminId}/presence/subscription`
    );

    const sse = new EventSource(
      `${presenceToken.url}?t=${presenceToken.token}`
    );

    sse.onmessage = e => setPresence(getRealtimeData(e.data, activities));
    sse.onerror = error => {
      console.log(error);
      sse.close();
    };
    return () => {
      sse.close();
    };
  } catch {}
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);

  const updatedActivityAttributes = Optional(
    xml.getElementsByTagName('rpid:activities')[0]
  )
    .map(activities => activities.attributes)
    .or(void 0);

  const updatedActivityId = Optional(
    xml.getElementsByTagName('telepo:activity')[0]
  )
    .map(data => data.value)
    .or(void 0);

  return {
    updatedActivityId,
    updatedActivityAttributes
  };
}
