import { withStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import flowRight from 'lodash.flowright';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/addressStep';
import { actions as reduxActions } from '../reducers';
import validateSchema from '../actions/validateSchema';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import CustomAddressSelect from '../../../partials/customAddressSelect/CustomAddressSelect';
import InputRow from '../../../partials/dataRows/InputRow';

function AddressStep({
  handleNext,
  intl,
  classes,
  setSimcardData,
  resetOrderSimcard,
  secondStepData
}) {
  const onSubmit = useCallback(
    values => {
      setSimcardData(values, 'secondStep');
      handleNext();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Formik
      initialValues={secondStepData}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl).addressStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = { errors, values, handleBlur, handleChange };

        return (
          <Form onSubmit={handleSubmit} className={classes.root}>
            <div className={classes.sectionContainer}>
              <InputRow
                label="ORDER_SIMCARD.NAME_COMPANY"
                name="name"
                {...fieldProps}
              />
              <InputRow labelText="Att." name="att" {...fieldProps} />
              <CustomAddressSelect
                selectName="shipping_address"
                inputName="shipping_address"
                fieldProps={fieldProps}
                setFieldValue={setFieldValue}
                splitLines={true}
                additionalValues={secondStepData.additionalValues}
                maxRows={3}
              />
            </div>

            <div className={classes.footer}>
              <SecondaryButton onClick={resetOrderSimcard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
              <PrimaryButton onClick={handleSubmit}>
                {intl.formatMessage({
                  id: 'BUTTON.NEXT'
                })}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({
  orderNewSimcard: { simcardData, addressesOptions }
}) => {
  return {
    secondStepData: simcardData.secondStep,
    addressesOptions
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddressStep);
