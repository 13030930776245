export default {
  root: {
    display: 'flex',
    width: '200px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
    backgroundColor: '#fff',
    fontWeight: 500,
    fontSize: '13px',
    margin: '5px',
    color: '#595D6E'
  },
  label: { display: 'flex', alignItems: 'center' },
  text: {
    width: '200px',
    padding: '5px',
    margin: '0 10px'
  },
  editIcon: { fontSize: '10px', cursor: 'pointer', margin: '0 5px' }
};
