const styles = theme => {
  return {
    root: {
      marginTop: '10px',
      padding: '5px 0',
      display: 'flex'
    },
    leftColumn: {
      minWidth: '550px',
      width: '46vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: '10px',
      backgroundColor: '#FFF'
    },
    rightColumn: {
      minWidth: '500px',
      width: '46vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundColor: '#FFF'
    }
  };
};

export default styles;

export const customStyles = {
  textfield: {
    root: {
      width: 'unset',
      paddingLeft: '10px'
    },
    textfield: { width: '300px' },
    label: {
      fontWeight: 600
    }
  }
};
