import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from '../../utils/isDefined';

import styles from './styles';

function UserPhoto({
  classes,
  clientDomain,
  mitelToken,
  baseUrl,
  id,
  initials,
  customClasses = {},
  customStyles = {},
  defaultPhotoSize = 200
}) {
  const [isError, setIsError] = useState(false);

  const onError = useCallback(e => {
    return setIsError(true);
  }, []);

  if (isUndefined(mitelToken)) {
    return setIsError(true);
  }
  return (
    <div className={customClasses.root} style={customStyles.root}>
      <div
        className={clsx(classes.photo, customClasses.photo)}
        style={customStyles.photo}
      >
        {isError ? (
          <div
            className={(classes.initials, customClasses.initials)}
            style={customStyles.initials}
          >
            {initials}
          </div>
        ) : (
          <img
            className={clsx(classes.photo, customClasses.photo)}
            style={customStyles.photo}
            alt={initials}
            onError={onError}
            src={`${baseUrl}/api/contacts/v1/info/${clientDomain}/${id}/image?t=${mitelToken}&prefWidth=${defaultPhotoSize}&prefHeight=${defaultPhotoSize}`}
          ></img>
        )}
      </div>
    </div>
  );
}
const mapStatesToProps = ({ auth }) => {
  return {
    clientDomain: auth.defaultClient.connect30_domain,
    mitelToken: auth.user.mitel_token,
    baseUrl: auth.user.mitel_profile_link
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(UserPhoto);
