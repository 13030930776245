import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, RESET_PASS } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export function resetPassRequest(username, fromDialog) {
  return {
    type: sagaActions[RESET_PASS],
    username,
    fromDialog
  };
}

export function* resetPass({ username, fromDialog = true }) {
  try {
    const response = yield call(postData, {
      url: URLS.PASS,
      errorsToPass: [RESPONSE_CODES.UNPROCESSABLE_ENTITY],
      data: {
        user: {
          login: username.trim()
        }
      }
    });

    if (response.status === RESPONSE_CODES.CREATED) {
      fromDialog
        ? yield put(reduxActions.setMessage('success'))
        : yield call(showSnackbar, {
            intlTextKey: 'FORGET_PASS.SUCCESS',
            messageType: 'success'
          });
    }
  } catch {
    yield put(reduxActions.setMessage('error'));
  }
}

function* watchResetPass() {
  yield takeLatest(sagaActions[RESET_PASS], resetPass);
}

export default watchResetPass();
