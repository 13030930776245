import { withStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv6';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/schemas';
import SchemaEditor from './SchemaEditor';
import CustomArrayFieldTemplate from '../../../partials/customForm/components/CustomArrayFieldTemplate';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';

function Schemas({
  classes,
  schema,
  uiSchema,
  setValue,
  setSchemaError,
  jobSchema,
  jobUiSchema
}) {
  const [formData, setFormData] = useState({});

  const setIsError = useCallback(
    isError => (isError ? setSchemaError(true) : setSchemaError(false)),
    [setSchemaError]
  );

  const onChange = useCallback(
    (value, type) => {
      return setValue(type, value);
    },
    [setValue]
  );
  
  return (
    <div className={classes.root}>
      <div className={classes.schemasContainer}>
        <SchemaEditor
          onSchemaChange={value => onChange(value, 'schema')}
          template={jobSchema}
          setIsError={setIsError}
          name="Form schema"
        />
        <SchemaEditor
          onSchemaChange={value => onChange(value, 'uiSchema')}
          template={jobUiSchema}
          setIsError={setIsError}
          name="UISchema"
        />
      </div>
      <div className={classes.form}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => setValue('schema', schema)}
        >
          <Form
            uiSchema={uiSchema}
            schema={schema}
            formData={formData}
            validator={validator}
            templates={{
              ArrayFieldTemplate: CustomArrayFieldTemplate
            }}
            onChange={e => setFormData(e.formData)}
          >
            <></>
          </Form>
        </ErrorBoundary>
      </div>
    </div>
  );
}
const mapStatesToProps = ({ editCustomJob }) => {
  return {
    jobSchema: Optional(editCustomJob.customJob?.form_schema).or({}),
    jobUiSchema: Optional(editCustomJob.customJob?.ui_schema).or({}),
    schema: Optional(editCustomJob.schema).or({}),
    uiSchema: Optional(editCustomJob.uiSchema).or({})
  };
};

const mapDispatchToProps = {
  setValue: reduxActions.setValue
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Schemas);

function ErrorFallback({ error }) {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>Please reload the page.</p>
    </div>
  );
}
