import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { getUsers } from './actions/getUsers';
import { actions as reduxActions } from './reducers';
import UsersTable from './components/UsersTable';
import getIdFromUrl from '../../utils/getIdfromUrl';
import RequestNewUser from '../../modals/requestNewUser/RequestNewUser';
import withUserCheck from '../../utils/withUserCheck';
import { isDefined } from '../../utils/isDefined';
import UsersTableBE from './components/UsersTableBE';
import getCustomJobs from '../../utils/getCustomJobs';

function Users({ location, resetUsers }) {
  const client = useMemo(() => getIdFromUrl(location), [location]);

  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
    return resetUsers;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDefined(client) ? (
        <UsersTable client={client} />
      ) : (
        <UsersTableBE client={client} />
      )}
      <RequestNewUser />
    </>
  );
}

const mapDispatchToProps = {
  getUsers,
  resetUsers: reduxActions.resetUsers
};

export default flowRight(
  withRouter,
  withUserCheck,
  connect(null, mapDispatchToProps)
)(Users);
