import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_HISTORY = 'OPEN_HISTORY';
const SET_HISTORY = 'SET_HISTORY';
const RESET_HISTORY = 'RESET_HISTORY';
const SET_SHOW_ALL_VALES = 'SET_SHOW_ALL_VALES';

export const actionTypes = createReduxActionTypes('HISTORY', [
  OPEN_HISTORY,
  SET_HISTORY,
  RESET_HISTORY,
  SET_SHOW_ALL_VALES
]);

const DEFAULT_STATE = {
  open: false,
  itemType: null,
  id: null,
  history: null,
  showAllValues: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_HISTORY]: {
      const {
        itemType,
        id,
        historyObject,
        titleText,
        itemTypeKey,
        showStandardValues,
        showAllValuesCheckbox
      } = action;
      return {
        ...state,
        itemType,
        id,
        historyObject,
        titleText,
        itemTypeKey,
        showStandardValues,
        showAllValuesCheckbox,
        open: true
      };
    }
    case actionTypes[SET_HISTORY]: {
      const { history } = action;
      return {
        ...state,
        history
      };
    }
    case actionTypes[SET_SHOW_ALL_VALES]: {
      return {
        ...state,
        showAllValues: !state.showAllValues
      };
    }
    case actionTypes[RESET_HISTORY]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openShowHistory: ({
    itemType,
    id,
    historyObject,
    titleText,
    itemTypeKey,
    showStandardValues,
    showAllValuesCheckbox
  }) => ({
    type: actionTypes[OPEN_HISTORY],
    itemType,
    id,
    historyObject,
    titleText,
    itemTypeKey,
    showStandardValues,
    showAllValuesCheckbox
  }),
  setHistory: history => ({
    type: actionTypes[SET_HISTORY],
    history
  }),
  setShowAllValues: () => ({
    type: actionTypes[SET_SHOW_ALL_VALES]
  }),
  resetShowHistory: () => ({ type: actionTypes[RESET_HISTORY] })
};
