import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_ADDRESSES = 'GET_ADDRESSES';
export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

export const actions = createSagaActionTypes('ADDRESSES', [
  GET_ADDRESSES,
  ADD_NEW_ADDRESS,
  DELETE_ADDRESS
]);
