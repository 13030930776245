const styles = theme => ({
  root: {
    width: '110px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'calc((100vw - 20px) / 3)'
    }
  },
  label: {
    fontWeight: 700
  },
  limitContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  limitValue: {
    fontWeight: 700,
    fontSize: '28px'
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chartInnerText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    fontWeight: 600,
    fontSize: '18px'
  },
  percentageContainer: { display: 'flex', alignItems: 'center' },
  percentageText: {
    fontSize: '10px',
    lineHeight: 0,
    color: '#f00'
  },
  icon: {
    fontSize: '8px',
    lineHeight: 0,
    color: '#f00',
    marginRight: '2px'
  },
  infoIcon: {
    fontSize: '10px',
    lineHeight: 0,
    color: '#000',
    marginLeft: '5px'
  }
});

export default styles;
