import React from 'react';
import withSettings from '../../utils/withSettings';

const CustomIcon = ({
  props,
  ref,
  icon,
  size,
  settings,
  settingsColor = false,
  customStyles = {}
}) => {
  return (
    <div style={{ height: '30px', ...customStyles }}>
      <i
        className={icon}
        style={{
          fontSize: size,
          color: settingsColor ? settings.colors.links : ''
        }}
        {...props}
        ref={ref}
      />
    </div>
  );
};

export default withSettings(CustomIcon);
