import * as yup from 'yup';
import checkICC from '../../../utils/checkICC';
import RESPONSE_CODES from '../../../consts/responseCodes';
import {
  isDefined,
  isStringDefined,
  isUndefined
} from '../../../utils/isDefined';
import { PROVIDERS_NUMBERS } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';
import { translate } from '../../../i18n/I18nProvider';
import checkAvailability from '../../../utils/checkAvailability';

export default function(intl, formNetwork) {
  return {
    infoStep: yup.object().shape({
      copy_from_user: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      email: yup
        .string()
        .email(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.INVALID_FIELD'
          })
        )
        .required(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          })
        ),
      first_name: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      last_name: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      mobile_number: yup
        .string()
        .when(['internal_number', 'isNewNumber', 'landline_number'], {
          is: (internal_number, isNewNumber, landline_number) =>
            !isNewNumber &&
            isUndefined(internal_number) &&
            isUndefined(landline_number),
          then: yup
            .string()
            .matches(
              /^[0-9]+$/,
              intl.formatMessage({
                id: 'VALIDATION.ONLY_DIGITS'
              })
            )
            .min(
              8,
              `${intl.formatMessage({
                id: 'VALIDATION.TOO_SHORT_NUMBER'
              })}
          ${intl.formatMessage({
            id: 'VALIDATION.DIGITS_LENGTH'
          })} 8`
            )
            .max(
              8,
              `${intl.formatMessage({
                id: 'VALIDATION.TOO_LONG_NUMBER'
              })}
          ${intl.formatMessage({
            id: 'VALIDATION.DIGITS_LENGTH'
          })} 8`
            )
            .required(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              })
            )
        })
    }),
    portStep: yup.object().shape({
      portMobileNumber: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      current_sim_card_number: yup
        .string()
        .matches(
          /^[0-9]+$/,
          intl.formatMessage({
            id: 'VALIDATION.ONLY_DIGITS'
          })
        )
        .min(
          19,
          `${intl.formatMessage({
            id: 'VALIDATION.TOO_SHORT_NUMBER'
          })}
        ${intl.formatMessage({
          id: 'VALIDATION.DIGITS_LENGTH'
        })} 19-20`
        )
        .max(
          20,
          `${intl.formatMessage({
            id: 'VALIDATION.TOO_LONG_NUMBER'
          })}
        ${intl.formatMessage({
          id: 'VALIDATION.DIGITS_LENGTH'
        })} 19-20`
        )
        .test(null, 'number is not valid', async function(value) {
          if (isUndefined(value)) {
            return true;
          }

          const { status, data } = await checkICC(value);
          return [RESPONSE_CODES.SUCCESS].findIndex(code => code === status) !==
            -1
            ? data.Valid
              ? true
              : this.createError({
                  message: translate(
                    `VALIDATE.${data.Message.split(' ')
                      .join('_')
                      .toUpperCase()}`
                  )
                })
            : true;
        }),
      recipient_email: yup
        .string()
        .email(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.INVALID_FIELD'
          })
        )
        .required(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          })
        ),
      other_recipient_email: yup.string().email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_FIELD'
        })
      )
    }),
    subscriptionStep: yup.object().shape({
      mobile_subscription: yup
        .string()
        .when(['isMobileNumberProvided', 'isNewNumber'], {
          is: (isMobileNumberProvided, isNewNumber) =>
            isMobileNumberProvided || isNewNumber,
          then: yup.string().required(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            })
          )
        }),
      network: yup.string().when(['isMobileNumberProvided', 'isNewNumber'], {
        is: (isMobileNumberProvided, isNewNumber) =>
          isMobileNumberProvided || isNewNumber,
        then: yup.string().required(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          })
        )
      }),
      mobile_sim_card_number: yup.string().when('have_mobile_sim_card', {
        is: true,
        then: yup
          .string()
          .required(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            })
          )
          .matches(
            /^[0-9]+$/,
            intl.formatMessage({
              id: 'VALIDATION.ONLY_DIGITS'
            })
          )
          .min(
            isDefined(checkFormNetwork(formNetwork)) ? 13 : 19,
            `${intl.formatMessage({
              id: 'VALIDATION.TOO_SHORT_NUMBER'
            })}
            ${intl.formatMessage({
              id: 'VALIDATION.DIGITS_LENGTH'
            })} 19-20`
          )
          .max(
            isDefined(checkFormNetwork(formNetwork)) ? 14 : 20,
            `${intl.formatMessage({
              id: 'VALIDATION.TOO_LONG_NUMBER'
            })}
            ${intl.formatMessage({
              id: 'VALIDATION.DIGITS_LENGTH'
            })} 19-20`
          )
          .test(null, 'number is not valid', async function(value) {
            if (isStringDefined(value)) {
              const { status, data } = await checkICC(
                findNumberPrefix(formNetwork, value)
              );
              return [RESPONSE_CODES.SUCCESS].findIndex(
                code => code === status
              ) !== -1
                ? data.Available
                  ? true
                  : await checkAvailability({
                      data,
                      createError: this.createError,
                      icc: value
                    })
                : true;
            }
          })
      }),
      data_sharing_sim_card_number: yup
        .string()
        .when('have_data_sharing_sim_card', {
          is: true,
          then: yup
            .string()
            .required(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              })
            )
            .matches(
              /^[0-9]+$/,
              intl.formatMessage({
                id: 'VALIDATION.ONLY_DIGITS'
              })
            )
            .min(
              isDefined(checkFormNetwork(formNetwork)) ? 13 : 19,
              `${intl.formatMessage({
                id: 'VALIDATION.TOO_SHORT_NUMBER'
              })}
              ${intl.formatMessage({
                id: 'VALIDATION.DIGITS_LENGTH'
              })} 19-20`
            )
            .max(
              isDefined(checkFormNetwork(formNetwork)) ? 14 : 20,
              `${intl.formatMessage({
                id: 'VALIDATION.TOO_LONG_NUMBER'
              })}
              ${intl.formatMessage({
                id: 'VALIDATION.DIGITS_LENGTH'
              })} 19-20`
            )
            .test(null, 'number is not valid', async function(value) {
              if (isStringDefined(value)) {
                const { status, data } = await checkICC(
                  findNumberPrefix(formNetwork, value)
                );
                return [RESPONSE_CODES.SUCCESS].findIndex(
                  code => code === status
                ) !== -1
                  ? data.Available
                    ? true
                    : this.createError({
                        message: translate(
                          `VALIDATE.${data.Message.split(' ')
                            .join('_')
                            .toUpperCase()}`
                        )
                      })
                  : true;
              }
            })
        })
    }),
    addressStep: yup.object().shape({
      shipping_address: yup
        .string()
        .when(
          [
            'have_mobile_sim_card',
            'have_data_sharing_sim_card',
            'isMobileNumberProvided',
            'isNewNumber'
          ],
          {
            is: (
              have_mobile_sim_card,
              have_data_sharing_sim_card,
              isMobileNumberProvided,
              isNewNumber
            ) =>
              !have_mobile_sim_card &&
              !have_data_sharing_sim_card &&
              (isMobileNumberProvided || isNewNumber),
            then: yup.string().required(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              })
            )
          }
        ),
      activation_date: yup
        .string()
        .when(['isMovingDateProvided', 'activationAsap'], {
          is: (isMovingDateProvided, activationAsap) =>
            !isMovingDateProvided && !activationAsap,
          then: yup.string().required(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.REQUIRED_FIELD'
            })
          )
        })
        .nullable(),
      name: yup
        .string()
        .when(
          [
            'have_mobile_sim_card',
            'have_data_sharing_sim_card',
            'isMobileNumberProvided',
            'isNewNumber'
          ],
          {
            is: (
              have_mobile_sim_card,
              have_data_sharing_sim_card,
              isMobileNumberProvided,
              isNewNumber
            ) =>
              !have_mobile_sim_card &&
              !have_data_sharing_sim_card &&
              (isMobileNumberProvided || isNewNumber),
            then: yup.string().required(
              intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              })
            )
          }
        )
    })
  };
}

function checkFormNetwork(network) {
  return Optional(PROVIDERS_NUMBERS[network]).or(null);
}

function findNumberPrefix(network, value = '') {
  const networkPrefix = checkFormNetwork(network);
  return isDefined(networkPrefix) ? `${networkPrefix}${value}` : value;
}
