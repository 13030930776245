import { isStringDefined, isUndefined } from './isDefined';

export default function createInitials(firstName, lastName) {
  const firstletter = findFirstLetter(firstName);
  const secondLetter = findFirstLetter(lastName);

  return firstletter + secondLetter;
}

function findFirstLetter(string) {
  if (isUndefined(string) || !isStringDefined(string)) {
    return '';
  }
  return string[0].toUpperCase();
}
