const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      padding: '10px 50px 10px 30px',
      justifyContent: 'space-between'
    },
    value: {
      fontWeight: 500,
      width: '120px',
      display: 'flex',
      alignItems: 'center'
    },
    valueOverflow: {
      overflow: 'auto'
    },
    hashed: { cursor: 'pointer' },
    dense: {
      padding: '3px 40px 3px 30px'
    },
    mobile: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    mobileFlexColumn: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    mobileRightAllignValue: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end'
      }
    },

    infoIcon: {
      fontSize: '13px',
      marginLeft: '3px'
    },
    label: { display: 'flex', alignItems: 'center' },
    link: { '&:hover': { filter: 'brightness(1.5)' } }
  };
};

export default styles;
