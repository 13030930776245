import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isDefined } from '../../../utils/isDefined';

export default function(isMultiedit) {
  return yup.object().shape({
    care_of_name: yup
      .string()
      .nullable()
      .test(null, translate('PHONEBOOK.MAX_CO'), value => {
        return isDefined(value) && value.length > 34 ? false : true;
      }),
    address: !isMultiedit
      ? yup.string().when(['use_default_address'], {
          is: use_default_address => !use_default_address,
          then: yup
            .string()
            .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
        })
      : null
  });
}
