import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function(object) {
  const objectKeys = Object.keys(object);

  const yupObject = objectKeys.reduce((obj, key) => {
    return {
      ...obj,
      [key]: yup.object().shape({
        forwardNumber: yup.string().when('isForwarded', {
          is: true,
          then: yup
            .string()
            .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
            .matches(/^[0-9.+]+$/, translate('VALIDATION.ONLY_DIGITS'))
        })
      })
    };
  }, {});
  return yup.object().shape({ ...yupObject });
}
