import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_RANGE } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import { actions as numberRangereduxActions } from '../../../pages/externalNumberRange/reducers';

import showSnackbar from '../../../utils/showSnackbar';
import mapDataToUpdate from './mapDataToUpdate';
import { fetchExternalNoRanges } from '../../../pages/externalNumberRange/actions/getExternalNoRanges';
import mapDataToCreate from './mapDataToCreate';
import { submitNumber } from '../../../pages/checkNumber/actions/submitNumber';
import { isDefined } from '../../../utils/isDefined';
import { actions as phonebookReduxActions } from '../../../pages/phoneBookRegistration/reducers';

export function updateOnSubmit({
  range,
  oldAddress,
  resetValues,
  isEdit,
  lookupNumber,
  fromRightPanel,
  fromLookup,
  rangeId,
  isBeTable,
  fromPhoneBook
}) {
  return {
    type: sagaActions[UPDATE_RANGE],
    range,
    oldAddress,
    resetValues,
    isEdit,
    lookupNumber,
    fromRightPanel,
    fromLookup,
    rangeId,
    isBeTable,
    fromPhoneBook
  };
}

export function* updateRange({
  range,
  oldAddress,
  resetValues,
  isEdit,
  lookupNumber,
  fromRightPanel,
  fromLookup,
  rangeId,
  isBeTable,
  fromPhoneBook
}) {
  const mappedData = isEdit
    ? mapDataToUpdate({ range, oldAddress })
    : mapDataToCreate({ range, clientId: resetValues.id });

  try {
    const response = isEdit
      ? yield call(updateData, {
          url: `${URLS.EXTERNAL_NO_RANGES}/${range.id}`,
          data: {
            data: mappedData
          }
        })
      : yield call(postData, {
          url: URLS.EXTERNAL_NO_RANGES,
          data: mappedData
        });
    if (
      response.status === RESPONSE_CODES.SUCCESS ||
      response.status === RESPONSE_CODES.CREATED
    ) {
      if (response.status === RESPONSE_CODES.SUCCESS) {
        yield call(showSnackbar, {
          intlTextKey: 'MESSAGE.UPDATE.RANGES',
          messageType: 'success'
        });
      }

      if (response.status === RESPONSE_CODES.CREATED) {
        yield call(showSnackbar, {
          intlTextKey: 'MESSAGE.ADD.RANGE',
          messageType: 'success'
        });
      }

      if (fromLookup) {
        return yield call(submitNumber, {
          number: lookupNumber,
          fromRightPanel
        });
      }

      if (fromPhoneBook) {
        return yield put(phonebookReduxActions.setRefresh(true));
      }

      if (isDefined(rangeId)) {
        return;
      }

      isBeTable
        ? yield put(numberRangereduxActions.setRefresh(true))
        : yield call(fetchExternalNoRanges, { ...resetValues });
    }
  } finally {
    yield put(reduxActions.closeEditRange());
  }
}

function* watchUpdateRange() {
  yield takeLatest(sagaActions[UPDATE_RANGE], updateRange);
}

export default watchUpdateRange();
