import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';

const ExpandBtn = ({
  title = '',
  onClick = () => {},
  expanded = false,
  className = '',
  IconButtonProps = {},
  isLoading = false
}) => {
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <IconButton
      title={title}
      className={className}
      onClick={onClick}
      {...IconButtonProps}
    >
      {!expanded ? (
        <i className="fas fa-chevron-right"></i>
      ) : (
        <i className="fas fa-chevron-down"></i>
      )}
    </IconButton>
  );
};

export default ExpandBtn;
