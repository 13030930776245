export default {
  title: {
    '& i': {
      fontSize: '30px',
      color: '#8589A1'
    },
    '& span': {
      fontSize: '18px',
      color: '#595D6E',
      padding: '0 20px'
    },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    },
    actionButtons: { maxWidth: '30px' }
  }
};
