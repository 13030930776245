import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';

export default async function getSchema({
  templateName,
  setSchema,
  setUiSchema,
  clientId
}) {
  let schema = {};
  let uiSchema = {};
  try {
    const response = await getData(
      `${URLS.CONFIG_TEMPLATES}/${templateName}?client_id=${clientId}`
    );
    schema = Optional(response.config).or({});
    uiSchema = Optional(response.ui_schema).or({});
  } catch {
  } finally {
    setSchema(schema);
    setUiSchema(uiSchema);
  }
}
