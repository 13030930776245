const styles = theme => {
  return {
    root: {
      display: 'flex',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      width: '100%',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        flexDirection: 'column'
      }
    },
    nameContainer: { display: 'flex', padding: '5px 0' },
    problemsContainer: { display: 'flex', flexDirection: 'column' },
    label: {
      marginRight: '20px'
    },
    problemsRoot: {
      width: '280px',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        width: '100%'
      }
    },
    productRoot: {
      width: '380px',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        width: '100%'
      }
    }
  };
};

export default styles;
