import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import styles from './styles';
import Optional from '../../utils/optional';
import { translate } from '../../i18n/I18nProvider';

const StatusCell = ({
  classes,
  intlKey,
  color = '',
  text = '',
  isTable = true,
  alignLeft = true,
  showTooltip = false,
  tooltipComponent = '',
  customStyles = { field: {} }
}) => {
  const completeText = Optional(intlKey)
    .map(key => translate(key))
    .or(text);

  return (
    <div
      className={clsx(classes.root, {
        [classes.isTable]: isTable,
        [classes.alignLeft]: alignLeft
      })}
    >
      {showTooltip ? (
        <Tooltip title={tooltipComponent}>
          <div
            className={classes.field}
            style={{ backgroundColor: color, ...customStyles.field }}
          >
            {completeText}
          </div>
        </Tooltip>
      ) : (
        <div
          className={classes.field}
          style={{ backgroundColor: color, ...customStyles.field }}
        >
          {completeText}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(StatusCell);
