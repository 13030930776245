import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import flowRight from 'lodash.flowright';

import styles from './styles/secondaryButton';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import DEFAULT_FRONTEND_SETTINGS from '../../consts/frontendSettings';

function SecondaryButton({
  children,
  onClick,
  classes,
  settings,
  isLoading = false,
  disabled = false
}) {
  return (
    <Button
      className={classes.root}
      style={{
        color: Optional(settings)
          .map(settings => settings.colors.primary)
          .or(DEFAULT_FRONTEND_SETTINGS.colors.primary)
      }}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <CircularProgress size={14} color="inherit" /> : children}
    </Button>
  );
}

export default flowRight(withSettings, withStyles(styles))(SecondaryButton);
