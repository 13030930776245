import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, GET_INVOICES } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';

export function fetchInvoices({ id, setInvoices, clientId }) {
  return {
    type: sagaActions[GET_INVOICES],
    id,
    setInvoices,
    clientId
  };
}

export function* getInvoices({ id, setInvoices, clientId }) {
  yield put(loaderReducActions.startSpinLoader());

  let invoices = [];

  try {
    const data = yield call(
      getData,
      id === 'all'
        ? `${URLS.QUOTE_CLIENTS}/${clientId}/invoices`
        : `${URLS.QUOTE_CLIENTS}/${clientId}/accounts/${id}/invoices`
    );
    invoices = data;
  } catch {}

  yield call(setInvoices, invoices);
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetInvoices() {
  yield takeLatest(sagaActions[GET_INVOICES], getInvoices);
}

export default watchGetInvoices();
