import Optional from '../../../utils/optional';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import { isNumber, isUndefined } from '../../../utils/isDefined';

const mapDataForChart = ({
  accumulated_data_usage_month = {},
  block_limit_mb = 0,
  fair_usage_limit = 0
}) => {

  const blockLimit = Optional(block_limit_mb).or(0);
  const calculatedUsageLimit = calculateMbToGb(block_limit_mb);
  const calculatedFairUsageLimit = calculateMbToGb(fair_usage_limit);

  const data =
    (isNumber(accumulated_data_usage_month.data)
      ? accumulated_data_usage_month.data
      : 0) +
    (isNumber(accumulated_data_usage_month.data_roam_home)
      ? accumulated_data_usage_month.data_roam_home
      : 0);
  return {
    data: Optional(calculateMbToGb(data)).or(0),
    fairData: Optional(
      calculateMbToGb(accumulated_data_usage_month.data_roam_home)
    ).or(0),
    totalRoam: Optional(
      calculateMbToGb(accumulated_data_usage_month.data_roam_others)
    ).or(0),
    calculatedUsageLimit,
    calculatedFairUsageLimit,
    dataPercentage: calculatePercentage(data, blockLimit),
    fairDataPercentage: calculatePercentage(
      accumulated_data_usage_month.data_roam_home,
      fair_usage_limit
    )
  };
};

export default mapDataForChart;

const calculatePercentage = (value, limit) => {
  if (isUndefined(limit) || Number(limit) <= 0) {
    return 0;
  }

  return Math.floor((Number(value) * 100) / Number(limit));
};
