import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';

export default async function getGroupData({ groupName, clientDomain }) {
  try {
    const response = await getData(
      `${OUTSIDE_API.CLIENTS}/${clientDomain}/user_groups/${groupName}`
    );

    return response.user.length;
  } catch {
    return 0;
  }
}
