import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/userDetailView';
import Optional from '../../../utils/optional';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

export function UserDetailView({ classes, data, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.dataRow}>
        <div className={classes.rowTitle}>
          {`${intl.formatMessage({
            id: 'GROUPS'
          })} : `}
        </div>
        {Optional(data.groups)
          .map(groups => groups.join(', '))
          .or('')}
      </div>
      <div className={clsx(classes.dataRow, classes.rowDark)}>
        <div className={clsx(classes.rowTitle, classes.fieldName)}>
          {intl.formatMessage({
            id: 'FIELD_NAME'
          })}
        </div>
        <div className={classes.rowTitle}>
          {intl.formatMessage({
            id: 'VALUE'
          })}
        </div>
      </div>
      {data.fields.map((field, i) => {
        return (
          <div className={clsx(classes.dataRow, classes.rowDark)} key={i}>
            <div className={classes.fieldName}>{field.name}</div>
            {field.value}
          </div>
        );
      })}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(UserDetailView);
