export default {
  root: { width: '650px', backgroundColor: '#FFFFFF' },
  dialogContent: {
    width: '650px'
  },
  scrollPaper: {
    alignItems: 'start'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '20px'
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center'
  },
  typeContainer: { display: 'flex' },
  icon: { cursor: 'pointer', margin: '0 5px 0 -20px' },
  infoIcon: { margin: '0 10px', fontSize: '18px', cursor: 'help' },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    padding: '30px 0',
    marginTop: '30px',
    '& button': {
      marginRight: '10px'
    }
  },

  errorMessage: {
    fontSize: '8px',
    color: '#FD397A'
  }
};

export const customStyles = {
  select: {
    typeSelect: {
      root: { marginRight: 0 }
    },
    vacationSelect: {
      root: { marginRight: 0, width: '200px' },
      label: { display: 'none' },
      value: { width: '200px' }
    }
  },
  input: {
    name: {
      root: { marginRight: 0 }
    }
  }
};
