import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField, Button } from "@material-ui/core";
import clsx from "clsx";
import flowRight from "lodash.flowright";
import * as msTeams from "@microsoft/teams-js";
import { BroadcastChannel } from "broadcast-channel";

import { INITIAL_VALUES } from "../../consts/initialValues";
import { loginUser } from "./actions/login";
import { actions as forgottenPassReduxActions } from "../../modals/forgottenPass/reducers";
import { actions as reduxActions } from "./reducers";
import ForgotPass from "../../modals/forgottenPass/ForgottenPass";
import { isDefined, isObject, isUndefined } from "../../utils/isDefined";
import { loginMsUser } from "./actions/otherLogin";
import { toAbsoluteUrl } from "../../../_metronic";
import Optional from "../../utils/optional";
import popupWindow from "../../utils/popupWindow";
import { isString } from "../../utils/isDefined";
import withSettings from "../../utils/withSettings";

function Login({
  intl,
  loginUser,
  loading,
  openForgetPass,
  authToken,
  loginMsUser,
  loginError,
  isLogged,
  user,
  setAuth,
  setLoginError,
  settings,
}) {
  const [isTeams, setIsTeams] = useState(false);

  const channel = useMemo(() => new BroadcastChannel("ms-login"), []);

  useEffect(() => {
    msTeams.getContext(context => {
      setIsTeams(isDefined(context.hostClientType));
    });
    channel.onmessage = msg => {
      const { token, error, email } = msg;
      if (token) {
        setAuth(`Bearer ${token}`);
      }
      if (error) {
        setLoginError({ email, error });
      }
      return channel.close;
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isDefined(authToken) && !isLogged && isUndefined(user)) {
      loginMsUser({ auth: authToken });
    }
    // eslint-disable-next-line
  }, [authToken]);

  const openMsLogin = useCallback(() => {
    if (!isTeams) {
      popupWindow(toAbsoluteUrl("/teams_login"), "_blank", window, 400, 500);
    } else {
      msTeams.authentication.authenticate({
        url: toAbsoluteUrl("/teams_login"),
        width: 400,
        height: 500,
        failureCallback: reason => {
          if (reason !== "CancelledByUser") {
            setLoginError(
              isString(reason)
                ? { error: reason }
                : { email: reason.email, error: reason.error }
            );
          }
        },
        successCallback: result => {
          setAuth(`Bearer ${result}`);
        },
      });
    }
    // eslint-disable-next-line
  }, [isTeams]);

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title kt-login__title_auth">
            <h3 style={{ color: settings.colors.login_right_text }}>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: INITIAL_VALUES.LOGIN_USERNAME,
              password: INITIAL_VALUES.LOGIN_PASSWORD,
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (values.email.length <= 4) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD",
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={values => {
              loginUser({
                login: values.email,
                password: values.password,
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {isDefined(loginError.error) && !isObject(loginError.error) ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      {Optional(loginError.email)
                        .map(
                          email =>
                            `${JSON.stringify(email)} : ${JSON.stringify(
                              loginError.error
                            )}`
                        )
                        .or(JSON.stringify(loginError.error))}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label={intl.formatMessage({
                      id: "TABLE.USERNAME",
                    })}
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    label={intl.formatMessage({
                      id: "EDIT.PASS",
                    })}
                    margin="normal"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <div className="kt-login_actions_container">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={{
                        backgroundColor: settings.colors.buttons,
                        color: settings.colors.buttons_text,
                        borderRadius: "5px",
                      }}
                    >
                      {intl
                        .formatMessage({
                          id: "AUTH.SIGN_IN",
                        })
                        .toUpperCase()}
                    </button>
                    <Button
                      className="kt-login_actions_ms_button"
                      onClick={openMsLogin}
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={toAbsoluteUrl("/media/logos/mssymbol.png")}
                        alt="microsoft logo"
                      />
                      {intl.formatMessage({
                        id: "AUTH.MS_LOGIN",
                      })}
                    </Button>
                  </div>
                  <div className="kt-login_actions_container">
                    <Button
                      color="primary"
                      className="kt-login_forgotten-pass"
                      onClick={openForgetPass}
                      style={{ color: settings.colors.login_right_text }}
                    >
                      {intl.formatMessage({
                        id: "AUTH.FORGOTTEN_PASS",
                      })}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ForgotPass />
    </>
  );
}
const mapStatesToProps = ({ auth }) => ({
  loading: auth.loading,
  authToken: auth.authToken,
  loginError: auth.loginError,
  isLogged: auth.isLogged,
  user: auth.user,
});
const mapDispatchToProps = {
  setAuth: reduxActions.setAuth,
  setLoginError: reduxActions.setLoginError,
  loginUser,
  openForgetPass: forgottenPassReduxActions.openDiaog,
  loginMsUser,
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps)
)(Login);
