import React from 'react';
import clsx from 'clsx';

import formatUnits from '../actions/formatUnits';
import formatMoney from '../../../utils/currencyFormatter';
import { withStyles } from '@material-ui/styles';
import styles from '../styles';
import Cell from './Cell';
import { formatDateTime } from '../../../utils/dateUtils';

const INDENT_WIDTH = 40;

function LineGrouped({
  line,
  indentLvl,
  chargeLineRowStyle,
  intl,
  classes,
  idx
}) {
  return (
    <>
      <div
        className={clsx(classes.row, classes.chargeLineRow)}
        style={chargeLineRowStyle}
        key={idx}
      >
        <div className={classes.rowContent}>
          <>
            <Cell
              width={(indentLvl + 1) * INDENT_WIDTH}
              customClasses={[classes.groupedIndent]}
            ></Cell>
            <Cell customClasses={[classes.idFlexCellDesc]}>
              <div className={classes.description}>{line.desciption}</div>
            </Cell>
            <Cell width={150}>{formatDateTime(line.usage_date)}</Cell>
            <Cell>{formatUnits(line.unit_type, line.units_charged)}</Cell>
            <Cell customClasses={[classes.idCell]} width={150}>
              {formatMoney(line.value_charged / 100)}
            </Cell>
            <Cell customClasses={[classes.idCell]} width={70}></Cell>
          </>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(LineGrouped);
