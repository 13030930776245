import flowRight from "lodash.flowright";
import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, withStyles } from "@material-ui/core";

import DraggableDialog from "../../../partials/DraggableDialog";
import CustomDialogTitle from "../../../partials/customDialogTitle/CustomDialogTitle";
import { actions as reduxActions } from "../reducers";
import styles from "../styles/activateSimcard";
import { translate } from "../../../i18n/I18nProvider";
import PrimaryButton from "../../../partials/customButtons/PrimaryButton";
import SecondaryButton from "../../../partials/customButtons/SecondaryButton";

const ActivationSummary = ({
  orderId = "",
  classes,
  open,
  toggleActivateDialog,
  toogleActivationSummaryPopup,
}) => {
  const onCloseDialog = () => {
    toogleActivationSummaryPopup(false);
  };

  const closeBothDialog = () => {
    toogleActivationSummaryPopup(false);
    toggleActivateDialog(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root,
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate("SIMCARD.ACTIVATE_SIMCARD")}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          {`${translate("ACTIVATION_SIMCARD.SUMMARY_TEXT")} ${orderId}`}
        </DialogContent>
        <div className={classes.footer}>
          <PrimaryButton onClick={onCloseDialog}>
            {translate("BUTTON.ACTIVATE_NEXT_CARD")}
          </PrimaryButton>
          <SecondaryButton onClick={closeBothDialog}>
            {translate("BUTTON.CLOSE")}
          </SecondaryButton>
        </div>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ iotProducts }) => {
  return {
    open: iotProducts.activationSummaryOpen,
    orderId: iotProducts.orderId,
  };
};

const mapDispatchToProps = {
  toogleActivationSummaryPopup: reduxActions.toggleActivationSummary,
  toggleActivateDialog: reduxActions.toggleActivateDialog,
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ActivationSummary);
