const styles = theme => {
  return {
    root: {
      marginRight: '10px'
    },
    link: {
      '&:hover': { filter: 'brightness(1.5)' }
    },
    username: {
      cursor: 'pointer'
    }
  };
};

export default styles;
