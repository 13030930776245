import { parsePhoneNumber } from 'libphonenumber-js';

const customParseNumber = number => {
  try {
    return parsePhoneNumber(number);
  } catch (e) {
    console.info(`${number} ${e}`);
    return void 0;
  }
};

export default customParseNumber;
