import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_TEST_USERS_SYNC = 'OPEN_TEST_USERS_SYNC';
const RESET_TEST_USERS_SYNC = 'RESET_TEST_USERS_SYNC';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('TEST_USERS_SYNC', [
  OPEN_TEST_USERS_SYNC,
  RESET_TEST_USERS_SYNC,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  open: false,
  data: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_TEST_USERS_SYNC]: {
      const { data } = action;
      return {
        ...state,
        open: true,
        data
      };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_TEST_USERS_SYNC]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openTestUserSync: data => ({
    type: actionTypes[OPEN_TEST_USERS_SYNC],
    data
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  closeTestUserSync: () => ({ type: actionTypes[RESET_TEST_USERS_SYNC] })
};
