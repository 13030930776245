import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import { TextField } from '@material-ui/core';
import Optional from '../../../utils/optional';

export function DetailsInput({ field, classes, fieldOptions, intl }) {
  const { onBlur, name, value, onChange } = field;
  return (
    <div
      className={classes.fieldContainer}
      style={{ width: fieldOptions.width }}
    >
      <div className={classes.fieldLabel}>
        {Optional(fieldOptions.intlKey)
          .map(key =>
            intl.formatMessage({
              id: key
            })
          )
          .or(fieldOptions.label)}
      </div>
      <TextField
        fullWidth={true}
        margin="dense"
        variant="outlined"
        className={clsx(classes.fieldValue, classes.textfield)}
        onBlur={onBlur}
        onChange={onChange}
        value={Optional(value).or(')')}
        name={name}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DetailsInput);
