import { call, put } from 'redux-saga/effects';
import { isUndefined } from '../../../utils/isDefined';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_PROMPTS } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { translate } from '../../../i18n/I18nProvider';

export function getPrompts(connectClientId) {
  return {
    type: sagaActions[GET_PROMPTS],
    connectClientId
  };
}

export function* getAllPrompts({ connectClientId }) {
  if (isUndefined(connectClientId)) {
    yield put(reduxActions.setPrompts([]));
  } else {
    yield put(loaderReduxActions.startSpinLoader());

    let prompts = [];
    try {
      prompts = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/prompts`
      );
    } catch {
    } finally {
      yield put(reduxActions.setPrompts(createPromptsSelectOptions(prompts)));
      yield put(loaderReduxActions.stopSpinLoader());
    }
  }
}

function* watchGetAllPrompts() {
  yield takeLatest(sagaActions[GET_PROMPTS], getAllPrompts);
}

export default watchGetAllPrompts();

export const createPromptsSelectOptions = (prompts = []) => {
  const mappedPrompts = prompts.map(prompt => {
    return { value: prompt.id, label: prompt.name };
  });

  return [{ value: '', label: translate('NONE') }, ...mappedPrompts];
};
