export default {
  root: { display: 'flex', alignItems: 'center' },
  container: { display: 'flex', alignItems: 'center' },
  icon: { marginRight: '5px', fontSize: '18px' },
  textRoot: {
    padding: '10px'
  },
  button: {
    padding: 'unset',
    width: '80px',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '14px',
    '&:hover': { filter: 'brightness(1.5)' }
  }
};
