import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import moment from 'moment';
import { isArray } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

const activateAction = async ({
  values,
  template,
  sku,
  accountReference,
  clientId
}) => {
  const { code, attributes } = template;

  const mappedValues = attributes.reduce((obj, att) => {
    if (
      att.valueType === 'Date' &&
      att.name === 'phone-and-sim-card.activation-date'
    ) {
      return {
        ...obj,
        [att.name]: moment(values[att.label]).isValid()
          ? moment(values[att.label]).format('yyyy-MM-DD')
          : values[att.label]
      };
    }
    return { ...obj, [att.name]: values[att.label] };
  }, {});

  let requestCompleted = {};

  try {
    const response = await postData({
      url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions`,
      data: {
        sku,
        account_reference: accountReference,
        template_code: code,
        data: mappedValues
      },
      errorsToPass: [RESPONSE_CODES.UNPROCESSABLE_ENTITY]
    });

    if (response.status === RESPONSE_CODES.ACCEPTED) {
      const { data } = response;

      requestCompleted = data;
    }
  } catch (e) {
    requestCompleted = {
      error: Optional(e?.response?.data?.errors)
        .map(errors => (isArray(errors) ? errors.join(', ') : ''))
        .or('')
    };
  } finally {
    return requestCompleted;
  }
};

export default activateAction;
