import { JSONAPI_URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import {
  doesArrayHasLength,
  isArray,
  isStringDefined
} from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';
import { actions as reduxActions } from '../reducers';

const QUERY_PARAMS = {
  include: ['roles']
};

export default function getCustomJobs() {
  let mappedCustomJobs = [];
  return async dispatch => {
    try {
      const { data: customJobs } = await getData(
        urlCreator(JSONAPI_URLS.CUSTOM_JOBS, QUERY_PARAMS)
      );

      if (doesArrayHasLength(customJobs)) {
        mappedCustomJobs = customJobs.reduce((arr, template) => {
          const { name } = template;

          if (isStringDefined(name)) {
            return [
              ...arr,
              {
                ...template,
                value: name,
                label: name
              }
            ];
          }

          return arr;
        }, []);
      }
    } catch {}

    const selectRolesOptions = await getRoles();

    dispatch(
      reduxActions.setValue(
        'customJobs',
        isArray(mappedCustomJobs) ? mappedCustomJobs : []
      )
    );

    dispatch(reduxActions.setValue('selectRolesOptions', selectRolesOptions));
  };
}

async function getRoles() {
  try {
    const { data } = await getData(JSONAPI_URLS.ROLES);
    return mapSelectOptions(data);
  } catch {
    return [];
  }
}

function mapSelectOptions(data) {
  if (!isArray(data)) {
    return [];
  }

  return data.map(el => {
    return {
      ...el,
      value: el.id,
      label: el.name
    };
  });
}
