import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import flowRight from 'lodash.flowright';

import styles from './styles/primaryButton';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import DEFAULT_FRONTEND_SETTINGS from '../../consts/frontendSettings';

const DEFAULT_MINWIDTH = '100px';

function PrimaryButton({
  children,
  onClick,
  disabled = false,
  classes,
  settings,
  minWidth = DEFAULT_MINWIDTH,
  maxWidth = 'unset',
  href = '',
  isLoading = false,
  component,
  type = 'button',
  id = null,
  alternativeColors = void 0
}) {
  const customStyle = useMemo(() => {
    return { minWidth, maxWidth };
  }, [minWidth, maxWidth]);

  const buttonClasses = useMemo(() => {
    return {
      disabled: classes.disabledButton
    };
  }, [classes.disabledButton]);

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      disabled={disabled || isLoading}
      component={component}
      type={type}
      style={{
        ...customStyle,
        backgroundColor:
          !disabled &&
          !isLoading &&
          (alternativeColors
            ? Optional(settings)
                .map(settings => settings.colors.buttons_text)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons_text)
            : Optional(settings)
                .map(settings => settings.colors.buttons)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons)),
        color:
          !disabled &&
          !isLoading &&
          (alternativeColors
            ? Optional(settings)
                .map(settings => settings.colors.buttons)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons)
            : Optional(settings)
                .map(settings => settings.colors.buttons_text)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons_text)),
        border: alternativeColors
          ? `1px solid ${Optional(settings)
              .map(settings => settings.colors.buttons)
              .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons)}`
          : 'inherit'
      }}
      classes={buttonClasses}
      href={href}
      id={id}
    >
      {isLoading ? <CircularProgress size={14} color="inherit" /> : children}
    </Button>
  );
}

export default flowRight(withSettings, withStyles(styles))(PrimaryButton);
