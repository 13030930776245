import { URLS } from "../../../consts/endpoints";
import store from "../../../store/store";
import { getData } from "../../../utils/http";
import { isArray, isUndefined } from "../../../utils/isDefined";
import { actions as reduxActions } from "../reducers";
import { actions as loaderReduxActions } from "../../../partials/loader/reducers";
import Optional from "../../../utils/optional";

const fetchSubscriptions = (client, selectedAccount) => {
  return async dispatch => {
    dispatch(loaderReduxActions.startSpinLoader());

    if (selectedAccount?.value === "all") {
      const accounts = store.getState().iotProducts?.accounts;
      let subsArray = [];

      const accountSubs = await getData(
        `${URLS.QUOTE_CLIENTS}/${client}/subscriptions?product_category=IoT&execute_fetch_events=false&return_projections=false`
      );

      if (isArray(accountSubs)) {
        let accountMappedSubs = accountSubs.reduce((arr, sub) => {
          if (isUndefined(sub?.subscription)) {
            return arr;
          }
          const account = accounts.find(
            acc => acc.value === sub?.subscription?.parentAccountId
          );

          return [
            ...arr,
            { ...sub?.subscription, account: Optional(account?.label).or("") },
          ];
        }, []);

        subsArray = [...subsArray, ...accountMappedSubs];
      }

      dispatch(reduxActions.setSubscriptions(subsArray));
    } else {
      const accountSubs = await getData(
        `${URLS.QUOTE_CLIENTS}/${client}/subscriptions?account_id=${selectedAccount?.value}&product_category=IoT&execute_fetch_events=false&return_projections=false`
      );

      if (isArray(accountSubs)) {
        const accountMappedSubs = accountSubs.reduce((arr, sub) => {
          if (isUndefined(sub?.subscription)) {
            return arr;
          }
          return [
            ...arr,
            { ...sub?.subscription, account: selectedAccount?.label },
          ];
        }, []);

        dispatch(reduxActions.setSubscriptions(accountMappedSubs));
      }
    }
    dispatch(loaderReduxActions.stopSpinLoader());
  };
};

export default fetchSubscriptions;
