import React from "react";
import { connect } from "react-redux";

import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { doesArrayHasLength, isDefined } from "../../../app/utils/isDefined";
import flowRight from "lodash.flowright";
import withUserCheck from "../../../app/utils/withUserCheck";
import checkMenuItemList from "../../../app/utils/checkMenuItemsList";
import Optional from "../../../app/utils/optional";

function MenuList({
  currentUrl,
  menuConfig,
  layoutConfig,
  isDomainProvided,
  onLinkClick,
  isSuperAdmin,
  userHasQueues,
  clientId,
  isMitelTokenProvided,
  isPartnerClient,
  hasCientSimcards,
  hasUserPartnerId,
  hasClientAccounts,
  userRules,
  hasCientIoTSubs,
}) {
  const itemList = checkMenuItemList({
    items: menuConfig?.aside?.items,
    clientId,
    values: {
      isMitelTokenProvided,
      isDomainProvided,
      isPartnerClient,
      isSuperAdmin,
      userHasQueues,
      hasCientSimcards,
      hasUserPartnerId,
      hasClientAccounts,
      userRules,
      hasCientIoTSubs,
    },
  });

  return itemList.map((child, index) => {
    return (
      <React.Fragment key={`menuList${index}`}>
        {
          <>
            {child.section && <MenuSection item={child} />}
            {child.separator && <MenuItemSeparator item={child} />}
            {child.title && (
              <MenuItem
                item={child}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                onLinkClick={onLinkClick}
              />
            )}
          </>
        }
      </React.Fragment>
    );
  });
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    isMitelTokenProvided: isDefined(auth?.user?.mitel_token),
    userHasQueues: auth.haveQueues,
    isDomainProvided: isDefined(selectClient.selectedClient.connect30_domain),
    clientId: selectClient.selectedClient.id,
    isPartnerClient:
      isDefined(selectClient.selectedClient.partner) ||
      isDefined(selectClient.selectedClient.partner_id),
    hasCientSimcards: selectClient.selectedClient.hasCientSimcards,
    hasCientIoTSubs: selectClient.selectedClient.hasCientIoTSubs,
    hasUserPartnerId: isDefined(auth.user?.partner_id),
    userRules: auth.user?.rules,
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false),
  };
};

export default flowRight(withUserCheck, connect(mapStatesToProps))(MenuList);
