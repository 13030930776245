import React, { useState, useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import differenceBy from 'lodash.differenceby';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles, { customSelectStyles } from '../styles/userRoles';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import withUserCheck from '../../../utils/withUserCheck';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { connect } from 'react-redux';

export function UserRoles({
  intl,
  classes,
  arrayHelpers: {
    form: {
      values: { roles = [] }
    },
    remove,
    push
  },
  options,
  isClientUser,
  clientId
}) {
  const [selected, setSelected] = useState({});

  const onAddClick = useCallback(
    () => {
      push({ name: selected.label, id: selected.value.toString() });
      setSelected({});
    },
    // eslint-disable-next-line
    [selected]
  );

  const availableOptions = useMemo(
    () => [...mapOptions(options, roles)],
    // eslint-disable-next-line
    [options, roles]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'USER.USER_ROLES'
        })}
      </div>
      {can('update', createSubject('User', { client_id: clientId })) && (
        <div className={classes.selectContainer}>
          <Select
            className={classes.fieldValue}
            styles={reactSelectDefaultStyles}
            customStyles={customSelectStyles}
            isSearchable
            maxMenuHeight={145}
            options={availableOptions}
            onChange={value => setSelected(value)}
            value={Object.keys(selected).length !== 0 ? selected : null}
          />
          <PrimaryButton
            onClick={onAddClick}
            disabled={Object.keys(selected).length === 0}
            minWidth={'unset'}
          >
            {intl.formatMessage({
              id: 'BUTTON.ADD'
            })}
          </PrimaryButton>
        </div>
      )}
      <div className={classes.rolesContainer}>
        {roles.map((role, i) => (
          <div className={classes.roleContainer} key={role.name}>
            {role.name}{' '}
            {can('update', createSubject('User', { client_id: clientId })) &&
              !(role.name.indexOf('telepo') !== -1) && (
                <i
                  className={clsx('far fa-times-circle', classes.icon)}
                  onClick={() => remove(i)}
                ></i>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: Number(selectClient.selectedClient.id)
  };
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserRoles);

function mapOptions(options, roles) {
  const changedRoles = roles.map(role => {
    return { label: role.name, value: Number(role.id) };
  });

  return differenceBy(options, changedRoles, 'label');
}
