import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';
import moment from 'moment';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_HISTORY } from './types';
import { getData } from '../../../utils/http';
import urlCreator from '../../../utils/urlCreator';
import { URLS } from '../../../consts/endpoints';
import mapSimcardHistory from './mapSimcardHistory';

export function fetchHistory({ itemType, id }) {
  return {
    type: sagaActions[GET_HISTORY],
    itemType,
    id
  };
}

export function* getHistory({ itemType, id }) {
  const QUERY_PARAMS = {
    filter: { item_type: itemType, item_id: id }
  };

  const { data: history } = yield call(
    getData,
    urlCreator(URLS.VERSIONS, QUERY_PARAMS)
  );

  const mappedHistory = yield call(mapHistory, { history, itemType });

  yield put(reduxActions.setHistory(mappedHistory));
}

function* watchGetHistory() {
  yield takeLatest(sagaActions[GET_HISTORY], getHistory);
}

export default watchGetHistory();

export function mapHistory({ history = [], itemType }) {
  let mappedHistory = history.map(item => {
    const { attributes = {} } = item;
    return {
      ...attributes,
      created_at: formatDate(attributes.created_at),
      id: attributes?.item_id
    };
  });

  if (itemType === 'SimCard') {
    mappedHistory = mapSimcardHistory(mappedHistory);
  }

  return mappedHistory;
}

export function formatDate(date) {
  return !isNaN(Date.parse(date)) && moment(date).isValid()
    ? moment(date).format('DD-MM-YYYY HH:mm:ss')
    : '';
}
