import { isEmptyArray } from 'formik';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function findSelectedQueue({ state, queuesStats, queues }) {
  if (isUndefined(queuesStats) || isEmptyArray(queues)) {
    return {};
  }

  return Optional(state.selectedQueue)
    .map(selectedQueue =>
      queuesStats.find(queue => queue.id === selectedQueue.id)
    )
    .or(queuesStats.find(queue => queue.id === queues[0].id));
}
