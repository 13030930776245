import React from 'react';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import formatDate from '../../../utils/formatDate';

import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import TableActionButtons from './TableActionButtons';

export default function tableColumns({ clientId }) {
  const columns = [
    {
      title: 'TABLE.STREET',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.fullAddress, term);
      },
      field: 'fullAddress'
    },
    {
      title: 'TABLE.ZIP_CODE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.zip_code, term);
      },
      field: 'zip_code'
    },
    {
      title: 'TABLE.CITY',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.city, term);
      },
      field: 'city'
    },
    
    {
      title: 'ADDRESS.DISTRICT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.municipality_code, term);
      },
      field: 'district_subdivision',
    },
    {
      title: 'TABLE.MUNICIPALITY_CODE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.municipality_code, term);
      },
      field: 'municipality_code',
      hidden: true
    },
    {
      title: 'TABLE.ROAD_CODE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.road_code, term);
      },
      field: 'road_code',
      hidden: true
    },
    {
      title: 'TABLE.COUNTRY',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.country, term);
      },
      field: 'country'
    },
    {
      title: 'DELETE',
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      filtering: false,
      width: '50px',
      align: 'center'
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (
    can('manage', createSubject('Address', { client_id: Number(clientId) }))
  ) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
