import { isUndefined } from './isDefined';
import Optional from './optional';

export default function mapUsernameForPassReset(user) {
  const connectId = user.connect30_user_id;
  const connectDomain = Optional(user.client)
    .map(client => client.connect30_domain)
    .or(null);

  if (isUndefined(connectId) || isUndefined(connectDomain)) {
    if (isUndefined(user.client)) {
      return user.email;
    }

    return `${user.username}@${user.client.name}.ipvisible.dk`;
  }

  return `${connectId}@${connectDomain}`;
}
