import React, { useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/formDataEditor';
import { isDefined } from '../../../utils/isDefined';

function SchemaEditor({
  classes,
  onSchemaChange,
  selected,
  template,
  setIsError
}) {
  const [errors, setErrors] = useState(void 0);
  const schemaEditorRef = useRef(null);

  const handleEditorDidMount = editor => {
    schemaEditorRef.current = editor;
  };

  const showValue = () => {
    try {
      const parsed = JSON.parse(schemaEditorRef.current.getValue());
      onSchemaChange(parsed);
    } catch {}
  };

  const handleEditorValidation = markers => {
    if (markers.length > 0) {
      setErrors(markers);
      setIsError(true);
    } else {
      setErrors(void 0);
      setIsError(false);
    }
  };

  return (
    <div className={classes.root}>
      {isDefined(errors) && (
        <div className={classes.errorsContainer}>
          {errors.map((error, i) => (
            <div
              key={i}
            >{`${error.endLineNumber}:${error.endColumn} ${error.message}`}</div>
          ))}
        </div>
      )}
      <div className={classes.label}>JSONSchema</div>
      <Editor
        height="38vh"
        width="45vw"
        language="json"
        value={
          selected === 'edit' && isDefined(template)
            ? JSON.stringify(template, null, 2)
            : '{}'
        }
        onMount={handleEditorDidMount}
        onValidate={handleEditorValidation}
        onChange={showValue}
      />
    </div>
  );
}

export default withStyles(styles)(SchemaEditor);
