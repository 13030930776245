import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import MultieditForm from './components/MultieditForm';

export function NumberRangeMultiedit({
  classes,
  open,
  intl,
  closeDialog,
  ranges,
  isSaving
}) {
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'BUTTON.MULTIEDIT'
      }),
    // eslint-disable-next-line
    []
  );

  return (
    <Dialog
      maxWidth="md"
      classes={{
        paper: classes.root
      }}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeDialog}>{title}</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isSaving && (
          <div className={classes.tableOverlay}>
            {`${intl.formatMessage({
              id: 'SAVING'
            })}...`}
            <CircularProgress />
          </div>
        )}
        <div className={classes.datalimitsList}>
          <div>
            {`${ranges.length} ${intl.formatMessage({
              id: 'NUMBER_RANGES.RANGES_SELECTED'
            })}`}
          </div>
          <Tooltip
            placement="bottom"
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {ranges
                  .map(
                    range => `${range.range_from} - ${range.range_up_to_incl}`
                  )
                  .join(',\n ')}
              </div>
            }
            className={classes.tooltip}
          >
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        </div>
        <MultieditForm />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ numberRangesMultiedit }) => {
  return {
    ...numberRangesMultiedit
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumberRangeMultiedit);
