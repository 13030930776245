import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_GROUPS } from './types';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isArray } from '../../../utils/isDefined';

export function fetchGroups({ clientDomain }) {
  return {
    type: sagaActions[GET_GROUPS],
    clientDomain
  };
}

export function* getGroups({ clientDomain }) {
  try {
    const response = yield call(
      getData,
      `${OUTSIDE_API.CLIENTS}/${clientDomain}/user_groups`
    );

    const options = yield call(mapResponse, response);

    yield put(reduxActions.setGroupsOptions(options));
  } catch {}
}

function* watchGetGroups() {
  yield takeLatest(sagaActions[GET_GROUPS], getGroups);
}

export default watchGetGroups();

function mapResponse(response) {
  if (!isArray(response)) {
    return [];
  }

  return response.map(el => {
    return { value: el.name, label: el.name };
  });
}
