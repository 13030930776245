import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isObject } from '../../../utils/isDefined';

const getProducts = async ({
  setProducts = () => {},
  setIsLoading = () => {},
  accountReference,
  clientId,
  fromEdit = false
}) => {
  setIsLoading(true);
  try {
    const products = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/products?account_reference=${accountReference}&category=IoT`
    );

    if (isObject(products)) {
      const productsKeys = Object.keys(products);

      const mappedProducts = productsKeys.reduce((obj, key) => {
        return {
          ...obj,
          [key]: [
            { value: '', label: '' },
            ...products[key]
              .map(el => {
                return {
                  ...el,
                  value: el.sku,
                  label: el.name
                };
              })
              .filter(el => el.createSubscriptionAllowed === true)
          ]
        };
      }, {});

      if (fromEdit) {
        return mappedProducts;
      }

      setProducts(mappedProducts);
    }
  } catch {
  } finally {
    setIsLoading(false);
  }
};

export default getProducts;
