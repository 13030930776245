const styles = theme => {
  return {
    root: {
      width: '350px',
      paddingBottom: '20px',
      fontSize: '18px',
      color: '#FFF',
      height: 'fit-content',
      marginTop: '55px',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 40)]: {
        width: '100%',
        marginTop: 0,
        fontSize: '14px'
      }
    },
    header: { padding: '10px 20px' },
    title: {
      padding: '10px 0',
      minWidth: '310px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '95%'
      }
    },
    info: { marginBottom: '10px' },
    infoContainer: { padding: '5px 0 0 20px', fontWeight: 400 },
    adminContainer: {
      padding: '5px 20px'
    },
    presence: {
      fontSize: '12px',
      marginLeft: '20px',
      width: 'fit-content',
      padding: '0 5px'
    },
    nameRow: { display: 'flex' },
    chevronIcon: {
      cursor: 'pointer',
      width: '18px',
      display: 'flex',
      alignItems: 'center'
    },
    adminName: { fontWeight: 500 },
    pagination: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    paginationInfo: { fontSize: '12px' },
    hidden: { visibility: 'hidden' }
  };
};

export default styles;
