import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/currentOwnerInfo';

function CurrentOwnerInfo({ intl, classes }) {
  return (
    <div>
      <div className={classes.textContainer}>
        {intl.formatMessage({
          id: 'REQUEST_NEW_USER.CURRENT_OWNER_INFO_1'
        })}
      </div>
      <div className={classes.textContainer}>
        {intl.formatMessage({
          id: 'REQUEST_NEW_USER.CURRENT_OWNER_INFO_2'
        })}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CurrentOwnerInfo);
