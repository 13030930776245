import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, MANAGE_STATUS } from './types';
import { actions as reduxActions, getters } from '../reducers';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';

export function updateStatus({
  mitelToken,
  baseUrl,
  clientDomain,
  loggedUserId,
  contactId,
  status
}) {
  return {
    type: sagaActions[MANAGE_STATUS],
    mitelToken,
    baseUrl,
    clientDomain,
    loggedUserId,
    contactId,
    status
  };
}

export function* manageStatus({
  mitelToken,
  baseUrl,
  clientDomain,
  loggedUserId,
  contactId,
  status
}) {
  const response = yield call(postData, {
    url: `${baseUrl}/api/contacts/update/${clientDomain}/${loggedUserId}/${contactId}?t=${mitelToken}`,
    data: { contactStatus: status }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    const prevContacts = yield select(getters.getContacts);
    const isFavoriteView = yield select(getters.getIsFavoriteView);

    const mappedContacts = yield call(mapContacts, {
      prevContacts,
      contactId,
      status,
      isFavoriteView
    });
    yield put(reduxActions.updateUserContacts(mappedContacts));
  }
}

function* watchManageStatus() {
  yield takeLatest(sagaActions[MANAGE_STATUS], manageStatus);
}

export default watchManageStatus();

function mapContacts({ prevContacts = [], contactId, status, isFavoriteView }) {
  let mappedContacts = prevContacts.map(contact => {
    if (contact.id !== contactId) {
      return contact;
    }
    return {
      ...contact,
      contactStatus: status
    };
  });

  if (isFavoriteView) {
    mappedContacts = mappedContacts.filter(
      contact => contact.contactStatus !== 'CONTACT'
    );
  }

  return mappedContacts;
}
