import React from 'react';
import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isDefined, isObject, isUndefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import OptionLabel from '../components/OptionLabel';
import orderBy from 'lodash.orderby';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import ShortcutLabel from '../components/ShortcutLabel';

export default async function getAllActivities(
  userId,
  simpleLabel = false,
  loggedUserId
) {
  if (isUndefined(userId)) {
    return [];
  }

  let activities = [];
  let activitiesOptions = [];
  let shortcutsOptions = [];

  if (userId === loggedUserId) {
    const mitelRequestProps = getMitelRequestProps();

    if (isObject(mitelRequestProps)) {
      const {
        mitelToken,
        baseUrl,
        clientDomain,
        mitelUserId
      } = mitelRequestProps;

      if (
        isDefined(baseUrl) &&
        isDefined(clientDomain) &&
        isDefined(mitelUserId) &&
        isDefined(mitelToken)
      ) {
        try {
          const response = await getData(
            `${baseUrl}/api/presence/shortcut/${clientDomain}/${mitelUserId}?t=${mitelToken}`
          );

          shortcutsOptions = mapShortcutOptions(response?.presenceShortcut);
        } catch {}
      }
    }
  }

  try {
    const { data } = await getData({
      options: {
        url: `${OUTSIDE_API.USERS}/${userId}/${OUTSIDE_API.PRESENCE}/activities`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });
    activities = data;

    activitiesOptions = [...mapActivitiesOptions(data, simpleLabel)];
  } catch {}

  return { options: { activities, activitiesOptions }, shortcutsOptions };
}

function mapActivitiesOptions(activities, simpleLabel) {
  const sortedActivities = [
    ...orderBy(
      activities.filter(
        activity => isDefined(activity.available) && activity.available
      ),
      [activity => activity.name.toLowerCase()]
    ),
    ...orderBy(
      activities.filter(
        activity => isDefined(activity.available) && !activity.available
      ),
      [activity => activity.name.toLowerCase()]
    )
  ];
  return sortedActivities.map(activity => {
    if (simpleLabel) {
      return { value: activity.id, label: activity.name };
    } else {
      return { value: activity.id, label: <OptionLabel activity={activity} /> };
    }
  });
}

function mapShortcutOptions(shortcuts) {
  if (isUndefined(shortcuts)) {
    return [];
  }

  return shortcuts.map(short => {
    return {
      ...short,
      value: short.id,
      label: <ShortcutLabel short={short} />
    };
  });
}
