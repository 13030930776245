import { URLS } from '../../../consts/endpoints';
import { updateData } from '../../../utils/http';
import { isStringDefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import reloadClient from '../../../partials/selectClient/actions/reloadClient';

const updateClient = async ({ values = {}, clientId, onCloseDialog }) => {
  const { addressId, displayName } = values;

  const response = await updateData({
    url: `${URLS.CLIENTS}/${clientId}/settings`,
    data: {
      client_settings: {
        display_name: displayName,
        primary_address_id: isStringDefined(addressId)
          ? Number(addressId)
          : null
      }
    }
  });

  if (response.status === RESPONSE_CODES.NO_CONTENT) {
    showSnackbar({
      intlTextKey: 'MESSAGE.UPDATE.CLIENT',
      messageType: 'success',
      time: 3000
    });
    
    reloadClient(clientId)
    onCloseDialog();
  }
};

export default updateClient;
