export default {
  root: {
    display: 'flex',
    padding: '10px 10px 0 10px',
    flexDiorection: 'column'
  },
  container: { display: 'flex', flexWrap: 'wrap' },
  label: { minWidth: '120px' },
  element: { margin: '0 5px', cursor: 'pointer' }
};
