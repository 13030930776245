import React, { cloneElement, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import CustomDialogTitle from '../../customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../DraggableDialog';
import { CellNumbersTable } from './CellNumbersTable';

export function ShowMoreDataDialog({ intl, children, data }) {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="sm"
        PaperComponent={DraggableDialog}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {`${intl.formatMessage({
            id: 'CELL_NUMBERS'
          })} : ${data.name} `}
        </CustomDialogTitle>
        <DialogContent>
          <CellNumbersTable data={data} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default injectIntl(ShowMoreDataDialog);
