import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  withStyles,
  CircularProgress,
  Tabs,
  Tab
} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { actions as reduxActions } from './reducers';
import { translate } from '../../i18n/I18nProvider';
import getPrompts from './actions/getPrompts';
import getVacations from './actions/getVacations';
import addSchedules from './actions/addSchedules';
import TabPanel from '../../partials/TabPanel';
import Predefined from './components/PredefinedDays';
import NewDay from './components/NewDay';

const AddingSchedule = ({
  classes,
  closeDialog,
  open,
  schedules,
  connectClientId,
  getPrompts,
  getVacations,
  inProgress
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (open) {
      getPrompts({ connectClientId });
      getVacations();
    }
  }, [connectClientId, open, getPrompts, getVacations]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        container: classes.container,
        paper: classes.dialogContent,
        scrollPaper: classes.scrollPaper
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog();
        }
      }}
    >
      {inProgress && (
        <div className={classes.tableOverlay}>
          <CircularProgress />
        </div>
      )}
      <CustomDialogTitle onCloseClick={closeDialog}>
        {translate('EDIT_SCHEDULE.ADDING_SCHEDULES')}
      </CustomDialogTitle>
      <DialogContent>
        <div className={classes.selectedContainer}>
          <div className={classes.selectedLabel}>{`${translate(
            'SELECTED'
          )} : `}</div>
          <div className={classes.selected}>
            {schedules.map(schedule => {
              return (
                <div key={schedule.id} className={classes.selectedElement}>
                  {`${schedule.number} - ${schedule.name}`}
                </div>
              );
            })}
          </div>
        </div>
        <>
          <Tabs value={tabIndex} onChange={handleChange} centered>
            <Tab label={translate('EDIT_SCHEDULE.ADD_PREDEFINED_DAYS')} />
            <Tab label={translate('EDIT_SCHEDULE.ADD_NEW_DAY')} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Predefined />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <NewDay />
          </TabPanel>
        </>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ addingSchedules, selectClient, loader }) => {
  return {
    ...addingSchedules,
    connectClientId: selectClient.selectedClient.connect30_domain,
    inProgress: loader.isSpinVisible
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog,
  getPrompts,
  getVacations,
  addSchedules
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddingSchedule);
