import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';

export default function mapGrouped(data = [], term) {
  return data.filter(data => {
    const {
      name,
      description,
      productCategory,
      status,
      sku,
      activationDate
    } = data;

    const values = [
      name,
      description,
      productCategory,
      status,
      sku,
      activationDate
    ];

    const searched = values
      .map(value => searchCaseInsensitive(value, term))
      .filter(value => value);

    return searched.length > 0;
  });
}
