import searchCaseInsensitive from './searchCaseInsensitive';
import {
  doesArrayHasLength,
  isArray,
  isString,
  isNumber,
  isUndefined
} from './isDefined';
import searchNumbers from './searchNumbers';

export default function searchInArray({
  array = [],
  term,
  searchBy = 'id',
  isValueNumber = false
}) {
  if (!isArray(array)) {
    return false;
  }

  const searched = array.reduce((arr, elem) => {
    if (isUndefined(elem)) {
      return arr;
    }
    const checkedElement =
      isString(elem) || isNumber(elem) ? elem : elem[searchBy];
    if (
      isValueNumber
        ? searchNumbers(checkedElement, term)
        : searchCaseInsensitive(checkedElement, term)
    ) {
      return [...arr, elem];
    }
    return arr;
  }, []);

  return doesArrayHasLength(searched);
}
