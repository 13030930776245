import { isDefined, isUndefined } from '../../../utils/isDefined';
import {
  checkDates,
  checkIfFromIsBeforeToDates,
  checkReccurenceDays,
  checkStartEndDateSet
} from './validateFunctions';

export default function checkReccurenceErrors(values) {
  let reccurenceErrors = {};
  let reccurenceErrorsArray = [];

  if (
    isUndefined(values.startTime) ||
    isNaN(values.startTime) ||
    isUndefined(values.endTime) ||
    isNaN(values.endTime)
  ) {
    reccurenceErrorsArray = [
      ...reccurenceErrorsArray,
      'EDIT_SCHEDULE.VALIDATION_TIME_REQUIRED'
    ];

    reccurenceErrors = {
      ...reccurenceErrors,
      startTime: ' ',
      endTime: ' '
    };
  }
  switch (values.recurrenceType) {
    case 'DAILY': {
      const { errors, errorsArray } = checkStartEndDateSet(values);

      reccurenceErrors = { ...errors };
      reccurenceErrorsArray = [...errorsArray];

      const dateErrors = checkIfFromIsBeforeToDates(values);

      if (isDefined(dateErrors)) {
        reccurenceErrorsArray = [...reccurenceErrorsArray, dateErrors.value];
        reccurenceErrors = { ...reccurenceErrors, ...dateErrors.errors };
      }

      return { reccurenceErrors, reccurenceErrorsArray };
    }
    case 'WEEKLY': {
      const reccurenceDaysError = checkReccurenceDays(values);

      if (isDefined(reccurenceDaysError)) {
        reccurenceErrorsArray = [
          ...reccurenceErrorsArray,
          reccurenceDaysError.value
        ];
        reccurenceErrors = {
          ...reccurenceErrors,
          ...reccurenceDaysError.errors
        };
      }

      const { errors, errorsArray } = checkStartEndDateSet(values);

      reccurenceErrors = { ...reccurenceErrors, ...errors };
      reccurenceErrorsArray = [...reccurenceErrorsArray, ...errorsArray];

      const dateErrors = checkIfFromIsBeforeToDates(values);

      if (isDefined(dateErrors)) {
        reccurenceErrorsArray = [...reccurenceErrorsArray, dateErrors.value];
        reccurenceErrors = { ...reccurenceErrors, ...dateErrors.errors };
      }

      return { reccurenceErrors, reccurenceErrorsArray };
    }
    case 'MONTHLY': {
      const monthDatesErrors = checkDates(values, 'MONTH');

      if (isDefined(monthDatesErrors)) {
        reccurenceErrorsArray = [
          ...reccurenceErrorsArray,
          monthDatesErrors.value
        ];
        reccurenceErrors = { ...reccurenceErrors, ...monthDatesErrors.errors };
      }

      return { reccurenceErrors, reccurenceErrorsArray };
    }
    case 'YEARLY': {
      const yearDatesErrors = checkDates(values, 'YEAR');

      if (isDefined(yearDatesErrors)) {
        reccurenceErrorsArray = [
          ...reccurenceErrorsArray,
          yearDatesErrors.value
        ];
        reccurenceErrors = { ...reccurenceErrors, ...yearDatesErrors.errors };
      }

      return { reccurenceErrors, reccurenceErrorsArray };
    }
    case 'NONE': {
      const neverDatesErrors = checkDates(values, 'NONE');

      if (isDefined(neverDatesErrors)) {
        reccurenceErrorsArray = [
          ...reccurenceErrorsArray,
          neverDatesErrors.value
        ];
        reccurenceErrors = { ...reccurenceErrors, ...neverDatesErrors.errors };
      }

      return { reccurenceErrors, reccurenceErrorsArray };
    }
    default:
      return { reccurenceErrors, reccurenceErrorsArray };
  }
}
