import { createReduxActionTypes } from '../../../utils/actionFactory';

const RESET_ERRORS = 'RESET_ERRORS';
const UPDATE_ERRORS = 'UPDATE_ERRORS';

export const actionTypes = createReduxActionTypes('ERRORS_PANEL', [
  RESET_ERRORS,
  UPDATE_ERRORS
]);

const DEFAULT_STATE = {
  errors: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[UPDATE_ERRORS]: {
      const { error } = action;
      return { ...state, errors: [...state.errors, error] };
    }
    case actionTypes[RESET_ERRORS]: {
      return {
        ...DEFAULT_STATE,
        open: state.open
      };
    }
    default:
      return state;
  }
};

export const actions = {
  updateErrors: error => ({
    type: actionTypes[UPDATE_ERRORS],
    error
  }),
  resetErrors: () => ({
    type: actionTypes[RESET_ERRORS]
  })
};
