import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import getFullTableData from '../../../utils/getFullTableData';
import { mapTags } from '../../../modals/addingSchedules/actions/getVacations';
import mapVacations from '../../../utils/mapVacations';

const getVacations = () => {
  return async dispatch => {
    const vacations = await getFullTableData(JSONAPI_URLS.VACATIONS, {});
    const tags = mapTags(vacations);

    const mappedVAcations = mapVacations(vacations);

    dispatch(reduxActions.setVacations(mappedVAcations, tags));
  };
};

export default getVacations;
