import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { removeAddress } from '../actions/deleteAddress';
import Optional from '../../../utils/optional';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import DeleteAddressConfimation from './DeleteAddressConfimation';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { doesArrayHasLength } from '../../../utils/isDefined';

export function TableActionButtons({
  classes,
  removeAddress,
  clientId,
  data = {}
}) {
  return (
    <div className={classes.actionButtons}>
      {can(
        'delete',
        createSubject('Address', { client_id: Number(clientId) })
      ) && (
        <ConfirmDialog
          onConfirmClick={() => removeAddress(data.id, clientId)}
          dialogContentComponent={<DeleteAddressConfimation />}
          dialogTitleIntlId={'TOOLTIP.DELETE_ADDRESS'}
        >
          <CustomIconButton
            disabledIconTooltipIntlKey="TOOLTIP.DELETE_ADDRESS_INFO"
            iconTooltipIntlKey="TOOLTIP.DELETE_ADDRESS"
            iconClassName="far fa-trash-alt"
            disabled={
              doesArrayHasLength(data.external_number_ranges) ||
              doesArrayHasLength(data.phone_book_registrations)
            }
          />
        </ConfirmDialog>
      )}
    </div>
  );
}
const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: Optional(selectClient.selectedClient.id).or('')
  };
};

const mapDispatchToProps = {
  removeAddress
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
