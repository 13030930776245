import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/informationSection';
import InputRow from '../../../partials/dataRows/InputRow';
import withUserCheck from '../../../utils/withUserCheck';
import Optional from '../../../utils/optional';

export function UserDirFields({ classes, form, intl, dirFields = {} }) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'USER.DIR_FIELDS'
        })}
      </div>
      <div className={classes.container}>
        <div>
          {Object.keys(dirFields)
            .sort()
            .map(field => {
              return (
                <InputRow
                  labelText={Optional(dirFields[field].label).or(field)}
                  name={`dirFields.${field}.value`}
                  mobileCustom
                  {...form}
                  isValueNested={true}
                  key={field}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ editUser }) => {
  const dirFields = Optional(editUser.user.generalData?.dirFields).or({});
  return {
    dirFields
  };
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserDirFields);
