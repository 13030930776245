import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import Optional from '../../../utils/optional';

import styles from '../styles';

function Cell({ classes, children, width, customClasses = [], key }) {
  const customWidth = Optional(width)
    .map(width => `${width}px`)
    .or('150px');

  return (
    <div
      key={key}
      className={clsx(classes.cell, customClasses.join(', '))}
      style={{ width: customWidth }}
    >
      {children}
    </div>
  );
}

export default withStyles(styles)(Cell);
