import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import DataRow from '../../Datarow/DataRow';
import TextFieldWithEditDialog from '../../textFieldWithEditDialog/TextFieldWithEditDialog';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import pickOptionsForStatusCell from '../../statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import StatusCell from '../../statusCell/StatusCell';
import CustomAccordion from '../../customAccordion/CustomAccordion';

const DESCRIPTION_FIELD_OPTIONS = {
  labelIntlKey: 'DESCRIPTION',
  tooltipIntlKey: 'TOOLTIP.EDIT_DESCRIPTION',
  editDialogLabelIntlKey: 'SIM_CARD.EDIT_DESCRIPTION',
  updateSuccessIntlKey: 'MESSAGE.UPDATE_SIMCARD_DESCRIPTION',
  field: 'description',
  maxTextLength: 35
};

export function SimcardInfo({
  classes,
  simCardData = {},
  intl,
  isClientUser,
  isAdminUser,
  isESimCard
}) {
  const customClasses = useMemo(() => {
    return {
      dataRow: {
        root: classes.dataRow_root,
        value: classes.dataRow_value
      },
      descriptionField: {
        root: classes.descriptionField_root,
        text: classes.descriptionField_text
      }
    };
  }, [classes]);

  return (
    <div className={classes.sectionContainer}>
      <DataRow
        label={translate('TABLE.NUMBER')}
        value={mapNumberToDisplay(simCardData.msisdn)}
        customClasses={customClasses.dataRow}
        dense
      />
      <TextFieldWithEditDialog
        {...DESCRIPTION_FIELD_OPTIONS}
        customClasses={customClasses.descriptionField}
        requestId={simCardData.id}
        editEnabled={isAdminUser}
      />
      <DataRow
        label={translate('CARD_TYPE')}
        value={setStatusText({
          value: simCardData.data_card,
          additionalValue: simCardData.primary_card_id,
          intl,
          field: 'data_card',
          isESimCard
        })}
        customClasses={customClasses.dataRow}
        dense
      />
      {isDefined(simCardData.primary_card_id) && (
        <DataRow
          label={intl.formatMessage({
            id: 'NUMBER.PRIMARY_CARD_NUMBER'
          })}
          value={Optional(simCardData.primary_card)
            .map(card => card.msisdn)
            .or('')}
          customClasses={customClasses.dataRow}
          dense
        />
      )}
      <DataRow
        label={translate('TABLE.NETWORK')}
        value={Optional(simCardData.mobile_network?.brand).or('')}
        customClasses={customClasses.dataRow}
        dense
      />
      <DataRow
        label={translate('STATUS')}
        value={
          <StatusCell
            isTable={false}
            {...pickOptionsForStatusCell(
              STATUS_BOX_OPTIONS.SIMCARD_ACTIVE,
              simCardData.active
            )}
          />
        }
        customClasses={customClasses.dataRow}
        dense
      />
      <CustomAccordion>
      <DataRow
        label={translate('SIMCARD_NUMBER')}
        value={simCardData.icc}
        customClasses={customClasses.dataRow}
        dense
      />
      <DataRow
        label={'PIN 1'}
        value={simCardData.pin1}
        customClasses={customClasses.dataRow}
        dense
      />
      <DataRow
        label={'PUK'}
        value={simCardData.puk1}
        customClasses={customClasses.dataRow}
        dense
      />
      </CustomAccordion>
      {!simCardData.active && isClientUser && (
        <div className={classes.sicardUnlockInfo}>
          {intl.formatMessage({
            id: 'NUMBER.CONTACT_SUPPORT'
          })}
        </div>
      )}
    </div>
  );
}

export default flowRight(
  withUserCheck,
  injectIntl,
  withStyles(styles)
)(SimcardInfo);

function setStatusText({
  additionalValue = false,
  value = false,
  field,
  isESimCard = false
}) {
  const options = {
    data_card: {
      true: 'DATACARD',
      false: 'STANDARD_CARD',
      additional: 'DATASHARING_CARD'
    },
    active: { true: 'ACTIVE', false: 'BLOCKED' }
  };

  const text = additionalValue
    ? translate(options[field].additional)
    : value
    ? translate(options[field].true)
    : translate(options[field].false);

  return isESimCard ? `eSim - ${text}` : text;
}
