export default {
  agentsRoot: {
    width: '100%',
    fontSize: '13px',
    color: '#212529',
    backgroundColor: '#FDFDFD',
    '& tr:nth-of-type(odd)': {
      backgroundColor: '#EFEFEF'
    }
  },
  agentContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center'
  },
  agentData: { display: 'inherit', alignItems: 'center' },
  photoRoot: {
    marginLeft: '20px'
  },
  photo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    width: '36px',
    borderRadius: '18px',
    backgroundColor: 'lightGrey'
  },
  initilas: {
    fontSize: '16px'
  },
  agentName: { width: '190px', margin: '0 5px 0 30px' },
  agentLogged: { marginLeft: '40px' },
  inCall: {
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    color: '#FFF'
  },
  hidden: {
    visibility: 'hidden'
  },
  presentDotRoot: {
    backgroundColor: 'inherit'
  },
};

