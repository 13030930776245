/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import * as builder from '../../ducks/builder';
import Optional from '../../../app/utils/optional';
import BreadCrumbs from '../../../app/partials/breadcrumbs/Breadcrumbs';
import getIdFromUrl from '../../../app/utils/getIdfromUrl';
import ClientDotMenu from '../../../app/partials/clientDotMenu/ClientDotMenu';
import withUserCheck from '../../../app/utils/withUserCheck';
import { Tooltip } from '@material-ui/core';
import { isDefined } from '../../../app/utils/isDefined';
import reloadClient from '../../../app/partials/selectClient/actions/reloadClient';
import ClientMenu from '../../../app/partials/clientMenu/ClientMenu';
import can from '../../../app/utils/can';
import createSubject from '../../../app/utils/createSubject';

const NOTES_LENGTH = 40;

function SubHeader({
  subheaderCssClasses,
  subheaderContainerCssClasses,
  subheaderMobileToggle,
  clientName,
  user,
  location,
  isNonClientAdminUser,
  notes,
  partnerName,
  isSuperAdmin,
  selectedClientId
}) {
  const name = useMemo(() => {
    const isClientPage = getIdFromUrl(location);
    return isClientPage ? clientName : 'Admin';
  }, [location, clientName]);

  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
    >
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <BreadCrumbs user={user} />
          <div className="kt-subheader__clientName_container">
            {isSuperAdmin && isDefined(partnerName) && (
              <div className="kt-subheader__clientName_container">
                {partnerName}
              </div>
            )}
            {isNonClientAdminUser && name !== 'Admin' ? (
              <div className="kt-subheader__clientName_container">
                <Tooltip
                  placement="bottom"
                  title={notes.length > NOTES_LENGTH ? notes : ''}
                >
                  <div className="kt-subheader__notes">
                    {notes.length > NOTES_LENGTH
                      ? `${notes.slice(0, NOTES_LENGTH)}...`
                      : notes}
                  </div>
                </Tooltip>
                <ClientDotMenu>
                  <div
                    className="kt-subheader__clientName"
                    style={{ cursor: 'pointer' }}
                  >
                    {name}
                  </div>
                </ClientDotMenu>
              </div>
            ) : can(
                'update',
                createSubject('Client', {
                  id: Number(selectedClientId)
                })
              ) && name !== 'Admin' ? (
              <ClientMenu>
                <div
                  className="kt-subheader__clientName"
                  style={{ cursor: 'pointer' }}
                >
                  {name}
                </div>
              </ClientMenu>
            ) : (
              <>
                <div className="kt-subheader__clientName">{name}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    'subheader.mobile-toggle'
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true
  }),
  clientName: Optional(store.selectClient.selectedClient)
    .map(client => Optional(client.connect30_domain).or(client.name))
    .or(store.auth.defaultClient.name),
  partnerName: store.selectClient?.selectedClient?.partner?.name,
  user: Optional(store.user).or(null),
  notes: Optional(store.selectClient.selectedClient?.notes).or(''),
  selectedClientId: store.selectClient?.selectedClient?.id
});

export default flowRight(
  injectIntl,
  withRouter,
  withUserCheck,
  connect(mapStateToProps)
)(SubHeader);

window.onload = async event => {
  const excludePageArray = ['auth', 'logout', 'password_reset'];
  const location = window.location.pathname;

  const [, page] = location.split('/');

  if (
    isDefined(page) &&
    excludePageArray.findIndex(el => el === page) === -1 &&
    isDefined(localStorage?.getItem('persist:selectClient'))
  ) {
    const client = JSON.parse(localStorage.getItem('persist:selectClient'));

    Optional(client?.selectedClient)
      .map(async selectedClient => {
        const clientid = JSON.parse(selectedClient).id;

        await reloadClient(clientid);
      })
      .or(void 0);
  }
};
