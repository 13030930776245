export default {
    root: {
      display: 'flex',
      padding: '10px 10px 0 10px',
      alignItems: 'center'
    },
    container: { display: 'flex', flexWrap: 'wrap' },
    label: { minWidth: '120px' },
    element: {
      cursor: 'pointer',
      padding: '5px 10px',
      borderRadius: '5px',
      margin: '5px'
    }
  };
