import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/notesSection';
import InputRow from '../../../partials/dataRows/InputRow';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';

const CUSTOM_STYLES = {
  textfield: { width: '400px' },
  label: { width: '50px' }
};

export function NotesSection({ classes, form, intl }) {
  return (
    <div className={classes.root}>
      <div>
        <CheckboxRow
          label="REQUEST_NEW_USER.CALLS_RECORDING"
          name="call_recording"
          {...form}
        />
        <CheckboxRow
          label="REQUEST_NEW_USER.VOICE_MAIL"
          name="voice_mail"
          {...form}
        />
      </div>
      <div>
        <InputRow
          customStyles={CUSTOM_STYLES}
          label="NOTES"
          name="notes"
          isMultiline={true}
          minRows={5}
          {...form}
        />
        <div className={classes.info}>
          {intl.formatMessage({
            id: 'REQUEST_NEW_USER.NOTES_INFO'
          })}
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(NotesSection);
