export const TOP_MENU_ITEMS = {
  DASHBOARD: {
    title: 'Dashboard',
    root: true,
    alignment: 'left',
    page: 'dashboard',
    translate: 'MENU.DASHBOARD'
  },
  CLIENTS: {
    title: 'Clients',
    page: 'clients',
    admin: true,
    translate: 'MENU.CLIENTS'
  },
  USERS: {
    title: 'Users',
    page: 'users',
    admin: true,
    translate: 'MENU.USERS'
  },
  SIM_CARDS: {
    title: 'Sim Cards',
    page: 'simCards',
    admin: true,
    translate: 'MENU.SIM_CARDS'
  },
  NUMBER_RANGE: {
    title: 'Number Range',
    page: 'externalNumber',
    translate: 'MENU.NUMBER_RANGE',
    admin: true
  },
  PARTNERS: {
    title: 'Partners',
    page: 'partners',
    translate: 'MENU.PARTNERS',
    admin: true,
    onlyForSuperAdmin: true
  },
  ROLES: {
    title: 'Roles',
    page: 'roles',
    translate: 'MENU.ROLES',
    admin: true,
    onlyForSuperAdmin: true
  },
  PERMISSIONS: {
    title: 'Permissions',
    page: 'permissions',
    translate: 'MENU.PERMISSIONS',
    admin: true,
    onlyForSuperAdmin: true
  },
  EDIT_SCHEMA: {
    title: 'Config Templates',
    page: 'editSchema',
    translate: 'MENU.EDIT_SCHEMA',
    admin: true,
    checkKey: 'config_template',
    checkAction: 'update'
  },
  CUSTOM_JOBS: {
    title: 'Custom jobs',
    page: 'customJobs',
    translate: 'MENU.CUSTOM_JOBS',
    admin: true,
    checkKey: 'custom_jobs',
    checkAction: 'update'
  },
  NUMBER_LOOKUP: {
    title: 'NumberLookup',
    page: 'numberLookup',
    translate: 'MENU.NUMBER_LOOKUP',
    admin: true
  },
  SWITCH_CLIENT: {
    title: 'Switch client',
    root: true,
    alignment: 'left',
    openDialogButton: true,
    translate: 'MENU.SWITCH_CLIENT'
  },
  DEFAULT_CLIENT: {
    isIconButton: true,
    isResetClientButton: true,
    awesomeIcon: 'fal fa-home-heart',
    root: true,
    hideOnDefaultUser: true
  },
  APP_SETTINGS: {
    title: 'Settings',
    page: 'settings',
    isSettingsLink: true,
    translate: 'MENU.SELFCARE_SETTINGS'
  },
  CHANGELOG: {
    title: 'Changelog',
    page: 'changelog',
    translate: 'MENU.CHANGELOG',
    admin: true
  },
  PHONEBOOK_REGISTRATION: {
    title: 'PhonebookRegistration',
    page: 'phonebookRegistration',
    translate: 'MENU.PHONEBOOK_REGISTRATION',
    admin: true
  },
  VACATIONS: {
    title: 'Vacations',
    page: 'vacations',
    translate: 'MENU.VACATIONS',
    admin: true
  },
  ORDERS: {
    title: 'Orders',
    page: 'orders',
    translate: 'MENU.ORDERS',
    admin: true
  }
};

export const ASIDE_MENU_ITEMS = {
  USER_PROFILE: {
    title: 'User profile',
    root: true,
    icon: 'flaticon2-architecture-and-city',
    awesomeIcon: 'fal fa-id-card',
    customStyles: {
      link: { paddingLeft: '22px' }
    },
    page: 'userProfile',
    translate: 'MENU.USER_PROFILE',
    isUserLink: true,
    ignoreCheck: true
  },
  USER_CONTACTS: {
    title: 'Contacs',
    root: true,
    awesomeIcon: 'fal fa-address-book',
    page: 'userContacts',
    translate: 'MENU.USER_CONTACTS',
    ignoreCheck: true,
    isUserLink: true,
    hideWhenThereIsNoMitelToken: true
  },
  DASHBOARD: {
    title: 'Dashboard',
    root: true,
    icon: 'flaticon2-architecture-and-city',
    awesomeIcon: 'fal fa-home-alt',
    customStyles: {
      icon: { fontSize: '1.5rem' },
      link: { paddingLeft: '20px' }
    },
    page: 'dashboard',
    translate: 'MENU.DASHBOARD',
    checkKey: 'Client',
    conditionKey: 'id'
  },
  SIM_CARDS: {
    title: 'Sim Cards',
    icon: 'flaticon2-sheet',
    awesomeIcon: 'fal fa-sim-card',
    page: 'simCards',
    customStyles: { icon: { fontSize: '1.5rem' } },
    translate: 'MENU.SIM_CARDS',
    checkKey: 'SimCard',
    hideWhenThereIsNoSimcards: true
  },
  NUMBER_RANGE: {
    title: 'Number Range',
    page: 'externalNumber',
    icon: 'flaticon2-sort-down',
    translate: 'MENU.NUMBER_RANGE',
    awesomeIcon: 'fal fa-th-list',
    checkKey: 'ExternalNumberRange'
  },
  QUEUES: {
    title: 'Queues',
    icon: 'flaticon2-sheet',
    page: 'queues',
    translate: 'MENU.QUEUES',
    awesomeIcon: 'fal fa-layer-group',
    hideWhenThereIsNoDomain: true,
    hideWhenThereIsNoQueues: true,
    checkKey: 'Mitel::Queue',
    ignoreCheck: true
  },
  SCHEDULES: {
    title: 'Schedules',
    icon: 'flaticon2-sheet',
    page: 'schedules',
    translate: 'MENU.SCHEDULES',
    awesomeIcon: 'fal fa-calendar-alt',
    hideWhenThereIsNoDomain: true,
    checkKey: 'Mitel::Schedule'
  },
  DATA_LIMITS: {
    title: 'Data Limits',
    icon: 'flaticon2-line-chart',
    page: 'dataLimits',
    awesomeIcon: 'fal fa-chart-bar',
    translate: 'MENU.DATA_LIMITS',
    checkKey: 'DataLimit',
    hideWhenThereIsNoSimcards: true
  },
  TELEPHONY_USERS: {
    title: 'Telephony Users',
    icon: 'flaticon2-user',
    page: 'telephonyUsers',
    translate: 'MENU.TELEPHONY_USERS',
    awesomeIcon: 'fal fa-user-friends',
    hideWhenThereIsNoDomain: true,
    checkKey: 'User'
  },
  GROUPS: {
    title: 'Groups',
    page: 'groups',
    translate: 'MENU.GROUPS',
    awesomeIcon: 'fal fa-users',
    hideWhenThereIsNoDomain: true,
    checkKey: 'Mitel::UserGroup'
  },
  USERS: {
    title: 'Users',
    icon: 'flaticon2-user',
    page: 'users',
    translate: 'MENU.USERS',
    awesomeIcon: 'fal fa-users-cog',
    showWhenThereIsNoDomain: true,
    hideForNotSuperAdminUsers: true,
    checkKey: 'User'
  },

  ADDRESSES: {
    title: 'Addresses',
    icon: 'flaticon2-map',
    page: 'addresses',
    customStyles: {
      icon: { fontSize: '1.5rem' }
    },
    translate: 'MENU.ADDRESSES',
    awesomeIcon: 'fal fa-map-marker-alt',
    checkKey: 'Address'
  },
  INVOICES: {
    title: 'Invoices',
    page: 'invoices',
    customStyles: {
      icon: { fontSize: '1.5rem' }
    },
    translate: 'MENU.INVOICES',
    awesomeIcon: 'fal fa-file-invoice-dollar',
    checkKey: 'Quote::Invoice',
    hideWhenNoAccounts: true
  },
  SUBSCRIPTIONS: {
    title: 'Subscriptions',
    page: 'subscriptions',
    translate: 'MENU.SUBSCRIPTIONS',
    awesomeIcon: 'fal fa-business-time',
    checkKey: 'Quote::Subscription',
    hideWhenNoAccounts: true
  },
  CONNECTIONS: {
    title: 'Network connections',
    page: 'network_connections',
    awesomeIcon: 'fal fa-network-wired',
    translate: 'MENU.NETWORK_CONNECTIONS',
    checkKey: 'Quote::Connection'
  },
  LICENSES: {
    title: 'Licenses',
    page: 'licenses',
    translate: 'MENU.LICENSES',
    awesomeIcon: 'fal fa-file-certificate',
    hideWhenThereIsNoDomain: true,
    checkKey: 'Mitel::License'
  },
  APP_SETTINGS: {
    title: 'APP_SETTINGS',
    page: 'appSettings',
    translate: 'MENU.APP_SETTINGS',
    awesomeIcon: 'fal fa-toolbox',
    checkKey: 'Config',
    checkAction: 'update'
  },
  PHONEBOOK_REGISTRATION: {
    title: 'PhonebookRegistration',
    page: 'phonebookRegistration',
    translate: 'MENU.PHONEBOOK_REGISTRATION',
    awesomeIcon: 'fal fa-address-book',
    checkKey: 'PhoneBookRegistration',
    ignoreCheck: true,
    customCheck: 'Phonebook'
  },
  SIM_CARDS_IOT: {
    title: 'IoT products',
    awesomeIcon: 'fal fa-sim-card',
    page: 'iot_subscriptions',
    customStyles: { icon: { fontSize: '1.5rem' } },
    translate: 'MENU.IOT_SUBSCRIPTIONS',
    checkKey: 'Quote::Subscription',
    hideWhenNoAccounts: true,
    hideWhenThereIsNoIoTSubs: true
  },
  CDR_HOOKS: {
    title: 'CdrHooks',
    page: 'cdrHooks',
    translate: 'MENU.CDR_HOOKS',
    awesomeIcon: 'fal fa-spider-web',
    hideWhenThereIsNoDomain: true,
    checkKey: 'Mitel::CdrHook'
  },
  ORDERS: {
    title: 'Orders',
    page: 'orders',
    translate: 'MENU.ORDERS',
    awesomeIcon: 'fal fa-shopping-cart',
    checkKey: 'Order'
  },
  ACCOUNTS: {
    title: 'Accounts',
    page: 'accounts',
    translate: 'MENU.ACCOUNTS',
    awesomeIcon: 'fal fa-book',
    checkKey: 'Quote::Account'
  }
};

export const ASIDE_MENU_SECTIONS = {
  TELEPHONY: {
    section: 'Telephony',
    translate: 'MENU.TELEPHONY',
    awesomeIcon: 'fal fa-phone-office'
  },
  CLIENTS_SETTINGS: {
    section: 'Client settings',
    translate: 'MENU.CLIENT_SETTINGS',
    awesomeIcon: 'fal fa-cogs'
  },
  ADMINISTRATION: {
    section: 'Administration',
    translate: 'MENU.ADMINISTRATION',
    awesomeIcon: 'fal fa-user-shield'
  }
};

export const TOP_MENU_BUTTON = {
  ADMINISTRATION: {
    title: 'Administration',
    root: true,
    alignment: 'left',
    translate: 'MENU.ADMINISTRATION'
  },
  SYSTEM_SETUP: {
    title: 'System setup',
    root: true,
    alignment: 'left',
    translate: 'MENU.SYSTEM_SETUP'
  }
};
