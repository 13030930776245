import { SERVER } from './initialValues';

export const API = '/api/v1/';
export const API_V2 = '/api/v2/';

export const JSONAPI_URLS = {
  LOGIN: `${SERVER}${API}login`,
  LOGOUT: `${SERVER}${API}logout`,
  SIM_CARDS: `${SERVER}${API}sim_cards.json`,
  CLIENTS: `${SERVER}${API}clients.json`,
  USERS: `${SERVER}${API}users.json`,
  EXTERNAL_NO_RANGES: `${SERVER}${API}external_number_ranges.json`,
  ADDRESSES: `${SERVER}${API}addresses.json`,
  ROLES: `${SERVER}${API}roles.json`,
  PERMISSIONS: `${SERVER}${API}permissions.json`,
  PARTNERS: `${SERVER}${API}partners.json`,
  DATA_LIMITS: `${SERVER}${API}data_limits.json`,
  LANGUAGES: `${SERVER}${API}languages.json`,
  CELL_NUMBERS: `${SERVER}${API}cell_numbers.json`,
  PROVIDERS: `${SERVER}${API}providers.json`,
  CUSTOM_JOBS: `${SERVER}${API}custom_jobs.json`,
  VERSIONS: `${SERVER}${API}versions.json`,
  PHONE_BOOK_REGISTRATION: `${SERVER}${API}phone_book_registrations.json`,
  VACATIONS: `${SERVER}${API}vacation_days.json`,
  ORDERS: `${SERVER}${API}orders.json`
};

export const URLS = {
  MS_LOGIN: `${SERVER}${API}auth/microsoft_graph`,
  SIM_CARDS: `${SERVER}${API}sim_cards`,
  PERMISSIONS: `${SERVER}${API}permissions`,
  CLIENTS: `${SERVER}${API}clients`,
  QUOTE_CLIENTS: `${SERVER}${API}quote/clients`,
  USERS: `${SERVER}${API}users`,
  EXTERNAL_NO_RANGES: `${SERVER}${API}external_number_ranges`,
  ADDRESSES: `${SERVER}${API}addresses`,
  PASS: `${SERVER}${API}password`,
  CELL_NUMBERS: `${SERVER}${API}cell_numbers`,
  DATA_LIMITS: `${SERVER}${API}data_limits`,
  NEW_USER_REQUESTS: `${SERVER}${API}requests/new_user`,
  ATTORNEY_REQUEST: `${SERVER}${API}requests/number_porting_attorney`,
  LOOKUP: `${SERVER}${API}och/lookup`,
  ORDER_SIMCARDS: `${SERVER}${API}requests/new_sim_cards`,
  BILLING_CLIENTS: `${SERVER}${API_V2}billing/clients`,
  CLIENT_LIST: `${SERVER}${API}client_list`,
  FRONTEND_SETTINGS: `${SERVER}/frontend_settings`,
  USER: `${SERVER}/api/config/user`,
  PARTNERS: `${SERVER}${API}partners`,
  VERSIONS: `${SERVER}${API}versions`,
  CARRIER: `${SERVER}${API}carrier`,
  API: `${SERVER}${API}`,
  ROLES: `${SERVER}${API}roles`,
  CONFIG_TEMPLATES: `${SERVER}${API}config_templates`,
  CUSTOM_JOBS: `${SERVER}${API}custom_jobs`,
  PHONE_BOOK_REGISTRATION: `${SERVER}${API}phone_book_registrations`,
  MICROSOFT: `${SERVER}${API}/microsoft_graph`,
  VACATIONS: `${SERVER}${API}vacation_days`,
  DATALIMITS_MDU: `${SERVER}${API}mdu/data_limits`,
  ORDERS: `${SERVER}${API}orders`
};

export const OUTSIDE_API = {
  QUEUES: `${SERVER}/mitel/distgroups`,
  CLIENTS: `${SERVER}/mitel/clients`,
  DISTGROUP_STATS: 'distgroups/statistics',
  USERS: `${SERVER}/mitel/users`,
  SMS: `${SERVER}/mitel/sms`,
  AVAILABLE_PUBLIC_NO: 'available_public_numbers',
  AVAILABLE_PRIVATE_NO: 'available_private_numbers',
  NOTES: 'note',
  PRESENCE: 'presence'
};

export const CSV_API = {
  SIM_CARDS: `${SERVER}${API}sim_cards.csv`,
  DATA_LIMITS: `${SERVER}${API}data_limits.csv`,
  EXTERNAL_NO_RANGES: `${SERVER}${API}number_ranges.csv`,
  USERS: `${SERVER}${API}users.csv`
};
