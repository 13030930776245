import { translate } from '../i18n/I18nProvider';

export default function mapProvidersOptions(fromIoT = false ) {
  return  !fromIoT ? [
    { value: '', label: '' },
    {
      value: 'TDC NET',
      label: `TDC ${translate('TABLE.NETWORK').toLowerCase()}`
    },
    {
      value: 'Telenor net',
      label: `Telenor ${translate('TABLE.NETWORK').toLowerCase()}`
    }
  ] : [
    {
      value: 'TDC IoT',
      label: `TDC ${translate('TABLE.NETWORK').toLowerCase()} IoT`
    }
  ];
}
