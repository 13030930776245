import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import { CONST_VALUES } from '../../../consts/initialValues';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';

function LockDialogText({
  classes,
  intl,
  isPartnerClient,
  websiteURL,
  settings,
  isDatasharingCard = false,
  network
}) {
  const is3Network = useMemo(() => network === '3', [network]);

  return (
    <div
      className={clsx(classes.dialogTextContainer, {
        [classes.dialogTextContainerBigger]: is3Network
      })}
    >
      {isDatasharingCard && (
        <div style={{ color: settings.colors.danger }}>
          {intl.formatMessage({
            id: 'NUMBER.LOCK_WARNING'
          })}
        </div>
      )}
      <div>
        {intl.formatMessage({
          id: is3Network
            ? 'NUMBER.LOCK_SIMCARD_1_3_NETWORK'
            : 'NUMBER.LOCK_SIMCARD_1'
        })}
      </div>
      {is3Network ? (
        <div>
          {intl.formatMessage({
            id: 'NUMBER.LOCK_SIMCARD_2_3_NETWORK'
          })}
        </div>
      ) : (
        ''
      )}
      <div>
        {intl.formatMessage({
          id: 'NUMBER.LOCK_SIMCARD_2'
        })}
        <div
          className={classes.appendixLink}
          style={{ color: settings.colors.links }}
          onClick={() =>
            window.open(
              isPartnerClient ? websiteURL : CONST_VALUES.APPENDIX_LINK
            )
          }
        >
          {isPartnerClient
            ? intl.formatMessage({
                id: 'NUMBER.LOCK_SIMCARD_LINK_PARTNER'
              })
            : intl.formatMessage({
                id: 'NUMBER.LOCK_SIMCARD_LINK'
              })}
        </div>

        {intl.formatMessage({
          id: 'NUMBER.LOCK_SIMCARD_3'
        })}
      </div>
      <div>
        {intl.formatMessage({
          id: 'NUMBER.LOCK_SIMCARD_4'
        })}
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(LockDialogText);
