import { isDefined, isString, isStringDefined } from '../../../utils/isDefined';

export default function mapDataLimitsToUpdate({
  datalimits = [],
  formValues = {}
}) {
  const mappedFormValues = mapFormValues(formValues);

  const mappedDatalimits = mapDatalimits({ datalimits, mappedFormValues });

  const datalimitsToUpdate = mapDatalimitsForRequest(mappedDatalimits);

  return datalimitsToUpdate;
}

function mapFormValues(formValues = {}) {
  const valuesKeys = Object.keys(formValues);
  const mappedFormValues = {};

  valuesKeys.map(valueKey => {
    if (isStringDefined(formValues[valueKey])) {
      if (
        valueKey === 'allow_recharge' ||
        valueKey === 'roaming_disabled' ||
        valueKey === 'manual_block' ||
        valueKey === 'update_block_limit_mb_now'
      ) {
        return (mappedFormValues[valueKey] =
          formValues[valueKey] === 'true' ? true : false);
      }

      if (valueKey === 'report_only_to') {
        return (mappedFormValues[valueKey] =
          formValues[valueKey] === 'true' ? 'true' : null);
      }

      if (valueKey === 'block_limit_mb' || valueKey === 'fair_usage_limit') {
        return (mappedFormValues[valueKey] = isString(formValues[valueKey])
          ? formValues[valueKey].replace(',', '.') * 1000
          : 0);
      }

      mappedFormValues[valueKey] = formValues[valueKey];
    }

    return valueKey;
  });

  return mappedFormValues;
}

function mapDatalimits({ datalimits, mappedFormValues }) {
  const mappedFormsKeys = Object.keys(mappedFormValues);
  const mappedDatalimits = datalimits.map(datalimit => {
    const mappedDatalimit = {};

    mappedFormsKeys.map(key => {
      if (mappedFormValues[key] !== datalimit[key]) {
        mappedDatalimit[key] = mappedFormValues[key];
      }

      return key;
    });

    if (Object.keys(mappedDatalimit).length === 0) {
      return null;
    }

    return { ...mappedDatalimit, id: datalimit.id };
  });

  return mappedDatalimits.filter(limit => isDefined(limit));
}

function mapDatalimitsForRequest(datalimits) {
  return datalimits.map(datalimit => {
    const { id, ...restDatalimitValues } = datalimit;
    return {
      id: Number(id),
      type: 'data_limits',
      attributes: { ...restDatalimitValues }
    };
  });
}
