const styles = theme => {
  return {
    root: {
      fontSize: '15px',
      fontWeight: 500,
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #EBEDF2',
      width: '100%'
    },
    label: {
      paddingLeft: '20px',
      width: 'calc(100% - 50px)',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    closeIcon: {
      fontSize: '1.5rem',
      marginLeft: '20px',
      marginRight: '20px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.medium
      }
    }
  };
};

export default styles;
