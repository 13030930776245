const styles = {
  root: { display: 'flex', flexDirection: 'column' },
  label: {
    padding: '10px',
    backgroundColor: '#0699A3',
    color: 'white',
    width: '100%'
  },
  infoIcon: {
    marginLeft: '5px'
  },
  row: {
    display: 'flex',
    width: '100%',
    padding: '5px 10px 5px 20px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  expandCell: { width: '20px' },
  numberCell: {
    width: '120px'
  },
  functionCell: {
    width: '180px'
  },
  serviceName: { width: '350px' },
  serviceLabel: { justifyContent: 'space-between', display: 'flex' },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '60px'
  },
  noDataInfo: {
    padding: '20px'
  }
};

export default styles;
