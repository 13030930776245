import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import getCdrHooks from '../actions/getCdrHooks';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import deleteCdrHooks from '../actions/deleteCdrHooks';
import DeleteCdrHookConfimation from './DeleteCdrHookConfimation';
import { actions as editCdrHookReduxActions } from '../../../modals/editCdrHook/reducers';

const TableActionButtons = ({
  data: cdrHook,
  classes,
  getCdrHooks,
  selectedClientId,
  connectClientId,
  openEditCrdHook
}) => {
  const onEditClick = useCallback(
    () => {
      openEditCrdHook(cdrHook, true);
    },
    // eslint-disable-next-line
    [cdrHook]
  );

  const onDeleteConfirmation = useCallback(
    async () => {
      const isDeleted = await deleteCdrHooks({
        connectClientId,
        id: cdrHook.id
      });

      if (isDeleted) {
        getCdrHooks(connectClientId);
      }
    },
    // eslint-disable-next-line
    [cdrHook.id]
  );

  return (
    <div className={classes.actionButtons}>
      <ConfirmDialog
        onConfirmClick={onDeleteConfirmation}
        dialogContentComponent={<DeleteCdrHookConfimation />}
        dialogTitleIntlId={'CDR_HOOKS.DELETE_CDR_HOOK'}
      >
        <CustomIconButton
          iconTooltipIntlKey="CDR_HOOKS.DELETE_CDR_HOOK"
          iconClassName="far fa-trash-alt"
          disabled={
            !can(
              'delete',
              createSubject('Mitel::CdrHook', {
                client_id: selectedClientId
              })
            )
          }
        />
      </ConfirmDialog>
      <CustomIconButton
        iconTooltipIntlKey="CDR_HOOKS.EDIT_CDR_HOOK"
        onClick={onEditClick}
        iconClassName="far fa-edit"
        disabled={
          !can(
            'update',
            createSubject('Mitel::CdrHook', {
              client_id: selectedClientId
            })
          )
        }
      />
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    selectedClientId: Number(selectClient.selectedClient.id),
    connectClientId: selectClient.selectedClient?.connect30_domain
  };
};

const mapDispatchToProps = {
  getCdrHooks,
  openEditCrdHook: editCdrHookReduxActions.openEditCdrHook
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
