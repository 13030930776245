const styles = theme => ({
  root: {
    padding: '5px 20px 5px 0',
    fontSize: '13px'
  },
  icon: {
    marginRight: '5px'
  },
  contact: { marginTop: '10px' }
});

export default styles;
