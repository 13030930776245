import store from '../store/store';
import { isUndefined } from './isDefined';
import history from '../../history';

export default function getMitelRequestProps() {
  const user = store.getState().auth.user;
  const defaultClient = store.getState().auth.defaultClient;

  if (isUndefined(user) || isUndefined(defaultClient)) {
    return history.push('/logout');
  }

  const { mitel_token, mitel_profile_link, connect30_user_id } = user;
  const { connect30_domain } = defaultClient;

  return {
    mitelToken: mitel_token,
    baseUrl: mitel_profile_link,
    clientDomain: connect30_domain,
    mitelUserId: connect30_user_id
  };
}
