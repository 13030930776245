import { getData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';
import mapData from './mapData';

export default async function getCsvData({
  requestUrl,
  queryParams,
  name,
  data,
  isCsvEndpoint = false
}) {
  const QUERY_PARAMS = {
    paginate: false,
    ...queryParams
  };

  let csvData = [];

  if (isDefined(data)) {
    csvData = data;
  } else {
    if (isCsvEndpoint) {
      const responseData = await getData(urlCreator(requestUrl, QUERY_PARAMS));
      return responseData;
    } else {
      const { data: responseData } = await getData(
        urlCreator(requestUrl, QUERY_PARAMS)
      );
      csvData = responseData;
    }
  }

  return mapData({ csvData, name });
}
