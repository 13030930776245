import { isArrayEmpty } from '../../../utils/isDefined';

export default function mapSchedules(prev, next) {
  if (isArrayEmpty(prev)) {
    return next;
  }
  return prev.map(item => {
    const newData = next.find(elem => elem.id === item.id);
    return { ...newData, tableData: { ...item.tableData } };
  });
}
