const styles = theme => ({
  root: {
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      justifyContent: 'space-between'
    }
  },
  container: {
    backgroundColor: '#FFF',
    borderRadius: '10px',
    padding: '10px 0'
  },
  infoContainer: {
    display: 'flex',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E'
  },
  dataRow_root: {
    paddingRight: '10px',
    paddingLeft: '10px',
    alignItems: 'flex-start'
  },
  dataRow_value: {
    width: 'fit-content',
    fontWeight: 600
  },
  dataRow_root_short: {
    width: '200px',
    padding: '3px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)'
    }
  },
  icon: { cursor: 'pointer' }
});

export default styles;
