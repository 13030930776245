import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import CustomSwitch from '../../../partials/CustomSwitch';
import { actions as reduxActions } from '../reducers';
import styles from '../styles/isUserViewSwitch';

function IsUserViewSwitch({ toggleSwitch, isUsersView, classes, intl }) {
  const handleSwitch = e => {
    toggleSwitch(e.target.checked);
  };

  return (
    <div className={classes.root}>
      <div>
        {intl.formatMessage({
          id: 'GROUP_BY'
        })}
      </div>
      <div className={classes.switchRow}>
        <div>
          {intl.formatMessage({
            id: 'MENU.QUEUES'
          })}
        </div>
        <CustomSwitch checked={isUsersView} handleChange={handleSwitch} />
        <div>
          {intl.formatMessage({
            id: 'MENU.USERS'
          })}
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ queues }) => {
  return {
    isUsersView: queues.isUsersView
  };
};

const mapDispatchToProps = {
  toggleSwitch: reduxActions.updateIsUsersView
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(IsUserViewSwitch);
