import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import styles from '../styles';
import CurrentStatus from './CurrentStatus';
import Info from './Info';
import Reporting from './Reporting';

export function DataLimit({ classes, datalimit = {} }) {
  return (
    <div>
      <div className={classes.basicInfoContainer}>
        <Info datalimit={datalimit} />
        <CurrentStatus datalimit={datalimit} />
      </div>
      <Reporting datalimit={datalimit} />
    </div>
  );
}

const mapStatesToProps = ({ datalimitStats }) => {
  return {
    ...datalimitStats
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(DataLimit);
