import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as sagaActions, GET_PHOTO } from './types';
import { actions as authReduxActions } from '../../../pages/auth/reducers';
import { isDefined } from '../../../utils/isDefined';
import fetchUserPhotoFromMitel from '../../../utils/fetchUserPhotoFromMitel';

export function getUserPhoto({ userId, connectDomain, domainUser }) {
  return {
    type: sagaActions[GET_PHOTO],
    userId,
    connectDomain,
    domainUser
  };
}

export function* getPhoto({ userId, connectDomain, domainUser = false }) {
  const { photo } = isDefined(userId)
    ? yield call(fetchUserPhotoFromMitel, {
        userId,
        domainUser,
        connectDomain
      })
    : {};

  yield put(authReduxActions.setPhoto(photo));
}

function* watchGetPhoto() {
  yield takeLatest(sagaActions[GET_PHOTO], getPhoto);
}

export default watchGetPhoto();
