import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_EXTERNAL_NO_RANGES = 'SET_EXTERNAL_NO_RANGES';
const RESET_EXTERNAL_NO_RANGES = 'RESET_EXTERNAL_NO_RANGES';
const SET_COLUMNS = 'SET_COLUMNS';
const SET_MAIN_SELECTED = 'SET_MAIN_SELECTED';
const SET_REFRESH = 'SET_REFRESH';

export const actionTypes = createReduxActionTypes('EXTERNAL_NO_RANGES', [
  SET_EXTERNAL_NO_RANGES,
  RESET_EXTERNAL_NO_RANGES,
  SET_COLUMNS,
  SET_MAIN_SELECTED,
  SET_REFRESH
]);

const DEFAULT_STATE = {
  externalNoRanges: [],
  columns: [],
  networkOptions: {},
  mainSelected: 'all',
  refresh: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_EXTERNAL_NO_RANGES]: {
      const { externalNoRanges, networkOptions } = action;
      return { ...state, externalNoRanges, networkOptions };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[SET_MAIN_SELECTED]: {
      return { ...state, mainSelected: action.selected };
    }
    case actionTypes[SET_REFRESH]: {
      return { ...state, refresh: action.refresh };
    }
    case actionTypes[RESET_EXTERNAL_NO_RANGES]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setExternalNoRanges: (externalNoRanges, networkOptions) => ({
    type: actionTypes[SET_EXTERNAL_NO_RANGES],
    externalNoRanges,
    networkOptions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  setMainSelected: selected => ({
    type: actionTypes[SET_MAIN_SELECTED],
    selected
  }),
  setRefresh: refresh => ({ type: actionTypes[SET_REFRESH], refresh }),
  resetExternalNoRanges: () => ({
    type: actionTypes[RESET_EXTERNAL_NO_RANGES]
  })
};
