const styles = () => {
  return {
    root: { backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    dataContainer: { display: 'flex', flexDirection: 'column' },
    footer: {
      display: 'flex',
      marginBottom: '10px',
      justifyContent: 'center',
      '& button': {
        marginRight: '10px'
      }
    },
    numbersRoot: {
      display: 'flex',
      padding: '20px 0',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '10px',
      borderTop: '1px #F2F3F8 solid'
    },
    addButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    numberRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
      padding: '5px'
    },
    numberIcon: {
      fontSize: '18px',
      cursor: 'pointer'
    },
    textfield: {
      width: '140px'
    },
    deleteIcon: { padding: '5px' },
    hiddenIcon: { visibility: 'hidden' },
    error: { fontSize: '8px', color: '#FD397A' },
    prefferedDot: {
      width: '150px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    emptyDot: { justifyContent: 'flex-end' }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px',
    padding: '5px'
  },
  control: {
    border: 'hidden',
    width: '130px',
    boxShadow: 'none'
  },
  menu: { width: '130px' },
  valueContainer: { fontSize: '12px' },
  indicatorSeparator: { display: 'none' },
  indicatorContainer: { padding: 0 }
};
