import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const RESET_DIALOG = 'RESET_DIALOG';
const SET_IS_SAVING = 'SET_IS_SAVING';

export const actionTypes = createReduxActionTypes('NUMBER_RANGE_MULTIEDIT', [
  OPEN_DIALOG,
  RESET_DIALOG,
  SET_IS_SAVING
]);

const DEFAULT_STATE = {
  open: false,
  ranges: [],
  selectOptions: {},
  resetValues: {},
  isSaving: false,
  isBeTable: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { ranges, resetValues, selectOptions, isBeTable } = action;
      return {
        ...state,
        ranges: [...ranges],
        resetValues,
        selectOptions,
        isBeTable,
        open: true
      };
    }
    case actionTypes[SET_IS_SAVING]: {
      const { isSaving } = action;
      return {
        ...state,
        isSaving
      };
    }
    case actionTypes[RESET_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDialog: ({ ranges, resetValues, selectOptions, isBeTable }) => ({
    type: actionTypes[OPEN_DIALOG],
    ranges,
    resetValues,
    selectOptions,
    isBeTable
  }),
  setIsSaving: isSaving => ({
    type: actionTypes[SET_IS_SAVING],
    isSaving
  }),
  closeDialog: () => ({ type: actionTypes[RESET_DIALOG] })
};
