export default {
  root: { backgroundColor: '#FFFFFF' },
  dialogContent: {
    overflow: 'visible'
  },
  scrollPaper: {
    alignItems: 'start'
  },
  content: { backgroundColor: '#FAFAFA' },
  topContainer: { display: 'flex' },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    padding: '20px 0',
    '& button': {
      marginRight: '10px'
    }
  },
  closeIcon: {
    fontSize: '1.5rem',
    marginLeft: '20px',
    cursor: 'pointer',
    color: '#A7ABC3',
    '&:hover': {
      color: '#5D78FF'
    }
  }
};
