import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import { fetchAccounts } from './actions/getAccounts';
import { isDefined } from '../../utils/isDefined';
import { actions as reduxActions } from './reducers';
import InvoicesTable from './components/InvoicesTable';
import styles from './styles';
import SelectAccount from './components/SelectAccount';

const Invoices = ({ clientId, fetchAccounts, resetAccounts, classes }) => {
  useEffect(() => {
    if (isDefined(clientId)) {
      fetchAccounts(clientId);
    }
    return resetAccounts;
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <>
      <div className={classes.root}>
        <SelectAccount />
      </div>
      <InvoicesTable />
    </>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  fetchAccounts,
  resetAccounts: reduxActions.resetAccounts
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Invoices);
