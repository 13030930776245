import createSelectClientOptions from '../../../partials/selectClient/actions/createSelectClientOptions';
import Optional from '../../../utils/optional';
import SELECTS_OPTIONS from '../../../consts/selectsOptions';
import { isStringDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import { LANGUAGES } from '../../../consts/initialValues';

const DEFAULT_LANGUAGE = 'da';

export function prepareTelephonySelectsOptions({ selectsOptions, userData }) {
  const { domain_languages = [], ...restSelectOptions } = selectsOptions;

  const mappedLanguages = domain_languages.map(language => {
    const { value, label, displayString } = language;

    return {
      value,
      label: Optional(displayString).or(
        Optional(LANGUAGES[label])
          .map(lang => translate(lang))
          .or(label)
      ),
      short: label
    };
  });

  const languageId = Optional(
    mappedLanguages.find(
      language =>
        language.short ===
        Optional(userData)
          .map(user => user.language)
          .or(DEFAULT_LANGUAGE)
    )
  )
    .map(language => language.value)
    .or(null);

  return {
    mappedselectOptions: { languages: mappedLanguages, ...restSelectOptions },
    languageId
  };
}

export function prepareSelectsOptions(selectsOptions, clientsList) {
  const clients = createSelectClientOptions(clientsList);
  const { roles = [], telephony_users = [], languages = [] } = selectsOptions;

  const mappedUsers = telephony_users.map(user => {
    const { value, label } = user;

    return {
      value,
      label: `${label} ${isStringDefined(value) ? '-' : ''} ${value}`
    };
  });

  const mappedLanguages = languages
    .filter(language => language.label === 'da' || language.label === 'en')
    .map(language => {
      const { value, label } = language;
      return {
        value,
        label: SELECTS_OPTIONS.LANG_OPTIONS.find(
          option => option.value === label
        ).label,
        short: label
      };
    });

  return {
    roles,
    telephony_users: mappedUsers,
    language: mappedLanguages,
    clients
  };
}
