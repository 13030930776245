import { isUndefined, isArray, isStringEmpty } from './isDefined';

export default function createDottedNumber(value, changeToComma = false) {
  if (
    isUndefined(value) ||
    isArray(value) ||
    isStringEmpty(value) ||
    isNaN(value)
  ) {
    return '';
  }
  const stringNumber = typeof value === 'number' ? value.toString() : value;

  if (changeToComma) {
    return stringNumber.match(/\d{1,3}(?=(\d{3})*$)/g).join('.');
  }

  return stringNumber.replace('.', ',');
}
