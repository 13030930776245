import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';

const CancelSubscriptionConfimation = ({ classes }) => {
  return (
    <div className={classes.dialogTextContainer}>
      <div>{translate('SUBSCRIPTIONS.CANCEL_TEXT')}</div>
      <div>{translate('SUBSCRIPTIONS.CANCEL_TEXT_2')}</div>
    </div>
  );
};

export default withStyles(styles)(CancelSubscriptionConfimation);
