export default {
  dialogContent: {
    overflow: 'visible'
  },
  // container: { display: 'flex' },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    '& button': {
      marginRight: '10px'
    }
  }
};

export const customStyles = {
  inputRow: {
    label: { fontWeight: '600' },
    textfield: { width: '100%' }
  },
  selectRow: {
    label: { fontWeight: '600', width: '105px' }
  }
};
