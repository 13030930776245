const styles = theme => ({
  root: {
    width: '400px',
    backgroundColor: '#FFF',
    margin: '5px',
    height: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },
  title: {
    display: 'flex',
    height: '40px',
    borderBottom: '1px solid #FAFAFA',
    fontSize: '16px',
    fontWeight: 500,
    alignItems: 'center',
    paddingLeft: '20px',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: '10px',
    borderBottom: '1px solid #FAFAFA'
  },
  cardCallContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardIcons: {
    paddingRight: '10px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center'
  },
  cardIcon: {
    padding: '0 5px',
    cursor: 'pointer',
    fontWeight: 500,
    color: '#BBBBBB'
  },
  cardCallInfo: {
    display: 'flex',
    paddingLeft: '10px',
    fontSize: '13px',
    alignItems: 'center',
    paddingBottom: '10px',
    color: '#BBBBBB',
    borderBottom: '3px solid #F2F3F8'
  },
  cardCallInfoEl: {
    margin: '0 5px'
  },
  favoriteIcon: { cursor: 'initial', fontWeight: 900 },
  addIcon: { paddingRight: 0 },
  cardName: {
    margin: '10px',
    fontSize: '15px',
    fontWeight: 500
  },
  callIcon: { fontSize: '14px', fontWeight: 400, marginRight: '5px' },
  deleteAllButton: {
    display: 'flex',
    alignItems: 'center'
  },
  voicemailButtons: { padding: '5px', justifyContent: 'flex-end' },
  deleteIcon: {
    fontSize: '16px',
    marginLeft: '5px',
    fontWeight: '500'
  },
  audioContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '5px',
    flexDirection: 'column'
  },
  audio: {
    height: '20px',
    width: '95%'
  },
  audioInfo: {
    padding: '5px',
    alignItems: 'center'
  },
  icon: { cursor: 'pointer', margin: '0 5px' },
  hidden: { display: 'none' },
  deleteIconvisibility: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  receiverContainer: {
    fontSize: '14px',
    width: '100%',
    padding: '10px 20px 0 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  receiverTextfield: { width: '280px' },
  messageContainer: {
    alignItems: 'baseline',
    padding: '0 20px 0 10px',
    fontSize: '16px'
  },
  countersContainer: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
    marginBottom: '10px'
  },
  lenghtContainer: {
    width: '100px',
    display: 'flex',
    paddingRight: '5px',
    justifyContent: 'flex-end'
  },
  noOfContainer: {
    width: '180px',
    display: 'flex',
    paddingLeft: '5px',
    justifyContent: 'flex-start'
  },
  sendContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px'
  }
});

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: { width: '280px' },
  menu: { width: 'fit-content' }
};
