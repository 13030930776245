const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 20px)',
      margin: '5px 0'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 700,
    color: ' #595D6E',
    justifyContent: 'space-between'
  },
  transferButton: {
    fontSize: '10px',
    paddingRightL: '10px',
    cursor: 'pointer'
  },
  numbersContainer: {
    padding: '10px 0'
  },
  numberContainer: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'space-between',
    color: '#74788D'
  },
  number: {
    fontWeight: 500
  },
  dataRow_root: { paddingRight: '30px', marginLeft: '5px' },
  dataRow_value: { width: 'fit-content' },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px 10px 10px 10px'
  },
  icon: {
    marginLeft: '10px',
    fontSize: '16px'
  },
  butonLabel: { display: 'flex', alignItems: 'center' }
});

export default styles;
