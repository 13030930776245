import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_SCHEDULE = 'SET_SCHEDULE';
const SET_PROMPTS = 'SET_PROMPTS';
const RESET_SCHEDULE = 'RESET_SCHEDULE';
const SET_ERRORS = 'SET_ERRORS';
const SET_VACATIONS = 'SET_VACATIONS';

export const actionTypes = createReduxActionTypes('EDIT_SCHEDULE', [
  SET_SCHEDULE,
  SET_PROMPTS,
  RESET_SCHEDULE,
  SET_ERRORS,
  SET_VACATIONS
]);

const DEFAULT_STATE = {
  open: false,
  schedule: {},
  prompts: [],
  customErrors: {},
  vacations: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_SCHEDULE]: {
      const { schedule, isEdit, group } = action;
      return {
        ...state,
        schedule: { ...schedule },
        isEdit,
        group,
        open: true
      };
    }
    case actionTypes[SET_PROMPTS]: {
      const { prompts } = action;
      return {
        ...state,
        prompts
      };
    }
    case actionTypes[SET_VACATIONS]: {
      const { vacations } = action;
      return {
        ...state,
        vacations
      };
    }
    case actionTypes[SET_ERRORS]: {
      const { customErrors } = action;
      return {
        ...state,
        customErrors
      };
    }
    case actionTypes[RESET_SCHEDULE]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setSchedule: ({ schedule, isEdit, group }) => ({
    type: actionTypes[SET_SCHEDULE],
    schedule,
    isEdit,
    group
  }),
  setPrompts: prompts => ({
    type: actionTypes[SET_PROMPTS],
    prompts
  }),
  setVacations: vacations => ({
    type: actionTypes[SET_VACATIONS],
    vacations
  }),
  setErrors: customErrors => ({
    type: actionTypes[SET_ERRORS],
    customErrors
  }),
  resetSchedule: () => ({ type: actionTypes[RESET_SCHEDULE] })
};
