import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';

export default async function getFormData({
  setFormData,
  clientId,
  templateName
}) {
  let formData = {};

  try {
    const { data } = await getData({
      options: {
        url: `${URLS.CLIENTS}/${clientId}/configs/${templateName}`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    formData = Optional(data).or({});
  } catch (e) {
    console.warn(e);
  } finally {
    setFormData(formData);
  }
}
