const styles = theme => ({
  root: {
    width: '400px',
    margin: '5px',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E'
  },
  titleActions: { display: 'flex', alignItems: 'center' },
  container: { backgroundColor: '#fff', borderRadius: '10px' },
  sectionContainer: {
    padding: '13px 0'
  },
  sectionLabel: {
    padding: '0 0 10px 30px'
  },
  buttonsContainer: {
    padding: '5px 0 15px',
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 500,
    color: '#48465B',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      margin: '5px 0'
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm - 150)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  buttonRow: {
    justifyContent: 'space-around',
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.sm - 150)]: {
      width: '100%'
    }
  },
  buttonContainer: {
    display: 'inherit',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm - 150)]: {
      width: 'fit-content',
      margin: '5px'
    }
  },
  redButton: {
    backgroundColor: '#FA5252',
    color: '#FFF',
    '&:hover': { backgroundColor: 'red' }
  },
  greenButton: {
    backgroundColor: '#A9B241',
    color: '#FFF',
    '&:hover': { backgroundColor: '#9BA33C' }
  },
  sicardUnlockInfo: {
    fontSize: '11px',
    textAlign: 'center',
    marginTop: '5px'
  },
  activeContainer: {
    display: 'flex',
    padding: '3px 30px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  activeLabel: {
    width: '140px'
  },
  activeText: {
    fontWeight: 500
  },
  dataRow_root: {
    paddingRight: '30px',
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '10px',
    }
  },
  dataRow_value: {
    width: 'fit-content',
    fontWeight: 600
  },
  descriptionField_root: {
    width: 'calc(100% - 10px)',
    margin: 0,
    padding: '3px 20px 3px 10px',
    height: '25px',
    fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      width : '100%',
      paddingRight: '10px',
    }
  },
  descriptionField_text: {
    width: 'fit-content',
    margin: 0,
    padding: 0,
    fontWeight: 600
  },
  dotsMenuIcon: {
    cursor: 'pointer',
    marginLeft: '5px'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#000',
    padding: '20px',
    fontWeight: 600,
    fontSize: '13px'
  }
});

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '10px'
  },
  control: {
    borderStyle: 'hidden',
    width: '130px',
    boxShadow: 'none'
  },
  valueContainer: { fontSize: '12px' },
  indicatorSeparator: { display: 'none' }
};
