import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { isStringDefined } from '../../../utils/isDefined';
import styles from '../styles/callLog';
import { deleteItem } from '../actions/deleteCallLogItem';
import withSettings from '../../../utils/withSettings';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';

function VoicemailCard({
  classes,
  data,
  mitelToken,
  deleteItem,
  settings,
  intl,
  isAppleDevice
}) {
  const [src, setSrc] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const audioRef = useRef();

  useEffect(() => {
    setSrc(`${data.url}?t=${mitelToken}`);
    audioRef.current.load();
  }, [data.url, mitelToken]);

  const canPlay = checkIfCanPlay(audioRef, isAppleDevice);

  const onDeleteClick = useCallback(() => {
    deleteItem({
      action: 'DELETE_VOICEMAIL',
      itemId: data.itemId,
      isCallLog: false
    });
    // eslint-disable-next-line
  }, [data.itemId]);

  return (
    <div
      className={classes.cardRoot}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.cardCallContainer}>
        <div className={classes.cardName}>
          {isStringDefined(data.displayedName)
            ? data.displayedName
            : data.number}
        </div>
        <div className={classes.cardIcons}>
          <ConfirmDialog
            onConfirmClick={onDeleteClick}
            dialogContentComponent={intl.formatMessage({
              id: 'CONTACTS.DELETE_ONE_VOICEMAIL'
            })}
            dialogTitleIntlId={'DELETE'}
          >
            <i
              className={clsx('fal fa-trash', classes.cardIcon, {
                [classes.deleteIconvisibility]: !isHovered
              })}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          </ConfirmDialog>
          <a href={`tel:${data.number}`}>
            <i
              className={clsx('fal fa-phone-alt', classes.cardIcon)}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          </a>
        </div>
      </div>
      <div className={classes.audioContainer}>
        <audio
          ref={audioRef}
          controls={canPlay}
          className={clsx(classes.audio)}
          preload="metadata"
        >
          <source src={src} type="audio/wav" />
        </audio>
        {!canPlay && (
          <p className={classes.audioInfo}>
            {intl.formatMessage({
              id: 'FORMAT_NOT_SUPPORTED'
            })}{' '}
            <a
              href={`${data.url}?t=${mitelToken}`}
              style={{ color: settings.colors.links }}
            >
              <i className="fas fa-download"></i>
            </a>
          </p>
        )}
      </div>
      <div className={classes.cardCallInfo}>
        <div>{<i className="far fa-voicemail"></i>}</div>
        <div className={classes.cardCallInfoEl}>{data.dateToShow}</div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ auth, builder }) => {
  return {
    isAppleDevice: builder.isAppleDevice,
    mitelToken: auth.user.mitel_token
  };
};

const mapDispatchToProps = {
  deleteItem
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(VoicemailCard);

function checkIfCanPlay(audioRef, isAppleDevice) {
  const canPlayType = audioRef.current?.canPlayType('audio/wav');

  return !isAppleDevice && isStringDefined(canPlayType);
}
