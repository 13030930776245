import React, { useState, cloneElement, useCallback } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";

import { actions as showHistoryReduxActions } from "../../../modals/showHistory/reducers";
import can from "../../../utils/can";
import createSubject from "../../../utils/createSubject";
import { openEditDatalimit } from "../../../modals/editDatalimit/actions/setDatalimit";
import { actions as showDatalimitReduxActions } from "../../../modals/showDatalimit/reducers";
import { actions as datalimitLogsReduxActions } from "../../../modals/datalimitLogs/reducers";
import { translate } from "../../../i18n/I18nProvider";

const DatalimitMenu = ({
  children,
  openEditDatalimit,
  openShowHistory,
  datalimit,
  openShowDatalimit,
  fromRightPanel,
  openDatalimitLogs,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = useCallback(
    () => {
      openEditDatalimit({
        datalimitId: datalimit.id,
        fromRightPanel,
        fromLookup: true,
        lookupNumber: datalimit.number,
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [datalimit, fromRightPanel]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: datalimit.id,
        itemType: "DataLimit",
        titleText: datalimit.name,
        historyObject: datalimit,
        itemTypeKey: "DATA_LIMITS.DATALIMIT",
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [datalimit.id]
  );

  const onShowClick = useCallback(
    () => {
      openShowDatalimit(datalimit.id, datalimit.name);
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [datalimit.id, datalimit.name]
  );

  const onLogsClick = useCallback(
    () => {
      openDatalimitLogs(datalimit?.id);
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [datalimit.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={onShowClick}>
          {translate("DATA_LIMITS.SHOW_DATALIMIT")}
        </MenuItem>
        {can(
          "update",
          createSubject("DataLimit", {
            client_id: Number(datalimit.client?.id),
          })
        ) && (
          <MenuItem onClick={onEditClick}>
            {translate("TOOLTIP.EDIT_DATALIMIT")}
          </MenuItem>
        )}
        <MenuItem onClick={onHistoryClick}>
          {translate("TOOLTIP.SHOW_HISTORY")}
        </MenuItem>
        <MenuItem onClick={onLogsClick}>
          {translate("DATA_LIMITS.SHOW_DATALIMIT_LOGS")}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openShowDatalimit: showDatalimitReduxActions.openShowDatalimit,
  openShowHistory: showHistoryReduxActions.openShowHistory,
  openEditDatalimit,
  openDatalimitLogs: datalimitLogsReduxActions.openDatalimitLogs,
};

export default connect(null, mapDispatchToProps)(DatalimitMenu);
