import CustomSwitch from '../../../partials/CustomSwitch';
import { isDefined } from '../../../utils/isDefined';
import React from 'react';
import EditQuota from './EditQuota';
import updateLIcense from '../actions/updateLicense';
import QuotaSwitch from './QuotaSwitch';

export default function tableColumns() {
  const columns = [
    {
      title: 'TABLE.NAME',
      field: 'name',
      editable: 'never'
    },
    {
      title: 'DISPLAY_NAME',
      render: rowData =>
        rowData.type === 'system' || rowData.type === 'organization' ? (
          <div style={{ diplay: 'flex', alignItems: 'center' }}>
            {rowData.display_name} <i className="fas fa-star"></i>{' '}
          </div>
        ) : (
          rowData.display_name
        ),
      field: 'display_name',
      editable: 'never'
    },
    {
      title: 'Quota',
      field: 'quota',
      render: rowData =>
        rowData.type === 'system' || rowData.type === 'organization' ? (
          <CustomSwitch
            checked={rowData.quota === 1}
            handleChange={async e =>
              await updateLIcense({
                component: rowData.component,
                quota: e.target.checked ? 1 : 0
              })
            }
          />
        ) : isDefined(rowData.quota) ? (
          rowData.quota
        ) : (
          <i className="fas fa-infinity" style={{ fontWeight: 500 }}></i>
        ),
      untranslatedTitle: true,
      align: 'center',
      disableClick: true,
      editComponent: props =>
        props.rowData.type === 'unit' ? (
          <EditQuota props={props} />
        ) : props.rowData.type === 'system' ||
          props.rowData.type === 'organization' ? (
          <QuotaSwitch props={props} />
        ) : null
    },
    {
      title: 'Current',
      field: 'current',
      untranslatedTitle: true,
      align: 'right',
      editable: 'never'
    },
    {
      title: 'Component',
      field: 'component',
      untranslatedTitle: true,
      hidden: true,
      editable: 'never'
    },
    {
      title: 'Peak',
      field: 'peak',
      untranslatedTitle: true,
      align: 'right',
      editable: 'never'
    },
    {
      title: 'Churn',
      field: 'churn',
      untranslatedTitle: true,
      align: 'right',
      editable: 'never'
    }
  ];

  let mappedColumns = [...columns];

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
