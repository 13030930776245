import { CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';

function Description({ text, count, clientId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    const regex = /#{(.*?)\}/g;
    const shouldEvaluate = isDefined(text.match(regex));

    if (shouldEvaluate) {
      evaluateExpression();
    } else setValue(text);
    // eslint-disable-next-line
  }, [clientId, text]);

  const evaluateExpression = useCallback(async () => {
    setIsLoading(true);
    let value = '';

    try {
      const response = await getData(
        `${
          URLS.QUOTE_CLIENTS
        }/${clientId}/subscriptions/evaluate_expression?expression=${encodeURIComponent(
          text
        )}`
      );
      value = response.data;
    } catch {
    } finally {
      setValue(value);
      setIsLoading(false);
    }
  }, [clientId, text]);

  if (isLoading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return `${value} (${count})`;
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

export default connect(mapStatesToProps)(Description);
