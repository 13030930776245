import { EMPTY_JOB } from '../../../consts/initialValues';
import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_VALUE = 'SET_VALUE';
const RESET_EDIT = 'RESET_EDIT';
const RESET_SCHEMAS = 'RESET_SCHEMAS';

export const actionTypes = createReduxActionTypes('EDIT_CUSTOM_JOB', [
  SET_VALUE,
  RESET_EDIT,
  RESET_SCHEMAS
]);

const DEFAULT_STATE = {
  customJobs: [],
  customJob: EMPTY_JOB,
  selected: 'create',
  schema: {},
  uiSchema: {},
  selectRolesOptions: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_VALUE]: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    case actionTypes[RESET_SCHEMAS]: {
      return {
        ...state,
        schema: DEFAULT_STATE.schema,
        uiSchema: DEFAULT_STATE.uiSchema
      };
    }
    case actionTypes[RESET_EDIT]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setValue: (key, value) => ({
    type: actionTypes[SET_VALUE],
    key,
    value
  }),
  resetSchemas: () => ({ type: actionTypes[RESET_SCHEMAS] }),
  resetEdit: () => ({ type: actionTypes[RESET_EDIT] })
};
