import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_VACATIONS } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import { isArray } from '../../../utils/isDefined';

export const getVacations = () => {
  return {
    type: sagaActions[GET_VACATIONS]
  };
};

export function* getAllVacationDays() {
  yield put(loaderReduxActions.startSpinLoader());

  let vacations = [];
  try {
    const { data } = yield call(getData, `${JSONAPI_URLS.VACATIONS}`);

    if (isArray(data)) {
      vacations = data;
    }
  } catch {
  } finally {
    yield put(
      reduxActions.setVacations(createVacationsSelectOptions(vacations))
    );
    yield put(loaderReduxActions.stopSpinLoader());
  }
}

function* watchGetAllVacationDays() {
  yield takeLatest(sagaActions[GET_VACATIONS], getAllVacationDays);
}

export default watchGetAllVacationDays();

export const createVacationsSelectOptions = (vacations = []) => {
  const mappedPrompts = vacations.map(vacation => {
    return { value: vacation.id, label: vacation.holiday, ...vacation };
  });

  return [...mappedPrompts];
};
