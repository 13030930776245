import { isDefined, isArray, isUndefined } from './isDefined';

export default function mapMitelPhoneNumbers(phoneNumbers = []) {
  if (!isArray(phoneNumbers)) {
    return {};
  }
  return phoneNumbers.reduce((obj, number) => {
    const { lineType, lineNumber, userDiversionRules, lineOrderType } = number;

    if (isUndefined(lineType)) {
      return { ...obj };
    }

    const objKey = lineType.toLowerCase();

    if (isDefined(obj[objKey])) {
      return {
        ...obj,
        [objKey]: [
          ...obj[objKey],
          { lineNumber, userDiversionRules, lineOrderType }
        ]
      };
    } else {
      return {
        ...obj,
        [objKey]: [{ lineNumber, userDiversionRules, lineOrderType }]
      };
    }
  }, {});
}
