import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { isString, isUndefined } from '../../../utils/isDefined';

export function createValueLabel(key) {
  if (!isString(key)) {
    return key;
  }
  const mappedLabel = key.split('_').join(' ');

  return capitaliseFirstLetter(mappedLabel);
}

export function mapValue(value) {
  if (isUndefined(value)) {
    return void 0;
  }

  return `${value}`;
}
