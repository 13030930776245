import moment from 'moment';

import { getData } from '../../../utils/http';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';

export default async function getPresence(userId) {
  if (isUndefined(userId)) {
    return { activity: {} };
  }
  let presence = {};
  try {
    const { data } = await getData({
      options: {
        url: `${OUTSIDE_API.USERS}/${userId}/${OUTSIDE_API.PRESENCE}`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    presence = { ...data };
  } finally {
    const { activity = {} } = presence;

    return {
      activity: {
        ...activity,
        expiration: Optional(activity.expiration)
          .map(expiration =>
            expiration !== 'never'
              ? moment(expiration).format('YYYY-MM-DD HH:mm')
              : ''
          )
          .or('')
      }
    };
  }
}
