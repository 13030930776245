import { findFirstAvailableDay } from '../../../utils/dateUtils';
import updatePresence from './updatePresence';
import moment from 'moment';
import { isDefined } from '../../../utils/isDefined';

export default async function updateActivity({ values = {}, userId }) {
  const { activity: id, available } = values;

  const nextWorkingDay = moment(findFirstAvailableDay(1, 'dk')).format(
    'YYYY-MM-DDT08:00:00'
  );
  const formattedExpiration = isDefined(values.expiration)
    ? moment(values.expiration).format()
    : values.expiration;

  let expiration = 'never';

  switch (values.picked) {
    case 'set':
      if (isDefined(formattedExpiration)) {
        expiration = formattedExpiration;
      }
      break;
    case 'nextDay':
      expiration = nextWorkingDay;
      break;
    default:
      break;
  }

  const updatedPresence = await updatePresence({
    userId,
    activity: { id, available, expiration }
  });

  return updatedPresence?.activity;
}
