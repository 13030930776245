import React, { useCallback, useMemo } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import styles from '../styles/dateRow';
import {
  materialDatePickerTheme,
  materialTimePickerTheme
} from '../styles/datePickers';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';
import { DATE_FORMAT, PICKERS_LOCALE } from '../../../consts/initialValues';

function DateRow({
  values,
  intl,
  setFieldValue,
  customErrors,
  settings,
  name,
  classes,
  language,
  isDisabled
}) {
  const title = useMemo(() => {
    return name === 'start'
      ? intl.formatMessage({
          id: 'FROM'
        })
      : intl.formatMessage({
          id: 'TO'
        });
  }, [name, intl]);

  const locale = useMemo(() => PICKERS_LOCALE[language], [language]);

  const fieldDateName = useMemo(() => {
    return name === 'start' ? 'startDate' : 'endDate';
  }, [name]);

  const fieldTimeName = useMemo(() => {
    return name === 'start' ? 'startTime' : 'endTime';
  }, [name]);

  const materialDateTheme = useMemo(() => {
    return materialDatePickerTheme(settings);
  }, [settings]);

  const materialTimeTheme = useMemo(() => {
    return materialTimePickerTheme(settings);
  }, [settings]);

  const onDateChange = useCallback(
    selectedDate => {
      const date = isDefined(selectedDate)
        ? Number(moment(selectedDate).format('x'))
        : null;
      setFieldValue(fieldDateName, date);
    },
    [setFieldValue, fieldDateName]
  );

  const onTimeChange = useCallback(
    selectedTime => {
      const date = isDefined(selectedTime)
        ? Number(moment(selectedTime).format('x'))
        : null;
      setFieldValue(fieldTimeName, date);
    },
    [setFieldValue, fieldTimeName]
  );

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }

  const errorMessage = Optional(customErrors[fieldDateName]).or(null);

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <ThemeProvider theme={materialDateTheme}>
          <div className={classes.label}>{title}</div>
          <div>
            <KeyboardDatePicker
              inputVariant="outlined"
              ampm={false}
              value={values[fieldDateName]}
              onChange={onDateChange}
              format={DATE_FORMAT[language]}
              autoOk={true}
              disabled={
                isDisabled ||
                (!values[`${fieldDateName}Set`] &&
                  (values.recurrenceType === 'DAILY' ||
                    values.recurrenceType === 'WEEKLY'))
              }
              showTodayButton
              todayLabel={intl.formatMessage({
                id: 'TODAY'
              })}
              error={Boolean(customErrors[fieldDateName])}
            />
            <div className={classes.errorMessage}>{errorMessage}</div>
          </div>
        </ThemeProvider>
        <ThemeProvider theme={materialTimeTheme}>
          <KeyboardTimePicker
            ampm={false}
            className={classes.select}
            inputVariant="outlined"
            margin="dense"
            value={values[fieldTimeName]}
            onChange={onTimeChange}
            autoOk={true}
            keyboardIcon={<i className="far fa-clock"></i>}
            invalidDateMessage={intl.formatMessage({
              id: 'INVALID_TIME_FORMAT'
            })}
            initialFocusedDate={0}
            minutesStep={5}
            error={Boolean(customErrors[fieldTimeName])}
            disabled={isDisabled}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

const mapStatesToProps = ({ editSchedule, auth }) => {
  const { hoursOptions, customErrors } = editSchedule;
  return {
    hoursOptions,
    customErrors,
    language: auth.lang
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(DateRow);
