import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, ADD_CONTACT } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import { actions as reduxActions } from '../reducers';
import { actions as userContactsReduxActions } from '../../../pages/userContacts/reducers';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';

export function addContactOnSubmit({ values, isEdit }) {
  return {
    type: sagaActions[ADD_CONTACT],
    values,
    isEdit
  };
}

export function* addContact({ values, isEdit = false }) {
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );
  try {
    const response = yield call(isEdit ? updateData : postData, {
      url: isEdit
        ? `${baseUrl}/api/personal_contacts/user/${clientDomain}/${mitelUserId}/${values.cid}?t=${mitelToken}`
        : `${baseUrl}/api/personal_contacts/user/${clientDomain}/${mitelUserId}?t=${mitelToken}`,
      data: values
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(showSnackbar, {
        intlTextKey: isEdit
          ? 'MESSAGE.CONTACT_UPDATED'
          : 'MESSAGE.CONTACT_ADDED',
        messageType: 'success'
      });
    }

    yield put(reduxActions.closeAddContact());
    yield put(userContactsReduxActions.setRefresh());
  } catch {}
}

function* watchAddContact() {
  yield takeLatest(sagaActions[ADD_CONTACT], addContact);
}

export default watchAddContact();
