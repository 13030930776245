import moment from 'moment-timezone';

import { isStringDefined, isUndefined } from '../../../utils/isDefined';

export default function mapInactiveCellvalue(data) {
  const { och_inactive_at: date, future_status } = data;

  if (isUndefined(date)) {
    return '';
  }
  
  return isStringDefined(future_status)
    ? `${formatDate(date)} - ${future_status}`
    : formatDate(date);
}

export function formatDate(date) {
  if (isUndefined(date)) {
    return '';
  }
 
  return moment(date)
    .format('DD-MM-YYYY');
}
