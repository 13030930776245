import { TextField, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import differenceBy from 'lodash.differenceby';
import flowRight from 'lodash.flowright';
import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import {
  isStringDefined,
  isUndefined,
  isArrayEmpty,
  isStringEmpty
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';
import getGroupData from '../actions/getGroupData';
import { fetchGroups } from '../actions/getGroups';
import { sendSms } from '../actions/postSms';
import styles, { customSelectStyles } from '../styles/callLog';

function SmsContainer({
  classes,
  settings,
  intl,
  sendSms,
  fetchGroups,
  groupsOptions,
  clientDomain,
  userId
}) {
  const [message, setMessage] = useState('');
  const [receivers, setReceivers] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [receiversCounter, setReceiversCounter] = useState(0);

  const permissionToSend = useMemo(
    () =>
      can('create', createSubject('Mitel::Sms', { user_id: Number(userId) })),
    [userId]
  );

  useEffect(() => {
    fetchGroups({ clientDomain });
    // eslint-disable-next-line
  }, [clientDomain]);

  const onMessageChange = e => {
    setMessage(e.target.value);
  };

  const onReceiversChange = e => {
    const numberRegexp = new RegExp(/^[+0-9][0-9,\s]*$/g);
    const lastNumber = e.target.value
      .split(',')
      .reverse()[0]
      .trim();
    const lastChar = e.target.value.split('').reverse()[0];
    if (
      lastChar === ',' ||
      lastNumber.match(numberRegexp) ||
      isStringEmpty(lastNumber)
    ) {
      setReceivers(e.target.value);
    }
  };

  const resetFields = () => {
    setMessage('');
    setReceivers('');
    setSelectedGroups([]);
    setReceiversCounter(0);
  };

  const onSend = () => {
    sendSms({ resetFields, message, receivers, selectedGroups });
  };

  const onChange = async selected => {
    if (isUndefined(selected)) {
      setSelectedGroups([]);
      setReceiversCounter(0);
    } else if (selected.length > selectedGroups.length) {
      const lastElement = selected.reverse()[0];
      const countedUsers = await getGroupData({
        groupName: lastElement.value,
        clientDomain
      });

      setSelectedGroups([...selectedGroups, { ...lastElement, countedUsers }]);

      setReceiversCounter(receiversCounter + countedUsers);
    } else {
      const removedElement = differenceBy(selectedGroups, selected, 'value');

      setSelectedGroups(
        selectedGroups.filter(group => group.value !== removedElement[0]?.value)
      );

      setReceiversCounter(
        receiversCounter - Optional(removedElement[0]?.countedUsers).or(0)
      );
    }
  };

  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <div className={classes.title}>Send Sms</div>

      <div className={classes.receiverContainer}>
        <div>
          {intl.formatMessage({
            id: 'NUMBERS'
          })}
        </div>
        <Tooltip
          title={intl.formatMessage({
            id: 'SMS.NUMBERS_INFO'
          })}
        >
          <i className={clsx('fas fa-info-circle', classes.icon)}></i>
        </Tooltip>

        <TextField
          className={classes.receiverTextfield}
          margin="dense"
          variant="outlined"
          value={receivers}
          onChange={onReceiversChange}
          placeholder={intl.formatMessage({
            id: 'SMS.TEXTFIELD_INFO'
          })}
          disabled={!permissionToSend}
        />
      </div>
      <div className={classes.receiverContainer}>
        <div>
          {intl.formatMessage({
            id: 'GROUPS'
          })}
        </div>
        <Select
          options={groupsOptions}
          onChange={onChange}
          styles={reactSelectDefaultStyles}
          customStyles={customSelectStyles}
          placeholder={intl.formatMessage({
            id: 'SMS.SELECT_GROUP'
          })}
          isMulti
          isSearchable
          isDisabled={!permissionToSend}
          closeMenuOnSelect={false}
        />
      </div>
      <div
        className={clsx(classes.receiverContainer, classes.messageContainer)}
      >
        <div>
          {intl.formatMessage({
            id: 'MESSAGE'
          })}
        </div>
        <TextField
          className={classes.receiverTextfield}
          value={message}
          onChange={onMessageChange}
          margin="dense"
          variant="outlined"
          multiline={true}
          minRows={15}
          disabled={!permissionToSend}
          inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
        />
      </div>
      <div className={classes.countersContainer}>
        <div className={classes.noOfContainer}>{`${intl.formatMessage({
          id: 'SMS.NUMBER_OF_RECEIVERS'
        })} : ${receiversCounter +
          receivers.split(',').filter(el => isStringDefined(el)).length}`}</div>
        <div className={classes.lenghtContainer}>{`${intl.formatMessage({
          id: 'LENGHT'
        })} : ${message.length}`}</div>
      </div>
      <div className={classes.sendContainer}>
        <PrimaryButton
          disabled={
            !permissionToSend ||
            !isStringDefined(message) ||
            (!isStringDefined(receivers) && isArrayEmpty(selectedGroups))
          }
          onClick={onSend}
        >
          Send
        </PrimaryButton>
      </div>
    </div>
  );
}
const mapStatesToProps = ({ auth, userContacts }) => {
  return {
    groupsOptions: userContacts.groupsOptions,
    clientDomain: auth.defaultClient.connect30_domain,
    userId: auth.user.id
  };
};

const mapDispatchToProps = {
  sendSms,
  fetchGroups
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SmsContainer);
