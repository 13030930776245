import * as yup from 'yup';

export default function(intl) {
  return {
    simcardsStep: yup.object().shape({
      provider: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      count: yup
        .string()
        .required(
          intl.formatMessage({
            id: 'AUTH.VALIDATION.REQUIRED_FIELD'
          })
        )
        .test(
          null,
          intl.formatMessage({
            id: 'ORDER_SIMCARD_VALIDATION.QUANTITY_RANGE'
          }),
          value => {
            return value <= 0 || value > 50 ? false : true;
          }
        )
    }),
    addressStep: yup.object().shape({
      shipping_address: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
      name: yup.string().required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
    })
  };
}
