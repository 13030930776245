const styles = theme => {
  return {
    root: {
      display: 'flex',
      height: 'fit-content',
      width: 'fit-content',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      backgroundColor: '#fff',
      marginBottom: '10px'
    },
    info: {
      width: '310px',
      fontSize: '16px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        width: '250px',
      }
    },
    body: { display: 'flex' },
    stats: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '13px',
      alignItems: 'center'
    }
  };
};

export default styles;
