export default {
    root: {
      padding: '10px 20px'
    },
    label: { marginBottom: '15px' },
    service: {
      display: 'flex',
      fontSize: '14px',
      fontWeight: 700,
      backgroundColor: '#FAFAFA'
    },
    serviceId: {
      width: '100px'
    },
    featureInfos: {
      paddingLeft: '100px'
    },
    parameter: {
      display: 'flex',
      paddingLeft: '50px'
    },
    parameterId: {
      width: '100px'
    },
    darkerRow : {
      backgroundColor : '#FAFAFA'
    }
  };
  