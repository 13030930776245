import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import flowRight from 'lodash.flowright';

import styles from './styles/successButton';
import withSettings from '../../utils/withSettings';
import DEFAULT_FRONTEND_SETTINGS from '../../consts/frontendSettings';
import Optional from '../../utils/optional';

function SuccessButton({ children, onClick, classes, settings }) {
  return (
    <Button
      className={classes.root}
      style={{
        backgroundColor: Optional(settings)
          .map(settings => settings.colors.success)
          .or(DEFAULT_FRONTEND_SETTINGS.colors.success),
        color: Optional(settings)
          .map(settings => settings.colors.white)
          .or(DEFAULT_FRONTEND_SETTINGS.colors.white)
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default flowRight(withSettings, withStyles(styles))(SuccessButton);
