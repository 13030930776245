import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';

import PhoneBookStatus from './PhoneBookStatus';
import styles from '../styles/numberServices';
import { isObject } from '../../../utils/isDefined';
import customParseNumber from '../../../utils/customParseNumber';

const NumberServices = ({ classes, numberData, number }) => {
  const [isDanishNumber, setIsDanishNumber] = useState(false);

  useEffect(() => {
    const parsedNumber = customParseNumber(number);
  
    if (
      isObject(parsedNumber) &&
      parsedNumber.country === 'DK' &&
      parsedNumber.isValid() === true
    ) {
      setIsDanishNumber(true);
    }
  }, [number]);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <div className={classes.serviceName}>Service</div>
        <div>Info</div>
        <div className={classes.actions}>Actions</div>
      </div>
      {isDanishNumber && (
        <PhoneBookStatus numberData={numberData} number={number} />
      )}
    </div>
  );
};

export default withStyles(styles)(NumberServices);
