import MaterialTable from '@material-table/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import { translate } from '../../../i18n/I18nProvider';
import {
  TABLE_OPTIONS,
  setLocalization,
  mapTableOptions
} from '../../../consts/tableOptions';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { actions as reduxActions } from '../reducers';
import tableColumns from './tableColumns';
import fetchPhonebookRegistration from '../actions/fetchPhonebookRegistration';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { isDefined } from '../../../utils/isDefined';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import ConnectedNumberRangesTable from './ConnectedNumberRangesTable';
import { customCheck } from '../../../utils/checkMenuItemsList';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

const PhoneBookRegistrationTable = ({
  columns,
  refresh,
  setRefresh,
  setColumns,
  fromAddress,
  userRules
}) => {
  const [filtering, setFiltering] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_OPTIONS.DEFAULT.pageSize);
  const [multieditNumbers, setMultieditNumbers] = useState(void 0);
  const [rowsSelected, setRowsSelected] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [allSelected, setAllSelcted] = useState(false);
  const [query, setQuery] = useState({});

  const location = useLocation();

  const client = getIdFromUrl(location);

  const tableRef = useRef();

  const tableDefaultColumns = useMemo(() => {
    return tableColumns(client);
  }, [client]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: isDefined(client)
        ? 'PhonebookRegistration-2.1'
        : 'PhonebookRegistration-Admin-2.1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  useEffect(() => {
    if (refresh) {
      tableRef.current.onQueryChange();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    tableRef.current.onQueryChange();
    // eslint-disable-next-line
  }, [client]);

  const fetchData = useCallback(
    query => {
      setQuery(query);
      setPageSize(query.pageSize);
      return fetchPhonebookRegistration({ query, client, setTotalCount });
      // eslint-disable-next-line
    },
    [setPageSize, client]
  );

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: translate('BUTTON.FILTER')
        }
      ];

      if (isDefined(client)) {
        buttons = [
          ...buttons,
          {
            tooltip: translate('BUTTON.MULTIEDIT'),
            icon: () => (
              <EditPhoneBookStatus
                isMultiedit={true}
                setReload={setRefresh}
                reload={refresh}
                numbersForMultiedit={multieditNumbers}
                clientId={client}
                query={query}
                allSelected={allSelected}
              >
                <CustomIconButton iconClassName="far fa-edit" />
              </EditPhoneBookStatus>
            )
          },
          {
            icon: () => <RefreshButton tableHeader={true} />,
            onClick: () => setRefresh(true),
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, client, multieditNumbers, allSelected, query]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      pageSize,
      filtering,
      toolbar: !fromAddress,
      detailPanelColumnStyle: { display: 'none', width: '5px' },
      selection:
        isDefined(client) && customCheck('Phonebook', userRules) ? true : false
    };
  }, [filtering, pageSize, client, fromAddress, userRules]);

  const localization = useMemo(() => {
    let loc = { ...setLocalization() };
    return {
      ...loc,
      toolbar: {
        ...loc.toolbar,
        nRowsSelected: count => {
          if (rowsSelected < pageSize) {
            setAllSelcted(false);
          }

          return allSelected ? (
            <div>
              {translate('ALL_ROWS_SELECTED', {
                count: totalCount
              })}
              <p
                style={{ cursor: 'pointer', fontWeight: 500 }}
                onClick={() => setAllSelcted(false)}
              >
                {translate('CLEAR_SELECTION')}
              </p>
            </div>
          ) : (
            <div>
              {`${count} ${translate('TABLE.ROWS_SELECTED')} ${
                rowsSelected === pageSize && totalCount - pageSize > 0
                  ? translate('MORE_ITEMS', {
                      count: totalCount - pageSize
                    }).toLowerCase()
                  : ''
              }`}

              {rowsSelected === pageSize && totalCount - pageSize > 0 && (
                <p
                  style={{ cursor: 'pointer', fontWeight: 500 }}
                  onClick={() => setAllSelcted(true)}
                >
                  {translate('SELECT_ALL_ROWS')}
                </p>
              )}
            </div>
          );
        }
      }
    };
    // eslint-disable-next-line
  }, [pageSize, rowsSelected, allSelected]);

  const onSelectRow = rows => {
    setRowsSelected(rows.length);
    const mappedNumbers = rows.map(el => {
      return {
        number: el.number,
        rangeId: el.external_number_range?.id,
        id: el.id
      };
    });
    setMultieditNumbers(mappedNumbers);
  };

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: isDefined(client)
          ? 'PhonebookRegistration-2.1'
          : 'PhonebookRegistration-Admin-2.1'
      });
    },
    [setColumns, tableDefaultColumns, client]
  );

  const detailPanel = [
    rowData => ({
      icon: () => null,
      render: () => (
        <ConnectedNumberRangesTable
          numberRange={rowData.external_number_range}
        />
      )
    })
  ];

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.PHONEBOOK_REGISTRATION"
            icon={<i className="fas fa-address-book"></i>}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={fetchData}
        options={options}
        localization={localization}
        tableRef={tableRef}
        onChangeColumnHidden={onChangeColumnHidden}
        onSelectionChange={rows => onSelectRow(rows)}
        detailPanel={detailPanel}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  );
};

const mapStatesToProps = ({ phonebookRegistration, auth }) => {
  return {
    ...phonebookRegistration,
    userRules: auth.user?.rules
  };
};

const mapDispatchToProps = {
  setRefresh: reduxActions.setRefresh,
  setColumns: reduxActions.setColumns
};

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(PhoneBookRegistrationTable);
