import { JSONAPI_URLS, URLS } from "../../../consts/endpoints";
import { getData } from "../../../utils/http";
import {
  isArray,
  isDefined,
  isString,
  isUndefined,
} from "../../../utils/isDefined";
import urlCreator from "../../../utils/urlCreator";

const getSimcard = async ({ clientId, subscriptionId }) => {
  try {
    const subDetails = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${subscriptionId}`
    );

    if (isUndefined(subDetails)) {
      return void 0;
    }

    const features = subDetails?.features;

    if (isUndefined(features) || !isArray(features)) {
      return void 0;
    }

    const subsSimcard = features.find(
      feature => feature.key === "phone-and-sim-card"
    );

    if (isUndefined(subsSimcard)) {
      return void 0;
    }

    const featureAttributes = subsSimcard?.featureAttributes;

    if (isUndefined(featureAttributes) || !isArray(featureAttributes)) {
      return void 0;
    }

    const networkSimCard = featureAttributes.find(
      attr => attr.key === "network-sim-card"
    );

    if (isUndefined(networkSimCard)) {
      return void 0;
    }

    const icc = networkSimCard?.value;

    if (isUndefined(icc) || !isString(icc)) {
      return void 0;
    }

    const queryParams = {
      include: ["owner"],
      fields: { users: "name" },
      filter: {
        client_id: clientId,
        icc,
      },
    };

    let simcard = void 0;

    const { data } = await getData(
      urlCreator(JSONAPI_URLS.SIM_CARDS, queryParams)
    );

    if (isDefined(data) && isArray(data)) {
      simcard = data[0];
    }

    return {
      simcard,
      subDetails,
    };
  } catch (e) {
    return { error: e.response.data.errors };
  }
};

export default getSimcard;
