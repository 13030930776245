import { createSagaActionTypes } from '../../../utils/actionFactory';
export const GET_SELECTS_OPTIONS = 'GET_SELECTS_OPTIONS';
export const UPDATE_SIMCARD = 'UPDATE_SIMCARD';
export const GET_SIMCARD = 'GET_SIMCARD';

export const actions = createSagaActionTypes('EDIT_SIMCARD', [
  GET_SELECTS_OPTIONS,
  UPDATE_SIMCARD,
  GET_SIMCARD
]);
