import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_ROLE } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';

import showSnackbar from '../../../utils/showSnackbar';
import mapDataToUpdate from './mapDataToUpdate';
import { getRoles } from '../../../pages/roles/actions/getRoles';

export function updateOnSubmit({ values, role, isEdit }) {
  return {
    type: sagaActions[UPDATE_ROLE],
    values,
    role,
    isEdit
  };
}

export function* updateRole({ values, role, isEdit }) {
  const mappedData = mapDataToUpdate({ values, role, isEdit });

  const response = isEdit
    ? yield call(updateData, {
        url: `${URLS.ROLES}/${role.id}`,
        data: {
          data: mappedData
        }
      })
    : yield call(postData, {
        url: URLS.ROLES,
        data: {
          data: mappedData
        }
      });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.UPDATE.ROLES',
      messageType: 'success'
    });
  }

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.ADD.ROLES',
      messageType: 'success'
    });
  }
  yield call(getRoles, {});
  yield put(reduxActions.closeEditRole());
}

function* watchUpdateRole() {
  yield takeLatest(sagaActions[UPDATE_ROLE], updateRole);
}

export default watchUpdateRole();
