import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import { actions as reduxActions } from '../reducers';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import deletePhonebookStatus from '../actions/deletePhonebookStatus';
import MoreActionsMenu from './MoreActionsMenu';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';

const TableActionButtons = ({
  data,
  classes,
  refresh,
  setRefresh,
  settings
}) => {
  const location = useLocation();
 
  const client = getIdFromUrl(location);

  const onDeleteClick = useCallback(async () => {
    const requestComplete = await deletePhonebookStatus(data.id);

    if (requestComplete) {
      setRefresh(!refresh);
    }
  }, [refresh, data.id, setRefresh]);

  return (
    <div className={classes.actionButtons}>
      {(isUndefined(client) ||
        (isDefined(client) &&
          can(
            'update',
            createSubject('PhoneBookRegistration', {
              external_number_range_id: Number(data?.external_number_range?.id)
            })
          ))) && (
        <EditPhoneBookStatus
          number={`45${data?.number}`}
          setReload={setRefresh}
          reload={refresh}
          numberRangeId={data?.external_number_range?.id}
          shouldFetchClient={isUndefined(client)}
          clientId={isDefined(client) ? client : void 0}
        >
          <CustomIconButton
            iconTooltipIntlKey="EDIT"
            iconClassName="far fa-edit"
          />
        </EditPhoneBookStatus>
      )}
      {(isUndefined(client) ||
        (isDefined(client) &&
          can(
            'delete',
            createSubject('PhoneBookRegistration', {
              external_number_range_id: Number(data?.external_number_range?.id)
            })
          ))) && (
        <MoreActionsMenu
          onDeleteClick={onDeleteClick}
          client={client}
          rangeId={data?.external_number_range?.id}
        >
          <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
            <i
              className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
              style={{
                color: settings.colors.links
              }}
            ></i>
          </Tooltip>
        </MoreActionsMenu>
      )}
    </div>
  );
};

const mapStatesToProps = ({ phonebookRegistration }) => {
  return {
    refresh: phonebookRegistration.refresh
  };
};

const mapDispatchToProps = {
  setRefresh: reduxActions.setRefresh
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
