import { OUTSIDE_API } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';
import {
  doesArrayHasLength,
  isArray,
  isUndefined
} from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

const sendActivationMessage = async ({ clientDomain, userConnectId, data }) => {
  if (isUndefined(clientDomain)) {
    showSnackbar({
      intlTextKey: 'MESSAGE.NO_DOMAIN',
      messageType: 'error'
    });

    return { isActionCompleted: false };
  }
  const mappedData = mapData({ userConnectId, data });

  try {
    const response = await postData({
      url: `${OUTSIDE_API.CLIENTS}/${clientDomain}/activation_message`,
      data: mappedData
    });

    const { data, status } = response;

    if (status === RESPONSE_CODES.CREATED) {
      if (isUndefined(data)) {
        showSnackbar({
          intlTextKey: 'MESSAGE.ACTIVATION_MESSAGE_SEND',
          messageType: 'success'
        });

        return { isActionCompleted: true };
      }

      if (data.succeededUsers > 0) {
        showSnackbar({
          intlTextKey: 'MESSAGE.ACTIVATION_MESSAGE_SEND',
          messageType: 'success'
        });

        return { isActionCompleted: true };
      } else {
        if (isArray(data.activationResultDTOs)) {
          if (!data.activationResultDTOs.isHavingFailureReasons) {
            return {
              isActionCompleted: false,
              responseErrors: [
                translate('MESSAGE.NO_REASON'),
                translate('MESSAGE.CONTACT_SUPPORT')
              ]
            };
          }

          const devices = data.activationResultDTOs.reduce((arr, el) => {
            if (isArray(el.devices)) {
              return [...arr, ...el.devices];
            }
            return arr;
          }, []);

          let responseErrors = devices.reduce((arr, el) => {
            if (doesArrayHasLength(el.failureReasons)) {
              return [...arr, ...el.failureReasons];
            }

            return arr;
          }, []);
          
          return { isActionCompleted: false, responseErrors };
        }

        showSnackbar({
          intlTextKey: 'MESSAGE.CONTACT_SUPPORT',
          messageType: 'error'
        });

        return { isActionCompleted: false };
      }
    }

    return { isActionCompleted: false };
  } catch {
    return { isActionCompleted: false };
  }
};

export default sendActivationMessage;

const mapData = ({ userConnectId, data }) => {
  return {
    userId: [userConnectId],
    ...data
  };
};
