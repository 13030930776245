import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import styles from '../styles/myNumbers';
import NumbersInfo from './NumbersInfo';
import TransferNumbers from '../../../modals/transferNumbers/TransferNumbers';
import { translate } from '../../../i18n/I18nProvider';
import flowRight from 'lodash.flowright';
import withSettings from '../../../utils/withSettings';
import Optional from '../../../utils/optional';
import DEFAULT_FRONTEND_SETTINGS from '../../../consts/frontendSettings';

const MyNumbers = ({ classes, settings }) => {

  return (
    <div className={classes.root}>
      <header className={classes.title}>{translate('NUMBER.MY_NUMBER')}</header>
      <NumbersInfo />
      <div className={classes.actionContainer}>
        <TransferNumbers
        >
          <Button
            style={{
              backgroundColor: Optional(settings)
                .map(settings => settings.colors.aside_menu)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.aside_menu),
              color: Optional(settings)
                .map(settings => settings.colors.aside_menu_text)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.baside_menu_text)
            }}
          >
            <div className={classes.butonLabel}>
              {translate('NUMBERS.FORWARD_NUMBERS')}
              <i className={clsx('far fa-sign-out', classes.icon)}></i>
            </div>
          </Button>
        </TransferNumbers>
      </div>
    </div>
  ) 
};

export default flowRight(
  withSettings,
  withStyles(styles)
)(MyNumbers);