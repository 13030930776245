import { URLS } from '../../../consts/endpoints';
import { translate } from '../../../i18n/I18nProvider';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { getData } from '../../../utils/http';
import { doesArrayHasLength, isStringDefined } from '../../../utils/isDefined';

export default async function getTemplates({
  setTemplate,
  setTemplates,
  setInfo,
  clientId,
  isNonClientAdminUser
}) {
  try {
    const templates = await getData(
      isNonClientAdminUser
        ? URLS.CONFIG_TEMPLATES
        : `${URLS.CLIENTS}/${clientId}/configs`
    );

    if (doesArrayHasLength(templates)) {
      const mappedTemplates = templates.reduce((arr, template) => {
        const { name } = template;

        if (isStringDefined(name)) {
          return [
            ...arr,
            {
              ...template,
              value: name,
              label: capitaliseFirstLetter(name).replaceAll('_', ' ')
            }
          ];
        }

        return arr;
      }, []);

      if (doesArrayHasLength(mappedTemplates)) {
        setTemplates(mappedTemplates);
        setTemplate(mappedTemplates[0]);
      } else {
        setInfo(translate('CLIENT.NO_SETTINGS'));
      }
    } else {
      setInfo(translate('CLIENT.NO_SETTINGS'));
    }
  } catch {}
}
