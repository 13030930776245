import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';

const getSubscriptionDetails = async ({
  setIsLoading = () => {},
  clientId,
  subId
}) => {
  setIsLoading(true);
  let problems = [];

  try {
    const details = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${subId}`
    );

    const subProblems = details?.problems;

    if (isDefined(subProblems)) {
      problems = subProblems;
    }
  } catch {
  } finally {
    setIsLoading(false);
    return problems;
  }
};

export default getSubscriptionDetails;
