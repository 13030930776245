import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

import styles from './styles';
import clsx from 'clsx';

function QueueStatRow({
  value,
  intl,
  classes,
  label,
  customStyles = {},
  helpTooltipIntlKey = 'EMPTY',
  infoIcon = true
}) {
  return (
    <div className={classes.root} style={customStyles.root}>
      <div className={classes.labelContainer}>
        <div>
          {intl.formatMessage({
            id: label
          })}
        </div>

        {infoIcon && (
          <Tooltip
            title={intl.formatMessage({
              id: helpTooltipIntlKey
            })}
          >
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <div className={classes.value} style={customStyles.value}>
        {value}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(QueueStatRow);
