import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { actions as reduxActions } from '../reducers';
import { setRange } from '../../../modals/editRange/actions/setRange';

const RangeMoreActionsMenu = ({
  children,
  numberRange = {},
  setRefresh,
  refresh,
  setRange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = useCallback(
    () => {
      handleClose();
      return setRange({ rangeId: numberRange.id, fromPhoneBook: true });
    },
    // eslint-disable-next-line
    [numberRange]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {can(
          'update',
          createSubject('ExternalNumberRange', {
            client_id: Number(numberRange.client?.id)
          })
        ) && (
          <MenuItem onClick={onEditClick}>
            {translate('TOOLTIP.EDIT_RANGE')}
          </MenuItem>
        )}
        {can(
          'update',
          createSubject('PhoneBookRegistration', {
            external_number_range_id: Number(numberRange.id)
          })
        ) &&
          numberRange?.interval > 1 && (
            <div>
              <EditPhoneBookStatus
                number={numberRange?.range_from}
                clientId={numberRange?.client_id}
                numberRangeId={numberRange?.id}
                closeMenuOnDialogOpen={handleClose}
                setReload={setRefresh}
                reload={refresh}
                numberRangeForSelect={
                  numberRange?.interval > 1
                    ? [numberRange?.range_from, numberRange?.range_up_to_incl]
                    : void 0
                }
              >
                <MenuItem>{translate('PHONE_BOOK.TITLE')}</MenuItem>
              </EditPhoneBookStatus>
            </div>
          )}
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ phonebookRegistration }) => {
  return {
    refresh: phonebookRegistration.refresh
  };
};

const mapDispatchToProps = {
  setRefresh: reduxActions.setRefresh,
  setRange
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps)
)(RangeMoreActionsMenu);
