const styles = theme => {
  return {
    root: { backgroundColor: '#F0F0F0' },
    dialogContent: {
      minHeight: '70vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    paperRoot: {
      width: '100%'
    },
    tableTitle: {
      display: 'flex',
      alignItems: 'center'
    },
    titleButtons: {
      marginRight: '10px'
    },
    selectContainer: { display: 'flex', alignItems: 'center' }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  menu: { width: '30px', top: 'unset' },
  container: { position: 'initial', zIndex: 100 },
  control: {
    border: 'unset'
  },
  indicatorSeparator: { display: 'none' },
  indicatorsContainer: { padding: '0' }
};
