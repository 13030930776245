const mapDataToUpdate = ({ range }) => {
  const {
    och_active_at,
    och_inactive_at,
    csbc_routing,
    id,
    network,
    status,
    future_status,
    description
  } = range;

  let data = {
    id: Number(id),
    type: 'external_number_ranges',
    attributes: {
      och_active_at,
      och_inactive_at,
      csbc_routing,
      status,
      future_status,
      description
    },
    relationships: {
      provider: {
        data: { type: 'providers', id: Number(network) }
      }
    }
  };

  return data;
};

export default mapDataToUpdate;
