import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_SELECT_OPTIONS = 'SET_SELECT_OPTIONS';
const SET_REFRESH = 'SET_REFRESH';
const RESET = 'RESET';
const SET_COLUMNS = 'SET_COLUMNS';
const SET_ACTIVE = 'SET_ACTIVE';

export const actionTypes = createReduxActionTypes('SIMCARDS', [
  SET_REFRESH,
  SET_SELECT_OPTIONS,
  SET_COLUMNS,
  SET_ACTIVE,
  RESET
]);

const DEFAULT_STATE = {
  refresh: false,
  selectsOptions: {},
  columns: [],
  active: 'true'
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_REFRESH]: {
      return { ...state, refresh: action.refresh };
    }
    case actionTypes[SET_SELECT_OPTIONS]: {
      return { ...state, selectsOptions: { ...action.selectsOptions } };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[SET_ACTIVE]: {
      return { ...state, active: action.active };
    }
    case actionTypes[RESET]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setRefresh: refresh => ({
    type: actionTypes[SET_REFRESH],
    refresh
  }),
  setSelectsOptions: selectsOptions => ({
    type: actionTypes[SET_SELECT_OPTIONS],
    selectsOptions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  setActive: active => ({
    type: actionTypes[SET_ACTIVE],
    active
  }),
  resetSimCards: () => ({ type: actionTypes[RESET] })
};
