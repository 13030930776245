import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, ADD_NEW_PERMISSION } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import { fetchPermissions } from '../../../pages/permissions/actions/getPermissions';
import { isStringDefined } from '../../../utils/isDefined';
import { actions as reduxActions } from '../reducers';

export function addNewPermission({ values, isEdit, id }) {
  return {
    type: sagaActions[ADD_NEW_PERMISSION],
    values,
    isEdit,
    id
  };
}

export function* addPermission({ values, isEdit, id }) {
  const { name, conditions, description, remote } = values;
  const data = {
    type: 'permissions',
    attributes: {
      name,
      conditions: isStringDefined(conditions?.trim()) ? conditions : null,
      description: isStringDefined(description?.trim()) ? description : null,
      remote
    }
  };
  const response = isEdit
    ? yield call(updateData, {
        url: `${URLS.PERMISSIONS}/${id}`,
        data: {
          data: { ...data, id: Number(id) }
        }
      })
    : yield call(postData, {
        url: `${URLS.PERMISSIONS}`,
        data: {
          data
        }
      });

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.ADD_PERMISSION',
      messageType: 'success'
    });

    yield call(fetchPermissions, {});
  }

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.EDIT_PERMISSION',
      messageType: 'success'
    });

    yield call(fetchPermissions, {});
    yield put(reduxActions.closeAddPermission());
  }
}

function* watchAddAddress() {
  yield takeLatest(sagaActions[ADD_NEW_PERMISSION], addPermission);
}

export default watchAddAddress();
