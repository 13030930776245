import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import { isUndefined } from '../../../utils/isDefined';
import mapCustomFilters from './mapCustomFilters';

export default async function fetchChangeLog({ query, clientId }) {
  if (isUndefined(clientId) || clientId === 'empty') {
    return {
      data: [],
      page: 0,
      totalCount: 0
    };
  }
  const queryParams = mapQueryParams({ clientId });

  const requestUrl = JSONAPI_URLS.VERSIONS;

  return await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: '',
    mapCustomFilters
  });
}

export function mapQueryParams({ clientId }) {
  let queryParams = {
    filter: { client_id: { eq: clientId } }
  };

  return queryParams;
}
