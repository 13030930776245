import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import { actions as queueStatsReduxActions } from '../../../modals/queueStats/reducers';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import compareIds from '../../../utils/compareIds';
import Optional from '../../../utils/optional';
import { actions as editQueueReduxActions } from '../../../modals/editQueue/reducers';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

export function TableActionButtons({
  data: queue,
  classes,
  openQueueStats,
  queuesStats,
  openEditQueue,
  showEdit = false
}) {
  const queueToShow = useMemo(() => {
    const queueStats = queuesStats.find(stats =>
      compareIds(stats.id, queue.id)
    );

    return Optional(queueStats).or({});
  }, [queuesStats, queue.id]);

  const onEditClick = useCallback(() => {
    openEditQueue(queue);
    // eslint-disable-next-line
  }, [queue]);

  return (
    <div className={classes.actionCell}>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.SHOW_STATS"
        onClick={() => openQueueStats(queueToShow)}
        iconClassName="far fa-tachometer-alt"
      />
      {can('update', createSubject('Mitel::Queue', queue)) && showEdit && (
        <CustomIconButton
          iconTooltipIntlKey="EDIT_QUEUE"
          onClick={onEditClick}
          iconClassName="far fa-edit"
        />
      )}
    </div>
  );
}
const mapStatesToProps = ({ queues }) => {
  return {
    queuesStats: queues.queuesStats
  };
};

const mapDispatchToProps = {
  openQueueStats: queueStatsReduxActions.openQueueStats,
  openEditQueue: editQueueReduxActions.openEditQueue
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
