const styles = theme => {
  return {
    root: {
      width: '500px',
      display: 'inherit',
      flexDirection: 'column',
      margin: '0 5px',
      [theme.breakpoints.down('xs')]: {
        width: '310px'
      }
    },
    labelContainer: {
      display: 'flex',
      backgroundColor: '#FFF',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      padding: '20px'
    },
    content: {},
    label: {
      marginLeft: '10px'
    },
    container: {
      width: '490px',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px',
      padding: '10px 0',
      [theme.breakpoints.down('xs')]: {
        width: '300px'
      }
    }
  };
};

export default styles;
