import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import { doesArrayHasLength, isArray } from '../../../utils/isDefined';
import ScheduleDetailView from './ScheduleDetailView';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import ShowHideButton from '../../../partials/tableCustomButtons/ShowHideButton';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { actions as addingSchedulesReduxActions } from '../../../modals/addingSchedules/reducers';
import { getSchedules } from '../actions/getSchedules';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

export function SchedulesTable({
  schedules,
  intl,
  isLoading,
  columns,
  setColumns,
  clientId,
  openAddingSchedules,
  connectClientId,
  getSchedules
}) {
  const [filtering, setFiltering] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getSchedules(connectClientId);
    // eslint-disable-next-line
  }, [connectClientId]);

  useEffect(() => {
    if (refresh) {
      getSchedules(connectClientId);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ clientId });
  }, [clientId]);

  const schedulesToShow = useMemo(() => {
    if (!isArray(schedules)) {
      return [];
    }
    if (showAll) {
      return schedules;
    } else {
      return schedules.filter(schedule => schedule.schedules.length > 1);
    }
  }, [schedules, showAll]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Schedules-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => [
      {
        icon: () => <ShowHideButton show={showAll} />,
        onClick: () => {
          setShowAll(!showAll);
        },
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'TOOLTIP.SHOW_HIDDEN_SHEDULES' })
      },
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
      },
      {
        tooltip: intl.formatMessage({ id: 'EDIT_SCHEDULE.ADD_NEW' }),
        icon: () => <CustomIconButton iconClassName="far fa-plus" />,
        onClick: (evt, data) =>
          openAddingSchedules({
            schedules: data
          })
      },
      {
        icon: () => <RefreshButton tableHeader={true} />,
        onClick: () => setRefresh(true),
        isFreeAction: true
      }
    ],
    // eslint-disable-next-line
    [filtering, showAll]
  );

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      filtering,
      selection: true,
      header: doesArrayHasLength(schedules),
      headerStyle: {
        zIndex: 2
      }
    };
  }, [filtering, schedules]);

  const detailPanel = useCallback(
    ({ rowData }) => (
      <ScheduleDetailView data={rowData.schedules} group={rowData} />
    ),
    []
  );

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Schedules-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      title={
        <CustomTableTitle
          text="MENU.SCHEDULES"
          icon={<i className="fas fa-calendar-alt"></i>}
          isLoading={isLoading}
        />
      }
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={schedulesToShow}
      options={tableOptions}
      detailPanel={detailPanel}
      localization={localization}
      onChangeColumnHidden={onChangeColumnHidden}
    />
  );
}

const mapStatesToProps = ({ schedules, selectClient, loader }) => {
  return {
    ...schedules,
    isLoading: loader.isSpinVisible,
    clientId: selectClient.selectedClient?.id,
    connectClientId: selectClient.selectedClient?.connect30_domain
  };
};

const mapDispatchToProps = {
  openAddingSchedules: addingSchedulesReduxActions.openDiaog,
  setColumns: reduxActions.setColumns,
  getSchedules
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(SchedulesTable);
