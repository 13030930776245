import React from 'react';
import { withStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import EditPhoneBookStatus from '../../../modals/editPhoneBookStatus/EditPhoneBookStatus';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { isDefined, isUndefined } from '../../../utils/isDefined';

export function PhoneBookActionButtons({ data, classes, refresh, setRefresh }) {
  const location = useLocation();

  const client = getIdFromUrl(location);
  
  return (
    <div className={classes.actionButtons}>
      {(isUndefined(client) ||
        (isDefined(client) &&
          can(
            'update',
            createSubject('PhoneBookRegistration', {
              external_number_range_id: data.external_number_range_id
            })
          ))) && (
        <EditPhoneBookStatus
          number={`45${data?.number}`}
          setReload={setRefresh}
          reload={refresh}
          numberRangeId={data?.external_number_range?.id}
          shouldFetchClient={isUndefined(client)}
          clientId={isDefined(client) ? client : void 0}
        >
          <CustomIconButton
            iconTooltipIntlKey="EDIT"
            iconClassName="far fa-edit"
          />
        </EditPhoneBookStatus>
      )}
    </div>
  );
}

export default withStyles(styles)(PhoneBookActionButtons);
