import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';
import moment from 'moment';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_DATA_LIMITS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import mapDataLimits from './mapDataLimits';
import getFullTableData from '../../../utils/getFullTableData';

export function getDataLimits(clientId, month) {
  return {
    type: sagaActions[GET_DATA_LIMITS],
    clientId,
    month
  };
}

export function* fetchDataLimits({ clientId, month }) {
  yield put(loaderReducActions.startSpinLoader());
  const QUERY_PARAMS = yield call(mapQueryParams, { clientId, month });

  const dataLimits = yield call(
    getFullTableData,
    JSONAPI_URLS.DATA_LIMITS,
    QUERY_PARAMS
  );

  const mappedDatLimits = yield call(mapDataLimits, dataLimits);

  const monthsOptions = yield call(createMonthsOptions, 5);

  yield put(reduxActions.setDataLimits(mappedDatLimits, monthsOptions));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetDataLimits() {
  yield takeLatest(sagaActions[GET_DATA_LIMITS], fetchDataLimits);
}

export default watchGetDataLimits();

export function mapQueryParams({ clientId, month }) {
  let params = {
    include: ['cell_numbers.sim_card.owner', 'client'],
    sort: 'name',
    extra_fields: { data_limits: 'accumulated_data_usage_month' },
    filter: {
      client_id: { eq: clientId }
    }
  };

  if (month !== 'current') {
    params = {
      ...params,
      filter: { ...params.filter, data_usage_month: month }
    };
  }

  return params;
}

export function createMonthsOptions(count) {
  const currentMonth = moment();
  let months = [currentMonth];

  for (let i = 0; i < count - 1; i++) {
    const previousMonth = moment(months[i]).subtract(1, 'M');
    months = [...months, previousMonth];
  }

  const options = months.map((month, i) => {
    const option = moment(month).format('YYYY-MM');
    return { value: i === 0 ? 'current' : option, label: option };
  });

  return options;
}
