import XMLParser from 'react-xml-parser';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';

export default function inCallListener({
  mitelToken,
  baseUrl,
  clientDomain,
  userId
}) {
  const sse = new EventSource(
    `${baseUrl}/api/contacts/v1/info/v1/${clientDomain}/${userId}/incall?t=${mitelToken}`
  );

  sse.onmessage = e => 
    store.dispatch(reduxActions.updateUserContacts(getRealtimeData(e.data)));
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
  return () => {
    sse.close();
  };
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);

  const state = Optional(xml.getElementsByTagName('state')[0])
    .map(state => state.value)
    .or(void 0);

  const entity = Optional(xml?.attributes?.entity)
    .map(data => data.split(':')[1])
    .or(void 0);

  const contacts = store.getState().userContacts.contacts;

  if (isUndefined(state)) {
    return contacts;
  }

  const updatedContacts = contacts.map(contact => {
    if (contact.id !== entity) {
      return contact;
    }

    return {
      ...contact,
      incall: state === 'confirmed' ? true : false
    };
  });

  return updatedContacts;
}
