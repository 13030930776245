import { isUndefined } from './isDefined';

export default function getNestedValue(dataObj = {}, objKeysString = '') {
  return objKeysString.split('.').reduce((obj, key) => {
    if (isUndefined(obj)) {
      return obj;
    }

    return obj[key];
  }, dataObj);
}
