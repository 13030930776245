import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { flowRight } from 'lodash';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { submitUpdateDiversion } from '../actions/updateDiversions';
import TransferRow from './TransferRow';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { isDefined } from '../../../utils/isDefined';
import styles from '../styles/transferNumberForm';
import withUserCheck from '../../../utils/withUserCheck';
import validateSchema from '../actions/validateSchema';

const TransferNumbersForm = ({
  mappedDiversions,
  intl,
  classes,
  diversions,
  userId,
  isCurrentUserSelectedClientUser,
  onCloseDialog,
  submitUpdateDiversion
}) => {
  const onSubmit = useCallback(
    values => {
      submitUpdateDiversion({
        values,
        diversions,
        userId,
        isCurrentUserSelectedClientUser,
        onCloseDialog
      });
    },
    // eslint-disable-next-line
    [diversions, userId]
  );

  const initialValues = useMemo(() => {
    return {
      ...mappedDiversions,
      transferAll: {
        isForwarded: false,
        forwardNumber: ''
      }
    };
  }, [mappedDiversions]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema(initialValues)}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        dirty,
        setFieldValue
      }) => {
        const rowProps = {
          errors,
          values,
          handleBlur,
          handleChange,
          setFieldValue
        };
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            <div className={classes.numbersContainer}>
              <div className={classes.transfer}>{`${intl.formatMessage({
                id: 'FORWARD'
              })}`}</div>
              {isDefined(values.office_fixed) && (
                <TransferRow
                  label={`${intl.formatMessage({
                    id: 'NUMBERS.OFFICE_FIXED'
                  })} :`}
                  valueKey="office_fixed"
                  isPhoneLink={true}
                  {...rowProps}
                />
              )}
              {isDefined(values.office_mobile) && (
                <TransferRow
                  label={`${intl.formatMessage({
                    id: 'NUMBERS.OFFICE_MOBILE'
                  })} :`}
                  valueKey="office_mobile"
                  isPhoneLink={true}
                  {...rowProps}
                />
              )}
              {isDefined(values.private_fixed) && (
                <TransferRow
                  label={`${intl.formatMessage({
                    id: 'NUMBERS.PRIVATE_FIXED'
                  })} :`}
                  valueKey="private_fixed"
                  isPhoneLink={true}
                  {...rowProps}
                />
              )}
              {isDefined(values.private_mobile) && (
                <TransferRow
                  label={`${intl.formatMessage({
                    id: 'NUMBERS.PRIVATE_MOBILE'
                  })} :`}
                  valueKey="private_mobile"
                  isPhoneLink={true}
                  {...rowProps}
                />
              )}
              {isDefined(values.datacard) && (
                <TransferRow
                  label={`${intl.formatMessage({
                    id: 'NUMBER.DATACARD'
                  })} :`}
                  valueKey="datacard"
                  {...rowProps}
                />
              )}
              {Object.keys(mappedDiversions).length > 1 && (
                <TransferRow
                  checkboxLabel={`${intl.formatMessage({
                    id: 'NUMBERS.FORWARD_ALL'
                  })}`}
                  valueKey="transferAll"
                  value={''}
                  isTransferAllCheckbox={true}
                  {...rowProps}
                />
              )}
              <footer className={classes.footer}>
                <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
                  {intl.formatMessage({
                    id: 'BUTTON.SAVE'
                  })}
                </PrimaryButton>
                <SecondaryButton onClick={onCloseDialog}>
                  {intl.formatMessage({
                    id: 'BUTTON.CANCEL'
                  })}
                </SecondaryButton>
              </footer>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStatesToProps = ({ user }) => {
  const { mappedDiversions, diversions, connect30_user_id } = user;

  return {
    mappedDiversions,
    diversions,
    userId: connect30_user_id
  };
};

const mapDispatchToProps = {
  submitUpdateDiversion
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withUserCheck,
  withStyles(styles)
)(TransferNumbersForm);
