import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import { isDefined } from '../../../utils/isDefined';
import mapCustomFilters from './mapCustomFilters';

export default async function fetchPhonebookRegistration({
  query,
  client,
  setTotalCount
}) {
  let queryParams = {
    extra_fields: { phone_book_registrations: 'default_address' }
  };

  if (isDefined(client)) {
    queryParams = { ...queryParams, filter: { client_id: client } };
  }

  const requestUrl = JSONAPI_URLS.PHONE_BOOK_REGISTRATION;

  const response = await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: 'number',
    mapCustomFilters
  });

  setTotalCount(response?.totalCount);

  return response;
}
