import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';

export default async function getExcel({ invoiceId, clientId }) {
  try {
    const data = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/invoices/${invoiceId}/details.xlsx?groupingOptions=START`
    );

    const blob = new Blob([data], { responseType: 'application/xlsx' });
    return { data: blob, fileName: `${invoiceId}_details.xlsx` };
  } catch {
    return {};
  }
}
