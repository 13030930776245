const styles = theme => ({
  root: {
    width: '400px',
    margin: '5px',
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      margin: '5px 0',
    }
  },
  container: {
    display: 'flex',
    width: '100%',
    padding: '5px',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10px'
    }
  },
  label: {
    fontWeight: 500
  },
  firstRow: {
    height: '40px',
    padding: '10px',
    fontSize: '16px',
    color: '#93A2DD'
  },
  userStatus: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '250px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 100px)'
    }
  },
  secondRow: {
    margin: ' 10px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: ' 0 10px 20px 10px'
    }
  },
  thirdRow: {
    flexDirection: 'column',
    fontSize: '12px',
    color: '#48465B'
  },
  fourthRow: {
    flexDirection: 'column',
    margin: '50px 30px 30px',
    fontSize: '12px',
    fontWeight: 500
  },
  row: {
    display: 'flex'
  },
  photo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90px',
    height: '90px',
    backgroundColor: '#fff',
    border: '1px solid #EBEDF2',
    borderRadius: '50%'
  },
  initials: {
    fontSize: '28px',
    fontWeight: 500
  },
  presence: {
    width: '130px',
    fontWeight: 500,
    fontSize: '12px'
  },
  title: {
    color: '#48465B',
    fontSize: '15px',
    fontWeight: 500,
    marginRight: '10px'
  },
  name: {
    color: '#000',
    fontSize: '15px',
    fontWeight: 700,
    margin: '0 5px'
  },
  sectionButton: {
    color: '#74788D',
    cursor: 'pointer',
    padding: '14px 0',
    '& i': {
      padding: '0 20px',
      color: '#000'
    },
    '&:hover': {
      backgroundColor: '#F2F4FF'
    }
  },
  choosenSection: {
    backgroundColor: '#EAEDFF',
    color: '#5D78FF',
    '& i': { color: '#5D78FF' }
  },
  phones: {
    '& >div': {
      width: '130px',
      padding: '3px 0',
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  phonesLabel: {
    width: '55px',
    textAlign: 'right'
  },
  dotsMenuIcon: {
    cursor: 'pointer'
  },
  noteRow: {
    display: 'flex',
    alignItems: 'center'
  },
  lockIcon: {
    fontSize: '10px',
    marginLeft: '5px'
  },
  presentDotRoot: { marginLeft: '0', marginTop: '-30px' },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  connectDataContainer: {
    display: 'flex'
  },
  noteRoot: {
    width: '390px',
    padding: '5px 0',
    margin: '0 0 10px 0',
    justifyContent: 'unset',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 20px)',
      justifyContent: 'space-between',
      paddingRight: '5px'
    }
  },
  noteText: {
    width: '250px',
    margin: 'unset',
    padding: '5px 10px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '4px',
    height: '38px',
    display: 'flex',
    alignItems: 'center'
  },
  noteLabel: {
    width: '130px',
    paddingLeft: '10px',
    fontSize: '12px'
  }
});

export default styles;
