import * as Sentry from '@sentry/react';

export default function sentryConfig(user) {
  const { id, client_id } = user;

  Sentry.setContext('client', {
    ID: client_id
  });
  Sentry.configureScope(scope => {
    scope.setUser({ id });
  });
}
