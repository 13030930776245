import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import Header from './header/Header';
import SubHeader from './sub-header/SubHeader';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';
import Footer from './footer/Footer';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import getMenuConfig from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import './assets/Base.scss';
import DetailView from '../../app/partials/detailView/DetailsView';
import Optional from '../../app/utils/optional';
import { ROLES_IDS } from '../../app/consts/rolesIds';

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  userRoleId
}) {
  htmlClassService.setConfig(LayoutConfig.default);
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(' ');

  const menuConfig = useMemo(
    () => Optional(getMenuConfig(userRoleId)).or(getMenuConfig()),
    [userRoleId]
  );

  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig.default}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}
            <Header />
            {/* <!-- end:: Header --> */}
            <DetailView />
            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Head --> */}
              {subheaderDisplay && <SubHeader />}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>{children}</KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    'header.self.fixed.desktop'
  ),
  userRoleId: Optional(auth.role.id).or(ROLES_IDS.STANDARD_USER),
  contentContainerClasses: ''
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default connect(mapStateToProps)(Layout);
