import React from 'react';
import { withStyles } from '@material-ui/styles';
import { FieldArray } from 'formik';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles, { customStyles } from '../styles/generalData';
import InputRow from '../../../partials/dataRows/InputRow';
import Roles from './Roles';
import SchemaEditor from './SchemaEditor';
import { actions as reduxActions } from '../reducers';

function GeneralData({ form, classes, selected }) {
  const { setFieldError, values, setFieldValue } = form;
  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <InputRow
          labelText="Custom job name"
          name="name"
          customStyles={customStyles.textfield}
          {...form}
        />
        <InputRow
          labelText="Category"
          name="category"
          customStyles={customStyles.textfield}
          {...form}
        />
        <InputRow
          labelText="Object type"
          name="object_type"
          customStyles={customStyles.textfield}
          {...form}
        />
        <InputRow
          labelText="Job class"
          name="job_class"
          customStyles={customStyles.textfield}
          {...form}
        />
        <SchemaEditor
          onSchemaChange={data => setFieldValue('parameters', data)}
          selected={selected}
          templateName={values.name}
          template={values.parameters}
          setIsError={isError =>
            isError
              ? setFieldError('parameters', 'Wrong JSON')
              : setFieldError('parameters', void 0)
          }
          name="Parameters"
        />
      </div>
      <div className={classes.rightColumn}>
        <InputRow
          labelText="Description"
          name="description"
          isMultiline={true}
          minRows="6"
          maxRows="6"
          customStyles={customStyles.textfield}
          {...form}
        />
        <FieldArray
          name="roles"
          render={arrayHelpers => (
            <div className={classes.customRolesRoot}>
              <Roles arrayHelpers={arrayHelpers} />
            </div>
          )}
        />
      </div>
    </div>
  );
}

const mapStatesToProps = ({ editCustomJob }) => {
  return {
    selected: editCustomJob.selected
  };
};

const mapDispatchToProps = {
  setValue: reduxActions.setValue
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(GeneralData);
