import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';

import styles from './styles';
import updateValue from './actions/updateValue';
import { isUndefined } from '../../utils/isDefined';
import Optional from '../../utils/optional';
import clsx from 'clsx';

export function CustomCheckboxRow({
  classes,
  label,
  intl,
  value = false,
  customStyles = {},
  isDisabled = false,
  errorIntlId = 'EMPTY',
  fieldName,
  id,
  type,
  urlKey,
  setValue,
  mobileRightAllignValue = false
}) {
  const [checkboxValue, setCheckboxValue] = useState(Optional(value).or(false));

  const handleChange = async event => {
    const { value: result, error } = await updateValue({
      value: event.target.checked,
      label: fieldName,
      id,
      type,
      urlKey,
      errorIntlId
    });

    if (isUndefined(error)) {
      setValue(result);
      setCheckboxValue(result);
    }
  };

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: label
      }),
    // eslint-disable-next-line
    [intl]
  );

  return (
    <div className={classes.container} style={customStyles.root}>
      <div className={classes.label} style={customStyles.label}>
        {title}
      </div>
      <div
        className={clsx(classes.checkboxValue, {
          [classes.mobileRightAllignValue]: mobileRightAllignValue
        })}
      >
        <Checkbox
          value={checkboxValue}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CustomCheckboxRow);
