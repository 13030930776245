export default function checkNumberData({
  numberData,
  rightPanelData,
  fromRightPanel
}) {
  const isDataExist = fromRightPanel
    ? Object.keys(rightPanelData).length > 0
    : Object.keys(numberData).length > 0;

  const data = fromRightPanel ? rightPanelData : numberData;

  return { isDataExist, data };
}
