import React, { useState, cloneElement } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import EditShortcuts from './EditShortcuts';
import ActivityExpiration from './ActivityExpiration';

export function PresenceMenu({
  children,
  intl,
  shortcuts = [],
  activity,
  activities,
  userId,
  setUpdatedActivity,
  setActualAvailability,
  setShortcuts
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <EditShortcuts
            shortcuts={shortcuts}
            activities={activities}
            setShortcuts={setShortcuts}
            closeMenuOnDialogOpen={handleClose}
          >
            <MenuItem>
              {intl.formatMessage({
                id: 'USER.EDIT_SHORTCUTS'
              })}
            </MenuItem>
          </EditShortcuts>
        </div>
        <ActivityExpiration
          activity={activity}
          activities={activities.activitiesOptions}
          userId={userId}
          setUpdatedActivity={setUpdatedActivity}
          setActualAvailability={setActualAvailability}
          closeMenuOnDialogOpen={handleClose}
        >
          <MenuItem>
            {intl.formatMessage({
              id: 'USER.SET_EXPIRATION'
            })}
          </MenuItem>
        </ActivityExpiration>
      </Menu>
    </div>
  );
}

export default flowRight(injectIntl, withUserCheck)(PresenceMenu);
