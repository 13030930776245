import {
  isArray,
  isDefined,
  isObject,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';

const mapSwapErrorResponse = response => {
  if (isUndefined(response)) {
    return '';
  }

  if (response.code === RESPONSE_CODES.BAD_REQUEST) {
    if (!isArray(response.code.data) || response.code.data.length === 0) {
      return '';
    }

    return response.code.data.join(', ');
  }

  if (response.code === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
    if (!isArray(response.code.data) || response.code.data.length === 0) {
      return '';
    }

    return Optional(response.code.data[0]?.problems).map(problems => {
      if (!isArray(problems)) {
        return '';
      }

      return problems
        .map(problem => {
          if (!isObject(problem) || isUndefined(problem?.message)) {
            return void 0;
          }

          return problem.message;
        })
        .filter(el => isDefined(el))
        .join(', ');
    });
  }

  return '';
};

export default mapSwapErrorResponse;
