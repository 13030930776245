import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    name: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    category: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    object_type: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    job_class: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
  });
}
