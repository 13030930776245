import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { CSVLink } from 'react-csv';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';

import getIdFromUrl from '../../../utils/getIdfromUrl';
import styles from '../styles/usageCsvDownload';
import { isDefined } from '../../../utils/isDefined';
import mapDataForExport from '../actions/mapDataForExport';
import createUsageColumnsLabels from '../actions/createColumnsLabels';

const UsageCsvDownload = ({
  classes,
  name,
  location,
  clientName,
  datalimits,
  lastMonthsCount,
  showAll
}) => {
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const columns = createUsageColumnsLabels(lastMonthsCount, showAll);
    setHeaders(columns);
  }, [lastMonthsCount, showAll]);

  const client = useMemo(() => {
    return getIdFromUrl(location);
    // eslint-disable-next-line
  }, [location]);

  const fileName = useMemo(
    () => `datalimitsUsage${isDefined(client) ? `-${clientName}` : ''}.csv`,
    [clientName, client]
  );

  const csvLink = useRef();

  const onClick = async () => {
    const { mappedData } = await mapDataForExport({
      data: datalimits,
      lastMonthsCount,
      showAll
    });

    setCsvData(mappedData);
    csvLink.current.link.click();
  };

  return (
    <div>
      <i onClick={onClick} className={clsx('fas fa-download', classes.root)} />
      <CSVLink
        data={csvData}
        headers={headers}
        filename={fileName}
        className={classes.link}
        ref={csvLink}
        separator={';'}
        target="_blank"
      />
    </div>
  );
}

const mapStatesToProps = ({ dataLimitsUsage, selectClient }) => {
  return {
    datalimits: dataLimitsUsage.datalimits,
    clientName: selectClient.selectedClient.name,
    lastMonthsCount: dataLimitsUsage.lastMonthsCount
  };
};

export default flowRight(
  withRouter,
  connect(mapStatesToProps),
  withStyles(styles)
)(UsageCsvDownload);
