import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_USER_CONTACTS } from './types';
import { actions as reduxActions } from '../reducers';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';
import { isStringDefined } from '../../../utils/isDefined';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';

export function fetchUsersContacts({ search }) {
  return {
    type: sagaActions[GET_USER_CONTACTS],
    search
  };
}

export function* getUserContacts({ search }) {
  yield put(loaderReducActions.startSpinLoader());
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );

  let contacts = [];
  let activities = [];

  try {
    const data = yield call(
      getData,
      `${baseUrl}/api/contacts/list/${clientDomain}/${mitelUserId}?t=${mitelToken}&query=${Optional(
        search
      ).or('')}`
    );
    yield put(
      reduxActions.setIsFavorite(isStringDefined(search) ? false : true)
    );
    contacts = Optional(data?.contact).or([]);
  } catch (e) {
    console.warn(e);
  }

  try {
    const { data: allActivity } = yield call(getData, {
      options: {
        url: `${baseUrl}/api/config/user/${clientDomain}/${mitelUserId}/presence/activity?t=${mitelToken}`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    activities = Optional(allActivity?.activity).or([]);
  } catch (e) {
    console.warn(e);
  }
  if (!isStringDefined(search)) {
    yield put(reduxActions.setUserFavorites(contacts));
  }

  yield put(reduxActions.setUserContacts({ contacts, activities }));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetUserContacts() {
  yield takeLatest(sagaActions[GET_USER_CONTACTS], getUserContacts);
}

export default watchGetUserContacts();
