import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';

import styles from '../styles';
import MoreActionsMenu from './MoreActionsMenu';
import withSettings from '../../../utils/withSettings';

const TableActionButtons = ({ classes, settings, data }) => {
  return (
    <div className={classes.actionButtons}>
      <MoreActionsMenu data={data}>
        <i
          className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
          style={{
            color: settings.colors.links
          }}
        ></i>
      </MoreActionsMenu>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(TableActionButtons);
