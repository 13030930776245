import React, { useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { Tooltip, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';

import styles from '../styles/charts';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';
import CustomChart from './CustomChart';
import { actions as showDatalimitReduxActions } from '../../../modals/showDatalimit/reducers';

const Charts = ({
  classes,
  data = {},
  settings,
  openShowDatalimit,
  datalimitId,
  datalimitName,
  hideShowButton = false
}) => {
  const onShowClick = useCallback(
    () => {
      openShowDatalimit(datalimitId, datalimitName, true);
    },
    // eslint-disable-next-line
    [datalimitId, datalimitName]
  );
  
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        {!hideShowButton && (
          <Tooltip title={translate('DATA_LIMITS.SHOW_DATALIMIT')}>
            <i
              className={clsx('fas fa-chart-area', classes.icon)}
              style={{ color: settings.colors.links }}
              onClick={onShowClick}
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.chartsContainer}>
        <CustomChart
          label={translate('DATA_LIMIT.PACKAGE_DATA')}
          color="#1BA9B5"
          limilLabel={translate('NUMBER.DATA_LIMIT')}
          showInfo={true}
          infoText={translate('DATA_LIMIT.PACKAGE_DATA_INFO')}
          data={{
            limit: data?.calculatedUsageLimit,
            chartUsage: data?.dataPercentage,
            usageText: data?.data
          }}
          usageType="GB"
        />
        <CustomChart
          label={translate('DATA_LIMITS.ROAMING_EU')}
          color="#D99355"
          limilLabel={translate('DATA_LIMITS.ROAMING_EU_LIMIT')}
          data={{
            limit: data?.calculatedFairUsageLimit,
            chartUsage: data?.fairDataPercentage,
            usageText: data?.fairData
          }}
          usageType="GB"
        />
        <CustomChart
          label={translate('DATA_LIMITS.ROAMING_WORLD')}
          color="#b61182"
          data={{
            chartUsage: data?.totalRoam,
            usageText: data?.totalRoam
          }}
          usageType="GB"
          customLimit={20}
          showLimit={false}
        />
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  openShowDatalimit: showDatalimitReduxActions.openShowDatalimit
};

export default flowRight(
  withSettings,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(Charts);
