import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/invoiceTableTitle';

function InvoiceTableTitle({ classes, intl, settings }) {
  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <i className="fal fa-file-invoice-dollar"></i>
      <div className={classes.label}>
        <div>{`${intl.formatMessage({
          id: 'MENU.INVOICES'
        })}`}</div>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(InvoiceTableTitle);
