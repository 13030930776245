import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_DATA_LIMITS = 'SET_DATA_LIMITS';
const RESET_DATA_LIMITS = 'RESET_DATA_LIMITS';
const SET_COLUMNS = 'SET_COLUMNS';
const SET_MONTH = 'SET_MONTH';

export const actionTypes = createReduxActionTypes('DATA_LIMITS', [
  SET_DATA_LIMITS,
  RESET_DATA_LIMITS,
  SET_COLUMNS,
  SET_MONTH
]);

const DEFAULT_STATE = {
  dataLimits: [],
  columns: [],
  monthsOptions: [],
  month: 'current'
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_DATA_LIMITS]: {
      const { monthsOptions, dataLimits } = action;
      return {
        ...state,
        dataLimits,
        monthsOptions
      };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[SET_MONTH]: {
      return { ...state, month: action.month };
    }
    case actionTypes[RESET_DATA_LIMITS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setDataLimits: (dataLimits, monthsOptions) => ({
    type: actionTypes[SET_DATA_LIMITS],
    dataLimits,
    monthsOptions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  setMonth: month => ({
    type: actionTypes[SET_MONTH],
    month
  }),
  resetDataLimits: () => ({ type: actionTypes[RESET_DATA_LIMITS] })
};
