import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_ROLES = 'SET_ROLES';
const RESET_ROLES = 'RESET_ROLES';
const SET_COLUMNS = 'SET_COLUMNS';
export const actionTypes = createReduxActionTypes('ROLES', [
  SET_ROLES,
  RESET_ROLES,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  roles: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ROLES]: {
      const { roles } = action;

      return { ...state, roles: [...roles] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_ROLES]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setRoles: roles => ({ type: actionTypes[SET_ROLES], roles }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetRoles: () => ({ type: actionTypes[RESET_ROLES] })
};

export const getters = {
  getRoles: state => state.roles.roles
};
