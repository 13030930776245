import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useCallback } from 'react';
import Select from 'react-select';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';

import styles, { customSelectStyles } from '../styles/selectClient';

const SelectClient = ({
  clientsList,
  selectedClient,
  setSelectedClient,
  classes,
  settings
}) => {
  const onChange = useCallback(
    selected => {
      if (selected.value !== selectedClient.value) {
        setSelectedClient(selected);
      }
    },
    // eslint-disable-next-line
    [selectedClient.value]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label} style={{ color: settings.colors.primary }}>
        {translate('EDIT.SELECT_CLIENT')}
      </div>
      <Select
        styles={reactSelectDefaultStyles}
        customStyles={customSelectStyles}
        options={clientsList}
        value={selectedClient}
        onChange={onChange}
        isSearchable
      />
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(SelectClient);
