export default function mapSubscriptionSku(value) {
  if (value.indexOf('TC') !== -1 || value.indexOf('TCO') !== -1) {
    return 'TDC NET';
  }
  if (value.indexOf('TN') !== -1 || value.indexOf('TNO') !== -1) {
    return 'Telenor net';
  }
  if (value.indexOf('MOB-W') !== -1 || value.indexOf('MBB-W') !== -1) {
    return '3 NET';
  }

  return void 0;
}
