import { call } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, ATTORNEY_REQUEST } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export function attorneySubmit({ userData, setUserData, handleNext }) {
  return {
    type: sagaActions[ATTORNEY_REQUEST],
    userData,
    setUserData,
    handleNext
  };
}

export function* attorneyRequest({ userData, setUserData, handleNext }) {
  const mappedData = yield call(mapData, userData);
  try {
    const response = yield call(postData, {
      url: URLS.ATTORNEY_REQUEST,
      data: {
        ...mappedData
      }
    });

    if (response.status === RESPONSE_CODES.CREATED) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.ATTORNEY_SEND',
        messageType: 'success'
      });
    }
    yield call(setUserData);
    yield call(handleNext);
  } catch {}
}

function* watchAttorneyRequest() {
  yield takeLatest(sagaActions[ATTORNEY_REQUEST], attorneyRequest);
}

export default watchAttorneyRequest();

export function mapData(data) {
  const {
    is_old_subscripion_date,
    move_number_date,
    portMobileNumber,
    ...restData
  } = data;

  return {
    ...restData,
    mobile_number: portMobileNumber,
    move_number_date: !is_old_subscripion_date ? move_number_date : ''
  };
}
