import SELECTS_OPTIONS, {
  createNumberTypes,
  createStatuses
} from '../../../consts/selectsOptions';

export default function prepareSelectsOptions(selectsOptions) {
  return {
    ...selectsOptions,
    platform: [{ value: '', label: '' }, ...SELECTS_OPTIONS.PLATFORM_OPTIONS],
    status: [...createStatuses()],
    futureStatus: [{ value: '', label: '' }, ...createStatuses()],
    numberType: [{ value: '', label: '' }, ...createNumberTypes()]
  };
}
