import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import mapCustomFilters from './mapCustomFilters';

export default async function fetchUsersPaginate({ query, client }) {
  const queryParams = mapQueryParams({ client });

  const requestUrl = JSONAPI_URLS.USERS;

  return await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: '',
    mapCustomFilters
  });
}

export function mapQueryParams({ client }) {
  let queryParams = {
    sort: 'name',
    include: ['sim_cards.cell_numbers', 'roles', 'department', 'client'],
    fields: {
      departments: 'name',
      sim_cards: 'cell_numbers',
      roles: 'name',
      clients: 'name'
    },
    filter: {
      client_id: isDefined(client) ? { eq: client } : { gt: 0 }
    }
  };

  if (isUndefined(client)) {
    queryParams = {
      ...queryParams,
      fields: { ...queryParams.fields, clients: 'name' },
      include: [...queryParams.include, 'client']
    };
  }

  return queryParams;
}
