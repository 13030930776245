const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '400px',
      height: 'fit-content',
      fontWeight: 500,
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px 30px 5px 0',
      flexDirection: 'column'
    },
    checkboxRoot: { width: '250px' },
    field: { display: 'flex' },
    label: {
      display: 'flex',
      alignItems: 'center',
      color: '#16374F',
      fontWeight: 500,
      width: '120px',
      marginRight: '20px'
    },
    fieldValue: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '11px',
      fontWeight: 'normal',
      borderRadius: '4px',
      width: '200px',
      margin: 0,
      '& p': {
        margin: '2px 0'
      }
    },
    hiddenValue: { justifyContent: 'space-between' },
    errorMessage: {
      fontSize: '8px',
      color: '#FD397A'
    },
    columnDirection: { flexDirection: 'column' },
    mobileRoot: {
      [theme.breakpoints.down('xs')]: {
        width: 'unset'
      }
    },
    mobileRootCustom: {
      [theme.breakpoints.down(theme.breakpoints.values.sm - 100)]: {
        width: 'unset'
      }
    },
    mobileFieldCustom: {
      [theme.breakpoints.down(theme.breakpoints.values.sm - 100)]: {
        flexDirection: 'column'
      }
    },
    mobileField: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    mobileText: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    mobileLabel: {
      [theme.breakpoints.down('xs')]: {
        width: 'unset'
      }
    },
    starIcon: {
      fontSize: '8px',
      paddingBottom: '5px'
    },
    infoIcon: {
      marginLeft: '5px'
    },
    disabled: {
      backgroundColor: '#F2F2F2'
    },
    eyeIcon: { cursor: 'pointer' },
    loaderConatiner: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    valueContainer: { display: 'flex', alignItems: 'flex-start' },
    requiredIcon: { marginLeft: '-8px', width: '8px' }
  };
};

export default styles;
