import { JSONAPI_URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import urlCreator from '../../../utils/urlCreator';

export default async function numberAvailabilityCheck(number) {
  const QUERY_PARAMS = {
    filter: {
      client_id: { gt: 0 },
      search: { match: number }
    }
  };

  try {
    const response = await getData(
      urlCreator(JSONAPI_URLS.EXTERNAL_NO_RANGES, QUERY_PARAMS)
    );

    const isLandlineNumber = Optional(response?.data[0]?.number_type)
      .map(type => type === 'landline')
      .or(false);

    if (isLandlineNumber) {
      return {
        isNumberAvailable: false,
        availabilityError: 'REQUEST_NEW_USER.LANDLINE_NUMBER'
      };
    }

    const isActive = Optional(response?.data[0]?.status)
      .map(status => status === 'active')
      .or(false);

    if (isActive) {
      return {
        isNumberAvailable: false,
        availabilityError: 'REQUEST_NEW_USER.NUMBER_IN_USE'
      };
    }

    return {
      isNumberAvailable: true
    };
  } catch {
    return { isNumberAvailable: true };
  }
}
