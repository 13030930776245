import changeObjectKeysCamelToUnderscore from '../../../utils/changeObjectKeysCamelToUnderscore';
import {
  isDefined,
  isStringDefined,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapDataToUpdate({
  user,
  clientId,
  initialUserRoles,
  clientDomain,
  onlyRoles = false
}) {
  const {
    id,
    name,
    username,
    email,
    roles = [],
    connect30_user_id,
    pass,
    language,
    generate_welcome_email
  } = user;

  const mappedRoles = roles.map(role => {
    return { type: 'roles', id: role.id };
  });

  const rolesToDelete = initialUserRoles.reduce((arr, initialRole) => {
    if (mappedRoles.find(role => role.id === initialRole.id)) {
      return [...arr];
    }

    return [
      ...arr,
      { type: 'roles', id: initialRole.id, method: 'disassociate' }
    ];
  }, []);

  if (onlyRoles) {
    return {
      data: {
        id,
        type: 'users',
        relationships: {
          roles: {
            data: [...mappedRoles, ...rolesToDelete]
          }
        }
      }
    };
  }

  const mappedName = name
    .split(' ')
    .filter(word => isStringDefined(word))
    .join(' ');

  const userData = {
    data: {
      id,
      type: 'users',
      attributes: {
        name: mappedName,
        username,
        email: Optional(email).or(
          `noreply@${Optional(clientDomain).or('noDomain.nn')}`
        ),
        client_id: clientId,
        connect30_user_id,
        password: pass
      },
      relationships: {
        roles: {
          data: [...mappedRoles, ...rolesToDelete]
        },
        language: { data: { type: 'language', id: language } }
      }
    },
    generate_welcome_email: generate_welcome_email ? 'yes' : 'no'
  };

  if (isUndefined(pass) || generate_welcome_email) {
    delete userData.data.attributes.password;
  }

  if (isDefined(id)) {
    delete userData.generate_welcome_email;
  }

  return userData;
}

export function mapGeneralData({ values = {}, languages = [] }) {
  const { dirFields, language, mitelUserId, ...restValues } = values;

  const dir_fields = mapFields(dirFields);

  const languageObj = languages.find(lang => lang.value === language);

  const updatedValues = changeObjectKeysCamelToUnderscore(restValues);

  return {
    ...updatedValues,
    dir_fields,
    language: Optional(languageObj)
      .map(lang => lang.short)
      .or('en')
  };
}

export function mapGroupData(data = {}) {
  return { user_groups: [...data.groups.sort()] };
}

function mapFields(fields = {}) {
  const fieldsKeys = Object.keys(fields);
  return fieldsKeys.map(key => {
    return { id: key, value: fields[key].value };
  });
}
