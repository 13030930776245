import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

const styles = {
  root: {
    display: 'flex',
    fontSize: '10px',
    padding: '5px 25px',
    width: '100%'
  }
};

function MandatoryField({ classes, intl }) {
  return (
    <div className={classes.root}>
      <div>
        {`* - ${intl.formatMessage({
          id: 'MANDATORY_FIELD'
        })}`}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(MandatoryField);
