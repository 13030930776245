import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

const addNewAddress = async ({ address, clientId, isClientUser }) => {
  const mappedData = mapDataToUpdate({ address, clientId, isClientUser });
  
  const response = await postData({
    url: `${URLS.ADDRESSES}`,
    data: {
      data: mappedData
    }
  });

  if (response.status === RESPONSE_CODES.CREATED) {
    showSnackbar({
      intlTextKey: 'MESSAGE.ADD_ADDRESS',
      messageType: 'success'
    });

    return response.data?.data?.id;
  }
};

export default addNewAddress;

const mapDataToUpdate = ({ address, clientId, isClientUser }) => {
  const requestData = {
    type: 'adresses',
    attributes: {
      street: address.vejnavn,
      house_number: address.husnr,
      stairway: address.trappe,
      floor: address.etage,
      sidedoor: address.dør,
      city: address.postnrnavn,
      zip_code: address.postnr,
      country: address.isDanishAddress ? 'DK' : address.country,
      municipality_code: address.kommunekode,
      road_code: address.vejkode,
      district_subdivision: address.supplerendebynavn,
      dawa_id: address.id
    }
  };

  if (!isClientUser) {
    requestData.relationships = {
      client: {
        data: {
          type: 'clients',
          id: clientId
        }
      }
    };
  }

  return requestData;
};
