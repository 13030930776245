import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import { translate } from '../../../i18n/I18nProvider';
import { setRange } from '../../../modals/editRange/actions/setRange';
import { isUndefined } from '../../../utils/isDefined';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';

const AdditionalDataMenu = ({
  children,
  openEditNumberRange,
  externalNoData,
  number,
  simcardData,
  openEditSimcard
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditRange = useCallback(
    () => {
      openEditNumberRange({
        rangeId: externalNoData.id,
        number,
        fromLookup: true
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [externalNoData, number]
  );

  const onEditClick = useCallback(
    () => {
      openEditSimcard({
        simcard: simcardData,
        simcardId: simcardData.id,
        clientId: simcardData.client_id,
        fromLookup: true,
        fromRightPanel: false
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [simcardData]
  );
  
  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem
          onClick={onEditRange}
          disabled={isUndefined(externalNoData?.id)}
        >
          {translate('TOOLTIP.EDIT_RANGE')}
        </MenuItem>
        <MenuItem
          onClick={onEditClick}
          disabled={
            isUndefined(simcardData?.id) ||
            !can('update', createSubject('SimCard', simcardData))
          }
        >
          {translate('TOOLTIP.EDIT_SIMCARD')}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  return {
    externalNoData: checkNumber.externalNoData,
    number: checkNumber.number,
    simcardData: checkNumber.simcardData
  };
};

const mapDispatchToProps = {
  openEditNumberRange: setRange,
  openEditSimcard: editSimcardReduxActions.openEditSimcard
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  AdditionalDataMenu
);
