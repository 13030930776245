import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React from 'react';
import { translate } from '../../i18n/I18nProvider';
import { isDefined, isUndefined } from '../../utils/isDefined';

import withSettings from '../../utils/withSettings';
import LinkCell from './components/LinkCell';
import ShowMoreDataDialog from './components/ShowMoreDataDialog';
import styles from './styles';

function TableNameCell({
  data,
  clientId,
  from,
  classes,
  showMore = false,
  length = 1,
  userDialogName
}) {
  const usersToShow = showMore ? data.slice(0, length) : data;

  return (
    <div className={classes.root}>
      {isDefined(data) &&
        usersToShow.map((user, i) => {
          if (isUndefined(user) || Object.keys(user).length === 0) {
            return <div key={i}></div>;
          }
          return (
            <LinkCell key={i} from={from} clientId={clientId} user={user} />
          );
        })}
      {data.length > length && showMore && (
        <ShowMoreDataDialog
          users={data}
          clientId={clientId}
          from={from}
          userDialogName={userDialogName}
        >
          <Tooltip placement="bottom" title={translate('SHOW_MORE')}>
            <i className={clsx('fal fa-ellipsis-h', classes.icon)}></i>
          </Tooltip>
        </ShowMoreDataDialog>
      )}
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(TableNameCell);
