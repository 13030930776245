const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '400px',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '320px'
      }
    },
    datarowRoot: {
      padding: '3px 0',
      [theme.breakpoints.down('xs')]: {
        width: '290px',
        padding: '3px 0px 3px 30px'
      }
    },
    datarowValue: { width: 'fit-content' },
    icon: {
      marginLeft: '10px',
      cursor: 'pointer',
      '&:hover': { filter: 'brightness(1.5)' },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    link: {
      width: '100%',
      textAlign: 'center',
      padding: '0 30px',
      '& a': {
        '&:hover': { filter: 'brightness(1.5)' }
      },
      [theme.breakpoints.down('xs')]: {
        width: '310px',
        padding: '0 20px'
      }
    }
  };
};

export default styles;
