const styles = theme => {
  return {
    labelContainer: {
      fontSize: '16px',
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center'
    },
    onlyAccount: { alignItems: 'start' },
    accountNo: {
      fontSize: '16px',
      fontWeight: 500,
      paddingLeft: '10px'
    }
  };
};

export default styles;

export const customSelectStyles = {
  container: { width: '100%' },
  option: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'end',
    textAlign: 'end'
  },
  control: {
    borderStyle: 'hidden',
    width: '100%',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'end',
    padding: 'none'
  },
  indicatorSeparator: { display: 'none' },
  menu: { zIndex: 100, width: 'fit-content', left: 0 }
};
