const styles = theme => {
  return {
    root: { width: '500px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: '20px'
    }
  };
};

export default styles;
