import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import { actions as detailsViewReduxActions } from '../partials/detailView/reducers';
import Optional from './optional';

export default function withDetailsView(WrappedComponent) {
  const mapStatesToProps = ({ detailView, selectClient }) => {
    return {
      detailOpen: detailView.open,
      selectedClient: Optional(selectClient.selectedClient).or({})
    };
  };

  const mapDispatchToProps = {
    closeDetailView: detailsViewReduxActions.closeDetailView,
    openDetailView: detailsViewReduxActions.openDetailView
  };

  return connect(
    mapStatesToProps,
    mapDispatchToProps
  )(({ closeDetailView, ...props }) => {
    useEffect(() => {
      if (props.detailOpen) {
        closeDetailView();
      }
      // eslint-disable-next-line
    }, [props.selectedClient]);

    useEffect(() => {
      return closeDetailView;
      // eslint-disable-next-line
    }, []);

    return <WrappedComponent {...props} closeDetailView={closeDetailView} />;
  });
}
