import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';

import { SUBSCRIPTIONS_STATUS_OPTIONS } from '../../../consts/initialValues';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { actions as reduxActions } from '../reducers';
import styles, { customSelectStyles } from '../styles/selects';

function FilterByStatus({ updateValue, filterBy, intl, classes }) {
  const handleChange = selected => {
    updateValue('filterBy', selected.value);
  };

  const value = SUBSCRIPTIONS_STATUS_OPTIONS.find(
    option => option.value === filterBy
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'SUBSCRIPTIONS.FILTER_BY_STATUS'
        })}
      </div>
      <Select
        customStyles={customSelectStyles}
        options={SUBSCRIPTIONS_STATUS_OPTIONS}
        styles={reactSelectDefaultStyles}
        value={value}
        onChange={handleChange}
        isRtl
        isSearchable={false}
      />
    </div>
  );
}

const mapStatesToProps = ({ subscriptions }) => {
  return {
    filterBy: subscriptions.filterBy
  };
};

const mapDispatchToProps = {
  updateValue: reduxActions.updateValue
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(FilterByStatus);
