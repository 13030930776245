import { formatDateForSchedule } from '../../../utils/formatDate';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import { findSkuTranslation } from './customSearchActions';

export const mapDataforExport = ({
  data,
  lang,
  selectedAccount,
  selectOptions
}) => {
  let mappedData = data.reduce((arr, el) => {
    const { subs } = el;

    const mappedSubs = subs.map(sub => {
      const {
        name,
        status,
        activationDate,
        expiryDate,
        quantity,
        description,
        sku,
        productCategory,
        parentAccountId
      } = sub;

      return {
        name,
        activationDate: Optional(formatDateForSchedule(activationDate)).or('-'),
        expiryDate: Optional(formatDateForSchedule(expiryDate)).or('-'),
        quantity,
        description,
        status,
        sku,
        productCategory,
        skuTranslations: findSkuTranslation({ rowData: sub, lang }),
        parentAccountId
      };
    });

    return [...arr, ...mappedSubs];
  }, []);

  if (selectedAccount?.value === 'ALL') {
    mappedData = mappedData.map(sub => {
      const account = findAccountName({ sub, selectOptions });

      return { ...sub, account };
    });
  }

  return mappedData;
};

export const findAccountName = ({ sub = {}, selectOptions = [] }) => {
  if (isUndefined(selectOptions)) {
    return '';
  }
  
  const foundedAccount = selectOptions.find(
    option => option.value === sub?.parentAccountId
  );

  return Optional(foundedAccount?.label).or('');
};
