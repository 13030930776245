import { CircularProgress, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import StatusCell from '../../../partials/statusCell/StatusCell';
import { doesArrayHasLength, isString } from '../../../utils/isDefined';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import styles from '../styles/domainNumberRangeCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

function DomainNumberRangeCell({ data = [], classes, isLoading }) {
  const tooltipText = doesArrayHasLength(data) ? createTooltipText(data) : '';

  return (
    <div className={classes.root}>
      <Tooltip
        placement="bottom"
        title={tooltipText}
        className={classes.tooltip}
      >
        <div className={classes.root}>
          <StatusCell
            {...pickOptionsForStatusCell(
              STATUS_BOX_OPTIONS.TRUE_FALSE,
              doesArrayHasLength(data)
            )}
          />
          {isLoading && (
            <CircularProgress size={18}  />
          )}
        </div>
      </Tooltip>
    </div>
  );
}

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(DomainNumberRangeCell);

function createTooltipText(data) {
  const numbers = [...data];
  return (
    <div>
      {numbers.map((no, i) => {
        return (
          <div key={i}>
            {`${checkNumberFormat(no.range_from)} - ${checkNumberFormat(
              no.range_up_to_incl
            )}`}
          </div>
        );
      })}
    </div>
  );
}

function checkNumberFormat(number) {
  if (!isString(number)) {
    return number;
  }

  return mapNumberToDisplay(
    number.charAt(0) === '+' ? number.substring(1) : number
  );
}
