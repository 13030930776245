import { STANDARD_USER_ROLE } from '../../../consts/initialValues';
import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const SET_USER = 'SET_USER';
const SET_SELECTS_OPTIONS = 'SET_SELECTS_OPTIONS';
const RESET_USER = 'RESET_USER';
const SET_CLIENT_USERS = 'SET_CLIENT_USERS';
const UPDATE_USER = 'UPDATE_USER';
const CHANGE_TABS_INDEX = 'CHANGE_TABS_INDEX';
const UPDATE_ROLES = 'UPDATE_ROLES';

export const actionTypes = createReduxActionTypes('EDIT_USER', [
  SET_USER,
  UPDATE_USER,
  SET_SELECTS_OPTIONS,
  RESET_USER,
  SET_CLIENT_USERS,
  CHANGE_TABS_INDEX,
  UPDATE_ROLES
]);

const DEFAULT_STATE = {
  open: false,
  user: { language: 'da', roles: [STANDARD_USER_ROLE] },
  tabIndex: 0,
  fromUsersTable: true,
  fromGroupsTable: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_USER]: {
      const {
        user,
        isDomainProvided,
        isTelephonyUser,
        mitelUserId,
        fromUsersTable,
        fromGroupsTable,
        clientConnectId,
        isBeTable
      } = action;
      return {
        ...state,
        user: { ...state.user, ...user },
        isDomainProvided,
        clientConnectId,
        isTelephonyUser,
        mitelUserId,
        open: true,
        fromGroupsTable: Optional(fromGroupsTable).or(state.fromGroupsTable),
        fromUsersTable: Optional(fromUsersTable).or(state.fromUsersTable),
        isBeTable
      };
    }
    case actionTypes[UPDATE_USER]: {
      const { user, isGeneralData, isRolesUpdate, roles } = action;
      return {
        ...state,
        user: {
          ...state.user,
          generalData: isGeneralData
            ? { ...user }
            : { ...state.user.generalData },
          groups: isGeneralData ? [...state.user.groups] : [...user],
          roles: isRolesUpdate ? [...roles] : [...state.user.roles]
        }
      };
    }
    case actionTypes[UPDATE_ROLES]: {
      const { roles } = action;
      return {
        ...state,
        user: {
          ...state.user,
          roles
        }
      };
    }
    case actionTypes[SET_SELECTS_OPTIONS]: {
      const { selectsOptions } = action;
      return {
        ...state,
        selectsOptions: { ...selectsOptions }
      };
    }
    case actionTypes[CHANGE_TABS_INDEX]: {
      const { tabIndex } = action;
      return {
        ...state,
        tabIndex
      };
    }
    case actionTypes[SET_CLIENT_USERS]: {
      const { clientsUsers } = action;
      return {
        ...state,
        selectsOptions: { ...state.selectsOptions, ...clientsUsers }
      };
    }
    case actionTypes[RESET_USER]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setUser: ({
    user,
    isDomainProvided,
    isTelephonyUser,
    mitelUserId,
    fromUsersTable,
    fromGroupsTable,
    clientConnectId,
    isBeTable
  }) => ({
    type: actionTypes[SET_USER],
    user,
    isDomainProvided,
    isTelephonyUser,
    mitelUserId,
    fromUsersTable,
    fromGroupsTable,
    clientConnectId,
    isBeTable
  }),
  changeTabsIndex: tabIndex => ({
    type: actionTypes[CHANGE_TABS_INDEX],
    tabIndex
  }),
  updateUser: (user, isGeneralData) => ({
    type: actionTypes[UPDATE_USER],
    user,
    isGeneralData
  }),
  updateRoles: roles => ({
    type: actionTypes[UPDATE_ROLES],
    roles
  }),
  setSelectsOptions: selectsOptions => ({
    type: actionTypes[SET_SELECTS_OPTIONS],
    selectsOptions
  }),
  setClientUsersOptions: clientsUsers => ({
    type: actionTypes[SET_CLIENT_USERS],
    clientsUsers
  }),
  resetUser: () => ({ type: actionTypes[RESET_USER] })
};
