import React, { useCallback, useState } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import styles from './styles';
import { translate } from '../../i18n/I18nProvider';
import sendActivationMessage from './actions/sendActivationMessage';
import DraggableDialog from '../DraggableDialog';
import PrimaryButton from '../customButtons/PrimaryButton';
import SecondaryButton from '../customButtons/SecondaryButton';
import CustomDialogTitle from '../customDialogTitle/CustomDialogTitle';
import { actions as reduxActions } from './reducers';
import getUserInfoFromLocation from '../../pages/user/actions/getUserInfoFromLocation';
import { isDefined, isUndefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';
import Optional from '../../utils/optional';

const DEFAULT_STATE = {
  sendForWeb: false,
  sendForMobile: false,
  sendForSoftphone: false,
  sendForWebSoftphone: false,
  sendForCrm: false,
  sendForTablet: false,
  sendEmail: true,
  sendSms: false
};

const ActivationMessageDialog = ({
  classes,
  clientDomain,
  user,
  open,
  closeDialog,
  location,
  currentUserDomain,
  settings,
  fromuserProfile
}) => {
  const [data, setData] = useState(DEFAULT_STATE);
  const [errors, setErrors] = useState(void 0);
  const { isCurrentUser } = getUserInfoFromLocation({ location });

  const onCloseDialog = () => {
    setData(DEFAULT_STATE);
    closeDialog();
  };
  const handleConfirm = useCallback(
    async () => {
      setErrors(void 0);
      const { isActionCompleted, responseErrors } = await sendActivationMessage(
        {
          clientDomain: isCurrentUser ? currentUserDomain : clientDomain,
          userConnectId: Optional(user?.user_id).or(user?.connect30_user_id),
          data
        }
      );

      if (isActionCompleted) {
        onCloseDialog();
      } else {
        setErrors(responseErrors);
      }
    },
    // eslint-disable-next-line
    [currentUserDomain, clientDomain, user, isCurrentUser, data]
  );

  const handleChange = (e, key) => {
    setData({ ...data, [key]: e.target.checked });
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('TOOLTIP.ACTIVATION_MESSAGE')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.info}>
            {translate('USER.ACTIVATION_MESSAGE_INFO_1')}
          </div>
          <div className={classes.row}>
            <Checkbox
              checked={data.sendForWeb}
              onChange={e => handleChange(e, 'sendForWeb')}
            />
            <div>Web</div>
          </div>

          <div className={classes.row}>
            <Checkbox
              checked={data.sendForMobile}
              onChange={e => handleChange(e, 'sendForMobile')}
              disabled={
                (!fromuserProfile && !user?.mobile_app) ||
                (fromuserProfile && !user?.mitelData?.mobile_voip)
              }
            />
            <div>Mobile</div>
          </div>

          <div className={classes.row}>
            <Checkbox
              checked={data.sendForSoftphone}
              onChange={e => handleChange(e, 'sendForSoftphone')}
              disabled={!user?.softphone}
            />
            <div>Softphone</div>
          </div>

          <div className={classes.row}>
            <Checkbox
              checked={data.sendForWebSoftphone}
              onChange={e => handleChange(e, 'sendForWebSoftphone')}
              disabled={
                (!fromuserProfile && isUndefined(user?.web_softphone)) ||
                (fromuserProfile && !user?.mitelData?.mobile_voip)
              }
            />
            <div>WebSoftphone</div>
          </div>
          <div className={classes.info}>
            {translate('USER.ACTIVATION_MESSAGE_INFO_2')}
          </div>
          <div className={classes.row}>
            <Checkbox
              checked={data.sendEmail}
              onChange={e => handleChange(e, 'sendEmail')}
            />
            <div>E-Mail</div>
          </div>

          <div className={classes.row}>
            <Checkbox
              checked={data.sendSms}
              onChange={e => handleChange(e, 'sendSms')}
            />
            <div>Sms</div>
          </div>

          <div className={classes.smallInfo}>
            {translate('USER.ACTIVATION_MESSAGE_INFO_3')}{' '}
          </div>
          {isDefined(errors) && (
            <div
              className={classes.errors}
              style={{ color: settings.colors.danger }}
            >
              {errors.map(error => (
                <div>{error}</div>
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <PrimaryButton
            onClick={handleConfirm}
            disabled={
              (isFalse(data.sendEmail) && isFalse(data.sendSms)) ||
              (isFalse(data.sendForWeb) &&
                isFalse(data.sendForMobile) &&
                isFalse(data.sendForSoftphone) &&
                isFalse(data.sendForWebSoftphone))
            }
          >
            OK
          </PrimaryButton>

          <SecondaryButton onClick={onCloseDialog}>
            {translate('BUTTON.CANCEL')}
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ selectClient, activationMessageDialog, auth }) => {
  return {
    ...activationMessageDialog,
    clientDomain: selectClient.selectedClient?.connect30_domain,
    currentUserDomain: auth?.defaultClient?.connect30_domain
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog
};

export default flowRight(
  withRouter,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ActivationMessageDialog);

const isFalse = key => {
  return key === false;
};
