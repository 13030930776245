const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        flexDirection: 'column',
        alignItems: 'unset'
      }
    },
    datacard: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 60px 10px 20px',
      '& div': {
        marginRight: '20px',
        fontWeight: 500,
        color: '#646C9A'
      }
    },
    noDatacardCheckboxContainer: { width: '200px', height: '58px' },
    withoutMsisdn: {
      justifyContent: 'start'
    },
    buttons: {
      display: 'flex',
      width: '320px',
      justifyContent: 'space-around',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        marginTop: '10px',
        width: '100%'
      }
    }
  };
};

export default styles;
