import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_ORDERS = 'SET_ORDERS';
const RESET_ORDERS = 'RESET_ORDERS';
const SET_COLUMNS = 'SET_COLUMNS';
const CHANGE_FILTERED_BY = 'CHANGE_FILTERED_BY';

export const actionTypes = createReduxActionTypes('ORDERS', [
  SET_ORDERS,
  RESET_ORDERS,
  SET_COLUMNS,
  CHANGE_FILTERED_BY
]);

const DEFAULT_STATE = {
  orders: [],
  columns: [],
  filteredBy: 'all'
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ORDERS]: {
      return { ...state, orders: [...action.orders] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[CHANGE_FILTERED_BY]: {
      return { ...state, filteredBy: action.filteredBy };
    }
    case actionTypes[RESET_ORDERS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setOrders: orders => ({
    type: actionTypes[SET_ORDERS],
    orders
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  changeFilteredBy: filteredBy => ({
    type: actionTypes[CHANGE_FILTERED_BY],
    filteredBy
  }),
  resetOrders: () => ({ type: actionTypes[RESET_ORDERS] })
};
