import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import DataRow from '../../partials/Datarow/DataRow';
import PasswordForm from '../../partials/passwordForm/PasswordForm';
import { changePass } from './actions/changePass';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';

export function ChangePass({
  resetUser,
  classes,
  open,
  intl,
  clientName,
  user = {},
  changePass
}) {
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'BUTTON.CHANGE_PASSWORD'
      }),
    // eslint-disable-next-line
    [clientName]
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      open={open}
      onClose={resetUser}
    >
      <CustomDialogTitle onCloseClick={resetUser}>{title}</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DataRow
          label="Name"
          value={user.name}
          valueWidth={150}
          mobile={true}
        />
        <DataRow
          label="Username"
          value={user.username}
          valueWidth={150}
          mobile={true}
        />
        <DataRow
          label="E-mail"
          value={user.email}
          valueWidth={150}
          mobile={true}
        />

        <PasswordForm
          changePass={data => changePass(data, user.id)}
          handleClose={resetUser}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ changePass }) => {
  return {
    ...changePass
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  changePass
};

export default flowRight(
  withRouter,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ChangePass);
