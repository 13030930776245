import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData, updateData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';
import store from '../../../store/store';
import { getQueues } from '../../../pages/queues/actions/getQueues';

const changePriority = async ({
  agents,
  distributionType,
  queue,
  closeEditQueue,
  isAdminUser,
  isCurrentUserSelectedClientUser
}) => {
  const mappedAgents = mapAgentsToUpdate({ agents, distributionType });

  const { domain, number } = queue;

  if (queue.distributionType !== distributionType) {
    switch (queue.type) {
      case 'ACD': {
        const data = await getData(
          `${OUTSIDE_API.CLIENTS}/${domain}/acds/${number}`
        );

        await updateData({
          url: `${OUTSIDE_API.CLIENTS}/${domain}/acds/${number}`,
          data: { ...data, distributionType }
        });
        break;
      }
      case 'ATTENDANT': {
        const data = await getData(
          `${OUTSIDE_API.CLIENTS}/${domain}/attendants/${number}`
        );

        await updateData({
          url: `${OUTSIDE_API.CLIENTS}/${domain}/attendants/${number}`,
          data: { ...data, distributionType }
        });
        break;
      }
      default:
        return;
    }
  }

  const response = await updateData({
    url: `${OUTSIDE_API.CLIENTS}/${domain}/distribution_groups/${number}/change_skill_level`,
    data: { distribution: [...mappedAgents] }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.UPDATE_QUEUE',
      messageType: 'success',
      time: 2000
    });

    await store.dispatch(
      getQueues({
        connectDomain: domain,
        isAdminUser,
        isCurrentUserSelectedClientUser,
        sortQueues: true
      })
    );

    closeEditQueue();
  }
};

export default changePriority;

const mapAgentsToUpdate = ({ agents, distributionType }) => {
  if (distributionType === 'SKILLS') {
    return agents.map(agent => {
      return {
        user: agent.mitelUserId,
        priority: agent.skillLevel
      };
    });
  }

  if (distributionType === 'PRIORITY') {
    return agents.map((agent, i) => {
      return {
        user: agent.mitelUserId,
        priority: i + 1
      };
    });
  }

  return void 0;
};
