import { isArray, isStringEmpty } from '../../../utils/isDefined';
import { mapSearchValue } from '../../../utils/tableUtils';

export default function mapCustomFilters({ filter, obj, value }) {
  if (isArray(value) && value.length >= 2) {
    return obj;
  }

  const mappedValue = isArray(value) ? value.join(',') : mapSearchValue(value);

  if (isStringEmpty(mappedValue)) {
    return { ...obj };
  }

  switch (filter.column.field) {
    case 'department':
      return { ...obj, [`department.name`]: { match: mappedValue } };
    case 'roles':
      return { ...obj, [`roles.name`]: { match: mappedValue } };
    case 'client':
      return { ...obj, [`client.name`]: { match: mappedValue } };
    case 'sim_cards':
      return {
        ...obj,
        [`sim_cards.cell_numbers.number`]: { match: mappedValue }
      };
    case 'locked':
      return { ...obj, [filter.column.field]: { eq: mappedValue } };
    default:
      return { ...obj, [filter.column.field]: { match: mappedValue } };
  }
}
