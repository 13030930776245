import { URLS } from "../../../consts/endpoints";
import { translate } from "../../../i18n/I18nProvider";
import { getData } from "../../../utils/http";
import { doesArrayHasLength } from "../../../utils/isDefined";
import { actions as reduxActions } from "../reducers";

const mapAccounts = ({ clientId }) => {
  return async dispatch => {
    let accountsSelectsOptions = [];
    try {
      const data = await getData(`${URLS.QUOTE_CLIENTS}/${clientId}/accounts`);

      const mappedAcounts = createSelectOptions(data);

      accountsSelectsOptions = mappedAcounts;
    } finally {
      if (doesArrayHasLength(accountsSelectsOptions)) {
        dispatch(reduxActions.setAccounts(accountsSelectsOptions));
        dispatch(reduxActions.setSelectedAccount(accountsSelectsOptions[0]));
      }
    }
  };
};

export default mapAccounts;

const createSelectOptions = (data = []) => {
  const options = data.map(el => {
    const { id, name, accountNo } = el;
    return { value: id, label: `${name} - ${accountNo}`, ...el };
  });

  if (options.length > 1) {
    return [
      {
        externalReference: "all",
        value: "all",
        label: translate("INVOICES.ALL_ACCOUNTS"),
        name: translate("INVOICES.ALL_ACCOUNTS"),
      },
      ...options,
    ];
  }

  return options;
};
