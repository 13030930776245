import React, { useState } from 'react';
import { Badge, Popover, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import flowRight from 'lodash.flowright';

import styles from './styles';
import withSettings from '../../utils/withSettings';
import { actions as reduxActions } from './reducers';
import { injectIntl } from 'react-intl';
import CustomIconButton from '../tableCustomButtons/CustomIconButton';
import ErrorTab from './components/ErrorTab';
import clsx from 'clsx';

function ErrorsPopover({
  classes,
  settings,
  errors,
  resetErrors,
  intl,
  hideOnMobile = false
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx({ [classes.hideOnMobile]: hideOnMobile })}>
      <Tooltip
        title={intl.formatMessage({
          id: 'TOOLTIP.SHOW_ERRORS'
        })}
      >
        <Badge
          onClick={onOpen}
          badgeContent={errors.length}
          color="error"
          className={classes.badge}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <ErrorOutlineIcon color="error" fontSize="large" />
        </Badge>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.container}>
          <div
            className={classes.buttonRow}
            style={{ backgroundColor: settings.colors.modal_header }}
          >
            <div style={{ color: settings.colors.modal_header_text }}>
              {intl.formatMessage({
                id: 'ERRORS_LIST'
              })}
            </div>
            <div className={classes.buttonContainer}>
              <CustomIconButton
                iconTooltipIntlKey="CLEAR_ERRORS"
                iconClassName="far fa-trash-alt"
                onClick={resetErrors}
                customStyles={{ color: settings.colors.modal_header_text }}
              />
              <CustomIconButton
                iconClassName="far fa-times"
                onClick={onClose}
                customStyles={{ color: settings.colors.modal_header_text }}
              />
            </div>
          </div>
          {errors.map((error, i) => {
            return <ErrorTab key={i} error={error} />;
          })}
        </div>
      </Popover>
    </div>
  );
}

const mapStatetoProps = ({ errorsPanel }) => {
  return {
    ...errorsPanel
  };
};

const mapDispatchToProps = {
  resetErrors: reduxActions.resetErrors
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatetoProps, mapDispatchToProps),
  withStyles(styles)
)(ErrorsPopover);
