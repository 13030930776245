import { postData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { translate } from '../../../i18n/I18nProvider';
import mapSwapErrorResponse from './mapSwapErrorResponse';
import store from '../../../store/store';
import { actions as errorReduxActions } from '../../../partials/errorDialog/reducers';

export default function swapCard({ clientId, prefix, values, number }) {
  const fullIcc = `${prefix}${values.new_sim_card_icc}`;

  return async dispatch => {
    try {
      const response = await postData({
        url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/change_sim_card`,
        data: {
          phone_number: number,
          new_icc: fullIcc
        },
        passErrors: [
          RESPONSE_CODES.BAD_REQUEST,
          RESPONSE_CODES.UNPROCESSABLE_ENTITY
        ]
      });

      if (response?.status === RESPONSE_CODES.SUCCESS) {
        showSnackbar({
          intlTextKey: 'MESSAGE.SWAP_CARD_SEND',
          messageType: 'success'
        });
        dispatch(reduxActions.setCompleted(fullIcc));
      }
    } catch (e) {
      const messageText = mapSwapErrorResponse(e.response);

      store.dispatch(
        errorReduxActions.openDialog({
          directMessage: `${translate(
            'MESSAGE.CONTACT_SUPPORT'
          )} \n ${messageText}`
        })
      );
    }
  };
}
