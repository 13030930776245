import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';

import styles from './styles';
import Optional from '../../utils/optional';
import clsx from 'clsx';
import calculateMbToGb from '../../utils/calculateMbToGb';
import getNestedValue from '../../utils/getNestedValue';
import { isDefined } from '../../utils/isDefined';

export function InputRow({
  classes,
  name,
  label,
  labelText,
  handleBlur,
  handleChange,
  setFieldValue,
  values = {},
  errors = {},
  intl,
  fieldType,
  minValue = 0,
  maxValue = 1,
  customStyles = {},
  disabled = false,
  isMultiline = false,
  minRows = 1,
  mobile = false,
  starIcon = false,
  infoIcon = false,
  infoIconTooltipIntlKey = 'EMPTY',
  infoIconText,
  isDatalimitValue = false,
  maxRows = 1,
  isValueHidden = false,
  placeholder = '',
  inputAdornmentText = null,
  isValueNested = false,
  isRequired = false,
  customOnChange,
  mobileCustom = false
}) {
  const [valueHidden, setValueHidden] = useState(false);
  const title = useMemo(
    () =>
      Optional(label)
        .map(label =>
          intl.formatMessage({
            id: label
          })
        )
        .or(labelText),
    // eslint-disable-next-line
    [intl]
  );
  const errorMessage = Optional(errors[name]).or(null);
  const onNumberChange = event => {
    if (event.target.value >= minValue && event.target.value <= maxValue) {
      setFieldValue(name, event.target.value);
    }
  };

  const value = isValueNested
    ? getNestedValue(values, name)
    : Optional(values[name]).or('');

  const datalimitValue = Optional(values[name])
    .map(value => calculateMbToGb(value))
    .or('');

  return (
    <div
      className={clsx(classes.root, {
        [classes.mobileRoot]: mobile,
        [classes.mobileRootCustom]: mobileCustom
      })}
      style={{ ...customStyles.root }}
    >
      <div
        className={clsx(classes.field, {
          [classes.mobileField]: mobile,
          [classes.mobileFieldCustom]: mobileCustom
        })}
        style={{ ...customStyles.field }}
      >
        <div
          className={clsx(classes.label, {
            [classes.mobileLabel]: mobile,
            [classes.hiddenValue]: isValueHidden
          })}
          style={customStyles.label}
        >
          {title} {starIcon && <div className={classes.starIcon}>&#10038;</div>}{' '}
          {infoIcon && (
            <Tooltip
              title={Optional(infoIconText).or(
                intl.formatMessage({
                  id: infoIconTooltipIntlKey
                })
              )}
            >
              <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
            </Tooltip>
          )}
        </div>
        <div className={classes.valueContainer}>
          <div className={classes.requiredIcon}>{isRequired ? '*' : ''}</div>
          <TextField
            style={customStyles.textfield}
            name={name}
            className={clsx(classes.fieldValue, {
              [classes.mobileText]: mobile,
              [classes.disabled]: disabled
            })}
            margin="dense"
            variant="outlined"
            type={
              isValueHidden ? (valueHidden ? 'text' : 'password') : fieldType
            }
            value={isDatalimitValue ? datalimitValue : value}
            onBlur={handleBlur}
            onChange={
              fieldType === 'number'
                ? event => onNumberChange(event)
                : isDefined(customOnChange)
                ? customOnChange
                : handleChange
            }
            error={Boolean(errors[name])}
            disabled={disabled}
            multiline={isMultiline}
            minRows={minRows}
            maxRows={maxRows}
            InputProps={{
              startAdornment: isDefined(inputAdornmentText) && (
                <InputAdornment position="start">
                  {inputAdornmentText}
                </InputAdornment>
              ),
              endAdornment: isValueHidden && (
                <InputAdornment position="end">
                  {valueHidden ? (
                    <i
                      className={clsx('fas fa-eye', classes.eyeIcon)}
                      onClick={() => setValueHidden(!valueHidden)}
                    ></i>
                  ) : (
                    <i
                      className={clsx('fas fa-eye-slash', classes.eyeIcon)}
                      onClick={() => setValueHidden(!valueHidden)}
                    ></i>
                  )}
                </InputAdornment>
              )
            }}
            inputProps={{
              autoComplete: 'off',
              'data-lpignore': true
            }}
            placeholder={placeholder}
          />
        </div>
      </div>
      <div className={classes.errorMessage}>{errorMessage}</div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(InputRow);
