import React, { useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { getQueues } from '../actions/getQueues';
import { connect } from 'react-redux';

import styles from '../styles/tableTitle';
import { actions as reducActions } from '../reducers';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';
import withUserCheck from '../../../utils/withUserCheck';
import IsUserViewSwitch from './IsUserViewSwitch';

export function QueuesTableTitle({
  classes,
  intl,
  getQueues,
  isAdminUser,
  connectDomain,
  isCurrentUserSelectedClientUser
}) {
  const onRefreshClick = useCallback(() => {
    getQueues({
      connectDomain,
      isAdminUser,
      isCurrentUserSelectedClientUser,
      sortQueues: true
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.title}>
      <span className={classes.titleText}>
        {intl.formatMessage({
          id: 'MENU.QUEUES'
        })}
      </span>
      <div className={classes.actions}>
        {isCurrentUserSelectedClientUser && <IsUserViewSwitch />}
        <RefreshButton onClick={onRefreshClick} />
      </div>
    </div>
  );
}

const mapStatesToProps = ({ queues, selectClient }) => {
  return {
    queues: queues.queues,
    connectDomain: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  getQueues,
  changeRefreshTime: reducActions.changeRefreshTime
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(QueuesTableTitle);
