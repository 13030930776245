import { all, call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_DATALIMITS } from './types';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import mapDataLimitsToUpdate from './mapDataLimitsToUpdate';
import { fetchDataLimits } from '../../../pages/dataLimits/actions/getDataLimits';
import { translate } from '../../../i18n/I18nProvider';

export function updateOnSubmit(datalimits, formValues, client) {
  return {
    type: sagaActions[UPDATE_DATALIMITS],
    datalimits,
    formValues,
    client
  };
}

export function* updateDatalimits({ datalimits, formValues, client }) {
  const mappedDatalimits = mapDataLimitsToUpdate({ datalimits, formValues });

  yield all(
    mappedDatalimits.map(datalimit => {
      return call(updateData, {
        url: `${URLS.DATA_LIMITS}/${datalimit.id}`,
        data: {
          data: datalimit
        },
        successSnackbar: {
          intlTextKey: 'MESSAGE.UPDATE_DATALIMIT',
          messageText: `${translate('DATA_LIMITS.DATALIMIT')} ${translate(
            'MESSAGE.UPDATED'
          )} `
        }
      });
    })
  );

  yield put(reduxActions.closeDialog());
  yield call(fetchDataLimits, { clientId: client });
}

function* watchUpdateDatalimits() {
  yield takeLatest(sagaActions[UPDATE_DATALIMITS], updateDatalimits);
}

export default watchUpdateDatalimits();
