import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchUsersContacts } from '../actions/getUserContacts';
import styles from '../styles';
import SearchBar from './SearchBar';
import ContactCard from './ContactCard';
import clsx from 'clsx';

function Contacts({
  classes,
  fetchUsersContacts,
  contacts,
  fromPanel = false,
  refresh
}) {
  useEffect(
    () => {
      fetchUsersContacts({});
    },
    // eslint-disable-next-line
    [refresh]
  );

  return (
    <div
      className={clsx(classes.contacts, { [classes.showContacts]: fromPanel })}
    >
      <SearchBar />
      {contacts.map(contact => {
        return <ContactCard contact={contact} key={contact.id} />;
      })}
    </div>
  );
}

const mapStatesToProps = ({ userContacts }) => {
  return {
    refresh: userContacts.refresh,
    contacts: userContacts.contacts
  };
};

const mapDispatchToProps = {
  fetchUsersContacts
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Contacts);
