import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isArray } from '../../utils/isDefined';

import Optional from '../../utils/optional';
import InputRow from '../dataRows/InputRow';
import SelectRow from '../dataRows/SelectRow';
import getClientAddresses from './actions/getClientAddresses';
import { isEmptyArray } from 'formik';

function CustomAddressSelect({
  selectName,
  inputName,
  fieldProps,
  setFieldValue,
  currentClientId,
  clientId,
  disabled = false,
  customLabel,
  maxRows = 1,
  menuPlacement,
  maxMenuHeight,
  canAddAddress = true,
  idAsValue = false,
  splitLines = false,
  additionalValues = null,
  isRequired = false,
  reload,
  disableReload = false,
  customStyles = {}
}) {
  const [addresses, setAddresses] = useState([]);
  useEffect(() => {
    if (
      !disableReload ||
      (disableReload && isEmptyArray(addresses)) ||
      reload
    ) {
      (async function() {
        const addresses = await getClientAddresses({
          id: Optional(clientId).or(currentClientId),
          idAsValue,
          splitLines
        });
        if (isArray(additionalValues)) {
          setAddresses([...additionalValues, ...addresses.splice(1)]);
        } else {
          setAddresses(addresses);
        }
      })();
    }
    // eslint-disable-next-line
  }, [currentClientId, clientId, additionalValues, reload, disableReload]);

  return (
    <>
      <SelectRow
        name={selectName}
        label={Optional(customLabel).or('ORDER_SIMCARD.SELECT_ADDRESS')}
        isEditModal={false}
        options={addresses}
        setFieldValue={setFieldValue}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        mobile={true}
        isRequired={isRequired}
        customStyles={customStyles}
        {...fieldProps}
      />
      {canAddAddress && (
        <InputRow
          label="ADDRESS"
          name={inputName}
          isMultiline={true}
          minRows={4}
          disabled={disabled}
          maxRows={maxRows}
          mobile={true}
          {...fieldProps}
        />
      )}
    </>
  );
}

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    currentClientId: Optional(selectClient.selectedClient)
      .map(client => client.id)
      .or(auth.defaultClient.id)
  };
};

export default connect(mapStatesToProps)(CustomAddressSelect);
