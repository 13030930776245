import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles';
import { CONST_VALUES } from '../../../consts/initialValues';
import withSettings from '../../../utils/withSettings';

function UnlockDialogText({
  classes,
  intl,
  isPartnerClient,
  websiteURL,
  settings
}) {
  return (
    <div className={clsx(classes.dialogTextContainer, classes.unlockDialog)}>
      {intl.formatMessage({
        id: 'NUMBER.UNLOCK_SIMCARD'
      })}
      <div
        className={classes.appendixLink}
        style={{ color: settings.colors.links }}
        onClick={() =>
          window.open(isPartnerClient ? websiteURL : CONST_VALUES.APPENDIX_LINK)
        }
      >
        {isPartnerClient
          ? intl.formatMessage({
              id: 'NUMBER.LOCK_SIMCARD_LINK_PARTNER'
            })
          : intl.formatMessage({
              id: 'NUMBER.LOCK_SIMCARD_LINK'
            })}
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(UnlockDialogText);
