import { isStringDefined } from '../../../utils/isDefined';

const mapDataToCreate = ({ range, clientId }) => {
  const {
    network,
    future_status,
    address,
    range_from,
    range_up_to_incl,
    ...restRange
  } = range;

  let data = {
    data: {
      type: 'external_number_ranges',
      attributes: {
        ...restRange,
        range_from: Number(range_from),
        range_up_to_incl: isStringDefined(range_up_to_incl)
          ? Number(range_up_to_incl)
          : Number(range_from)
      },
      relationships: {
        client: {
          data: { type: 'client', id: Number(clientId) }
        }
      }
    }
  };

  if (isStringDefined(network)) {
    data.data.relationships.provider = {
      data: { type: 'providers', id: Number(network) }
    };
  }

  return data;
};

export default mapDataToCreate;
