const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      fontWeight: 500,
      fontSize: '12px',
      margin: '0 0 0 5px',
      color: '#C75455',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& i': {
        cursor: 'pointer'
      }
    },
    presence: { display: 'flex', alignItems: 'center' },
    dotsMenuIcon: { margin: '0 10px' },
    data: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px'
    },
    available: {
      color: '#82C91E'
    },
    selectConatiner: {
      display: 'flex',
      alignItems: 'center',
      height: '33px',
      '& i': {
        marginLeft: '10px'
      }
    },
    rightPreseneceRowMeuContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  };
};

export default styles;

export const customStyles = {
  option: {
    fontSize: '12px',
    padding: '5px 10px'
  },
  control: {
    borderStyle: 'hidden',
    width: '240px',
    boxShadow: 'none'
  },
  menu: { marginTop: '-35px', width: 'fit-content' },
  valueContainer: { fontSize: '12px' },
  indicatorSeparator: { display: 'none' },
  group: {
    paddingTop: '4px',
    paddingBottom: '4px'
  }
};
