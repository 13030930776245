import { JSONAPI_URLS } from '../../../consts/endpoints';
import fetchTableData from '../../../utils/fetchTableData';
import { isDefined } from '../../../utils/isDefined';
import mapCustomFilters from './mapCustomFilters';
import { mapPlatform } from './mapMitelData';

export default async function fetchRangesPaginate({
  query,
  client,
  getServiceOperator,
  getConnectRanges
}) {
  const queryParams = mapQueryParams({
    client,
    getServiceOperator,
    getConnectRanges
  });

  const requestUrl = JSONAPI_URLS.EXTERNAL_NO_RANGES;

  const response = await fetchTableData({
    query,
    queryParams,
    requestUrl,
    defaultSort: '',
    mapCustomFilters
  });

  const mappedRanges = mapPlatform(response?.data);

  return { ...response, data: mappedRanges };
}

export function mapQueryParams({
  client,
  getServiceOperator,
  getConnectRanges
}) {
  let external_number_ranges = [];

  if (getServiceOperator) {
    external_number_ranges = [...external_number_ranges, 'service_operator'];
  }
  if (getConnectRanges) {
    external_number_ranges = [
      'connect30_number_ranges',
      ...external_number_ranges
    ];
  }
  let queryParams = {
    include: ['client', 'provider', 'address'],
    fields: { client: ['name', 'csbc_routing'] },
    extra_fields: {
      external_number_ranges
    },
    filter: { client_id: isDefined(client) ? { eq: client } : { gt: 0 } }
  };

  return queryParams;
}
