import { isDefined, isUndefined } from '../../../utils/isDefined';

const STANDARD_AGENT_TYPE = 'AGENT';

export default function sortQueues(queues) {
  const notUserQueues = queues.filter(queues => isUndefined(queues.roles));
  const onlySupervisorQueues = queues.filter(
    queue =>
      isDefined(queue.roles) &&
      isUndefined(queue.roles.find(role => role.type === STANDARD_AGENT_TYPE))
  );

  const agentQueues = queues.filter(
    queue =>
      isDefined(queue.roles) &&
      isDefined(queue.roles.find(role => role.type === STANDARD_AGENT_TYPE))
  );

  const loggedQueues = agentQueues.filter(queue => queue.isLoggedIn === true);

  const unloggedQueues = agentQueues.filter(
    queue => queue.isLoggedIn === false
  );
  return [
    ...loggedQueues,
    ...unloggedQueues,
    ...onlySupervisorQueues,
    ...notUserQueues
  ];
}
