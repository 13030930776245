import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { actions as reduxAction } from './reducers';
import SimCardsTable from './components/SimCardsTable';
import getIdFromUrl from '../../utils/getIdfromUrl';
import OrderNewSimcard from '../../modals/orderNewSimcard/OrderNewSimcard';
import getCustomJobs from '../../utils/getCustomJobs';

function SimCards({ resetSimCards, location }) {
  const client = getIdFromUrl(location);

  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
    return resetSimCards;
    // eslint-disable-next-line
  }, [client]);

  return (
    <div>
      <SimCardsTable />
      <OrderNewSimcard />
    </div>
  );
}

const mapDispatchToProps = {
  resetSimCards: reduxAction.resetSimCards
};

export default flowRight(
  withRouter,
  connect(null, mapDispatchToProps)
)(SimCards);
