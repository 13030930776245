export default {
  root: {
    margin: '5px 10px'
  },
  checkboxRoot: { margin: '5px 10px', display: 'flex', alignItems: 'center' },
  label: { width: '100%', padding: '5px 0', fontSize: '12px' },
  value: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '46px',
    border: '1px solid #ECECF2',
    borderRadius: '5px',
    width: '100%',
    padding: '12px',
    backgroundColor: '#FFF'
  },
  infoIcon: {
    marginLeft: '5px'
  },
  selectRoot: { display: 'flex', flexDirection: 'column', margin: '5px 10px' }
};
