const styles = {
  root: { marginBottom: '15px' },
  dataContainer: { display: 'flex', margin: '5px' },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: '#16374F',
    fontWeight: 500,
    width: '200px',
    marginRight: '20px',
    fontSize: '12px'
  },
  fieldValue: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
    borderRadius: '4px',
    width: '250px',
    margin: 0,
    '& p': {
      margin: '2px 0'
    }
  }
};

export default styles;
