import React, { cloneElement, useCallback, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';

import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import { translate } from '../../i18n/I18nProvider';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../partials/customButtons/SecondaryButton';
import DraggableDialog from '../../partials/DraggableDialog';
import { isDefined } from '../../utils/isDefined';
import styles, { customStyles } from './styles';
import CustomAddressSelect from '../../partials/customAddressSelect/CustomAddressSelect';
import CreateAddress from '../../partials/createAddress/CreateAddress';
import InputRow from '../../partials/dataRows/InputRow';
import Optional from '../../utils/optional';
import updateClient from './actions/updateClient';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';

const EditClientForClientAdmin = ({
  closeMenu,
  children,
  classes,
  clientId,
  addressId,
  displayName
}) => {
  const [open, setOpen] = useState(false);

  const onOpenDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseDialog = () => {
    closeMenu();

    setOpen(false);
  };

  const handleConfirm = async values => {
    await updateClient({ values, clientId, onCloseDialog });
  };

  return (
    <>
      {isDefined(children) && cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('TOOLTIP.EDIT_CLIENT')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={{
              addressId: addressId.toString(),
              displayName,
              reload: false
            }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleConfirm}
          >
            {({
              handleSubmit,
              errors,
              values,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              const fieldProps = {
                errors,
                values,
                handleBlur,
                handleChange
              };

              const setAddresId = id => {
                setFieldValue('addressId', id);
                setFieldValue('reload', true);
                setFieldValue('reload', false);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <div className={classes.infoContainer}>
                    {translate('CLIENT.EDIT_CLIENT_INFO')}
                  </div>
                  <InputRow
                    label="CLIENT.DISPLAY_NAME"
                    name="displayName"
                    disabled={
                      !can(
                        'update',
                        createSubject('Client', {
                          id: Number(clientId)
                        }),
                        'display_name'
                      )
                    }
                    customStyles={customStyles.input}
                    {...fieldProps}
                  />
                  <CustomAddressSelect
                    selectName="addressId"
                    customLabel="PHONE_BOOK.DEFAULT_ADDRESS"
                    fieldProps={fieldProps}
                    canAddAddress={false}
                    setFieldValue={setFieldValue}
                    idAsValue={true}
                    splitLines={true}
                    clientId={clientId}
                    reload={values.reload}
                    additionalValues={[]}
                    disableReload={true}
                    customStyles={customStyles.select}
                    disabled={
                      !can(
                        'update',
                        createSubject('Client', {
                          id: Number(clientId)
                        }),
                        'primary_address_id'
                      )
                    }
                  />
                  <div className={classes.newAddressContainer}>
                    <CreateAddress
                      fromTable={false}
                      customAction={id => setAddresId(id)}
                    >
                      <PrimaryButton
                        maxWidth="200px"
                        disabled={
                          !can(
                            'update',
                            createSubject('Client', {
                              id: Number(clientId)
                            }),
                            'primary_address_id'
                          )
                        }
                      >
                        {translate('BUTTON.NEW_ADDRESS')}
                      </PrimaryButton>
                    </CreateAddress>
                  </div>
                  <footer className={classes.footer}>
                    <PrimaryButton onClick={handleSubmit}>
                      {translate('BUTTON.SAVE')}
                    </PrimaryButton>

                    <SecondaryButton onClick={onCloseDialog}>
                      {translate('BUTTON.CANCEL')}
                    </SecondaryButton>
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id,
    addressId: Optional(selectClient.selectedClient.primary_address_id).or(''),
    displayName: Optional(
      selectClient.selectedClient.metadata?.display_name
    ).or('')
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(EditClientForClientAdmin);
