const styles = theme => ({
  root: {
    padding: '5px 20px',
    fontSize: '11px',
    color: '#74788D'
  },
  icon: {
    marginRight: '5px'
  }
});

export default styles;
