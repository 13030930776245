export default function mapDataToUpdate({ values, role, isEdit }) {
  const { id, main = true, permissions: initialPermissions } = role;
  const { name, context, permissions } = values;

  const mappedPermissions = permissions.map(role => {
    return { type: 'roles', id: role.id };
  });

  const permissionsToDelete = isEdit
    ? initialPermissions.reduce((arr, initialPermission) => {
        if (
          mappedPermissions.find(
            permission => permission.id === initialPermission.id
          )
        ) {
          return [...arr];
        }

        return [
          ...arr,
          { type: 'roles', id: initialPermission.id, method: 'disassociate' }
        ];
      }, [])
    : [];

  const data = {
    type: 'roles',
    attributes: {
      name,
      context,
      main
    },
    relationships: {
      permissions: {
        data: [...mappedPermissions, ...permissionsToDelete]
      }
    }
  };

  if (isEdit) {
    data.id = id;
  }

  return data;
}
