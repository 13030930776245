import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    holiday: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    holiday_type: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    date: yup
      .string()
      .matches(/^[0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
      .test(null, 'number is not valid', function(value) {
        const number = Number(value);

        return number < 1
          ? this.createError({
              message: translate('VALIDATION.NUMBER_BETWEEN', {
                from: 1,
                to: 31
              })
            })
          : number > 31
          ? this.createError({
              message: translate('VALIDATION.NUMBER_BETWEEN', {
                from: 1,
                to: 31
              })
            })
          : true;
      })
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    month: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
  });
}
