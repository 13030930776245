import { call, put, select } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, LOCK_USER } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { getData, postData } from '../../../utils/http';
import { actions as reduxActions, getters } from '../reducers';
import urlCreator from '../../../utils/urlCreator';
import mapUsersAfterLock from '../../../utils/mapUsersAfterLock';

export function lockUserAction(id, action) {
  return {
    type: sagaActions[LOCK_USER],
    id,
    action
  };
}

export function* lockUser({ id, action }) {
  const intlTextKey =
    action === 'lock' ? 'MESSAGE.USER_LOCKED' : 'MESSAGE.USER_UNLOCKED';

  const response = yield call(postData, {
    url: `${URLS.USERS}/${id}/${action}`,
    data: {}
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey,
      messageType: 'success'
    });

    const { data: updatedUser } = yield call(
      getData,
      urlCreator(`${URLS.USERS}/${id}.json`, {})
    );

    const users = yield select(getters.getUsers);

    const mappedUsers = yield call(mapUsersAfterLock, { updatedUser, users });

    yield put(reduxActions.setUsers(mappedUsers));
  }
}

function* watchLockUser() {
  yield takeLatest(sagaActions[LOCK_USER], lockUser);
}

export default watchLockUser();
