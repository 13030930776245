import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmptyArray } from 'formik';
import { flowRight } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import { deleteItem } from '../actions/deleteCallLogItem';
import { fetchUserLogs } from '../actions/getUserCallLog';
import { mapIsFavorite } from '../actions/mapCallLog';
import { actions as reduxActions } from '../reducers';

import styles from '../styles/callLog';
import CallLogCard from './CallLogCard';
import ShowMore from './ShowMore';

function CallLogContainer({
  classes,
  fetchUserLogs,
  callLog,
  settings,
  intl,
  deleteItem,
  callLogCount,
  setAreMissedCalls,
  areMissedCalls,
  favorites,
  refresh
}) {
  useEffect(
    () => {
      fetchUserLogs({});
    },
    // eslint-disable-next-line
    [refresh]
  );

  const onAllClick = useCallback(() => {
    fetchUserLogs({});
    setAreMissedCalls(false);
    // eslint-disable-next-line
  }, [fetchUserLogs]);

  const onMissedClick = useCallback(() => {
    fetchUserLogs({
      areMissedCalls: true
    });
    setAreMissedCalls(true);
    // eslint-disable-next-line
  }, [fetchUserLogs]);

  const onDeleteClick = useCallback(() => {
    deleteItem({
      allAction: 'CLEAR_LOG',
      deleteAll: true
    });
  }, [deleteItem]);

  const dataToShow = useMemo(() => {
    if (isUndefined(callLog) || isUndefined(favorites)) {
      return callLog;
    }

    return callLog.map(log => {
      return {
        ...log,
        isFavorite: mapIsFavorite(log, favorites)
      };
    });
  }, [favorites, callLog]);

  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <div className={classes.title}>
        {intl.formatMessage({
          id: 'CONTACTS.CALL_LOG'
        })}{' '}
        <ConfirmDialog
          onConfirmClick={onDeleteClick}
          dialogContentComponent={intl.formatMessage({
            id: 'CONTACTS.DELETE_ALL_CONFIRMATION'
          })}
          dialogTitleIntlId={'BUTTON.CLEAR.LOG'}
          diasabledChildren={isEmptyArray(callLog)}
        >
          <SecondaryButton>
            <div
              className={classes.deleteAllButton}
              disabled={isEmptyArray(callLog)}
            >
              {intl.formatMessage({
                id: 'BUTTON.CLEAR.LOG'
              })}
              <i className={clsx('fal fa-trash', classes.deleteIcon)}></i>
            </div>
          </SecondaryButton>
        </ConfirmDialog>
      </div>
      <div className={classes.buttonContainer}>
        {!areMissedCalls ? (
          <PrimaryButton onClick={onAllClick}>
            {intl.formatMessage({
              id: 'BUTTON.ALL'
            })}
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={onAllClick}>
            {intl.formatMessage({
              id: 'BUTTON.ALL'
            })}
          </SecondaryButton>
        )}
        {areMissedCalls ? (
          <PrimaryButton>
            {intl.formatMessage({
              id: 'BUTTON.MISSED_CALLS'
            })}
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={onMissedClick}>
            {intl.formatMessage({
              id: 'BUTTON.MISSED_CALLS'
            })}
          </SecondaryButton>
        )}
      </div>
      {isDefined(dataToShow) &&
        dataToShow.map((log, i) => <CallLogCard data={log} key={i} />)}

      {isDefined(dataToShow) && callLogCount - 1 > dataToShow.length && (
        <ShowMore />
      )}
    </div>
  );
}

const mapStatesToProps = ({ userContacts }) => {
  return {
    favorites: userContacts.favorites,
    areMissedCalls: userContacts.areMissedCalls,
    callLog: userContacts.callLog,
    callLogCount: userContacts.callLogCount,
    refresh: userContacts.refresh
  };
};

const mapDispatchToProps = {
  fetchUserLogs,
  deleteItem,
  setAreMissedCalls: reduxActions.setAreMissedCalls
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(CallLogContainer);
