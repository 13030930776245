import orderBy from 'lodash.orderby';
import { isArray, isDefined } from './isDefined';
import moment from 'moment';
import OPTIONS from '../consts/selectsOptions';

const mapVacations = (vacations = {}) => {
  if (!isArray(vacations)) {
    return [];
  }

  const mapped = vacations.map(v => {
    return { ...v, shortDate: mapDate(v) };
  });

  return orderBy(mapped, 'shortDate');
};

export const mapDate = data => {
  const { date, month } = data;

  const fullDate = `2000${findMonthTranslation(month)}${
    date.toString().length === 1 ? `0${date}` : date
  }`;

  return moment(fullDate).format('MMDD');
};

const findMonthTranslation = value => {
  const founded = OPTIONS.VACATION_MONTHS.find(
    option => option.value === value
  );

  if (isDefined(founded)) {
    return founded.no;
  }

  return value;
};

export default mapVacations;
