import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';

function DeleteCustomJobConfimation({ classes, intl }) {
  return (
    <div className={classes.dialogTextContainer}>
      {translate('CUSTOM_JOB.DELETE_CONFIMATION')}
    </div>
  );
}

export default withStyles(styles)(DeleteCustomJobConfimation);
