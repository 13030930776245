import { createSagaActionTypes } from '../../../utils/actionFactory';

export const SET_USER = 'SET_USER';
export const SET_TELEPHONY_USER = 'SET_TELEPHONY_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_CLIENT_USERS = 'SET_CLIENT_USERS';
export const UPDATE_TELEPHONY_USER = 'UPDATE_TELEPHONY_USER';

export const actions = createSagaActionTypes('EDIT_USER', [
  SET_USER,
  SET_TELEPHONY_USER,
  UPDATE_USER,
  SET_CLIENT_USERS,
  UPDATE_TELEPHONY_USER
]);
