export default {
  dialogContent: {
    overflow: 'visible'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    '& button': {
      marginRight: '10px'
    }
  }
};
