import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, CHANGE_PASS } from './types';
import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export function changePass(data) {
  return {
    type: sagaActions[CHANGE_PASS],
    data
  };
}

export function* updatePass({ data }) {
  try {
    const response = yield call(updateData, {
      url: URLS.PASS,
      data: {
        user: { ...data }
      },
      errorsToPass: [RESPONSE_CODES.UNPROCESSABLE_ENTITY]
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.UPDATE.PASSWORD',
        messageType: 'success'
      });
    }

    yield put(reduxActions.resetUser());
  } catch (e) {
    if (e.response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.INVALID_CURRENT_PASSWORD',
        messageType: 'error'
      });
    } else {
      yield call(showSnackbar, {
        text: JSON.stringify(e.response.data.errors),
        messageType: 'error'
      });
    }
  }
}

function* watchUpdatePass() {
  yield takeLatest(sagaActions[CHANGE_PASS], updatePass);
}

export default watchUpdatePass();
