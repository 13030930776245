import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import styles from './styles';
import NumberLookup from './components/NumberLookup';
import NumbersDataContainer from './components/NumbersDataContainer';
import { actions as reduxActions } from './reducers';

function CheckNumber({ classes, resetNumberData, fromRightPanel = false }) {
  useEffect(() => {
    if (!fromRightPanel) {
      return resetNumberData;
    }
  }, [resetNumberData, fromRightPanel]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rightPanelRoot]: fromRightPanel
      })}
    >
      <div>
        <NumberLookup fromRightPanel={fromRightPanel} />
        <NumbersDataContainer fromRightPanel={fromRightPanel} />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  resetNumberData: reduxActions.resetCheckNumber
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(CheckNumber);
