import React from 'react';
import { connect } from 'react-redux';
import { ROLES, ROLES_GROUPS, ROLES_IDS } from '../consts/rolesIds';
import compareIds from './compareIds';
import compareRolesInArray from './compareRolesInArray';
import { isDefined } from './isDefined';

export default function withUserCheck(WrappedComponent) {
  const mapStatesToProps = ({ auth, selectClient }) => ({
    isClientUser:
      compareRolesInArray(auth.role, ROLES_GROUPS.CLIENT_USERS) ||
      auth.role.id <= ROLES_IDS.CLIENT_ADMIN,
    isNonClientAdminUser:
      compareRolesInArray(auth.role, ROLES_GROUPS.NON_CLIENT_ADMINS) ||
      auth.role.id >= ROLES_IDS.PARTNER_ADMIN,
    isPartnerOrDstnyAdmin: auth.role.id >= ROLES_IDS.PARTNER_ADMIN,
    isSuperAdmin:
      auth.role.name === ROLES.IPV_SUPERVISOR ||
      auth.role.id >= ROLES_IDS.IPV_SUPERVISOR,
    isAdminUser:
      compareRolesInArray(auth.role, ROLES_GROUPS.ADMINS) ||
      auth.role.id >= ROLES_IDS.CLIENT_ADMIN,
    isCurrentUserSelectedClientUser: compareIds(
      auth.defaultClient.id,
      selectClient.selectedClient.id
    ),
    isStandardUser: auth.role.name === ROLES.STANDARD_USER,
    isClientAdminUser: auth.role.name === ROLES.CLIENT_ADMIN,
    isPartnerAdmin: auth.role.name === ROLES.PARTNER_ADMIN,
    hasCurrentUserConnectId: isDefined(auth.user?.connect30_user_id),
    hasUSerPartner: isDefined(auth.user?.partner_id),
    currentUserConnectId: auth.user?.connect30_user_id,
    isMitelTokenProvided: isDefined(auth.user?.mitel_token)
  });

  return connect(mapStatesToProps)(({ ...props }) => {
    return <WrappedComponent {...props} />;
  });
}
