const styles = theme => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& i': {
        fontSize: '30px',
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
          display: 'flex'
        }
      }
    },
    label: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 10px 0 20px'
    },
    accountNo: {
      fontSize: '12px',
      color: '#888',
      fontWeight: 400
    }
  };
};

export default styles;
