import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../consts/tableOptions';
import Optional from '../../utils/optional';
import { getAllAddresses } from './actions/getAddresses';
import { actions as reduxActions } from './reducers/addressesReducer';
import FilterButton from '../../partials/tableCustomButtons/FilterButton';
import CustomTableTitle from '../../partials/CustomTableTitle';
import tableColumns from './components/tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../utils/localstorageUtils';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';
import customTableIcons from '../../partials/tableIcons/customTableIcons';
import filterCustomJobs from '../../utils/filterCustomJobs';
import { doesArrayHasLength, isArrayEmpty } from '../../utils/isDefined';
import CustomJobsMenu from '../../partials/customJobsMenu/CustomJobsMenu';
import ConnectedNumberRangesTable from './components/ConnectedNumberRangesTable';
import { Tooltip } from '@material-ui/core';
import AddAddress from './components/AddAddress';
import ConnectedPhonebookRegistrationTable from './components/ConnectedPhonebookRegistrationTable';
import withSettings from '../../utils/withSettings';
import RefreshButton from '../../partials/tableCustomButtons/RefreshButton';

export function Addresses({
  intl,
  clientId,
  addressesList,
  resetAddress,
  getAllAddresses,
  isLoading,
  columns,
  setColumns,
  customJobs,
  settings
}) {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getAllAddresses(clientId);
    return resetAddress;
    // eslint-disable-next-line
  }, [clientId, refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ clientId });
  }, [clientId]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Addresses-2'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Addresses');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        },
        {
          icon: () => <AddAddress />,
          onClick: () => {},
          isFreeAction: true,
          hidden: !can(
            'create',
            createSubject('Address', { client_id: Number(clientId) })
          )
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(!refresh),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const options = useMemo(() => {
    return { ...mapTableOptions({ exportData: false }), filtering };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Addresses-2'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const detailPanelIconsStyles = {
    fontSize: '18px',
    color: settings.colors.links,
    fontWeight: 400,
    '&:hover': { filter: 'brightness(1.5)' }
  };

  const detailPanel = [
    rowData => ({
      disabled: isArrayEmpty(rowData.external_number_ranges),
      icon: () => (
        <Tooltip
          title={intl.formatMessage({
            id: 'TOOLTIP.CONNECTED_NUMBER_RANGES'
          })}
        >
          <i
            className="fal fa-th-list"
            style={{
              display: isArrayEmpty(rowData.external_number_ranges)
                ? 'none'
                : '',
              ...detailPanelIconsStyles
            }}
          ></i>
        </Tooltip>
      ),
      openIcon: () => (
        <i
          className="fal fa-th-list"
          style={{ backgroundColor: '#EDEEF4', ...detailPanelIconsStyles }}
        ></i>
      ),
      render: () => (
        <ConnectedNumberRangesTable
          numberRanges={rowData.external_number_ranges}
        />
      )
    }),
    rowData => ({
      disabled: isArrayEmpty(rowData.phone_book_registrations),
      icon: () => (
        <Tooltip
          title={intl.formatMessage({
            id: 'TOOLTIP.CONNECTED_PHONEBOOK'
          })}
        >
          <i
            className="fal fa-address-book"
            style={{
              display: isArrayEmpty(rowData.phone_book_registrations)
                ? 'none'
                : '',
              ...detailPanelIconsStyles
            }}
          ></i>
        </Tooltip>
      ),
      openIcon: () => (
        <i
          className="fal fa-address-book"
          style={{ backgroundColor: '#EDEEF4', ...detailPanelIconsStyles }}
        ></i>
      ),
      render: () => (
        <ConnectedPhonebookRegistrationTable
          data={rowData.phone_book_registrations}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )
    })
  ];

  return (
    <div>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.ADDRESSES"
            icon={<i className="fas fa-map-marker-alt"></i>}
            isLoading={isLoading}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={addressesList}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
        detailPanel={detailPanel}
      />
    </div>
  );
}

const mapStatesToProps = ({
  selectClient,
  auth,
  addresses: { addresses },
  loader
}) => {
  return {
    isLoading: loader.isSpinVisible,
    addressesList: Optional(addresses.addresses).or([]),
    columns: addresses.columns,
    clientId: Optional(selectClient.selectedClient)
      .map(client => client.id)
      .or(auth.defaultClient.id),
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  getAllAddresses,
  setColumns: reduxActions.setColumns,
  resetAddress: reduxActions.resetAddress
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps)
)(Addresses);
