import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData, updateData } from '../../../utils/http';

import store from '../../../store/store';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { isObject } from '../../../utils/isDefined';

const updateLicenses = async ({ changes }) => {
  store.dispatch(loaderReduxActions.startSpinLoader());

  const connectClientId = store.getState().selectClient.selectedClient
    .connect30_domain;

  try {
    const mappedData = mapData({ changes });

    const response = await updateData({
      url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/licenses`,
      data: mappedData
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.UPDATE.LICENSE',
        messageType: 'success',
        time: 3000
      });
    }
  } finally {
    const licenses = await getData(
      `${OUTSIDE_API.CLIENTS}/${connectClientId}/licenses`
    );

    store.dispatch(reduxActions.setLicenses(licenses));

    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
};

export default updateLicenses;

const mapData = ({ changes = {} }) => {
  const objKeys = Object.keys(changes);

  if (objKeys.length === 0) {
    return void 0;
  }

  const licenses = objKeys.reduce((arr, key) => {
    if (!isObject(changes[key].newData)) {
      return arr;
    }

    const { component, quota } = changes[key].newData;

    return [...arr, { component, quota }];
  }, []);

  if (licenses.length === 0) {
    return void 0;
  }

  return { licenses };
};
