const styles = theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexWrap: 'wrap',
      color: '#16374F'
    },
    databoxContainer: {
      display: 'flex',
      width: '1020px',
      justifyContent: 'space-between'
    },
    dataRoot: { display: 'flex' },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    container: {
      width: '500px',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px',
      padding: '10px 0',
      [theme.breakpoints.down('xs')]: {
        width: '310px'
      }
    },
    numberError: {
      fontSize: '12px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    rightPanelRoot: {
      backgroundColor: '#F2F3F8',
      height: 'fit-content'
    },
    panelContainerRoot: {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      justifyContent: 'unset'
    },
    popupContainerRoot: {
      flexDirection: 'row',
      justifyContent: 'center'
    },
    icon: {
      fontSize: '40px',
      '&:hover': { filter: 'brightness(1.5)' },
      cursor: 'pointer'
    },
    disablePointer: { cursor: 'unset', '&:hover': { filter: 'brightness(1)' } }
  };
};

export default styles;
