const styles = theme => ({
  root: {
    width: '400px',
    backgroundColor: '#fff',
    margin: '0 5px 5px 5px',
    padding: '5px 0',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      maxWidth: '400px',
      margin: '5px 0'
    }
  },
  datarowValue: { width: '200px', justifyContent: 'flex-end' },
  actions: {
    display: 'flex',
    fontSize: '15px',
    justifyContent: 'flex-end',
    padding: '10px 20px'
  },
  icon: {
    paddingRight: '10px',
    cursor: 'pointer'
  }
});

export default styles;
