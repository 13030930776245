import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import withSettings from '../../../utils/withSettings';
import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';
import ExpandAllSwitch from './ExpandAllSwitch';
import GroupingSelector from './GroupingSelector';
import FilterByStatus from './FilterByStatus';

const SubscriptionsTableHeader = ({ classes, settings }) => {
  return (
    <div
      className={classes.headerRoot}
      style={{ color: settings.colors.primary }}
    >
      <div className={classes.label}>
        <i className={clsx('fas fa-business-time', classes.icon)}></i>
        <div className={classes.title}>{translate('MENU.SUBSCRIPTIONS')}</div>
      </div>
      <ExpandAllSwitch />
      <GroupingSelector />
      <FilterByStatus />
    </div>
  );
};

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(SubscriptionsTableHeader);
