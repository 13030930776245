const styles = theme => {
  return {
    closeIcon: {
      fontSize: '20px',
      margin: '10px 20px 0 10px',
      paddingBottom: '10px',
      cursor: 'pointer',
      color: '#FFF'
    },
    headerContainer: { display: 'flex', alignItems: 'center', color: '#FFF' },
    container: {
      width: '280px',
      display: 'flex',
      flexDirection: 'column'
    },
    buttonRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px'
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    badge: {
      marginRight: '20px'
    },
    hideOnMobile: {
      [theme.breakpoints.down(theme.breakpoints.values.md + 5)]: {
        display: 'none'
      }
    }
  };
};

export default styles;
