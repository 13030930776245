export default {
  root: {
    width: '320px',
    height: '85px',
    backgroundColor: '#fff',
    margin: '0 5px',
    display: 'flex',
    borderRadius: '5px',
    position: 'relative'
  },
  valueContainer: {
    fontSize: '16px',
    padding: '10px',
    width: '80%'
  },
  value: { fontWeight: 600 },
  additionalDataValue: { fontSize: '14px', fontWeight: 600 },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: '40px',
    '&:hover': { filter: 'brightness(1.5)' },
    cursor: 'pointer'
  },
  disablePointer: { cursor: 'unset', '&:hover': { filter: 'brightness(1)' } },
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    background: '#FFF',
    opacity: '.5',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
