import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';

const fetchLogs = ({ datalimitId }) => {
  return async dispatch => {
    let logs = [];
    try {
      const response = await getData(
        `${URLS.DATALIMITS_MDU}/${datalimitId}/action_logs`
      );

      if (isDefined(response)) {
        logs = response;
      }
    } finally {
      dispatch(reduxActions.setDatalimitLogs(logs));
    }
  };
};

export default fetchLogs;
