import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';

import StatusCell from '../../externalNumberRange/components/StatusCell';
import { formatDate } from '../../externalNumberRange/actions/mapInactiveCellValue';
import Optional from '../../../utils/optional';
import { translate } from '../../../i18n/I18nProvider';
import RangeMoreActionsMenu from './RangeMoreActionsMenu';
import withSettings from '../../../utils/withSettings';
import styles from '../styles';

const ConnectedNumberRangesTable = ({
  numberRange,
  classes,
  settings,
  phonebookData
}) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{translate('TABLE.RANGE_FROM')}</TableCell>
            <TableCell>{translate('TABLE.RANGE_TO')}</TableCell>
            <TableCell>{translate('TABLE.NETWORK')}</TableCell>
            <TableCell>{translate('TABLE.NO_OF_NUMBERS')}</TableCell>
            <TableCell>{translate('STATUS')}</TableCell>
            <TableCell>{translate('TABLE.TYPE')}</TableCell>
            <TableCell>{translate('TABLE.ACTIVE_AT')}</TableCell>
            <TableCell>{translate('TABLE.INACTVE_AT')}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <RangeMoreActionsMenu
                numberRange={numberRange}
                phonebookData={phonebookData}
              >
                <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
                  <i
                    className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
                    style={{
                      color: settings.colors.links
                    }}
                  ></i>
                </Tooltip>
              </RangeMoreActionsMenu>
            </TableCell>
            <TableCell>{numberRange.range_from}</TableCell>
            <TableCell>{numberRange.range_up_to_incl}</TableCell>
            <TableCell>
              {Optional(numberRange.provider)
                .map(provider => Optional(provider.sane_name).or(provider.name))
                .or('')}
            </TableCell>
            <TableCell>{numberRange.interval}</TableCell>
            <TableCell>
              <StatusCell status={numberRange.status} />
            </TableCell>
            <TableCell>{numberRange.number_type}</TableCell>
            <TableCell>{formatDate(numberRange.och_active_at)}</TableCell>
            <TableCell>{formatDate(numberRange.och_inactive_at)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default flowRight(
  withSettings,
  withStyles(styles)
)(ConnectedNumberRangesTable);
