import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import styles from '../styles/domainNumberRangeCell';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import Optional from '../../../utils/optional';
import { doesArrayHasLength } from '../../../utils/isDefined';

function OperatorCell({ data = {}, classes, isLoading }) {
  return (
    <div className={classes.root}>
      <div className={classes.root}>
        {Optional(data.service_operator)
          .map(operators => operators.join(', '))
          .or('')}
        {isLoading && !doesArrayHasLength(data.service_operator) && (
          <CircularProgress size={18} />
        )}
      </div>
    </div>
  );
}

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(OperatorCell);
