import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';

import styles from './styles';
import Optional from '../../utils/optional';
import HashedData from '../HashedData';
import { isUndefined, isDefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';
import CopyToClipbaordTooltip from '../copyToClipbaordTooltip/CopyToClipbaordTooltip';

function DataRow({
  label,
  labelText,
  value,
  classes,
  intl,
  hashed,
  dense,
  valueWidth,
  isValueBoolean,
  settings,
  mobile = false,
  customStyles = {},
  tooltipIntlId = null,
  valueOverflow = false,
  mobileFlexColumn = false,
  customClasses = {},
  isPhoneLink = false,
  mobileRightAllignValue = false,
  showCopyValueTooltip = false
}) {
  const customValueStyle = {
    width: Optional(valueWidth).or(''),
    ...customStyles.value
  };

  const dataToShow = mapValuesToShow({ isValueBoolean, value, intl });

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.dense]: dense,
          [classes.mobile]: mobile,
          [classes.mobileFlexColumn]: mobileFlexColumn
        },
        customClasses.root
      )}
      style={customStyles.root}
    >
      <div
        className={clsx(classes.label, customClasses.label)}
        style={customStyles.label}
      >
        <div>{Optional(label).or(Optional(labelText).or(''))}</div>
        {tooltipIntlId && (
          <Tooltip
            placement="bottom"
            title={
              isDefined(tooltipIntlId)
                ? intl.formatMessage({
                    id: tooltipIntlId
                  })
                : ''
            }
          >
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      {!hashed ? (
        <div
          className={clsx(
            classes.value,
            {
              [classes.valueOverflow]: valueOverflow,
              [classes.mobileRightAllignValue]: mobileRightAllignValue
            },
            customClasses.value
          )}
          style={customValueStyle}
        >
          {isPhoneLink ? (
            showCopyValueTooltip ? (
              <CopyToClipbaordTooltip value={Optional(dataToShow).or('')}>
                <a
                  className={classes.link}
                  style={{ color: settings.colors.links }}
                  href="tel:dataToShow"
                >
                  {dataToShow}
                </a>
              </CopyToClipbaordTooltip>
            ) : (
              <a
                className={classes.link}
                style={{ color: settings.colors.links }}
                href="tel:dataToShow"
              >
                {dataToShow}
              </a>
            )
          ) : showCopyValueTooltip ? (
            <CopyToClipbaordTooltip value={Optional(dataToShow).or('')}>
              {dataToShow}
            </CopyToClipbaordTooltip>
          ) : (
            dataToShow
          )}
        </div>
      ) : (
        <HashedData
          dataClassName={clsx(
            classes.value,
            classes.hashed,
            {
              [classes.valueOverflow]: valueOverflow
            },
            customClasses.value
          )}
          style={customValueStyle}
          data={dataToShow}
        />
      )}
    </div>
  );
}

export default flowRight(injectIntl, withSettings, withStyles(styles))(DataRow);

function mapValuesToShow({ isValueBoolean, value, intl }) {
  if (isUndefined(isValueBoolean)) {
    return Optional(value).or('');
  }

  return value
    ? intl.formatMessage({
        id: 'YES'
      })
    : intl.formatMessage({
        id: 'NO'
      });
}
