import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { ORDERS_STATUS_OPTIONS } from '../../../consts/initialValues';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { actions as reduxActions } from '../reducers';
import styles, { customSelectStyles } from '../styles/selects';
import { translate } from '../../../i18n/I18nProvider';

const FilterByStatus = ({ changeFilteredBy, filteredBy, classes }) => {
  const handleChange = selected => {
    changeFilteredBy(selected.value);
  };

  const value = useMemo(
    () => ORDERS_STATUS_OPTIONS.find(option => option.value === filteredBy),
    [filteredBy]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {translate('SUBSCRIPTIONS.FILTER_BY_STATUS')}
      </div>
      <Select
        customStyles={customSelectStyles}
        options={ORDERS_STATUS_OPTIONS}
        styles={reactSelectDefaultStyles}
        value={value}
        onChange={handleChange}
        isRtl
        isSearchable={false}
      />
    </div>
  );
};

const mapStatesToProps = ({ orders }) => {
  return {
    filteredBy: orders.filteredBy
  };
};

const mapDispatchToProps = {
  changeFilteredBy: reduxActions.changeFilteredBy
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(FilterByStatus);
