import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import Select from 'react-select';

import styles, { customSelectStyles } from '../styles/formSection';
import CustomForm from '../../../partials/customForm/CustomForm';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { doesArrayHasLength, isStringDefined } from '../../../utils/isDefined';

export function FormSection({ classes, onCloseDialog, intl, clientId }) {
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    (async function() {
      try {
        const templates = await getData(URLS.CONFIG_TEMPLATES);
        if (doesArrayHasLength(templates)) {
          const mappedTemplates = templates.reduce((arr, template) => {
            const { name } = template;

            if (isStringDefined(name)) {
              return [
                ...arr,
                {
                  ...template,
                  value: name,
                  label: capitaliseFirstLetter(name).replaceAll('_', ' ')
                }
              ];
            }

            return arr;
          }, []);

          if (doesArrayHasLength(mappedTemplates)) {
            setTemplates(mappedTemplates);
            setTemplate(mappedTemplates[0]);
          } else {
            setInfo(
              intl.formatMessage({
                id: 'CLIENT.NO_TEMPLATE'
              })
            );
          }
        } else {
          setInfo(
            intl.formatMessage({
              id: 'CLIENT.NO_TEMPLATE'
            })
          );
        }
      } catch {}
    })();
  }, [intl]);

  const handleChange = selected => {
    setTemplate(selected);
  };

  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>
        {info && <div className={classes.info}>{info}</div>}
        {template && (
          <div className={classes.selectContainer}>
            <div>
              {intl.formatMessage({
                id: 'CLIENT.SELECT_TEMPLATE'
              })}
            </div>
            <Select
              customStyles={customSelectStyles}
              options={templates}
              styles={reactSelectDefaultStyles}
              value={template}
              onChange={handleChange}
            />
          </div>
        )}
        {template && (
          <CustomForm
            onCloseDialog={onCloseDialog}
            templateName={template.value}
            clientId={clientId}
          />
        )}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(FormSection);
