import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { connect } from 'react-redux';

import { translate } from '../../../i18n/I18nProvider';
import CustomSwitch from '../../../partials/CustomSwitch';
import { actions as reduxActions } from '../reducers';
import styles from '../styles';

function ExpandAllSwitch({ toggleSwitch, expandAll, classes }) {
  const handleSwitch = e => {
    toggleSwitch('expandAll', e.target.checked);
  };

  return (
    <div>
      <div className={classes.expandSwitchLabel}>{translate('EXPAND_ALL')}</div>
      <CustomSwitch checked={expandAll} handleChange={handleSwitch} />
    </div>
  );
}

const mapStatesToProps = ({ subscriptions }) => {
  return {
    expandAll: subscriptions.expandAll
  };
};

const mapDispatchToProps = {
  toggleSwitch: reduxActions.updateValue
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ExpandAllSwitch);
