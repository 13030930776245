import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

export function RolesTable({ roles }) {
  return (
    <div>
      <Table>
        <TableBody>
          {roles.map((role, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{role.name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default RolesTable;
