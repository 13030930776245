import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';

export default async function fetchOperator(number) {
  try {
    const response = await getData({
      options: {
        url: `${URLS.LOOKUP}/${number}`
      },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    });

    return response;
  } catch (e) {
    return { error: e.response };
  }
}
