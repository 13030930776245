import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/summary';
import { actions as reduxActions } from '../reducers';
import { translate } from '../../../i18n/I18nProvider';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import withSettings from '../../../utils/withSettings';

const Summary = ({
  summaryOpen,
  summaryInfo = {},
  closeDialog,
  classes,
  settings
}) => {
  const handleClose = () => {
    closeDialog();
  };
  const numbersArray = useMemo(() => Object.keys(summaryInfo), [summaryInfo]);

  return (
    <Dialog open={summaryOpen} onClose={handleClose}>
      <CustomDialogTitle onCloseClick={handleClose}>
        {translate('EDIT_SCHEDULE.ADDING_SCHEDULES')}
      </CustomDialogTitle>
      <DialogContent className={classes.content}>
        {numbersArray.map(number => {
          return (
            <div className={classes.numberContainer} key={number}>
              <div className={classes.label}>{number}</div>
              <div>
                {summaryInfo[number].map((info, i) => (
                  <div
                    key={i}
                    style={{
                      color: info.isError
                        ? settings.colors.danger
                        : settings.colors.success
                    }}
                  >
                    {info.text}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStatesToProps = ({ addingSchedules }) => {
  const { summaryOpen, summaryInfo } = addingSchedules;
  return { summaryOpen, summaryInfo };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeSummaryDialog
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Summary);
