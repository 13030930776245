import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { openEditSchedule } from '../../../modals/editSchedule/actions/setSchedule';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import DeleteScheduleConfirmation from './DeleteScheduleConfirmation';
import { removeSchedule } from '../actions/deleteSchedule';
import createSubject from '../../../utils/createSubject';
import can from '../../../utils/can';

export function TableActionButtons({
  data: schedule,
  classes,
  openEditSchedule,
  group,
  connectClientId,
  removeSchedule,
  clientId
}) {
  const onDeleteClick = useCallback(
    () => {
      removeSchedule({
        number: group.number,
        name: schedule.name,
        connectClientId
      });
    },
    // eslint-disable-next-line
    [group, schedule.name, connectClientId]
  );

  return (
    <div className={classes.actionButtons}>
      {can(
        'update',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) && (
        <CustomIconButton
          iconTooltipIntlKey="EDIT_SCHEDULE.TITLE"
          onClick={() => openEditSchedule({ schedule, group })}
          iconClassName="far fa-edit"
        />
      )}
      {can(
        'delete',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) &&
        schedule.name !== 'default' && (
          <ConfirmDialog
            onConfirmClick={onDeleteClick}
            dialogContentComponent={
              <DeleteScheduleConfirmation name={schedule.name} />
            }
            dialogTitleIntlId={'TOOLTIP.DELETE'}
          >
            <CustomIconButton
              iconTooltipIntlKey="TOOLTIP.DELETE"
              iconClassName="far fa-trash-alt"
            />
          </ConfirmDialog>
        )}
    </div>
  );
}
const mapStatesToProps = ({ selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  openEditSchedule,
  removeSchedule
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
