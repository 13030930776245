import { createReduxActionTypes } from '../../../utils/actionFactory';
import { isArrayEmpty, isUndefined } from '../../../utils/isDefined';
import orderBy from 'lodash.orderby';

const OPEN_EDIT_QUEUE = 'OPEN_EDIT_QUEUE';
const RESET_EDIT_QUEUE = 'RESET_EDIT_QUEUE';
const CHANGE_TYPE = 'CHANGE_TYPE';
const UPDATE_AGENT_LEVEL = 'UPDATE_AGENT_LEVEL';
const UPDATE_AGENTS = 'UPDATE_AGENTS';
const RESET_AGENTS_TO_DEFAULT_LEVEL = 'RESET_AGENTS_TO_DEFAULT_LEVEL';

export const actionTypes = createReduxActionTypes('EDIT_QUEUE', [
  OPEN_EDIT_QUEUE,
  RESET_EDIT_QUEUE,
  CHANGE_TYPE,
  UPDATE_AGENT_LEVEL,
  UPDATE_AGENTS,
  RESET_AGENTS_TO_DEFAULT_LEVEL
]);

const DEFAULT_STATE = {
  open: false,
  queue: {},
  distributionType: 'SKILLS',
  agents: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_EDIT_QUEUE]: {
      const { queue } = action;
      return {
        ...state,
        open: true,
        queue,
        agents: mapAgents(queue),
        distributionType: queue.distributionType
      };
    }
    case actionTypes[CHANGE_TYPE]: {
      const { distributionType } = action;
      return {
        ...state,
        distributionType
      };
    }
    case actionTypes[UPDATE_AGENTS]: {
      const { agents } = action;
      return {
        ...state,
        agents
      };
    }
    case actionTypes[RESET_AGENTS_TO_DEFAULT_LEVEL]: {
      return {
        ...state,
        agents: state.agents.map(el => {
          return { ...el, skillLevel: 3 };
        })
      };
    }
    case actionTypes[UPDATE_AGENT_LEVEL]: {
      const { agent, level } = action;
      return {
        ...state,
        agents: state.agents.map(el => {
          if (el.id === agent) {
            return { ...el, skillLevel: level };
          }

          return el;
        })
      };
    }
    case actionTypes[RESET_EDIT_QUEUE]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditQueue: queue => ({
    type: actionTypes[OPEN_EDIT_QUEUE],
    queue
  }),
  changeType: distributionType => ({
    type: actionTypes[CHANGE_TYPE],
    distributionType
  }),
  updateAgents: agents => ({
    type: actionTypes[UPDATE_AGENTS],
    agents
  }),
  resetAgentsToDefaultLevel: () => ({
    type: actionTypes[RESET_AGENTS_TO_DEFAULT_LEVEL]
  }),
  updateAgentLevel: ({ agent, level }) => ({
    type: actionTypes[UPDATE_AGENT_LEVEL],
    agent,
    level
  }),
  closeEditQueue: () => ({ type: actionTypes[RESET_EDIT_QUEUE] })
};

const mapAgents = queue => {
  if (isUndefined(queue?.agents) || isArrayEmpty(queue?.agents)) {
    return [];
  }

  if (queue.distributionType === 'PRIORITY') {
    return orderBy(queue.agents, 'skillLevel');
  }

  return queue.agents;
};
