import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from './styles';
import DataLimitChart from './components/DataLimitChart';
import DataLimitRow from './components/DataLimitRow';
import DataLimit from './components/DataLimit';
import { getDatalimit } from './actions/getDatalimit';
import { actions as reduxActions } from './reducers';
import { isDefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';
import { CUSTOMER_SERVICE_MAIL } from '../../consts/initialValues';
import Charts from '../datalimitStats/components/Charts';

const DEFAULT_CHART_DATA = {
  dataUsage: [[], [], []],
  chartLabels: []
};

export function DataLimitsStats({
  classes,
  intl,
  datalimit = {},
  data = {},
  getDatalimit,
  resetDatalimitStats,
  isEditView = false,
  settings
}) {
  useEffect(() => {
    if (isDefined(data.id)) {
      getDatalimit(data.id);
    }
    return resetDatalimitStats;
    // eslint-disable-next-line
  }, [data.id]);
  const { chartData = DEFAULT_CHART_DATA } = datalimit;

  if (Object.keys(datalimit).length === 0) {
    return null;
  }
  return datalimit.showCharts ? (
    <div className={classes.root}>
      {isEditView && (
        <div>{`${intl.formatMessage({
          id: 'EDIT.USER'
        })} : ${datalimit.owners.map(owner => owner.name).join(', ')}`}</div>
      )}
      <Charts
        data={datalimit.lastMonthChartData}
        datalimitId={datalimit.id}
        datalimitName={datalimit.name}
        hideShowButton={true}
      />
      <DataLimitChart chartData={chartData} datalimit={datalimit} />
      <DataLimit />
    </div>
  ) : (
    <div className={classes.root}>
      {isEditView && (
        <div>{`${intl.formatMessage({
          id: 'EDIT.USER'
        })} : ${datalimit.owners.map(owner => owner.name).join(', ')}`}</div>
      )}
      <DataLimitRow
        test="test"
        datalimit={{
          limit: datalimit.block_limit_mb
        }}
        showCharts={datalimit.showCharts}
      />
      {datalimit.roaming_disabled === false && (
        <>
          <DataLimitRow
            datalimit={{
              limit: datalimit.fair_usage_limit
            }}
            showCharts={datalimit.showCharts}
            labelId="DATA_LIMITS.FAIR_USAGE_LIMIT"
          />
        </>
      )}
      <div className={classes.info}>
        <span>
          {intl.formatMessage({
            id: 'DATA_LIMITS.NO_USAGE_DATA_INFO_1'
          })}
        </span>{' '}
        <span
          className={classes.appendixLink}
          style={{ color: settings.colors.links }}
          onClick={() => (window.location = CUSTOMER_SERVICE_MAIL)}
        >
          {CUSTOMER_SERVICE_MAIL}
        </span>{' '}
        <span>
          {intl.formatMessage({
            id: 'DATA_LIMITS.NO_USAGE_DATA_INFO_2'
          })}
        </span>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ datalimitStats }) => {
  return {
    ...datalimitStats
  };
};

const mapDispatchToProps = {
  getDatalimit,
  resetDatalimitStats: reduxActions.resetDatalimitStats
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DataLimitsStats);
