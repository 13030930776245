import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { isArray } from '../../../utils/isDefined';

const getAccounts = clientId => {
  return async dispatch => {
    const data = await getData(`${URLS.QUOTE_CLIENTS}/${clientId}/accounts`);

    if (isArray(data)) {
      dispatch(reduxActions.setAccounts(data));
    }
  };
};

export default getAccounts;
