const styles = theme => {
  return {
    root: {
      color: '#000',
      fontSize: '12px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    label: {
      marginLeft: '36px'
    }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'end',
    textAlign: 'end'
  },
  menu: { width: '160px', zIndex: 100, position: 'absolute' },
  control: {
    border: 'unset'
  },
  container: { position: 'unset' },
  indicatorSeparator: { display: 'none' },
  indicatorContainer: { padding: 0 },
  valueContainer: { fontSize: '12px', padding: 'unset' },
  singleValue: {
    display: 'flex',
    overflowWrap: 'break-word',
    inlineSize: '130px',
    textAlign: 'end',
    width: '130px',
    flexDirection: 'row-reverse',
    whiteSpace: 'unset'
  }
};

export const groupbySelectStyles = {
  menu: { width: '120px', zIndex: 100, position: 'absolute' },
  singleValue: {
    display: 'flex',
    overflowWrap: 'break-word',
    inlineSize: '100px',
    textAlign: 'end',
    width: '100px',
    flexDirection: 'row-reverse',
    whiteSpace: 'unset'
  }
};
