import React from 'react';

import TableActionButtons from './TableActionButtons';

const tableColumns = () => {
  const columns = [
    {
      title: 'TABLE.ACTIONS',
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      width: '50px'
    },
    {
      title: 'CDR_HOOKS.TARGET_URL',
      field: 'target_url'
    },
    {
      title: 'TABLE.TYPE',
      field: 'type'
    },
    {
      title: 'ONGOING',
      field: 'ongoing'
    },
    {
      title: 'PUBLIC',
      field: 'public'
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;
