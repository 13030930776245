import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapNumberData({ numberData }) {
  const { carrier_settings, ...restNumberData } = numberData;
  let generalData = { ...restNumberData };
  let serviceInfos = {};
  let carrierError = void 0;

  if (isDefined(carrier_settings)) {
    const {
      price_plan,
      service_infos,
      error,
      ...restCarrierData
    } = carrier_settings;

    generalData = { ...generalData, ...restCarrierData };
    serviceInfos = { price_plan, service_infos };
    carrierError = Optional(error)
      .map(error => JSON.stringify(error))
      .or(void 0);
  }

  return { generalData, ...serviceInfos, carrierError };
}
