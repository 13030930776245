import { getData } from './http';
import urlCreator from './urlCreator';
import { mapFilters, mapSearchValue, mapSorting } from './tableUtils';
import { isDefined, isStringEmpty, isStringDefined } from './isDefined';
import { isEmptyArray } from 'formik';
import Optional from './optional';

const USE_SPIN_LOADER = false;

export default async function fetchTableData({
  query,
  queryParams,
  requestUrl,
  defaultSort = '',
  mapCustomSorting,
  mapCustomFilters
}) {
  const sort = isDefined(query.orderBy)
    ? mapSorting(query, mapCustomSorting)
    : defaultSort;

  const filters = mapFilters(query.filters, mapCustomFilters);

  let QUERY_PARAMS = {
    ...queryParams,
    sort,
    filter: { ...queryParams.filter, ...filters },
    page: { size: query.pageSize, number: query.page + 1 },
    stats: { total: 'count' }
  };

  const search = !isStringEmpty(query.search.trim())
    ? mapSearchValue(query.search.trim())
    : '';

  if (isStringDefined(search)) {
    QUERY_PARAMS = {
      ...QUERY_PARAMS,
      filter: { ...QUERY_PARAMS.filter, search: { match: search } }
    };
  }

  try {
    let result = await getData(
      urlCreator(requestUrl, QUERY_PARAMS),
      USE_SPIN_LOADER
    );

    if (result.meta.stats.total.count === 0) {
      return { data: [], page: 0, totalCount: 0 };
    }
    let page = query.page;

    if (query.page > 0 && isEmptyArray(result.data)) {
      page = checkPageNumber(query, result);
      result = await getData(
        urlCreator(requestUrl, {
          ...QUERY_PARAMS,
          page: { size: QUERY_PARAMS.page.size, number: page + 1 }
        }),
        USE_SPIN_LOADER
      );
    }

    return {
      data: result.data,
      page: page,
      totalCount: result.meta.stats.total.count
    };
  } catch {
    return {
      data: [],
      page: Optional(query.page).or(0),
      totalCount: Optional(query.totalCount).or(0)
    };
  }
}

function checkPageNumber(query, result) {
  const { pageSize } = query;

  return Math.floor(result.meta.stats.total.count / pageSize);
}
