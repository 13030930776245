const styles = theme => {
  return {
    dialogContent: {
      paddingBottom: '10px',
      overflow: 'visible',
      minWidth: '700px'
    },
    agentsRoot: {
      width: '100%',
      fontSize: '13px',
      color: '#212529',
      backgroundColor: '#FDFDFD',
      '& tr:nth-of-type(odd)': {
        backgroundColor: '#EFEFEF'
      },
      '& th,td': {
        padding: '2px 10px',
        height: '30px'
      }
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '20px'
    },

    skillLevelContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  menu: { width: '30px', top: 'unset' },
  container: { position: 'initial' },
  control: {
    border: 'unset',
    backgroundColor: 'inherit'
  },
  valueContainer: { padding: 'unset' },
  indicatorSeparator: { display: 'none' },
  indicatorsContainer: { padding: '0' }
};
