const styles = theme => {
  return {
    content: {
      [theme.breakpoints.down('xs')]: {
        margin: '5px'
      }
    }
  };
};

export default styles;
