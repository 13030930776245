import { isArray, isStringEmpty } from '../../../utils/isDefined';
import { mapSearchValue } from '../../../utils/tableUtils';

export default function mapCustomFilters({ filter, obj, value }) {
  if (
    isArray(value) &&
    value.length >= 2 &&
    filter.column.field !== 'card_type' && 
    filter.column.field !== 'active'
  ) {
    return obj;
  }

  if (filter.column.field === 'active') {
    if (value.length >= 2) {
      return { ...obj, [filter.column.field]: { any: true } };
    }
    return { ...obj, [filter.column.field]: { eq: value } };
  }

  const mappedValue = isArray(value) ? value.join(',') : mapSearchValue(value);

  if (isStringEmpty(mappedValue)) {
    return { ...obj };
  }

  switch (filter.column.field) {
    case 'card_type':
      return { ...obj, [filter.column.field]: { eq: mappedValue } };
    default:
      return { ...obj, [filter.column.field]: { match: mappedValue } };
  }
}
