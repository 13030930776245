import { createSagaActionTypes } from '../../../utils/actionFactory';
export const GET_USER_CONTACTS = 'GET_USER_CONTACTS';
export const MANAGE_STATUS = 'MANAGE_STATUS';
export const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
export const GET_USER_CALL_LOG = 'GET_USER_CALL_LOG';
export const DELETE_ITEM = 'DELETE_ITEM';
export const GET_USER_VOICEMAILS = 'GET_USER_VOICEMAILS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const POST_SMS = 'POST_SMS';
export const GET_GROUPS = 'GET_GROUPS';

export const actions = createSagaActionTypes('USER_CONTACTS', [
  GET_USER_CONTACTS,
  MANAGE_STATUS,
  GET_CONTACT_DATA,
  GET_USER_CALL_LOG,
  DELETE_ITEM,
  GET_USER_VOICEMAILS,
  DELETE_CONTACT,
  POST_SMS,
  GET_GROUPS
]);
