import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from '@material-ui/core';

import styles from '../styles/networkinfo';
import { CONST_VALUES } from '../../../consts/initialValues';

function NetworkInfo({ classes, intl }) {
  return (
    <div className={classes.dialogTextContainer}>
      <div>
        {intl.formatMessage({
          id: 'USER.NETWORK_INFO'
        })}
        <Link
          className={classes.appendixLink}
          onClick={() => window.open(CONST_VALUES.APPENDIX_LINK)}
        >
          {intl.formatMessage({
            id: 'USER.NETWORK_INFO_LINK'
          })}
        </Link>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(NetworkInfo);
