export default {
  root: {
    height: '100%',
    backgroundColor: '#F2F3F8',
    display: 'flex',
    justifyContent: 'center'
  },
  formContainer: {
    width: '480px',
    minHeight: '120px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    alignItems: 'center',
    marginTop: '200px'
  },
  title: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 500,
    padding: '15px 40px',
    textAlign: 'center'
  },
  message: {
    display: 'none',
    minHeight: '20px',
    width: '70%',
    textAlign: 'center'
  },
  messageVisible: {
    display: 'block'
  }
};
