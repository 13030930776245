import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import VacationsTable from './components/VacationsTable';
import { actions as reduxActions } from './reducers';
import EditVacation from '../../modals/editVacation/EditVacation';

const Vacations = ({ resetVacations }) => {
  useEffect(() => {
    return resetVacations;
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <VacationsTable />
      <EditVacation />
    </>
  );
};

const mapDispatchToProps = {
  resetVacations: reduxActions.resetVacations
};

export default connect(null, mapDispatchToProps)(Vacations);
