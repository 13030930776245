import * as yup from 'yup';

export default function(intl) {
  return yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-z\u00c0-\u017e][a-z0-9\u00c0-\u017e]{0,24}$/g,
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_FIELD'
        })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    vip_status: yup.number().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    csbc_routing: yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  });
}
