const styles = () => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    form: {
      marginLeft: '20px',
      width: '45vw',
      height: '80vh',
      backgroundColor: '#FFF',
      padding: '10px',
      overflow: 'auto'
    },
    radioRow: {
      display: 'flex',
      width: '45vw',
      minWidth: '550px',
      alignItems: 'center',
      paddingLeft: '10px',
      backgroundColor: '#FFF'
    },
    radioButtons: {
      paddingLeft: '10px'
    },
    nameContainer: {
      width: '45vw',
      backgroundColor: '#FFF',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600
    },
    nameLabel: { marginRight: '10px', width: '150px' },
    textfield: { width: '300px' },
    textfieldDescription: {
      width: '350px'
    },
    boxRow: { display: 'flex', justifyContent: 'space-between' },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '250px'
  },
  menu: {
    width: '250px'
  }
};
