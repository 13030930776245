const styles = theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 40)]: {
        flexDirection: 'column',
        justifyContent: 'unset'
      }
    },

    container: {
      display: 'inherit',
      padding: '0',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '20px',
      backgroundColor: '#F2F3F8'
    },
    buttonsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      },
      [theme.breakpoints.down(theme.breakpoints.values.xl - 90)]: {
        width: '1040px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg + 210)]: {
        width: '700px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg - 10)]: {
        width: '600px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.md - 40)]: {
        width: '300px'
      }
    },
    accordion: {
      [theme.breakpoints.up(theme.breakpoints.values.xl - 90)]: {
        width: '1050px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg + 210)]: {
        width: '710px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg - 10)]: {
        width: '610px'
      },
      [theme.breakpoints.down(theme.breakpoints.values.md - 40)]: {
        width: '100%'
      }
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      height: '120px'
    },
    accordionDetailsRoot: { backgroundColor: '#F2F3F8', padding: '8px' },
    accordionIcon: {
      width: '120px',
      fontSize: '48px',
      paddingLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.down(theme.breakpoints.values.md - 40)]: {
        width: '60px',
        fontSize: '36px',
        paddingLeft: '10px',
        marginRight: '10px'
      }
    },
    showMoreIcon: {
      fontSize: '30px',
      [theme.breakpoints.down(theme.breakpoints.values.md - 40)]: {
        fontSize: '24px'
      }
    }
  };
};

export default styles;
