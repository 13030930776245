const styles = theme => {
  return {
    actionButtons: {
      display: 'flex'
    },
    actionCell: { padding: '0 5px', color: '#93A2DD' },
    lockCell: { display: 'flex', alignItems: 'center' }
  };
};

export default styles;
