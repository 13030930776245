const styles = theme => {
  return {
    actionButtons: {
      display: 'flex',
      justifyContent: 'center'
    },
    radioButtons: {
      display: 'flex',
      fontSize: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    headerRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    icon: {
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    },
    title: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    label: {
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    radio: {
      color: 'inherit'
    },
    toolbarTitle: {
      overflow: 'visible',
      width: '70%',
      [theme.breakpoints.down('xs')]: {
        width: 'fit-content'
      }
    },
    toolbarSpacer: { flex: 'unset' },
    selectContainer: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    },
    toolbarHighlight: {
      backgroundColor: '#E5EAEE',
      color: '#03284F'
    },
    actionIcon: {
      margin: '5px 10px',
      cursor: 'pointer',
      fontSize: '18px',
      '&:hover': { filter: 'brightness(1.5)' }
    }
  };
};

export default styles;

export const customStyles = {
  option: {
    fontSize: '12px',
    padding: '5px 10px'
  },
  control: {
    borderStyle: 'hidden',
    width: '120px',
    boxShadow: 'none'
  },
  menu: { width: '120px', zIndex: 100 },
  valueContainer: { fontSize: '12px' },
  indicatorSeparator: { display: 'none' }
};
