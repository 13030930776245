import React, { useCallback } from "react";
import flowRight from "lodash.flowright";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";

import styles from "../styles";
import { actions as showHistoryReduxActions } from "../../../modals/showHistory/reducers";
import CustomIconButton from "../../../partials/tableCustomButtons/CustomIconButton";
import can from "../../../utils/can";
import createSubject from "../../../utils/createSubject";
import { setRange } from "../../../modals/editRange/actions/setRange";
import { removeRange } from "../actions/deleteRange";
import MoreActionsMenu from "./MoreActionsMenu";
import { translate } from "../../../i18n/I18nProvider";
import withSettings from "../../../utils/withSettings";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import withUserCheck from "../../../utils/withUserCheck";
import CheckNumberDialog from "../../../modals/checkNumberDialog/CheckNumberDialog";

export function ActionButtons({
  numberRange,
  classes,
  openShowHistory,
  setRange,
  resetValues,
  removeRange,
  isBeTable,
  settings,
  isNonClientAdminUser,
}) {
  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: numberRange.id,
        itemType: "ExternalNumberRange",
        historyObject: numberRange,
        itemTypeKey: "MENU.NUMBER_RANGE",
        titleText: `${numberRange.range_from} - ${numberRange.range_up_to_incl}`,
      });
    },
    // eslint-disable-next-line
    [numberRange.id]
  );

  const onEditClick = useCallback(
    () => setRange({ range: numberRange, resetValues, isBeTable }),
    // eslint-disable-next-line
    [numberRange, isBeTable]
  );

  const onDeleteClick = useCallback(
    () => removeRange({ id: numberRange.id, resetValues }),
    // eslint-disable-next-line
    [numberRange]
  );

  return (
    <div className={classes.actionButtons}>
      {can(
        "update",
        createSubject("ExternalNumberRange", {
          client_id: Number(numberRange.client?.id),
        })
      ) &&
        isNonClientAdminUser && (
          <CustomIconButton
            iconTooltipIntlKey="TOOLTIP.EDIT_RANGE"
            onClick={onEditClick}
            iconClassName="far fa-edit"
          />
        )}
      {isNonClientAdminUser && (
        <CheckNumberDialog
          numbers={
            numberRange?.interval > 1
              ? createNumbersArray(
                  numberRange?.range_from,
                  numberRange?.range_up_to_incl
                )
              : [numberRange?.range_from.toString()]
          }
        >
          <CustomIconButton
            iconTooltipIntlKey="MENU.NUMBER_LOOKUP"
            iconClassName="far fa-info-square"
          />
        </CheckNumberDialog>
      )}
      <MoreActionsMenu
        numberRange={numberRange}
        onDeleteClick={onDeleteClick}
        onHistoryClick={onHistoryClick}
      >
        <Tooltip title={translate("TOOLTIP.MORE_ACTION")}>
          <i
            className={clsx("fas fa-ellipsis-h", classes.actionIcon)}
            style={{
              color: settings.colors.links,
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
}

const mapDispatchToProps = {
  openShowHistory: showHistoryReduxActions.openShowHistory,
  setRange,
  removeRange,
};

export default flowRight(
  withSettings,
  withUserCheck,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ActionButtons);

const createNumbersArray = (from, to) => {
  let numbersArray = [];

  for (let i = from; i <= to; i++) {
    numbersArray = [...numbersArray, i.toString()];
  }

  return numbersArray;
};
