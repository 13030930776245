const styles = theme => {
  return {
    infoBox: {
      width: '100%',
      padding: '20px 0'
    },
    infoLabel: {
      fontWeight: '600'
    },
    infoContainer: {
      padding: '5px',
      marginTop: '10px',
      border: '1px solid #16374F'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      '& button': {
        marginRight: '10px'
      }
    }
  };
};

export default styles;

export const customStyles = {
  inputRow: {
    root: { marginRight: 0 },
    field: { flexDirection: 'column', width: '100%' },
    label: { fontWeight: '600' },
    textfield: { width: '100%' }
  },
  checkbox: {
    root: {
      width: '100%',
      alignItems: 'flex-start'
    },
    label: { fontWeight: '600' }
  }
};
