import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import StatusCell from '../../externalNumberRange/components/StatusCell';
import { formatDate } from '../../externalNumberRange/actions/mapInactiveCellValue';
import Optional from '../../../utils/optional';

export function ConnectedNumberRangesTable({ numberRanges, intl }) {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({ id: 'TABLE.RANGE_FROM' })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'TABLE.RANGE_TO' })}
            </TableCell>
            <TableCell>{intl.formatMessage({ id: 'TABLE.NETWORK' })}</TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'TABLE.NO_OF_NUMBERS' })}
            </TableCell>
            <TableCell>{intl.formatMessage({ id: 'STATUS' })}</TableCell>
            <TableCell>{intl.formatMessage({ id: 'TABLE.TYPE' })}</TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'TABLE.ACTIVE_AT' })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'TABLE.INACTVE_AT' })}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {numberRanges.map((range, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{range.range_from}</TableCell>
                <TableCell>{range.range_up_to_incl}</TableCell>
                <TableCell>
                  {Optional(range.provider)
                    .map(provider =>
                      Optional(provider.sane_name).or(provider.name)
                    )
                    .or('')}
                </TableCell>
                <TableCell>{range.interval}</TableCell>
                <TableCell>
                  <StatusCell status={range.status} />
                </TableCell>
                <TableCell>{range.number_type}</TableCell>
                <TableCell>{formatDate(range.och_active_at)}</TableCell>
                <TableCell>{formatDate(range.och_inactive_at)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default flowRight(injectIntl)(ConnectedNumberRangesTable);
