import { isArray, isString, isUndefined } from './isDefined';
import searchInArray from './searchInArray';

export default function filterCustomJobs(jobs, category) {
  if (isUndefined(jobs)) {
    return [];
  }

  return jobs.filter(job => filterFunction(job.category, category));
}

function filterFunction(jobCategory, category) {
  if (isString(jobCategory)) {
    return jobCategory === category;
  }
  if (isArray(jobCategory)) {
    return searchInArray({ array: jobCategory, term: category });
  }
  return false;
}
