import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_NUMBER_DATA = 'SET_NUMBER_DATA';
const RESET_CHECK_NUMBER = 'RESET_ROLES';
const SET_IS_DIALOG = 'SET_IS_DIALOG';
const SET_HISTORY = 'SET_HISTORY';
const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_IS_LOADING = 'SET_IS_LOADING';

export const actionTypes = createReduxActionTypes('CHECK_NUMBER', [
  SET_NUMBER_DATA,
  RESET_CHECK_NUMBER,
  SET_IS_DIALOG,
  SET_SEARCH_TYPE,
  SET_HISTORY,
  SET_SUBSCRIPTION,
  SET_IS_LOADING
]);

const DEFAULT_STATE = {
  history: [],
  searchType: 'Number',
  isLoadingSubscription: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_NUMBER_DATA]: {
      const { numberData } = action;

      return { ...state, ...numberData };
    }
    case actionTypes[SET_IS_DIALOG]: {
      const { isDialog } = action;

      return { ...state, isDialog };
    }
    case actionTypes[SET_HISTORY]: {
      const { history } = action;

      return { ...state, history };
    }
    case actionTypes[SET_SUBSCRIPTION]: {
      const { subscription } = action;

      return { ...state, subscription };
    }
    case actionTypes[SET_IS_LOADING]: {
      const { isLoadingSubscription } = action;

      return { ...state, isLoadingSubscription };
    }
    case actionTypes[SET_SEARCH_TYPE]: {
      const { searchType } = action;

      return { ...state, searchType };
    }
    case actionTypes[RESET_CHECK_NUMBER]: {
      return {
        ...DEFAULT_STATE,
        history: state.history,
        searchType: state.searchType
      };
    }
    default:
      return state;
  }
};

export const actions = {
  setNumberData: numberData => ({
    type: actionTypes[SET_NUMBER_DATA],
    numberData
  }),
  setIsDialog: isDialog => ({
    type: actionTypes[SET_IS_DIALOG],
    isDialog
  }),
  setHistory: history => ({
    type: actionTypes[SET_HISTORY],
    history
  }),
  setSubscription: subscription => ({
    type: actionTypes[SET_SUBSCRIPTION],
    subscription
  }),
  setSearchType: searchType => ({
    type: actionTypes[SET_SEARCH_TYPE],
    searchType
  }),
  setIsLoading: isLoadingSubscription => ({
    type: actionTypes[SET_IS_LOADING],
    isLoadingSubscription
  }),
  resetCheckNumber: () => ({ type: actionTypes[RESET_CHECK_NUMBER] })
};
