import { all } from 'redux-saga/effects';

import watchSimcards from '../pages/simCards/actions';
import watchEditSimcard from '../modals/editSimcard/actions';
import watchEditClient from '../modals/editClient/actions';
import watchAuth from '../pages/auth/actions';
import watchUsers from '../pages/users/actions';
import watchTelephonyUsers from '../pages/telephonyUsers/actions';
import watchUpdatePass from '../modals/changePass/actions';
import watchResetPass from '../modals/forgottenPass/actions';
import watchUser from '../pages/user/actions';
import watchEditUser from '../modals/editUser/actions';
import watchPartners from '../pages/partners/actions';
import watchSelectClient from '../partials/selectClient/actions';
import watchExternalNoRanges from '../pages/externalNumberRange/actions';
import watchEditRange from '../modals/editRange/actions';
import watchMultieditNumberRanges from '../modals/numberRangeMultiedit/actions';
import watchAddresses from '../pages/addresses/actions';
import watchRoles from '../pages/roles/actions';
import watchEditRoles from '../modals/editRole/actions';
import watchPermission from '../pages/permissions/actions';
import watchAddPermission from '../modals/addPermission/actions';
import watchQueues from '../pages/queues/actions';
import watchSchedules from '../pages/schedules/actions';
import watchEditSchedule from '../modals/editSchedule/actions';
import watchDataLimits from '../pages/dataLimits/actions';
import watchDataLimitsUsage from '../pages/datalimitsUsage/actions';
import watchEditDatalimit from '../modals/editDatalimit/actions';
import watchRequestUser from '../modals/requestNewUser/actions';
import watchRequestUserWizard from '../modals/requestUserWizard/actions';
import watchOrderSimcardWizard from '../modals/orderNewSimcard/actions';
import watchDatalimitStats from '../partials/dataLImitsStats/actions';
import watchUserAvatar from '../partials/userAvatar/actions';
import watchAppSettings from '../pages/appSettings/actions';
import watchShowHistory from '../modals/showHistory/actions';
import watchMultieditDatalimits from '../modals/datalimitMultiedit/actions';
import watchCheckNumber from '../pages/checkNumber/actions';
import watchTransferNumbers from '../modals/transferNumbers/actions';
import watchUserContacts from '../pages/userContacts/actions';
import watchAddExteralContact from '../modals/addExternalContact/actions';
import watchInvoices from '../pages/invoices/actions';
import watchInvoiceDetails from '../modals/invoiceDetails/actions';
import watchGroups from '../pages/groups/actions';
import watchSubscriptions from '../pages/subscriptions/actions';
import watchLicenses from '../pages/licenses/actions';
import watchConnections from '../pages/connections/actions';

export default function* rootSaga() {
  yield all([
    ...watchAuth,
    ...watchAddresses,
    ...watchEditClient,
    ...watchPartners,
    ...watchSimcards,
    ...watchEditSimcard,
    ...watchUsers,
    ...watchTelephonyUsers,
    ...watchUser,
    ...watchUpdatePass,
    ...watchResetPass,
    ...watchEditUser,
    watchSelectClient,
    ...watchExternalNoRanges,
    ...watchEditRange,
    ...watchMultieditNumberRanges,
    ...watchRoles,
    ...watchEditRoles,
    ...watchPermission,
    ...watchAddPermission,
    ...watchQueues,
    ...watchSchedules,
    ...watchEditSchedule,
    ...watchDataLimits,
    ...watchDataLimitsUsage,
    ...watchEditDatalimit,
    ...watchRequestUser,
    ...watchRequestUserWizard,
    ...watchOrderSimcardWizard,
    ...watchDatalimitStats,
    ...watchUserAvatar,
    ...watchAppSettings,
    ...watchShowHistory,
    ...watchMultieditDatalimits,
    ...watchCheckNumber,
    ...watchTransferNumbers,
    ...watchUserContacts,
    ...watchAddExteralContact,
    ...watchInvoices,
    ...watchInvoiceDetails,
    ...watchGroups,
    ...watchSubscriptions,
    ...watchLicenses,
    ...watchConnections
  ]);
}
