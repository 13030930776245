export default {
  content: {
    background: '#F2F3F8'
  },
  selectRowContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '350px',
    justifyContent: 'space-between',
    marginLeft: '10px'
  },
  selectLabel: { fontWeight: 600 },
  title: { display: 'flex', alignItems: 'center' }
};

export const customStyles = {
  option: {
    fontSize: '10px',
    color: '#000'
  },
  input: { color: '#fff' },
  control: {
    borderStyle: 'hidden',
    width: '160px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '14px'
  },
  indicatorSeparator: { display: 'none' }
};
