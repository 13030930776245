const styles = theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  contacts: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  showContacts: {
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  twoTabsView: { backgroundColor: '#FFF', margin: '5px' },
  fourTabsView: { backgroundColor: '#FFF' },
  maxView: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  standardView: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  tabRoot: { minWidth: '90px' }
});

export default styles;
