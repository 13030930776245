import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, SET_INITIAL_VALUE } from './types';
import { actions as reduxActions } from '../reducers';
import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { doesArrayHasLength } from '../../../utils/isDefined';
import { ROLES_IDS } from '../../../consts/rolesIds';

export function getClientsList({ role, adminDomains }) {
  return {
    type: sagaActions[SET_INITIAL_VALUE],
    role,
    adminDomains
  };
}

export function* getInitialValue({ role, adminDomains }) {
  let allClients = [];
  const shouldFetch =
    role.id >= ROLES_IDS.PARTNER_ADMIN || doesArrayHasLength(adminDomains);

  try {
    if (shouldFetch) {
      let { data } = yield call(getData, {
        options: {
          url: urlCreator(URLS.CLIENT_LIST, {})
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.FORBIDDEN]
      });
      const { data: clients } = data;
      allClients = doesArrayHasLength(clients) ? clients : [];
    }
  } catch (e) {
  } finally {
    yield put(reduxActions.setInitialState(allClients));
  }
}

function* watchgetInitialValue() {
  yield takeLatest(sagaActions[SET_INITIAL_VALUE], getInitialValue);
}

export default watchgetInitialValue();
