import { isArray, isDefined, isUndefined } from './isDefined';

export default function searchCaseInsensitive(value, searchTerm) {
  if (isArray(searchTerm)) {
    return searchInArray({ value, searchTerm });
  }

  if (isUndefined(value)) {
    return false;
  }

  return checkValue(value, searchTerm);
}

function searchInArray({ value, searchTerm }) {
  if (searchTerm.length === 0) {
    return true;
  }

  if (
    isUndefined(value) &&
    isDefined(searchTerm.find(term => term === 'null'))
  ) {
    return true;
  }

  if (isUndefined(value)) {
    return false;
  }

  return searchTerm.filter(term => checkValue(value, term)).length > 0
    ? true
    : false;
}

function checkValue(value, searchTerm) {
  return (
    value
      .toString()
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase().trim()) !== -1
  );
}
