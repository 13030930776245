import React from 'react';
import { TextField } from '@material-ui/core';

import Optional from '../../../utils/optional';
import styles from '../styles';

export default function CustomTextField({
  customClasses,
  disabled = false,
  field,
  form,
  readOnly = false
}) {
  const { values } = form;
  const { onChange, onBlur, name } = field;

  return (
    <TextField
      className={customClasses}
      margin="dense"
      variant="outlined"
      onBlur={onBlur}
      onChange={onChange}
      value={Optional(values[name]).or('')}
      name={name}
      disabled={disabled}
      InputProps={{
        style: styles.inputField,
        readOnly
      }}
      inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
    />
  );
}
