export default function formatUnits(unit, value) {
  switch (unit) {
    case 'SEC':
      return secondsToTime(value);
    case 'BYTES':
      return formatBytes(value);
    case 'DAY':
    case 'MONTH':
    case 'YEAR':
    case 'PCS':
    case 'REFILL':
      return value;
    default:
      return `${value} ${unit}`;
  }
}

function formatBytes(bytes, decimals) {
  if (!bytes) {
    return '0 Bytes';
  }
  const k = 1000;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function secondsToTime(secs) {
  const t = new Date(1970, 0, 1);
  t.setSeconds(secs);
  let s = t.toTimeString().substr(0, 8);
  if (secs > 86399) {
    s = Math.floor((t - Date.parse('1/1/70')) / 3600000) + s.substr(2);
  }
  return s;
}
