import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, UPDATE_SETTINGS } from './types';
import { actions as reduxActions } from '../reducers';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData } from '../../../utils/http';
import mapSettingsToUpdate from './mapSettingsToUpdate';
import showSnackbar from '../../../utils/showSnackbar';
import mapSettingsToStore from './mapSettingsToStore';
import Optional from '../../../utils/optional';

export function updateOnSubmit(settings, partnerId) {
  return {
    type: sagaActions[UPDATE_SETTINGS],
    settings,
    partnerId
  };
}

export function* updateSettings({ settings, partnerId }) {
  const mappedsSettings = mapSettingsToUpdate(settings, partnerId);
  yield put(loaderReducActions.startLinearLoader());
  const response = yield call(updateData, {
    url: `${URLS.PARTNERS}/${partnerId}`,
    data: mappedsSettings
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.UPDATE.SETTINGS',
      messageType: 'success'
    });
    const {
      data: { data }
    } = response;

    yield put(
      reduxActions.setSettings(
        Optional(data.attributes)
          .map(attributes => mapSettingsToStore(attributes.settings))
          .or({}),
        partnerId
      )
    );
    yield put(loaderReducActions.stopLinearLoader());
  }
}

function* watchUpdateAppSettings() {
  yield takeLatest(sagaActions[UPDATE_SETTINGS], updateSettings);
}

export default watchUpdateAppSettings();
