import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import validateSchema from '../actions/validateSchema';
import styles from '../styles/numberLookup';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import { actions as reduxActions } from '../reducers';
import { fetchNumberData } from '../actions/submitNumber';
import { actions as rightPanelReduxActions } from '../../../partials/rightPanel/reducers';
import withUserCheck from '../../../utils/withUserCheck';
import {
  isDefined,
  isStringDefined,
  isStringEmpty
} from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import LastNumbers from './LastNumbers';
import {
  getCurrentValues,
  setSearchHistory
} from '../../../utils/localstorageUtils';

export function NumberLookup({
  classes,
  intl,
  resetNumberData,
  fetchNumberData,
  requestErrors,
  responseError,
  panelRequestErrors,
  panelResponseError,
  fromRightPanel,
  resetPanelData,
  isNonClientAdminUser,
  settings,
  setSearchType,
  searchType,
  setHistory,
  setHistoryPanel,
  setSearchTypePanel,
  searchTypePanel
}) {
  const [number, setNumber] = useState('');
  const location = useLocation();

  useEffect(() => {
    const history = getCurrentValues({
      name: fromRightPanel
        ? `check_number_${searchTypePanel}`
        : `check_number_${searchType}`
    });

    fromRightPanel ? setHistoryPanel(history) : setHistory(history);

    if (isStringDefined(location?.hash)) {
      const decodedHash = decodeURIComponent(location.hash);
      const cleanedHash = decodedHash.replace(/[#+\s]/g, '');

      setNumber(cleanedHash);
      onSubmit({ number: cleanedHash, searchType: 'Number' });
    }
    // eslint-disable-next-line
  }, [location, searchType, searchTypePanel]);

  const onSubmit = useCallback(
    (values, fromQuickNumber) => {
      if (fromQuickNumber) {
        setNumber(values.number);
      }

      if (fromRightPanel) {
        resetPanelData();
      } else {
        resetNumberData();
      }

      const newSearchHistory = setSearchHistory({
        name: fromRightPanel
          ? `check_number_${searchTypePanel}`
          : `check_number_${searchType}`,
        value: values.number.trim()
      });

      if (isDefined(newSearchHistory)) {
        fromRightPanel
          ? setHistoryPanel(newSearchHistory)
          : setHistory(newSearchHistory);
      }

      fetchNumberData({
        number: values.number.trim(),
        fromRightPanel,
        isNonClientAdminUser,
        searchType: fromRightPanel ? searchTypePanel : searchType
      });
    },
    // eslint-disable-next-line
    [
      resetNumberData,
      fetchNumberData,
      fromRightPanel,
      resetPanelData,
      isNonClientAdminUser,
      searchType,
      searchTypePanel
    ]
  );

  return (
    <div className={classes.content}>
      <Formik
        initialValues={{
          number,
          searchType: fromRightPanel ? searchTypePanel : searchType
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validateSchema()}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          dirty,
          setFieldValue
        }) => {
          const onRadioButtonChange = e => {
            fromRightPanel
              ? setSearchTypePanel(e.target.value)
              : setSearchType(e.target.value);
            setFieldValue('searchType', e.target.value);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className={classes.container}>
                <RadioGroup
                  value={values.searchType}
                  onChange={onRadioButtonChange}
                  className={classes.radioButtons}
                >
                  <FormControlLabel
                    value="Number"
                    control={
                      <Radio
                        icon={<i className="far fa-circle"></i>}
                        checkedIcon={
                          <i
                            className="far fa-dot-circle"
                            style={{ color: settings.colors.primary }}
                          ></i>
                        }
                      />
                    }
                    label="Number"
                  />
                  <FormControlLabel
                    value="ICC"
                    control={
                      <Radio
                        icon={<i className="far fa-circle"></i>}
                        checkedIcon={
                          <i
                            className={'far fa-dot-circle'}
                            style={{ color: settings.colors.primary }}
                          ></i>
                        }
                      />
                    }
                    label="ICC"
                  />
                </RadioGroup>
                <div className={classes.submitNumberRow}>
                  <div>
                    {intl.formatMessage({
                      id: 'ENTER_NUMBER'
                    })}
                  </div>
                  <TextField
                    name="number"
                    variant="outlined"
                    margin="dense"
                    value={values.number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.number)}
                    autoFocus
                    inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
                  />
                  <PrimaryButton
                    onClick={handleSubmit}
                    disabled={isStringEmpty(values.number)}
                  >
                    {intl.formatMessage({
                      id: 'BUTTON.SUBMIT'
                    })}
                  </PrimaryButton>
                </div>
                <div
                  className={clsx(classes.messageContainer, {
                    [classes.messageVisible]:
                      Boolean(errors.number) ||
                      (fromRightPanel
                        ? Boolean(panelRequestErrors) ||
                          Boolean(panelResponseError)
                        : Boolean(requestErrors) || Boolean(responseError))
                  })}
                >
                  {errors.number}
                  {fromRightPanel ? panelRequestErrors : requestErrors}
                  {fromRightPanel ? panelResponseError : responseError}
                </div>
                <LastNumbers
                  onSubmit={onSubmit}
                  fromRightPanel={fromRightPanel}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    panelRequestErrors: rightPanel.number?.errors,
    panelResponseError: rightPanel.number?.carrierError,
    requestErrors: checkNumber.errors,
    responseError: checkNumber.carrierError,
    searchType: checkNumber.searchType,
    searchTypePanel: rightPanel.searchType
  };
};

const mapDispatchToProps = {
  fetchNumberData,
  resetNumberData: reduxActions.resetCheckNumber,
  setSearchType: reduxActions.setSearchType,
  resetPanelData: rightPanelReduxActions.resetRightPanelData,
  setHistory: reduxActions.setHistory,
  setSearchTypePanel: rightPanelReduxActions.setSearchType,
  setHistoryPanel: rightPanelReduxActions.setHistory
};

export default flowRight(
  withSettings,
  withUserCheck,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumberLookup);
