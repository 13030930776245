import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { URLS } from '../../../consts/endpoints';
import { isArray, isDefined, isStringDefined } from '../../../utils/isDefined';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import Optional from '../../../utils/optional';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';

export default async function getAdminInfo({ selectedClientId }) {
  const {
    mitelToken,
    baseUrl,
    clientDomain,
    mitelUserId
  } = getMitelRequestProps();
  let admins = [];
  let activities = [];

  try {
    const { data: response } = await getData({
      options: { url: `${URLS.CLIENTS}/${selectedClientId}/admins.json` },
      errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.INTERNAL_ERROR]
    });

    const mappedResponse = mapResponse(response);

    admins = mappedResponse;
  } catch (e) {
    console.warn(e);
  }
  if (mitelToken) {
    try {
      const { data: allActivity } = await getData({
        options: {
          url: `${baseUrl}/api/config/user/${clientDomain}/${mitelUserId}/presence/activity?t=${mitelToken}`
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND]
      });

      activities = Optional(allActivity?.activity).or([]);
    } catch (e) {
      console.warn(e);
    }
  }

  store.dispatch(reduxActions.setAdmins({ admins, activities }));
}

function mapResponse(response) {
  if (!isArray(response)) {
    return [];
  }

  return response.map(el => {
    const { primary_line, secondary_line } = el;

    const phones = [
      isStringDefined(primary_line) ? primary_line : null,
      isStringDefined(secondary_line) ? secondary_line : null
    ].filter(el => isDefined(el));

    return {
      ...el,
      phones
    };
  });
}
