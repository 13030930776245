import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/simcardsTableHeader';
import clsx from 'clsx';

const SimcardsTableHeader = ({ classes, intl, isLoading, settings }) => {
  return (
    <div
      className={classes.headerRoot}
      style={{ color: settings.colors.primary }}
    >
      <div className={classes.label}>
        <i className={clsx('fas fa-sim-card', classes.icon)}></i>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'MENU.SIM_CARDS'
          })}
        </div>
        {isLoading ? <CircularProgress /> : null}
      </div>
    </div>
  );
};

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(SimcardsTableHeader);
