import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';

import styles from '../styles/userRoles';
import withUserCheck from '../../../utils/withUserCheck';
import ActionsButtons from './ActionsButtons';
import { actions as reduxActions } from '../reducers';
import UserRoles from './UserRoles';
import { updateOnSubmit } from '../actions/updateUser';
import Optional from '../../../utils/optional';

export function Roles({
  classes,
  roles = [],
  clientId,
  resetUser,
  selectsOptions,
  updateOnSubmit,
  userId,
  clientConnectId
}) {
  const onSubmit = useCallback(
    values => {
      updateOnSubmit({
        user: values,
        clientId,
        isAdminView: false,
        initialUserRoles: roles,
        onlyRoles: true,
        clientConnectId
      });
    },
    // eslint-disable-next-line
    []
  );
  const initialValues = useMemo(() => {
    return {
      id: userId,
      roles: [...roles]
    };
  }, [userId, roles]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, dirty, errors, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="roles"
              render={arrayHelpers => (
                <div className={classes.customRolesRoot}>
                  <UserRoles
                    options={selectsOptions.roles}
                    arrayHelpers={arrayHelpers}
                  />
                </div>
              )}
            />
            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={resetUser}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ editUser, selectClient }) => {
  return {
    ...editUser,
    userId: editUser.user.id,
    roles: Optional(editUser.user.roles).or([]),
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Roles);
