import { doesArrayHasLength, isDefined } from "../../../utils/isDefined";
import mapNumberToDisplay from "../../../utils/mapNumberToDisplay";

export function mapCellNumbers(data) {
    return data.sim_cards
      .map(simcard =>
        isDefined(simcard.cell_numbers) &&
        doesArrayHasLength(simcard.cell_numbers)
          ? mapNumberToDisplay(simcard.cell_numbers[0].number)
          : null
      )
      .filter(number => isDefined(number));
  }