import Optional from '../../../utils/optional';
import { calculateMonthUsage } from '../../../utils/findCurrentMonthUsage';
import {
  isArray,
  isArrayEmpty,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import moment from 'moment';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import findDatalimitOwner from '../../../utils/findDataLimitOwner';

export default function mapDatalimits(dataLimits) {
  if (!isArray(dataLimits)) {
    return [];
  }

  return dataLimits.map(dataLimit => {
    let {
      accumulated_data_usages: usage,
      name,
      cell_numbers = [],
      block_limit_mb,
      fair_usage_limit
    } = dataLimit;

    if (!isArray(usage)) {
      usage = [];
    }

    const usagesMonths = createMonthsArray(6);

    const mappedUsages = mapUsage(usage);

    const calculatedUsage = usagesMonths.map(month => {
      const usageMonth = findUsageMonth(mappedUsages, month);

      return calculateMonthUsage(dataLimit, usageMonth);
    });

    const [actualMonthUsage, ...lastMonthUsage] = calculatedUsage;
    const totalAverage = calculateAverageUsage({
      usage: lastMonthUsage,
      totalData: true
    });
    const fairAverage = calculateAverageUsage({
      usage: lastMonthUsage,
      fairData: true
    });

    const worldAverage = calculateAverageUsage({ usage: lastMonthUsage });

    const user = mapNames([...findDatalimitOwner(dataLimit)]);

    return {
      cell_numbers,
      name,
      user,
      actualMonthUsage,
      lastMonthUsage,
      totalAverage,
      fairAverage,
      worldAverage,
      totalLimit: block_limit_mb,
      otherLimit: fair_usage_limit,
      limit: calculateMbToGb(block_limit_mb, true),
      fairLimit: calculateMbToGb(fair_usage_limit, true)
    };
  });
}

function mapUsage(usage) {
  return usage.map(data => {
    const [year, month] = data.month.split('-');

    return { ...data, year, month };
  });
}

function findUsageMonth(usage = [], usageDate = {}) {
  return usage.find(
    element =>
      element.year === usageDate.year && element.month === usageDate.month
  );
}

export function createMonthsArray(count) {
  const currentMonth = moment();
  let months = [currentMonth];

  for (let i = 0; i < count - 1; i++) {
    const previousMonth = moment(months[i]).subtract(1, 'M');
    months = [...months, previousMonth];
  }

  return months.map(el => {
    const date = moment(el).format('YYYY-MM');
    const [year, month] = date.split('-');
    return { year, month, date };
  });
}

function calculateAverageUsage({ usage, totalData, fairData }) {
  return usage.reduce((arr, el, i) => {
    const tempUsages = [...usage].splice(0, i + 1);

    const definedUsages = tempUsages.filter(usage =>
      totalData
        ? isDefined(usage.total_data) || isDefined(usage.data_total)
        : fairData
        ? isDefined(usage.data_roam_home)
        : isDefined(usage.data_roam_others)
    );

    if (isArrayEmpty(definedUsages)) {
      return [...arr, '-'];
    }

    const mappedUsages = definedUsages
      .map(usage => {
        return totalData
          ? Optional(usage.total_data).or(usage.data_total)
          : fairData
          ? usage.data_roam_home
          : usage.data_roam_others;
      })
      .reduce((count, el, i) => {
        return count + el;
      }, 0);

    return [...arr, calculateMbToGb(mappedUsages / definedUsages.length, true)];
  }, []);
}

function mapNames(data = []) {
  return data
    .map(user => {
      if (isUndefined(user) || Object.keys(user).length === 0) {
        return null;
      }

      return Optional(user.name).or('');
    })
    .filter(el => isDefined(el))
    .join(', ');
}
