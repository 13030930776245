import { isArray, isStringEmpty } from '../../../utils/isDefined';
import { mapSearchValue } from '../../../utils/tableUtils';

export default function mapCustomFilters({ filter, obj, value }) {
  if (isArray(value) && value.length >= 2) {
    return obj;
  }

  const mappedValue = isArray(value) ? value.join(',') : mapSearchValue(value);

  if (isStringEmpty(mappedValue)) {
    return { ...obj };
  }

  switch (filter.column.field) {
    case 'range_from':
      return { ...obj, [`range_from`]: { eq: mappedValue } };
    default:
      return { ...obj, [filter.column.field]: { match: mappedValue } };
  }
}
