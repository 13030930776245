import React, { useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import ActionsButtons from './components/ActionsButtons';
import validateSchema from './components/validateSchema';
import styles from './styles';
import InputRow from '../dataRows/InputRow';
import PassStrengthMeter from '../passStrengthMeter/PassStrengthMeter';
import clsx from 'clsx';
import withSettings from '../../utils/withSettings';

export function PasswordChange({
  classes,
  intl,
  changePass,
  handleClose = () => {},
  isNotDialog = false,
  settings,
  getSettings = true,
  isResetPassword = false
}) {
  const INITIAL_VALUES = useMemo(() => {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      isNotDialog
    };
  }, [isNotDialog]);

  const CUSTOM_STYLES = useMemo(() => {
    return {
      root: { margin: '5px 0' },
      label: { color: getSettings ? settings.colors.primary : '#15344B' }
    };
  }, [settings, getSettings]);

  const onSubmit = useCallback(
    (data, { resetForm }) => {
      changePass(data);
      resetForm(INITIAL_VALUES);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl)}
    >
      {({ handleSubmit, dirty, errors, values, handleBlur, handleChange }) => {
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            <div className={classes.passwordContainer}>
              {isNotDialog && (
                <>
                  <div className={classes.passRowContainer}>
                    <InputRow
                      customStyles={CUSTOM_STYLES}
                      label="USER.OLD_PASS"
                      name="current_password"
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      mobile={true}
                      isValueHidden={true}
                    />
                  </div>
                  <PassStrengthMeter pass={''} />
                </>
              )}
              <div className={classes.passRowContainer}>
                <InputRow
                  customStyles={CUSTOM_STYLES}
                  label="USER.NEW_PASS"
                  name="password"
                  errors={errors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  mobile={true}
                  isValueHidden={true}
                />
              </div>
              <PassStrengthMeter pass={values.password} />
              <div className={classes.passRowContainer}>
                <InputRow
                  customStyles={CUSTOM_STYLES}
                  label="USER.REPEAT_PASS"
                  name="password_confirmation"
                  errors={errors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  mobile={true}
                  isValueHidden={true}
                />
              </div>
            </div>
            <footer
              className={clsx(classes.footer, {
                [classes.isNotDialog]: isNotDialog
              })}
            >
              <ActionsButtons
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
                isNotDialog={isNotDialog}
                isResetPassword={isResetPassword}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(PasswordChange);
