export default {
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: '10px'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.3s linear,opacity 0.3s linear'
  },
  contentVisible: {
    opacity: 1,
    visibility: 'visible',
    transition: 'visibility 0.3s linear,opacity 0.3s linear'
  },
  icon: { cursor: 'pointer' },
  hidden: {
    visibility: 'hidden'
  }
};
