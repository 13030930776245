export function isDefined(variable) {
  return typeof variable !== 'undefined' && variable !== null;
}

export function areAllDefined(...args) {
  return args.reduce(
    (areDefined, val) => (!areDefined ? false : isDefined(val)),
    true
  );
}

export function isUndefined(variable) {
  return !isDefined(variable);
}

export function isString(value) {
  return isDefined(value) && typeof value === 'string';
}

export function isStringDefined(value) {
  return isString(value) && value.length > 0;
}

export function isStringEmpty(value) {
  return isString(value) && value.length === 0;
}

export function isFunction(fn) {
  return typeof fn === 'function';
}

export function isArray(arr) {
  return Array.isArray(arr);
}

export function doesArrayHasLength(arr) {
  return isArray(arr) && arr.length > 0;
}

export function isArrayEmpty(arr) {
  return isArray(arr) && arr.length === 0;
}

export function isObject(o) {
  return isDefined(o) && typeof o === 'object' && !isFunction(o) && !isArray(o);
}

export function isNumber(v) {
  return typeof v === 'number';
}

export function isBoolean(v) {
  return typeof v === 'boolean';
}

export function isPromise(p) {
  return Promise.resolve(p) === p;
}

export function isJSON(value) {
  if (isUndefined(value)) {
    return false;
  }

  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}

export function isObjectEmpty(obj) {
  if (!isObject(obj)) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
