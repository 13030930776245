import React, { useEffect } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import AvailabilityBox from '../../../partials/AvailabilityBox';
import CustomSwitch from '../../../partials/CustomSwitch';
import UserPhoto from '../../../partials/userPhoto/UserPhoto';
import { logUserActions } from '../actions/logUserToDistgroup';
import styles from '../styles/agentsDetails';
import withUserCheck from '../../../utils/withUserCheck';
import withSettings from '../../../utils/withSettings';
import PresenceDot from '../../../partials/presenceDot/PresenceDot';
import agentsSubscriptions from '../actions/agentSubscriptions';

function AgentDetails({
  classes,
  isSupervisor,
  agent,
  key,
  logUserActions,
  userMitelId,
  isAdminUser,
  isCurrentUserSelectedClientUser,
  connectDomain,
  queueId
}) {
  useEffect(() => {
    agentsSubscriptions({ userId: agent.id });
  }, [agent.id]);

  return (
    <tr key={key} className={classes.agentContainer}>
      <td className={classes.agentLogged}>
        {isSupervisor && (
          <CustomSwitch
            checked={agent.isLoggedIn}
            handleChange={event =>
              logUserActions({
                checked: event.target.checked,
                groupId: queueId,
                otherUserId: agent.mitelUserId,
                isAdminUser,
                connectDomain,
                isCurrentUserSelectedClientUser,
                userMitelId
              })
            }
          />
        )}
      </td>
      <td className={classes.agentData}>
        <UserPhoto
          id={agent.id}
          initials={agent.initials}
          customClasses={{
            root: classes.photoRoot,
            photo: classes.photo,
            initials: classes.initials
          }}
        />
        <div
          className={classes.agentName}
        >{`${agent.firstName} ${agent.lastName}`}</div>
        <PresenceDot
          actualAvailability={agent.isAvailable}
          inCall={agent.incall}
          customClasses={{ root: classes.presentDotRoot }}
        />
        <AvailabilityBox
          available={agent.isAvailable}
          text={agent.activity}
          incall={agent.incall}
        />
      </td>
    </tr>
  );
}

const mapStatesToProps = ({ selectClient, auth, queues }) => {
  return {
    connectDomain: selectClient.selectedClient.connect30_domain,
    userMitelId: auth.user.connect30_user_id
  };
};

const mapDispatchToProps = {
  logUserActions
};

export default flowRight(
  withUserCheck,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AgentDetails);
