const styles = theme => {
  return {
    accordion: {
      boxShadow: 'unset',
      '&::before': {
        display: 'none'
      }
    },
    accordionSummary: { paddingLeft: '10px' },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 0'
    },
    expanded: {
      margin: '0'
    }
  };
};

export default styles;
