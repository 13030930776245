import getPhoneBookRegistration from './getPhoneBookRegistration';
import mapDataForSend from './mapDataForSend';
import { isArrayEmpty, isDefined, isUndefined } from '../../../utils/isDefined';
import phoneBookRegistrationRequest from './phoneBookRegistrationRequest';
import store from '../../../store/store';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import customParseNumber from '../../../utils/customParseNumber';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';

const bulkEditPhonebookRegistration = async ({
  allNumbersArray = [],
  numberRangeId,
  values = {},
  registrationData,
  defaultClientAddress
}) => {
  store.dispatch(loaderReduxActions.startSpinLoader());
  let errors = [];
  let completed = [];

  const numbersToEditArray = createNumbersArray(allNumbersArray);
  let currentRegistrationData = registrationData;

  if (isUndefined(currentRegistrationData)) {
    const { data: range } = await getData(
      urlCreator(`${URLS.EXTERNAL_NO_RANGES}/${numberRangeId}.json`, {
        include: ['phone_book_registrations']
      })
    );

    if (isDefined(range?.phone_book_registrations)) {
      currentRegistrationData = range?.phone_book_registrations;
    } else {
      currentRegistrationData = [];
    }
  }

  await Promise.all(
    numbersToEditArray.map(async number => {
      let phoneBookData = { ...values, id: void 0 };

      if (isArrayEmpty(currentRegistrationData)) {
        const phonebookEntry = await getPhoneBookRegistration(number);

        if (isDefined(phonebookEntry?.id)) {
          phoneBookData = { ...phoneBookData, id: phonebookEntry?.id };
        }
      } else {
        let phoneNumber = customParseNumber(`+${number}`);

        if (isDefined(phoneNumber?.nationalNumber)) {
          const existInRegistry = currentRegistrationData.find(
            el => el.number === Number(phoneNumber?.nationalNumber)
          );
          if (isDefined(existInRegistry)) {
            phoneBookData = { ...phoneBookData, id: existInRegistry?.id };
          }
        }
      }
      const mappedData = mapDataForSend({
        values: phoneBookData,
        numberRangeId,
        phoneNumber: number,
        defaultClientAddress
      });

      const response = await phoneBookRegistrationRequest(mappedData, true);

      if (!response) {
        errors = [...errors, number];
      } else {
        completed = [...completed, number];
      }
    })
  );
  store.dispatch(loaderReduxActions.stopSpinLoader());
  return { completed, errors };
};

export default bulkEditPhonebookRegistration;

const createNumbersArray = (numbers = []) => {
  const [start, end] = numbers;

  let numberOptions = [];

  for (let i = start; i <= end; i++) {
    numberOptions = [...numberOptions, i];
  }

  return numberOptions;
};
