import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

const deleteVacation = async ({ id }) => {

  const response = await deleteData({
    url: `${URLS.VACATIONS}/${id}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.DELETE_PERMISSION',
      messageType: 'success'
    });

    return true;
  }

  return false;
};

export default deleteVacation;
