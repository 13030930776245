import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { updateData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import ShortcutLabel from '../components/ShortcutLabel';

export default async function updateShortcut(shortcut, isEdit) {
  const {
    mitelToken,
    baseUrl,
    clientDomain,
    mitelUserId
  } = getMitelRequestProps();

  const data = mapShortcutForUpdate(shortcut, isEdit);

  try {
    const response = await updateData({
      url: `${baseUrl}/api/presence/shortcut/${clientDomain}/${mitelUserId}/${data.id}?t=${mitelToken}`,
      data
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.UPDATE_SHORTCUT',
        messageType: 'success'
      });

      return {
        ...response.data,
        value: response.data.id,
        label: <ShortcutLabel short={response.data} />
      };
    }
  } catch {}
}

function mapShortcutForUpdate(shortcut = {}, isEdit) {
  const {
    value,
    label,
    durationMinutes,
    durationType,
    applyDiversionNumber,
    diversionPhoneNumber,
    ...restShortcut
  } = shortcut;

  const mappedShortcut = {
    ...restShortcut,
    durationType,
    durationMinutes: durationType !== 'MINUTES' ? 0 : Number(durationMinutes),
    diversionPhoneNumber: applyDiversionNumber ? diversionPhoneNumber : ''
  };

  if (!isEdit) {
    mappedShortcut.id = uuidv4();
  }

  return mappedShortcut;
}
