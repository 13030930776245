const styles = () => {
  return {
    root: {
      marginBottom: '5px'
    },
    numberContainer: {
      display: 'flex',
      margin: '5px',
      borderBottom: '1px solid #EEEFF5',
      paddingBottom: '5px'
    },
    label: {
      width: '100px'
    }
  };
};

export default styles;
