import React, { useRef } from 'react';

import CreateAddress from '../../../partials/createAddress/CreateAddress';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import { getAllAddresses } from '../actions/getAddresses';
import { connect } from 'react-redux';
import Optional from '../../../utils/optional';

const AddAddress = ({ getAllAddresses, clientId }) => {
  const ref = useRef();

  const refreshAddresses = () => {
    getAllAddresses(clientId);
  };

  const onClick = () => {
    ref.current.click();
  };

  return (
    <div ref={ref} onClick={onClick}>
      <CreateAddress customAction={refreshAddresses}>
        <AddButton buttonText="BUTTON.NEW_ADDRESS" forwardRef={ref} />
      </CreateAddress>
    </div>
  );
};

const mapStatesToProps = ({ selectClient, auth }) => {
  return {
    clientId: Optional(selectClient.selectedClient?.id).or(
      auth.defaultClient?.id
    )
  };
};

const mapDispatchToProps = {
  getAllAddresses
};

export default connect(mapStatesToProps, mapDispatchToProps)(AddAddress);
