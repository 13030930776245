import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const actionTypes = createReduxActionTypes('ACTIVATION_MESSAGE', [
  OPEN_DIALOG,
  CLOSE_DIALOG
]);

const DEFAULT_STATE = {
  open: false,
  user: {},
  fromuserProfile: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DIALOG]: {
      const { user, fromuserProfile } = action;
      return {
        ...state,
        open: true,
        user,
        fromuserProfile
      };
    }
    case actionTypes[CLOSE_DIALOG]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDialog: (user, fromuserProfile) => ({
    type: actionTypes[OPEN_DIALOG],
    user,
    fromuserProfile
  }),
  closeDialog: () => ({ type: actionTypes[CLOSE_DIALOG] })
};
