import React, { useState, cloneElement, useMemo, useCallback } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router';

import { setUser } from '../../../modals/editUser/actions/setUser';
import { lockUserAction } from '../actions/lockUser';
import LockText from '../../../partials/lockUserTableCell/components/LockText';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { isDefined } from '../../../utils/isDefined';
import { setTelephonyUser } from '../../../modals/editUser/actions/setTelephonyUser';
import ChangePhotoDialog from './ChangePhotoDialog';
import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import { actions as activationMessageDialogReduxActions } from '../../../partials/activationMessageDialog/reducers';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

const UserMenu = ({
  children,
  openEditUser,
  user,
  lockUserAction,
  clientConnectId,
  setTelephonyUser,
  location,
  loggedUserDomainId,
  userConnectId,
  isCurrentUserSelectedClientUser,
  openActivationMessageDialog
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isUserProfilePage = useMemo(() => userProfilePageCheck(location), [
    location
  ]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = useCallback(
    () => {
      if (isDefined(user.connect30_user_id)) {
        setTelephonyUser({
          userId: user.connect30_user_id,
          clientConnectId: isUserProfilePage
            ? loggedUserDomainId
            : clientConnectId,
          fromUsersTable: false,
          isTelephonyUser: true,
          roles: user.roles,
          id: user.id
        });
      } else {
        openEditUser({
          user,
          clientId: user.client_id,
          isDomainProvided: isDefined(clientConnectId)
        });
      }
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [user.connect30_user_id, clientConnectId, loggedUserDomainId]
  );

  const handleLockClick = useCallback(
    action => {
      lockUserAction(user.id, action);
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [user.id]
  );

  const handleChangePhotoClick = useCallback(
    () => {
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    []
  );

  const handleActivationMessageClick = () => {
    openActivationMessageDialog(user, true);
    handleClose();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {can('update', createSubject('User', user)) && (
          <MenuItem onClick={handleEditClick}>
            {translate('TOOLTIP.EDIT_USER')}
          </MenuItem>
        )}
        {can('update', createSubject('User', user)) &&
          (user.locked ? (
            <ConfirmDialog
              dialogTitleIntlId={'USER.UNLOCK_USER'}
              dialogContentComponent={<LockText isLocking={false} />}
              onConfirmClick={() => handleLockClick('unlock')}
            >
              <MenuItem>{translate('USER.UNLOCK_USER')}</MenuItem>
            </ConfirmDialog>
          ) : (
            <ConfirmDialog
              dialogTitleIntlId={'USER.LOCK_USER'}
              dialogContentComponent={<LockText isLocking={true} />}
              onConfirmClick={() => handleLockClick('lock')}
            >
              <MenuItem>{translate('USER.LOCK_USER')}</MenuItem>
            </ConfirmDialog>
          ))}
        {isDefined(userConnectId) &&
          (isCurrentUserSelectedClientUser || isUserProfilePage) && (
            <ChangePhotoDialog onOpenClick={handleChangePhotoClick}>
              <MenuItem>{translate('CHANGE_PHOTO')}</MenuItem>
            </ChangePhotoDialog>
          )}
        <MenuItem onClick={handleActivationMessageClick}>
          {translate('TOOLTIP.ACTIVATION_MESSAGE')}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient, auth, user }) => {
  return {
    clientConnectId: selectClient.selectedClient.connect30_domain,
    loggedUserDomainId: auth.defaultClient.connect30_domain,
    userConnectId: user.connect30_user_id
  };
};

const mapDispatchToProps = {
  openEditUser: setUser,
  lockUserAction,
  setTelephonyUser,
  openActivationMessageDialog: activationMessageDialogReduxActions.openDialog
};

export default flowRight(
  withUserCheck,
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps)
)(UserMenu);

function userProfilePageCheck(location) {
  if (!location) {
    return false;
  }
  const isUserProfile = location.pathname
    .split('/')
    .find(el => el === 'userProfile');

  return isDefined(isUserProfile);
}
