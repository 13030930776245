import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/statusCell';
import { NUMBER_STATUSES } from '../../../consts/initialValues';
import findTranslatedValues from '../../../utils/findTranslatedValues';
import clsx from 'clsx';

function StatusCell({ status, settings, classes }) {
  const statusValue = setIcon({ status, settings, classes });
  return (
    <div className={classes.statusRoot}>
      {statusValue.icon}
      <div className={classes.statusLabel}>{statusValue.label}</div>
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(StatusCell);

function setIcon({ status, settings, classes }) {
  switch (status) {
    case 'active': {
      return {
        label: findTranslatedValues(status, NUMBER_STATUSES),
        icon: (
          <i
            className={clsx('fas fa-check-circle', classes.icon)}
            style={{ color: settings.colors.success }}
          ></i>
        )
      };
    }
    default:
      return {
        label: findTranslatedValues(status, NUMBER_STATUSES),
        icon: (
          <i
            className={clsx('fas fa-minus-circle', classes.icon)}
            style={{ color: settings.colors.danger }}
          ></i>
        )
      };
  }
}
