import React from 'react';

import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import TableNameCell from '../../../partials/tableNameCell/TableNameCell';
import TableActionButtons from './TableActionButtons';
import styles from '../styles';
import Optional from '../../../utils/optional';
import searchInArray from '../../../utils/searchInArray';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import StatusCell from '../../../partials/statusCell/StatusCell';
import { mapCellNumbers } from '../actions/tableColumnsActions';
import CellNumbersTableCell from '../../../partials/CellNumbersTableCell/CellNumbersTableCell';
import LockUserTableCell from '../../../partials/lockUserTableCell/LockUserTableCell';
import formatDate from '../../../utils/formatDate';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { mapCellNumbersToDisplay } from '../../dataLimits/actions/tableColumnsActions';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import { translate } from '../../../i18n/I18nProvider';

export default function tableColumns({
  client,
  lockUserAction,
  clientsLookupOptions,
  settings
}) {
  const columns = [
    {
      render: rowData => (
        <TableActionButtons
          data={rowData}
          clientId={Optional(client).or(rowData.client_id)}
        />
      ),
      cellStyle: styles.actionCell,
      disableClick: true,
      sorting: false,
      width: '40px'
    },
    {
      title: 'CLIENT',
      render: rowData =>
        Optional(rowData.client)
          .map(client => client.name)
          .or(''),
      hideForUserView: true,
      field: 'client.name',
      width: '100px'
    },
    {
      title: 'TABLE.NAME',
      field: 'name',
      render: rowData => (
        <TableNameCell
          data={[rowData]}
          from="users"
          clientId={rowData.client_id}
        />
      ),
      exportTransformer: rowData => rowData.name,
      customSort: (a, b) => {
        return a.name > b.name ? 1 : -1;
      },
      customFilterAndSearch: (term, rowData) =>
        searchCaseInsensitive(rowData.name, term),
      disableClick: true
    },
    {
      title: 'TABLE.EMAIL',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.email, term);
      },
      field: 'email'
    },
    {
      title: 'TABLE.USERNAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.username, term);
      },
      field: 'username'
    },
    {
      title: 'USERS_TABLE.PLATFORM_USER_ID',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.connect30_user_id, term);
      },
      field: 'connect30_user_id'
    },
    {
      title: 'TABLE.DEPARTMENT',
      field: 'department',
      render: rowData => (
        <div>
          {Optional(rowData.department)
            .map(department => department.name)
            .or('')}
        </div>
      ),
      exportTransformer: rowData =>
        Optional(rowData.department)
          .map(department => department.name)
          .or(''),
      customFilterAndSearch: (term, rowData) => {
        const departmentName = Optional(rowData.department)
          .map(department => department.name)
          .or('');
        return searchCaseInsensitive(departmentName, term);
      }
    },
    {
      title: 'CELL_NUMBERS',
      field: 'sim_cards',
      render: rowData => (
        <CellNumbersTableCell data={rowData} mapNumbers={mapCellNumbers} />
      ),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: mapCellNumbers(rowData),
          term,
          isValueNumber: true
        }),
      exportTransformer: rowData =>
        Optional(rowData.sim_cards)
          .map(simcCards =>
            simcCards
              .reduce((arr, simcard) => {
                return [...arr, ...mapCellNumbersToDisplay(simcard)];
              }, [])
              .join(', ')
          )
          .or(''),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },

    {
      title: 'TABLE.USER_ROLE',
      field: 'roles',
      render: rowData => (
        <div>
          {Optional(rowData.roles)
            .map(roles => roles.map(role => role.name).join(','))
            .or('')}
        </div>
      ),
      exportTransformer: rowData =>
        Optional(rowData.roles)
          .map(roles => roles.map(role => role.name).join(','))
          .or(''),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({ array: rowData.roles, term, searchBy: 'name' })
    },
    {
      title: 'USERS_TABLE.SUPER_ADMIN',
      field: 'is_super_admin',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.TRUE_FALSE,
            rowData.is_super_admin
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.TRUE_FALSE)
      },
      hiddenByColumnsButton: true,
      hidden: true
    },
    {
      title: 'USERS_TABLE.CONNECT_LINK',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.mitel_profile_link, term);
      },
      field: 'mitel_profile_link',
      hidden: true
    },
    {
      title: 'LANGUAGE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.language, term);
      },
      field: 'language',
      hidden: true
    },
    {
      title: `${translate('USER.ACCES_TO_SELFCARE')} ${Optional(
        settings?.title
      ).or('selfacre')}`,
      untranslatedTitle: true,
      field: 'locked',
      render: rowData => (
        <LockUserTableCell data={rowData} lockUserAction={lockUserAction} />
      ),
      sorting: false,
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.LOCK)
      }
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      exportTransformer: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      exportTransformer: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (isDefined(client)) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  if (can('manage', createSubject('User', { client_id: Number(client) }))) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
