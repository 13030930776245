import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, NEW_USER_REQUEST } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import Optional from '../../../utils/optional';
import { PROVIDERS_NUMBERS } from '../../../consts/initialValues';
import {
  isDefined,
  isStringDefined,
  isStringEmpty
} from '../../../utils/isDefined';

export function newUserSubmit(userData) {
  return {
    type: sagaActions[NEW_USER_REQUEST],
    userData
  };
}

export function* newUserRequest({ userData }) {
  const mappedData = yield call(mapaData, userData);

  try {
    const response = yield call(postData, {
      url: URLS.NEW_USER_REQUESTS,
      data: {
        ...mappedData
      }
    });

    if (response.status === RESPONSE_CODES.CREATED) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.NEW_USER_REQUESTS_SEND',
        messageType: 'success'
      });
    }

    yield put(reduxActions.resestUserWizard());
  } catch {}
}

function* watchNewUserRequest() {
  yield takeLatest(sagaActions[NEW_USER_REQUEST], newUserRequest);
}

export default watchNewUserRequest();

export function mapaData(userData) {
  const data = { ...userData };
  const {
    data_sharing_card,
    shipping_address,
    network,
    data_sharing_sim_card_number,
    mobile_sim_card_number,
    isNewNumber,
    activationAsap,
    fields,
    activation_date,
    name,
    account,
    att
  } = data;

  const dir_fields = mapFields(fields);

  data.shipping_address =
    data.have_mobile_sim_card || data.have_data_sharing_sim_card
      ? null
      : `${name}, ${shipping_address}${
          isStringDefined(att.trim()) ? `\n Att. ${att}` : ''
        } `;

  data.activation_date = !activationAsap ? activation_date : 'ASAP';

  delete data.have_mobile_sim_card;
  delete data.have_data_sharing_sim_card;
  delete data.isMobileNumberProvided;
  delete data.isOurNumber;
  delete data.isNewNumber;
  delete data.mobileNumber;
  delete data.portMobileNumber;
  delete data.isMovingDateProvided;
  delete data.fields;
  delete data.activationAsap;
  delete data.name;
  delete data.isDisabledNetworkSelect;
  delete data.additionalValues;
  delete data.att;

  return {
    ...data,
    data_sharing_sim_card_number: mapNumberValue(
      network,
      data_sharing_sim_card_number
    ),
    dir_fields,
    is_new_number: isNewNumber,
    mobile_sim_card_number: mapNumberValue(network, mobile_sim_card_number),
    data_sharing_card: data_sharing_card === true ? 'yes' : 'no',
    account: account.value
  };
}

function mapNumberValue(network, value) {
  if (isStringEmpty(value)) {
    return '';
  }
  const networkPrefix = Optional(PROVIDERS_NUMBERS[network]).or(null);
  return isDefined(networkPrefix) ? `${networkPrefix}${value}` : value;
}

function mapFields(fields) {
  return fields.map(field => {
    const value = isStringEmpty(field.value) ? null : field.value;
    return { ...field, value };
  });
}
