import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import flowRight from 'lodash.flowright';

import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';

export function ActionButtons({ data, intl, openAppSettings }) {
  const { name, id } = data;
  return (
    <div>
      <Link
        to={{
          pathname: '/admin/partners/settings',
          state: { id }
        }}
      >
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.OPEN_APP_SETTINGS"
          iconClassName="far fa-edit"
        />
      </Link>

      <Link
        to={{
          pathname: '/admin/partners/clients',
          state: { id, name }
        }}
      >
        <Button>{intl.formatMessage({ id: 'BUTTON.GO_TO_CLIENTS' })}</Button>
      </Link>
    </div>
  );
}


export default flowRight(
  injectIntl
)(ActionButtons);
