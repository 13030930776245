import { createReduxActionTypes } from '../../../utils/actionFactory';

const RESET_DATALIMITS_USAGE = 'RESET_DATALIMITS_USAGE';
const SET_DATALIMITS = 'SET_DATALIMITS';
const SET_LAST_MONTHS_COUNT = 'SET_LAST_MONTHS_COUNT';

export const actionTypes = createReduxActionTypes('DATALIMITS_USAGE', [
  RESET_DATALIMITS_USAGE,
  SET_DATALIMITS,
  SET_LAST_MONTHS_COUNT
]);

const DEFAULT_STATE = {
  datalimits: [],
  lastMonthsCount: 3,
  selectsOptions: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_DATALIMITS]: {
      const { datalimits, selectsOptions } = action;
      return {
        ...state,
        datalimits,
        selectsOptions
      };
    }
    case actionTypes[SET_LAST_MONTHS_COUNT]: {
      const { lastMonthsCount } = action;
      return {
        ...state,
        lastMonthsCount
      };
    }
    case actionTypes[RESET_DATALIMITS_USAGE]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setDatalimits: (datalimits, selectsOptions) => ({
    type: actionTypes[SET_DATALIMITS],
    datalimits,
    selectsOptions
  }),
  setLastMonthsCounts: lastMonthsCount => ({
    type: actionTypes[SET_LAST_MONTHS_COUNT],
    lastMonthsCount
  }),
  resetDatalimitsUsage: () => ({ type: actionTypes[RESET_DATALIMITS_USAGE] })
};
