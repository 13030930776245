import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
    fontSize: '14px',
    '& span': {
      padding: '0 5px'
    }
  }
};

export function FilterButton({ classes }) {
  return (
    <div className={classes.root}>
      <i className="fas fa-filter"></i>
    </div>
  );
}

export default withStyles(styles)(FilterButton);
