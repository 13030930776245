import { getData } from '../../../utils/http';
import { OUTSIDE_API, URLS } from '../../../consts/endpoints';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';

export default async function getActualValue(field, requestId) {
  if (isUndefined(requestId)) {
    return { [field]: '' };
  }
  let text = {};

  try {
    const { data } = await getData({
      options: { ...getOptions({ field, requestId }) }
    });
    if (isDefined(data.data)) {
      text = { [field]: Optional(data.data.attributes[field]).or('') };
    } else text = { [field]: data[field] };
  } finally {
    return text;
  }
}

function getOptions({ field, requestId }) {
  const options = {
    note: {
      url: `${OUTSIDE_API.USERS}/${requestId}/${OUTSIDE_API.NOTES}`,
      errorsToPass: [RESPONSE_CODES.NOT_FOUND]
    },
    description: {
      url: `${URLS.SIM_CARDS}/${requestId}`
    }
  };

  return options[field];
}
