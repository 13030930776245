import { withStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import flowRight from 'lodash.flowright';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import CustomAddressSelect from '../../../partials/customAddressSelect/CustomAddressSelect';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import DatepickerRow from '../../../partials/dataRows/DatepickerRow';
import InputRow from '../../../partials/dataRows/InputRow';
import {
  isDefined,
  isStringDefined,
  isStringEmpty
} from '../../../utils/isDefined';
import { newUserSubmit } from '../actions/newUserRequest';
import validateSchema from '../actions/validateSchema';

import { actions as reduxActions } from '../reducers';
import styles, { CUSTOM_STYLES } from '../styles/subscriptionStep';
import MandatoryField from './MandatoryField';

function AddressStep({
  handleBack,
  setUserData,
  classes,
  intl,
  resestUserWizard,
  isMovingDateProvided,
  isNewNumber,
  userData,
  isMobileNumberProvided,
  newUserSubmit,
  firstStep,
  secondStep,
  thirdStep,
  haveMobileSimcard,
  haveDataSharingSimcard,
  fourthStep
}) {
  const onSubmit = useCallback(
    dialogValues => {
      newUserSubmit({
        ...firstStep,
        ...secondStep,
        ...thirdStep,
        ...dialogValues
      });
    },
    // eslint-disable-next-line
    [firstStep, secondStep, thirdStep]
  );

  const onBackClick = useCallback(
    () => {
      setUserData(null, 'fourthStep');
      setUserData(
        {
          shipping_address: fourthStep.shipping_address,
          name: fourthStep.name,
          att: fourthStep.att,
          additionalValues: fourthStep.additionalValues
        },
        'fourthStep'
      );
      if (
        isStringEmpty(firstStep.mobile_number) &&
        !firstStep.isNewNumber &&
        (isStringDefined(firstStep.internal_number) ||
          isStringDefined(firstStep.landline_number))
      ) {
        return handleBack(3);
      }
      return handleBack();
    },
    // eslint-disable-next-line
    [firstStep]
  );
  return (
    <Formik
      initialValues={{
        ...userData.fourthStep,
        isMovingDateProvided,
        have_data_sharing_sim_card: haveDataSharingSimcard,
        have_mobile_sim_card: haveMobileSimcard,
        isNewNumber,
        isMobileNumberProvided
      }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl).addressStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = { errors, values, handleBlur, handleChange };
        return (
          <Form onSubmit={handleSubmit} className={classes.root}>
            <div className={classes.rowContainer}>
              {(isMobileNumberProvided || isNewNumber) && (
                <div>
                  <InputRow
                    label="ORDER_SIMCARD.NAME_COMPANY"
                    name="name"
                    disabled={haveMobileSimcard || haveDataSharingSimcard}
                    isRequired={!haveMobileSimcard && !haveDataSharingSimcard}
                    {...fieldProps}
                  />
                  <InputRow labelText="Att." name="att" {...fieldProps} />
                  <CustomAddressSelect
                    customLabel="ORDER_SIMCARD.ADDRESS_STEP"
                    selectName="shipping_address"
                    inputName="shipping_address"
                    fieldProps={fieldProps}
                    setFieldValue={setFieldValue}
                    splitLines={true}
                    maxRows={3}
                    menuPlacement="bottom"
                    maxMenuHeight={160}
                    additionalValues={fourthStep.additionalValues}
                    disabled={haveMobileSimcard || haveDataSharingSimcard}
                    isRequired={!haveMobileSimcard && !haveDataSharingSimcard}
                  />
                </div>
              )}
              <div>
                {!isMovingDateProvided && (
                  <DatepickerRow
                    label="ACTIVATION_DATE"
                    name="activation_date"
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    daysAhead={3}
                    disabled={values.activationAsap}
                    infoIconTooltipIntlKey="REQUEST_NEW_USER.ACTIVATION_DATE_INFO"
                    infoIcon={true}
                    isRequired={!values.activationAsap}
                  />
                )}
                {!isMovingDateProvided && (
                  <CheckboxRow
                    label="REQUEST_NEW_USER.ASAP"
                    name="activationAsap"
                    customStyles={CUSTOM_STYLES.checkbox}
                    {...fieldProps}
                  />
                )}
                <InputRow
                  customStyles={CUSTOM_STYLES.notes}
                  label="NOTES"
                  name="notes"
                  isMultiline={true}
                  minRows={6}
                  maxRows={4}
                  {...fieldProps}
                />
              </div>
            </div>
            <MandatoryField />
            <div className={classes.footer}>
              <PrimaryButton onClick={onBackClick}>
                {intl.formatMessage({
                  id: 'BUTTON.BACK'
                })}
              </PrimaryButton>
              <PrimaryButton onClick={handleSubmit}>
                {intl.formatMessage({
                  id: 'BUTTON.ORDER_NEW_USER'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={resestUserWizard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({
  requestUserWizard: { userData, mobileSubscriptions, checkNumberData }
}) => {
  const { firstStep, secondStep, thirdStep, fourthStep } = userData;
  const isMovingDateProvided =
    isDefined(secondStep.move_number_date) ||
    secondStep.is_old_subscripion_date;
  const isNewNumber = firstStep.isNewNumber;
  const isMobileNumberProvided = isStringDefined(firstStep.mobile_number);
  const haveMobileSimcard = thirdStep.have_mobile_sim_card;
  const haveDataSharingSimcard = thirdStep.have_data_sharing_sim_card;
  return {
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    isMovingDateProvided,
    isNewNumber,
    userData,
    isMobileNumberProvided,
    haveMobileSimcard,
    haveDataSharingSimcard
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  newUserSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddressStep);
