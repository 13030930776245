const styles = theme => {
  return {
    selectContainer: {
      width: '100%',
      marginBottom: '2px',
      display: 'flex',
      backgroundColor: '#FFF'
    },
    labelContainer: {
      fontSize: '18px',
      fontWeight: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end'
    },
    onlyAccount: { alignItems: 'start', marginLeft: '36px' },
    accountNo: {
      fontSize: '12px',
      color: '#888',
      fontWeight: 400
    }
  };
};

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'end',
    textAlign: 'end'
  },
  control: {
    borderStyle: 'hidden',
    width: '400px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'end'
  },
  indicatorSeparator: { display: 'none' },
  menu: { zIndex: 100, width: 'fit-content', left: 0 }
};
