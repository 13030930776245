import { isArray, isObject, isString, isUndefined } from './isDefined';
import Optional from './optional';

export function mapErrorMessage(errorResponse, customErrorText) {
  if (isObject(errorResponse)) {
    const { status, data = {}, config = {} } = errorResponse;

    const endpoint = Optional(config.url)
      .map(url => {
        return Optional(url.split('//')[2]).or('unknown');
      })
      .or('unknown');

    let errorTextArray = [];

    if (isString(data)) {
      errorTextArray = [data];
    } else {
      if (!isObject(data)) {
        return {
          endpoint,
          status: `Status code : ${status}`,
          errorTextArray: []
        };
      }

      const { error, errors, errmsg } = data;

      errorTextArray = Optional(error)
        .map(error => (isString(error) ? [error] : []))
        .or(
          Optional(errmsg)
            .map(errmsg => (isString(errmsg) ? [errmsg] : []))
            .or([])
        );

      if (errors) {
        errorTextArray = mapErrors({ errors, customErrorText });
      }

      if (isObject(error)) {
        const { field } = error;

        if (isArray(field)) {
          errorTextArray = field.map(
            el => `${el.fieldName} : ${el.description}`
          );
        }
      }
    }

    return { endpoint, status: `Status code : ${status}`, errorTextArray };
  } else {
    return {
      endpoint: 'unknown',
      status: 'Status code : unknown',
      errorTextArray: []
    };
  }
}

function mapErrors({ errors, customErrorText }) {
  return errors.reduce((arr, error) => {
    return [
      ...arr,
      ...mapErrorDetail({
        detail: error.detail,
        title: error.title,
        customErrorText
      })
    ];
  }, []);
}

function mapErrorDetail({ detail, title, customErrorText }) {
  let errorsArray = [];

  if (isUndefined(detail)) {
    return isString(title) ? [title] : [];
  }

  if (isObject(detail)) {
    const { field } = detail;

    if (isArray(field)) {
      errorsArray = field.reduce((fieldArray, el) => {
        const elKeys = Object.keys(el);

        return [
          ...fieldArray,
          ...elKeys.reduce((arr, key) => {
            return [...arr, `${key}: ${el[key]}`];
          }, [])
        ];
      }, []);
    }
  }

  if (isArray(detail)) {
    errorsArray = detail.reduce((arr, el) => {
      if (isString(el)) {
        return [...arr, el];
      }

      const { detail } = el;
      if (isString(detail)) {
        return [...arr, detail];
      }

      return arr;
    }, []);
  }

  if (isString(detail)) {
    return [detail];
  }

  if (customErrorText) {
    errorsArray = [...errorsArray, customErrorText];
  }

  return errorsArray;
}
