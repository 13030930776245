import { call } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, DELETE_RANGE } from './types';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import { fetchExternalNoRanges } from './getExternalNoRanges';

export function removeRange({ id, resetValues }) {
  return {
    type: sagaActions[DELETE_RANGE],
    id,
    resetValues
  };
}

export function* deleteRange({ id, resetValues }) {
  try {
    const response = yield call(deleteData, {
      url: `${URLS.EXTERNAL_NO_RANGES}/${id}`
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.DELETE_RANGE',
        messageType: 'success'
      });

      yield call(fetchExternalNoRanges, { ...resetValues });
    }
  } catch {}
}

function* watchDeleteRange() {
  yield takeLatest(sagaActions[DELETE_RANGE], deleteRange);
}

export default watchDeleteRange();
