const styles = theme => ({
  selectRow: {
    display: 'flex',
    width: '400px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '12px',
    fontWeight: '500',
    marginBottom: '10px',
    borderRadius: '10px',
    padding: '20px 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'fit-content',
      margin: 'unset'
    }
  },
  appendixLink: {
    height: '19px',
    fontSize: '12px',
    '&:hover': { filter: 'brightness(1.5)' },
    cursor: 'pointer'
  },
  info: {
    padding: '10px',
    borderRadius: '10px',
    backgroundColor:'#FFF'
  }
});

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '10px'
  },
  control: {
    borderRadius: '4px',
    width: '250px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '14px'
  },
  indicatorSeparator: { display: 'none' }
};
