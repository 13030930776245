import React, { useMemo, useState } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import SimcardsStep from './SimcardsStep';
import AddressStep from './AddressStep';
import SummaryStep from './SummaryStep';
import CustomStepperStepIcon from '../../../partials/CustomStepperStepIcon/CustomStepperStepIcon';

export function UserStepper({ intl }) {
  const STEPS = useMemo(() => {
    return [
      intl.formatMessage({
        id: 'ORDER_SIMCARD.ORDER_STEP'
      }),
      intl.formatMessage({
        id: 'ORDER_SIMCARD.ADDRESS_STEP'
      }),
      intl.formatMessage({
        id: 'ORDER_SIMCARD.SUMMARY_STEP'
      }),
      intl.formatMessage({
        id: 'ORDER_SIMCARD.FINISH_STEP'
      })
    ];
  }, [intl]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (step = 1) => {
    return setActiveStep(prevActiveStep => prevActiveStep + step);
  };

  const handleBack = (step = 1) => {
    return setActiveStep(prevActiveStep => prevActiveStep - step);
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepperStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{getStepContent(activeStep, handleNext, handleBack)}</div>
    </>
  );
}

export default injectIntl(UserStepper);

function getStepContent(stepIndex, handleNext, handleBack) {
  switch (stepIndex) {
    case 0:
      return <SimcardsStep handleNext={handleNext} />;
    case 1:
      return <AddressStep handleNext={handleNext} handleBack={handleBack} />;
    case 2:
      return <SummaryStep handleBack={handleBack} handleNext={handleNext} />;
    case 3:
      return (
        <SummaryStep
          handleBack={handleBack}
          handleNext={handleNext}
          isLastStep={true}
        />
      );
    default:
      return 'Unknown stepIndex';
  }
}
