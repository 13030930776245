import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import Optional from '../../../utils/optional';
import HashedData from '../../HashedData';

export function DetailsReadOnly({ field, classes, fieldOptions, intl }) {
  const { value } = field;

  const style = useMemo(
    () => {
      return {
        backgroundColor: fieldOptions.background,
        width: fieldOptions.width
      };
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div
      className={classes.fieldContainer}
      style={{ width: fieldOptions.width }}
    >
      <div className={classes.fieldLabel}>
        {Optional(fieldOptions.intlKey)
          .map(key =>
            intl.formatMessage({
              id: key
            })
          )
          .or(fieldOptions.label)}
      </div>
      {fieldOptions.hashed ? (
        <HashedData style={style} data={Optional(value).or('----')} />
      ) : (
        <div className={classes.fieldValue} style={style}>
          {Optional(value).or('----')}
        </div>
      )}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DetailsReadOnly);
