const styles = theme => ({
  root: {
    width: '400px',
    minHeight: '110px',
    backgroundColor: '#fff',
    margin: '5px 5px 0 5px',
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },

  firstRow: {
    height: '40px',
    padding: '10px',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    color: '#93A2DD',
    borderBottom: '1px solid #EBEDF2'
  },
  userStatus: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 130px)'
    }
  },
  row: {
    margin: ' 0 16px 0 20px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: ' 0 10px 20px 10px'
    },
    display: 'flex'
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000',
    fontSize: '15px',
    fontWeight: 500
  },
  icon: { cursor: 'pointer', marginRight: '10px' },
  chevronIcon: {
    cursor: 'pointer',
    width: '12px',
    display: 'flex',
    alignItems: 'center'
  },
  actions: { display: 'flex', alignItems: 'center' },
  hidden: {
    visibility: 'hidden',
    [theme.breakpoints.down('xs')]: {
      visibility: 'visible'
    }
  }
});

export default styles;
