import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles';
import { setClient } from '../../../modals/editClient/actions/setClient';
import { connect } from 'react-redux';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import tempUserAction from '../actions/tempUserAction';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import history from '../../../../history';
import changeClient from '../../../utils/changeClient';

export function TableButtonsColumn({
  data: client,
  classes,
  openEditClient,
  openShowHistory,
}) {
  const onEditClick = useCallback(
    () => {
      openEditClient(client);
    },
    // eslint-disable-next-line
    [client]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: client.id,
        itemType: 'Client',
        titleText: client.name,
        itemTypeKey: 'CLIENT',
        historyObject: client
      });
    },
    // eslint-disable-next-line
    [client.id]
  );

  const onGoToClick = async () => {
    await changeClient({ client });

    history.push(`/${client.id}/dashboard`);
  };

  const onTempUserActionClick = useCallback(
    async action => {
      await tempUserAction(client.id, action);
    },
    // eslint-disable-next-line
    [client.id]
  );

  return (
    <div className={classes.actionButtons}>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.GO_TO_CLIENT"
        iconClassName="far fa-arrow-alt-circle-right"
        onClick={onGoToClick}
      />
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.EDIT_CLIENT"
        onClick={onEditClick}
        iconClassName="far fa-edit"
      />
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.SHOW_HISTORY"
        onClick={onHistoryClick}
        iconClassName="far fa-history"
      />
      {isDefined(client.connect30_domain) && (
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.CREATE_TEMP_USER"
          onClick={() => onTempUserActionClick('create')}
          iconClassName="far fa-user-headset"
        />
      )}
      {/*disabled for now as there is no easy way to check if temp user is already created
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.DELETE_TEMP_USER"
        onClick={() => onTempUserActionClick('delete')}
        iconClassName="far fa-user-slash"
      /> */}
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientsList: Optional(selectClient.selectClientsList).or([])
  };
};

const mapDispatchToProps = {
  openEditClient: setClient,
  openShowHistory: showHistoryReduxActions.openShowHistory
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableButtonsColumn);
