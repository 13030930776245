import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, LOG_USER_TO_DISTGROUP } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData } from '../../../utils/http';
import { fetchQueues } from './getQueues';
import { isDefined } from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';

const QUERY_PARAMS = {
  ignore_statistics: 'true'
};

export function logUserActions({
  checked,
  groupId,
  userMitelId,
  isAdminUser,
  otherUserId,
  connectDomain,
  isCurrentUserSelectedClientUser
}) {
  return {
    type: sagaActions[LOG_USER_TO_DISTGROUP],
    checked,
    groupId,
    userMitelId,
    isAdminUser,
    otherUserId,
    connectDomain,
    isCurrentUserSelectedClientUser
  };
}

export function* logUserToDistgroup({
  checked,
  groupId,
  userMitelId,
  isAdminUser,
  otherUserId,
  connectDomain,
  isCurrentUserSelectedClientUser
}) {
  yield put(loaderReducActions.startSpinLoader());
  try {
    const action = checked ? 'login' : 'logout';

    const response = yield call(postData, {
      url: isDefined(otherUserId)
        ? urlCreator(
            `${OUTSIDE_API.QUEUES}/${groupId}/users/${otherUserId}/${action}`,
            QUERY_PARAMS
          )
        : urlCreator(
            `${OUTSIDE_API.QUEUES}/${groupId}/users/${userMitelId}/${action}`,
            QUERY_PARAMS
          )
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      yield call(fetchQueues, {
        connectDomain,
        isCurrentUserSelectedClientUser,
        isAdminUser,
        sortQueues: false
      });
    }
  } finally {
    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchLogUserToDistgroup() {
  yield takeLatest(sagaActions[LOG_USER_TO_DISTGROUP], logUserToDistgroup);
}

export default watchLogUserToDistgroup();
