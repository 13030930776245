import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { mitelRequest } from '../../../utils/mitelRequest';
import Optional from '../../../utils/optional';
import getDiversions from './getDiversions';
import mapLinesSelectOptions from './mapLinesSelectOptions';
import mapRolesSelectOptions from './mapRolesSelectOptions';

export default async function getMitelData({
  user,
  isCurrentUserSelectedClientUser,
  connectClientId,
  isCurrentUser
}) {
  const { connect30_user_id } = user;

  const canFetch = isCurrentUserSelectedClientUser || isCurrentUser;
  const { data: mitelData } = await mitelRequest({
    options: {
      url: canFetch
        ? `${OUTSIDE_API.USERS}/${connect30_user_id}`
        : `${OUTSIDE_API.CLIENTS}/${connectClientId}/users/${connect30_user_id}`
    }
  });

  const { data: lines } = canFetch
    ? await mitelRequest({
        options: {
          url: `${OUTSIDE_API.USERS}/${user.connect30_user_id}/lines`
        }
      })
    : {};

  const { diversions, mappedDiversions } = await getDiversions(
    user.connect30_user_id,
    canFetch
  );

  const { data: mitelRoles } = canFetch
    ? await mitelRequest({
        options: {
          url: `${OUTSIDE_API.USERS}/${user.connect30_user_id}/roles`
        }
      })
    : {};

  const presenceToken = canFetch
    ? await getData(
        `${OUTSIDE_API.USERS}/${user.connect30_user_id}/presence/subscription`
      )
    : {};

  const rolesOptions = mapRolesSelectOptions(mitelRoles);
  const { linesOptions, selectedLine } = mapLinesSelectOptions(lines);

  return {
    ...user,
    mitelData: Optional(mitelData).or({}),
    linesOptions,
    selectedLine,
    rolesOptions,
    mitelRoles,
    presenceToken,
    diversions,
    mappedDiversions
  };
}
