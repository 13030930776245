import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_DETAILS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';
import urlCreator from '../../../utils/urlCreator';

export function fetchDetails({
  clientId,
  invoiceId,
  account,
  invoiceAccount,
  invoiceGrouping
}) {
  return {
    type: sagaActions[GET_DETAILS],
    clientId,
    invoiceId,
    account,
    invoiceAccount,
    invoiceGrouping
  };
}

export function* getDetails({
  clientId,
  invoiceId,
  account,
  invoiceAccount,
  invoiceGrouping = 'END'
}) {
  yield put(loaderReducActions.startLinearLoader());

  let details = null;

  const queryOptions = { groupingOption: invoiceGrouping };

  try {
    const data = yield call(
      getData,
      urlCreator(
        `${URLS.QUOTE_CLIENTS}/${clientId}/invoices/${invoiceId}/details`,
        queryOptions
      )
    );

    details = Optional(data?.billingData)
      .map(({ group }) => group)
      .or(null);
  } catch {
  } finally {
    yield put(
      reduxActions.openInvoiceDetails(
        details,
        invoiceId,
        account,
        invoiceAccount
      )
    );
    yield put(loaderReducActions.stopLinearLoader());
  }
}

function* watchGetDetails() {
  yield takeLatest(sagaActions[GET_DETAILS], getDetails);
}

export default watchGetDetails();
