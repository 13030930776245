export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  switchRow: {
    display: 'flex',
    alignItems: 'center'
  }
};
