const styles = theme => ({
  root: {
    display: 'flex',
    color: '#595D6E',
    fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 'fit-content'
    }
  },
  numbersDataContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 'fit-content'
    }
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  selectRow: {
    display: 'flex',
    width: '750px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    color: '#000',
    margin: '5px',
    fontSize: '14px',
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'fit-content',
      margin: 'unset'
    }
  },
  firstRow: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

export default styles;

export const customSelectStyles = {
  option: {
    fontSize: '10px'
  },
  control: {
    borderStyle: 'hidden',
    width: '160px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '14px'
  },
  indicatorSeparator: { display: 'none' }
};
