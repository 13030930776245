import Form from '@rjsf/core';
import React, { useEffect, useState } from 'react';
import validator from '@rjsf/validator-ajv6';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { RadioGroup, TextField } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import { ErrorBoundary } from 'react-error-boundary';

import CustomArrayFieldTemplate from '../../partials/customForm/components/CustomArrayFieldTemplate';
import withSettings from '../../utils/withSettings';
import FormDataEditor from './components/FormDataEditor';
import SchemaEditor from './components/SchemaEditor';
import styles, { customSelectStyles } from './styles';
import CustomFormControlLabel from '../../partials/customFormControlLabel';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import {
  doesArrayHasLength,
  isDefined,
  isObjectEmpty,
  isStringDefined,
  isStringEmpty,
  isUndefined
} from '../../utils/isDefined';
import getTemplates from './actions/getTempaltes';
import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import Optional from '../../utils/optional';
import ConfirmDialog from '../../partials/confirmationDialog/ConfirmDialog';
import editTemplate from './actions/editTemplate';

function EditSchema({ settings, classes, intl }) {
  const [schema, setSchema] = useState({});
  const [selected, setSelected] = useState('create');
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [formData, setFormData] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [templateName, setTemplateName] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async function() {
      const response = await getTemplates();

      if (doesArrayHasLength(response)) {
        setTemplates(response);
        setTemplate(response[0]);
      } else {
        setTemplates(void 0);
      }
    })();
  }, []);

  const onRadioButtonChange = e => {
    if (e.target.value === 'create') {
      setFormData({});
      setSchema({});
      setTemplateName('');
    } else {
      setTemplateName(Optional(template?.name).or(''));
      setUiSchema(Optional(template.ui_schema).or({}));
    }
    setSelected(e.target.value);
  };

  const handleChange = choosen => {
    setFormData({});
    setSchema({});
    setTemplate(choosen);
    setTemplateName(Optional(choosen?.name).or(''));
    setUiSchema(Optional(choosen.ui_schema).or({}));
  };

  const handleNameChange = event => {
    setTemplateName(event.target.value);
  };

  const onSubmit = async () => {
    if (isObjectEmpty(schema)) {
      return;
    }

    const response = await editTemplate({
      name: templateName,
      config: schema,
      selected,
      uiSchema
    });

    if (response) {
      const response = await getTemplates();

      if (doesArrayHasLength(response)) {
        setTemplates(response);
        if (selected === 'create') {
          setTemplate(response[0]);
          setFormData({});
          setSchema({});
        } else {
          const newTemplate = Optional(
            response.find(el => el.id === template.id)
          ).or(response[0]);
          setTemplate(newTemplate);
        }
      } else {
        setTemplates(void 0);
      }
    }
  };

  const onSchemaChange = newSchema => {
    setSchema(newSchema);
  };

  return (
    <div className={classes.root} style={{ color: settings.colors.primary }}>
      <div>
        <div className={classes.radioRow}>
          {selected === 'create' || template.name === templateName ? (
            <PrimaryButton
              onClick={onSubmit}
              disabled={
                isError || isStringEmpty(templateName) || isObjectEmpty(schema)
              }
            >
              {intl.formatMessage({
                id: 'BUTTON.SAVE'
              })}
            </PrimaryButton>
          ) : (
            <ConfirmDialog
              dialogTextIntlId="EDIT_TEMPLATE.CONFIRMATION_TEXT"
              onClick={onSubmit}
            >
              <PrimaryButton
                disabled={
                  isError ||
                  isStringEmpty(templateName) ||
                  isObjectEmpty(schema)
                }
              >
                {intl.formatMessage({
                  id: 'BUTTON.SAVE'
                })}
              </PrimaryButton>
            </ConfirmDialog>
          )}
          <RadioGroup
            row
            className={classes.radioButtons}
            value={selected}
            onChange={onRadioButtonChange}
          >
            <CustomFormControlLabel
              value="create"
              label={intl.formatMessage({
                id: 'EDIT.CREATE'
              })}
            />
            <CustomFormControlLabel
              value="edit"
              label={intl.formatMessage({
                id: 'EDIT.EDIT'
              })}
            />
          </RadioGroup>
          {selected === 'edit' && isDefined(templates) && (
            <Select
              customStyles={customSelectStyles}
              options={templates}
              styles={reactSelectDefaultStyles}
              value={template}
              onChange={handleChange}
            />
          )}
          {selected === 'edit' &&
            isUndefined(templates) &&
            intl.formatMessage({
              id: 'CLIENT.NO_TEMPLATE'
            })}
        </div>
        <div className={classes.nameContainer}>
          <div className={classes.nameLabel}>Template name</div>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Enter template name"
            classes={{ root: classes.textfield }}
            value={templateName}
            onChange={handleNameChange}
            inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
          />
        </div>
        <SchemaEditor
          onSchemaChange={onSchemaChange}
          selected={selected}
          template={template?.config}
          setIsError={setIsError}
        />
        <div className={classes.boxRow}>
          <FormDataEditor
            formData={uiSchema}
            label="UISchema"
            readOnly={false}
            onChange={schema => {
              return isStringDefined(schema)
                ? setUiSchema(JSON.parse(schema))
                : {};
            }}
          />
          <FormDataEditor
            formData={formData}
            label="formData"
            onChange={setFormData}
          />
        </div>
      </div>
      <div className={classes.form}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => setSchema(schema)}
        >
          <Form
            uiSchema={uiSchema}
            schema={schema}
            formData={formData}
            validator={validator}
            templates={{
              ArrayFieldTemplate: CustomArrayFieldTemplate
            }}
            onChange={e => setFormData(e.formData)}
          >
            <></>
          </Form>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(EditSchema);

function ErrorFallback({ error }) {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>Please reload the page.</p>
    </div>
  );
}
