import React, { useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { CSVLink } from 'react-csv';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import getCsvData from './actions/getCsvData';
import getIdFromUrl from '../../utils/getIdfromUrl';
import styles from './styles';
import { connect } from 'react-redux';
import { isDefined } from '../../utils/isDefined';
import withSettings from '../../utils/withSettings';
import { Tooltip } from '@material-ui/core';
import Optional from '../../utils/optional';

export function DownloadCsv({
  classes,
  name,
  location,
  clientName,
  requestUrl,
  queryParams,
  headers,
  isCsvEndpoint,
  data,
  isLinkIcon = false,
  settings,
  tooltipTitle
}) {
  const [csvData, setCsvData] = useState([]);
  const client = useMemo(() => {
    return getIdFromUrl(location);
    // eslint-disable-next-line
  }, [location]);

  const fileName = useMemo(
    () => `${name}${isDefined(client) ? `-${clientName}` : ''}.csv`,
    [name, clientName, client]
  );

  const csvLink = useRef();

  const onClick = async () => {
    const dataForCsv = await getCsvData({
      queryParams,
      requestUrl,
      name,
      isCsvEndpoint,
      data
    });

    setCsvData(dataForCsv);
    csvLink.current.link.click();
  };

  return (
    <div>
      <Tooltip title={Optional(tooltipTitle).or('')}>
        <i
          onClick={onClick}
          className={clsx('fas fa-download', classes.root, {
            [classes.linkIcon]: isLinkIcon
          })}
          style={{ color: isLinkIcon ? settings.colors.links : 'inherit' }}
        />
      </Tooltip>
      <CSVLink
        data={csvData}
        headers={headers}
        filename={fileName}
        className={classes.link}
        ref={csvLink}
        separator={';'}
        target="_blank"
      />
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientName: selectClient.selectedClient.name
  };
};

export default flowRight(
  withRouter,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(DownloadCsv);
