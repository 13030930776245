import { isUndefined } from './isDefined';

export default function mapUsersAfterLock({ updatedUser = {}, users = [] }) {
  const { locked, locked_at } = updatedUser;

  if (isUndefined(locked)) {
    return users;
  }

  return users.map(user => {
    if (user.id !== updatedUser.id) {
      return user;
    }

    return { ...user, locked, locked_at };
  });
}
