import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';

export function SimcardMenu({ children, intl, simcard = {}, openEditSimcard }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = useCallback(
    () => {
      openEditSimcard({
        simcard,
        clientId: simcard.client_id,
        fromUserView: true
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [simcard]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleEditClick}>
          {intl.formatMessage({
            id: 'TOOLTIP.EDIT_SIMCARD'
          })}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(null, mapDispatchToProps)
)(SimcardMenu);
