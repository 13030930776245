const styles = theme => {
  return {
    dialogContent: {
      paddingBottom: '10px',
      overflow: 'visible',
      minWidth: '700px'
    }
  };
};

export default styles;

export const messageCellStyles = {
  width: '350px',
  whiteSpace: 'normal',
  wordWrap: 'break-word'
};
