import { translate } from '../../../i18n/I18nProvider';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';
import { formatDateForSchedule } from '../../../utils/formatDate';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import { findSkuTranslation } from '../../subscriptions/actions/customSearchActions';

const tableColumns = ({ lang, showAll }) => {
  const columns = [
    {
      title: 'TABLE.NAME',
      field: 'name'
    },
    {
      title: capitaliseFirstLetter(translate('SUBSCRIPTIONS.GROUP_BY_SKU')),
      untranslatedTitle: 'true',
      field: 'skuTranslations',
      render: rowData => {
        return findSkuTranslation({ rowData, lang });
      },
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(
          findSkuTranslation({ rowData, lang }),
          term
        );
      },
      exportTransformer: rowData => findSkuTranslation({ rowData, lang })
    },
    {
      title: 'TABLE.DESCRIPTION',
      field: 'description'
    },
    {
      title: 'STATUS',
      field: 'status'
    },
    {
      title: 'ACCOUNT',
      field: 'accountName',
      hideWhenAccountSelected: true
    },
    {
      title: 'ACTIVATION_DATE',
      field: 'activationDate',
      render: rowData => {
        return Optional(formatDateForSchedule(rowData.activationDate)).or('-');
      }
    },
    {
      title: 'EXPIRE_DATE',
      field: 'expiryDate',
      render: rowData => {
        return Optional(formatDateForSchedule(rowData.expiryDate)).or('-');
      }
    }
  ];

  let mappedColumns = [...columns];

  if (!showAll) {
    mappedColumns = mappedColumns.filter(column =>
      isUndefined(column.hideWhenAccountSelected)
    );
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;
