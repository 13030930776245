import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, DELETE_USER } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { deleteData } from '../../../utils/http';
import { getUsers } from './getUsers';
import urlCreator from '../../../utils/urlCreator';
import { actions as reduxAction } from '../reducers';

export function removeUser({ userId, clientId, isBeTable }) {
  return {
    type: sagaActions[DELETE_USER],
    userId,
    clientId,
    isBeTable
  };
}

export function* deleteUser({ userId, clientId, isBeTable }) {
  const QUERY_PARAMS = {
    filter: { client_id: clientId }
  };
  const response = yield call(deleteData, {
    url: urlCreator(`${URLS.USERS}/${userId}`, QUERY_PARAMS)
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.DELETE.USER',
      messageType: 'success'
    });

    isBeTable
      ? yield put(reduxAction.setRefresh(true))
      : yield put(getUsers(clientId));
  }
}

function* watchDeleteUser() {
  yield takeLatest(sagaActions[DELETE_USER], deleteUser);
}

export default watchDeleteUser();
