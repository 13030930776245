import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_QUEUE = 'SET_QUEUE';
const RESET_QUEUE_STATS = 'RESET_QUEUE_STATS';

export const actionTypes = createReduxActionTypes('QUEUE_STATS', [
  SET_QUEUE,
  RESET_QUEUE_STATS
]);

const DEFAULT_STATE = {
  open: false,
  queue: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_QUEUE]: {
      const { queue } = action;
      return {
        ...state,
        queue,
        open: true
      };
    }
    case actionTypes[RESET_QUEUE_STATS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openQueueStats: queue => ({
    type: actionTypes[SET_QUEUE],
    queue
  }),
  resetQueueStats: () => ({ type: actionTypes[RESET_QUEUE_STATS] })
};
