import changeObjectKeysCamelToUnderscore from '../../../utils/changeObjectKeysCamelToUnderscore';
import {
  formatDateForSchedule,
  formatTimeForSchedule
} from '../../../utils/formatDate';

const mapScheduleForUpdate = ({ schedule }) => {
  const {
    duration,
    endDateSet,
    promptText,
    startDateSet,
    reccurence_0,
    reccurence_1,
    reccurence_2,
    reccurence_3,
    reccurence_4,
    reccurence_5,
    reccurence_6,
    ...restSchedule
  } = schedule;

  const recurrence = [
    reccurence_0,
    reccurence_1,
    reccurence_2,
    reccurence_3,
    reccurence_4,
    reccurence_5,
    reccurence_6
  ].map(el => (el ? 1 : 0));

  const {
    disable_recording_prompts,
    end_date,
    end_time,
    forward_number,
    hangup,
    name,
    prompt,
    reccurence_end_date,
    reccurence_end_type,
    recurrence_type,
    start_date,
    start_time,
    type
  } = changeObjectKeysCamelToUnderscore(restSchedule);

  return {
    name,
    action: {
      disable_recording_prompts,
      forward_number,
      hangup,
      prompt,
      type
    },
    limits: {
      start: {
        date: formatDateForSchedule(start_date),
        time: formatTimeForSchedule(start_time)
      },
      end: {
        date: formatDateForSchedule(end_date),
        time: formatTimeForSchedule(end_time)
      },
      recurrence,
      recurrence_type,
      recurrence_end:
        reccurence_end_type === 'DATE'
          ? {
              type: 'Date',
              date: formatDateForSchedule(reccurence_end_date)
            }
          : null
    }
  };
};

export default mapScheduleForUpdate;
