const styles = theme => {
  return {
    root: {},
    dialogContent: {
      overflow: 'visible'
    },
    loaderOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      '& button': {
        margin: '10px'
      }
    },
    editContainer: {
      margin: '20px 0',
      minHeight: '140px'
    },
    datarowRoot: {
      width: '480px',
      padding: '10px 0',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    dataRowLabel: { width: '190px' },
    datarowValue: { fontWeight: 600, width: '290px' },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '5px'
    },
    label: { width: '190px' },
    info: { marginTop: '15px' }
  };
};

export default styles;

export const customSubSelectStyles = {
  option: {
    fontSize: '12px'
  },
  control: {
    width: '290px'
  },
  menu: {
    width: '290px'
  }
};
