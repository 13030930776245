const styles = theme => {
  return {
    root: {
      minWidth: '100px',
      borderRadius: '5px',
      '&:hover': { backgroundColor: theme.palette.orange.medium }
    }
  };
};

export default styles;
