import { flowRight } from 'lodash';
import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import capitaliseFirstLetter from '../../utils/capitaliseFirstLetter';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import styles from './styles';

function PresenceRow({
  settings,
  classes,
  presence,
  contact = {},
  intl,
  activities = []
}) {
  const mapName = id => {
    const activity = activities.find(el => el.id === id);

    return Optional(activity?.name).or(id);
  };

  return (
    <div className={classes.presenceRow}>
      <div
        className={classes.presence}
        style={{
          color:
            presence && !contact.incall
              ? settings.colors.success
              : settings.colors.danger
        }}
      >
        {contact.incall
          ? intl.formatMessage({ id: 'IN_CALL' })
          : capitaliseFirstLetter(
              Optional(contact.presence?.activity?.id)
                .map(id => mapName(id))
                .or('')
            )}
      </div>
      {Optional(contact.presence?.activity?.expiration)
        .map(
          expiration =>
            expiration !== 'never' &&
            expiration !== '' && (
              <Tooltip
                placement="bottom"
                title={moment(expiration).format('YYYY-MM-DD HH:mm')}
              >
                <i
                  className="far fa-clock"
                  style={{ color: settings.colors.primary }}
                ></i>
              </Tooltip>
            )
        )
        .or('')}
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(PresenceRow);
