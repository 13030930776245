export default {
  usersRoot: {
    width: '100%',
    fontSize: '13px',
    color: '#212529',
    backgroundColor: '#FDFDFD',
    '& tr:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7'
    },
    '& tr': {
      '&:hover': { backgroundColor: '#EFEFEF' }
    }
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px'
  },
  userContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px'
  },
  userData: { display: 'inherit', alignItems: 'center' },
  userName: { width: '190px', margin: '0 5px 0 30px' }
};
