import { createSagaActionTypes } from '../../../utils/actionFactory';

export const SET_CLIENT = 'SET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const SYNC_ACTION = 'SYNC_ACTION';

export const actions = createSagaActionTypes('EDIT_CLIENT', [
  SET_CLIENT,
  UPDATE_CLIENT,
  SYNC_ACTION
]);
