import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_ACCOUNTS = 'SET_ACCOUNTS';
const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('ACCOUNTS', [
  SET_ACCOUNTS,
  RESET_ACCOUNTS,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  accounts: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ACCOUNTS]: {
      return { ...state, accounts: [...action.accounts] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_ACCOUNTS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setAccounts: accounts => ({
    type: actionTypes[SET_ACCOUNTS],
    accounts
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetAccounts: () => ({ type: actionTypes[RESET_ACCOUNTS] })
};
