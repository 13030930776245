import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { Formik, Form } from 'formik';

import { actions as reduxActions } from './reducers';
import styles, { customStyles } from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import InputRow from '../../partials/dataRows/InputRow';
import ActionsButtons from './components/ActionsButtons';
import { PERMISSION_CREATE } from '../../consts/initialValues';
import validateSchema from './actions/validateSchema';
import { addNewPermission } from './actions/addPermission';
import CheckboxRow from '../../partials/dataRows/CheckboxRow';

export function AddPermission({
  classes,
  open,
  closeAddPermission,
  intl,
  addNewPermission,
  isEdit,
  permission
}) {
  const onSubmit = useCallback(
    values => {
      addNewPermission({ values, isEdit, id: permission.id });
    },
    // eslint-disable-next-line
    [isEdit]
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeAddPermission();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeAddPermission}>
        {intl.formatMessage({
          id: isEdit ? 'TOOLTIP.EDIT_PERMISSION' : 'PERMISSIONS.ADD_PERMISSION'
        })}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={
            isEdit
              ? permission
              : {
                  name: '',
                  conditions: '',
                  description: '',
                  remote: false
                }
          }
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            dirty
          }) => {
            const fieldProps = { errors, values, handleBlur, handleChange };
            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                <InputRow
                  label="NAME"
                  name="name"
                  customStyles={customStyles.inputRow}
                  disabled={isEdit}
                  {...fieldProps}
                />
                <div className={classes.infoBox}>
                  <div className={classes.infoLabel}>
                    Permission name format
                  </div>
                  <div className={classes.infoContainer}>
                    {PERMISSION_CREATE.map((el, i) => (
                      <div key={i}> {el}</div>
                    ))}
                  </div>
                </div>
                <CheckboxRow
                  name="remote"
                  label="TABLE.REMOTE"
                  customStyles={customStyles.checkbox}
                  {...fieldProps}
                />
                <InputRow
                  label="DESCRIPTION"
                  name="description"
                  customStyles={customStyles.inputRow}
                  {...fieldProps}
                />
                <InputRow
                  label="TABLE.CONDITIONS"
                  name="conditions"
                  customStyles={customStyles.inputRow}
                  {...fieldProps}
                />

                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeAddPermission}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ addPermission }) => {
  return {
    ...addPermission
  };
};

const mapDispatchToProps = {
  closeAddPermission: reduxActions.closeAddPermission,
  addNewPermission
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddPermission);
