import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import styles from '../styles/requestForm';
import BasicData from './BasicData';
import NumbersData from './NumbersData';
import NotesSection from './NotesSection';
import ActionsButtons from './ActionsButtons';
import { newUserRequest } from '../actions/requestNewUser';
import { actions as reduxActions } from '../reducers';
import validateSchema from '../actions/validateSchema';

const INITIAL_VALUES = {
  name: '',
  username: '',
  email: '',
  department: '',
  landline_number: '',
  internal_number: '',
  mobile_number: '',
  subscription: '',
  activation_date: '',
  has_sim_card: '',
  call_recording: false,
  voice_mail: false,
  notes: ''
};

export function RequestForm({
  classes,
  resetRequestUserDialog,
  newUserRequest,
  intl
}) {
  const onSubmit = useCallback(
    values => {
      newUserRequest(values);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl)}
    >
      {({ handleSubmit, dirty, errors, values, touched, initialValues }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={classes.dataContainer}>
              <Field component={BasicData} />
              <Field component={NumbersData} />
            </div>
            <Field component={NotesSection} />
            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={resetRequestUserDialog}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapDispatchToProps = {
  ...reduxActions,
  newUserRequest
};

export default flowRight(
  injectIntl,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(RequestForm);
