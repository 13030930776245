import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';

import styles from '../styles/permissions';
import { isDefined } from '../../../utils/isDefined';
import CustomSearch from './CustomSearch';

export function Permissions({
  intl,
  classes,
  arrayHelpers: {
    form: {
      values: { permissions = [] }
    },
    remove,
    push
  },
  options
}) {
  const [allSearchTerm, setAllSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const availableOptions = useMemo(
    () => [...mapOptions({ options, permissions, allSearchTerm })],
    // eslint-disable-next-line
    [options, permissions, allSearchTerm]
  );

  return (
    <div className={classes.groupsRoot}>
      <div className={classes.groupColumn}>
        <div className={classes.label}>
          {intl.formatMessage({
            id: 'ROLES.ALL_PERMISSIONS'
          })}
        </div>
        <CustomSearch term={allSearchTerm} setFunction={setAllSearchTerm} />
        <div
          className={clsx(
            classes.permissionsContainer,
            classes.groupsContainer
          )}
        >
          {availableOptions
            .filter(permission => !permission.isUserPermission)
            .map(permission => (
              <div className={classes.permissionContainer} key={permission.id}>
                {permission.name}
                <i
                  className={clsx('far fa-chevron-double-right', classes.icon)}
                  onClick={() => push(permission)}
                ></i>
              </div>
            ))}
        </div>
      </div>
      <div className={classes.groupColumn}>
        <div className={classes.label}>
          {intl.formatMessage({
            id: 'ROLES.ROLE_PERMISSIONS'
          })}
        </div>
        <CustomSearch term={searchTerm} setFunction={setSearchTerm} />
        <div
          className={clsx(
            classes.permissionsContainer,
            classes.groupsContainer
          )}
        >
          {permissions.map(
            (permission, i) =>
              permission.name
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase().trim()) !== -1 && (
                <div
                  className={classes.permissionContainer}
                  key={permission.id}
                >
                  {permission.name}
                  <i
                    className={clsx('far fa-times-circle', classes.icon)}
                    onClick={() => remove(i)}
                  ></i>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Permissions);

function mapOptions({ options, permissions, allSearchTerm }) {
  return options
    .map(option => {
      const isUserPermission = permissions.find(
        permission => permission.name === option.name
      );
      return { ...option, isUserPermission: isDefined(isUserPermission) };
    })
    .filter(
      option =>
        option.name
          .toLowerCase()
          .indexOf(allSearchTerm.toLowerCase().trim()) !== -1
    );
}
