import { TextField, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import withSettings from '../../../utils/withSettings';
import { fetchUsersContacts } from '../actions/getUserContacts';
import styles from '../styles/searchBar';
import { actions as addContactReduxActions } from '../../../modals/addExternalContact/reducers';

function SearchBar({
  intl,
  fetchUsersContacts,
  classes,
  settings,
  openAddContacts
}) {
  const [term, setTerm] = useState('');

  const handleSearch = useCallback(() => {
    fetchUsersContacts({
      search: term
    });
    // eslint-disable-next-line
  }, [term]);

  const handleChange = event => {
    setTerm(event.target.value);
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const onAddClick = useCallback(() => {
    openAddContacts({});
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        margin="dense"
        onChange={handleChange}
        value={term}
        onKeyPress={onKeyPress}
        inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
      />
      <PrimaryButton onClick={handleSearch}>
        {intl.formatMessage({
          id: 'BUTTON.SEARCH'
        })}
      </PrimaryButton>
      <Tooltip
        title={intl.formatMessage({
          id: 'ADD_EXTERNAL_CONTACT.TITLE'
        })}
      >
        <i
          onClick={onAddClick}
          className={clsx('fas fa-user-plus', classes.addIcon)}
          style={{ color: settings.colors.primary }}
        ></i>
      </Tooltip>
    </div>
  );
}

const mapDispatchToProps = {
  fetchUsersContacts,
  openAddContacts: addContactReduxActions.openAddContact
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(SearchBar);
