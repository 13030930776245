import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_TELEPHONY_USER } from './types';
import { fetchUsers } from '../../../pages/telephonyUsers/actions/getUsers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import showSnackbar from '../../../utils/showSnackbar';
import { actions as groupsReduxActions } from '../../../pages/groups/reducers';

export function updateTelephonyUserOnSubmit({
  userData,
  clientConnectId,
  clientId,
  mitelUserId,
  values,
  isGeneralData,
  fromUsersTable,
  fromGroupsTable
}) {
  return {
    type: sagaActions[UPDATE_TELEPHONY_USER],
    userData,
    clientConnectId,
    clientId,
    mitelUserId,
    values,
    isGeneralData,
    fromUsersTable,
    fromGroupsTable
  };
}

export function* updateTelephonyUser({
  userData,
  clientConnectId,
  clientId,
  mitelUserId,
  values,
  isGeneralData,
  fromUsersTable = true,
  fromGroupsTable = false
}) {
  try {
    const response = yield call(updateData, {
      url: `${OUTSIDE_API.CLIENTS}/${clientConnectId}/users/${mitelUserId}`,
      data: { user: { ...userData } }
    });

    if (response.status === RESPONSE_CODES.NO_CONTENT) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.UPDATE.USER',
        messageType: 'success'
      });
    }

    if (fromUsersTable) {
      yield call(fetchUsers, { connectClientId: clientConnectId, clientId });
    }

    if (fromGroupsTable) {
      yield put(groupsReduxActions.resetGroupUsers());
    }

    yield put(reduxActions.updateUser(values, isGeneralData));
  } catch {}
}

function* watchUpdateTelephonyUser() {
  yield takeLatest(sagaActions[UPDATE_TELEPHONY_USER], updateTelephonyUser);
}

export default watchUpdateTelephonyUser();
