import React, { useMemo, useCallback } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import Optional from '../../utils/optional';
import {
  findFirstAvailableDay,
  checkNonWorkingDays
} from '../../utils/dateUtils';
import withSettings from '../../utils/withSettings';
import { materialDatePickerTheme } from '../../modals/editSchedule/styles/datePickers';

const DANISH_COUNTRY_CODE = 'DK';

export function DatePickerRow({
  classes,
  name,
  label = 'EMPTY',
  labelText,
  showLabel = true,
  handleBlur,
  setFieldValue,
  values = {},
  errors = {},
  intl,
  customStyles = {},
  disabled = false,
  daysAhead = 0,
  settings,
  infoIcon = false,
  infoIconTooltipIntlKey = 'EMPTY',
  mobile = false,
  disablePast = true,
  showOnlyAvailable = true,
  isRequired = false,
  format = 'dd/MM/yyyy',
  placeholder = 'dd/MM/yyyy',
  key = 'name',
  disableWeekends = true
}) {
  const firstAvailableDay = useMemo(
    () => findFirstAvailableDay(daysAhead, DANISH_COUNTRY_CODE),
    [daysAhead]
  );

  const title = useMemo(
    () =>
      Optional(labelText).or(
        intl.formatMessage({
          id: label
        })
      ),
    // eslint-disable-next-line
    [labelText, label]
  );

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }

  const handleChange = useCallback(
    selected => {
      setFieldValue(name, selected);
    },
    // eslint-disable-next-line
    []
  );

  const errorMessage = Optional(errors[name]).or(null);

  const materialDateTheme = useMemo(() => {
    return materialDatePickerTheme(settings);
  }, [settings]);

  return (
    <div className={classes.root} style={customStyles.root} key={key}>
      <div className={classes.field}>
        <div
          className={clsx(classes.label, {
            [classes.mobileLabel]: mobile
          })}
          style={customStyles.label}
        >
          {showLabel && title}
          {infoIcon && (
            <Tooltip
              title={intl.formatMessage({
                id: infoIconTooltipIntlKey
              })}
            >
              <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
            </Tooltip>
          )}
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <ThemeProvider theme={materialDateTheme}>
            <div className={classes.valueContainer}>
              <div className={classes.requiredIcon}>
                {isRequired ? '*' : ''}
              </div>
              <KeyboardDatePicker
                inputVariant="outlined"
                margin="dense"
                className={classes.fieldValue}
                name={name}
                value={Optional(values[name]).or(null)}
                onChange={handleChange}
                disabled={disabled}
                disablePast={disablePast}
                autoOk={true}
                format={format}
                placeholder={placeholder}
                minDate={showOnlyAvailable ? firstAvailableDay : new Date(0)}
                InputProps={{ readOnly: true }}
                showTodayButton
                shouldDisableDate={date =>
                  checkNonWorkingDays({
                    date,
                    countryCode: DANISH_COUNTRY_CODE,
                    disableWeekends
                  })
                }
              />
            </div>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.errorMessage}>{errorMessage}</div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withSettings,
  withStyles(styles)
)(DatePickerRow);
