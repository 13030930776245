import { JSONAPI_URLS } from '../../../consts/endpoints';
import store from '../../../store/store';
import { postData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';
import { actions as loginReduxActions } from '../../auth/reducers';

const refreshUser = async () => {
  const { data } = await postData({
    url: JSONAPI_URLS.LOGIN,
    data: {}
  });

  if (isDefined(data?.data)) {
    store.dispatch(loginReduxActions.reloadUser(data.data));
  }
};

export default refreshUser;
