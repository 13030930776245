export default function getSelectValuefromOptions(options = [], value) {
  return options
    .map(option => {
      if (option.options) {
        return [...option.options];
      } else {
        return option;
      }
    })
    .flat()
    .find(option => option.value === value);
}
