import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const SET_ACCOUNTS = 'SET_ACCOUNTS';
const RESET = 'RESET';
const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
const UPDATE_VALUE = 'UPDATE_VALUE';

export const actionTypes = createReduxActionTypes('SUBSCRIPTIONS', [
  SET_ACCOUNTS,
  SET_SELECTED_ACCOUNT,
  RESET,
  UPDATE_VALUE,
  SET_SUBSCRIPTIONS
]);

const DEFAULT_STATE = {
  accounts: [],
  selectOptions: [],
  selectedAccount: {},
  expandAll: false,
  groupByCategory: false,
  subscriptions: { groupedBySku: [], groupedByCategory: [] },
  filterBy: 'ACTIVE'
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_ACCOUNTS]: {
      const { accounts, selectOptions, selectedAccount } = action;
      return { ...state, accounts, selectOptions, selectedAccount };
    }
    case actionTypes[SET_SELECTED_ACCOUNT]: {
      const { selectedAccount } = action;
      return {
        ...state,
        selectedAccount
      };
    }
    case actionTypes[SET_SUBSCRIPTIONS]: {
      const { subscriptions, expandAll } = action;
      return {
        ...state,
        subscriptions,
        expandAll: Optional(expandAll).or(state.expandAll)
      };
    }
    case actionTypes[UPDATE_VALUE]: {
      const { key, value } = action;
      return {
        ...state,
        [key]: value
      };
    }
    case actionTypes[RESET]: {
      return DEFAULT_STATE;
    }
    default:
      return state;
  }
};

export const actions = {
  setAccounts: (accounts, selectOptions, selectedAccount) => ({
    type: actionTypes[SET_ACCOUNTS],
    accounts,
    selectOptions,
    selectedAccount
  }),
  setSelectedAccount: selectedAccount => ({
    type: actionTypes[SET_SELECTED_ACCOUNT],
    selectedAccount
  }),
  setSubscriptions: (subscriptions, expandAll) => ({
    type: actionTypes[SET_SUBSCRIPTIONS],
    subscriptions,
    expandAll
  }),
  updateValue: (key, value) => ({
    type: actionTypes[UPDATE_VALUE],
    key,
    value
  }),
  resetSubscriptions: () => ({
    type: actionTypes[RESET]
  })
};
