import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/features';
import withSettings from '../../../utils/withSettings';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import DataRow from '../../../partials/Datarow/DataRow';
import StatusCell from '../../../partials/statusCell/StatusCell';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';

const Features = ({ features = [], classes, settings }) => {
  const mappedFeatures = mapFeatures(features);

  const customClasses = useMemo(() => {
    return {
      dataRow: {
        root: classes.dataRow_root,
        value: classes.dataRow_value
      }
    };
  }, [classes]);

  return (
    <div className={classes.root}>
      {mappedFeatures.map(feature => (
        <DataRow
          key={feature.label}
          labelText={feature.label}
          value={
            feature.isBoolean ? (
              <StatusCell
                isTable={false}
                {...pickOptionsForStatusCell(
                  STATUS_BOX_OPTIONS.ENABLED,
                  feature.value
                )}
              />
            ) : (
              feature.value
            )
          }
          customClasses={customClasses.dataRow}
          dense
        />
      ))}
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(Features);

export const mapFeatures = features => {
  return features.reduce((arr, feature) => {
    switch (feature.key) {
      case 'watch-esim': {
        if (isUndefined(feature?.featureAttributes)) {
          return arr;
        }

        const attributes = feature?.featureAttributes;

        const enabledObject = attributes.find(attr => attr.key === 'enabled');

        if (isUndefined(enabledObject)) {
          return arr;
        }

        return [
          ...arr,
          {
            label: feature.name,
            value: checkValue(enabledObject.value),
            isBoolean: true
          }
        ];
      }
      case 'addons': {
        let addons = [];
        if (isUndefined(feature?.featureAttributes)) {
          return arr;
        }

        const attributes = feature?.featureAttributes;

        const usaObject = attributes.find(attr => attr.key === 'EU-USA');

        if (isDefined(usaObject)) {
          addons = [
            ...addons,
            {
              label: usaObject.name,
              value: checkValue(usaObject.value),
              isBoolean: true
            }
          ];
        }

        const travelObject = attributes.find(
          attr => attr.key === 'travel-data'
        );

        if (isDefined(travelObject)) {
          let value = checkValue(travelObject.value);

          if (isDefined(travelObject?.configuration?.radioOptions)) {
            const foundOption = travelObject?.configuration?.radioOptions.find(
              option => option.value === travelObject.value
            );

            if (isDefined(foundOption)) {
              value = foundOption.label;
            }
          }

          addons = [
            ...addons,
            {
              label: travelObject.name,
              value
            }
          ];
        }

        const extraSimObject = attributes.find(
            attr => attr.key === 'EXTRASIM'
          );
  
          if (isDefined(extraSimObject)) {
            let value = checkValue(extraSimObject.value);
  
            if (isDefined(extraSimObject?.configuration?.radioOptions)) {
              const foundOption = extraSimObject?.configuration?.radioOptions.find(
                option => option.value === extraSimObject.value
              );
  
              if (isDefined(foundOption)) {
                value = foundOption.label;
              }
            }
  
            addons = [
              ...addons,
              {
                label: extraSimObject.name,
                value
              }
            ];
          }

        return [...arr, ...addons];
      }
      default:
        return arr;
    }
  }, []);
};

const checkValue = value => {
  if (isUndefined(value) || value === false || value === 'DISABLED_OPTION') {
    return false;
  }

  return true;
};
