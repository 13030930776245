export default {
  autocompleteContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '46px',
    '& input': {
      width: '100%',
      boxSizing: 'border-box',
      padding: '5px'
    }
  },
  hidden: {
    visibility: 'hidden'
  }
};
