import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import getGroupUsers from '../actions/getGroupUsers';
import styles from '../styles/groupUsers';
import withSettings from '../../../utils/withSettings';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import createSubject from '../../../utils/createSubject';
import can from '../../../utils/can';
import TableNameCell from '../../../partials/tableNameCell/TableNameCell';
import { openEditUser } from '../actions/openEdit';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';

function GroupUsers({
  name,
  connectClientId,
  classes,
  settings,
  selectedClientId,
  openEditUser,
  intl,
  reset
}) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function() {
      if (isDefined(name)) {
        const users = await getGroupUsers({
          name,
          connectClientId,
          setIsLoading
        });
        setUsers(users);
      }
    })();
    // eslint-disable-next-line
  }, [connectClientId, reset]);

  const onEditClick = user => {
    openEditUser({
      userId: user.userId,
      clientConnectId: connectClientId
    });
  };

  return isLoading ? (
    <div className={classes.loaderContainer}>
      <CircularProgress style={{ color: settings.colors.primary }} />
    </div>
  ) : doesArrayHasLength(users) ? (
    <table className={classes.usersRoot}>
      <tbody>
        {users.map(user => (
          <tr key={user.userId} className={classes.userContainer}>
            {can(
              'update',
              createSubject('Mitel::UserGroup', {
                client_id: Number(selectedClientId)
              })
            ) && (
              <td>
                <CustomIconButton
                  iconTooltipIntlKey="TOOLTIP.EDIT_USER"
                  onClick={() => onEditClick(user)}
                  iconClassName="far fa-edit"
                />
              </td>
            )}
            <td className={classes.userName}>
              <TableNameCell
                data={[{ name: user.name, user_id: user.userId }]}
                from="groups"
                clientId={selectedClientId}
              />
            </td>
            <td className={classes.userData}>
              <div>
                <CopyToClipbaordTooltip value={user.primaryNumber}>
                  {user.primaryNumber}
                </CopyToClipbaordTooltip>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className={classes.loaderContainer}>
      {intl.formatMessage({ id: 'GROUPS.NO_USERS' })}
    </div>
  );
}

const mapStatesToProps = ({ selectClient, groups }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain,
    selectedClientId: selectClient.selectedClient.id,
    reset: groups.reset
  };
};

const mapDispatchToProps = {
  openEditUser
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withSettings,
  withStyles(styles)
)(GroupUsers);
