const styles = theme => {
  return {
    root: { width: '500px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'unset'
    },
    infoContainer: { margin: '10px', fontWeight: 600 },
    newAddressContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '20px'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '30px 0 10px',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;

export const customStyles = {
  select: {
    root: { width: '100%', marginRight: 0 },
    value: { width: '300px' }
  },
  input: {
    root: { width: '100%' },
    textfield: { width: '300px' }
  }
};
