import { withStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';

import GeneralData from './GeneralData';
import LookupData from './LookupData';
import PricePlanInfo from './PricePlanInfo';
import ServiceInfos from './ServiceInfos';
import styles from '../styles';
import SimcardData from './SimcardData';
import NumberRangesData from './NumberRangesData';
import withUserCheck from '../../../utils/withUserCheck';
import DatalimitData from './DatalimitData';
import UserData from './UserData';
import DataBox from './DataBox';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import AdditionalData from './AdditionalData';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import changeClient from '../../../utils/changeClient';
import history from '../../../../history';
import withSettings from '../../../utils/withSettings';
import SubscriptionBox from './SubscriptionBox';
import NetworkData from './NetworkData';
import SubscriptionStatus from './SubscriptionStatus';

const NumbersDataContainer = ({
  classes,
  fromRightPanel,
  isNonClientAdminUser,
  clientName,
  userName,
  clientId,
  userId,
  client,
  settings,
  userConnectId,
  fromPopup,
  isLandlineNumber,
  partnerName
}) => {
  const onClientClick = async () => {
    await changeClient({ client });

    history.push(`/${clientId}/dashboard`);
  };

  const onUserLinkClick = async () => {
    if (isUndefined(userId)) {
      return;
    }

    return await changeClient({ client });
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.panelContainerRoot]: fromRightPanel,
        [classes.popupContainerRoot]: fromPopup
      })}
    >
      {!fromRightPanel && (
        <>
          <div className={classes.databoxContainer}>
            <DataBox
              label={translate('CUSTOMER')}
              value={clientName}
              additionalValue={partnerName}
              isDisabled={isUndefined(clientId)}
              icon="far fa-arrow-alt-circle-right"
              linkAction={onClientClick}
            />
            <SubscriptionBox />
            <DataBox
              label={translate('MENU.USER')}
              value={userName}
              isDisabled={isUndefined(userId)}
              linkComponent={
                isDefined(userId) ? (
                  isDefined(userConnectId) ? (
                    <Link
                      onClick={onUserLinkClick}
                      to={
                        isDefined(userId)
                          ? {
                              pathname: `/${clientId}/telephonyUsers/user`,
                              state: { id: userConnectId }
                            }
                          : ''
                      }
                    >
                      <i
                        className={clsx('far fa-user', classes.icon)}
                        style={{
                          color: isDefined(userId)
                            ? settings.colors.links
                            : '#F2F3F8'
                        }}
                      ></i>
                    </Link>
                  ) : (
                    <Link
                      onClick={onUserLinkClick}
                      to={
                        isDefined(userId)
                          ? {
                              pathname: `/${clientId}/users/user`,
                              state: { id: userId }
                            }
                          : ''
                      }
                    >
                      <i
                        className={clsx('far fa-user', classes.icon)}
                        style={{
                          color: isDefined(userId)
                            ? settings.colors.links
                            : '#F2F3F8'
                        }}
                      ></i>
                    </Link>
                  )
                ) : (
                  <i
                    className={clsx('far fa-user', classes.icon, {
                      [classes.disablePointer]: isUndefined(userId)
                    })}
                    style={{
                      color: '#F2F3F8'
                    }}
                  ></i>
                )
              }
            />
          </div>
        </>
      )}
      <div className={classes.dataRoot}>
        {!fromRightPanel && <AdditionalData />}
        <div
          className={clsx(classes.dataContainer, {
            [classes.panelContainerRoot]: !fromRightPanel
          })}
        >
          <div>
            <div className={classes.container}>
              <UserData fromRightPanel={fromRightPanel} />
            </div>
            {!isLandlineNumber && (
              <div className={classes.container}>
                <SimcardData fromRightPanel={fromRightPanel} />
              </div>
            )}
            <div className={classes.container}>
              <NumberRangesData fromRightPanel={fromRightPanel} />
            </div>
            {fromRightPanel ? (
              <div className={classes.container}>
                <DatalimitData fromRightPanel={fromRightPanel} />
              </div>
            ) : (
              <div className={classes.container}>
                <SubscriptionStatus />
              </div>
            )}
          </div>
          {isNonClientAdminUser &&
            (fromRightPanel ? (
              <div>
                <div className={classes.container}>
                  <GeneralData fromRightPanel={fromRightPanel} />
                </div>
                <div className={classes.container}>
                  <LookupData fromRightPanel={fromRightPanel} />
                </div>
                <div className={classes.container}>
                  <ServiceInfos fromRightPanel={fromRightPanel} />
                </div>
                <div className={classes.container}>
                  <PricePlanInfo fromRightPanel={fromRightPanel} />
                </div>
              </div>
            ) : (
              <>
                {!isLandlineNumber && <NetworkData />}
                <div className={classes.container}>
                  <LookupData fromRightPanel={fromRightPanel} />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  return {
    clientName: Optional(checkNumber.externalNoData?.client?.name).or(),
    clientId: Optional(checkNumber.externalNoData?.client?.id).or(void 0),
    userName: Optional(checkNumber.simcardData?.owner?.name).or(void 0),
    userId: Optional(checkNumber.simcardData?.owner?.id).or(void 0),
    userConnectId: Optional(
      checkNumber.simcardData?.owner?.connect30_user_id
    ).or(void 0),
    subscription: checkNumber.subscription,
    client: Optional(checkNumber.externalNoData?.client).or(void 0),
    partnerName: Optional(checkNumber.simcardData?.client?.partner?.name).or(
      void 0
    ),
    isLandlineNumber:
      checkNumber.externalNoData?.number_type === 'landline' ? true : false
  };
};

export default flowRight(
  withUserCheck,
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(NumbersDataContainer);
