import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';

import VoicePrompt from '../../../pages/schedules/components/VoicePrompt';
import SelectRow from '../../../partials/dataRows/SelectRow';
import styles, { customStyles, customSelectStyles } from '../styles/promptRow';

function PromptRow({ classes, form, promptsOptions }) {
  const { values, handleBlur, handleChange, setFieldValue } = form;

  const fieldProps = useMemo(() => {
    return {
      values,
      handleBlur,
      handleChange,
      setFieldValue
    };
  }, [values, handleBlur, handleChange, setFieldValue]);

  return (
    <div className={classes.root}>
      <SelectRow
        name="prompt"
        label="SCHEDULES.PROMPT"
        isEditModal={false}
        customStyles={customSelectStyles}
        options={promptsOptions}
        {...fieldProps}
      />
      <VoicePrompt
        prompt={values.prompt}
        customStyles={customStyles}
        onlyPlay={true}
      />
    </div>
  );
}

export default withStyles(styles)(PromptRow);
