export default {
  root: { display: 'flex', marginLeft: '30px', alignItems: 'center' },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  label: { width: '120px', marginRight: '20px' },
  datesRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  endRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  errorMessage: {
    fontSize: '8px',
    color: '#FD397A'
  }
};

export const customDaysCheckboxesStyles = {
  root: {
    width: '55px',
    marginRight: '0'
  },
  label: {
    width: '15px',
    marginRight: '0'
  }
};
