const styles = {
  root: { display: 'flex', flexDirection: 'column' },
  titleContainer: { display: 'flex', alignItems: 'center' },
  title: { marginRight: '10px' },
  dataContainer: { display: 'flex' },
  column: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    '& button': {
      marginRight: '10px'
    }
  },
  tableOverlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    background: '#FFF',
    opacity: '.5',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  numbersList: {
    display: 'flex',
    width: '100%',
    margin: '5px 0 10px 25px',
    alignItems: 'center'
  },
  infoIcon: {
    marginLeft: '10px'
  },
  infoContainer: {
    maxWidth: '400px',
    marginTop: '20px',
    fontWeight: 400,
    fontSize: '13px'
  },
  defaultAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 15px'
  },
  defaultAddress: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 600
  }
};

export default styles;

export const customStyles = {
  option: {
    fontSize: '10px',
    color: '#000'
  },
  control: {
    borderStyle: 'hidden',
    width: '160px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '14px'
  },
  indicatorSeparator: { display: 'none' },
  input: { color: '#FFF' }
};

export const customCheckboxeStyles = {
  root: {
    width: '400px',
    marginRight: '0'
  },
  field: { width: '100%', paddingLeft: '15px' },
  label: {
    width: '300px',
    marginRight: '0'
  }
};
