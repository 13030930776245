const styles = () => {
  return {
    root: { minWidth: '600px' },
    dialogContent: { padding: '20px' },
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    value: {
      fontWeight: 600
    }
  };
};

export default styles;
