const styles = theme => {
  return {
    dialogContent: {
      display: 'flex',
      height: '80vh',
      width:'90vw',
    }
  };
};

export default styles;
