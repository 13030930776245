import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';

export default async function getInvoice({ invoice, clientId }) {
  try {
    const { id, fileName } = invoice;

    const doc = await getData(
      `${URLS.QUOTE_CLIENTS}/${clientId}/documents/${id}`
    );
    const blob = new Blob([doc], { responseType: 'arraybuffer' });

    return { data: blob, fileName };
  } catch {
    return {};
  }
}
