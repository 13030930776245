import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import styles from './styles';
import flowRight from 'lodash.flowright';
import withSettings from '../../utils/withSettings';

function CustomStepperStepIcon({ classes, active, completed, icon, settings }) {
  return (
    <div
      className={classes.root}
      style={{
        color: active && settings.colors.primary
      }}
    >
      {completed ? (
        <i
          className={clsx('fas fa-check-circle', classes.completed)}
          style={{
            color: settings.colors.primary
          }}
        ></i>
      ) : (
        <div className={classes.circle}>
          <span>{icon}</span>
        </div>
      )}
    </div>
  );
}

export default flowRight(
  withSettings,
  withStyles(styles)
)(CustomStepperStepIcon);
