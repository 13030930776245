import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';

import { actions as reduxActions } from './reducers';
import Optional from '../../utils/optional';
import changeObjectValues from '../../utils/changeObjectValues';
import styles from './styles';
import DetailsSelect from './components/DetailSelect';
import DetailsInput from './components/DetailsInput';
import DetailsCheckbox from './components/DetailsCheckbox';
import DetailsReadOnly from './components/DetailsReadOnly';
import ActionsButtons from './components/ActionsButtons';

export function DetailView({
  clientId,
  classes,
  fields = [],
  data = {},
  open,
  closeDetailView,
  title = '',
  submitFunction
}) {
  const onCloseClick = useCallback(() => {
    closeDetailView();
  }, [closeDetailView]);

  if (!data || !Object.keys(data).length) {
    return null;
  }

  return (
    <div className={clsx(classes.root, { [classes.openedView]: open })}>
      <header className={classes.header}>
        <i
          className={clsx('far', 'fa-times-circle', classes.closeIcon)}
          onClick={onCloseClick}
          title="Close"
        ></i>
        <div className={classes.headerText}>{`${title} Detail View`}</div>
      </header>
      <div>
        <Formik
          initialValues={changeObjectValues(data, null, '')}
          enableReinitialize
          onSubmit={values => {
            submitFunction(changeObjectValues(values, '', null), clientId);
          }}
        >
          {({
            values,
            initialValues,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            dirty,
            ...props
          }) => {
            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                {fields.map((field, i) => {
                  if (field.hidden) {
                    return null;
                  }
                  switch (field.type) {
                    case 'input': {
                      return (
                        <Field
                          key={field.value}
                          name={field.value}
                          fieldOptions={field}
                          component={DetailsInput}
                        />
                      );
                    }
                    case 'select': {
                      return (
                        <Field
                          key={field.value}
                          name={field.value}
                          fieldOptions={field}
                          options={optionalFieldValue(
                            field.selectOptions,
                            values,
                            []
                          )}
                          component={DetailsSelect}
                        />
                      );
                    }
                    case 'checkbox': {
                      return (
                        <Field
                          key={field.value}
                          name={field.value}
                          fieldOptions={field}
                          component={DetailsCheckbox}
                        />
                      );
                    }
                    default: {
                      return (
                        <Field
                          key={field.value}
                          name={field.value}
                          fieldOptions={field}
                          component={DetailsReadOnly}
                        />
                      );
                    }
                  }
                })}
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={onCloseClick}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

const mapStatetoProps = ({ detailView }) => {
  return {
    ...detailView
  };
};

const mapDispatchToProps = {
  closeDetailView: reduxActions.closeDetailView
};

export default flowRight(
  connect(mapStatetoProps, mapDispatchToProps),
  withStyles(styles)
)(DetailView);

const optionalFieldValue = (field, data, orValue) => {
  return Optional(field.split('.').reduce((o, i) => o[i], data)).or(orValue);
};
