import React, { useEffect, useMemo, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { Chart } from 'chart.js';

import styles from '../styles';

const CHART_OPTIONS = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  title: { display: false },
  legend: {
    display: false
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        display: true
      }
    ]
  }
};

export function DataLimitChart({
  classes,
  chartData: { dataUsage, chartLabels } = {},
  datalimit: { roaming_disabled, no_limit }
}) {
  const ref = useRef();
  const data = useMemo(() => {
    return {
      labels: chartLabels,
      datasets: mapDataset({ dataUsage, roaming_disabled, no_limit })
    };
  }, [chartLabels, dataUsage, roaming_disabled, no_limit]);

  useEffect(() => {
    const myChart = new Chart(ref.current, {
      type: 'line',
      data: data,
      options: CHART_OPTIONS
    });
    return () => {
      myChart.destroy();
    };
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={classes.chartRoot}>
      <canvas ref={ref} width="400" height="150" />
    </div>
  );
}

export default withStyles(styles)(DataLimitChart);

function mapDataset({ dataUsage, roaming_disabled, no_limit }) {
  let datasets =
    roaming_disabled === false
      ? [
          {
            fill: false,
            data: dataUsage[0],
            borderWidth: 2,
            borderColor: '#1BA9B5',
            pointRadius: 2
          },
          {
            fill: false,
            data: dataUsage[1],
            borderWidth: 2,
            borderColor: '#D99355',
            pointRadius: 2
          },
          {
            fill: false,
            data: dataUsage[2],
            borderWidth: 2,
            borderColor: '#b61182',
            pointRadius: 2
          },
          {
            fill: false,
            data: dataUsage[3],
            borderWidth: 1,
            borderColor: '#FF0000',
            pointRadius: 1
          }
        ]
      : [
          {
            fill: false,
            data: dataUsage[0],
            borderWidth: 2,
            borderColor: '#003D51',
            pointRadius: 2
          },
          {
            fill: false,
            data: dataUsage[3],
            borderWidth: 1,
            borderColor: '#FF0000',
            pointRadius: 1
          }
        ];

  if (no_limit === true) {
    datasets.pop();
  }
  return datasets;
}
