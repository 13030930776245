import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, SET_CONTACT } from './types';
import { getData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';

export function setContactOnEdit({ cid }) {
  return {
    type: sagaActions[SET_CONTACT],
    cid
  };
}

export function* setContact({ cid }) {
  const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
    getMitelRequestProps
  );
  try {
    const contact = yield call(
      getData,
      `${baseUrl}/api/personal_contacts/user/${clientDomain}/${mitelUserId}/${cid}?t=${mitelToken}`
    );

    yield put(reduxActions.openAddContact({ contact, isEdit: true }));
  } catch {}
}

function* watchSetContact() {
  yield takeLatest(sagaActions[SET_CONTACT], setContact);
}

export default watchSetContact();
