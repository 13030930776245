import { flowRight } from 'lodash';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { injectIntl } from 'react-intl';

import styles, { customDaysCheckboxesStyles } from '../styles/elements';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';

function DaysElement({ form, classes, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'DAYS'
        })}
      </div>
      <div className={classes.container}>
        <CheckboxRow
          label="MONDAY_SHORT"
          name="reccurence_0"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="TUESDAY_SHORT"
          name="reccurence_1"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="WEDNESDAY_SHORT"
          name="reccurence_2"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="THURSDAY_SHORT"
          name="reccurence_3"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="FRIDAY_SHORT"
          name="reccurence_4"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="SATURDAY_SHORT"
          name="reccurence_5"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
        <CheckboxRow
          label="SUNDAY_SHORT"
          name="reccurence_6"
          customStyles={customDaysCheckboxesStyles}
          {...form}
        />
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DaysElement);
