import { translate } from '../i18n/I18nProvider';
import { isUndefined } from './isDefined';
import Optional from './optional';

export default function createTableFilterSelectOptions(statusBoxOptions) {
  return Optional(statusBoxOptions)
    .map(options => {
      return {
        ...Object.keys(options).reduce((obj, option) => {
          if (isUndefined(options[option])) {
            return obj;
          }
          const { intlKey, text } = options[option];
          return {
            ...obj,
            [option]: Optional(intlKey)
              .map(key =>
                translate(key)
              )
              .or(text)
          };
        }, {})
      };
    })
    .or({});
}
