const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      bottom: '70px',
      left: '10px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        bottom: '20px'
      }
    },
    photo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50px',
      height: '50px',
      backgroundColor: '#fff',
      borderRadius: '50%'
    },
    initials: {
      fontSize: '18px',
      fontWeight: 500
    },
    name: {
      marginLeft: '10px',
      display: 'none',
      opacity: 0,
      transition: 'visibility 1s, opacity 0.3s linear',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        opacity: 1
      }
    },
    visible: {
      display: 'block',
      opacity: 1
    }
  };
};

export default styles;
