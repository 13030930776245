import React from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { actions as addingSchedulesReduxActions } from '../../../modals/addingSchedules/reducers';

function AddSchedule({ openAddingSchedules, group = {}, clientId }) {
  return (
    <div>
      {can(
        'create',
        createSubject('Mitel::Schedule', { client_id: Number(clientId) })
      ) && (
        <CustomIconButton
          iconTooltipIntlKey="EDIT_SCHEDULE.ADD_NEW"
          onClick={() =>
            openAddingSchedules({
              schedules: [group]
            })
          }
          iconClassName="far fa-plus"
        />
      )}
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  openAddingSchedules: addingSchedulesReduxActions.openDiaog
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  AddSchedule
);
