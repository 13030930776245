import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import {
  isDefined,
  isObject,
} from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';

const getSubscriptionDetails = async (subId, clientId) => {
  if (isDefined(subId) && isDefined(clientId)) {
    try {
      const { data } = await getData({
        options: {
          url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${subId}`
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND]
      });

      if (!isObject(data)) {
        return void 0;
      }

      if (Object.keys(data).indexOf('error') === -1) {

        return data;
      }
    } catch {
      return void 0;
    }
  }
};

export default getSubscriptionDetails;
