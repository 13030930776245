import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import flowRight from 'lodash.flowright';

import HMenu from './HMenu/HMenu';
import KTHeader from '../../_assets/js/header';
import * as builder from '../../ducks/builder';
import SelectClient from '../../../app/partials/selectClient/SelectClient';
import Loader from '../../../app/partials/loader/Loader';
import withSettings from '../../../app/utils/withSettings';
import withUserCheck from '../../../app/utils/withUserCheck';
import can from '../../../app/utils/can';
import createSubject from '../../../app/utils/createSubject';
import Optional from '../../../app/utils/optional';
import { isString, isStringDefined } from '../../../app/utils/isDefined';
import ErrorsPopover from '../../../app/partials/errorsPanel/ErrorsPopover';
import SearchIcon from '../../../app/partials/searchIcon/SearchIcon';

class Header extends React.Component {
  headerCommonRef = React.createRef();
  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute('data-ktheader-minimize') ===
      '1'
    ) {
      options['minimize.desktop.on'] = 'kt-header--minimize';
      options['offset.desktop'] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const {
      menuHeaderDisplay,
      headerAttributes,
      headerClasses,
      settings,
      appTitle,
      systemErrorsQty
    } = this.props;
    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <Loader />
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              className="kt-header--text"
              style={{ color: settings.colors.primary }}
            >
              {appTitle}
            </div>
            <div>
              {menuHeaderDisplay && <HMenu />}
              <SelectClient />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {systemErrorsQty > 0 && <ErrorsPopover hideOnMobile />}
            {can(
              'read',
              createSubject('SimCard', {
                client_id: Number(this.props.selectedClientId)
              })
            ) && (
              <SearchIcon
                hideOnMobile
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: 'header',
    toString: true
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: 'header' }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    'header.menu.self.display'
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, 'header.self.width') === 'fluid',
  selectedClientId: store.selectClient.selectedClient.id,
  appTitle: Optional(store.builder.settings?.title)
    .map(title =>
      isString(title) && isStringDefined(title.trim()) ? title : 'Selfcare'
    )
    .or('Selfcare'),
  systemErrorsQty: store.errorsPanel.errors.length
});

export default flowRight(
  withUserCheck,
  withSettings,
  connect(mapStateToProps)
)(Header);
