const styles = theme => {
  return {
    root: { display: 'flex', alignItems: 'center' },
    deliveryRoot: { display: 'flex', margin: '0 10px' },
    icon: {
      fontSize: '24px'
    },
    statusContainer: {
      fontSize: '16px',
      position: 'relative',
      top: '-5px',
      left: '-10px',
      backgroundColor: '#FFFFFF',
      height: '15px'
    }
  };
};

export default styles;
