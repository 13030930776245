import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_SIMCARD } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import { actions as simcardsReduxActions } from '../../../pages/simCards/reducers';
import { actions as userReduxActions } from '../../../pages/user/reducers';
import mapDataToUpdate from './mapDataToUpdate';
import showSnackbar from '../../../utils/showSnackbar';
import { submitNumber } from '../../../pages/checkNumber/actions/submitNumber';

export function updateOnSubmit({
  simcard,
  initialUserId,
  fromLookup,
  fromUserView,
  fromRightPanel
}) {
  return {
    type: sagaActions[UPDATE_SIMCARD],
    simcard,
    initialUserId,
    fromLookup,
    fromUserView,
    fromRightPanel
  };
}

export function* updateSimcard({
  simcard,
  initialUserId,
  fromLookup,
  fromUserView,
  fromRightPanel
}) {
  const mappedData = mapDataToUpdate(simcard, initialUserId);
  const response = yield call(updateData, {
    url: `${URLS.SIM_CARDS}/${simcard.id}`,
    data: {
      data: mappedData
    }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.UPDATE.SIMCARD',
      messageType: 'success'
    });

    yield put(reduxActions.closeEditSimcard());

    if (fromLookup) {
      return yield call(submitNumber, {
        number: simcard.msisdn,
        fromRightPanel
      });
    }

    if (fromUserView) {
      return yield put(userReduxActions.refresh());
    }

    return yield put(simcardsReduxActions.setRefresh(true));
  }
}

function* watchDetailsViewSubmit() {
  yield takeLatest(sagaActions[UPDATE_SIMCARD], updateSimcard);
}

export default watchDetailsViewSubmit();
