export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  dot: {
    minWidth: '10px',
    minHeight: '10px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    marginRight: '3px'
  },
  icon: { width: '15px' }
};
