const styles = theme => {
  return {
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: '22px',
      alignItems: 'center'
    },
    circle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      '& span': {
        color: '#FFF',
        fontSize: '11px'
      }
    },
    completed: {
      zIndex: 1,
      fontSize: '20px'
    }
  };
};

export default styles;
