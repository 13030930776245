const styles = theme => {
  return {
    actionButtons: {
      display: 'flex',
      '& i': {
        fontSize: '18px',
        margin: '5px 10px',
        cursor: 'pointer'
      }
    },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    },
    actionIcon: {
      margin: '5px 10px',
      cursor: 'pointer',
      fontSize: '18px',
      '&:hover': { filter: 'brightness(1.5)' }
    }
  };
};

export default styles;
