import React, { useCallback, useMemo } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { createContactStatuses } from '../../../consts/selectsOptions';
import SelectRow from '../../../partials/dataRows/SelectRow';
import UserData from './UserData';
import AddressData from './AddressData';
import InputRow from '../../../partials/dataRows/InputRow';
import Numbers from './Numbers';
import styles from '../styles';
import { actions as reduxActions } from '../reducers';
import ActionsButtons from './ActionsButtons';
import { addContactOnSubmit } from '../actions/addContact';
import { EMPTY_CONTACT } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';
import validateSchema from '../actions/validateSchema';

export function AddContactForm({
  classes,
  intl,
  number,
  closeAddContact,
  addContactOnSubmit,
  contact,
  isEdit
}) {
  const onSubmit = useCallback(
    values =>
      addContactOnSubmit({
        values,
        isEdit
      }),
    // eslint-disable-next-line
    [addContactOnSubmit]
  );

  const selectOptions = useMemo(() => {
    return createContactStatuses();
  }, []);

  return (
    <Formik
      initialValues={
        isEdit
          ? contact
          : {
              ...EMPTY_CONTACT,
              phoneNumbers: [
                {
                  type: 'MAIN',
                  phoneNumber: Optional(number).or(''),
                  preferredNumber: true
                }
              ]
            }
      }
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema()}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        dirty,
        setFieldValue
      }) => {
        const fieldProps = { errors, values, handleBlur, handleChange };
        return (
          <Form onSubmit={handleSubmit}>
            <SelectRow
              name="contactStatus"
              label="ADD_EXTERNAL_CONTACT.ADD_AS"
              isEditModal={false}
              options={selectOptions}
              setFieldValue={setFieldValue}
              maxMenuHeight={150}
              {...fieldProps}
            />
            <div className={classes.dataContainer}>
              <Field component={UserData} />
              <div>
                <Field component={AddressData} />
                <InputRow
                  label="ADD_EXTERNAL_SPEED_DIAL"
                  name="speeddial"
                  {...fieldProps}
                />
              </div>
            </div>

            <FieldArray
              name="phoneNumbers"
              render={arrayHelpers => (
                <div className={classes.customRolesRoot}>
                  <Numbers
                    arrayHelpers={arrayHelpers}
                    form={{ setFieldValue, values, errors }}
                  />
                </div>
              )}
            />
            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={closeAddContact}
                handleSubmit={handleSubmit}
                dirty={dirty}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ addExternalContact }) => {
  return {
    number: addExternalContact.number?.number,
    contact: addExternalContact.contact,
    isEdit: addExternalContact.isEdit
  };
};

const mapDispatchToProps = {
  closeAddContact: reduxActions.closeAddContact,
  addContactOnSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AddContactForm);
