import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialTable from '@material-table/core';
import { connect } from 'react-redux';

import tableColumns from './tableColumns';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import ConnectionsTableHeader from './ConnectionsTableHeader';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { getConnections } from '../actions/getConnections';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

const ConnectionsTable = ({
  connections,
  lang,
  selectedAccount,
  showTerminated,
  setColumns,
  columns,
  clientName,
  clientId,
  getConnections
}) => {
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getConnections({ clientId });
    // eslint-disable-next-line
  }, [clientId]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns({ lang, showAll: selectedAccount.value === 'ALL' });
  }, [lang, selectedAccount.value]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Connections'
    });
  }, [setColumns, tableDefaultColumns]);

  useEffect(() => {
    if (refresh) {
      getConnections({ clientId });
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    []
  );

  const dataToShow =
    isDefined(selectedAccount.value) && selectedAccount.value !== 'ALL'
      ? connections
          .filter(el => el.parentAccountId === selectedAccount.value)
          .filter(el =>
            showTerminated
              ? el.status === 'TERMINATED'
              : el.status !== 'TERMINATED'
          )
      : connections.filter(el =>
          showTerminated
            ? el.status === 'TERMINATED'
            : el.status !== 'TERMINATED'
        );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ tableName: 'Network_connections', clientName }),
      selection: false
    };
  }, [clientName]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Connections'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <div>
      <MaterialTable
        title={<ConnectionsTableHeader />}
        columns={columns}
        data={dataToShow}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
        icons={customTableIcons}
        actions={actions}
      />
    </div>
  );
};

const mapStatesToProps = ({ connections, auth, selectClient }) => {
  return {
    ...connections,
    lang: Optional(auth.lang).or('default'),
    clientName: selectClient.selectedClient.name,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  getConnections
};

export default connect(mapStatesToProps, mapDispatchToProps)(ConnectionsTable);
