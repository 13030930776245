import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import StatusCell from '../../../partials/statusCell/StatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import styles from '../styles/infoSection';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import Optional from '../../../utils/optional';

function InfoSimCardSection({ classes, data, intl }) {
  const { owner, mobile_network, msisdn, active } = data;
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div
          className={clsx(classes.element, classes.elementRow)}
        >{`${intl.formatMessage({
          id: 'EDIT.USER'
        })} : ${Optional(owner)
          .map(owner => owner.name)
          .or('')}`}</div>
        <div className={clsx(classes.elementRow)}>
          <div className={classes.element}>{`${intl.formatMessage({
            id: 'STATUS'
          })} : `}</div>
          <StatusCell
            isTable={false}
            {...pickOptionsForStatusCell(
              STATUS_BOX_OPTIONS.SIMCARD_ACTIVE,
              active
            )}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div
          className={clsx(classes.element, classes.elementRow)}
        >{`${intl.formatMessage({
          id: 'TABLE.MOBILE_NUMBER'
        })} : ${msisdn}`}</div>
        <div
          className={clsx(classes.element, classes.elementRow)}
        >{`${intl.formatMessage({
          id: 'TABLE.NETWORK'
        })} : ${Optional(mobile_network)
          .map(network => network.brand)
          .or('')}`}</div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(InfoSimCardSection);
