import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import UserStepper from './components/UserStepper';
import { getAllSelectOptions } from './actions/getSelectOptions';
import Optional from '../../utils/optional';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';

export function RequestUserWizard({
  resestUserWizard,
  classes,
  open,
  intl,
  getAllSelectOptions,
  connect30_domain,
  clientId
}) {
  useEffect(() => {
    if (open === true) {
      getAllSelectOptions({ connect30_domain, clientId });
    }
    // eslint-disable-next-line
  }, [connect30_domain, clientId, open]);

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          resestUserWizard();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={resestUserWizard}>
        {intl.formatMessage({
          id: 'REQUEST_NEW_USER.TITLE'
        })}
      </CustomDialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <UserStepper />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ requestUserWizard, selectClient, auth }) => {
  return {
    open: requestUserWizard.open,
    connect30_domain: Optional(selectClient.selectedClient.connect30_domain).or(
      auth.defaultClient.connect30_domain
    ),
    clientId: Optional(selectClient.selectedClient.id).or(auth.defaultClient.id)
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  getAllSelectOptions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(RequestUserWizard);
