import { OUTSIDE_API } from '../../../consts/endpoints';
import { updateData } from '../../../utils/http';

import store from '../../../store/store';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { actions as reduxActions } from '../reducers';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

const updateLicense = async ({ component, quota }) => {
  store.dispatch(loaderReduxActions.startSpinLoader());
  try {
    const mappedData = mapData({ component, quota });

    const connectClientId = store.getState().selectClient.selectedClient
      .connect30_domain;

    const response = await updateData({
      url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/licenses`,
      data: mappedData
    });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: 'MESSAGE.UPDATE.LICENSE',
        messageType: 'success',
        time: 3000
      });

      const licenses = store.getState().licenses.licenses;

      const mappedLicenses = licenses.map(license => {
        if (license.component === component) {
          return { ...license, quota };
        }
        return license;
      });

      store.dispatch(reduxActions.setLicenses(mappedLicenses));
    }
  } finally {
    store.dispatch(loaderReduxActions.stopLinearLoader());
  }
};

export default updateLicense;

const mapData = ({ component, quota }) => {
  return { licenses: [{ component, quota }] };
};
