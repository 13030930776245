import React from 'react';
import { connect } from 'react-redux';

export default function withSettings(WrappedComponent) {
  const mapStatesToProps = ({ builder }) => ({
    settings: builder.settings,
    defaultSettings: builder.defaultSettings
  });

  return connect(mapStatesToProps)(({ ...props }) => {
    return <WrappedComponent {...props} />;
  });
}
