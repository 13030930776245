import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import InputRow from '../../../partials/dataRows/InputRow';

export function UserData({ classes, form, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <InputRow label="FIRST_NAME" name="firstname" {...form} />
          <InputRow label="LAST_NAME" name="lastname" {...form} />
          <InputRow name="company" label="COMPANY" {...form} />
          <InputRow name="department" label="DEPARTMENT" {...form} />
          <InputRow name="email" label="EMAIL" {...form} />
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(UserData);
