const styles = theme => ({
  root: {
    width: '750px',
    height: 'fit-content',
    backgroundColor: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E',
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  },
  dataLimitRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #F2F3F8',
    height: '90px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '120px'
    }
  },
  dataLimitTextContainer: {
    width: '150px',
    display: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  dataLimitNoData: {
    display: 'flex',
    justifyContent: 'center',
    width: '310px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
      width: '330px'
    }
  },

  dataLimitValue: {
    color: '#68677B',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  dataUsedValue: {
    width: '90px',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  dataLimitChartContainer: {
    width: '450px',
    display: 'inherit',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      alignItems: 'center'
    }
  },
  chart: {
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    color: '#68677B',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: '5px'
    }
  },
  dataLimitChart: {
    width: '310px',
    height: '15px',
    borderRadius: '7px',
    backgroundColor: '#EDEFF3',
    marginRight: '10px'
  },
  chartRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    margin: '1vh 1vw'
  },
  sectionContainer: {
    display: 'flex',
    height: '170px',
    flexDirection: 'column',
    width: '52%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      height: 'unset',
      width: '100%'
    }
  },
  infoContainer: {
    width: '46%'
  },
  basicInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  columnContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  dataRowLabel: {
    width: '200px'
  },
  reportingAllNumbersRoot: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  reportingAllNumbersValue: {
    width: '420px',
    [theme.breakpoints.down('xs')]: {
      width: 'inherit'
    }
  },
  labelContainer: { display: 'flex', alignItems: 'center' },
  dot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  info: {
    padding: '15px',
    color: '#000'
  },
  appendixLink: {
    height: '19px',
    fontSize: '12px',
    '&:hover': { filter: 'brightness(1.5)' },
    cursor: 'pointer'
  }
});

export default styles;

export const CUSTOM_STYLES = {
  infoDataRow: {
    label: { width: '195px' },
    value: { width: '120px' }
  },
  statusDataRow: {
    label: { width: '195px' },
    value: { width: '145px' }
  }
};
