import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import DataRow from '../../../partials/Datarow/DataRow';
import { isArray, isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';
import { getAdditionalData } from '../actions/getContactData';
import styles from '../styles/additionalInfo';
import { setContactOnEdit } from '../../../modals/addExternalContact/actions/setContact';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { removeContact } from '../actions/deleteContact';

function AdditionalInfo({
  classes,
  contact,
  getAdditionalData,
  intl,
  setMoreInfo,
  baseUrl,
  clientDomain,
  mitelToken,
  settings,
  setContactOnEdit,
  removeContact,
  refresh
}) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (isDefined(contact.id)) {
      getAdditionalData({
        contactId: contact.id,
        setData,
        setMoreInfo,
        baseUrl,
        clientDomain,
        mitelToken
      });
    }
    // eslint-disable-next-line
  }, [refresh]);

  const onEditClick = useCallback(() => {
    setContactOnEdit({ cid: contact.id });
    // eslint-disable-next-line
  }, [contact.id]);

  const onDeleteClick = useCallback(() => {
    removeContact({ cid: contact.id });
    // eslint-disable-next-line
  }, [contact.id]);

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <DataRow
        label={intl.formatMessage({
          id: 'COMPANY'
        })}
        value={Optional(contact.company).or('')}
        customClasses={{ value: classes.datarowValue }}
        dense
      />
      <DataRow
        label={intl.formatMessage({
          id: 'EMAIL'
        })}
        value={data.email}
        customClasses={{ value: classes.datarowValue }}
        dense
      />
      {isArray(data.phones) &&
        data.phones.map((number, i) => {
          return (
            <DataRow
              key={i}
              label={
                isDefined(number.numberType)
                  ? `${intl.formatMessage({
                      id: `PHONE_TYPE.${number.numberType}`
                    })} ${
                      number.isShort
                        ? `(${intl.formatMessage({
                            id: 'SHORT'
                          })})`
                        : ''
                    }`
                  : ''
              }
              value={Optional(number.value).or('')}
              customClasses={{ value: classes.datarowValue }}
              dense
            />
          );
        })}
      <DataRow
        label={intl.formatMessage({
          id: 'DEPARTMENT'
        })}
        value={Optional(data.department).or('')}
        customClasses={{ value: classes.datarowValue }}
        dense
      />
      {isDefined(data.address) && (
        <DataRow
          label={`${intl.formatMessage({
            id: 'ADDRESS'
          })}`}
          value={Optional(data.address).or('')}
          customClasses={{ value: classes.datarowValue }}
          dense
        />
      )}
      {isArray(data.fields) &&
        data.fields.map(field => {
          return (
            field.value && (
              <DataRow
                key={field.id}
                label={Optional(field.label).or(field.id)}
                value={field.value}
                customClasses={{ value: classes.datarowValue }}
                dense
              />
            )
          );
        })}
      {contact.type === 'PERSONAL' && (
        <div
          className={classes.actions}
          style={{ color: settings.colors.primary }}
        >
          <Tooltip
            title={intl.formatMessage({
              id: 'TOOLTIP.EDIT_CONTACT'
            })}
          >
            <i
              className={clsx('fas fa-user-edit', classes.icon)}
              onClick={onEditClick}
            ></i>
          </Tooltip>
          <ConfirmDialog
            onConfirmClick={onDeleteClick}
            dialogContentComponent={intl.formatMessage({
              id: 'CONTACTS.DELETE_CONTACT'
            })}
            dialogTitleIntlId={'DELETE'}
          >
            <Tooltip
              title={intl.formatMessage({
                id: 'TOOLTIP.DELETE_CONTACT'
              })}
            >
              <i className={clsx('fas fa-trash-alt', classes.icon)}></i>
            </Tooltip>
          </ConfirmDialog>
        </div>
      )}
    </div>
  );
}

const mapStatesToProps = ({ auth, userContacts }) => {
  return {
    clientDomain: auth.defaultClient.connect30_domain,
    mitelToken: auth.user.mitel_token,
    baseUrl: auth.user.mitel_profile_link,
    refresh: userContacts.refresh
  };
};

const mapDispatchToProps = {
  getAdditionalData,
  setContactOnEdit,
  removeContact
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AdditionalInfo);
