import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../partials/customButtons/PrimaryButton';
import styles from './styles';
import LanguageRow from './components/LanguageRow';
import MitelSettingsRow from './components/MitelSettingsRow';
import withSettings from '../../utils/withSettings';
import { resetPassRequest } from '../../modals/forgottenPass/actions/resetPass';
import mapUsernameForPassReset from '../../utils/mapUsernameForPassReset';
import UpdatePass from './components/UpdatePass';
import ChangePhoto from '../../partials/changePhoto/ChangePhoto';
import { isDefined } from '../../utils/isDefined';
import RefreshButton from '../../partials/tableCustomButtons/RefreshButton';
import refreshUser from './actions/refreshUser';
import { translate } from '../../i18n/I18nProvider';

const BUTTON_MIN_WIDTH = '150px';

function UserSettings({ classes, intl, settings, user, resetPassRequest }) {
  const onResetPassClick = useCallback(
    () => {
      const username = mapUsernameForPassReset(user);
      resetPassRequest(username, false);
    },
    // eslint-disable-next-line
    []
  );

  const onReloadClick = useCallback(async () => {
    await refreshUser();
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.container}>
          <div
            className={classes.title}
            style={{
              color: settings.colors.primary
            }}
          >
            {intl.formatMessage({
              id: 'MENU.USER_SETTINGS'
            })}
            <RefreshButton
              onClick={onReloadClick}
              customTitle={translate('TOOLTIP.REFRESH_USER_SESSION')}
            />
          </div>
          <div className={classes.settingsContainer}>
            <LanguageRow />
            {user.connect30_user_id && (
              <MitelSettingsRow user={user} settings={settings} />
            )}
            <PrimaryButton href={'/logout'} minWidth={BUTTON_MIN_WIDTH}>
              {intl
                .formatMessage({
                  id: 'MENU.SIGN_OUT'
                })
                .toUpperCase()}
            </PrimaryButton>
          </div>
        </div>

        <UpdatePass />
        <div className={classes.container}>
          <div className={classes.passwordContainer}>
            <div
              className={classes.title}
              style={{
                color: settings.colors.primary
              }}
            >
              {intl.formatMessage({
                id: 'RESET_PASS.RESET'
              })}
            </div>
            <div
              className={classes.centered}
              style={{
                color: settings.colors.primary
              }}
            >
              {intl.formatMessage({
                id: 'FORGET_PASS.TEXT'
              })}
            </div>
            <div className={classes.passButtonContainer}>
              <PrimaryButton onClick={onResetPassClick}>
                {intl.formatMessage({
                  id: 'RESET_PASS.SEND_RESET_EMAIL'
                })}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      <div>{isDefined(user.connect30_user_id) && <ChangePhoto />}</div>
    </div>
  );
}

const mapStatesToProps = ({ auth }) => {
  return {
    user: auth.user
  };
};
const mapDispatchToProps = {
  resetPassRequest
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserSettings);
