import { createTheme } from '@material-ui/core';

export function customPickerStyles(settings) {
  return {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: settings.colors.aside_menu
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: settings.colors.white,
        color: settings.colors.primary
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        color: settings.colors.primary,
        backgroundColor: settings.colors.white
      }
    },
    MuiPickersDay: {
      day: {
        color: settings.colors.primary
      },
      daySelected: {
        backgroundColor: settings.colors.aside_menu
      },
      dayDisabled: {
        color: '#CACACA'
      },
      current: {
        color: settings.colors.primary
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: settings.colors.aside_menu
      },
      noPoint: {
        backgroundColor: settings.colors.aside_menu
      },
      thumb: {
        borderColor: settings.colors.aside_menu
      }
    },
    PrivateTabIndicator: {
      colorSecondary: { backgroundColor: settings.colors.aside_menu }
    }
  };
}

export const materialDatePickerTheme = settings => {
  return createTheme({
    overrides: {
      MuiTextField: {
        root: {
          width: '210px',
          marginRight: '20px'
        }
      },
      MuiOutlinedInput: {
        root: {
          width: '200px',
          padding: '0',
          marginRight: '30px',
          borderRadius: '0'
        },
        input: {
          padding: '10px'
        },
        adornedEnd: {
          paddingRight: '0'
        }
      },
      ...customPickerStyles(settings)
    }
  });
};

export const materialTimePickerTheme = settings => {
  return createTheme({
    overrides: {
      MuiTextField: {
        root: {
          width: '120px'
        }
      },
      MuiOutlinedInput: {
        root: {
          width: '100%',
          padding: '0',
          borderRadius: '0'
        },
        input: {
          padding: '10px'
        },
        adornedEnd: {
          paddingRight: '0'
        }
      },
      ...customPickerStyles(settings)
    }
  });
};

export const materialEndDatePickerTheme = settings => {
  return createTheme({
    overrides: {
      MuiTextField: {
        root: {
          width: '180px'
        }
      },
      MuiOutlinedInput: {
        root: {
          width: '150px',
          padding: '0',
          borderRadius: '0'
        },
        input: {
          padding: '10px'
        },
        adornedEnd: {
          paddingRight: '0'
        }
      },
      ...customPickerStyles(settings)
    }
  });
};
