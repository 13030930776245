import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/myNumbers';
import { isDefined } from '../../../utils/isDefined';
import NumberRow from './NumberRow';
import { connect } from 'react-redux';
import { submitUpdateDiversion } from '../../../modals/transferNumbers/actions/updateDiversions';
import { translate } from '../../../i18n/I18nProvider';

export function NumbersInfo({ classes, mappedDiversions = {} }) {
  const {
    office_mobile,
    office_fixed,
    private_fixed,
    private_mobile,
    datacard
  } = mappedDiversions;

  return (
    <div className={classes.numbersContainer}>
      {isDefined(office_fixed) && (
        <NumberRow
          label={translate('NUMBERS.OFFICE_FIXED')}
          number={office_fixed}
          isPhoneLink={true}
        />
      )}
      {isDefined(office_mobile) && (
        <NumberRow
          label={translate('NUMBERS.OFFICE_MOBILE')}
          number={office_mobile}
          isPhoneLink={true}
        />
      )}
      {isDefined(private_fixed) && (
        <NumberRow
          label={translate('NUMBERS.PRIVATE_FIXED')}
          number={private_fixed}
          isPhoneLink={true}
        />
      )}
      {isDefined(private_mobile) && (
        <NumberRow
          label={translate('NUMBERS.PRIVATE_MOBILE')}
          number={private_mobile}
          isPhoneLink={true}
        />
      )}
      {isDefined(datacard) && (
        <NumberRow
          label={translate('NUMBER.DATACARD')}
          number={datacard}
        />
      )}
    </div>
  );
}

const mapStatesToProps = ({ user }) => {
  const { mappedDiversions } = user;

  return {
    mappedDiversions
  };
};

const mapDispatchToProps = {
  submitUpdateDiversion
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NumbersInfo);
