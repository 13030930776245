import React, { useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import { materialDatePickerTheme } from '../styles/activityExpiration';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

function SetDate({ form, settings, dateError }) {
  const { values, setFieldValue, setFieldError, errors } = form;
  const currentError = Optional(dateError).or(errors['expiration']);
  const date = useMemo(() => {
    if (values.expiration === '' || values.expiration === 'never') {
      return moment().format();
    }

    return values.expiration;
  }, [values.expiration]);

  const materialTheme = useMemo(() => {
    return materialDatePickerTheme(settings);
  }, [settings]);

  const onChange = selectedDate => {
    if (date !== 'Invalid Date') {
      setFieldValue('expiration', selectedDate);
    }
  };
  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          value={date}
          onChange={onChange}
          disabled={values.picked !== 'set'}
          disablePast
          format="yyyy/MM/dd HH:mm"
          autoOk={true}
          error={Boolean(currentError)}
          onError={error => {
            if (error !== currentError) {
              setFieldError('expiration', error);
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default withSettings(SetDate);
