export default {
    root: {
      width: '100%',
      backgroundColor: '#FFFFFF'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      '& button': {
        margin: '10px'
      }
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
  };