import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_USER = 'SET_USER';
const RESET_USER = 'RESET_USER';
const SET_LINES = 'SET_LINES';
const SET_SELECTED_NUMBER = 'SET_SELECTED_NUMBER';
const SET_REFRESH_USER = 'SET_REFRESH_USER';
const SET_UPDATED_USER_DATA = 'SET_UPDATED_USER_DATA';
const SET_DIVERSIONS = 'SET_DIVERSIONS';
const SET_USER_PHOTO = 'SET_USER_PHOTO';
const REFRESH = 'REFRESH';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_SIMCARD_RELOAD = 'SET_SIMCARD_RELOAD';

export const actionTypes = createReduxActionTypes('USER', [
  SET_USER,
  RESET_USER,
  SET_LINES,
  SET_SELECTED_NUMBER,
  SET_UPDATED_USER_DATA,
  SET_REFRESH_USER,
  SET_DIVERSIONS,
  SET_USER_PHOTO,
  REFRESH,
  SET_SUBSCRIPTION,
  SET_SIMCARD_RELOAD
]);

const DEFAULT_STATE = {
  selectOptions: {},
  updatedUserData: {},
  diversions: [],
  mappedDiversions: {},
  refresh: false,
  simcardReload: false,
  subscription: void 0
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_USER]: {
      return { ...state, ...action.user };
    }
    case actionTypes[SET_DIVERSIONS]: {
      const { diversions, mappedDiversions } = action;
      return {
        ...state,
        diversions,
        mappedDiversions
      };
    }
    case actionTypes[SET_USER_PHOTO]: {
      const { photo } = action;
      return {
        ...state,
        photo
      };
    }
    case actionTypes[SET_REFRESH_USER]: {
      return { ...state, ...action.user };
    }
    case actionTypes[SET_LINES]: {
      const { linesOptions, selectedLine } = action;
      return {
        ...state,
        linesOptions: [...linesOptions],
        selectedLine: { ...selectedLine }
      };
    }
    case actionTypes[SET_UPDATED_USER_DATA]: {
      const { updatedUserData } = action;
      return {
        ...state,
        updatedUserData
      };
    }
    case actionTypes[SET_SELECTED_NUMBER]: {
      const { selected, label } = action;
      return {
        ...state,
        [label]: selected
      };
    }
    case actionTypes[SET_SUBSCRIPTION]: {
      const { subscription } = action;
      return {
        ...state,
        subscription
      };
    }
    case actionTypes[SET_SIMCARD_RELOAD]: {
      const { simcardReload } = action;
      return {
        ...state,
        simcardReload
      };
    }
    case actionTypes[REFRESH]: {
      return { ...state, refresh: !state.refresh };
    }
    case actionTypes[RESET_USER]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setUser: user => ({
    type: actionTypes[SET_USER],
    user
  }),
  setDiversions: ({ diversions, mappedDiversions }) => ({
    type: actionTypes[SET_DIVERSIONS],
    diversions,
    mappedDiversions
  }),
  setUserPhoto: photo => ({
    type: actionTypes[SET_USER_PHOTO],
    photo
  }),
  refreshUser: user => ({
    type: actionTypes[SET_REFRESH_USER],
    user
  }),
  setLines: ({ linesOptions, selectedLine }) => ({
    type: actionTypes[SET_LINES],
    linesOptions,
    selectedLine
  }),
  setSelectedNumber: (selected, label) => ({
    type: actionTypes[SET_SELECTED_NUMBER],
    selected,
    label
  }),
  setUpdatedUserData: updatedUserData => ({
    type: actionTypes[SET_UPDATED_USER_DATA],
    updatedUserData
  }),
  setSimcardReload: simcardReload => ({
    type: actionTypes[SET_SIMCARD_RELOAD],
    simcardReload
  }),
  setSubscription: subscription => ({
    type: actionTypes[SET_SUBSCRIPTION],
    subscription
  }),
  refresh: () => ({ type: actionTypes[REFRESH] }),
  resetUser: () => ({ type: actionTypes[RESET_USER] })
};
