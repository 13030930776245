import { isUndefined } from 'lodash';
import store from '../../../store/store';
import { isObject } from '../../../utils/isDefined';

export default function mapParameters(parameters) {
  if (isObject(parameters)) {
    const paramsArray = Object.keys(parameters);

    return paramsArray.reduce((obj, param) => {
      switch (param) {
        case 'client_id': {
          const id = store.getState().selectClient.selectedClient.id;
          if (isUndefined(id)) {
            return {};
          }
          return {
            ...obj,
            [param]: parameters[param].type === 'integer' ? Number(id) : id
          };
        }
        case 'current_user_id': {
          const id = store.getState().auth.user.id;
          if (isUndefined(id)) {
            return {};
          }
          return {
            ...obj,
            [param]: parameters[param].type === 'integer' ? Number(id) : id
          };
        }
        default: {
          return obj;
        }
      }
    }, {});
  }

  return {};
}
