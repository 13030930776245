import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import styles from '../styles/portNumberStep';
import { actions as reduxActions } from '../reducers';
import validateSchema from '../actions/validateSchema';
import InputRow from '../../../partials/dataRows/InputRow';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import DatePickerRow from '../../../partials/dataRows/DatepickerRow';
import { attorneySubmit } from '../actions/attorneyRequest';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import CurrentOwnerInfo from './CurrentOwnerInfo';
import withSettings from '../../../utils/withSettings';

const CUSTOM_STYLES = {
  moveNumberCheckbox: {
    label: {
      width: 'fit-content'
    }
  }
};

export function PortNumberStep({
  classes,
  intl,
  resestUserWizard,
  handleBack,
  handleNext,
  setUserData,
  secondStepData,
  attorneySubmit,
  attorneySend,
  settings
}) {
  const onSubmit = useCallback(
    async values => {
      await attorneySubmit({
        userData: { ...values },
        setUserData: () => setUserData(values, 'secondStep'),
        handleNext
      });
    },
    // eslint-disable-next-line
    [attorneySend]
  );
  const onBackClick = useCallback(
    () => {
      setUserData(null, 'secondStep');
      return handleBack();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Formik
      initialValues={secondStepData}
      enableReinitialize
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl).portStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = { errors, values, handleBlur, handleChange };
        return (
          <Form onSubmit={handleSubmit}>
            <div className={classes.root}>
              <div className={classes.sectionContainer}>
                <div className={classes.sectionLabel}>
                  {intl.formatMessage({
                    id: 'REQUEST_NEW_USER.MOVE_NUMBER'
                  })}
                </div>
                <div className={classes.dataContainer}>
                  <div className={classes.moveNumber}>
                    <InputRow
                      label="REQUEST_NEW_USER.MOVE_MOBILE"
                      name="portMobileNumber"
                      disabled={true}
                      {...fieldProps}
                    />
                    <DatePickerRow
                      label="REQUEST_NEW_USER.MOVE_DATA"
                      name="move_number_date"
                      disabled={values.is_old_subscripion_date}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      daysAhead={3}
                    />
                    <CheckboxRow
                      label="REQUEST_NEW_USER.WHEN_OLD_SUBSCRIPTION"
                      name="is_old_subscripion_date"
                      customStyles={CUSTOM_STYLES.moveNumberCheckbox}
                      {...fieldProps}
                    />
                  </div>
                  <div>
                    <InputRow
                      label="REQUEST_NEW_USER.CURRENT_SIM_NUMBER"
                      name="current_sim_card_number"
                      {...fieldProps}
                    />
                    <InputRow
                      name="current_operator"
                      label="REQUEST_NEW_USER.CURRENT_OPERATOR"
                      disabled
                      {...fieldProps}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.sectionContainer}>
                <div className={classes.sectionLabel}>
                  {intl.formatMessage({
                    id: 'REQUEST_NEW_USER.CURRENT_OWNER'
                  })}
                  <ConfirmDialog
                    dialogTitleIntlId={
                      'REQUEST_NEW_USER.CURRENT_OWNER_INFO_TITLE'
                    }
                    dialogContentComponent={<CurrentOwnerInfo />}
                    actionButtonsEnabled={false}
                  >
                    <CustomIconButton
                      iconTooltipIntlKey="TOOLTIP.CLICK_FOR_INFOTOOLTIP"
                      iconClassName="fas fa-info-circle"
                      customStyles={{
                        color: settings.colors.primary,
                        margin: '5px 20px'
                      }}
                    />
                  </ConfirmDialog>
                </div>
                <div className={classes.dataContainer}>
                  <div>
                    <InputRow
                      name="name"
                      label="REQUEST_NEW_USER.COMPANY_NAME"
                      {...fieldProps}
                    />
                    <InputRow
                      label="REQUEST_NEW_USER.CVR_NUMBER"
                      name="cvr"
                      {...fieldProps}
                    />
                  </div>
                  <div>
                    <InputRow
                      label="REQUEST_NEW_USER.ADDRESS"
                      name="address"
                      {...fieldProps}
                    />
                    <InputRow
                      label="REQUEST_NEW_USER.POSTAL_NO"
                      name="postal_code"
                      {...fieldProps}
                    />
                    <InputRow
                      label="REQUEST_NEW_USER.CITY"
                      name="city"
                      {...fieldProps}
                    />
                  </div>
                </div>
              </div>
              <div>
                <InputRow
                  label="REQUEST_NEW_USER.RECIPIENT_EMAIL"
                  name="recipient_email"
                  disaled={true}
                  {...fieldProps}
                />
                <InputRow
                  label="REQUEST_NEW_USER.OTHER_RECIPIENT_EMAIL"
                  name="other_recipient_email"
                  {...fieldProps}
                />
              </div>
            </div>
            <div className={classes.footer}>
              <PrimaryButton onClick={onBackClick}>
                {intl.formatMessage({
                  id: 'BUTTON.BACK'
                })}
              </PrimaryButton>
              <PrimaryButton onClick={handleSubmit}>
                {intl.formatMessage({
                  id: 'BUTTON.PRINT_ATTORNEY'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={resestUserWizard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ requestUserWizard }) => {
  const {
    userData: { firstStep, secondStep }
  } = requestUserWizard;

  const secondStepData = {
    ...secondStep,
    recipient_email: firstStep.email,
    portMobileNumber: firstStep.mobile_number
  };
  return {
    secondStepData
  };
};

const mapDispatchToProps = {
  setUserData: reduxActions.setUserData,
  resestUserWizard: reduxActions.resestUserWizard,
  attorneySubmit
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(PortNumberStep);
