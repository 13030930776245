import store from '../../../store/store';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import agentEventListener from './agentEventListener';
import agentInCallListener from './agentInCallListener';
import { actions as reduxActions } from '../reducers';
import { isDefined, isUndefined } from '../../../utils/isDefined';

export default function agentsSubscriptions({ userId }) {
  const subscriptions = store.getState().queues.subscriptions;

  const subscriptionAleadyExist = isDefined(
    subscriptions.find(el => el === userId)
  );
  const { mitelToken, baseUrl, clientDomain } = getMitelRequestProps();

  if (isUndefined(mitelToken) || subscriptionAleadyExist) {
    return;
  }
  agentInCallListener({
    mitelToken,
    baseUrl,
    clientDomain,
    userId
  });
  agentEventListener({
    mitelToken,
    baseUrl,
    clientDomain,
    userId
  });

  return store.dispatch(reduxActions.updateSubscriptions(userId));
}
