import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import TableActionButtons from './TableActionButtons';
import React from 'react';

export default function tableColumns() {
  const columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      width: '50px'
    },
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'TABLE.DESCRIPTION',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.description, term);
      },
      field: 'description'
    },
    {
      title: 'TABLE.CONDITIONS',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.conditions, term);
      },
      field: 'conditions'
    },
    {
      title: 'TABLE.REMOTE',
      field: 'remote',
      type: 'boolean'
    },
    {
      title: 'ID',
      field: 'id',
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}
