import { mapCellNumbersToDisplay } from '../../../pages/dataLimits/actions/tableColumnsActions';
import { createMonthsArray } from './mapDatalimits';

export default async function mapDataForExport({
  data,
  lastMonthsCount,
  showAll
}) {
  const [actualMonth, ...months] = createMonthsArray(lastMonthsCount + 1);

  const mappedData = [...data].map(el => {
    const {
      tableData,
      lastMonthUsage = [],
      actualMonthUsage = {},
      cell_numbers,
      totalAverage = [],
      fairAverage = [],
      worldAverage = [],
      ...restEl
    } = el;

    const actualUsage = {
      [`usageGB_${actualMonth.date}`]: actualMonthUsage.total_data_string,
      [`fairUsageGB_${actualMonth.date}`]: actualMonthUsage.roamString,
      [`worldUsageGB_${actualMonth.date}`]: actualMonthUsage.data_roam_others_string
    };

    const usage = lastMonthUsage
      .slice(0, lastMonthsCount)
      .reduce((obj, usage, i) => {
        return {
          ...obj,
          [`usageGB_${months[i].date}`]: usage.total_data_string,
          [`fairUsageGB_${months[i].date}`]: usage.roamString,
          [`worldUsageGB_${months[i].date}`]: usage.data_roam_others_string
        };
      }, {});

    const cellNumbers = `\t${mapCellNumbersToDisplay(el, false)}`;

    return {
      ...restEl,
      ...usage,
      cellNumbers,
      ...actualUsage,
      totalAverage: showAll
        ? totalAverage[lastMonthsCount - 1]
        : totalAverage[2],
      fairAverage: showAll ? fairAverage[lastMonthsCount - 1] : fairAverage[2],
      worldAverage: showAll
        ? worldAverage[lastMonthsCount - 1]
        : worldAverage[2]
    };
  });

  return { mappedData };
}
