import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import styles from './styles';
import { isUndefined } from '../../utils/isDefined';
import { mapFilterValue } from '../../utils/tableUtils';
import Optional from '../../utils/optional';
import clsx from 'clsx';

function CustomCopyToClipboard({
  value,
  classes,
  intl,
  isPhoneNumber = true,
  customTooltip,
  customClasses = {},
  placement = 'bottom-start'
}) {
  if (isUndefined(value)) {
    return null;
  }

  const text = isPhoneNumber ? mapDanishNumber(value) : mapFilterValue(value);

  return (
    <CopyToClipboard
      className={clsx(classes.root, customClasses.root)}
      text={text}
      onCopy={() =>
        toast.success(
          intl.formatMessage({
            id: 'MESSAGE.COPIED_TO_CLIPBOARD'
          }),
          { autoClose: 1000 }
        )
      }
    >
      <Tooltip
        title={Optional(customTooltip).or(
          intl.formatMessage({
            id: 'TOOLTIP.COPY_TO_CLIPBOARD'
          })
        )}
        placement={placement}
      >
        <div>{value}</div>
      </Tooltip>
    </CopyToClipboard>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CustomCopyToClipboard);

export function mapDanishNumber(number = '') {
  return number
    .trim()
    .replace('+45', '')
    .replace(/\s+/g, '');
}
