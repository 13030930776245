import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, REQUEST_NEW_USER } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export function newUserRequest(data) {
  return {
    type: sagaActions[REQUEST_NEW_USER],
    data
  };
}

export function* requestNewUser({ data }) {
  const mappedData = yield call(mapData, data);
  
  const response = yield call(postData, {
    url: `${URLS.USERS}/new`,
    data: {
      data: mappedData
    }
  });

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(
      showSnackbar,{
        intlTextKey: 'MESSAGE.REQUEST_SEND',
        messageType: 'success'
      }
    );
  }

  yield put(reduxActions.resetRequestUserDialog());
}

function* watchUpdatePass() {
  yield takeLatest(sagaActions[REQUEST_NEW_USER], requestNewUser);
}

export default watchUpdatePass();

export function mapData(data) {
  const { call_recording, voice_mail } = data;

  return {
    ...data,
    call_recording: call_recording ? 'yes' : 'no',
    voice_mail: voice_mail ? 'yes' : 'no'
  };
}
