import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_USERS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import createClientsLookup from './createClientsLookup';
import getFullTableData from '../../../utils/getFullTableData';

export function getUsers(client) {
  return {
    type: sagaActions[GET_USERS],
    client
  };
}

export function* fetchUsers({ client }) {
  yield put(loaderReducActions.startSpinLoader());

  const QUERY_PARAMS = yield call(mapQueryParams, { client });

  const users = yield call(getFullTableData, JSONAPI_URLS.USERS, QUERY_PARAMS);

  let clientsLookupOptions = {};

  if (isUndefined(client)) {
    clientsLookupOptions = yield call(createClientsLookup, users);
  }

  yield put(reduxActions.setUsers(users, clientsLookupOptions));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetUsers() {
  yield takeLatest(sagaActions[GET_USERS], fetchUsers);
}

export default watchGetUsers();

export function mapQueryParams({ client }) {
  return {
    sort: 'name',
    include: ['sim_cards.cell_numbers', 'roles', 'department', 'client'],
    fields: {
      departments: 'name',
      sim_cards: 'cell_numbers',
      roles: 'name',
      clients: 'name'
    },
    filter: {
      client_id: isDefined(client) ? { eq: client } : { gt: 0 }
    }
  };
}
