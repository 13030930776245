import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import checkNumberData from '../../../utils/checkNumberData';
import styles from '../styles/userData';
import CustomTitle from './CustomTitle';
import { createValueLabel, mapValue } from '../actions/dataActions';
import { translate } from '../../../i18n/I18nProvider';
import changeClient from '../../../utils/changeClient';
import history from '../../../../history';
import { actions as rightPanelReduxActions } from '../../../partials/rightPanel/reducers';
import withSettings from '../../../utils/withSettings';
import { isDefined } from '../../../utils/isDefined';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';
import Optional from '../../../utils/optional';

function UserData({
  classes,
  user = {},
  fromRightPanel,
  rightPanelData = {},
  rightPanelSimcardData = {},
  simcard = {},
  closeRightPanel,
  settings
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: user,
      rightPanelData,
      fromRightPanel
    });
  }, [user, rightPanelData, fromRightPanel]);

  const { isDataExist: isSimcardDataExist, data: simcardData } = useMemo(() => {
    return checkNumberData({
      numberData: simcard,
      rightPanelData: rightPanelSimcardData,
      fromRightPanel
    });
  }, [simcard, rightPanelSimcardData, fromRightPanel]);

  const closeRightPanelAction = useCallback(
    () => {
      if (fromRightPanel) {
        closeRightPanel();
      }
    },
    // eslint-disable-next-line
    [fromRightPanel]
  );

  const onClientClick = async () => {
    closeRightPanelAction();

    const { client } = simcardData;

    await changeClient({ client });

    history.push(`/${client.id}/telephonyUsers`);
  };

  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.TELEPHONY_FUNCTION" isDataExist={isDataExist}>
        {isSimcardDataExist &&
        isDefined(simcardData.client?.connect30_domain) ? (
          <div className={classes.dataContainer}>
            <div className={classes.dataKey}>{translate('CLIENT')}</div>
            <div
              onClick={onClientClick}
              style={{
                color: settings.colors.links,
                cursor: 'pointer'
              }}
            >
              <CopyToClipbaordTooltip
                value={Optional(simcardData.client?.name).or('')}
              >
                {simcardData.client?.name}
              </CopyToClipbaordTooltip>
            </div>
          </div>
        ) : (
          <div className={classes.dataContainer}>
            <div className={classes.dataKey}>{translate('CLIENT')}</div>
            <CopyToClipbaordTooltip
              value={Optional(simcardData.client?.name).or('')}
            >
              <div>{simcardData.client?.name}</div>
            </CopyToClipbaordTooltip>
          </div>
        )}

        {isDataExist &&
          Object.keys(data).map((key, i) => {
            const value = mapValue(data[key]);
            const label = createValueLabel(key);

            if (key === 'name' || key === 'number') {
              return (
                <div
                  className={classes.dataContainer}
                  key={i}
                  style={{
                    backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFF'
                  }}
                >
                  <div className={classes.dataKey}>{label}</div>
                  <CopyToClipbaordTooltip value={Optional(value).or('')}>
                    <div>{value}</div>{' '}
                  </CopyToClipbaordTooltip>
                </div>
              );
            }
            return (
              <div
                className={classes.dataContainer}
                key={i}
                style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFF' }}
              >
                <div className={classes.dataKey}>{label}</div>
                <div>{value}</div>
              </div>
            );
          })}
        {isSimcardDataExist && (
          <div
            className={classes.dataContainer}
            style={{ backgroundColor: '#FAFAFA' }}
          >
            <div className={classes.dataKey}>Email</div>
            <CopyToClipbaordTooltip
              value={Optional(simcardData.owner?.email).or('')}
            >
              <div>{simcardData.owner?.email}</div>
            </CopyToClipbaordTooltip>
          </div>
        )}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.user,
    user: checkNumber.user,
    rightPanelSimcardData: rightPanel.number?.simcardData,
    simcard: checkNumber.simcardData
  };
};

const mapDispatchToProps = {
  closeRightPanel: rightPanelReduxActions.closeRightPanel
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserData);
