import { createReduxActionTypes } from '../../../utils/actionFactory';
import { isUndefined } from '../../../utils/isDefined';

const OPEN_USER_WIZARD = 'OPEN_USER_WIZARD';
const RESET_USER_WIZARD = 'RESET_USER_WIZARD';
const SET_USER_DATA = 'SET_USER_DATA';
const SET_SELECT_OPTIONS = 'SET_SELECT_OPTIONS';
const SET_MOBILE_SUBSCRIPTIONS = 'SET_MOBILE_SUBSCRIPTIONS';
const SET_CHECK_NUMBER_DATA = 'SET_CHECK_NUMBER_DATA';
const SET_ATTRONEY_SEND = 'SET_ATTRONEY_SEND';
const SET_ACCOUNT = 'SET_ACCOUNT';

export const actionTypes = createReduxActionTypes('REQUEST_UZER_WIZARD', [
  OPEN_USER_WIZARD,
  RESET_USER_WIZARD,
  SET_USER_DATA,
  SET_SELECT_OPTIONS,
  SET_CHECK_NUMBER_DATA,
  SET_MOBILE_SUBSCRIPTIONS,
  SET_ATTRONEY_SEND,
  SET_ACCOUNT
]);

const DEFAULT_STATE = {
  open: false,
  userData: {
    firstStep: {
      first_name: '',
      last_name: '',
      email: '',
      department: '',
      mobile_number: '',
      internal_number: '',
      landline_number: '',
      copy_from_user: '',
      custom_id: '',
      isNewNumber: false,
      account: '',
      fields: [],
      cost_center: ''
    },
    secondStep: {
      current_sim_card_number: '',
      move_number_date: null,
      current_operator: '',
      name: '',
      cvr: '',
      address: '',
      postal_code: '',
      city: '',
      other_recipient_email: '',
      is_old_subscripion_date: false
    },
    thirdStep: {
      mobile_subscription: '',
      data_sharing_card: false,
      data_sharing_sim_card_number: '',
      data_subscription: '',
      mobile_sim_card_number: '',
      data_sim_card_number: '',
      have_mobile_sim_card: false,
      have_data_sharing_sim_card: false,
      network: '',
      isDisabledNetworkSelect: false
    },
    fourthStep: {
      notes: '',
      activation_date: null,
      activationAsap: false,
      additionalValues: null,
      att: ''
    }
  },
  selectOptions: {},
  mobileSupscriptions: null,
  attorneySend: false,
  checkNumberData: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_USER_WIZARD]: {
      return {
        ...state,
        userData: {
          ...state.userData,
          firstStep: {
            ...state.userData.firstStep,
            fields: action.dirFields
          }
        },
        open: true
      };
    }
    case actionTypes[SET_ATTRONEY_SEND]: {
      return {
        ...state,
        attorneySend: true
      };
    }
    case actionTypes[SET_SELECT_OPTIONS]: {
      return {
        ...state,
        selectOptions: { ...action.selectOptions }
      };
    }
    case actionTypes[SET_ACCOUNT]: {
      return {
        ...state,
        userData: {
          ...state.userData,
          firstStep: {
            ...state.userData.firstStep,
            account: action.account
          },
          fourthStep: {
            ...state.userData.fourthStep,
            ...action.fourthStep
          }
        }
      };
    }
    case actionTypes[SET_CHECK_NUMBER_DATA]: {
      return {
        ...state,
        checkNumberData: { ...action.checkNumberData }
      };
    }
    case actionTypes[SET_MOBILE_SUBSCRIPTIONS]: {
      return {
        ...state,
        mobileSubscriptions: [...action.mobileSubscriptions]
      };
    }
    case actionTypes[SET_USER_DATA]: {
      const { userData, step } = action;
      return {
        ...state,
        userData: {
          ...state.userData,
          [step]: isUndefined(userData)
            ? DEFAULT_STATE.userData[step]
            : { ...state.userData[step], ...userData }
        }
      };
    }
    case actionTypes[RESET_USER_WIZARD]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openUserWizard: dirFields => ({
    type: actionTypes[OPEN_USER_WIZARD],
    dirFields
  }),
  setAttorneySend: () => ({
    type: actionTypes[SET_ATTRONEY_SEND]
  }),
  setUserData: (userData, step) => ({
    type: actionTypes[SET_USER_DATA],
    userData,
    step
  }),
  setSelectOptions: selectOptions => ({
    type: actionTypes[SET_SELECT_OPTIONS],
    selectOptions
  }),
  setAccount: (account, fourthStep) => ({
    type: actionTypes[SET_ACCOUNT],
    account,
    fourthStep
  }),
  setCheckNumberData: checkNumberData => ({
    type: actionTypes[SET_CHECK_NUMBER_DATA],
    checkNumberData
  }),
  setMobileSubscriptions: mobileSubscriptions => ({
    type: actionTypes[SET_MOBILE_SUBSCRIPTIONS],
    mobileSubscriptions
  }),
  resestUserWizard: () => ({ type: actionTypes[RESET_USER_WIZARD] })
};
