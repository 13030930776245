import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';

const getEsimStatus = async number => {
  const status = await getData(
    `${URLS.CARRIER}/cell_number/${number}/esim_status`
  );

  return Optional(status?.state).or(void 0);
};

export default getEsimStatus;
