import watchGetUserContacts from '././getUserContacts';
import watchManageStatus from './manageStatus';
import watchGetContactData from './getContactData';
import watchGetUserCallLog from './getUserCallLog';
import watchDeleteCallLogItem from './deleteCallLogItem';
import watchGetUserVoicemails from './getUserVoicemails';
import watchDeleteContact from './deleteContact';
import watchPostSms from './postSms';
import watchGetGroups from './getGroups';

const watchUserContacts = [
  watchGetUserContacts,
  watchManageStatus,
  watchGetContactData,
  watchGetUserCallLog,
  watchDeleteCallLogItem,
  watchGetUserVoicemails,
  watchDeleteContact,
  watchPostSms,
  watchGetGroups
];

export default watchUserContacts;
