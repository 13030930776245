import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CheckboxSection from './components/CheckboxSection';
import ReportSection from './components/ReportSection';
import ActionButtons from './components/ActionButtons';
import changeValuesForFormik from '../../utils/changeValuesForFormik';
import { updateOnSubmit } from './actions/updateDatalimit';
import changeObjectValues from '../../utils/changeObjectValues';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import validateSchema from './actions/validateSchema';
import Optional from '../../utils/optional';
import DatalimitInfo from './components/DatalimitInfo';

const EditDatalimit = ({
  classes,
  open,
  intl,
  resetEditDatalimit,
  datalimit,
  updateOnSubmit,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId,
  client
}) => {
  const onSubmit = useCallback(
    values => {
      updateOnSubmit({
        datalimit: changeObjectValues(values, '', null),
        client,
        fromUserDetails,
        fromLookup,
        fromRightPanel,
        lookupNumber,
        datalimitId
      });
    },
    // eslint-disable-next-line
    [fromUserDetails, client, fromLookup, fromRightPanel, lookupNumber]
  );

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={resetEditDatalimit}
    >
      <CustomDialogTitle onCloseClick={resetEditDatalimit}>
        {intl.formatMessage({
          id: 'EDIT.EDIT_DATALIMIT'
        })}
      </CustomDialogTitle>
      <DialogContent className={classes.content}>
        <DatalimitInfo datalimit={datalimit} />
        <Formik
          initialValues={changeValuesForFormik({
            values: datalimit,
            fromNull: true
          })}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validateSchema()}
        >
          {({ handleSubmit, errors, dirty, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field component={CheckboxSection} clientId={client} />
                <Field component={ReportSection} clientId={client} />
                <div className={classes.footer}>
                  <ActionButtons
                    onCloseClick={resetEditDatalimit}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStatesToProps = ({ editDatalimit, selectClient }) => {
  return {
    ...editDatalimit,
    client: Optional(editDatalimit.clientId).or(selectClient.selectedClient.id)
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditDatalimit);
