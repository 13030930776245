import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';
import { isBoolean } from '../../../utils/isDefined';

import styles from '../styles/projections';

function Projections({ projection = [], classes, intl }) {
  return (
    <table className={classes.root}>
      <tbody>
        <tr className={classes.row}>
          <th>{intl.formatMessage({ id: 'NAME' })}</th>
          <th>{intl.formatMessage({ id: 'VALUE' })}</th>
        </tr>
        {projection.map(el => {
          const value = isBoolean(el.value)
            ? el.value
              ? intl.formatMessage({ id: 'ENABLED' })
              : intl.formatMessage({ id: 'DISABLED' })
            : el.value;

          return (
            <tr key={`${el.name}-${el.key}`} className={classes.row}>
              <td className={classes.severity}>{el.name}</td>
              <td className={classes.cell}>{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Projections);
