export default {
  root: {
    margin: '10px'
  },
  cardInfo: {
    fontSize: '12px',
    fontStyle: 'italic',
    maxWidth: '400px',
    padding: '10px'
  },
  sectionContainer: {},
  sectionLabel: {
    fontSize: '14px',
    color: '#000000',
    padding: '10px'
  },
  dataContainer: {
    display: 'flex'
  },
  moveNumber: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #EBEDF2',
    padding: '10px',
    '& button': {
      margin: '10px'
    }
  }
};
