const styles = () => {
  return {
    root: {
      marginTop: '10px',

      padding: '5px 0',
      display: 'flex'
    },
    schemasContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#FFF'
    },
    form: {
      marginLeft: '10px',
      width: '45vw',
      backgroundColor: '#FFF',
      padding: '10px',
      overflow: 'auto'
    }
  };
};

export default styles;
