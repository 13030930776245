import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isStringDefined } from '../../../utils/isDefined';

export default function() {
  return yup.object().shape({
    och_inactive_at: yup
      .string()
      .when(['future_status'], {
        is: future_status => isStringDefined(future_status),
        then: yup.string().required(translate('VALIDATION.INACTIVE_DATE'))
      })
      .nullable()
  });
}
