import React from "react";

import withSettings from "../../../utils/withSettings";

const EnabledCell = ({ enabled, settings }) => {
  const color = enabled ? settings.colors.success : settings.colors.danger;

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {enabled ? (
        <i class="fa fa-check-circle" style={{ color }}></i>
      ) : (
        <i className="fa fa-times-circle" style={{ color }}></i>
      )}
    </div>
  );
};

export default withSettings(EnabledCell);
