import { isUndefined } from './isDefined';
import Optional from './optional';
import formater from 'currency-formatter';

export default function currencyFormatter(
  value = 0,
  currency,
  useDefault = true
) {
  if (!useDefault && isUndefined(currency)) {
    return value;
  }

  const definedCurrency = Optional(currency).or('DKK');

  if (definedCurrency !== 'DKK') {
    return value;
  }

  return formater.format(value, {
    locale: 'da-DK',
    code: definedCurrency,
    thousand: '.',
    format: '%v %s',
    decimal: ','
  });
}
