export default {
  root: { width: '400px', backgroundColor: '#FFFFFF', marginBottom: ' 20px' },
  label: {
    margin: '10px 0 30px 50px'
  },
  info: {
    fontSize: '10px',
    textAlign: 'center'
  }
};
