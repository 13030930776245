import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React from 'react';

import withSettings from '../../utils/withSettings';
import styles from './styles';

function CustomDialogTitle({
  classes,
  children,
  onCloseClick,
  settings,
  isWarning,
  customClasses = {}
}) {
  return (
    <div
      className={clsx(classes.root, customClasses.root)}
      style={{
        backgroundColor: isWarning
          ? settings.colors.warning
          : settings.colors.modal_header,
        color: isWarning
          ? settings.colors.white
          : settings.colors.modal_header_text
      }}
    >
      <div className={classes.label} id="draggable-dialog-title">
        {children}
      </div>
      <i
        className={clsx('fas fa-times', classes.closeIcon)}
        style={{
          color: isWarning
            ? settings.colors.white
            : settings.colors.modal_header_text
        }}
        onClick={onCloseClick}
      ></i>
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(CustomDialogTitle);
