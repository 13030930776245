import { Tooltip, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import styles from './styles';
import clsx from 'clsx';

const DataBoxHashed = ({
  label,
  value,
  classes,
  width,
  infoIcon = false,
  infoIconText,
  shouldDataBeHashed = false
}) => {
  const [hashed, setHashed] = useState(shouldDataBeHashed);

  return (
    <div
      className={classes.root}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={infoIconText}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <div
        className={classes.value}
        onClick={() => (shouldDataBeHashed ? setHashed(!hashed) : () => {})}
      >
        {!hashed ? value : '#'.repeat(value.length)}
      </div>
    </div>
  );
};

export default withStyles(styles)(DataBoxHashed);
