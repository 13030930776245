import React from 'react';
import changeClient from '../../../utils/changeClient';
import history from '../../../../history';
import flowRight from 'lodash.flowright';
import withSettings from '../../../utils/withSettings';
import { isUndefined } from '../../../utils/isDefined';
import store from '../../../store/store';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';

const ClientCell = ({ settings, clientId }) => {
  const onClientClick = async () => {
    const { data: client } = await getData(
      `${URLS.CLIENTS}/${clientId}.json?include=partner`
    );
    await changeClient({ client });
    history.push(`/${client.id}/dashboard`);
  };

  return (
    <div
      style={{
        color: settings.colors.links,
        cursor: 'pointer'
      }}
      onClick={onClientClick}
    >
      {findClientName(clientId)}
    </div>
  );
};

export default flowRight(withSettings)(ClientCell);

const findClientName = clientId => {
  const clientList = store.getState().selectClient?.selectClientsList;
  if (isUndefined(clientList)) {
    return '';
  }
  const foundedClient = clientList.find(client => client.id === clientId);

  if (isUndefined(foundedClient)) {
    return '';
  }

  return foundedClient.name;
};
