const styles = theme => {
  return {
    content: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;

export const CUSTOM_STYLES = {
  selectRow: {
    field: { width: '650px', paddingLeft: '10px' },

    label: { width: '240px', marginLeft: '5px' },
    value: { width: '140px', paddingRight: '300px' }
  },
  textFieldRow: {
    label: {
      width: '240px'
    },
    root: {
      width: '650px'
    },
    textfield: {
      width: '360px'
    }
  }
};
