import { updateData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export default async function updateValue({
  value,
  label,
  id,
  type,
  urlKey,
  errorIntlId
}) {
  try {
    const { data } = await updateData({
      url: `${URLS[urlKey]}/${id}`,
      data: {
        data: {
          type: type,
          id,
          attributes: {
            [label]: value
          }
        }
      },
      errorsToPass: [RESPONSE_CODES.UNPROCESSABLE_ENTITY]
    });

    if (isDefined(data.data) && isDefined(data.data.attributes)) {
      return { value: data.data.attributes[label] };
    }

    return { value: '' };
  } catch (e) {
    showSnackbar({
      intlTextKey: errorIntlId,
      messageType: 'error',
      time: 8000
    });

    return { error: e.response };
  }
}
