import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import {
  doesArrayHasLength,
  isArrayEmpty,
  isDefined,
  isObject
} from '../../../utils/isDefined';
import { showCustomSnackbar } from '../../../utils/showSnackbar';
import bulkEditPhonebookRegistration from './bulkEditPhonebookRegistration';
import mapDataForSend from './mapDataForSend';
import multieditPhonebookRegistration from './multieditPhonebookRegistration';
import phoneBookRegistrationRequest from './phoneBookRegistrationRequest';

const editPhoneBookRegistration = async ({
  values,
  numberRangeId,
  phoneNumber,
  allNumbersArray,
  isMultiedit,
  numbersForMultiedit,
  registrationData,
  defaultClientAddress
}) => {
  if (isMultiedit) {
    const bulkEditResponse = await multieditPhonebookRegistration({
      numbersForMultiedit,
      values,
      defaultClientAddress
    });

    if (isObject(bulkEditResponse)) {
      const { errors, completed } = bulkEditResponse;

      if (doesArrayHasLength(completed)) {
        showCustomSnackbar({
          messageType: 'success',
          component: <CompletedSnackbar completed={completed} />
        });
      }

      return isArrayEmpty(errors) ? true : false;
    }

    return false;
  }

  if (isDefined(allNumbersArray)) {
    const bulkEditResponse = await bulkEditPhonebookRegistration({
      allNumbersArray,
      numberRangeId,
      values,
      registrationData,
      defaultClientAddress
    });

    if (isObject(bulkEditResponse)) {
      const { errors, completed } = bulkEditResponse;

      if (doesArrayHasLength(completed)) {
        showCustomSnackbar({
          messageType: 'success',
          component: <CompletedSnackbar completed={completed} />
        });
      }

      return isArrayEmpty(errors) ? true : false;
    }

    return false;
  } else {
    const mappedData = mapDataForSend({
      values,
      numberRangeId,
      phoneNumber,
      defaultClientAddress
    });

    const requestResponse = await phoneBookRegistrationRequest(mappedData);

    return requestResponse;
  }
};

export default editPhoneBookRegistration;

const CompletedSnackbar = ({ completed = [] }) => {
  return (
    <div>
      <div>{translate('MESSAGE.STATUS_UPDATED')}</div>
      <div>{completed.join(', ')}</div>
    </div>
  );
};
