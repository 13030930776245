import { call } from 'redux-saga/effects';

import { getData } from './http';
import { JSONAPI_URLS } from '../consts/endpoints';
import urlCreator from './urlCreator';
import Optional from './optional';
import { mitelRequest } from './mitelRequest';
import { isArray } from './isDefined';

export default function* selectOptionsCreator(selectsArray) {
  let selectOptions = {};

  for (let item of selectsArray) {
    const {
      endpoint,
      queryParams,
      number,
      ignoreNoneOption,
      customEndpoint,
      key,
      ignore,
      isDomainProvided = true,
      startFrom,
      customKey
    } = item;

    if (number) {
      selectOptions = {
        ...selectOptions,
        ...(yield call(createNumbersOptions, {
          key: endpoint,
          number,
          startFrom
        }))
      };
    } else {
      let response;

      if (isDomainProvided) {
        response = !ignore
          ? endpoint
            ? yield call(
                getData,
                urlCreator(JSONAPI_URLS[endpoint], {
                  ...queryParams,
                  page: { size: 1000 }
                })
              )
            : yield call(mitelRequest, {
                options: { url: customEndpoint },
                errorResponseReturnValue: []
              })
          : [];
      } else {
        response = [];
      }

      const fetchedData = Optional(response.data).or(response);

      selectOptions = {
        ...selectOptions,
        ...(yield call(selectOptionMapper, {
          customKey,
          key: Optional(endpoint).or(key),
          fetchedData,
          ignoreNoneOption
        }))
      };
    }
  }

  return selectOptions;
}

export function selectOptionMapper({
  customKey,
  key,
  fetchedData,
  ignoreNoneOption
}) {
  if (!isArray(fetchedData)) {
    return {};
  }
  let mappedData = [];
  if (customKey) {
    switch (customKey) {
      case 'DOMAIN_LANGUAGES': {
        mappedData = fetchedData
          .map(item => {
            return { ...item, value: item.languageId, label: item.languageId };
          })
          .sort((a, b) => a.languageId < b.languageId);
        break;
      }
      default: {
        mappedData = fetchedData.map(item => {
          return { value: item.id, label: item.id };
        });
      }
    }
  } else {
    switch (key) {
      case 'ROLES': {
        mappedData = fetchedData
          .filter(item => item.assignable === true)
          .map(item => {
            return {
              value: Optional(item.id)
                .map(elem => Number(elem))
                .or(item.user_id),
              label: item.name
            };
          })
          .sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          );
        break;
      }
      case 'PROVIDERS': {
        mappedData = fetchedData
          .map(item => {
            return {
              value: item.id,
              label: item.name
            };
          })
          .sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          );
        break;
      }
      default: {
        mappedData = fetchedData
          .map(item => {
            return {
              value: Optional(item.id)
                .map(elem => Number(elem))
                .or(item.user_id),
              label: item.name
            };
          })
          .sort((a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
          );
      }
    }
  }

  const optionsKey = Optional(customKey)
    .or(key)
    .toLowerCase();

  if (ignoreNoneOption) {
    return { [optionsKey]: [...mappedData] };
  }
  return {
    [optionsKey]: [{ value: '', label: 'None' }, ...mappedData]
  };
}

export function createNumbersOptions({ key, number, startFrom = 0 }) {
  let numberOptions = [];

  for (let i = startFrom; i < number; i++) {
    numberOptions = [...numberOptions, { value: i, label: i }];
  }

  const optionsKey = key.toLowerCase();

  return {
    [optionsKey]: [...numberOptions]
  };
}
