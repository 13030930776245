import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/dataLimits';
import { translate } from '../../../i18n/I18nProvider';
import DatalimitStats from '../../../partials/datalimitStats/DatalimitStats';
import { actions as reduxActions } from '../reducers';

const DataLimits = ({
  classes,
  selectedNumber,
  setSelectedNumber = {},
  selectOptions
}) => {
  const onNumberChange = useCallback(
    selected => {
      if (selected.value !== selectedNumber.value) {
        setSelectedNumber(selected, 'selectedNumber');
      }
    },
    // eslint-disable-next-line
    [selectedNumber.value]
  );

  return (
    <div className={classes.root}>
      <header className={classes.title}>
        {translate('NUMBER.DATA_USAGE_TITLE')}
        <div className={classes.titleActions}>
        </div>
      </header>
      <DatalimitStats
        datalimitId={selectedNumber.datalimitId}
        selectOptions={selectOptions}
        selectedNumber={selectedNumber}
        onNumberChange={onNumberChange}
      />
    </div>
  );
};

const mapStatesToProps = ({ user }) => {
  const { selectedNumber = {}, selectOptions } = user;

  return {
    selectedNumber,
    selectOptions
  };
};

const mapDispatchToProps = {
  setSelectedNumber: reduxActions.setSelectedNumber
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DataLimits);
