import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_CONNNECTIONS = 'SET_CONNNECTIONS';
const RESET_CONNNECTIONS = 'RESET_CONNNECTIONS';
const SET_ACCOUNTS = 'SET_ACCOUNTS';
const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
const TOOGLE_SWITCH = 'TOOGLE_SWITCH';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('CONNNECTIONS', [
  SET_CONNNECTIONS,
  RESET_CONNNECTIONS,
  SET_ACCOUNTS,
  SET_SELECTED_ACCOUNT,
  TOOGLE_SWITCH,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  connections: [],
  selectOptions: [],
  selectedAccount: {},
  showTerminated: false,
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_CONNNECTIONS]: {
      return { ...state, connections: [...action.connections] };
    }
    case actionTypes[SET_ACCOUNTS]: {
      const { accounts, selectOptions, selectedAccount } = action;
      return { ...state, accounts, selectOptions, selectedAccount };
    }
    case actionTypes[SET_SELECTED_ACCOUNT]: {
      const { selectedAccount } = action;
      return {
        ...state,
        selectedAccount
      };
    }
    case actionTypes[TOOGLE_SWITCH]: {
      const { showTerminated } = action;
      return {
        ...state,
        showTerminated
      };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_CONNNECTIONS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setConnections: connections => ({
    type: actionTypes[SET_CONNNECTIONS],
    connections
  }),
  setAccounts: (accounts, selectOptions, selectedAccount) => ({
    type: actionTypes[SET_ACCOUNTS],
    accounts,
    selectOptions,
    selectedAccount
  }),
  setSelectedAccount: selectedAccount => ({
    type: actionTypes[SET_SELECTED_ACCOUNT],
    selectedAccount
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  toggleShowTerminated: showTerminated => ({
    type: actionTypes[TOOGLE_SWITCH],
    showTerminated
  }),
  resetConnections: () => ({ type: actionTypes[RESET_CONNNECTIONS] })
};
