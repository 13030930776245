import { call } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, GET_CHARGE_LINES } from './types';
import { getData } from '../../../utils/http';
import { isUndefined } from '../../../utils/isDefined';
import { groupBy } from 'lodash';

export function fetchChargeLines({
  clientId,
  details,
  setChargeLinees,
  setChargeLineesFetched,
  invoiceGrouping
}) {
  return {
    type: sagaActions[GET_CHARGE_LINES],
    clientId,
    details,
    setChargeLinees,
    setChargeLineesFetched,
    invoiceGrouping
  };
}

export function* getChargeLines({
  clientId,
  details,
  setChargeLinees,
  setChargeLineesFetched,
  invoiceGrouping
}) {
  const { invoiceNumber, level1, level2, level3, level4 } = details;
  const formattedLevels = yield call(formatLevels, [
    level1,
    level2,
    level3,
    level4
  ]);

  let chargeLines = '';

  try {
    const response = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/invoices/${invoiceNumber}/charge_lines${formattedLevels}&groupingOption=${invoiceGrouping}`
    );

    const grouped = Object.entries(
      groupBy(response.chargeLines, line => line.external_reference)
    );

    chargeLines = grouped.reduce((arr, el) => {
      const [, lines = []] = el;
      return [...arr, ...lines];
    }, []);

    yield call(setChargeLinees, chargeLines);
    yield call(setChargeLineesFetched, true);
  } catch {}
}

function* watchGetChargeLines() {
  yield takeLatest(sagaActions[GET_CHARGE_LINES], getChargeLines);
}

export default watchGetChargeLines();

function formatLevels(levelArray) {
  return levelArray.reduce((str, level, i) => {
    if (isUndefined(level)) {
      return str;
    }

    const levelText = i === 0 ? `level${i + 1}` : `&level${i + 1}`;
    return `${str}${levelText}=${encodeURIComponent(level)}`;
  }, '?');
}
