import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback
} from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import withDetailsView from '../../../utils/withDetailsView';
import Optional from '../../../utils/optional';
import styles from '../styles';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import AddButton from '../../../partials/tableCustomButtons/AddButton';
import tableColumns from './tableColumns';
import { actions as orderSimcardReduxActions } from '../../../modals/orderNewSimcard/reducers';
import { actions as reduxActions } from '../reducers';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import SimcardsTableHeader from './SimcardsTableHeader';
import { translate } from '../../../i18n/I18nProvider';
import fetchSubscriptions from '../actions/fetchSubscriptions';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import EditSubscription from './ChangeSubscription';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

const SimCardsIoTTable = ({
  isLoading,
  orderSimcard,
  location,
  columns,
  setColumns,
  classes,
  hasClientAccounts,
  toggleActivateDialog,
  subscriptions,
  fetchSubscriptions,
  lang,
  selectedAccount
}) => {
  const [filtering, setFiltering] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const client = useMemo(() => {
    return getIdFromUrl(location);
  }, [location]);

  useEffect(() => {
    if (isDefined(selectedAccount.value)) {
      fetchSubscriptions(client, selectedAccount);
    }
    // eslint-disable-next-line
  }, [client, selectedAccount]);

  useEffect(() => {
    if (refresh && isDefined(selectedAccount.value)) {
      fetchSubscriptions(client, selectedAccount);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh, selectedAccount]);

  const tableRef = useRef();

  const tableDefaultColumns = useMemo(
    () =>
      tableColumns({
        client,
        lang
      }),
    // eslint-disable-next-line
    [client, lang]
  );

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Simcards-IoT_1'
    });
  }, [setColumns, tableDefaultColumns, client]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: translate('BUTTON.FILTER')
        },
        {
          icon: () => <AddButton buttonText="SIMCARD.ACTIVATE_SIMCARD" />,
          onClick: () => toggleActivateDialog(true),
          isFreeAction: true,
          hidden: !can(
            'update',
            createSubject('Quote::Subscription', { client_id: Number(client) })
          )
        },
        {
          icon: () => <AddButton buttonText="BUTTON.NEW_SIM_CARD" />,
          onClick: () => orderSimcard(true),
          isFreeAction: true,
          hidden: !hasClientAccounts ? true : false
        }
      ];

      if (selectedAccount?.value !== 'all') {
        return [
          ...buttons,
          {
            tooltip: translate('BUTTON.MULTIEDIT'),
            onClick: () => {},
            icon: () => (
              <EditSubscription isMultiedit={true} selectedRows={selectedRows}>
                <CustomIconButton iconClassName="far fa-edit" />
              </EditSubscription>
            )
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, selectedRows, selectedAccount]
  );

  const localization = useMemo(() => {
    return {
      ...setLocalization()
    };
    // eslint-disable-next-line
  }, []);

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      filtering,
      selection: selectedAccount?.value !== 'all' ? true : false,
      selectionProps: rowData => ({
        disabled: rowData.status !== 'ACTIVE'
      })
    };
  }, [filtering, selectedAccount]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Simcards-IoT_1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  const onSelectRow = rows => {
    setSelectedRows(rows);
  };

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <MTableToolbar
            classes={{
              title: classes.toolbarTitle,
              spacer: classes.toolbarSpacer,
              actions: classes.toolbarActions,
              highlight: classes.toolbarHighlight
            }}
            {...props}
          />
        )
      }}
      title={<SimcardsTableHeader />}
      icons={customTableIcons}
      actions={actions}
      localization={localization}
      columns={columns}
      data={subscriptions}
      options={tableOptions}
      tableRef={tableRef}
      onChangeColumnHidden={onChangeColumnHidden}
      onSelectionChange={rows => onSelectRow(rows)}
      isLoading={isLoading}
    />
  );
};

const mapStatesToProps = ({ iotProducts, selectClient, loader, auth }) => {
  return {
    ...iotProducts,
    isLoading: loader.isSpinVisible,
    clientName: Optional(selectClient.selectedClient)
      .map(client => client.name)
      .or(''),
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false),
    lang: auth.lang
  };
};

const mapDispatchToProps = {
  orderSimcard: orderSimcardReduxActions.openOrderSimcard,
  setColumns: reduxActions.setColumns,
  toggleActivateDialog: reduxActions.toggleActivateDialog,
  fetchSubscriptions
};

export default flowRight(
  withRouter,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SimCardsIoTTable);
