import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_USERS = 'SET_USERS';
const RESET_USERS = 'RESET_USERS';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('TELEPHONY_USERS', [
  SET_USERS,
  RESET_USERS,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  users: [],
  columns: [],
  dirFields: [],
  filtersOptions: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_USERS]: {
      const { users, dirFields, filtersOptions } = action;
      return {
        ...state,
        users,
        dirFields,
        filtersOptions
      };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_USERS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setUsers: (users, dirFields, filtersOptions) => ({
    type: actionTypes[SET_USERS],
    users,
    dirFields,
    filtersOptions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetUsers: () => ({ type: actionTypes[RESET_USERS] })
};

export const getters = {
  getUsers: state => state.telephonyUsers.users
};
