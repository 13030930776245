import { withStyles } from '@material-ui/styles';
import { Field, FieldArray, Form, Formik } from 'formik';
import { flowRight } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import SelectRow from '../../../partials/dataRows/SelectRow';
import ActionsButtons from './ActionsButtons';
import UserRoles from './UserRoles';
import styles from '../styles';
import validateSchema from '../actions/validateSchema';
import InformationSection from './InformationSection';
import changeObjectValues from '../../../utils/changeObjectValues';
import { updateOnSubmit } from '../actions/updateUser';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import { withRouter } from 'react-router';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';
import { actions as reduxActions } from '../reducers';
import { changeClientUsersOptions } from '../actions/changeClientUsersOptions';

function UserGeneralDataForm({
  classes,
  changeClientUsersOptions,
  isDomainProvided,
  clientDomain,
  selectsOptions = {},
  updateOnSubmit,
  location,
  user,
  resetUser,
  isBeTable
}) {
  const client = useMemo(() => getIdFromUrl(location), [location]);
  
  const onSubmit = useCallback(
    values => {
      updateOnSubmit({
        user: changeObjectValues(values, '', null),
        clientId: Optional(values.client_id).or(client),
        isAdminView: isUndefined(client),
        initialUserRoles: Optional(user.roles).or([]),
        clientDomain,
        isBeTable
      });
    },
    // eslint-disable-next-line
    [user, updateOnSubmit, isBeTable]
  );
  return (
    <Formik
      initialValues={{
        ...changeObjectValues(user, null, ''),
        fromAdminPage: isUndefined(client),
        isDomainProvided,
        generate_welcome_email: false,
        pass: ''
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema().user}
    >
      {({
        handleSubmit,
        dirty,
        errors,
        values,
        setFieldValue,
        touched,
        initialValues
      }) => {
        const changeClientAction = (fieldName, clientId) => {
          changeClientUsersOptions(clientId);
          setFieldValue(fieldName, clientId);
        };
        return (
          <Form onSubmit={handleSubmit}>
            {!client && !user.id ? (
              <SelectRow
                errors={errors}
                touched={touched}
                setFieldValue={changeClientAction}
                values={values}
                name="client_id"
                label="SELECT_CLIENT"
                options={selectsOptions.clients}
              />
            ) : null}
            <div className={classes.content}>
              <Field
                component={InformationSection}
                selectsOptions={selectsOptions}
                initialValues={initialValues}
                isDomainProvided={isDomainProvided}
              />
              <FieldArray
                name="roles"
                render={arrayHelpers => (
                  <UserRoles
                    options={selectsOptions.roles}
                    arrayHelpers={arrayHelpers}
                  />
                )}
              />
            </div>
            <footer className={classes.footer}>
              <ActionsButtons
                onCloseClick={resetUser}
                handleSubmit={handleSubmit}
                dirty={dirty}
                errors={errors}
              />
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({ editUser, selectClient }) => {
  return {
    ...editUser,
    clientDomain: Optional(selectClient.selectedClient)
      .map(client => client.connect30_domain)
      .or(null)
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit,
  changeClientUsersOptions
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(UserGeneralDataForm);
