import { isStringEmpty, isArray } from '../../../utils/isDefined';

export default function mapLinesSelectOptions(lines) {
  if (!isArray(lines)) {
    return { linesOptions: [], selectedLine: {} };
  }
  const linesOptions = lines.map(line => {
    return {
      ...line,
      value: line.number,
      label: createLineOptionText(line)
    };
  });

  const selectedLine = findSelectedLine(linesOptions);

  return { linesOptions, selectedLine };
}

function createLineOptionText(line) {
  const { type, number, name } = line;
  const lowercaseType = type
    .split('_')
    .join(' ')
    .toLowerCase();

  const completeType = lowercaseType.charAt(0).toUpperCase() + lowercaseType.slice(1);
  
  return `${number} (${!isStringEmpty(name) ? name : completeType})`;
}

function findSelectedLine(lines = []) {
  return lines.find(line => line.active === true);
}
