import mapDataForSend from './mapDataForSend';
import phoneBookRegistrationRequest from './phoneBookRegistrationRequest';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import store from '../../../store/store';

const multieditPhonebookRegistration = async ({
  numbersForMultiedit = [],
  values = {},
  defaultClientAddress
}) => {
  store.dispatch(loaderReduxActions.startSpinLoader());
  let errors = [];
  let completed = [];

  await Promise.all(
    numbersForMultiedit.map(async el => {
      const mappedData = mapDataForSend({
        values: { ...values, id: el.id },
        numberRangeId: el.rangeId,
        phoneNumber: el.number,
        isMultiedit: true,
        defaultClientAddress
      });

      const response = await phoneBookRegistrationRequest(mappedData, true);

      if (!response) {
        errors = [...errors, el.number];
      } else {
        completed = [...completed, el.number];
      }
    })
  );

  store.dispatch(loaderReduxActions.stopSpinLoader());
  return { completed, errors };
};

export default multieditPhonebookRegistration;
