const styles = theme => ({
  root: {
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    margin: '5px 0',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 10px)',
      margin: '5px 0'
    }
  }
});

export default styles;
