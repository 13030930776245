import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';

function LockText({ classes, intl, isLocking }) {
  return (
    <div className={classes.textRoot}>
      {isLocking
        ? intl.formatMessage({
            id: 'USER.LOCK_QUESTION'
          })
        : intl.formatMessage({
            id: 'USER.UNLOCK_QUESTION'
          })}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(LockText);
