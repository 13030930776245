import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import Select from 'react-select';
import { Form, Formik } from 'formik';

import { translate } from '../../../i18n/I18nProvider';
import DraggableDialog from '../../../partials/DraggableDialog';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import styles, {
  customSelectStyles,
  customInputStules
} from '../styles/activateSimcard';
import { actions as reduxActions } from '../reducers';
import getProducts from '../actions/getProducts';
import withSettings from '../../../utils/withSettings';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import InputRow from '../../../partials/dataRows/InputRow';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import Optional from '../../../utils/optional';
import {
  isArray,
  isArrayEmpty,
  isDefined,
  isString,
  isUndefined
} from '../../../utils/isDefined';
import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import activateAction from '../actions/activateAction';
import clsx from 'clsx';
import DatepickerRow from '../../../partials/dataRows/DatepickerRow';
import moment from 'moment';

const ActivateSimcard = ({
  classes,
  toggleActivateDialog,
  open,
  clientId,
  settings,
  accounts,
  selectedAccount,
  toogleActivationSummaryPopup
}) => {
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [account, setAccount] = useState(null);
  const [template, setTemplate] = useState(void 0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [errorText, setErrorText] = useState(void 0);

  useEffect(() => {
    if (open) {
      if (isUndefined(account)) {
        if (
          isDefined(selectedAccount?.value) &&
          selectedAccount?.value !== 'all'
        ) {
          setAccount(selectedAccount);
        } else {
          setAccount(null);
        }
      }
      if (isDefined(account)) {
        (async function() {
          await getProducts({
            clientId,
            setIsLoading,
            setProducts,
            accountReference: account.externalReference
          });
        })();
      }
    }
    // eslint-disable-next-line
  }, [clientId, open, account]);

  useEffect(() => {
    if (isDefined(product) && product?.value !== '' && isDefined(account)) {
      (async function() {
        setIsLoading(true);
        const response = await getData(
          `${URLS.QUOTE_CLIENTS}/${clientId}/products/${product.value}/templates?account_reference=${account.externalReference}`
        );

        setTemplate(response[0]);
        setFormData(mapTemplate(response[0]));
        setIsLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [product, account, clientId]);

  const onCloseDialog = () => {
    setAccount(null);
    setProducts([]);
    setProduct(null);
    setTemplate(void 0);
    toggleActivateDialog(false);
  };

  const handleConfirm = useCallback(
    async values => {
      setErrorText(void 0);
      setIsLoading(true);
      const { id, error } = await activateAction({
        values,
        template,
        sku: product?.value,
        accountReference: account?.externalReference,
        clientId
      });

      if (isDefined(error)) {
        setErrorText(error);
      }

      if (isDefined(id)) {
        toogleActivationSummaryPopup(true, id);
        setFormData(mapTemplate(template));
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [template, product, account, clientId]
  );

  const accountsOptions = useMemo(() => {
    return accounts.filter(account => account.value !== 'all');
  }, [accounts]);

  const handleAccountChange = selected => {
    setAccount(selected);
    setProduct(null);
    setTemplate(void 0);
  };

  const handleChange = selected => {
    setTemplate(void 0);
    setProduct(selected);
  };

  const productsOptions = useMemo(() => {
    return Optional(products[account?.externalReference]).or([]);
  }, [account, products]);

  const priceText = useMemo(() => {
    return translate('ACTIVATION_SIMCARD.PRICE_TEXT', {
      ...findProductPrices(product)
    });
  }, [product]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('SIMCARD.ACTIVATE_SIMCARD')}
        </CustomDialogTitle>
        {isLoading && (
          <div className={classes.loaderOverlay}>
            <CircularProgress />
          </div>
        )}
        <DialogContent className={classes.dialogContent}>
          <div
            className={classes.root}
            style={{ color: settings.colors.primary }}
          >
            {isDefined(errorText) && (
              <div
                className={classes.error}
                style={{ color: settings.colors.danger }}
              >
                {errorText}
              </div>
            )}

            {accounts.length > 1 && (
              <div className={classes.selectContainer}>
                <div>{translate('ACCOUNT')}</div>
                <Select
                  customStyles={customSelectStyles}
                  options={accountsOptions}
                  styles={reactSelectDefaultStyles}
                  value={account}
                  onChange={handleAccountChange}
                />
              </div>
            )}
            <div className={classes.selectContainer}>
              <div>{translate('REQUEST_NEW_USER.SUBSCRIPTION_STEP')}</div>
              <Select
                customStyles={customSelectStyles}
                options={productsOptions}
                styles={reactSelectDefaultStyles}
                value={product}
                onChange={handleChange}
                isDisabled={
                  isUndefined(account) || isArrayEmpty(productsOptions)
                }
              />
            </div>
            <div
              className={clsx(classes.pricesContainer, {
                [classes.hidden]: isUndefined(product)
              })}
            >
              <div>{priceText}</div>
              <Tooltip
                title={Optional(product?.description)
                  .map(description => {
                    if (!isString(description)) {
                      return '';
                    }
                    return (
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    );
                  })
                  .or('')}
              >
                <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
              </Tooltip>
            </div>

            <Formik
              initialValues={formData}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleConfirm}
            >
              {({
                handleSubmit,
                errors,
                values,
                handleBlur,
                handleChange,
                setFieldValue
              }) => {
                const fieldProps = {
                  errors,
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue
                };

                return (
                  <Form onSubmit={handleSubmit} className={classes.root}>
                    <div className={classes.sectionContainer}>
                      {isArray(template?.attributes) &&
                        template.attributes.map(el => {
                          const { label } = el;

                          if (el.valueType === 'Date') {
                            return (
                              <DatepickerRow
                                key={el.name}
                                labelText={label}
                                name={label}
                                errors={errors}
                                values={values}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                customStyles={customInputStules}
                                format="yyyy-MM-dd"
                                placeholder="yyyy-mm-dd"
                              />
                            );
                          }
                          return (
                            <InputRow
                              key={el.name}
                              labelText={label}
                              name={label}
                              customStyles={customInputStules}
                              {...fieldProps}
                            />
                          );
                        })}
                    </div>

                    <div className={classes.footer}>
                      <PrimaryButton onClick={handleSubmit}>
                        {translate('BUTTON.CONFIRM')}
                      </PrimaryButton>
                      <SecondaryButton onClick={onCloseDialog}>
                        {translate('BUTTON.CANCEL')}
                      </SecondaryButton>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ iotProducts, selectClient, loader }) => {
  return {
    open: iotProducts.activateDialogOpen,
    clientId: selectClient.selectedClient?.id,
    isLoading: loader.isSpinVisible,
    accounts: iotProducts.accounts,
    selectedAccount: iotProducts.selectedAccount
  };
};

const mapDispatchToProps = {
  toggleActivateDialog: reduxActions.toggleActivateDialog,
  startLoader: loaderReduxActions.startSpinLoader,
  stopLoader: loaderReduxActions.stopSpinLoader,
  toogleActivationSummaryPopup: reduxActions.toggleActivationSummary
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ActivateSimcard);

const mapTemplate = template => {
  if (isUndefined(template)) {
    return {};
  }
  const { attributes } = template;

  return attributes.reduce((obj, el) => {
    if (
      el.valueType === 'Date' &&
      el.name === 'phone-and-sim-card.activation-date'
    ) {
      return { ...obj, [el.label]: Optional(el.defaultValue).or(moment()) };
    }

    return { ...obj, [el.label]: Optional(el.defaultValue).or('') };
  }, {});
};

const findProductPrices = product => {
  if (isUndefined(product?.prices) || !isArray(product?.prices)) {
    return {
      setupPrice: '',
      price: ''
    };
  }

  const foundedPrices = product?.prices.find(el => el.sku === product.sku);

  if (isUndefined(foundedPrices)) {
    return {
      setupPrice: '',
      price: ''
    };
  }

  return {
    setupPrice: Optional(foundedPrices.setupPrice).or(0),
    price: Optional(Number(foundedPrices.price) / 100).or(0)
  };
};
