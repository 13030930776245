import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from './styles';
import QueuesTable from './components/QueuesTable';
import { actions as reduxActions } from './reducers';
import { getQueues } from './actions/getQueues';
import QueueStats from '../../modals/queueStats/QueueStats';
import withUserCheck from '../../utils/withUserCheck';
import queuesEventListener from './actions/queuesEventListener';
import UserViewQueuesTable from './components/UserViewQueuesTable';
import EditQueue from '../../modals/editQueue/EditQueue';

export function Queues({
  classes,
  getQueues,
  resetQueues,
  clientId,
  connectDomain,
  isAdminUser,
  isCurrentUserSelectedClientUser,
  currentUserConnectId,
  queuesToken = {},
  isUsersView
}) {
  useEffect(() => {
    getQueues({
      connectDomain,
      isAdminUser,
      isCurrentUserSelectedClientUser,
      currentUserConnectId,
      sortQueues: true
    });
    return resetQueues;
    // eslint-disable-next-line
  }, [clientId]);

  useEffect(() => {
    if (Object.keys(queuesToken).length > 0) {
      queuesEventListener({
        queuesToken,
        userMitelId: currentUserConnectId
      });
    }
    // eslint-disable-next-line
  }, [queuesToken]);

  return (
    <div className={classes.root}>
      {isUsersView ? <UserViewQueuesTable /> : <QueuesTable />}
      <QueueStats />
      <EditQueue/>
    </div>
  );
}

const mapStatesToProps = ({ queues, auth, selectClient }) => {
  return {
    ...queues,
    connectDomain: selectClient.selectedClient.connect30_domain,
    userId: auth.user.id,
    customJobs: auth.customJobs
  };
};

const mapDispatchToProps = {
  getQueues,
  resetQueues: reduxActions.resetQueues
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Queues);
