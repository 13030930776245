export default {
  root: {
    width: '100%',
    backgroundColor: '#FAFAFA',
    padding: '10px 0'
  },
  dataRow: {
    display: 'flex',
    paddingLeft: '60px',
    alignItems: 'center'
  },
  dataBox: { display: 'flex', flexDirection: 'column' },
  rowTitle: {
    width: '100px',
    fontWeight: 600
  },
  rowDark: {
    backgroundColor: '#FAFAFA',
    width: '100%'
  },
  title: { padding: '5px 10px', fontSize: '13px' },
  fieldName: { width: '250px' },
  noData: {
    padding: '20px',
    height: '60px'
  },
  loaderOverlay: {
    height: '60px',
    width: '150px',
    background: '#FFF',
    opacity: '.5',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsInfo: {
    padding: '10px 0 10px 70px',
    fontSize: '12px'
  }
};
