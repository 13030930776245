import moment from 'moment';

import { translate } from '../../../i18n/I18nProvider';
import {
  isDefined,
  isStringDefined,
  isUndefined
} from '../../../utils/isDefined';

import store from '../../../store/store';
import Optional from '../../../utils/optional';

export default function mapDataForDetalView(data = []) {
  const reversedData = [...data].reverse();
  const mappedData = reversedData.map(el => {
    const { prompt } = el.action;
    const duration = createDurationString(el.limits);
    const promptText = createPromtText(prompt);
    return {
      ...el.action,
      ...el.limits,
      promptText: Optional(promptText).or(prompt),
      duration,
      name: el.name
    };
  });

  const sortableSchedules = mappedData.filter(
    schedule => schedule.name !== 'default'
  );

  const defaultSchedule = mappedData.filter(
    schedule => schedule.name === 'default'
  );

  return { sortableSchedules, defaultSchedule };
}

function createDurationString(limits = {}) {
  const { recurrenceType, start = {}, end = {} } = limits;
  let duration = '';

  if (isDefined(recurrenceType) && recurrenceType !== 'NONE')
    duration = `${translate(recurrenceType)}`;

  duration = `${duration}${
    (isStringDefined(duration) &&
      (isDefined(start.date) || isDefined(end.date))) ||
    isDefined(start.time) ||
    isDefined(end.time)
      ? ', '
      : ''
  }${formatDate(start.date)}${isDefined(start.date) ? ' - ' : ''}${formatDate(
    end.date
  )}${
    (isDefined(start.date) || isDefined(end.date)) &&
    (isDefined(start.time) || isDefined(end.time))
      ? ', '
      : ''
  }${formatTime(start.time)}${
    isDefined(start.time) || isDefined(end.time) ? ' - ' : ''
  }${formatTime(end.time)}`;

  return duration;
}

function formatTime(time) {
  if (isUndefined(time)) {
    return '';
  }
  return moment(time)
    .tz('UTC')
    .format('HH:mm');
}

function formatDate(date) {
  if (isUndefined(date)) {
    return '';
  }
  return moment(date).format('YYYY-MM-DD');
}

function createPromtText(promptId) {
  const prompts = store.getState().schedules.prompts;

  const promptData = prompts.find(prompt => prompt.id === promptId);

  if (isUndefined(promptData)) {
    return void 0;
  }

  return `${promptData.name} - ${promptData.description}`;
}
