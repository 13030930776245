import { createTheme } from '@material-ui/core';

const styles = theme => {
  return {
    root: {
      width: '100%',
      marginBottom: '2px',
      display: 'flex',
      backgroundColor: '#FFF'
    },
    surchargeInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: '10px'
    },
    actionButtons: {
      margin: '5px 10px',
      display: 'flex',
      '& i': {
        margin: '5px 10px',
        cursor: 'pointer',
        fontSize: '24px'
      }
    },
    documentContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 5px',
      border: '1px solid #ECECF2',
      borderRadius: '4px',
      marginRight: '10px',
      cursor: 'pointer'
    },
    actionCell: { padding: '0 5px', color: '#93A2DD' }
  };
};

export default styles;

export const themes = {
  table: createTheme({
    overrides: {
      MuiPaper: {
        elevation2: {
          boxShadow: 'unset'
        }
      },
      MuiTableSortLabel: {
        root: {
          fontWeight: 700,
          fontSize: '14px'
        }
      },
      MuiTouchRipple: {
        root: {
          top: 'unset'
        }
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiTableCell: {
        head: { fontWeight: 700, fontSize: '14px' },
        root: {
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '8px'
        },
        sizeSmall: {
          padding: '6px 10px'
        }
      }
    }
  })
};
