import SELECTS_OPTIONS, {
  createStatuses
} from '../../../consts/selectsOptions';
import { translate } from '../../../i18n/I18nProvider';

export default function prepareSelectsOptions(selectsOptions) {
  const { providers = [] } = selectsOptions;
  return {
    providers: [{ value: '', label: translate('NO_CHANGES') }, ...providers],
    platform: [
      { value: '', label: translate('NO_CHANGES') },
      ...SELECTS_OPTIONS.PLATFORM_OPTIONS
    ],
    status: [
      { value: '', label: translate('NO_CHANGES') },
      ...createStatuses()
    ],
    futureStatus: [
      { value: '', label: translate('NO_CHANGES') },
      ...createStatuses()
    ]
  };
}
