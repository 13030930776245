const styles = theme => {
  return {
    root: {
      width: '70vw',
      backgroundColor: '#FFF',
      display: 'inherit',
      flexDirection: 'column',
      margin: '5px 0',
      paddingBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    title: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
      fontSize: '18px',
      borderBottom: '1px solid #EBEDF2'
    },
    section: {
      marginTop: '5px'
    },
    sectionTitle: {
      fontSize: '16px',
      paddingLeft: '10px'
    },
    colorsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    msKeyContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  };
};

export default styles;
