import React, { forwardRef } from 'react';
import CustomIcon from './CustomIcon';

export default {
  Export: forwardRef((props, ref) => (
    <CustomIcon ref={ref} {...props} icon="fas fa-download" size={'14px'} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <CustomIcon ref={ref} {...props} icon="fas fa-bars" size={'14px'} />
  )),
  Edit: forwardRef((props, ref) => (
    <CustomIcon
      ref={ref}
      {...props}
      icon="far fa-edit"
      size={'18px'}
      settingsColor={true}
      customStyles={{ height: '35px' }}
    />
  ))
};
