import React from 'react';
import { withStyles } from '@material-ui/styles';

import { CONST_VALUES } from '../../consts/initialValues';
import styles from './styles';

function AppVersion({ classes, customStyles }) {
  return (
    <div
      className={classes.root}
      style={customStyles}
    >{`v${CONST_VALUES.VERSION}`}</div>
  );
}

export default withStyles(styles)(AppVersion);
