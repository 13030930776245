import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles/reportSection';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import DataInputBox from '../../../partials/dataBox/DataInputBox';
import { translate } from '../../../i18n/I18nProvider';
import DataCheckboxRow from '../../../partials/dataBox/DataCheckboxRow';

const ReportSection = ({ classes, form, clientId }) => {
  return (
    <div className={classes.root}>
      <DataCheckboxRow
        label={translate('DATA_LIMITS.REPORT_TO')}
        name="report_only_to"
        infoIcon={true}
        infoIconText={translate('DATA_LIMITS.TOOLTIP.REPORT_TO')}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_only_to'
          )
        }
        {...form}
      />
      <DataInputBox
        label={translate('DATA_LIMITS.REPORT_SMS')}
        name="report_to_sms"
        infoIcon={true}
        infoIconText={translate('DATA_LIMITS.TOOLTIP.REPORT_SMS')}
        width={570}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_to_sms'
          )
        }
        {...form}
      />
      <DataInputBox
        label={translate('DATA_LIMITS.REPORT_EMAIL')}
        infoIcon={true}
        infoIconText={translate('DATA_LIMITS.TOOLTIP.REPORT_EMAIL')}
        name="report_to_email"
        width={570}
        disabled={
          !can(
            'update',
            createSubject('DataLimit', {
              client_id: Number(clientId)
            }),
            'report_to_email'
          )
        }
        {...form}
      />
    </div>
  );
};

export default flowRight(withStyles(styles))(ReportSection);
