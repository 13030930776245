import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';

import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import styles, { customSelectStyles } from '../styles';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';

const TableTitle = ({
  classes,
  setShowAll,
  showAll,
  intl,
  lastMonthsCount,
  setLastMonthsCount,
  monthsCount
}) => {
  const onChange = e => {
    setLastMonthsCount(e.value);
  };

  return (
    <div className={classes.tableTitle}>
      <div className={classes.titleButtons}>
        {!showAll ? (
          <PrimaryButton>
            {intl.formatMessage({
              id: 'BUTTON.STANDARD VIEW'
            })}
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={() => setShowAll(false)}>
            {intl.formatMessage({
              id: 'BUTTON.STANDARD VIEW'
            })}
          </SecondaryButton>
        )}
        {showAll ? (
          <PrimaryButton>
            {intl.formatMessage({
              id: 'BUTTON.FULL VIEW'
            })}
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={() => setShowAll(true)}>
            {intl.formatMessage({
              id: 'BUTTON.FULL VIEW'
            })}
          </SecondaryButton>
        )}
      </div>
      {showAll && (
        <div className={classes.selectContainer}>
          <div>{`${intl.formatMessage({
            id: 'SHOW'
          })}`}</div>
          <Select
            styles={reactSelectDefaultStyles}
            customStyles={customSelectStyles}
            options={monthsCount}
            value={{ value: lastMonthsCount, label: lastMonthsCount }}
            onChange={onChange}
          />
          <div>{`${intl.formatMessage({
            id: 'DATA_LIMITS.LAST_MONTHS'
          })}`}</div>
        </div>
      )}
    </div>
  );
}

const mapStatesToProps = ({ dataLimitsUsage }) => {
  return {
    lastMonthsCount: dataLimitsUsage.lastMonthsCount,
    monthsCount: Optional(dataLimitsUsage.selectsOptions.monthscount).or([])
  };
};

const mapDispatchToProps = {
  setLastMonthsCount: reduxActions.setLastMonthsCounts
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableTitle);
