import React from 'react';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { translate } from '../../../i18n/I18nProvider';

const ActionsButtons = ({ onCloseClick, handleSubmit, dirty }) => {
  return (
    <>
      <SecondaryButton onClick={onCloseClick}>
        {translate('BUTTON.CANCEL')}
      </SecondaryButton>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {translate('BUTTON.SAVE')}
      </PrimaryButton>
    </>
  );
};

export default ActionsButtons;
