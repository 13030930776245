import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    firstname: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    lastname: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    email: yup.string().email(translate('AUTH.VALIDATION.INVALID_FIELD')),
    phoneNumbers: yup.array().of(
      yup.object().shape({
        phoneNumber: yup
          .string()
          .required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
    )
  });
}
