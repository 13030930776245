import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import { translate } from '../../../i18n/I18nProvider';
import { isDefined } from '../../../utils/isDefined';
import {
  customSearchByProduct,
  customSearchByProductCategory,
  findSkuTranslation
} from '../actions/customSearchActions';
import capitaliseFirstLetter from '../../../utils/capitaliseFirstLetter';

const tableColumns = ({lang, groupByCategory,}) => {
  const columns = [
    {
      title: capitaliseFirstLetter(translate('SUBSCRIPTIONS.GROUP_BY_SKU')),
      customFilterAndSearch: (term, rowData) => {
        return customSearchByProduct({ rowData, term, lang });
      },
      field: 'skuTranslations',
      render: rowData => {
        return findSkuTranslation({ rowData, lang });
      }
    },
    {
      title: translate('SUBSCRIPTIONS.ITEM_NUMBER'),
      customFilterAndSearch: (term, rowData) => {
        return customSearchByProduct({ rowData, term });
      },
      field: 'name'
    },
    {
      title: translate('SUBSCRIPTIONS.PRODUCT_CATEGORY'),
      customFilterAndSearch: (term, rowData) => {
        return !groupByCategory
          ? customSearchByProduct({ rowData, term })
          : customSearchByProductCategory({ rowData, term });
      },
      field: 'category',
      showForCategory: true
    },
    {
      title: translate('QUANTITY'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.quantity, term);
      },
      field: 'quantity',
      showForCategory: true
    }
  ];

  if (groupByCategory) {
    return columns.filter(column => isDefined(column.showForCategory));
  }

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;


