export default {
  root: {
    paddingTop: '20px',
    fontSize: '12px'
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '20px'
  },
  container: {
    display: 'flex',
    marginTop: '20px'
  }
};

export const checkboxCustomStyles = {
  root: { width: '235px' }
};
