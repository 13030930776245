import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_DATALIMIT = 'SET_DATALIMIT';
const RESET_DATALIMIT = 'RESET_DATALIMIT';
const SET_UPDATE = 'SET_UPDATE';
export const actionTypes = createReduxActionTypes('SHOW_DATALIMIT', [
  SET_DATALIMIT,
  RESET_DATALIMIT,
  SET_UPDATE
]);

const DEFAULT_STATE = {
  open: false,
  datalimitId: null,
  datalimitName: '',
  update: false,
  updateAfterClose: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_DATALIMIT]: {
      const { datalimitId, datalimitName, updateAfterClose = false } = action;
      return {
        ...state,
        datalimitId,
        datalimitName,
        updateAfterClose,
        open: true
      };
    }
    case actionTypes[SET_UPDATE]: {
      const { update } = action;
      return {
        ...state,
        update
      };
    }
    case actionTypes[RESET_DATALIMIT]: {
      return { ...DEFAULT_STATE, update: state.update };
    }
    default:
      return state;
  }
};

export const actions = {
  openShowDatalimit: (datalimitId, datalimitName, updateAfterClose) => ({
    type: actionTypes[SET_DATALIMIT],
    datalimitId,
    datalimitName,
    updateAfterClose
  }),
  setUpdate: update => ({ type: actionTypes[SET_UPDATE], update }),
  resetShowDatalimit: () => ({ type: actionTypes[RESET_DATALIMIT] })
};
