import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import flowRight from 'lodash.flowright';
import React, { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { STATUSES_ICONS } from '../../../consts/initialValues';

import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { isStringDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import { deleteItem } from '../actions/deleteCallLogItem';
import styles from '../styles/callLog';
import { actions as addContactReduxActions } from '../../../modals/addExternalContact/reducers';

const CALL_ICONS = {
  INCOMING_CALL: 'fal fa-arrow-to-bottom',
  MISSED_CALL: 'fal fa-arrow-to-bottom',
  OUTGOING_CALL: 'fal fa-arrow-from-bottom'
};

function CallLogCard({
  classes,
  data = {},
  settings,
  deleteItem,
  intl,
  openAddContacts
}) {
  const [isHovered, setIsHovered] = useState(false);
  const isMissedCall = useMemo(() => data.itemType === 'MISSED_CALL', [
    data.itemType
  ]);

  const onDeleteClick = useCallback(() => {
    deleteItem({
      action: 'DELETE_LOG',
      itemId: data.itemId
    });
    // eslint-disable-next-line
  }, [data.itemId]);

  const onAddClick = useCallback(() => {
    openAddContacts({ number: data.remoteParty });
    // eslint-disable-next-line
  }, [data.remoteParty]);

  return (
    <div
      className={classes.cardRoot}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.cardCallContainer}>
        <div
          className={classes.cardName}
          style={{
            color: isMissedCall
              ? settings.colors.danger
              : settings.colors.primary
          }}
        >
          {isStringDefined(data.displayedName)
            ? data.displayedName
            : data.number}
        </div>
        <div className={classes.cardIcons}>
          <ConfirmDialog
            onConfirmClick={onDeleteClick}
            dialogContentComponent={intl.formatMessage({
              id: 'CONTACTS.DELETE_ONE_CALL'
            })}
            dialogTitleIntlId={'DELETE'}
          >
            <i
              className={clsx('fal fa-trash', classes.cardIcon, {
                [classes.deleteIconvisibility]: !isHovered
              })}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          </ConfirmDialog>
          {isUndefined(data.remoteParty.contactId) && (
            <i
              onClick={onAddClick}
              className={clsx(
                'fas fa-user-plus',
                classes.cardIcon,
                classes.addIcon,
                {
                  [classes.deleteIconvisibility]: !isHovered
                }
              )}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          )}
          {data.isFavorite && (
            <i
              className={clsx(
                STATUSES_ICONS['FAVORITE'],
                classes.cardIcon,
                classes.favoriteIcon
              )}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          )}
          <a href={`tel:${data.number}`}>
            <i
              className={clsx('fal fa-phone-alt', classes.cardIcon)}
              style={{
                color: isHovered ? settings.colors.primary : '#BBBBBB'
              }}
            ></i>
          </a>
        </div>
      </div>
      <div className={classes.cardCallInfo}>
        <div
          style={{
            color: isMissedCall ? settings.colors.danger : settings.colors.links
          }}
        >
          {
            <i
              className={clsx(CALL_ICONS[data.itemType], classes.callIcon)}
            ></i>
          }
        </div>
        <div className={classes.cardCallInfoEl}>{data.dateToShow}</div>|
        <div className={classes.cardCallInfoEl}>{data.duration}</div>|
        <div className={classes.cardCallInfoEl}>{data.number}</div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  deleteItem,
  openAddContacts: addContactReduxActions.openAddContact
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(CallLogCard);
