import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';

export function ActionsButtons({ onCloseClick, handleSubmit, dirty, intl }) {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {intl.formatMessage({
          id: 'BUTTON.SAVE'
        })}
      </PrimaryButton>
      <SecondaryButton onClick={onCloseClick}>
        {intl.formatMessage({
          id: 'BUTTON.CANCEL'
        })}
      </SecondaryButton>
    </>
  );
}

export default flowRight(injectIntl)(ActionsButtons);
