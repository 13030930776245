import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import ConfirmDialog from '../../../partials/confirmationDialog/ConfirmDialog';
import { removePermission } from '../actions/deletePermission';
import DeletePermissionConfimation from './DeletePermissionConfimation';
import { doesArrayHasLength } from '../../../utils/isDefined';
import { actions as addPermissionReduxActions } from '../../../modals/addPermission/reducers';

export function TableActionButtons({
  data: permission,
  classes,
  removePermission,
  openCreatePermission
}) {
  const onEditClick = useCallback(
    () => {
      openCreatePermission(permission, true);
    },
    // eslint-disable-next-line
    [permission]
  );

  return (
    <div className={classes.actionButtons}>
      <ConfirmDialog
        onConfirmClick={() => removePermission(permission.id)}
        dialogContentComponent={<DeletePermissionConfimation />}
        dialogTitleIntlId={'TOOLTIP.DELETE_PERMISSION'}
      >
        <CustomIconButton
          disabledIconTooltipIntlKey="TOOLTIP.DELETE_PERMISSION_INFO"
          iconTooltipIntlKey="TOOLTIP.DELETE_PERMISSION"
          iconClassName="far fa-trash-alt"
          disabled={doesArrayHasLength(permission.roles)}
        />
      </ConfirmDialog>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.EDIT_PERMISSION"
        onClick={onEditClick}
        iconClassName="far fa-edit"
      />
    </div>
  );
}

const mapDispatchToProps = {
  removePermission,
  openCreatePermission: addPermissionReduxActions.openAddPermission
};

export default flowRight(
  injectIntl,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
