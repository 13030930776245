import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { setRange } from '../../../modals/editRange/actions/setRange';

export function NumberRangeMenu({
  children,
  intl,
  openEditNumberRange,
  openShowHistory,
  numberRange,
  rightPanelNumber,
  number,
  fromRightPanel
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = useCallback(
    () => {
      openEditNumberRange({
        rangeId: numberRange.id,
        lookupNumber: fromRightPanel ? rightPanelNumber : number,
        fromRightPanel,
        fromLookup: true
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [numberRange]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: numberRange.id,
        itemType: 'ExternalNumberRange',
        historyObject: numberRange,
        itemTypeKey: 'MENU.NUMBER_RANGE',
        titleText: `${numberRange.range_from} - ${numberRange.range_up_to_incl}`
      });
      setAnchorEl(null);
    },
    // eslint-disable-next-line
    [numberRange.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {can(
          'update',
          createSubject('ExternalNumberRange', {
            client_id: Number(numberRange.client?.id)
          })
        ) && (
          <MenuItem onClick={onEditClick}>
            {intl.formatMessage({
              id: 'TOOLTIP.EDIT_RANGE'
            })}
          </MenuItem>
        )}

        <MenuItem onClick={onHistoryClick}>
          {intl.formatMessage({
            id: 'TOOLTIP.SHOW_HISTORY'
          })}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelNumber: rightPanel.number?.number,
    number: checkNumber.number
  };
};

const mapDispatchToProps = {
  openShowHistory: showHistoryReduxActions.openShowHistory,
  openEditNumberRange: setRange
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(NumberRangeMenu);
