import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    name: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    fair_usage_limit: yup
      .string()
      .matches(/^[-,-.0-9]+$/, translate('VALIDATION.ONLY_DIGITS')),
    block_limit_mb: yup
      .string()
      .matches(/^[-,-.0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
  });
}
