const styles = theme => {
  return {
    root: { width: '500px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    messageContainer: {
      visibility: 'hidden',
      height: '15px',
      textAlign: 'center',
      color: ''
    },
    infoText: {
      width: '100%',
      textAlign: 'right',
      fontSize: '10px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left'
      }
    },

    messageVisible: {
      visibility: 'visible'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;

export const CUSTOM_STYLES = {
  root: {
    width: '100%',
    margin: '10px 0 0 0'
  },
  label: {
    width: '200px'
  },
  textfield: {
    width: '200px'
  }
};
