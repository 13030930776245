import React, { useEffect, useRef, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import { Chart } from 'chart.js';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/queuesPieChart';
import { isDefined } from '../../../utils/isDefined';

const CHART_OPTIONS = {
  cutoutPercentage: 80,
  animation: {
    duration: 500
  },
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 15,
      fontColor: '#A7ABC3'
    }
  }
};

export function PieChart({ classes, queue, intl }) {
  const CHART_LABELS = [
    intl.formatMessage({
      id: 'QUEUES.ANSWERED'
    }),
    intl.formatMessage({
      id: 'QUEUES.DROPPED'
    })
  ];
  const ref = useRef();

  const data = useMemo(() => {
    return {
      labels: CHART_LABELS,
      datasets: [
        {
          data: [queue.answeredCallsLast24h, queue.droppedCallsLast24h],
          backgroundColor: ['#27AE60', '#EB5757'],
          borderWidth: 1
        }
      ]
    };
    // eslint-disable-next-line
  }, [queue, intl]);

  useEffect(() => {
    const myChart = new Chart(ref.current, {
      type: 'doughnut',
      data: data,
      options: CHART_OPTIONS
    });
    return () => {
      myChart.destroy();
    };
  }, [data, queue, intl]);

  const percentage =
    queue.totalCallsLast24h !== 0
      ? isDefined(queue.totalCallsLast24h) && queue.totalCallsLast24h !== 0
        ? Math.floor(
            (queue.answeredCallsLast24h * 100) / queue.totalCallsLast24h
          )
        : 0
      : 0;

  return (
    <div className={classes.root}>
      <div>{`${percentage} ${intl.formatMessage({
        id: 'QUEUES.PERCENTAGE_ANSWERED'
      })}`}</div>
      <canvas ref={ref} height="auto" width="300px" />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(PieChart);
