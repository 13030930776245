import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_USER_CONTACTS = 'SET_USER_CONTACTS';
const UPDATE_USER_CONTACTS = 'UPDATE_USER_CONTACTS';
const RESET_USER_CONTACTS = 'RESET_USER_CONTACTS';
const SET_IS_FAVORITES = 'SET_IS_FAVORITES';
const SET_USER_CALL_LOG = 'SET_USER_CALL_LOG';
const SET_USER_VOICEMAILS = 'SET_USER_VOICEMAILS';
const SET_USER_FAVORITES = 'SET_USER_FAVORITES';
const ADD_USER_CALL_LOG = 'ADD_USER_CALL_LOG';
const SET_ARE_MISSED_CALLS = 'SET_ARE_MISSED_CALLS';
const SET_REFRESH = 'SET_REFRESH';
const SET_GROUPS_OPTIONS = 'SET_GROUPS_OPTIONS';

export const actionTypes = createReduxActionTypes('USER_CONTACTS', [
  SET_USER_CONTACTS,
  RESET_USER_CONTACTS,
  UPDATE_USER_CONTACTS,
  SET_IS_FAVORITES,
  SET_USER_CALL_LOG,
  SET_USER_VOICEMAILS,
  SET_USER_FAVORITES,
  ADD_USER_CALL_LOG,
  SET_ARE_MISSED_CALLS,
  SET_REFRESH,
  SET_GROUPS_OPTIONS
]);

const DEFAULT_STATE = {
  contacts: [],
  activities: [],
  isFavoriteView: true,
  callLog: [],
  voicemails: [],
  favorites: [],
  groupsOptions: [],
  callLogCount: 0,
  voicemailsCount: 0,
  areMissedCalls: false,
  refresh: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_USER_CONTACTS]: {
      const { contacts, activities } = action;

      return { ...state, contacts, activities };
    }
    case actionTypes[SET_ARE_MISSED_CALLS]: {
      const { areMissedCalls } = action;

      return { ...state, areMissedCalls };
    }
    case actionTypes[SET_USER_FAVORITES]: {
      const { favorites } = action;

      return { ...state, favorites };
    }
    case actionTypes[SET_USER_CALL_LOG]: {
      const { callLog, callLogCount } = action;

      return { ...state, callLog, callLogCount };
    }
    case actionTypes[ADD_USER_CALL_LOG]: {
      const { callLog } = action;

      return {
        ...state,
        callLog: [...state.callLog, ...callLog]
      };
    }
    case actionTypes[SET_USER_VOICEMAILS]: {
      const { voicemails, voicemailsCount } = action;

      return { ...state, voicemails, voicemailsCount };
    }
    case actionTypes[UPDATE_USER_CONTACTS]: {
      const { contacts } = action;

      return { ...state, contacts };
    }
    case actionTypes[SET_IS_FAVORITES]: {
      const { isFavoriteView } = action;

      return { ...state, isFavoriteView };
    }
    case actionTypes[SET_REFRESH]: {
      return { ...state, refresh: !state.refresh };
    }
    case actionTypes[SET_GROUPS_OPTIONS]: {
      const { groupsOptions } = action;
      return { ...state, groupsOptions };
    }
    case actionTypes[RESET_USER_CONTACTS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setUserContacts: ({ contacts, activities }) => ({
    type: actionTypes[SET_USER_CONTACTS],
    contacts,
    activities
  }),
  setUserFavorites: favorites => ({
    type: actionTypes[SET_USER_FAVORITES],
    favorites
  }),
  setUserCallLog: (callLog, callLogCount) => ({
    type: actionTypes[SET_USER_CALL_LOG],
    callLog,
    callLogCount
  }),
  setAreMissedCalls: areMissedCalls => ({
    type: actionTypes[SET_ARE_MISSED_CALLS],
    areMissedCalls
  }),
  setRefresh: () => ({
    type: actionTypes[SET_REFRESH]
  }),
  addUserLogs: callLog => ({
    type: actionTypes[ADD_USER_CALL_LOG],
    callLog
  }),
  setUserVoicemails: (voicemails, voicemailsCount) => ({
    type: actionTypes[SET_USER_VOICEMAILS],
    voicemails,
    voicemailsCount
  }),
  updateUserContacts: contacts => ({
    type: actionTypes[UPDATE_USER_CONTACTS],
    contacts
  }),
  setGroupsOptions: groupsOptions => ({
    type: actionTypes[SET_GROUPS_OPTIONS],
    groupsOptions
  }),
  setIsFavorite: isFavoriteView => ({
    type: actionTypes[SET_IS_FAVORITES],
    isFavoriteView
  }),
  resetUserContacts: () => ({ type: actionTypes[RESET_USER_CONTACTS] })
};

export const getters = {
  getContacts: state => state.userContacts.contacts,
  getIsFavoriteView: state => state.userContacts.isFavoriteView,
  getAllCallLog: state => state.userContacts.callLog,
  getAllVoicemails: state => state.userContacts.voicemails,
  getLastCallLogId: state => {
    const allLogs = state.userContacts.callLog;
    return allLogs[allLogs.length - 1].itemId;
  },
  getLastVoicemailsId: state => {
    const allVoicemails = state.userContacts.voicemails;
    return allVoicemails[allVoicemails.length - 1].itemId;
  },
  getFirstCallLogId: state => {
    const allLogs = state.userContacts.callLog;
    return allLogs[0].itemId;
  },
  getFirstVoicemailsId: state => {
    const allVoicemails = state.userContacts.voicemails;
    return allVoicemails[0].itemId;
  }
};
