const styles = theme => {
  return {
    root: {
      paddingRight: '24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        padding: '0'
      }
    },
    eSim: {
      borderLeft: '1px solid #f0f0f0',
      paddingLeft: '24px',
      paddingRight: 0
    },
    label: {
      marginBottom: '20px',
      fontWeight: 500
    }
  };
};

export default styles;
