import { call, put } from "redux-saga/effects";
import { takeLatest } from "../../../utils/reduxSaga";
import groupby from "lodash.groupby";
import orderBy from "lodash.orderby";

import { actions as sagaActions, GET_SUBSCRIPTIONS } from "./types";
import { actions as loaderReduxActions } from "../../../partials/loader/reducers";
import { actions as reduxActions } from "../reducers";
import { getData } from "../../../utils/http";
import { URLS } from "../../../consts/endpoints";
import {
  isArray,
  isArrayEmpty,
  isDefined,
  isNumber,
} from "../../../utils/isDefined";

export function fetchSubscriptions({ id, clientId, expandAll }) {
  return {
    type: sagaActions[GET_SUBSCRIPTIONS],
    id,
    clientId,
    expandAll,
  };
}

export function* getSubscriptions({ id, clientId, expandAll }) {
  yield put(loaderReduxActions.startSpinLoader());

  let subscriptions = [];
  let data = [];

  if (id === "ALL") {
    data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions`
    );
    subscriptions = isArray(data) ? data : [];
  } else {
    try {
      data = yield call(
        getData,
        `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions?account_id=${id}`
      );
      subscriptions = isArray(data) ? data : [];
    } catch {}
  }

  const groupedBySku = yield call(groupSubscriptions, {
    data: subscriptions,
    key: "sku",
  });
  const groupedByCategory = yield call(groupSubscriptions, {
    data: subscriptions,
    key: "productCategory",
  });

  yield put(
    reduxActions.setSubscriptions(
      { groupedBySku, groupedByCategory },
      expandAll
    )
  );
  yield put(loaderReduxActions.stopSpinLoader());
}

function* watchGetSubscriptions() {
  yield takeLatest(sagaActions[GET_SUBSCRIPTIONS], getSubscriptions);
}

export default watchGetSubscriptions();

const groupSubscriptions = ({ data, key }) => {
  if (!isArray(data)) {
    return [];
  }

  const grouped = groupby(data, el => el.subscription[key]);
  const keys = Object.keys(grouped);

  return orderBy(
    keys
      .reduce((arr, el) => {
        return [
          ...arr,
          {
            name: el,

            subs: grouped[el].map(sub => {
              return { ...sub.subscription, problems: sub.problems };
            }),
          },
        ];
      }, [])
      .map(el => {
        const category = el.subs[0].productCategory;
        const skuTranslations = el.subs[0].skuTranslations;
        const count = el.subs.length;
        const quantity = el.subs.reduce((number, el) => {
          if (!isNumber(el.quantity)) {
            return number;
          }
          return number + el.quantity;
        }, 0);

        const mapped = mapSubscriptions(el.subs);

        return {
          ...el,
          category,
          skuTranslations,
          subs: mapped,
          count,
          quantity,
        };
      }),
    "category"
  );
};

const mapSubscriptions = data => {
  return data.map(sub => {
    if (isArrayEmpty(sub.projection)) {
      return { ...sub, quantity: 1 };
    }
    const quantity = sub.projection.find(proj => proj.name === "Quantity");

    if (isDefined(quantity)) {
      const filtered = sub.projection.filter(proj => proj.name !== "Quantity");

      return { ...sub, quantity: quantity.value, projection: filtered };
    }

    return { ...sub, quantity: 1 };
  });
};
