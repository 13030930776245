const styles = theme => {
  return {
    root: { width: '550px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    row: { margin: '5px' },
    titleRow: { fontWeight: '700', fontSize: '15px', margin: '5px' },
    italicRow: {
      paddingLeft: '30px',
      fontStyle: 'italic',
      margin: '5px',
      display: 'inherit',
      flexDirection: 'row'
    },
    infoRow: {
      backgroundColor: '#FFE5CC',
      height: 'fit-content',
      margin: '5px 0',
      padding: '5px',
      '& div': {
        margin: '5px 0'
      }
    },
    codeRow: { display: 'inherit', flexDirection: 'row', alignItems: 'center' },
    boldRow: { fontWeight: '700', margin: '5px' },
    link: {
      '&:hover': { filter: 'brightness(1.5)' },
      cursor: 'pointer',
      margin: '0 2px'
    },
    container: { margin: '10px 0' },
    loaderContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      margin: '10px'
    },
    qrCodeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px',
      justifyContent: 'center'
    },
    sampleIcon: { width: '80px' },
    icon: { width: '150px' },
    codeText: {
      position: 'relative',
      top: '50px',
      fontSize: '14px',
      width: '100%',
      height: '20px',
      backgroundColor: '#FFF',
      textAlign: 'center',
      fontStyle: 'italic',
      fontWeight: 600,
      paddingRight: '5px'
    },
    bold: { fontWeight: 500 },
    qrCodeColumContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;
