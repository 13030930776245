import React from 'react';

import { findPhonebookStatuses } from '../../../consts/initialValues';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import TableActionButtons from './TableActionButtons';
import styles from '../styles';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import ClientCell from './ClientCell';
import formatDate from '../../../utils/formatDate';

const tableColumns = client => {
  let columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      cellStyle: styles.actionCell,
      disableClick: true,
      width: '40px'
    },
    {
      title: 'CLIENT',
      render: rowData => <ClientCell clientId={rowData.client_id} />,
      hideForUserView: true,
      field: 'client_id',
      sorting: false,
      searchable: false,
      disableClick: true
    },
    {
      title: 'TABLE.NUMBER',
      field: 'number',
      disableClick: true
    },
    {
      title: 'STATUS',
      render: rowData => {
        return findPhonebookStatuses(rowData.protection_code);
      },
      field: 'protection_code',
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.PHONEBOOK_STATUSES)
      },
      disableClick: true
    },
    {
      title: 'NAME',
      render: rowData => {
        return `${Optional(rowData.person_given_name).or('')} ${Optional(
          rowData.person_middle_name
        ).or('')} ${Optional(rowData.person_surname_name).or('')}`;
      },
      field: 'person_given_name',
      disableClick: true
    },
    {
      title: 'COMPANY',
      field: 'organization_name',
      disableClick: true
    },
    {
      title: 'ADDRESS',
      render: rowData => {
        return mapAddress(
          Optional(rowData.address).or(
            Optional(rowData.default_address).or(void 0)
          )
        );
      },
      field: 'address',
      sorting: false,
      filtering: false,
      disableClick: true
    },
    {
      title: 'C/O',
      field: 'care_of_name',
      disableClick: true,
      untranslatedTitle: true
    },
    {
      title: 'MENU.NUMBER_RANGE',
      render: rowData => {
        return mapRange(rowData.external_number_range);
      },
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(
          rowData.external_number_range?.range_from,
          term
        );
      },
      field: 'external_number_range'
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (isDefined(client)) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;

const mapAddress = address => {
  if (isUndefined(address)) {
    return '';
  }
  const {
    street,
    house_number,
    stairway,
    floor,
    sidedoor,
    zip_code,
    city
  } = address;

  const addressArray = [
    street,
    house_number,
    stairway,
    floor,
    sidedoor,
    zip_code,
    city
  ];

  return addressArray.reduce((text, item) => {
    return isDefined(item) ? `${text} ${item}` : text;
  }, '');
};

const mapRange = range => {
  if (isUndefined(range)) {
    return '';
  }
  return range.range_from === range.range_up_to_incl
    ? range.range_from.toString()
    : `${range.range_from} - ${range.range_up_to_incl}`;
};
