const styles = () => {
  return {
    root: {
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      marginBottom: '10px',
      paddingBottom: '10px'
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  };
};

export default styles;
