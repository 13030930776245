import { isDefined, isObject } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';

export function customSearchByProduct({ rowData, term, lang }) {
  const { name, category, subs = [], skuTranslations = {} } = rowData;

  if (
    (searchCaseInsensitive(name, term) || searchCaseInsensitive(category, term),
    searchCaseInsensitive(
      Optional(skuTranslations[lang]).or(skuTranslations.default),
      term
    ))
  ) {
    return true;
  }

  return searchInSubs(subs, term);
}

export function customSearchByProductCategory({ rowData, term }) {
  const { category, subs = [] } = rowData;

  if (searchCaseInsensitive(category, term)) {
    return true;
  }

  return searchInSubs(subs, term);
}

function searchInSubs(subs, term) {
  return subs.reduce((arr, sub) => {
    const {
      name: elName,
      description,
      status,
      activationDate,
      expiryDate
    } = sub;

    const values = [elName, description, status, activationDate, expiryDate];

    const searched = values
      .map(value => searchCaseInsensitive(value, term))
      .filter(value => value);

    return searched.length > 0 ? [...arr, true] : arr;
  }, []).length > 0
    ? true
    : false;
}

export function findSkuTranslation({ rowData, lang }) {
  const { skuTranslations } = rowData;

  if (isDefined(skuTranslations) && isObject(skuTranslations)) {
    return Optional(skuTranslations[lang]).or(skuTranslations.default);
  }

  return '';
}
