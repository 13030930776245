import { JSONAPI_URLS, URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';
import customParseNumber from '../../../utils/customParseNumber';
import Optional from '../../../utils/optional';

const clientQueryParams = {
  extra_fields: { clients: 'default_address' }
};

const getPhoneBookRegistration = async (number, customerId, onlyClient) => {
  if (isDefined(number)) {
    const mappedNumber = number.toString().replace('+', '');

    let phoneNumber = customParseNumber(`+${mappedNumber}`);

    if (isDefined(phoneNumber?.nationalNumber)) {
      const queryParams = {
        filter: {
          number: { eq: phoneNumber?.nationalNumber }
        },
        include: ['client'],
        extra_fields: { clients: 'default_address' }
      };

      try {
        const { data } = await getData(
          urlCreator(JSONAPI_URLS.PHONE_BOOK_REGISTRATION, queryParams)
        );

        if (doesArrayHasLength(data)) {
          const {
            address,
            external_number_range,
            ...restRegistrationEntry
          } = data[0];

          const { data: client } = await getData(
            urlCreator(
              `${URLS.CLIENTS}/${data[0].client_id}.json`,
              clientQueryParams
            )
          );

          const defaultAddress = Optional(client?.default_address).or(void 0);

          return {
            status: { ...restRegistrationEntry, address: address?.id },
            defaultAddress
          };
        } else {
          const { data: client } = await getData(
            urlCreator(`${URLS.CLIENTS}/${customerId}.json`, clientQueryParams)
          );

          const defaultAddress = Optional(client?.default_address).or(void 0);

          const defaultName = Optional(client?.metadata?.display_name).or(
            void 0
          );

          return { defaultAddress, defaultName };
        }
      } catch {
        return void 0;
      }
    } else {
      void 0;
    }
  } else if (onlyClient) {
    const { data: client } = await getData(
      urlCreator(`${URLS.CLIENTS}/${customerId}.json`, clientQueryParams)
    );

    const defaultAddress = Optional(client?.default_address).or(void 0);

    const defaultName = Optional(client?.metadata?.display_name).or(void 0);

    return { defaultAddress, defaultName };
  } else {
    void 0;
  }
};

export default getPhoneBookRegistration;
