import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import store from '../../../store/store';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import Optional from '../../../utils/optional';

const cancelSubscription = async ({ clientId, externalReference, name }) => {
  store.dispatch(loaderReduxActions.startSpinLoader());
  let summaryInfo = {};
  try {
    const response = await postData({
      url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/${externalReference}/cancel`,
      passErrors: true
    });

    if (response?.status === RESPONSE_CODES.SUCCESS) {
      summaryInfo = updateSummaryInfo({
        summaryInfo,
        name,
        newElement: {
          isError: false
        }
      });
    }
  } catch (e) {
    summaryInfo = updateSummaryInfo({
      summaryInfo,
      name,
      newElement: {
        text: translate('MESSAGE.CREATE_ERROR', {
          name
        }),
        isError: true,
        errors: Optional(e.response?.data)
          .map(data => [JSON.stringify(data)])
          .or([])
      }
    });
  } finally {
    store.dispatch(loaderReduxActions.stopSpinLoader());
    return { requestCompleted: true, summaryInfo };
  }
};

export default cancelSubscription;

export const updateSummaryInfo = ({
  summaryInfo,
  name,
  newElement,
  fromChange,
  changeInfo
}) => {
  if (isUndefined(summaryInfo[name])) {
    return fromChange
      ? {
          ...summaryInfo,
          [name]: { changeInfo, status: [newElement] }
        }
      : {
          ...summaryInfo,
          [name]: { status: [newElement] }
        };
  } else {
    return {
      ...summaryInfo,
      [name]: {
        ...summaryInfo[name],
        status: [...summaryInfo[name].status, newElement]
      }
    };
  }
};
