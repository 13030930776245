const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '550px',
      fontSize: '14px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexDirection: 'column'
      }
    },
    labelContainer: { display: 'flex' },
    label: { width: '160px', marginRight: '5px' },
    colorBox: {
      width: '50px',
      height: '25px',
      border: '1px solid #000',
      marginRight: '10px',
      cursor: 'pointer'
    },
    secondRow: { display: 'flex', alignItems: 'center' },
    resetIcon: { marginLeft: '10px', cursor: 'pointer' }
  };
};

export default styles;
