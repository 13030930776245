import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import urlCreator from '../../../utils/urlCreator';
import { actions as reduxActions } from '../reducers';

const QUERY_PARAMS = {
  include: ['roles']
};

const getCustomJob = id => {
  let customJob = {};
  return async dispatch => {
    try {
      const response = await getData(
        urlCreator(`${URLS.CUSTOM_JOBS}/${id}`, QUERY_PARAMS)
      );

      customJob = mapCustomJob(response);
    } catch {}

    dispatch(reduxActions.setValue('customJob', customJob));
  };
};

const mapCustomJob = response => {
  const { data, included } = response;
  const { id, attributes = {} } = data;

  const roles = included.reduce((arr, el) => {
    if (el.type !== 'roles') {
      return arr;
    }

    return [...arr, { ...el.attributes, id: el.id }];
  }, []);

  return {
    ...attributes,
    id,
    roles,
    value: id,
    label: attributes.name
  };
};

export default getCustomJob;
