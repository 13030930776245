import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_TELEPHONY_USER } from './types';
import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import { mitelRequest } from '../../../utils/mitelRequest';
import mapTelephonyDataForForm from './mapTelephonyDataForForm';
import { prepareTelephonySelectsOptions } from './prepareSelectsOptions';

export function setTelephonyUser({
  userId,
  clientConnectId,
  isTelephonyUser,
  fromUsersTable,
  fromGroupsTable,
  roles,
  id
}) {
  return {
    type: sagaActions[SET_TELEPHONY_USER],
    userId,
    clientConnectId,
    isTelephonyUser,
    fromUsersTable,
    fromGroupsTable,
    roles,
    id
  };
}

export function* getTelephonyUser({
  userId,
  clientConnectId,
  isTelephonyUser,
  fromUsersTable,
  fromGroupsTable,
  roles,
  id
}) {
  const SELECTS_ARRAY_PARAMS = [
    {
      customEndpoint: `${OUTSIDE_API.CLIENTS}/${clientConnectId}/languages`,
      queryParams: {},
      ignoreNoneOption: true,
      customKey: 'DOMAIN_LANGUAGES'
    },
    {
      endpoint: 'ROLES',
      queryParams: { fields: { partners: 'name' } },
      ignoreNoneOption: true
    }
  ];

  const selectsOptions = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);

  const { data: userData } = yield call(mitelRequest, {
    options: {
      url: `${OUTSIDE_API.CLIENTS}/${clientConnectId}/users/${userId}`
    }
  });

  const {
    mappedselectOptions,
    languageId
  } = yield call(prepareTelephonySelectsOptions, { selectsOptions, userData });

  const { data: groups } = yield call(mitelRequest, {
    options: {
      url: `${OUTSIDE_API.CLIENTS}/${clientConnectId}/user_groups`
    }
  });

  const mappedData = yield call(mapTelephonyDataForForm, {
    userData,
    groups,
    languageId
  });

  yield put(reduxActions.setSelectsOptions(mappedselectOptions));
  yield put(
    reduxActions.setUser({
      mitelUserId: userId,
      user: { ...mappedData, roles, id },
      isDomainProvided: isDefined(clientConnectId),
      clientConnectId,
      isTelephonyUser,
      fromUsersTable,
      fromGroupsTable
    })
  );
}

function* watchSetTelephonyUser() {
  yield takeLatest(sagaActions[SET_TELEPHONY_USER], getTelephonyUser);
}

export default watchSetTelephonyUser();
