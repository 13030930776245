import React from 'react';
import formatDate from '../../../utils/formatDate';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import searchNumbers from '../../../utils/searchNumbers';
import ActionButtons from './ActionButtons';

export default function tableColumns() {
  const columns = [
    {
      title: 'TABLE.ACTIONS',
      render: rowData => <ActionButtons data={rowData} />,
      disableClick: true
    },
    {
      title: 'TABLE.NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'UUID',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.uuid, term);
      },
      field: 'uuid',
      untranslatedTitle: true
    },
    {
      title: 'TABLE.CLIENTS_NO',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.clientsCount, term);
      },
      field: 'clientsCount'
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    },
    {
      title: 'ID',
      field: 'id',
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
}
