import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_SCHEDULE } from './types';
import mapScheduleForEdit from './mapScheduleForEdit';
import { isDefined } from '../../../utils/isDefined';
import { EMPTY_SCHEDULE } from '../../../consts/initialValues';

export function openEditSchedule({ schedule, isEdit, group }) {
  return {
    type: sagaActions[SET_SCHEDULE],
    schedule,
    isEdit,
    group
  };
}

export function* setSchedule({ schedule, isEdit, group }) {
  const mappedSchedule = isDefined(schedule)
    ? yield call(mapScheduleForEdit, schedule)
    : { ...EMPTY_SCHEDULE };

  yield put(
    reduxActions.setSchedule({
      schedule: mappedSchedule,
      isEdit,
      group
    })
  );
}

function* watchSetSchedule() {
  yield takeLatest(sagaActions[SET_SCHEDULE], setSchedule);
}

export default watchSetSchedule();
