import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles';
import { setTelephonyUser } from '../../../modals/editUser/actions/setTelephonyUser';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import MoreActionsMenu from './MoreActionsMenu';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';

export function TableActionButtons({
  data: user,
  classes,
  openEditUser,
  clientConnectId,
  settings
}) {
  const onEditClick = useCallback(
    () => {
      openEditUser({
        userId: user.user_id,
        clientConnectId,
        isTelephonyUser: true,
        roles: user.roles,
        id: user.id
      });
    },
    // eslint-disable-next-line
    [user, clientConnectId]
  );

  return (
    <div className={classes.actionButtons}>
      {can('update', createSubject('User', user)) && (
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.EDIT_USER"
          onClick={onEditClick}
          iconClassName="far fa-edit"
        />
      )}
      <MoreActionsMenu user={user}>
        <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
          <i
            className={clsx('far fa-cog', classes.icon)}
            style={{
              color: settings.colors.links
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientConnectId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  openEditUser: setTelephonyUser
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TableActionButtons);
