import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';

import { setLocalization, TABLE_OPTIONS } from '../../../consts/tableOptions';
import tableColumns from './tableColumns';

const LogsTable = ({ logs, isLoading }) => {
  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  const options = {
    ...TABLE_OPTIONS.DEFAULT,
    showTitle: false
  };

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, []);

  return (
    <MaterialTable
      columns={tableDefaultColumns}
      data={logs}
      options={options}
      localization={localization}
      isLoading={isLoading}
    />
  );
};

const mapStatesToProps = ({ datalimitLogs, loader }) => {
  return {
    ...datalimitLogs,
    isLoading: loader.isSpinVisible
  };
};

export default connect(mapStatesToProps)(LogsTable);
