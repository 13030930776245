import React, { useEffect, useState } from 'react';
import { isArrayEmpty, isDefined } from '../../utils/isDefined';
import fetchClientsList from './actions/fetchClientsList';
import ChangeLogTable from './components/ChangeLogTable';
import SelectClient from './components/SelectClient';

const ChangeLog = () => {
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(void 0);

  useEffect(() => {
    (async () => {
      const list = await fetchClientsList();
      if (!isArrayEmpty(list)) {
        setSelectedClient(list[0]);
      }

      return setClientsList(list);
    })();
  }, []);
  return (
    isDefined(selectedClient) && (
      <div>
        <SelectClient
          setSelectedClient={setSelectedClient}
          clientsList={clientsList}
          selectedClient={selectedClient}
        />
        <ChangeLogTable selectedClient={selectedClient} />
      </div>
    )
  );
};

export default ChangeLog;
