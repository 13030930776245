import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isStringDefined, isUndefined } from '../../../utils/isDefined';

const isEmailSchema = yup.string().email();

export default function() {
  return yup.object().shape(
    {
      report_to_email: yup.string().test({
        name: 'emails',
        test: function(value) {
          const invalidEmail =
            value &&
            value
              .split(',')
              .map(email => email.trim())
              .filter(email => isStringDefined(email))
              .find(email => !isEmailSchema.isValidSync(email));

          return !invalidEmail
            ? true
            : this.createError({
                message: `${translate('INVALID_EMAIL')} : '${invalidEmail}'.`
              });
        }
      }),
      fair_usage_limit: yup
        .string()
        .when(['update_block_limit_mb_now', 'block_limit_mb'], {
          is: (update_block_limit_mb_now, block_limit_mb) =>
            update_block_limit_mb_now === 'true' && isUndefined(block_limit_mb),
          then: yup
            .string()
            .required(
              translate('DATA_LIMITS.VALIDATE_LIMIT_REQUIREMENT_MULTIEDIT')
            )
        })
        .matches(/^[-,-.0-9]+$/, translate('VALIDATION.ONLY_DIGITS')),
      block_limit_mb: yup
        .string()
        .when(['update_block_limit_mb_now', 'fair_usage_limit'], {
          is: (update_block_limit_mb_now, fair_usage_limit) =>
            update_block_limit_mb_now === 'true' &&
            isUndefined(fair_usage_limit),
          then: yup
            .string()
            .required(
              translate('DATA_LIMITS.VALIDATE_LIMIT_REQUIREMENT_MULTIEDIT')
            )
        })
        .matches(/^[-,-.0-9]+$/, translate('VALIDATION.ONLY_DIGITS'))
    },
    ['block_limit_mb', 'fair_usage_limit']
  );
}
