import { isStringDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import DEFAULT_SETTINGS from '../../../consts/frontendSettings';

export default function mapSettings(settings = {}, defaultSettings = {}) {
  const {
    colors,
    logos,
    footer,
    favicon,
    title,
    website_url,
    support_phone_number
  } = settings;
  const mappedColors = mapSettingsElement(colors, 'colors', defaultSettings);
  const mappedLogos = mapSettingsElement(logos, 'logos', defaultSettings);

  return {
    colors: mappedColors,
    logos: mappedLogos,
    footer: mapStringValues(footer, defaultSettings),
    favicon: mapStringValues(favicon, defaultSettings),
    title: mapStringValues(title, defaultSettings),
    websiteUrl: mapStringValues(website_url, defaultSettings),
    supportNumber: mapStringValues(support_phone_number, defaultSettings)
  };
}

export function mapSettingsElement(settingObj = {}, valueKey, defaultSettings) {
  return Object.keys(DEFAULT_SETTINGS[valueKey]).reduce((obj, key) => {
    const objectValue = Optional(settingObj[key])
      .map(value => isStringDefined(value))
      .or(false);

    return {
      ...obj,
      [key]: objectValue
        ? settingObj[key]
        : Optional(defaultSettings?.[valueKey]?.[key]).or(
            DEFAULT_SETTINGS[valueKey][key]
          )
    };
  }, {});
}

function mapStringValues(value, defaultSettings) {
  const defaultValue = Optional(defaultSettings?.[value]).or(
    DEFAULT_SETTINGS[value]
  );
  return Optional(value).or(defaultValue);
}
