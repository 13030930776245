import { isUndefined } from './isDefined';

export default function searchNumbers(value, searchTerm) {
  if (isUndefined(value)) {
    return false;
  }

  return (
    value
      .toString()
      .replace(/ |\+|\s/g, '')
      .indexOf(searchTerm.replace(/ |\+|\s/g, '')) !== -1
  );
}
