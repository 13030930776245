export default {
  dialogTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '80px',
    justifyContent: 'space-around'
  },
  appendixLink: {
    height: '19px',
    fontSize: '12px'
  }
};
