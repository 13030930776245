export default {
  title: {
    backgroundColor: '#F44336',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '10px',
    padding: '10px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
