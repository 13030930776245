import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import { isDefined } from '../../utils/isDefined';
import { actions as showHistoryReduxActions } from '../../modals/showHistory/reducers';
import tempUserAction from '../../pages/clients/actions/tempUserAction';
import { setClient } from '../../modals/editClient/actions/setClient';

export function ClientDotMenu({
  children,
  intl,
  client,
  openShowHistory,
  openEditClient
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = useCallback(
    () => {
      openEditClient(client, true);
      handleClose();
    },
    // eslint-disable-next-line
    [client]
  );

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: client.id,
        itemType: 'Client',
        titleText: client.name,
        itemTypeKey: 'CLIENT',
        historyObject: client
      });
      handleClose();
    },
    // eslint-disable-next-line
    [client.id]
  );

  const onTempUserActionClick = useCallback(
    async action => {
      await tempUserAction(client.id, action);
      handleClose();
    },
    // eslint-disable-next-line
    [client.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onEditClick}>
          {intl.formatMessage({
            id: 'TOOLTIP.EDIT_CLIENT'
          })}
        </MenuItem>
        <MenuItem onClick={onHistoryClick}>
          {intl.formatMessage({
            id: 'TOOLTIP.SHOW_HISTORY'
          })}
        </MenuItem>
        {isDefined(client.connect30_domain) && (
          <MenuItem onClick={() => onTempUserActionClick('create')}>
            {intl.formatMessage({
              id: 'TOOLTIP.CREATE_TEMP_USER'
            })}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    client: selectClient.selectedClient
  };
};
const mapDispatchToProps = {
  openEditClient: setClient,
  openShowHistory: showHistoryReduxActions.openShowHistory
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(ClientDotMenu);
