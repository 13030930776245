import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { updateStatus } from '../actions/manageStatus';
import { withStyles } from '@material-ui/styles';
import styles from '../styles/contactCard';
import clsx from 'clsx';

export function StatusMenu({
  children,
  intl,
  updateStatus,
  mitelToken,
  baseUrl,
  clientDomain,
  loggedUserId,
  contact,
  classes
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const onIconClick = useCallback(
    status => {
      return updateStatus({
        contactId: contact.id,
        mitelToken,
        baseUrl,
        clientDomain,
        loggedUserId,
        status
      });
    },
    // eslint-disable-next-line
    [mitelToken, baseUrl, clientDomain, loggedUserId, contact.id]
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StatusOption = ({ icon, text }) => {
    return (
      <div className={classes.optionRoot}>
        <i className={clsx(icon, classes.icon)}></i>
        {text}
      </div>
    );
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => onIconClick('FAVORITE')}>
          <StatusOption
            icon={'fas fa-star'}
            text={intl.formatMessage({
              id: 'FAVORITE'
            })}
          />
        </MenuItem>
        <MenuItem onClick={() => onIconClick('CONTACT')}>
          <StatusOption
            icon={'far fa-star'}
            text={intl.formatMessage({
              id: 'CONTACT'
            })}
          />
        </MenuItem>
        <MenuItem onClick={() => onIconClick('VIP')}>
          <StatusOption icon={'far fa-heart'} text={'VIP'} />
        </MenuItem>
        <MenuItem onClick={() => onIconClick('BLOCKED')}>
          <StatusOption
            icon={'fas fa-ban'}
            text={intl.formatMessage({
              id: 'BLOCKED'
            })}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStatesToProps = ({ auth }) => {
  return {
    clientDomain: auth.defaultClient.connect30_domain,
    mitelToken: auth.user.mitel_token,
    baseUrl: auth.user.mitel_profile_link,
    loggedUserId: auth.user.connect30_user_id
  };
};

const mapDispatchToProps = {
  updateStatus
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(StatusMenu);
