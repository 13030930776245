import moment from 'moment';

import { isString, isUndefined } from '../../../utils/isDefined';

export default function checkIfExpired(data) {
  if (data.name === 'default') {
    return false;
  }
  const now = moment();

  if (
    data.recurrenceType === 'NONE' ||
    data.recurrenceType === 'DAILY' ||
    data.recurrenceType === 'WEEKLY'
  ) {
    if (isUndefined(data.end?.date) || isUndefined(data.end?.time)) {
      return false;
    }
    const toDate = moment(data.end.date);

    if (moment(toDate).isBefore(now, 'day')) {
      return true;
    } else {
      if (moment(toDate).isSame(now, 'day')) {
        const nowTime = now.format('HH:mm');
        const toTime = moment(data.end.time)
          .tz('UTC')
          .format('HH:mm');

        return checkIfTimeExpired(nowTime, toTime);
      }
    }
    return false;
  }

  if (data.recurrenceType === 'MONTHLY' || data.recurrenceType === 'YEARLY') {
    if (isUndefined(data.recurrenceEnd?.value)) {
      return false;
    }
    const endDate = moment(Number(data.recurrenceEnd?.value));

    if (moment(endDate).isBefore(now, 'day')) {
      return true;
    }
    return false;
  }

  return false;
}

function checkIfTimeExpired(nowTime, endTime) {
  if (!isString(nowTime) || !isString(endTime)) {
    return false;
  }

  const [nowH, nowM] = nowTime.split(':');
  const [endH, endM] = endTime.split(':');

  if (Number(nowH) > Number(endH)) {
    return true;
  } else {
    if (Number(nowH) === Number(endH)) {
      if (Number(nowM) > Number(endM)) {
        return true;
      }
      return false;
    }
  }
}
