import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider, createIntl } from 'react-intl';

import daMessages from './messages/da';
import enMessages from './messages/en';
import store from '../store/store';
import { isObject } from '../utils/isDefined';

const allMessages = {
  da: daMessages,
  en: enMessages
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ auth }) => auth.lang);
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export function translate(text, params) {
  const {
    auth: { lang }
  } = store.getState();

  const intl = createIntl({
    locale: lang,
    messages: allMessages[lang]
  });

  if (isObject(params)) {
    return intl.formatMessage({ id: text }, { ...params });
  }
  
  return intl.formatMessage({ id: text });
}

