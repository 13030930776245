import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import styles from './styles';
import Contacts from './components/Contacts';
import TwoPanelsView from './components/TwoPanelsView';
import ThreePanelsView from './components/ThreePanelsView';
import AddExternalContact from '../../modals/addExternalContact/AddExternalContact';
import SmsContainer from './components/SmsContainer';
import FourPanelView from './components/FourPanelView';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';
import CallLogContainer from './components/CallLogContainer';
import VoicemailsContainer from './components/VoicemailsContainer';

function UserContacts({ classes, userId }) {
  const permissionToSendSms = useMemo(
    () =>
      can('create', createSubject('Mitel::Sms', { user_id: Number(userId) })),
    [userId]
  );

  return permissionToSendSms ? (
    <div className={classes.root}>
      <Contacts />
      <div className={classes.maxView}>
        <TwoPanelsView />
        <SmsContainer />
      </div>
      <div className={classes.standardView}>
        <ThreePanelsView permissionToSendSms={permissionToSendSms} />
      </div>

      <FourPanelView permissionToSendSms={permissionToSendSms} />
      <AddExternalContact />
    </div>
  ) : (
    <div className={classes.root}>
      <Contacts />
      <div className={classes.maxView}>
        <CallLogContainer />
        <VoicemailsContainer />
      </div>
      <div className={classes.standardView}>
        <ThreePanelsView permissionToSendSms={permissionToSendSms} />
      </div>

      <FourPanelView permissionToSendSms={permissionToSendSms} />
      <AddExternalContact />
    </div>
  );
}
const mapStatesToProps = ({ auth }) => {
  return {
    userId: auth.user.id
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(UserContacts);
