import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_USERS = 'SET_USERS';
const RESET_USERS = 'RESET_USERS';
const SET_COLUMNS = 'SET_COLUMNS';
const SET_REFRESH = 'SET_REFRESH';

export const actionTypes = createReduxActionTypes('USERS', [
  SET_USERS,
  RESET_USERS,
  SET_COLUMNS,
  SET_REFRESH
]);

const DEFAULT_STATE = {
  users: [],
  columns: [],
  refresh: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_REFRESH]: {
      return { ...state, refresh: action.refresh };
    }
    case actionTypes[SET_USERS]: {
      const { users, clientsLookupOptions } = action;
      return { ...state, users: [...users], clientsLookupOptions };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_USERS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setRefresh: refresh => ({
    type: actionTypes[SET_REFRESH],
    refresh
  }),
  setUsers: (users, clientsLookupOptions) => ({
    type: actionTypes[SET_USERS],
    users,
    clientsLookupOptions
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetUsers: () => ({ type: actionTypes[RESET_USERS] })
};

export const getters = {
  getUsers: state => state.users.users
};
