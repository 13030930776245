import { isEmptyArray } from 'formik';
import { URLS } from '../../../consts/endpoints';
import { SERVER } from '../../../consts/initialValues';
import { getData } from '../../../utils/http';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export async function getCustomJob(jobId) {
  try {
    const { data } = await getData(`${URLS.CUSTOM_JOBS}/${jobId}`);

    const { id, attributes } = data;

    const job = { id, ...attributes };
    if (
      isUndefined(job?.additionalRequests) ||
      isEmptyArray(job?.additionalRequests)
    ) {
      return {
        job,
        uiSchema: Optional(job.ui_schema).or({}),
        schema: job.form_schema
      };
    }

    const mappedSchema = await createDefinitions(job);

    return {
      job,
      schema: Optional(mappedSchema).or(void 0),
      uiSchema: Optional(job.ui_schema).or({})
    };
  } catch {
    return false;
  }
}

async function createDefinitions(job) {
  let { additionalRequests, form_schema } = job;

  const additionalDefinitions = await Promise.all(
    additionalRequests.map(async el => {
      const { name, url, ...restEl } = el;
      const response = await getData(`${SERVER}/${el.url}`);
      return isDefined(response?.data)
        ? {
            [name]: {
              enum: response?.data.map(el => el.id),

              ...restEl
            }
          }
        : {
            [name]: {
              enum: [],
              ...restEl
            }
          };
    })
  );

  const definitionsObject = additionalDefinitions
    .filter(def => isDefined(def))
    .reduce((obj, el) => {
      return { ...obj, ...el };
    }, {});

  return {
    ...form_schema,
    definitions: { ...form_schema.definitions, ...definitionsObject }
  };
}
