import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getSubscriptionsGroupingOptions } from '../../../consts/initialValues';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';

import { actions as reduxActions } from '../reducers';
import styles, {
  customSelectStyles,
  groupbySelectStyles
} from '../styles/selects';

function GroupingSelector({ updateValue, groupByCategory, classes, intl }) {
  const handleChange = selected => {
    updateValue('groupByCategory', selected.value);
  };

  const options = getSubscriptionsGroupingOptions();

  const value = useMemo(
    () => options.find(option => option.value === groupByCategory),
    [groupByCategory, options]
  );

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'SUBSCRIPTIONS.GROUP_BY'
        })}
      </div>
      <Select
        customStyles={{ ...customSelectStyles, ...groupbySelectStyles }}
        options={options}
        styles={reactSelectDefaultStyles}
        value={value}
        onChange={handleChange}
        isRtl
        isSearchable={false}
      />
    </div>
  );
}

const mapStatesToProps = ({ subscriptions }) => {
  return {
    groupByCategory: subscriptions.groupByCategory
  };
};

const mapDispatchToProps = {
  updateValue: reduxActions.updateValue
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(GroupingSelector);
