import React from 'react';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';

import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import searchNumbers from '../../../utils/searchNumbers';
import ScheduleStatusCell from './ScheduleStatusCell';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';

export default function tableColumns({ clientId }) {
  const columns = [
    {
      title: 'NAME',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: 'TABLE.NUMBER',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.number, term);
      },
      render: rowData => (
        <CopyToClipbaordTooltip value={rowData.number} isPhoneNumber={true}>
          {rowData.number}
        </CopyToClipbaordTooltip>
      ),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'SCHEDULES.NUMBER_TYPE',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.type, term);
      },
      field: 'type'
    },
    {
      title: 'SCHEDULES.STATUS',
      field: 'status',
      render: rowData => (
        <ScheduleStatusCell status={rowData.currentActionType} />
      )
    },
    {
      title: 'SCHEDULES.TIMEZONES',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.timeZoneId, term);
      },
      field: 'timeZoneId'
    },
    {
      title: 'SCHEDULES.COUNT',
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.schedules.length, term);
      },
      field: 'schedules.length',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    }
  ];

  let mappedColumns = [...columns];

  if (
    can(
      'manage',
      createSubject('Mitel::Schedule', { client_id: Number(clientId) })
    )
  ) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}
