import { deleteData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import store from '../../../store/store';
import { getUserPhoto } from '../../userAvatar/actions/getPhoto';
import fetchUserPhotoFromMitel from '../../../utils/fetchUserPhotoFromMitel';
import { actions as userReduxActions } from '../../../pages/user/reducers';

export default async function deletePhoto({
  clientDomain,
  baseUrl,
  mitelToken,
  userConnectId,
  resetChangePhoto
}) {
  resetChangePhoto();
  const response = await deleteData({
    url: `${baseUrl}/api/user/info/${clientDomain}/${userConnectId}/photo?t=${mitelToken}`,
    successSnackbar: { intlTextKey: 'MESSAGE.REMOVE_USER_PHOTO' }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    const loggedUserId = store.getState().auth.user.connect30_user_id;

    const { photo } = await fetchUserPhotoFromMitel({
      userId: userConnectId,
      connectDomain: clientDomain,
      domainUser: true
    });

    store.dispatch(userReduxActions.setUserPhoto(photo));

    if (userConnectId === loggedUserId) {
      await store.dispatch(
        getUserPhoto({ userId: userConnectId, domainUser: true })
      );
    }
  }
}
