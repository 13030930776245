export default {
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  footer: {
    margin: '15px',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      marginRight: '10px'
    }
  }
};
