import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API } from '../../../consts/endpoints';
import {
  actions as sagaActions,
  UPDATE_DIVERSIONS
} from '../../../pages/user/actions/types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../../../pages/user/reducers';
import getDiversions from '../../../pages/user/actions/getDiversions';
import mapDiversionData from './mapDiversionsData';

export function submitUpdateDiversion({
  userId,
  values,
  diversions,
  isCurrentUserSelectedClientUser,
  onCloseDialog
}) {
  return {
    type: sagaActions[UPDATE_DIVERSIONS],
    userId,
    values,
    diversions,
    isCurrentUserSelectedClientUser,
    onCloseDialog
  };
}

export function* updateDiversions({
  userId,
  values,
  diversions,
  isCurrentUserSelectedClientUser,
  onCloseDialog
}) {
  const mappedData = yield call(mapDiversionData, { values, diversions });

  const response = yield call(updateData, {
    url: `${OUTSIDE_API.USERS}/${userId}/diversions`,
    data: { rules: mappedData }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.NUMBER_FORWARD',
      messageType: 'success'
    });

    const { diversions, mappedDiversions } = yield call(
      getDiversions,
      userId,
      isCurrentUserSelectedClientUser
    );

    yield put(reduxActions.setDiversions({ diversions, mappedDiversions }));
    yield call(onCloseDialog);
  }
}

function* watchUpdateDiversions() {
  yield takeLatest(sagaActions[UPDATE_DIVERSIONS], updateDiversions);
}

export default watchUpdateDiversions();
