import React, { useEffect } from "react";
import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import { actions as reduxAction } from "./reducers";
import SimCardsTable from "./components/SimCardsTable";
import OrderNewSimcard from "../../modals/orderNewSimcard/OrderNewSimcard";
import ActivateSimcard from "./components/ActivateSimcard";
import mapAccounts from "./actions/mapAccounts";
import SelectAccount from "./components/SelectAccount";
import ActivationSummary from "./components/ActivationSummary";

const SimCardsIoT = ({ resetSimCards, clientId, mapAccounts }) => {
  useEffect(() => {
    mapAccounts({
      clientId,
    });
    return resetSimCards;
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <div>
      <SelectAccount />
      <SimCardsTable />
      <OrderNewSimcard />
      <ActivateSimcard />
      <ActivationSummary />
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient?.id,
  };
};

const mapDispatchToProps = {
  resetSimCards: reduxAction.resetSimCards,
  mapAccounts,
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  SimCardsIoT
);
