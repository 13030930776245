import fetchSchedules from './getSchedules';
import watchDeleteSchedule from './deleteSchedule';
import watchUpdateScheduleOrder from './updateSchedulesOrder';

const watchSchedules = [
  fetchSchedules,
  watchDeleteSchedule,
  watchUpdateScheduleOrder
];

export default watchSchedules;
