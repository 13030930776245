import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import flowRight from 'lodash.flowright';
import React from 'react';
import { isDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';

import PrimaryButton from '../../customButtons/PrimaryButton';

const styles = {
  root: { display: 'flex' },
  buttonsContainer: { display: 'flex', width: '120px', height: '25px' },
  description: { marginBottom: '10px' },
  button: { width: '30px', height: '25px', minWidth: 'unset' },
  children: { width: '100%' },
  addbuttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
};

function CustomArrayFieldTemplate({
  items,
  classes,
  className,
  onAddClick,
  canAdd,
  settings,
  title,
  schema
}) {
  return (
    <div className={className}>
      <legend>{title}</legend>
      {isDefined(schema.description) && (
        <div className={classes.description}>{schema.description}</div>
      )}
      {items.map(element => {
        return (
          <div
            className={clsx(classes.root, element.className)}
            key={element.index}
          >
            <div className={classes.children}>{element.children} </div>
            <div className={classes.buttonsContainer}>
              {(element.hasMoveDown || element.hasMoveUp) && (
                <>
                  <Button
                    className={classes.button}
                    style={{
                      backgroundColor: element.hasMoveDown
                        ? settings.colors.buttons
                        : '#F3F6F9',
                      color: element.hasMoveDown
                        ? settings.colors.buttons_text
                        : '#FFF'
                    }}
                    onClick={element.onReorderClick(
                      element.index,
                      element.index + 1
                    )}
                    disabled={!element.hasMoveDown}
                  >
                    <i className="fas fa-arrow-alt-down"></i>
                  </Button>
                  <Button
                    className={classes.button}
                    style={{
                      backgroundColor: element.hasMoveUp
                        ? settings.colors.buttons
                        : '#F3F6F9',
                      color: element.hasMoveDown
                        ? settings.colors.buttons_text
                        : '#FFF'
                    }}
                    onClick={element.onReorderClick(
                      element.index,
                      element.index - 1
                    )}
                    disabled={!element.hasMoveUp}
                  >
                    <i className="fas fa-arrow-alt-up"></i>
                  </Button>
                </>
              )}
              {element.hasRemove && (
                <Button
                  onClick={element.onDropIndexClick(element.index)}
                  className={classes.button}
                  style={{
                    backgroundColor: settings.colors.danger,
                    color: settings.colors.white
                  }}
                >
                  <i className="fas fa-times"></i>
                </Button>
              )}
            </div>
          </div>
        );
      })}
      <div className={classes.addbuttonContainer}>
        {canAdd && (
          <PrimaryButton onClick={onAddClick}>
            <i className="fas fa-plus"></i>
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}

export default flowRight(
  withSettings,
  withStyles(styles)
)(CustomArrayFieldTemplate);
