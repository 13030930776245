import React, { useState, useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';

import styles from './styles';
import PasswordForm from '../../partials/passwordForm/PasswordForm';
import { isStringEmpty, isUndefined, isDefined } from '../../utils/isDefined';
import setNewPass from './actions/setNewPass';
import messageColors from '../../consts/messageColors';
import Optional from '../../utils/optional';
import InputRow from '../../partials/dataRows/InputRow';

const CUSTOM_STYLES = { root: { margin: '5px 40px 0 0' } };

export function ResetPassword({ classes, history, location, intl }) {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState({});

  if (!isStringEmpty(location.search)) {
    const { reset_password_token } = queryString.parse(location.search);
    setToken(reset_password_token);
    location.search = '';
    history.replace('/password_reset');
  }

  const showPasswordForm =
    !isDefined(message?.type) || message.type !== 'success';

  const onChangePass = useCallback(
    async data => {
      const returnedMessage = await setNewPass(data.password, token);
      setMessage(returnedMessage);

      if (returnedMessage.type === 'success') {
        setTimeout(() => history.push('/'), 5000);
      }
    },
    [token, history]
  );

  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'RESET_PASS.TITLE'
          })}
        </div>
        <div
          className={clsx(classes.message, {
            [classes.messageVisible]:
              isDefined(message.text) || isDefined(message.intlText)
          })}
          style={{ color: messageColors[message.type] }}
        >
          {message.text
            ? message.text
            : intl.formatMessage({
                id: Optional(message.intlText).or('EMPTY')
              })}
        </div>
        <InputRow
          customStyles={CUSTOM_STYLES}
          disabled={true}
          name="token"
          values={{ token }}
          errors={{
            token: isUndefined(token)
              ? intl.formatMessage({
                  id: 'RESET_PASS.NO_TOKEN'
                })
              : void 0
          }}
          label="RESET_PASS.TOKEN"
        />
        {showPasswordForm ? (
          <PasswordForm
            changePass={onChangePass}
            getSettings={false}
            isResetPassword={true}
          />
        ) : null}
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withRouter,
  withStyles(styles)
)(ResetPassword);
