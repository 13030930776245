import calculateMbToGb from '../../../utils/calculateMbToGb';
import { formatDateForSchedule } from '../../../utils/formatDate';
import { isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapDataToEdit(datalimit = {}) {
  const {
    id,
    name,
    max_numbers,
    tmp_open,
    manual_block,
    no_limit,
    block_limit_mb,
    report_to_email,
    allow_recharge,
    roaming_disabled,
    fair_usage_limit,
    report_to_sms,
    report_only_to,
    client,
    cell_numbers = [],
    recharge_count,
    status,
    future_limit,
    future_limit_at
  } = datalimit;

  const clientName = Optional(client?.name).or('');

  const clientId = Optional(client?.id).or(void 0);

  const numbers = mapCellNumbers(cell_numbers).join(', ');

  return {
    clientId,
    mappedDatalimit: {
      update_block_limit_mb_now: false,
      id,
      name,
      max_numbers,
      tmp_open,
      manual_block,
      no_limit,
      block_limit_mb: calculateMbToGb(block_limit_mb),
      report_to_email,
      allow_recharge,
      roaming_disabled,
      fair_usage_limit: calculateMbToGb(fair_usage_limit),
      report_to_sms,
      report_only_to: Optional(report_only_to).or(false),
      clientName,
      numbers,
      recharge_count,
      status,
      future_limit: calculateMbToGb(future_limit),
      future_limit_at: formatDateForSchedule(future_limit_at)
    }
  };
}

function mapCellNumbers(cellNumbers) {
  if (isUndefined(cellNumbers)) {
    return [];
  }
  return cellNumbers.map(number => number.number);
}
