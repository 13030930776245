import React from 'react';

const Info = () => {
  return (
    <div style={{ width: '500px', marginBottom: '10px' }}>
      <h6>
        <b>Type</b>
      </h6>
      <ul>
        <li>
          <b>CallCdr</b> - Created when a call is made through the system. A
          single call can create a number of CDRs with different CDR types
          specified to describe complex call flows.
        </li>
        <li>
          <b>ImCdr</b> - Triggered by sending Instant Messages.
        </li>
        <li>
          <b>VoiceQualityCdr</b> - Triggered by a SIP endpoint voice quality
          report.
        </li>
        <li>
          <b>SmsCdr</b>- Triggered by sending SMS from any SMS sending service
          or using the desktop application.{' '}
        </li>
        <li>
          <b>RegistrationCdr</b> - Are written to track registered SIP user
          agents. This is available for requirements on government data
          retention.
        </li>
        <li>
          <b>AgentCdr</b> - Are written to track status changes for agents of
          selected ACD/Attendant group.
        </li>
      </ul>
      <h6>
        <b>Ongoing</b>
      </h6>
      <ul>
        <li>
          Indicates if the target wants to receive ongoing or only completed
          CDRs
        </li>
      </ul>
    </div>
  );
};

export default Info;
