import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import { formatDateUTC } from '../../../utils/formatDate';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import { messageCellStyles } from '../styles';

const tableColumns = () => {
  return [
    {
      title: translate('TIME'),
      field: 'logged_at',
      render: rowData => formatDateUTC(rowData.logged_at)
    },
    {
      title: translate('ACTIVITY'),
      field: 'action_handler',
      customFilterAndSearch: (term, rowData) =>
        searchCaseInsensitive(rowData.action_handler, term)
    },
    {
      title: translate('ACTION'),
      field: 'action',
      customFilterAndSearch: (term, rowData) =>
        searchCaseInsensitive(rowData.action, term)
    },
    {
      title: translate('RECEIVER'),
      field: 'receiver'
    },
    {
      title: translate('MESSAGE'),
      field: 'message',
      render: rowData => <div style={messageCellStyles}>{rowData.message}</div>,
      customFilterAndSearch: (term, rowData) =>
        searchCaseInsensitive(rowData.message, term)
    },
    {
      title: translate('RESPONSE'),
      field: 'response',
      render: rowData => (
        <div style={messageCellStyles}>{JSON.stringify(rowData.response)}</div>
      ),
      hidden: true,
      customFilterAndSearch: (term, rowData) =>
        searchCaseInsensitive(JSON.stringify(rowData.response), term)
    }
  ];
};

export default tableColumns;
