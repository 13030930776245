import { URLS } from '../consts/endpoints';
import { getData } from './http';
import Optional from './optional';
import urlCreator from './urlCreator';

export default async function checkEuIccNumber(euicc, clientId) {
  const queryOptions = {
    euicc
  };

  try {
    const response = await getData(
      urlCreator(
        `${URLS.QUOTE_CLIENTS}/${clientId}/resources/search`,
        queryOptions
      )
    );
    const { icc, mobile_network } = response;

    const network = Optional(mobile_network?.operator).or(void 0);
    return { icc, network };
  } catch {
    return { icc: void 0, network: void 0 };
  }
}
