export default {
    photo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80px',
        height: '80px',
        backgroundColor: '#fff',
        border: '1px solid #EBEDF2',
        borderRadius: '50%'
      },
      initials: {
        fontSize: '28px',
        fontWeight: 500
      },
}