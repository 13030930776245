const styles = {
  root: {
    display: 'flex',
    marginRight: '5px',
    alignItems: 'self-end',
    '&:hover': { filter: 'brightness(1.5)' }
  },
  icon: {
    cursor: 'pointer',
    marginLeft: '5px'
  }
};

export default styles;
