import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles';
import { openEditDatalimit } from '../../../modals/editDatalimit/actions/setDatalimit';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import withUserCheck from '../../../utils/withUserCheck';
import CheckNumberDialog from '../../../modals/checkNumberDialog/CheckNumberDialog';
import { mapCellNumbersForLookup } from '../actions/tableColumnsActions';
import { isArrayEmpty } from '../../../utils/isDefined';
import MoreActionsMenu from './MoreActionsMenu';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';
import clsx from 'clsx';

export function TableActionButtons({
  data: dataLimit,
  classes,
  openEditDatalimit,
  isNonClientAdminUser,
  settings
}) {
  const numbers = useMemo(() => {
    return [...mapCellNumbersForLookup(dataLimit)];
  }, [dataLimit]);

  const onEditClick = useCallback(
    () => {
      openEditDatalimit({ datalimit: dataLimit });
    },
    // eslint-disable-next-line
    [dataLimit]
  );

  return (
    <div className={classes.actionCell}>
      {isNonClientAdminUser && (
        <CheckNumberDialog numbers={numbers}>
          <CustomIconButton
            iconTooltipIntlKey="MENU.NUMBER_LOOKUP"
            iconClassName="far fa-info-square"
            disabled={isArrayEmpty(numbers)}
          />
        </CheckNumberDialog>
      )}
      {can(
        'update',
        createSubject('DataLimit', { client_id: Number(dataLimit.client?.id) })
      ) && (
        <CustomIconButton
          iconTooltipIntlKey="TOOLTIP.EDIT_DATALIMIT"
          onClick={onEditClick}
          iconClassName="far fa-edit"
        />
      )}
      <MoreActionsMenu dataLimit={dataLimit}>
        <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
          <i
            className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
            style={{
              color: settings.colors.links
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
}

const mapDispatchToProps = { openEditDatalimit };

export default flowRight(
  connect(null, mapDispatchToProps),
  withSettings,
  withUserCheck,
  withStyles(styles)
)(TableActionButtons);
