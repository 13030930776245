import getValueFromLocation from '../../../utils/getValueFromLocation';

export default function getUserInfoFromLocation({
  location,
  currentLoggedUserId
}) {
  const [, clientId, page] = location.pathname.split('/');
  const userIdfromLocation = getValueFromLocation(location, 'id');
  const isCurrentUser =
    getValueFromLocation(location, 'isCurrentUser') || page === 'userProfile';
  const userId =
    page === 'userProfile' ? currentLoggedUserId : userIdfromLocation;

  return { userId, isCurrentUser, page, clientId };
}
