import React from 'react';

import withSettings from '../../utils/withSettings';
import DEFAULT_FRONTEND_SETTINGS from '../../consts/frontendSettings';
import Optional from '../../utils/optional';
import { translate } from '../../i18n/I18nProvider';

const CustomTableSaveButton = ({ props, ref, settings }) => {
  return (
    <div
      {...props}
      ref={ref}
      style={{
        fontSize: '12px',
        borderRadius: '5px',
        minWidth: 'unset',
        padding: '5px 10px',
        backgroundColor: Optional(settings)
          .map(settings => settings.colors.buttons)
          .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons),
        color: Optional(settings)
          .map(settings => settings.colors.buttons_text)
          .or(DEFAULT_FRONTEND_SETTINGS.colors.buttons_text)
      }}
    >
      {translate('BUTTON.SAVE')}
    </div>
  );
};

export default withSettings(CustomTableSaveButton);
