export default {
  root: {
    width: '100%',
    background: '#FFF'
  },
  dataRow: {
    display: 'flex',
    paddingLeft: '70px',
    height: '60px',
    alignItems: 'center',
    borderBottom: '1px solid #F5F5F5',
    '&:hover': {
      filter: 'brightness(0.95)'
    }
  },
  rowTitle: {
    width: '100px',
    fontWeight: 600
  },
  rowDark: {
    backgroundColor: '#FAFAFA',
    width: '100%'
  },
  title: { padding: '5px 10px', fontSize: '13px' },
  fieldName: { width: '250px' }
};
