import React from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from '../styles/subscriptionStatus';
import { translate } from '../../../i18n/I18nProvider';
import { translateDatalimitStatus } from '../../../consts/initialValues';
import { isDefined } from '../../../utils/isDefined';

const MAX_RECHARGE_COUNT = 4;

const DatalimitInfo = ({ classes, datalimitData }) => {
  return (
    isDefined(datalimitData) && (
      <div className={classes.contentContainer}>
        <div
          className={classes.dataContainer}
          style={{
            backgroundColor: '#FAFAFA'
          }}
        >
          <div className={classes.dataKey}>{translate('CURRENT_STATUS')}</div>

          <div>{translateDatalimitStatus(datalimitData?.status)}</div>
        </div>
        <div
          className={classes.dataContainer}
          style={{
            backgroundColor: '#FFF'
          }}
        >
          <div className={classes.dataKey}>
            {translate('NUMBER.ROAMING_BLOCK')}
          </div>

          <div>
            {datalimitData?.roaming_disabled
              ? translate('YES')
              : translate('NO')}
          </div>
        </div>
        <div
          className={classes.dataContainer}
          style={{
            backgroundColor: '#FAFAFA'
          }}
        >
          <div className={classes.dataKey}>
            {translate('NUMBER.RECHARGE_COUNT')}
          </div>

          <div>{`${datalimitData?.recharge_count}/${MAX_RECHARGE_COUNT}`}</div>
        </div>
      </div>
    )
  );
};

const mapStatesToProps = ({ checkNumber }) => {
  return {
    datalimitData: checkNumber.datalimitData
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(DatalimitInfo);
