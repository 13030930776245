import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import urlCreator from '../../../utils/urlCreator';

const QUERY_PARAMS = {
  include: ['roles', 'client'],
  fields: {
    clients: 'connect30_domain'
  }
};

const fetchUser = async ({ userId }) => {
  try {
    let { data } = await getData(
      urlCreator(`${URLS.USERS}/${userId}.json`, QUERY_PARAMS)
    );

    return { user: data };
  } catch (e) {
    return { error: true };
  }
};

export default fetchUser;
