const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '320px',
      marginLeft: '10px',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        marginLeft: 0,
        width: '100%'
      }
    },
    label: {
      color: '#646C9A',
      fontWeight: 500,
      marginBottom: '20px'
    }
  };
};
export default styles;
