import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { actions as reduxActions } from '../reducers';

export function ActionsButtons({ closeDialog, handleSubmit, dirty, intl }) {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {intl.formatMessage({
          id: 'BUTTON.SAVE'
        })}
      </PrimaryButton>
      <SecondaryButton onClick={closeDialog}>
        {intl.formatMessage({
          id: 'BUTTON.CANCEL'
        })}
      </SecondaryButton>
    </>
  );
}

const mapDispatchToProps = {
  closeDialog: reduxActions.resetClient
};

export default flowRight(
  injectIntl,
  connect(null, mapDispatchToProps)
)(ActionsButtons);
