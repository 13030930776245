import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as sagaActions, SET_SELECTED_LINE } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData, getData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import mapLinesSelectOptions from './mapLinesSelectOptions';
import showSnackbar from '../../../utils/showSnackbar';

export function changeSelectedLine(choosenLine, mitelId) {
  return {
    type: sagaActions[SET_SELECTED_LINE],
    choosenLine,
    mitelId
  };
}

export function* setSelectedLine({ choosenLine, mitelId }) {
  const mappedData = yield call(mapDataToUpdate, {
    choosenLine
  });

  const response = yield call(updateData, {
    url: `${OUTSIDE_API.USERS}/${mitelId}/lines/active`,
    data: {
      ...mappedData
    }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(
      showSnackbar,{
        intlTextKey: 'MESSAGE.CHANGE_LINE',
        messageType: 'success'
      }
    );
  }

  const lines = yield call(getData, `${OUTSIDE_API.USERS}/${mitelId}/lines`);

  const { linesOptions, selectedLine } = yield call(
    mapLinesSelectOptions,
    lines
  );

  yield put(reduxActions.setLines({ linesOptions, selectedLine }));
}

function* watchSetSelectedLine() {
  yield takeLatest(sagaActions[SET_SELECTED_LINE], setSelectedLine);
}

export default watchSetSelectedLine();

function mapDataToUpdate({ choosenLine }) {
  const { groupId, type } = choosenLine;

  return {
    type,
    group_id: groupId
  };
}
