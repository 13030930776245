import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_SETTINGS = 'SET_SETTINGS';
const RESET_SETTINGS = 'RESET_SETTINGS';

export const actionTypes = createReduxActionTypes('APP_SETTINGS', [
  SET_SETTINGS,
  RESET_SETTINGS
]);

const DEFAULT_STATE = {
  settings: {},
  partnerId: ''
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_SETTINGS]: {
      const { settings, partnerId } = action;
      return {
        ...state,
        settings,
        partnerId
      };
    }
    case actionTypes[RESET_SETTINGS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setSettings: (settings, partnerId) => ({
    type: actionTypes[SET_SETTINGS],
    settings,
    partnerId
  }),
  resetSettings: () => ({ type: actionTypes[RESET_SETTINGS] })
};
