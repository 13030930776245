import Optional from '../../../utils/optional';
import { calculateMonthUsage } from '../../../utils/findCurrentMonthUsage';

export default function mapDataLimits(dataLimits = []) {
  return dataLimits.map(dataLimit => {
    return {
      ...dataLimit,
      currentMonthUsage: Optional(
        calculateMonthUsage(dataLimit, dataLimit.accumulated_data_usage_month)
      ).or({})
    };
  });
}
