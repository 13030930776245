import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_SCHEDULES = 'SET_SCHEDULES';
const RESET_SCHEDULES = 'RESET_SCHEDULES';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('SCHEDULES', [
  SET_SCHEDULES,
  RESET_SCHEDULES,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  schedules: [],
  prompts: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_SCHEDULES]: {
      const { schedules, prompts } = action;
      return { ...state, schedules, prompts };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_SCHEDULES]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setSchedules: (schedules, prompts) => ({
    type: actionTypes[SET_SCHEDULES],
    schedules,
    prompts
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetSchedules: () => ({ type: actionTypes[RESET_SCHEDULES] })
};

export const getters = {
  getSchedules: state => state.schedules.schedules
};