import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import HomePage from '../pages/home/HomePage';
import LogoutPage from '../pages/auth/Logout';
import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import AuthPage from '../pages/auth/AuthPage';
import ErrorDialog from '../partials/errorDialog/ErrorDialog';
import ChangePass from '../modals/changePass/ChangePass';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import Optional from '../utils/optional';
import sentryConfig from '../utils/sentryConfig';
import flowRight from 'lodash.flowright';
import { loginUser } from '../pages/auth/actions/login';
import Logged from '../pages/auth/Logged';
import TeamsLogin from '../pages/auth/MsLogin';
import { isDefined, isStringEmpty } from '../utils/isDefined';
import LoadingScreen from '../pages/loadingScreen/LoadingScreen';
import withSettings from '../utils/withSettings';
import ShowHistory from '../modals/showHistory/ShowHistory';
import ShowDatalimit from '../modals/showDatalimit/ShowDatalimit';
import EditDatalimit from '../modals/editDatalimit/EditDatalimit';
import SwapSimcard from '../modals/swapSimcard/SwapSimcard';
import ActivationMessageDialog from '../partials/activationMessageDialog/ActivationMessageDialog';
import DatalimitLogs from '../modals/datalimitLogs/DatalimitLogs';

function Routes({
  history,
  location,
  isAuthorized,
  menuConfig,
  user,
  settings,
  clientId
}) {
  sentryConfig(user);

  const {
    isResetPage,
    isLoggedPage,
    isTeamsLogin,
    isStartPage
  } = getInfoFromLocation(location);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */

    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      {isResetPage ? (
        <ResetPassword />
      ) : isTeamsLogin ? (
        <TeamsLogin />
      ) : isLoggedPage ? (
        <Logged />
      ) : isDefined(settings) ? (
        <Switch>
          {!isAuthorized ? (
            /* Render auth page when user at `/auth` and not authorized. */
            <AuthPage />
          ) : (
            /* Otherwise redirect to root page (`/`) */
            <Redirect from="/auth" to={`/${clientId}/dashboard`} />
          )}

          {/* <Route path="/error" component={ErrorsPage} /> */}
          <Route path="/logout" component={LogoutPage} />

          {!isAuthorized ? (
            /* Redirect to `/auth` when user is not authorized */
            <Redirect to="/auth/login" />
          ) : (
            <Layout>
              {isStartPage ? (
                <Redirect from="/" to={`/${clientId}/dashboard`} />
              ) : (
                <Route path="/:id?/:page?" component={HomePage} />
              )}
            </Layout>
          )}
        </Switch>
      ) : (
        <LoadingScreen />
      )}
      <ErrorDialog />
      <ShowHistory />
      <ChangePass />
      <ShowDatalimit />
      <EditDatalimit />
      <ToastContainer position={'bottom-center'} limit={3} />
      <SwapSimcard />
      <ActivationMessageDialog />
      <DatalimitLogs />
    </LayoutContextProvider>
  );
}

const mapStatesToProps = ({ auth, builder: { menuConfig } }) => {
  return {
    menuConfig,
    isAuthorized: auth.isLogged,
    userName: Optional(auth.user)
      .map(user => user.username)
      .or(null),
    clientId: Optional(auth.defaultClient)
      .map(defaultClient => defaultClient.id)
      .or(''),
    user: Optional(auth.user).or({})
  };
};

const mapDispatchToProps = {
  loginUser
};

export default flowRight(
  withRouter,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps)
)(Routes);

function getInfoFromLocation(location) {
  if (!location) {
    return null;
  }

  const [, page] = location.pathname.split('/');
  const isResetPage = page === 'password_reset';
  const isLoggedPage = page === 'logged';
  const isTeamsLogin = page === 'teams_login';
  const isStartPage = isStringEmpty(page);

  return { isResetPage, isLoggedPage, isTeamsLogin, isStartPage };
}
