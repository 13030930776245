import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import styles from '../styles/userDetail';
import BasicInfo from './BasicInfo';
import UserPresence from '../../../partials/userPresence/UserPresence';
import { isDefined,doesArrayHasLength } from '../../../utils/isDefined';
import UserMenu from './UserMenu';
import TextFieldWithEditDialog from '../../../partials/textFieldWithEditDialog/TextFieldWithEditDialog';
import Optional from '../../../utils/optional';
import withUserCheck from '../../../utils/withUserCheck';
import withSettings from '../../../utils/withSettings';
import eventListener from '../actions/eventListener';
import formatDate from '../../../utils/formatDate';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import getUserInfoFromLocation from '../actions/getUserInfoFromLocation';
import UserPhoto from './UserPhoto';
import { translate } from '../../../i18n/I18nProvider';
import MyNumbers from './MyNumbers';

const NOTE_FIELD_OPTIONS = {
  labelIntlKey: 'USER.NOTE',
  tooltipIntlKey: 'TOOLTIP.EDIT_NOTE',
  editDialogLabelIntlKey: 'USER.EDIT_NOTE',
  updateSuccessIntlKey: 'MESSAGE.UPDATE_USER_NOTE',
  field: 'note',
  maxTextLength: 25,
  isLabel: true,
  editOnValueClick: true
};

const UserDetails = ({
  name,
  classes,
  connect30_user_id,
  user,
  isDomainProvided,
  settings,
  mitelData,
  hasCurrentUserConnectId,
  presenceToken = {},
  isCurrentUserSelectedClientUser,
  updatedNote,
  isPartnerAdmin,
  location,
  mappedDiversions,
  email
}) => {
  const [actualAvailability, setActualAvailability] = useState(null);
  const { isCurrentUser } = useMemo(
    () => getUserInfoFromLocation({ location }),
    [location]
  );

  const mappedEmail = Optional(mitelData.email).or(Optional(email).or(''));

  useEffect(() => {
    if (Object.keys(presenceToken).length > 0) {
      return eventListener(presenceToken);
    }
    // eslint-disable-next-line
  }, [presenceToken]);

  const noteCustomClasses = {
    root: classes.noteRoot,
    text: classes.noteText,
    label: classes.noteLabel
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.row, classes.firstRow)}>
        <div className={classes.title}>{translate('ACCOUNT')}</div>
        {(can('update', createSubject('User', user)) || isCurrentUser) && (
          <UserMenu user={user}>
            <i
              className={clsx('fas fa-ellipsis-h', classes.dotsMenuIcon)}
              style={{ color: settings.colors.primary }}
            ></i>
          </UserMenu>
        )}
      </div>
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.secondRow)}>
          <UserPhoto actualAvailability={actualAvailability} />
          <div className={classes.userStatus}>
            <div className={classes.name}>
              {Optional(name).or(
                Optional(mitelData.name)
                  .map(
                    name =>
                      `${Optional(name.givenName).or('')} ${Optional(
                        name.familyName
                      ).or('')}`
                  )
                  .or('')
              )}
              {user.locked && (
                <Tooltip
                  title={Optional(user.locked_at)
                    .map(date => formatDate(date))
                    .or('')}
                >
                  <i
                    className={clsx(classes.lockIcon, 'fas fa-lock')}
                    style={{ color: settings.colors.danger }}
                  ></i>
                </Tooltip>
              )}
            </div>
            <div className={classes.container}>{mappedEmail}</div>
            {hasCurrentUserConnectId &&
              connect30_user_id &&
              isDomainProvided &&
              (isCurrentUserSelectedClientUser || isCurrentUser) &&
              (!isPartnerAdmin || isCurrentUser) && (
                <UserPresence
                  setActualAvailability={availability =>
                    setActualAvailability(availability)
                  }
                />
              )}
          </div>
        </div>
        {hasCurrentUserConnectId &&
          connect30_user_id &&
          isDomainProvided &&
          (isCurrentUserSelectedClientUser || isCurrentUser) &&
          (!isPartnerAdmin || isCurrentUser) && (
            <TextFieldWithEditDialog
              {...NOTE_FIELD_OPTIONS}
              requestId={connect30_user_id}
              updatedValue={updatedNote}
              customClasses={noteCustomClasses}
            />
          )}
        <BasicInfo />
        {doesArrayHasLength(Object.keys(mappedDiversions)) && <MyNumbers />}
      </div>
    </div>
  );
};

const mapStatesToProps = ({ user, selectClient }) => {
  const {
    name,
    classes,
    photo,
    connect30_user_id,
    presenceToken,
    updatedUserData,
    mappedDiversions,
    email
  } = user;
  return {
    presenceToken,
    mappedDiversions,
    name,
    classes,
    photo,
    email,
    connect30_user_id,
    user,
    isDomainProvided: isDefined(selectClient.selectedClient.connect30_domain),
    mitelData: Optional(user.mitelData).or({}),
    updatedNote: updatedUserData.updatedNote
  };
};

export default flowRight(
  withRouter,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(UserDetails);
