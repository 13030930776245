import React, { useMemo } from 'react';

const UsageCell = ({ percentage = 0, value }) => {
  const valueColor = useMemo(() => {
    if (percentage > 100) {
      return '#F20000';
    }

    if (percentage >= 80) {
      return '#F48500';
    }

    return 'inherit';
  }, [percentage]);

  return <div style={{ color: valueColor }}>{value}</div>;
};

export default UsageCell;
