import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions as reduxActions } from './reducers';
import CdrHooksTable from './components/CdrHooksTable';
import EditCdrHook from '../../modals/editCdrHook/EditCdrHook';

const CdrHooks = ({ resetCdrHooks }) => {
  useEffect(() => {
    return resetCdrHooks;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <CdrHooksTable />
      <EditCdrHook />
    </div>
  );
};

const mapDispatchToProps = {
  resetCdrHooks: reduxActions.resetCdrHooks
};

export default connect(null, mapDispatchToProps)(CdrHooks);
