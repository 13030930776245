const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px'
    },
    photo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      objectFit: 'cover',
      width: '400px',
      height: '400px',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '200px'
      }
    },
    closeIcon: {
      fontSize: '32px',
      position: 'relative',
      cursor: 'pointer',
      top: '-390px',
      left: '10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        top: '-190px',
        left: '10px'
      }
    },
    noPhotoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '32px'
    }
  };
};

export default styles;
