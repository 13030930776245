import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  withStyles
} from '@material-ui/core';

import styles from '../styles/rangesDetails';
import Optional from '../../../utils/optional';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import NumberData from '../../../partials/numberData/NumberData';

const NumberDetails = ({ data, isMobile, classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        {false && (
          <TableCell className={classes.expandCell}>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}
        <TableCell className={classes.numberCell}>{data.number}</TableCell>
        <TableCell className={classes.functionCell}>
          {Optional(data.function).or('')}
        </TableCell>
        <TableCell className={classes.nameCell}>
          {Optional(data.name).or('')}
        </TableCell>
        {isMobile && (
          <TableCell className={classes.nameCell}>
            {Optional(data.description).or('')}
          </TableCell>
        )}
      </TableRow>
      {false && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} unmountOnExit>
              <NumberData number={data.number} getSubscriptionData={false} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default withStyles(styles)(NumberDetails);
