import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';
import getQueuesRealtimeData from './getQueuesRealtimeData';
import groupQueuesByUser from './groupQueuesByUser';

export default function queuesEventListener({ queuesToken, userMitelId }) {
  const sse = new EventSource(`${queuesToken.url}?t=${queuesToken.token}`);

  sse.onmessage = async e => {
    const queues = await getQueuesRealtimeData({ data: e.data, userMitelId });

    const queuesGroupedByUser = groupQueuesByUser(queues);

    store.dispatch(
      reduxActions.setUpdatedQueues({ queues, queuesGroupedByUser })
    );
  };
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
}
