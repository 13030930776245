import changeObjectKeysCamelToUnderscore from '../../../utils/changeObjectKeysCamelToUnderscore';
import Optional from '../../../utils/optional';

export default function mapScheduleForUpdate({ tableSchedules }) {
  return tableSchedules.map(schedule => {
    const {
      disable_recording_prompts,
      end,
      forward_number,
      hangup,
      name,
      prompt,
      recurrence_end,
      recurrence_type,
      recurrence,
      start,
      type
    } = changeObjectKeysCamelToUnderscore(schedule);

    return {
      name,
      action: {
        disable_recording_prompts,
        forward_number,
        hangup,
        prompt,
        type
      },
      limits: {
        start,
        end,
        recurrence,
        recurrence_type,
        recurrence_end: Optional(recurrence_end)
          .map(data => {
            return { type: data.type, date: data.value };
          })
          .or(null)
      }
    };
  });
}
