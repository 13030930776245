import React from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import DataLimitsStats from '../../partials/dataLImitsStats/DataLimitsStats';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';

export function EditDatalimit({
  classes,
  open,
  intl,
  resetShowDatalimit,
  datalimitId,
  datalimitName,
  updateAfterClose,
  setUpdate
}) {
  const onClose = () => {
    if (updateAfterClose) {
      setUpdate(true);
    }
    return resetShowDatalimit();
  };

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        container: classes.container,
        paper: classes.content,
        scrollPaper: classes.scrollPaper
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onCloseClick={onClose}>
        {`${intl.formatMessage({
          id: 'DATA_LIMITS.DATALIMIT'
        })} ${datalimitName}`}
      </CustomDialogTitle>
      <DialogContent>
        <DataLimitsStats data={{ id: datalimitId }} isEditView={true} />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ showDatalimit }) => {
  return {
    ...showDatalimit
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditDatalimit);
