import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { isDefined } from '../../../utils/isDefined';
import mapParameters from './mapParameters';

export async function runCustomJob(customJob = {}, formData) {
  const { parameters } = customJob;

  const data = { parameters: {}, form_parameters: {} };

  if (isDefined(formData)) {
    data.form_parameters = formData;
  }

  if (isDefined(parameters)) {
    data.parameters = mapParameters(parameters);
  }

  try {
    const response = await postData({
      url: `${URLS.CUSTOM_JOBS}/${customJob.id}/run`,
      data
    });

    if (response.status === RESPONSE_CODES.ACCEPTED) {
      showSnackbar({
        intlTextKey: 'MESSAGE.CUSTOM_JOB_RUN',
        messageType: 'success'
      });
    }

    return true;
  } catch {
    return false;
  }
}
