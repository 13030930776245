import React, { useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { MuiThemeProvider, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { TABLE_OPTIONS } from '../../../consts/tableOptions';
import Optional from '../../../utils/optional';
import withDetailsView from '../../../utils/withDetailsView';
import styles, { themes } from '../styles';
import AgentsDetails from './AgentsDetails';
import { logUserActions } from '../actions/logUserToDistgroup';
import QueuesTableTitle from './QueuesTableTitle';
import tableColumns from './tableColumns';
import DetailPanelNoPermission from '../../../partials/tableNoPermission/TableNoPermission';
import withUserCheck from '../../../utils/withUserCheck';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { actions as reduxActions } from '../reducers';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import { doesArrayHasLength } from '../../../utils/isDefined';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';

export function QueuesTable({
  classes,
  queues,
  intl,
  logUserActions,
  isAdminUser,
  isLoading,
  connectDomain,
  isCurrentUserSelectedClientUser,
  userMitelId,
  columns,
  setColumns,
  customJobs
}) {
  const tableDefaultColumns = useMemo(() => {
    return tableColumns({
      intl,
      logUserActions,
      isAdminUser,
      connectDomain,
      isCurrentUserSelectedClientUser,
      userMitelId
    });
    // eslint-disable-next-line
  }, [userMitelId]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Queues'
    });
  }, [setColumns, tableDefaultColumns]);

  const detailPanel = ({ rowData }) => {
    return rowData.showAgents ? (
      <AgentsDetails queue={rowData} />
    ) : (
      <DetailPanelNoPermission />
    );
  };

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Queues');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      return buttons;
    },
    // eslint-disable-next-line
    [customJobs]
  );

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Queues'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MuiThemeProvider theme={themes.table}>
      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.tableOwerlay}>
            <CircularProgress />
          </div>
        ) : null}
        <MaterialTable
          components={{
            Toolbar: props => (
              <MTableToolbar
                classes={{
                  title: classes.toolbarTitle
                }}
                {...props}
              />
            )
          }}
          title={<QueuesTableTitle />}
          actions={actions}
          columns={columns}
          data={queues}
          options={TABLE_OPTIONS.QUEUES}
          style={styles.table}
          detailPanel={
            isCurrentUserSelectedClientUser ? detailPanel : undefined
          }
          onChangeColumnHidden={onChangeColumnHidden}
        />
      </div>
    </MuiThemeProvider>
  );
}

const mapStatesToProps = ({ queues, auth, selectClient, loader }) => {
  return {
    isLoading: loader.isSpinVisible,
    queues: Optional(queues.queues).or([]),
    columns: queues.columns,
    connectDomain: Optional(selectClient.selectedClient)
      .map(client => client.connect30_domain)
      .or(auth.defaultClient.connect30_domain),
    userMitelId: auth.user.connect30_user_id
  };
};

const mapDispatchToProps = {
  logUserActions,
  setColumns: reduxActions.setColumns
};

export default flowRight(
  injectIntl,
  withUserCheck,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(QueuesTable);
