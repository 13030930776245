import { useMemo } from 'react';

const usePagination = (data, page, size) => {
  const paginatedItems = useMemo(() => {
    const itemsFrom = page * size;
    const itemsTo = itemsFrom + size;
    return data.slice(itemsFrom, itemsTo);
  }, [data, page, size]);
  return paginatedItems;
};

export default usePagination;
