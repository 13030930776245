import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/editDialog';
import update from '../actions/update';
import Optional from '../../../utils/optional';
import PrimaryButton from '../../customButtons/PrimaryButton';
import SecondaryButton from '../../customButtons/SecondaryButton';
import DraggableDialog from '../../DraggableDialog';

export function EditDialog({
  open,
  onClose,
  text,
  classes,
  intl,
  requestId,
  editDialogLabelIntlKey,
  updateSuccessIntlKey,
  field,
  onSubmit
}) {
  const onSubmitClick = async values => {
    await update({
      text: values.text,
      requestId,
      field,
      updateSuccessIntlKey
    });

    onSubmit(values.text);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      PaperComponent={DraggableDialog}
    >
      <DialogTitle className={classes.header}>
        {intl.formatMessage({
          id: editDialogLabelIntlKey
        })}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{ text: Optional(text).or('') }}
          onSubmit={onSubmitClick}
        >
          {({ handleSubmit, values, handleBlur, handleChange, dirty }) => {
            return (
              <Form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  className={classes.textfield}
                  name="text"
                  variant="outlined"
                  value={values.text}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline={true}
                  minRows={4}
                  inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
                />
                <footer className={classes.footer}>
                  <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
                    {intl.formatMessage({
                      id: 'BUTTON.CONFIRM'
                    })}
                  </PrimaryButton>
                  <SecondaryButton onClick={() => onClose()}>
                    {intl.formatMessage({
                      id: 'BUTTON.CANCEL'
                    })}
                  </SecondaryButton>
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default flowRight(injectIntl, withStyles(styles))(EditDialog);
