import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import InputRow from '../../../partials/dataRows/InputRow';
import styles from '../styles/frontendSettings';

function OtherSettings({ classes, intl, form }) {
  const customInputStyles = useMemo(() => {
    return {
      root: { width: 'unset', fontSize: '14px' },
      label: {  width: '200px' },
      textfield: { width: '300px' }
    };
  }, []);

  return (
    <div className={classes.root} >
      <div
        className={classes.title}
      >
        {intl.formatMessage({
          id: 'APP_SETTINGS.OTHER_SETTINGS'
        })}
      </div>

      <div className={classes.section}>
        <div className={classes.sectionTitle}></div>
        <div>
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.FRONTEND_URL"
            name="frontendUrl"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.CUSTOMER_SERVICE_EMAIL"
            name="customerServiceEmail"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.DISPLAY_NAME"
            name="displayName"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.LETTER_FOOTER"
            name="letterFooter"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.WEBSITE"
            name="websiteURL"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.HELPDESK"
            name="helpdeskURL"
            {...form}
          />
          <InputRow
          mobile={true}
            customStyles={customInputStyles}
            label="APP_SETTINGS.SUPPORT_NUMBER"
            name="supportPhoneNumber"
            {...form}
          />
        </div>
      </div>
    </div>
  );
}

export default flowRight(
  injectIntl,
  withStyles(styles)
)(OtherSettings);
