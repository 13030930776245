export default {
  root: {
    marginLeft: '-40px',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    backgroundColor: '#00f'
  },
  contactView: {
    marginTop: '50px'
  },
  phoneIcon: { color: '#FFF' }
};
