const styles = theme => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #EBEDF2',
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      '& button': {
        margin: '10px'
      }
    },
    stepDataContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px',
      fontWeight: 600
    },
    address: {
      flexDirection: 'column',
      width: 'fit-content',
      alignItems: 'flex-start'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
      fontWeight: 400,
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    },
    lastSentence: { margin: '5px 0' },
    centerContainer: {
      width: '100%',
      display: 'flex',
      paddingLeft: '60px'
    },
    info: {
      width: '250px',
      margin: '10px'
    }
  };
};

export default styles;
