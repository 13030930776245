import React, { useCallback, useEffect, useMemo, useState } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/subscriptionData';
import DataRow from '../../../partials/Datarow/DataRow';
import Optional from '../../../utils/optional';
import StatusCell from '../../../partials/statusCell/StatusCell';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import { isArray, isUndefined } from '../../../utils/isDefined';
import getSubscriptions from '../actions/getSubscriptions';
import { actions as reduxActions } from '../reducers';
import Problems from './Problems';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';
import { translate } from '../../../i18n/I18nProvider';
import Features from './Features';
import { CircularProgress } from '@material-ui/core';

const SubscriptionData = ({
  selectedNumber,
  classes,
  subscription,
  clientId,
  lang
}) => {
  const [refresh, setRefreh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getSubscriptions({
        number: selectedNumber.value,
        clientId,
        setIsLoading
      });
    })();
    // eslint-disable-next-line
  }, [selectedNumber, clientId]);

  useEffect(() => {
    (async () => {
      if (refresh) {
        await getSubscriptions({
          number: selectedNumber.value,
          clientId,
          reload: true,
          setIsLoading
        });

        setRefreh(false);
      }
    })();
    // eslint-disable-next-line
  }, [selectedNumber, clientId, refresh]);

  const checkRefillStatus = useCallback((projection = []) => {
    const refill = projection.find(el => el.name === 'Refill');

    if (isUndefined(refill)) {
      return false;
    }

    return refill.value;
  }, []);

  const customClasses = useMemo(() => {
    return {
      dataRow: {
        root: classes.dataRow_root,
        value: classes.dataRow_value
      }
    };
  }, [classes]);

  return (
    <div className={classes.root}>
      <header className={classes.title}>
        {translate('REQUEST_NEW_USER.SUBSCRIPTION')}
        <RefreshButton onClick={() => setRefreh(true)} />
      </header>
      {isLoading && (
            <div className={classes.overlay}>
              <CircularProgress />
            </div>
          )}
      {isUndefined(subscription) ? (
        <div className={classes.textContainer}>
          <div> There is no billing data for this number</div>
        </div>
      ) : (
        <div className={classes.contentContainer}>
          
          <DataRow
            label={`${translate('REQUEST_NEW_USER.SUBSCRIPTION')} `}
            value={Optional(subscription?.skuTranslations?.[lang]).or(
              subscription?.skuTranslations?.da
            )}
            customClasses={customClasses.dataRow}
            dense
          />
          <DataRow
            label={`${translate('NAME')} `}
            value={Optional(subscription?.name).or('')}
            customClasses={customClasses.dataRow}
            dense
          />
          <DataRow
            label={`${translate('SUBSCRIPTIONS.REFILL')} `}
            value={
              <StatusCell
                isTable={false}
                {...pickOptionsForStatusCell(
                  STATUS_BOX_OPTIONS.SIMCARD_ACTIVE,
                  checkRefillStatus(subscription?.projection)
                )}
              />
            }
            customClasses={customClasses.dataRow}
            dense
          />
          {isArray(subscription?.features) && (
            <Features features={subscription?.features} />
          )}
          {isArray(subscription?.problems) && (
            <Problems problems={subscription?.problems} />
          )}
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = ({ user, auth }) => {
  const { selectedNumber = {}, client_id, subscription } = user;

  return {
    selectedNumber,
    clientId: client_id,
    subscription,
    lang: auth.lang
  };
};

const mapDispatchToProps = {
  setSubscription: reduxActions.setSubscription
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SubscriptionData);
