const styles = theme => {
  return {
    root: {
      minWidth: '100px',
      height: '30px',
      borderRadius: '5px',
      backgroundColor: '#FFF',
      '&:hover': { backgroundColor: '#9E9E9E' }
    }
  };
};

export default styles;
