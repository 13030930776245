import React from 'react';
import { createTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
// import { selectors as builderSelectors } from '../ducks/builder';
// import partnersValues from '../../app/consts/frontendSettings';
// import getValueFromInitialValues from '../../app/utils/getValueFromInitialValues';
// import store from '../../app/store/store';
// import Optional from '../../app/utils/optional';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Open Sans'].join(',')
    },

    palette: {
      contrastThreshold: 3,
      orange: { light: '#d1935a', medium: '#bd824b' },
      primary: {
        main: '#5d78ff',
        medium: '#516b82'
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#0abb87',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff'
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#fd397a'
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        main: '#a8b241',
        light: '#a8b241',
        medium: '#959e3e'
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },
    overrides: {
      MuiTabs: {
        indicator: { backgroundColor: '#16374F' }
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '12px'
        }
      },
      MuiPaper: {
        elevation2: {
          boxShadow: 'unset'
        }
      },
      MuiTableSortLabel: {
        root: {
          fontWeight: 800,
          fontSize: '14px'
        }
      },
      MuiTouchRipple: {
        root: {
          top: 'unset'
        }
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiDrawer: {
        paper: { backgroundColor: '#F2F2F2' }
      },
      MuiTableRow: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important'
          }
        }
      },
      MuiTableCell: {
        root: {
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '8px'
        },
        sizeSmall: {
          padding: '6px 10px'
        }
      },
      MuiTextField: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 'unset',
            '&:hover fieldset': {
              borderColor: '#16374f'
            },
            '&.Mui-focused fieldset': {
              borderRadius: 'unset',
              borderColor: '#16374f',
              borderWidth: '1px'
            }
          }
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1020,
        lg: 1280,
        xl: 1920
      }
    }
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
