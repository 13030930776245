import { isEmptyArray } from 'formik';
import moment from 'moment';
import { WEEKDAYS } from '../../../consts/statusBoxOptions';
import { translate } from '../../../i18n/I18nProvider';
import store from '../../../store/store';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapCallLog(data) {
  const today = moment();
  const favorites = store.getState().userContacts.favorites;
  let log = [];

  data.map(el => {
    const { CallDTO, VoiceMailDTO } = el;

    if (isDefined(CallDTO)) {
      const displayedName = CallDTO.remoteParty?.displayName;
      const number = Optional(CallDTO.remoteParty?.number).or(' ');
      const dateToShow = mapDateToShow(CallDTO.startTime, today);
      const duration = mapDuration(CallDTO.duration);
      const isFavorite = mapIsFavorite(CallDTO, favorites);

      return (log = [
        ...log,
        {
          ...CallDTO,
          dateToShow,
          duration,
          displayedName,
          number,
          isFavorite
        }
      ]);
    }

    if (isDefined(VoiceMailDTO)) {
      const displayedName = VoiceMailDTO.remoteParty?.displayName;
      const number = Optional(VoiceMailDTO.remoteParty?.number).or(' ');
      const dateToShow = mapDateToShow(VoiceMailDTO.startTime, today);

      return (log = [
        ...log,
        {
          ...VoiceMailDTO,
          dateToShow,
          displayedName,
          number
        }
      ]);
    }
    return true;
  });

  return log;
}

function mapDateToShow(date, today) {
  let day = moment(date).format('YYYY-MM-DD');

  const diff = moment(today).diff(day, 'days');
  if (diff < 7) {
    if (diff === 0) {
      day = translate('TODAY');
    } else if (diff === 1) {
      day = translate('YESTERDAY');
    } else {
      day = translate(WEEKDAYS[moment(date).isoWeekday()]);
    }
  }

  const hour = moment(date).format('HH:mm');

  return `${day} ${hour}`;
}

function mapDuration(duration) {
  if (isUndefined(duration)) {
    return '';
  }

  if (duration < 60) {
    return `${duration} ${translate('SEC_SHORT')}`;
  }

  const minutes = Math.floor(duration / 60);
  const sec = duration % 60;

  return `${minutes}.${sec} min.`;
}

export function mapIsFavorite(data, favorites) {
  if (isEmptyArray(favorites) || isUndefined(data.remoteParty?.contactId)) {
    return false;
  }

  const mappedFavorites = favorites.find(
    el => el.id === data.remoteParty?.contactId
  );

  if (isDefined(mappedFavorites)) {
    return true;
  }

  return false;
}
