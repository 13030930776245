export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    width: '100%',
    backgroundColor: '#fff'
  },
  label: {
    height: '38px',
    width: '150px',
    paddingLeft: '10px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center'
  },
  labelContainer: {
    fontSize: '16px',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  }
};

export const customSelectStyles = {
  container: { width: '250px' },
  option: {
    fontSize: '12px',
    display: 'flex'
  },
  control: {
    borderStyle: 'hidden',
    width: '250px',
    boxShadow: 'none'
  },
  valueContainer: {
    fontSize: '18px',
    display: 'flex',
    padding: 'none'
  },
  indicatorSeparator: { display: 'none' },
  menu: { zIndex: 100, width: '250px', left: 0 }
};
