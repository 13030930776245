export default {
  root: {
    display: 'flex',
    alignItems: 'center'
  }
};

export const customSelectStyles = {
  root: {
    width: '450px',
    marginRight: '10px',
    marginLeft: '30px'
  },
  field: { width: '100%' },
  value: { width: '300px' }
};

export const customStyles = {
  audio: {
    width: '110px'
  }
};
