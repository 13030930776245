import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import InformationStep from './InformationStep';
import { actions as reduxActions } from '../reducers';
import SubscriptionStep from './SubscriptionStep';
import { isStringEmpty } from '../../../utils/isDefined';
import PortNumberStep from './PortNumberStep';
import CustomStepperStepIcon from '../../../partials/CustomStepperStepIcon/CustomStepperStepIcon';
import AddressStep from './AddressStep';

export function UserStepper({ isMobileNumberProvided, intl }) {
  const STEPS = [
    intl.formatMessage({
      id: 'REQUEST_NEW_USER.INFORMATION_STEP'
    }),
    intl.formatMessage({
      id: 'REQUEST_NEW_USER.PORT_NUMBER_STEP'
    }),
    intl.formatMessage({
      id: 'REQUEST_NEW_USER.SUBSCRIPTION_STEP'
    }),
    intl.formatMessage({
      id: 'REQUEST_NEW_USER.ADDRESS_STEP'
    })
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (step = 1) => {
    return setActiveStep(prevActiveStep => prevActiveStep + step);
  };

  const handleBack = (step = 1) => {
    return setActiveStep(prevActiveStep => prevActiveStep - step);
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepperStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{getStepContent(activeStep, handleNext, handleBack)}</div>
    </>
  );
}

const mapStatesToProps = ({ requestUserWizard }) => {
  return {
    isMobileNumberProvided: !isStringEmpty(
      requestUserWizard.userData.firstStep.mobile_number
    )
  };
};

const mapDispatchToProps = {
  resetWizard: reduxActions.resestUserWizard
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(UserStepper);

function getStepContent(stepIndex, handleNext, handleBack) {
  switch (stepIndex) {
    case 0:
      return <InformationStep handleNext={step => handleNext(step)} />;
    case 1:
      return <PortNumberStep handleNext={handleNext} handleBack={handleBack} />;
    case 2:
      return (
        <SubscriptionStep handleBack={handleBack} handleNext={handleNext} />
      );
    case 3:
      return <AddressStep handleBack={handleBack} />;
    default:
      return 'Unknown stepIndex';
  }
}
