import { toast } from 'react-toastify';
import React from 'react';

import { translate } from '../i18n/I18nProvider';
import createErrorText from './createErrorText';
import Optional from './optional';

const AUTOCLOSE_DEFAULT = 3000;

export default function showSnackbar({
  intlTextKey,
  text,
  messageType,
  time,
  errorMessage
}) {
  const errorText = Optional(createErrorText(errorMessage)).or('');

  const textToShow = Optional(intlTextKey)
    .map(text => translate(text))
    .or(Optional(text).or(errorText));

  return toast[messageType](textToShow, {
    autoClose: Optional(time).or(AUTOCLOSE_DEFAULT),
    position: toast.POSITION.TOP_RIGHT
  });
}

export const showCustomSnackbar = ({ component, messageType }) => {
  return toast[messageType](component, {
    autoClose: AUTOCLOSE_DEFAULT,
    position: toast.POSITION.TOP_RIGHT
  });
};

export function showErrorSnackbar({
  messageType,
  time,
  errorMessage,
  showEndpoint
}) {
  return toast[messageType](
    <ErrorMessage errorMessage={errorMessage} showEndpoint={showEndpoint} />,
    {
      autoClose: Optional(time).or(AUTOCLOSE_DEFAULT),
      position: toast.POSITION.TOP_RIGHT
    }
  );
}

function ErrorMessage({ errorMessage, showEndpoint = false }) {
  return (
    <div>
      {showEndpoint && (
        <div style={{ fontSize: '9px' }}>{errorMessage.endpoint}</div>
      )}
      <div>{errorMessage.status}</div>
      {errorMessage.errorTextArray.map((el, i) => {
        return <div key={i}>{el}</div>;
      })}
    </div>
  );
}
