import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import AppVersion from '../../../app/partials/appVersion/AppVersion';
import withSettings from '../../../app/utils/withSettings';

const styles = {
  footer: {
    '& a': {
      color: '#2d81b5',
      '&:hover': { color: '#00aaff' }
    }
  }
};

function Footer({ settings, footerClasses, footerContainerClasses, classes }) {
  const footerRef = useRef();

  useEffect(() => {
    footerRef.current.innerHTML = settings.footer;
  }, [settings.footer]);

  const today = new Date().getFullYear();
  return (
    <div
      className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <div className={`kt-container ${footerContainerClasses}`}>
        <div className="kt-footer__copyright">
          {today.toString()}&nbsp;&copy;&nbsp;
          <div className={classes.footer} ref={footerRef}></div>
        </div>
        <AppVersion customStyles={{ color: '#74788d', fontSize: '1rem' }} />
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true
  })
});

export default flowRight(
  withSettings,
  connect(mapStateToProps),
  withStyles(styles)
)(Footer);
