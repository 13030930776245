import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import clsx from 'clsx';

import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import styles, { customSelectStyles } from '../styles/selectAccount';
import { actions as reduxActions } from '../reducers';
import Optional from '../../../utils/optional';
import withSettings from '../../../utils/withSettings';

const SelectAccount = ({
  accounts,
  selectedAccount,
  setSelectedAccount,
  classes,
  settings
}) => {
  const onAccountChange = useCallback(
    selected => {
      if (selected.value !== selectedAccount.value) {
        setSelectedAccount(selected);
      }
    },
    // eslint-disable-next-line
    [selectedAccount.value]
  );

  const ValueContainer = props => {
    const { children } = props;
    return (
      <components.ValueContainer {...props}>
        <div className={classes.labelContainer}>
          <div style={{ color: settings.colors.primary }}>
            {Optional(children[0].props.data.name).or('')}
          </div>
          <div className={classes.accountNo}>
            {Optional(children[0].props.data.accountNo).or('')}
          </div>
        </div>
      </components.ValueContainer>
    );
  };

  return (
    <div className={classes.selectContainer}>
      {accounts.length > 1 && Object.keys(selectedAccount).length > 0 ? (
        <Select
          styles={reactSelectDefaultStyles}
          customStyles={customSelectStyles}
          options={accounts}
          value={selectedAccount}
          onChange={onAccountChange}
          isRtl
          components={{ ValueContainer }}
        />
      ) : (
        <div className={clsx(classes.labelContainer, classes.onlyAccount)}>
          <div style={{ color: settings.colors.primary }}>
            {Optional(selectedAccount.name).or('')}
          </div>
          <div className={classes.accountNo}>
            {Optional(selectedAccount.accountNo).or('')}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = ({ iotProducts }) => {
  return {
    accounts: iotProducts.accounts,
    selectedAccount: iotProducts.selectedAccount
  };
};

const mapDispatchToProps = {
  setSelectedAccount: reduxActions.setSelectedAccount
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SelectAccount);
