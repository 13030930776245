import orderBy from 'lodash.orderby';

import createInitials from '../../../utils/createUserInitials';
import { isDefined, isUndefined } from '../../../utils/isDefined';

export default function groupQueuesByUser(queues) {
  const agentsObject = queues.reduce((obj, queue) => {
    const { agents, ...restQueue } = queue;

    if (isUndefined(agents)) {
      return obj;
    }

    agents.map(agent => {
      const { firstName, lastName, id, ...restAgent } = agent;
      const initials = createInitials(firstName, lastName);
      return (obj = {
        ...obj,
        [agent.id]: isDefined(obj[agent.id])
          ? {
              ...obj[agent.id],
              queues: [
                ...obj[agent.id].queues,
                {
                  ...restQueue,
                  ...restAgent
                }
              ]
            }
          : {
              ...agent,
              initials,
              connectId: id,
              name: `${firstName} ${lastName}`,
              queues: [
                {
                  ...restQueue,
                  ...restAgent
                }
              ]
            }
      });
    });

    return obj;
  }, {});

  return orderBy(
    Object.keys(agentsObject).reduce((arr, agent, i) => {
      const mappedQueues = agentsObject[agent].queues.map(queue => {
        return { ...queue, parentId: i };
      });
      return [...arr, { ...agentsObject[agent], id: i }, ...mappedQueues];
    }, []),
    ['initials']
  );
}
