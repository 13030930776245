import { isUndefined } from 'lodash';

import { translate } from '../../../i18n/I18nProvider';
import checkEuIccNumber from '../../../utils/checkEuIccNumber';
import { isStringDefined } from '../../../utils/isDefined';

export default async function validateEuicc({
  euicc,
  currentNetwork,
  clientId
}) {
  const { icc, network } = await checkEuIccNumber(euicc, clientId);

  if (isUndefined(icc)) {
    return {
      info: {
        text: translate('VALIDATION.NOT_VALID_AVAILABLE').split('/')[0],
        isError: true
      }
    };
  }

  const networkShort = isStringDefined(network)
    ? network.split(' ')[0]
    : network;

  const isDifferentNetwok = currentNetwork.split(' ')[0] !== networkShort;

  if (isDifferentNetwok) {
    return {
      info: {
        text: translate('VALIDATION.DIFFERENT_NETWORK'),
        isError: true
      }
    };
  }

  return {
    icc: icc.slice(6),
    info: {
      text: translate('REQUEST_NEW_USER.CORRECT_NUMBER'),
      isError: false
    }
  };
}
