import { call, put, select } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_USER } from './types';
import { getters as selectClientGetters } from '../../../partials/selectClient/reducers';
import selectOptionsCreator from '../../../utils/selectOptionsCreator';
import { OUTSIDE_API } from '../../../consts/endpoints';
import findLowestIdRole from '../../../utils/findLowestIRole';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import { prepareSelectsOptions } from './prepareSelectsOptions';

const DEFAULT_LANGUAGE = 'da';

export function setUser({ user, clientId, isDomainProvided, isBeTable }) {
  return {
    type: sagaActions[SET_USER],
    user,
    clientId,
    isDomainProvided,
    isBeTable
  };
}

export function* getUser({ user, clientId, isDomainProvided, isBeTable }) {
  const SELECTS_ARRAY_PARAMS = [
    {
      endpoint: 'ROLES',
      queryParams: { fields: { partners: 'name' } },
      ignoreNoneOption: true
    },
    {
      customEndpoint: `${OUTSIDE_API.CLIENTS}/${clientId}/users`,
      queryParams: {},
      key: 'TELEPHONY_USERS',
      ignore: !clientId,
      isDomainProvided
    },
    {
      endpoint: 'LANGUAGES',
      queryParams: {},
      ignoreNoneOption: true
    }
  ];

  const clientsList = yield select(selectClientGetters.getAllClientsList);
  const selectsOptions = yield call(selectOptionsCreator, SELECTS_ARRAY_PARAMS);
  const completeSelectsOptions = yield call(
    prepareSelectsOptions,
    selectsOptions,
    clientsList
  );

  const role = yield call(defineRole, user);

  const languageId = Optional(
    completeSelectsOptions.language.find(
      language =>
        language.short ===
        Optional(user)
          .map(user => user.language)
          .or(DEFAULT_LANGUAGE)
    )
  )
    .map(language => language.value)
    .or(null);

  yield put(reduxActions.setSelectsOptions(completeSelectsOptions));
  yield put(
    reduxActions.setUser({
      user: { ...user, role, language: languageId },
      isDomainProvided,
      isBeTable
    })
  );
}

function* watchSetUser() {
  yield takeLatest(sagaActions[SET_USER], getUser);
}

export default watchSetUser();

export function defineRole(user) {
  if (!isDefined(user)) {
    return null;
  }

  return Number(findLowestIdRole(user.roles).id);
}
