import { createTheme } from '@material-ui/core';

const styles = theme => {
  return {
    root: {
      width: '320px',
      backgroundColor: '#FFFFFF'
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#74788D'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0 30px',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    radioGroup: {
      width: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    radioRow: {
      width: '100%',
      margin: '10px 0 10px 5px'
    },
    radioLabel: { marginLeft: '5px' },
    shortcutSelectContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '10px',
      borderBottom: '1px solid #F2F2F2'
    },
    shortcutSelectLabel: {
      marginTop: '10px',
      paddingLeft: '10px',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      margin: '0 10px'
    },
    shortcutSelectRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    deleteIcon: {
      fontSize: '20px',
      marginLeft: '5px',
      fontWeight: '500',
      paddingTop: '10px',
      cursor: 'pointer'
    },
    addButton: {
      display: 'flex',
      width: '20px',
      height: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      color: '#fff',
      borderRadius: '5px',
      marginTop: '10px',
      cursor: 'pointer'
    }
  };
};

export default styles;

export const customStyles = {
  selectRow: {
    root: {
      width: '250px'
    },
    label: {
      marginBottom: '5px',
      color: '#74788D'
    }
  },
  datePicker: {
    root: {
      width: '250px',
      margin: '5px'
    }
  },
  inputRow: {
    root: {
      width: '250px'
    },
    label: {
      marginBottom: '5px',
      color: '#74788D'
    },
    field: { flexDirection: 'column' }
  },
  iconSelect: {
    value: {
      width: '100px'
    },
    root: {
      width: '250px',
      marginLeft: '-60px'
    }
  },
  checkboxRow: {
    root: {
      marginLeft: '-20px'
    }
  }
};

export const materialDatePickerTheme = settings => {
  return createTheme({
    overrides: {
      MuiInput: {
        root: {
          width: '80%'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: settings.colors.aside_menu
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: settings.colors.white,
          color: settings.colors.primary
        }
      },
      MuiPickerDTTabs: {
        tabs: {
          color: settings.colors.primary,
          backgroundColor: settings.colors.white
        }
      },
      MuiPickersDay: {
        day: {
          color: settings.colors.primary
        },
        daySelected: {
          backgroundColor: settings.colors.aside_menu
        },
        dayDisabled: {
          color: '#CACACA'
        },
        current: {
          color: settings.colors.primary
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: settings.colors.aside_menu
        },
        noPoint: {
          backgroundColor: settings.colors.aside_menu
        },
        thumb: {
          borderColor: settings.colors.aside_menu
        }
      },
      PrivateTabIndicator: {
        colorSecondary: { backgroundColor: settings.colors.aside_menu }
      }
    }
  });
};

export const customSelectStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    width: '280px'
  },
  menu: {
    width: '100%'
  },
  option: {
    fontSize: '12px'
  },
  control: {
    width: '95%'
  }
};
