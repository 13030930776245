import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';

import styles from '../styles';
import { isDefined } from '../../../utils/isDefined';
import calculateMbToGb from '../../../utils/calculateMbToGb';
import { Tooltip } from '@material-ui/core';

const CHART_WIDTH = 310;

export function DataLimitRow({
  classes,
  datalimit: { used, limit, value } = {},
  chartColor,
  intl,
  barLabel,
  valueText = '%',
  noData = false,
  noLimit,
  showCharts = true,
  labelId = 'DATA_LIMIT.PACKAGE_DATA'
}) {
  const style = useMemo(() => {
    return showCharts
      ? { ...createDisplayStyle({ used, chartColor, noLimit }) }
      : {};
  }, [used, chartColor, noLimit, showCharts]);

  const usageTooltipText = !(barLabel === 'ROAM') ? `${used} %` : '';

  return (
    <div className={classes.dataLimitRoot}>
      <div className={classes.dataLimitTextContainer}>
        {isDefined(limit) && !noLimit && (
          <>
            <div>
              {intl.formatMessage({
                id: labelId
              })}
            </div>

            <div className={classes.dataLimitValue}>{`${calculateMbToGb(
              limit,
              true
            )} GB`}</div>
          </>
        )}
      </div>
      {showCharts &&
        (!noData ? (
          <div className={classes.dataLimitChartContainer}>
            <div className={classes.labelContainer}>
              <div
                className={classes.dot}
                style={{ backgroundColor: chartColor }}
              ></div>
              {`${intl.formatMessage({
                id: `NUMBER.${barLabel}`
              })} ${!noLimit && valueText === '%' ? '%' : ''}`}
            </div>
            <div className={classes.chart}>
              <div className={classes.dataLimitChart}>
                <Tooltip title={usageTooltipText}>
                  <div className={classes.dataLimitChart} style={style}></div>
                </Tooltip>
              </div>
              {noLimit ? (
                <div className={classes.dataUsedValue}>{`${value} GB`}</div>
              ) : (
                <div className={classes.dataUsedValue}>
                  {`${value} ${valueText}`}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.labelContainer}>
              <div
                className={classes.dot}
                style={{ backgroundColor: chartColor }}
              ></div>
              {`${intl.formatMessage({
                id: `NUMBER.${barLabel}`
              })} ${!noLimit && valueText === '%' ? '%' : ''}`}
            </div>
            <div className={classes.dataLimitNoData}>
              {intl.formatMessage({
                id: 'DATA_LIMITS.NO_DATA'
              })}
            </div>
          </div>
        ))}
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DataLimitRow);

function createDisplayStyle({ used, chartColor, noLimit }) {
  const usedPercentage = Math.floor(
    (CHART_WIDTH * Number(used.split(',')[0])) / 100
  );
  const width = usedPercentage <= CHART_WIDTH ? usedPercentage : CHART_WIDTH;

  return {
    backgroundColor: chartColor,
    width: noLimit ? CHART_WIDTH : `${width}px`
  };
}
