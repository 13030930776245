import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { connect } from 'react-redux';

import { translate } from '../../../i18n/I18nProvider';
import CustomSwitch from '../../../partials/CustomSwitch';
import { actions as reduxActions } from '../reducers';
import styles from '../styles';

const ShowTerminatedSwitch = ({ toggleSwitch, showTerminated, classes }) => {
  const handleSwitch = e => {
    toggleSwitch( e.target.checked);
  };

  return (
    <div className={classes.switchRoot}>
      <div className={classes.expandSwitchLabel}>{translate('ACTIVE')}</div>
      <CustomSwitch checked={showTerminated} handleChange={handleSwitch} />
      <div className={classes.expandSwitchLabel}>{translate('TERMINATED')}</div>
    </div>
  );
};

const mapStatesToProps = ({ connections }) => {
  return {
    showTerminated: connections.showTerminated
  };
};

const mapDispatchToProps = {
  toggleSwitch: reduxActions.toggleShowTerminated
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ShowTerminatedSwitch);
