import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';

export function ActionsButtons({
  onCloseClick,
  handleSubmit,
  dirty,
  intl,
  isEdit
}) {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {intl.formatMessage({
          id: isEdit ? 'BUTTON.SAVE' : 'BUTTON.ADD'
        })}
      </PrimaryButton>
      <SecondaryButton onClick={onCloseClick}>
        {intl.formatMessage({
          id: 'BUTTON.CANCEL'
        })}
      </SecondaryButton>
    </>
  );
}

const mapStatesToProps = ({ addPermission }) => {
  return {
    isEdit: addPermission.isEdit
  };
};

export default flowRight(injectIntl, connect(mapStatesToProps))(ActionsButtons);
