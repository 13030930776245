import { Tooltip, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import styles from './styles';
import clsx from 'clsx';
import { isDefined } from '../../utils/isDefined';

const DataBox = ({
  label,
  value,
  classes,
  width,
  infoIcon = false,
  infoIconText,
  customClasses = {}
}) => {
  const customStyles = useMemo(() => {
    let style = {};

    if (isDefined(width)) {
      style = { ...style, minWidth: width ? `${width}px` : '260px' };
    }

    return style;
  }, [width]);

  return (
    <div
      className={clsx(classes.root, customClasses.root)}
      style={customStyles}
    >
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={infoIconText}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export default withStyles(styles)(DataBox);
