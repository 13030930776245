import React from 'react';

import StatusCell from '../../../partials/statusCell/StatusCell';
import TableNameCell from '../../../partials/tableNameCell/TableNameCell';
import ActionButtons from './ActionButtons';
import Optional from '../../../utils/optional';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import mapNumberToDisplay from '../../../utils/mapNumberToDisplay';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import formatDate from '../../../utils/formatDate';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../../i18n/I18nProvider';
import searchNumbers from '../../../utils/searchNumbers';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';

export default function tableColumns({ client }) {
  const columns = [
    {
      title: 'TABLE.ACTIONS',
      render: rowData => <ActionButtons simcard={rowData} clientId={client} />,
      disableClick: true,
      width: '40px',
      align: 'center',
      sorting: false,
      filtering: false
    },
    {
      title: 'EDIT.USER',
      render: rowData => (
        <TableNameCell
          data={[Optional(rowData.owner).or({})]}
          clientId={rowData.client_id}
          from="simCards"
        />
      ),
      field: 'owner_name',
      disableClick: true
    },
    {
      title: 'TABLE.MOBILE_NUMBER',
      render: rowData => (
        <CopyToClipbaordTooltip
          value={mapNumberToDisplay(rowData.msisdn)}
          isPhoneNumber={true}
        >
          {mapNumberToDisplay(rowData.msisdn)}
        </CopyToClipbaordTooltip>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.msisdn, term);
      },
      field: 'msisdn',
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'CLIENT',
      render: rowData =>
        Optional(rowData.client)
          .map(client => client.name)
          .or(''),
      hideForUserView: true,
      field: 'client.name',
      sorting: false,
      searchable: false
    },
    {
      title: 'TABLE.NETWORK',
      field: 'mobile_network.brand',
      render: rowData => <div>{rowData.mobile_network.brand}</div>,
      filtering: false,
      sorting: false
    },
    {
      title: 'SIMCARD_NUMBER',
      render: rowData => (
        <CopyToClipbaordTooltip value={rowData.icc}>
          {rowData.icc}
        </CopyToClipbaordTooltip>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.icc, term);
      },
      align: 'right',
      field: 'icc',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: 'PIN1',
      field: 'pin1',
      hidden: true,
      untranslatedTitle: true,
      render: rowData => {
        return shouldShowPin(rowData) ? (
          <div>{rowData.pin1}</div>
        ) : (
          <Tooltip title={translate('TOOLTIP.SIMCARD_PIN_INFO')}>
            <i className="fas fa-info-circle"></i>
          </Tooltip>
        );
      },
      align: 'right'
    },
    {
      title: 'PUK1',
      field: 'puk1',
      hidden: true,
      untranslatedTitle: true,
      render: rowData => {
        return shouldShowPin(rowData) ? (
          <div>{rowData.puk1}</div>
        ) : (
          <Tooltip title={translate('TOOLTIP.SIMCARD_PIN_INFO')}>
            <i className="fas fa-info-circle"></i>
          </Tooltip>
        );
      },
      align: 'right'
    },
    {
      title: 'TABLE.PRIMARY_CARD',
      render: rowData => (
        <div>
          {Optional(rowData.primary_card)
            .map(card => (
              <CopyToClipbaordTooltip
                value={mapNumberToDisplay(card.msisdn)}
                isPhoneNumber={true}
              >
                {mapNumberToDisplay(card.msisdn)}
              </CopyToClipbaordTooltip>
            ))
            .or('')}
        </div>
      ),
      exportTransformer: rowData =>
        Optional(rowData.primary_card)
          .map(card => mapNumberToDisplay(card.msisdn))
          .or(''),
      field: 'primary_card',
      hidden: true,
      sorting: false,
      filtering: false
    },
    {
      title: 'TABLE.DESCRIPTION',
      field: 'description'
    },
    {
      title: 'CARD_TYPE',
      field: 'card_type',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.SIMCARD_TYPE,
            rowData.card_type
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.SIMCARD_TYPE)
      },
      width: '80px',
      sorting: false
    },
    {
      title: 'STATUS',
      field: 'active',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.SIMCARD_ACTIVE,
            rowData.active
          )}
        />
      ),
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.SIMCARD_ACTIVE)
      },
      sorting: false
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      exportTransformer: rowData => formatDate(rowData.created_at),
      field: 'created_at',
      hidden: true
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      exportTransformer: rowData => formatDate(rowData.updated_at),
      field: 'updated_at',
      hidden: true
    }
  ];

  let mappedColumns = [...columns];

  if (isDefined(client)) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  if (can('manage', createSubject('SimCard', { client_id: Number(client) }))) {
    mappedColumns = [
      ...mappedColumns,
      {
        title: 'ID',
        field: 'id',
        hidden: true
      }
    ];
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
}

function shouldShowPin(value) {
  return Optional(value?.mobile_network?.brand)
    .map(brand => brand === 'TDC' || brand === 'Telenor')
    .or(false);
}
