import { withStyles } from '@material-ui/styles';
import React from 'react';

import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import styles from '../styles/checkboxesElement';

function CheckboxesElement({ form, classes }) {
  const { setFieldValue } = form;

  const onCheck = (values, field) => {
    const checked = values.target.checked;
    if (!checked) {
      setFieldValue(`${field}Date`, null);
    }
    setFieldValue(`${field}DateSet`, values.target.checked);
  };

  return (
    <div className={classes.root}>
      <CheckboxRow
        label="SCHEDULES.SET_START_DATE"
        name="startDateSet"
        customCheckFunction={value => onCheck(value, 'start')}
        {...form}
      />
      <CheckboxRow
        label="SCHEDULES.SET_END_DATE"
        name="endDateSet"
        customCheckFunction={value => onCheck(value, 'end')}
        {...form}
      />
    </div>
  );
}

export default withStyles(styles)(CheckboxesElement);
