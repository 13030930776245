import React, { cloneElement, useMemo, useState } from 'react';
import { Dialog, DialogContent, withStyles } from '@material-ui/core';

import CustomDialogTitle from '../../customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../DraggableDialog';
import { isUndefined } from '../../../utils/isDefined';
import styles from '../styles';
import { translate } from '../../../i18n/I18nProvider';
import { UsersTable } from './UsersTable';
import Optional from '../../../utils/optional';

export function ShowMoreDataDialog({
  children,
  users,
  from,
  clientId,
  userDialogName
}) {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const mappedUsers = useMemo(() => {
    return users.reduce((arr, user) => {
      if (isUndefined(user) || Object.keys(user).length === 0) {
        return arr;
      }

      return [...arr, user];
    }, []);
  }, [users]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="sm"
        PaperComponent={DraggableDialog}
        open={open}
        onClose={onCloseDialog}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {`${translate('MENU.USERS')} ${Optional(userDialogName)
            .map(name => `: ${name}`)
            .or('')} `}
        </CustomDialogTitle>
        <DialogContent>
          <UsersTable users={mappedUsers} from={from} clientId={clientId} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(ShowMoreDataDialog);
