import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/dataSections';
import InputRow from '../../../partials/dataRows/InputRow';

export function BasicData({ classes, form, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'REQUEST_NEW_USER.INFO'
        })}
      </div>
      <div className={classes.container}>
        <div>
          <InputRow label="NAME" name="name" {...form} />
          <InputRow label="REQUEST_NEW_USER.USERNAME" name="username" {...form} />
          <InputRow name="email" label="EMAIL" {...form} />
          <InputRow name="department" label="DEPARTMENT" {...form} />
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(BasicData);
