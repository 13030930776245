import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles/optionLabel';
import withSettings from '../../../utils/withSettings';
import { translate } from '../../../i18n/I18nProvider';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import { toAbsoluteUrl } from '../../../../_metronic';

function ShortcutLabel({ short, classes, settings }) {
  const dotStyles = useMemo(() => {
    return {
      backgroundColor: short.available
        ? settings.colors.success
        : settings.colors.danger
    };
    // eslint-disable-next-line
  }, [short.available]);

  const label = createLabelText(short);

  const iconSrc = Optional(short.imageId).or(void 0);

  return (
    <div className={classes.root}>
      {isDefined(iconSrc) ? (
        <img
          src={toAbsoluteUrl(`/media/shortcuts/${iconSrc}.png`)}
          alt="icon"
          className={classes.icon}
        ></img>
      ) : (
        <div className={classes.dot} style={dotStyles}></div>
      )}
      {label}
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(ShortcutLabel);

function createLabelText(shortcut) {
  if (shortcut.durationType === 'NO_END_TIME') {
    return `${shortcut.name} : ${translate('USER.NO_END_TIME')}`;
  }

  if (shortcut.durationType === 'UNTIL_NEXT_WORKING_DAY') {
    return `${shortcut.name} : ${translate('USER.UNTIL_NEXT_DAY')}`;
  }

  if (shortcut.durationType === 'MINUTES') {
    return `${shortcut.name} : ${shortcut.durationMinutes} ${translate(
      'MINUTES'
    )}`;
  }
}
