import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_CLIENT = 'SET_CLIENT';
const SET_SELECTS_OPTIONS = 'SET_SELECTS_OPTIONS';
const RESET_CLIENT = 'RESET_CLIENT';

export const actionTypes = createReduxActionTypes('EDIT_CLIENT', [
  SET_CLIENT,
  SET_SELECTS_OPTIONS,
  RESET_CLIENT
]);

const DEFAULT_STATE = {
  open: false,
  client: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_CLIENT]: {
      const { client } = action;
      return {
        ...state,
        client: { ...client },
        open: true
      };
    }
    case actionTypes[SET_SELECTS_OPTIONS]: {
      const { selectsOptions } = action;
      return {
        ...state,
        selectsOptions: { ...selectsOptions }
      };
    }
    case actionTypes[RESET_CLIENT]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setClient: client => ({
    type: actionTypes[SET_CLIENT],
    client
  }),
  setSelectsOptions: selectsOptions => ({
    type: actionTypes[SET_SELECTS_OPTIONS],
    selectsOptions
  }),
  resetClient: () => ({ type: actionTypes[RESET_CLIENT] })
};
