import orderBy from 'lodash.orderby';
import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import {
  doesArrayHasLength,
  isArray,
  isDefined,
  isUndefined
} from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';

export default async function getAvailableNumbersOptions({
  connect30_domain,
  clientId
}) {
  let externalNumbers = [],
    mitelNumbers = [];

  const NUMBER_RANGES_QUERY_PARAMS = {
    paginate: false,
    filter: { client_id: { eq: clientId }, number_type: { eq: 'landline' } }
  };

  try {
    const { data } = await getData(
      urlCreator(JSONAPI_URLS.EXTERNAL_NO_RANGES, NUMBER_RANGES_QUERY_PARAMS)
    );
    externalNumbers = data.map(el => el.range_from);
  } catch {}

  const mitelQueryParam = {
    include_usage: true,
    paginate: false
  };

  try {
    const response = isDefined(connect30_domain)
      ? await getData(
          urlCreator(
            `${OUTSIDE_API.CLIENTS}/${connect30_domain}/number_ranges`,
            mitelQueryParam
          )
        )
      : [];
    mitelNumbers = response;
  } catch {}

  return mapNumbers({
    externalNumbers,
    mitelNumbers
  });
}

export function mapNumbers({ externalNumbers, mitelNumbers }) {
  const fixedNumbers = externalNumbers.filter(
    number =>
      number.toString().match(/[45][0-9]+$/) && number.toString().length >= 8
  );

  const localNumbers = mitelNumbers
    .filter(number => number.range_from.toString().length <= 8)
    .map(el => Number(el.range_from));

  return {
    publicNumbers: orderBy(reduceNumbers(fixedNumbers, mitelNumbers), 'value'),
    privateNumbers: orderBy(reduceNumbers(localNumbers, mitelNumbers), 'value')
  };
}

function reduceNumbers(numbers, mitelNumbers) {
  if (!isArray(numbers)) {
    return [];
  }

  const reducedNUmbers = numbers.reduce((arr, number) => {
    const foundedRange = mitelNumbers.find(
      mitel => Number(mitel.range_from.replace('+', '')) === number
    );

    if (isUndefined(foundedRange)) {
      return arr;
    }

    const unassignedNumbers = foundedRange.unassigned_numbers;

    if (!isArray(unassignedNumbers)) {
      return arr;
    }

    return [...arr, ...mapNumbersForSelect(unassignedNumbers)];
  }, []);

  return doesArrayHasLength(reducedNUmbers)
    ? [{ value: '', label: '' }, ...reducedNUmbers]
    : [];
}

export function mapNumbersForSelect(numbers = []) {
  return numbers.map(number => {
    const mappedNumber = number.replace('+', '');

    return {
      value: mappedNumber,
      label: mappedNumber
    };
  });
}
