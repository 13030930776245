import { call } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, POST_SMS } from './types';
import { postData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { isStringDefined } from '../../../utils/isDefined';

export function sendSms({ message, receivers, resetFields, selectedGroups }) {
  return {
    type: sagaActions[POST_SMS],
    message,
    receivers,
    selectedGroups,
    resetFields
  };
}

export function* postSms({ message, receivers, resetFields, selectedGroups }) {
  try {
    const mappedReceivers = yield call(mapReceivers, {
      receivers,
      selectedGroups
    });

    const response = yield call(postData, {
      url: OUTSIDE_API.SMS,
      data: { to: mappedReceivers, text: message },
      errorsToPass: [RESPONSE_CODES.FORBIDDEN]
    });

    if (response.status === RESPONSE_CODES.CREATED) {
      yield call(showSnackbar, {
        intlTextKey: 'MESSAGE.SMS_SEND',
        messageType: 'success'
      });
    }

    yield call(resetFields, {});
  } catch {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.PERMISSION_DENIED',
      messageType: 'error'
    });
  }
}

function* watchPostSms() {
  yield takeLatest(sagaActions[POST_SMS], postSms);
}

export default watchPostSms();

function mapReceivers({ receivers, selectedGroups }) {
  const numbers = receivers
    .split(',')
    .filter(number => isStringDefined(number));
  const groups = selectedGroups.map(group => group.value);

  return [...numbers, ...groups].join(',');
}
