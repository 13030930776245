import { doesArrayHasLength, isArray } from '../../../utils/isDefined';

export const mapDataForCreate = ({ customJob }) => {
  const { id, label, value, roles = [], ...restJob } = customJob;

  const mappedRoles = mapRoles(roles);

  let data = { type: 'custom_jobs', attributes: { ...restJob } };

  if (doesArrayHasLength(roles)) {
    data = {
      ...data,
      relationships: {
        roles: { data: [...mappedRoles] }
      }
    };
  }

  return data;
};

export const mapDataForEdit = ({ customJob, initialValues }) => {
  const { id, label, value, roles = [], ...restJob } = customJob;

  const mappedRoles = mapRoles(roles);
  let data = {
    type: 'custom_jobs',
    attributes: { ...restJob }
  };

  const initialRoles = isArray(initialValues?.roles) ? initialValues.roles : [];

  const rolesToDelete = initialRoles.reduce((arr, initialRole) => {
    if (mappedRoles.find(role => role.id === initialRole.id)) {
      return [...arr];
    }

    return [
      ...arr,
      { type: 'roles', id: initialRole.id, method: 'disassociate' }
    ];
  }, []);

  data = {
    ...data,
    id,
    relationships: {
      roles: { data: [...mappedRoles, ...rolesToDelete] }
    }
  };

  return data;
};

const mapRoles = roles => {
  return roles.map(role => {
    return {
      type: 'roles',
      id: role.id
    };
  });
};

export const mapResponse = (response, customJob) => {
  const { data, included } = response;
  const { id, attributes = {} } = data;
  const { form_schema, ui_schema } = customJob;

  const roles = included.reduce((arr, el) => {
    if (el.type !== 'roles') {
      return arr;
    }

    return [...arr, { ...el.attributes, id: el.id }];
  }, []);

  return {
    ...attributes,
    form_schema,
    ui_schema,
    id,
    roles,
    value: id,
    label: attributes.name
  };
};
