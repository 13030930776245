import React from 'react';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import currencyFormatter from '../../../utils/currencyFormatter';
import customTableColumnSort from '../../../utils/customTableColumnSort';
import { isUndefined } from '../../../utils/isDefined';
import DotsMenu from './DotsMenu';
import Deliveries from './Deliveries';

export default function invoicesTableColumns({ clientId, selectedAccount }) {
  const columns = [
    {
      title: translate('INVOICES.INVOICE_NO'),
      field: 'invoiceNumber'
    },
    {
      title: translate('ACCOUNT'),
      showForAllOptionOnly: true,
      field: 'account',
      customSort: (a, b) => customTableColumnSort(a, b, 'account')
    },
    {
      title: translate('INVOICES.INVOICE_DATE'),
      field: 'invoiceDate'
    },
    {
      title: translate('INVOICES.DUE_DATE'),
      field: 'dueDate'
    },

    {
      title: translate('INVOICES.TOTAL_CHARGE'),
      field: 'totalCharge',
      render: rowData => currencyFormatter(rowData.totalCharge),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: translate('INVOICES.DELIVERY'),
      field: 'deliveries',
      render: rowData => <Deliveries deliveries={rowData.deliveries} />
    },
    {
      render: rowData =>
        can(
          'details',
          createSubject('Quote::Invoice', {
            client_id: Number(clientId)
          })
        ) && (
          <DotsMenu data={rowData}>
            <i className="fas fa-ellipsis-v" style={{ cursor: 'pointer' }}></i>
          </DotsMenu>
        ),
      disableClick: true,
      sorting: false,
      width: '40px'
    }
  ];

  let mappedColumns = [...columns];

  if (selectedAccount.value !== 'all') {
    mappedColumns = columns.filter(column =>
      isUndefined(column.showForAllOptionOnly)
    );
  }

  return mappedColumns;
}
