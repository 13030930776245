import { call, put } from 'redux-saga/effects';

import { JSONAPI_URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { takeLatest } from '../../../utils/reduxSaga';
import urlCreator from '../../../utils/urlCreator';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, SET_ROLE } from './types';

const QUERY_PARAMS = {
  paginate: false
};

export function setRole({ role, isEdit }) {
  return {
    type: sagaActions[SET_ROLE],
    role,
    isEdit
  };
}

export function* getRole({ role, isEdit = true }) {
  let allPermissions = [];
  try {
    const { data: permissions } = yield call(
      getData,
      urlCreator(JSONAPI_URLS.PERMISSIONS, QUERY_PARAMS)
    );
    allPermissions = permissions;
  } catch {}

  yield put(reduxActions.openEditRole({ role, allPermissions, isEdit }));
}

function* watchGetRole() {
  yield takeLatest(sagaActions[SET_ROLE], getRole);
}

export default watchGetRole();
