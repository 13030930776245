import { produce } from 'immer';
import compareIds from './compareIds';
import Optional from './optional';

export default function changeObjectValuesInArray(baseArray = [], object, by) {
  const compareBy = Optional(by).or('id');

  const index = baseArray.findIndex(element =>
    compareIds(element[compareBy], object[compareBy])
  );

  if (index === -1) {
    return [...baseArray, { ...object }];
  }

  const newObject = {
    ...object,
    tableData: { ...baseArray[index].tableData }
  };

  return produce(baseArray, draft => {
    draft[index] = { ...newObject };
  });
}
