import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import checkNumberData from '../../../utils/checkNumberData';
import styles from '../styles/generalData';
import CustomTitle from './CustomTitle';
import { isDefined, isObject } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import clsx from 'clsx';
import moment from 'moment';

function LookupData({
  classes,
  lookupData = {},
  fromRightPanel,
  rightPanelData = {}
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: lookupData,
      rightPanelData,
      fromRightPanel
    });
  }, [lookupData, rightPanelData, fromRightPanel]);

  const mappedData = useMemo(() => {
    return mapData({ data, classes });
  }, [data, classes]);

  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.LOOKUP_DATA" isDataExist={isDataExist}>
        {isDataExist &&
          mappedData.map((el, i) => {
            const { value, label } = el;
            return (
              <div
                className={classes.dataContainer}
                key={i}
                style={{ backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA' }}
              >
                <div className={classes.dataKey}>{label}</div>
                <div>{value}</div>
              </div>
            );
          })}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.lookupData,
    lookupData: checkNumber.lookupData
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(LookupData);

const mapData = ({ data, classes }) => {
  if (!isObject(data)) return [];

  return [
    { value: data.number, label: translate('TABLE.NUMBER') },
    {
      label: 'Range Holder',
      value: (
        <div className={classes.lookupRowValue}>
          <div className={classes.holderContainer}>
            <div>{data.holder_N}</div>
            <div>{data.holder}</div>
          </div>
          <i
            className={clsx('fa fa-map-marker', classes.markerIcon)}
            style={{ color: data.owned_by_us ? 'green' : 'red' }}
          ></i>
        </div>
      )
    },
    {
      label: 'Network Operator',
      value: (
        <div className={classes.lookupRowValue}>
          <div className={classes.holderContainer}>
            <div>{data.netoper_N}</div>
            <div>{data.netoper}</div>
          </div>
        </div>
      )
    },
    {
      label: 'Service Operator',
      value: (
        <div className={classes.lookupRowValue}>
          <div className={classes.holderContainer}>
            <div>{data.servoper_N}</div>
            <div>{data.servoper}</div>
          </div>
          <i
            className={clsx('fa fa-map-marker', classes.markerIcon)}
            style={{ color: data.our ? 'green' : 'red' }}
          ></i>
        </div>
      )
    },
    { value: data.type, label: 'Type' },
    { value: formatDate(data.opendate), label: 'Start date' },
    {
      value: data.inported ? translate('YES') : translate('NO'),
      label: translate('NUMBER.IMPORTED')
    }
  ];
};

const formatDate = date => {
  return isDefined(date) && moment(date).isValid()
    ? moment(date).format('DD MMM YYYY h:mm:ss')
    : '';
};
