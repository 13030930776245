const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 20px)',
        justifyContent: 'space-between',
        paddingRight: '5px'
      }
    },
    valueContainer: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, 0.26)',
      borderRadius: '4px',
      maxHeight: '38px',
      width: '250px',
      padding: '10px',
      justifyContent: 'space-between'
    },
    transferContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '160px'
    },
    mobile: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    mobileFlexColumn: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    label: {
      width: '130px',
      paddingLeft: '10px',
      fontSize: '12px'
    },
    link: { '&:hover': { filter: 'brightness(1.5)' } },
    icon: { margin: '0 5px 0 20px' }
  };
};

export default styles;
