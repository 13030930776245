import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { actions as reduxActions } from './reducers';
import getIdFromUrl from '../../utils/getIdfromUrl';
import NumbersRangesTable from './components/NumbersRangesTable';
import NumbersRangesTableBE from './components/NumberRangeTableBE';
import EditRange from '../../modals/editRange/EditRange';
import NumberRangeMultiedit from '../../modals/numberRangeMultiedit/NumberRangeMultiedit';
import { isDefined } from '../../utils/isDefined';
import getCustomJobs from '../../utils/getCustomJobs';

function ExternalNumber({ location, resetExternalNoRanges }) {
  const client = useMemo(() => getIdFromUrl(location), [location]);

  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();

    return resetExternalNoRanges;

    // eslint-disable-next-line
  }, [client]);

  return (
    <>
      {isDefined(client) ? (
        <NumbersRangesTable client={client} />
      ) : (
        <NumbersRangesTableBE client={client} />
      )}
      <EditRange />
      <NumberRangeMultiedit />
    </>
  );
}

const mapDispatchToProps = {
  resetExternalNoRanges: reduxActions.resetExternalNoRanges
};

export default flowRight(
  withRouter,
  connect(null, mapDispatchToProps)
)(ExternalNumber);
