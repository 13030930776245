import React, { useCallback, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';

import styles, { customSelectStyles } from '../styles';
import { createContactTypes } from '../../../consts/selectsOptions';
import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';

export function PhoneNumber({
  intl,
  classes,
  data,
  remove,
  replace,
  setFieldValue,
  values,
  index,
  errors: { phoneNumbers = [] }
}) {
  const selectOptions = useMemo(() => {
    return createContactTypes();
  }, []);

  const handleSelectChange = useCallback(
    selected => {
      if (selected.value !== data.type) {
        replace({ ...data, type: selected.value });
      }
    },
    // eslint-disable-next-line
    [data]
  );

  const handleNumberChange = useCallback(
    event => {
      replace({ ...data, phoneNumber: event.target.value });
    },
    // eslint-disable-next-line
    [data]
  );

  const handlePrefferedChange = useCallback(
    () => {
      const mappedValues = mapValues(values, index);
      setFieldValue('phoneNumbers', mappedValues);
    },
    // eslint-disable-next-line
    [data, values]
  );

  const defaultValue = useMemo(() => {
    return selectOptions.find(option => option.value === data.type);
  }, [data.type, selectOptions]);

  return (
    <div className={classes.numberRoot}>
      {data.preferredNumber ? (
        <div className={classes.prefferedDot}>
          {intl.formatMessage({
            id: 'ADD_EXTERNAL_CONTACT.PREFFERED'
          })}
          <i className={clsx('far fa-dot-circle', classes.numberIcon)}></i>
        </div>
      ) : (
        <div className={clsx(classes.prefferedDot, classes.emptyDot)}>
          <i
            className={clsx('far fa-circle', classes.numberIcon)}
            onClick={handlePrefferedChange}
          ></i>
        </div>
      )}
      <Select
        customStyles={customSelectStyles}
        styles={reactSelectDefaultStyles}
        value={defaultValue}
        onChange={handleSelectChange}
        options={selectOptions}
        menuPlacement="top"
      />
      <div>
        <TextField
          value={data.phoneNumber}
          onChange={handleNumberChange}
          className={classes.textfield}
          error={isDefined(phoneNumbers[index])}
          placeholder={intl.formatMessage({
            id: 'ENTER_NUMBER'
          })}
          inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
        />
        <div className={classes.error}>
          {Optional(phoneNumbers[index])
            .map(numbers => numbers.phoneNumber)
            .or('')}
        </div>
      </div>
      <i
        className={clsx(
          'far fa-times-circle',
          classes.numberIcon,
          classes.deleteIcon,
          { [classes.hiddenIcon]: data.preferredNumber }
        )}
        onClick={remove}
      />
      <div></div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(PhoneNumber);

function mapValues(values, index) {
  const { phoneNumbers } = values;
  return phoneNumbers.map((number, i) => {
    const preferredNumber = i === index ? true : false;

    return { ...number, preferredNumber };
  });
}
