import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles, { CUSTOM_STYLES } from '../styles/multieditForm';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { actions as reduxActions } from '../reducers';
import { DATALIMIT_MULTIEDIT } from '../../../consts/initialValues';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import { updateOnSubmit } from '../actions/updateDatalimits';
import { withRouter } from 'react-router';
import getIdFromUrl from '../../../utils/getIdfromUrl';
import validateSchema from '../actions/validateSchema';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import withUserCheck from '../../../utils/withUserCheck';

export function MultieditForm({
  classes,
  datalimits,
  closeDialog,
  location,
  updateOnSubmit,
  isClientUser
}) {
  const client = useMemo(() => getIdFromUrl(location), [location]);

  const onSubmit = useCallback(
    values => updateOnSubmit(datalimits, values, client),
    // eslint-disable-next-line
    [datalimits, client]
  );

  const rechargeOptions = [
    {
      value: '',
      label: translate('NO_CHANGES')
    },
    {
      value: 'true',
      label: translate('YES')
    },
    {
      value: 'false',
      label: translate('NO')
    }
  ];

  return (
    <Formik
      initialValues={DATALIMIT_MULTIEDIT}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        dirty,
        setFieldValue
      }) => {
        const fieldProps = { errors, values, handleBlur, handleChange };
        return (
          <Form className={classes.content} onSubmit={handleSubmit}>
            <SelectRow
              name="allow_recharge"
              label="DATA_LIMITS.AUTO_RECHARGE"
              isEditModal={false}
              options={rechargeOptions}
              setFieldValue={setFieldValue}
              customStyles={CUSTOM_STYLES.selectRow}
              infoIcon={true}
              isDisabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'allow_recharge'
                )
              }
              infoIconTooltipComponent={
                <div>
                  {translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_1')}
                  <br />
                  {translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_2')}
                </div>
              }
              {...fieldProps}
            />
            <SelectRow
              name="report_only_to"
              label="DATA_LIMITS.REPORT_TO"
              isEditModal={false}
              options={rechargeOptions}
              setFieldValue={setFieldValue}
              customStyles={CUSTOM_STYLES.selectRow}
              infoIcon={true}
              infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_TO"
              isDisabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'report_only_to'
                )
              }
              {...fieldProps}
            />
            <SelectRow
              name="roaming_disabled"
              label="DATA_LIMITS.ROAMING_DISABLED"
              isEditModal={false}
              options={rechargeOptions}
              setFieldValue={setFieldValue}
              customStyles={CUSTOM_STYLES.selectRow}
              isDisabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'roaming_disabled'
                )
              }
              {...fieldProps}
            />
            <SelectRow
              name="manual_block"
              label="DATA_LIMITS.BLOCK_DATA_USAGE"
              isEditModal={false}
              options={rechargeOptions}
              setFieldValue={setFieldValue}
              customStyles={CUSTOM_STYLES.selectRow}
              infoIcon={true}
              infoIconTooltipIntlKey="DATA_LIMITS.BLOCK_MANUALY"
              isDisabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'manual_block'
                )
              }
              {...fieldProps}
            />
            {!isClientUser && (
              <SelectRow
                name="update_block_limit_mb_now"
                label="DATA_LIMITS.UPDATE_NOW"
                isEditModal={false}
                options={rechargeOptions}
                setFieldValue={setFieldValue}
                customStyles={CUSTOM_STYLES.selectRow}
                infoIcon={true}
                infoIconTooltipIntlKey="DATA_LIMITS.UPDATE_NOW_TOOLTIP"
                isDisabled={
                  !can(
                    'update',
                    createSubject('DataLimit', {
                      client_id: Number(client)
                    }),
                    'update_block_limit_mb_now'
                  )
                }
                {...fieldProps}
              />
            )}
            <InputRow
              labelText={`${translate('DATA_LIMITS.DATA_LIMIT')} GB`}
              name="block_limit_mb"
              customStyles={CUSTOM_STYLES.textFieldRow}
              placeholder={translate('NO_CHANGES')}
              disabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'block_limit_mb'
                )
              }
              {...fieldProps}
            />
            <InputRow
              labelText={`${translate('DATA_LIMITS.FAIR_USAGE_LIMIT')} GB`}
              name="fair_usage_limit"
              customStyles={CUSTOM_STYLES.textFieldRow}
              placeholder={translate('NO_CHANGES')}
              disabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'fair_usage_limit'
                )
              }
              {...fieldProps}
            />
            <InputRow
              label="DATA_LIMITS.REPORT_SMS"
              name="report_to_sms"
              infoIcon={true}
              infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_SMS"
              customStyles={CUSTOM_STYLES.textFieldRow}
              placeholder={translate('NO_CHANGES')}
              disabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'report_to_sms'
                )
              }
              {...fieldProps}
            />
            <InputRow
              label="DATA_LIMITS.REPORT_EMAIL"
              name="report_to_email"
              infoIcon={true}
              infoIconTooltipIntlKey="DATA_LIMITS.TOOLTIP.REPORT_EMAIL"
              customStyles={CUSTOM_STYLES.textFieldRow}
              placeholder={translate('NO_CHANGES')}
              disabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(client)
                  }),
                  'report_to_email'
                )
              }
              {...fieldProps}
            />
            <footer className={classes.footer}>
              <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
                {translate('BUTTON.SAVE')}
              </PrimaryButton>
              <SecondaryButton onClick={closeDialog}>
                {translate('BUTTON.CANCEL')}
              </SecondaryButton>
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}
const mapStatesToProps = ({ datalimitMultiedit }) => {
  return {
    datalimits: datalimitMultiedit.datalimits
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit
};

export default flowRight(
  withRouter,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(MultieditForm);
