import { OUTSIDE_API } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

const editCdrHook = async ({
  values,
  isEdit,
  id,
  connectClientId,
  isSuperAdmin
}) => {
  const { target_url } = values;

  const data = {
    ...values,
    target_url: isSuperAdmin ? target_url : `https://${target_url}`
  };
  const response = isEdit
    ? await updateData({
        url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/cdr_hooks/${id}`,
        data: { ...data }
      })
    : await postData({
        url: `${`${OUTSIDE_API.CLIENTS}/${connectClientId}/cdr_hooks`}`,
        data: {
          ...data
        }
      });

  if (response.status === RESPONSE_CODES.CREATED) {
    showSnackbar({
      intlTextKey: 'MESSAGE.ADD_CDR_HOOK',
      messageType: 'success'
    });

    return true;
  }

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.EDIT_CDR_HOOK',
      messageType: 'success'
    });
    return true;
  }

  return false;
};

export default editCdrHook;
