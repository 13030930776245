export default {
  root: {
    height: '100hv',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '32px'
  },
  text: { marginTop: '100px' }
};
