import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API} from '../../../consts/endpoints';
import { actions as sagaActions, DELETE_SCHEDULE } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { deleteData } from '../../../utils/http';
import { getSchedules } from './getSchedules';
import { translate } from '../../../i18n/I18nProvider';

export function removeSchedule({ number, name, connectClientId }) {
  return {
    type: sagaActions[DELETE_SCHEDULE],
    number,
    name,
    connectClientId
  };
}

export function* deleteSchedule({ number, name, connectClientId }) {
  const response = yield call(deleteData, {
    url: `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules/${number}?schedule=${name}`
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      text : `${translate('MESSAGE.DELETE.SCHEDULE', {
        name
      })}`,
      messageType: 'success'
    });

    yield put(getSchedules(connectClientId));
  }
}

function* watchDeleteSchedule() {
  yield takeLatest(sagaActions[DELETE_SCHEDULE], deleteSchedule);
}

export default watchDeleteSchedule();
