import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import createInitials from '../../../utils/createUserInitials';
import withSettings from '../../../utils/withSettings';
import PresenceDot from '../../../partials/presenceDot/PresenceDot';
import styles from '../styles/contactCard';
import { isDefined } from '../../../utils/isDefined';
import eventListener from '../actions/eventListener';
import UserPhoto from '../../../partials/userPhoto/UserPhoto';
import inCallListener from '../actions/inCallListener';
import StatusIcon from './StatusIcon';
import AdditionalInfo from './AdditionalInfo';
import clsx from 'clsx';
import PresenceRow from '../../../partials/presenceRow/PresenceRow';

function ContactCard({
  contact = {},
  classes,
  intl,
  settings,
  mitelToken,
  baseUrl,
  clientDomain,
  activities
}) {
  const [moreInfo, setMoreInfo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isDefined(mitelToken)) {
      inCallListener({ mitelToken, baseUrl, clientDomain, userId: contact.id });
      return eventListener({
        mitelToken,
        baseUrl,
        clientDomain,
        userId: contact.id
      });
    }
    // eslint-disable-next-line
  }, [mitelToken]);

  const presence = useMemo(() => {
    return contact.presence?.available;
  }, [contact]);

  const initials = useMemo(() => {
    const { firstname, lastname } = contact;
    return createInitials(firstname, lastname);
  }, [contact]);
  const onInfoIconClick = value => {
    setMoreInfo(value);
  };

  return (
    <>
      <div
        className={classes.root}
        key={contact.id}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={classes.row}>
          <div
            className={clsx(classes.chevronIcon, {
              [classes.hidden]: !isHovered
            })}
          >
            {moreInfo ? (
              <Tooltip
                title={intl.formatMessage({
                  id: 'TOOLTIP.HIDE_ADDITIONAL_INFO'
                })}
              >
                <i
                  className={clsx('fas fa-chevron-down')}
                  onClick={() => onInfoIconClick(false)}
                ></i>
              </Tooltip>
            ) : (
              <Tooltip
                title={intl.formatMessage({
                  id: 'TOOLTIP.SHOW_ADDITIONAL_INFO'
                })}
              >
                <i
                  className={clsx('fas fa-chevron-right')}
                  onClick={() => onInfoIconClick(true)}
                ></i>
              </Tooltip>
            )}
          </div>
          <UserPhoto id={contact.id} initials={initials} />
          <PresenceDot
            actualAvailability={presence}
            isContactView={true}
            inCall={contact.incall}
          />
          <div className={classes.userStatus}>
            <div className={classes.name}>
              {`${contact.firstname} ${contact.lastname}`}
              <div className={classes.actions}>
                <StatusIcon contact={contact} />
              </div>
            </div>
            <PresenceRow
              presence={presence}
              contact={contact}
              activities={activities}
            />
            <a
              className={classes.link}
              style={{ color: settings.colors.links }}
              href={`tel:${contact.preferredNumber}`}
            >
              {contact.preferredNumber}
            </a>
            {isDefined(contact.presence?.note) && (
              <div>{contact.presence.note}</div>
            )}
          </div>
        </div>
      </div>
      {moreInfo && (
        <AdditionalInfo contact={contact} setMoreInfo={setMoreInfo} />
      )}
    </>
  );
}

const mapStatesToProps = ({ auth, userContacts }) => {
  return {
    activities: userContacts.activities,
    clientDomain: auth.defaultClient.connect30_domain,
    mitelToken: auth.user.mitel_token,
    baseUrl: auth.user.mitel_profile_link
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(ContactCard);
