import { createTheme } from '@material-ui/core';

const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    centered: {
      textAlign: 'center'
    },
    select: { marginTop: '10px' },
    lowerHeader: { textAlign: 'center', zIndex: 0 },
    tableContainer: {
      position: 'relative'
    },
    tableOwerlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    toolbarTitle: {
      overflow: 'visible',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'fit-content'
      }
    },
    switchCell: { padding: '0 8px' },
    actionCell: {
      display: 'flex',
      padding: '0 5px',
      '& i': {
        margin: '5px 5px',
        cursor: 'pointer'
      }
    }
  };
};

export const themes = {
  table: createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          fontFamily: 'Open Sans',
          fontSize: '13px'
        },
        body: {
          borderRight: 'none'
        },
        head: {
          fontWeight: 700
        }
      }
    }
  })
};

export const customSelectStyles = {
  menu: { zIndex: 9999 },
  option: { fontSize: '11px' },
  control: {
    width: '200px',
    height: '20px',
    fontSize: '13px'
  }
};

export const customStyles = {
  presenceDot: {
    root: { backgroundColor: 'inherit', marginLeft: '-10px' }
  },
  photo: {
    photoRoot: {
      marginLeft: '20px'
    },
    photo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      width: '36px',
      borderRadius: '18px',
      backgroundColor: 'lightGrey'
    },
    initilas: {
      fontSize: '16px'
    }
  }
};

export default styles;
