import React from 'react';
import { translate } from '../../../i18n/I18nProvider';
import DifferencesCell from './DifferencesCell';

export default function tableColumns() {
  return [
    {
      title: translate('TIME'),
      field: 'created_at'
    },
    {
      title: translate('ACTION'),
      field: 'event'
    },
    {
      title: translate('RESPONSIBLE'),
      field: 'whodunnit'
    },
    {
      title: translate('DIFFERENCES'),
      render: rowData => (
        <DifferencesCell data={rowData}  />
      )
    }
  ];
}
