import React from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles/selectsSection';
import Optional from '../../../utils/optional';
import DataBox from '../../../partials/dataBox/DataBox';
import { translate } from '../../../i18n/I18nProvider';
import DataSelect from '../../../partials/dataBox/DataSelect';

export function SelectsSection({
  classes,
  form,
  selectOptions = {},
  customer,
  defaultClientName,
  isIoTCard
}) {
  const { values } = form;
  const { users = [] } = selectOptions;
  const clientValue = Optional(customer).or(defaultClientName);

  return (
    <div className={classes.root}>
      <DataBox
        label={translate('TABLE.NETWORK')}
        value={Optional(values.mobile_network?.brand).or('')}
        width={180}
      />

      <DataBox
        label={translate('CUSTOMER')}
        value={Optional(clientValue).or('')}
        width={180}
      />

      {!isIoTCard && (
        <div className={classes.container}>
          <DataSelect
            name="user_id"
            label={translate('OWNER')}
            options={users}
            {...form}
          />
        </div>
      )}
    </div>
  );
}

const mapStatesToProps = ({ editSimcard, selectClient }) => {
  return {
    isIoTCard: editSimcard.isIoTCard,
    selectOptions: editSimcard.selectOptions,
    customer: editSimcard.simcard?.client?.name,
    defaultClientName: selectClient.selectedClient.name
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(SelectsSection);
