import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_PARTNERS = 'SET_PARTNERS';
const RESET_PARTNERS = 'RESET_PARTNERS';
const SET_COLUMNS = 'SET_COLUMNS';

export const actionTypes = createReduxActionTypes('SIMCARDS', [
  SET_PARTNERS,
  RESET_PARTNERS,
  SET_COLUMNS
]);

const DEFAULT_STATE = {
  partners: [],
  columns: []
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_PARTNERS]: {
      return { ...state, partners: [...action.partners] };
    }
    case actionTypes[SET_COLUMNS]: {
      return { ...state, columns: [...action.columns] };
    }
    case actionTypes[RESET_PARTNERS]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setPartners: partners => ({
    type: actionTypes[SET_PARTNERS],
    partners
  }),
  setColumns: columns => ({
    type: actionTypes[SET_COLUMNS],
    columns
  }),
  resetPartners: () => ({ type: actionTypes[RESET_PARTNERS] })
};
