const styles = theme => {
  return {
    root: {
      color: '#FFF',
      width: 'fit-content'
    }
  };
};

export default styles;
