import { isStringEmpty, isUndefined } from './isDefined';

export default function validateJSON({ string, nullable = false }) {
  if (nullable && (isUndefined(string) || isStringEmpty(string))) {
    return true;
  }

  if (
    !isNaN(string) ||
    string.toString() === 'true' ||
    string.toString() === 'false'
  ) {
    return false;
  }

  try {
    const json = JSON.parse(string);
    if (Object.prototype.toString.call(json).slice(8, -1) !== 'Object') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
}
