import { withStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';

import styles from '../styles/dirFieldsElement';
import DirFieldRow from './DirFieldRow';

function DirFieldsElement({
  classes,
  arrayHelpers: {
    form: {
      values: { fields = [] }
    },
    replace
  }
}) {
  const onValueChange = useCallback(
    (data, i) => {
      replace(i, data);
    },
    [replace]
  );

  return (
    <div className={classes.root}>
      {fields.map((field, i) => {
        return (
          <DirFieldRow
            field={field}
            key={field.id}
            changeValue={data => onValueChange(data, i)}
          />
        );
      })}
    </div>
  );
}

export default withStyles(styles)(DirFieldsElement);
