import { createReduxActionTypes } from '../../../utils/actionFactory';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const SET_USER = 'SET_USER';
const RESET_USER = 'RESET_USER';
const SET_LOADING = 'SET_LOADING';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_LOGIN = 'SET_LOGIN';
const SET_AUTH = 'SET_AUTH';
const SET_ERROR = 'SET_ERROR';
const SET_PHOTO = 'SET_PHOTO';
const SET_CUSTOM_JOBS = 'SET_CUSTOM_JOBS';
const RELOAD_USER = 'RELOAD_USER';

export const actionTypes = createReduxActionTypes('AUTH', [
  SET_USER,
  RESET_USER,
  SET_LOADING,
  SET_LANGUAGE,
  SET_LOGIN,
  SET_AUTH,
  SET_ERROR,
  SET_PHOTO,
  SET_CUSTOM_JOBS,
  RELOAD_USER
]);

const DEFAULT_STATE = {
  user: undefined,
  authToken: undefined,
  loading: false,
  lang: 'en',
  defaultClient: {},
  role: {},
  isLogged: false,
  haveQueues: true,
  loginError: { email: null, error: null },
  customJobs: []
};

export const reducer = persistReducer(
  {
    storage,
    key: 'auth',
    whitelist: [
      'user',
      'authToken',
      'role',
      'defaultClient',
      'lang',
      'isLogged',
      'haveQueues',
      'photo',
      'customJobs'
    ]
  },
  (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case actionTypes[SET_USER]: {
        const {
          user,
          lang,
          defaultClient,
          authToken,
          role,
          haveQueues,
          customJobs
        } = action;

        return {
          ...state,
          user,
          lang,
          defaultClient,
          authToken,
          role,
          haveQueues,
          customJobs
        };
      }
      case actionTypes[SET_LOGIN]: {
        const { isLogged } = action;

        return { ...state, isLogged };
      }
      case actionTypes[SET_AUTH]: {
        const { auth } = action;

        return { ...state, authToken: auth };
      }
      case actionTypes[SET_ERROR]: {
        const { loginError } = action;

        return { ...state, loginError };
      }
      case actionTypes[SET_LOADING]: {
        const { loading } = action;

        return { ...state, loading };
      }
      case actionTypes[SET_LANGUAGE]: {
        const { lang } = action;

        return { ...state, lang };
      }
      case actionTypes[SET_PHOTO]: {
        const { photo } = action;

        return { ...state, photo };
      }
      case actionTypes[SET_CUSTOM_JOBS]: {
        const { customJobs } = action;

        return { ...state, customJobs };
      }
      case actionTypes[RELOAD_USER]: {
        const { user } = action;

        return { ...state, user: { ...state.user, ...user } };
      }
      case actionTypes[RESET_USER]: {
        return DEFAULT_STATE;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setUser: ({
    user,
    lang,
    defaultClient,
    authToken,
    role,
    haveQueues,
    customJobs
  }) => ({
    type: actionTypes[SET_USER],
    user,
    lang,
    defaultClient,
    authToken,
    role,
    haveQueues,
    customJobs
  }),
  setAuth: auth => ({ type: actionTypes[SET_AUTH], auth }),
  setLoginError: loginError => ({ type: actionTypes[SET_ERROR], loginError }),
  setLogin: isLogged => ({ type: actionTypes[SET_LOGIN], isLogged }),
  setLoading: loading => ({ type: actionTypes[SET_LOADING], loading }),
  resetUser: () => ({ type: actionTypes[RESET_USER] }),
  setLanguage: lang => ({ type: actionTypes[SET_LANGUAGE], lang }),
  setPhoto: photo => ({ type: actionTypes[SET_PHOTO], photo }),
  reloadUser: user => ({
    type: actionTypes[RELOAD_USER],
    user
  }),
  setCustomJobs: customJobs => ({
    type: actionTypes[SET_CUSTOM_JOBS],
    customJobs
  })
};

export const getters = {
  getAuth: state => state.auth
};
