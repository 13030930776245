import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_USER = 'SET_USER';
const RESET_USER = 'RESET_USER';

export const actionTypes = createReduxActionTypes('CHANGE_PASS', [
  SET_USER,
  RESET_USER
]);

const DEFAULT_STATE = {
  open: false,
  user: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_USER]: {
      const { user } = action;
      return {
        ...state,
        user: { ...state.user, ...user },
        open: true
      };
    }
    case actionTypes[RESET_USER]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  setUser: user => ({
    type: actionTypes[SET_USER],
    user
  }),
  resetUser: () => ({ type: actionTypes[RESET_USER] })
};
