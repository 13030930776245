import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import CustomJobsRunDialog from './components/CustomJobsRunDialog';
import ActionsButton from '../tableCustomButtons/ActionsButton';

function CustomJobsMenu({ customJobs = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ActionsButton handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {customJobs.map(job => {
          return (
            <div key={job.id}>
              <CustomJobsRunDialog
                customJobId={job.id}
                onOpenDialog={handleClose}
              >
                <MenuItem>{job.name}</MenuItem>
              </CustomJobsRunDialog>
            </div>
          );
        })}
      </Menu>
    </div>
  );
}

export default flowRight(injectIntl)(CustomJobsMenu);
