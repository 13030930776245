export default {
  label: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '600'
  },
  permissionsContainer: {
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    width: '100%',
    height: '110px',
    overflowX: 'auto',
    marginBottom: '25px'
  },
  permissionContainer: {
    margin: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ' & i': {
      color: '#B3B3B3'
    }
  },
  groupsRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '15px'
  },
  groupsContainer: {
    minWidth: '350px',
    height: '200px',
    margin: '0 10px 0 0 '
  },
  icon: { cursor: 'pointer' },
  groupColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: { width: '100%' },
  searchIcon: {
    margin: '0 10px'
  }
};
