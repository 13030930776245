import { updateData } from '../../../utils/http';
import { OUTSIDE_API, URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';

export default async function update({
  text,
  requestId,
  field,
  updateSuccessIntlKey
}) {
  const updateOptions = getUpdateOptions({ field, requestId, text });
  try {
    const response = await updateData({ ...updateOptions });

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: updateSuccessIntlKey,
        messageType: 'success'
      });

      return response.data;
    }
  } catch {}
}

function getUpdateOptions({ field, requestId, text }) {
  const options = {
    note: {
      url: `${OUTSIDE_API.USERS}/${requestId}/${OUTSIDE_API.NOTES}`,
      data: { [field]: text }
    },
    description: {
      url: `${URLS.SIM_CARDS}/${requestId}`,
      data: {
        data: {
          type: 'sim_cards',
          id: requestId,
          attributes: {
            [field]: text
          }
        }
      }
    }
  };

  return options[field];
}
