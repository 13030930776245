const styles = theme => {
  return {
    root: { width: '500px', backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    name: {
      padding: '10px',
      fontWeight: '500',
      fontSize: '14px'
    },
    description: {
      marginBottom: '10px',
      '& a': {
        '&:hover': { filter: 'brightness(1.5)' }
      }
    },
    formContainer: {
      width: '100%',
      diaplay: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;
