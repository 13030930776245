import { flowRight } from 'lodash';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import styles from '../styles/elements';
import DateRow from './DateRow';

function DatesElement({ form, classes, systemDateSelected }) {
  const { errors, values, handleBlur, setFieldValue, setFieldError } = form;
  const fieldProps = {
    errors,
    values,
    handleBlur,
    setFieldValue,
    setFieldError
  };
  return (
    <div className={classes.datesRoot}>
      <DateRow name="start" isDisabled={systemDateSelected} {...fieldProps} />
      <DateRow name="end" isDisabled={systemDateSelected} {...fieldProps} />
    </div>
  );
}

export default flowRight(withStyles(styles))(DatesElement);
