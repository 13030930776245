export default {
  root: { display: 'flex', flexDirection: 'column' },
  rowRoot: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 10px 0 0'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: '#16374F',
    fontWeight: 500,
    width: '120px',
    marginRight: '20px'
  },
  fieldValue: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'normal',
    borderRadius: '4px',
    width: '200px',
    margin: 0,
    '& p': {
      margin: '2px 0'
    }
  }
};
