import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Tooltip
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import flowRight from 'lodash.flowright';
import {
  SortableHandle,
  sortableContainer,
  sortableElement
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { reactSelectDefaultStyles } from '../../../consts/reactSelectDefaultStyles';
import styles, { customSelectStyles } from '../styles';
import { createNumbersOptions } from '../../../utils/selectOptionsCreator';
import { translate } from '../../../i18n/I18nProvider';
import { actions as reduxActions } from '../reducers';

const AgentsTable = ({
  distributionType,
  classes,
  agents,
  changeAgentLevel,
  updateAgents
}) => {
  const selectOptions = createNumbersOptions({
    key: 'skill',
    number: 6,
    startFrom: 1
  });

  const onSkillLevelChange = async (e, id) => {
    changeAgentLevel({ agent: id, level: e.value });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      updateAgents(arrayMoveImmutable(agents, oldIndex, newIndex));
    }
  };

  return (
    <Table className={classes.agentsRoot} size='small'>
      <TableHead>
        <TableRow>
          {distributionType !== 'SKILLS' && (
            <TableCell className={classes.tableDragColumn}></TableCell>
          )}
          <TableCell>User ID</TableCell>
          <TableCell>Name</TableCell>
          {distributionType === 'SKILLS' && <TableCell>Level</TableCell>}
        </TableRow>
      </TableHead>
      {distributionType === 'SKILLS' ? (
        <TableBody>
          {agents.map(agent => {
            return (
              <TableRow key={agent.id}>
                <TableCell>{agent.id}</TableCell>
                <TableCell>{`${agent.firstName} ${agent.lastName}`}</TableCell>
                <TableCell className={classes.skillLevelContainer}>
                  <Select
                    styles={reactSelectDefaultStyles}
                    customStyles={customSelectStyles}
                    options={selectOptions.skill}
                    value={{
                      value: agent.skillLevel,
                      label: agent.skillLevel
                    }}
                    onChange={e => onSkillLevelChange(e, agent.id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <SortableContainer
          onSortEnd={onSortEnd}
          useDragHandle
          lockAxis="y"
          lockToContainerEdges={true}
        >
          {agents.map((agent, index) => (
            <SortableItem
              agent={agent}
              key={`item-${agent.id}`}
              classes={classes}
              index={index}
            />
          ))}
        </SortableContainer>
      )}
    </Table>
  );
};

const mapStatesToProps = ({ editQueue }) => {
  return {
    ...editQueue
  };
};

const mapDispatchToProps = {
  changeAgentLevel: reduxActions.updateAgentLevel,
  updateAgents:reduxActions.updateAgents
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(AgentsTable);

const SortableContainer = sortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

const DragHandle = SortableHandle(({ tooltip = ' ' }) => {
  return (
    <Tooltip title={tooltip}>
      <i className="fal fa-arrows" style={{ cursor: 'pointer' }}></i>
    </Tooltip>
  );
});

const SortableItem = sortableElement(({ agent, index, classes }) => {
  return (
    <TableRow>
      <TableCell className={classes.tableDragColumn}>
        <DragHandle tooltip={translate('SCHEDULES.TOOLTIP_DRAG_HANDLE')} />
      </TableCell>
      <TableCell>{agent.id}</TableCell>
      <TableCell>{`${agent.firstName} ${agent.lastName}`}</TableCell>
    </TableRow>
  );
});
