import { OUTSIDE_API } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import Optional from '../../../utils/optional';

export default async function getGroupUsers({
  name,
  connectClientId,
  setIsLoading
}) {
  let users = [];
  setIsLoading(true);
  try {
    const group = await getData(
      `${OUTSIDE_API.CLIENTS}/${connectClientId}/user_groups/${name}`
    );
    users = Optional(group.user).or([]);
  } catch {
  } finally {
    setIsLoading(false);
    return users;
  }
}
