import { Box, withStyles } from '@material-ui/core';
import React from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/simcardData';
import DataRow from '../../Datarow/DataRow';
import { isObject } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

const SimcardData = ({ classes, data }) => {
  return (
    <Box>
      <div className={classes.root}>
        <div className={classes.root}>
          <div className={classes.label}>Simcard</div>
          {isObject(data?.simcard) && Object.keys(data?.simcard).length > 0 ? (
            <div className={classes.generalDataContainer}>
              <div className={classes.dataColumn}>
                <DataRow
                  label="Status"
                  value={data?.simcard?.active ? 'ACTIVE' : 'BLOCKED'}
                />
              </div>
              <div className={classes.dataColumn}>
                <DataRow
                  label="eSim"
                  value={Optional(data?.simcard?.isESimCard)
                    .map(value => JSON.stringify(value))
                    .or('')}
                />
                <DataRow
                  label="ICC"
                  value={Optional(data?.simcard?.icc).or('')}
                />
                <DataRow
                  label="PIN1"
                  value={Optional(data?.simcard?.pin1).or('')}
                />
                <DataRow
                  label="PUK1"
                  value={Optional(data?.simcard?.puk1).or('')}
                />
              </div>
            </div>
          ) : (
            <div className={classes.noDataInfo}>No simcard data found</div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default flowRight(withStyles(styles))(SimcardData);
