import React, { useState, cloneElement } from 'react';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogContent } from '@material-ui/core';

import styles from '../styles/eSimInfo';
import DraggableDialog from '../../../partials/DraggableDialog';
import { translate } from '../../../i18n/I18nProvider';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';

const ESimInfo = ({ classes, children }) => {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpen(false);
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>eSIM</CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>
            <div>{translate('E_SIM_INFO_1')}</div>
            <div>{translate('E_SIM_INFO_2')}</div>
          </div>
          <div className={classes.container}>
            <div>{translate('E_SIM_INFO_4')}</div>
            <div>{translate('E_SIM_INFO_5')}</div>
          </div>
          <div className={classes.container}>{translate('E_SIM_INFO_6')}</div>
          <div className={classes.container}>{translate('E_SIM_INFO_7')}</div>
          <div className={classes.container}>{translate('E_SIM_INFO_8')}</div>
          {[
            translate('E_SIM_INFO_SUPPORTED_LIST_1'),
            translate('E_SIM_INFO_SUPPORTED_LIST_2'),
            translate('E_SIM_INFO_SUPPORTED_LIST_3'),
            translate('E_SIM_INFO_SUPPORTED_LIST_4')
          ].map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ESimInfo);
