import { FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';
import withSettings from '../utils/withSettings';

function CustomFormControlLabel({ settings, value, label, disabled = false }) {
  return (
    <FormControlLabel
      disabled={disabled}
      style={{ color: settings.colors.primary }}
      value={value}
      control={<Radio size="small" color="default" />}
      label={label}
    />
  );
}

export default withSettings(CustomFormControlLabel);
