import XMLParser from 'react-xml-parser';
import Optional from '../../../utils/optional';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';

export default function eventListener(presenceToken) {
  const sse = new EventSource(`${presenceToken.url}?t=${presenceToken.token}`);

  sse.onmessage = e =>
    store.dispatch(reduxActions.setUpdatedUserData(getRealtimeData(e.data)));
  sse.onerror = error => {
    console.log(error);
    sse.close();
  };
  return () => {
    sse.close();
  };
}

function getRealtimeData(data) {
  const xml = new XMLParser().parseFromString(data);

  const updatedActivityAttributes = Optional(
    xml.getElementsByTagName('rpid:activities')[0]
  )
    .map(activities => activities.attributes)
    .or(void 0);

  const updatedActivityId = Optional(
    xml.getElementsByTagName('telepo:activity')[0]
  )
    .map(data => data.value)
    .or(void 0);

  const updatedNote = Optional(xml.getElementsByTagName('dm:note')[0])
    .map(data => data.value)
    .or(void 0);

  const updatedRole = Optional(xml.getElementsByTagName('telepo:role')[0])
    .map(data => data.value)
    .or(void 0);

  return {
    updatedActivityId,
    updatedActivityAttributes,
    updatedNote,
    updatedRole
  };
}
