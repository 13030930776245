export default {
  root: {
    paddingTop: '10px',
    fontSize: '12px',
    width: '860px',
    height: '440px'
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '0 0 20px 20px'
  },
  container: {
    display: 'flex',
    marginTop: '20px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #EBEDF2',
    marginTop: '10px',
    padding: '20px 0',
    '& button': {
      marginRight: '10px'
    }
  }
};
