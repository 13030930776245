import * as yup from 'yup';

export default function(intl) {
  return yup.object().shape({
    username: yup.string(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.INVALID_FIELD'
      })
    )
  });
}
