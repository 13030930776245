import React from 'react';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { translate } from '../../../i18n/I18nProvider';

const ActionsButtons = ({
  onCloseClick,
  handleSubmit,
  dirty,
  isEdit
}) => {
  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {translate(isEdit ? 'BUTTON.SAVE' : 'BUTTON.ADD')}
      </PrimaryButton>
      <SecondaryButton onClick={onCloseClick}>
        {translate('BUTTON.CANCEL')}
      </SecondaryButton>
    </>
  );
};

const mapStatesToProps = ({ editVacation }) => {
  return {
    isEdit: editVacation.isEdit
  };
};

export default connect(mapStatesToProps)(ActionsButtons);
