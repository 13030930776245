import React from 'react';

import { translate } from '../../../i18n/I18nProvider';
import formatDate from '../../../utils/formatDate';
import TableActionButtons from './TableActionButtons';
import CreatedBy from './CreatedBy';
import Optional from '../../../utils/optional';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import createTableFilterSelectOptions from '../../../utils/createTableFilterSelectOptions';
import StatusCell from '../../../partials/statusCell/StatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';

const tableColumns = client => {
  const columns = [
    {
      render: rowData => <TableActionButtons data={rowData} />,
      disableClick: true,
      sorting: false,
      width: '50px'
    },
    {
      title: `${translate('BUTTON.ORDER')} ID`,
      untranslatedTitle: true,
      field: 'order_id'
    },
    {
      title: 'CLIENT',
      render: rowData => Optional(rowData.client?.name).or(''),
      field: 'client.name',
      hideForUserView: true
    },
    {
      title: 'TABLE.TYPE',
      field: 'order_type'
    },
    {
      title: 'Service',
      untranslatedTitle: true,
      field: 'service'
    },
    {
      title: 'STATUS',
      render: rowData => (
        <StatusCell
          {...pickOptionsForStatusCell(
            STATUS_BOX_OPTIONS.ORDERS,
            rowData.status
          )}
        />
      ),
      field: 'status',
      lookup: {
        ...createTableFilterSelectOptions(STATUS_BOX_OPTIONS.ORDERS)
      }
    },
    {
      title: 'CREATED_BY',
      render: rowData => <CreatedBy data={rowData.created_by} />,
      field: 'created_by.name'
    },
    {
      title: 'CREATED_AT',
      render: rowData => formatDate(rowData.created_at),
      field: 'created_at'
    },
    {
      title: 'UPDATED_AT',
      render: rowData => formatDate(rowData.updated_at),
      field: 'updated_at'
    }
  ];

  let mappedColumns = [...columns];

  if (isDefined(client)) {
    mappedColumns = columns.filter(column =>
      isUndefined(column.hideForUserView)
    );
  }

  return mappedColumns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;
