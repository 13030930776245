import React, { useMemo, Fragment, useState, useCallback } from 'react';
import { Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Optional from '../../../utils/optional';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import formatMoney from '../../../utils/currencyFormatter';
import ExpandBtn from '../../../partials/customButtons/ExpandButton';
import formatUnits from '../actions/formatUnits';
import { idx } from '../../../utils/tableUtils';
import Description from './Description';
import ChargeLines from './ChargeLines';
import styles from '../styles';
import Cell from './Cell';
import { flowRight } from 'lodash';
import withSettings from '../../../utils/withSettings';

const INDENT_WIDTH = 40;

const LEVEL_BG_COLORS = {
  0: '#bfd1dc',
  1: '#dae4ea',
  2: '#eaf0f3',
  3: '#f6f9fa',
  4: '#fafafa'
};

function Group({
  intl,
  group,
  level = 0,
  isExpanded,
  parentGroupNames = [],
  invoiceDetails,
  subscriptions,
  classes,
  settings,
  invoiceGrouping
}) {
  const [chargeLinesDetails, setChargeLineDetails] = useState({});
  const [expandedGroups, setExpandedGrpups] = useState(() =>
    group.map(g => level === 0)
  );

  const [expandedChargeLines, setExpandedChargeLines] = useState(() => {
    const result = {};
    group.forEach(g => {
      result[g.description] = false;
    });
    return result;
  });

  const setExpandedForChargeLine = useCallback(
    groupDescription => {
      const copy = {
        ...expandedChargeLines
      };
      copy[groupDescription] = !copy[groupDescription];
      setExpandedChargeLines(copy);
    },
    [expandedChargeLines]
  );

  const setExpandedForGroup = useCallback(
    (idx, isExpanded) => {
      const copy = [...expandedGroups];
      copy[idx] = isExpanded;
      setExpandedGrpups(copy);
    },
    [expandedGroups]
  );

  const rowStyle = useMemo(
    () => ({
      backgroundColor: LEVEL_BG_COLORS[level]
    }),
    [level]
  );

  const chargeLineRowStyle = useMemo(
    () => ({
      backgroundColor: LEVEL_BG_COLORS[level + 1]
    }),
    [level]
  );

  const expandAndFetchLineDetails = useCallback(
    async levelNames => {
      try {
        const groupDescription = idx(
          levelNames.filter(v => v),
          -1
        );
        if (isUndefined(chargeLinesDetails[groupDescription])) {
          setChargeLineDetails({
            ...chargeLinesDetails,
            [groupDescription]: {
              ...invoiceDetails,
              level1: levelNames[0],
              level2: levelNames[1],
              level3: levelNames[2],
              level4: levelNames[3]
            }
          });
        }

        setExpandedForChargeLine(groupDescription);
      } catch (e) {
        console.error(e);
      }
    },
    [invoiceDetails, chargeLinesDetails, setExpandedForChargeLine]
  );

  const indentLevelArr = useMemo(() => new Array(level + 1).fill(''), [level]);
  return (
    <div
      className={classes.tableBody}
      style={{ color: settings.colors.primary }}
    >
      {group.map((groupLine, groupRowIdx) => {
        const levelNames = getGroupLevelNames(
          parentGroupNames,
          level,
          groupLine.description
        );

        return (
          <Fragment key={groupRowIdx}>
            <div className={classes.row} style={rowStyle}>
              <div className={classes.rowContent}>
                {indentLevelArr.map((_, indentLevel) => (
                  <Cell key={indentLevel} width={INDENT_WIDTH}>
                    {shouldRenderExpandBtnForGroup(
                      level,
                      groupLine.line.length,
                      indentLevelArr,
                      indentLevel
                    ) ? (
                      <ExpandBtn
                        expanded={expandedGroups[groupRowIdx]}
                        onClick={() =>
                          setExpandedForGroup(
                            groupRowIdx,
                            !expandedGroups[groupRowIdx]
                          )
                        }
                      />
                    ) : null}
                    {isLastGroupLine(groupLine) &&
                    indentLevelArr.length - 1 === indentLevel &&
                    isDefined(invoiceDetails) ? (
                      <ExpandBtn
                        expanded={expandedChargeLines[groupLine.description]}
                        onClick={() => expandAndFetchLineDetails(levelNames)}
                      />
                    ) : null}
                  </Cell>
                ))}
                <Cell customClasses={[classes.idDescCell]}>
                  {isExpanded ? (
                    <Description
                      text={groupLine.description}
                      count={groupLine.number_of_lines}
                    />
                  ) : null}
                </Cell>
                <Cell>
                  {isLastGroupLine(groupLine)
                    ? formatUnitsCharged(intl, groupLine.units_charged)
                    : null}
                </Cell>
                <Cell customClasses={[classes.idCell]} width={150}>
                  {formatMoney(groupLine.excl_tax)}
                </Cell>
                <Cell customClasses={[classes.idCell]} width={70}></Cell>
              </div>
            </div>
            <Collapse in={expandedChargeLines[groupLine.description]}>
              {Optional(chargeLinesDetails[groupLine.description])
                .map(details => (
                  <ChargeLines
                    details={details}
                    chargeLineRowStyle={chargeLineRowStyle}
                    indentLvl={indentLevelArr.length}
                    invoiceGrouping={invoiceGrouping}
                  />
                ))
                .or(null)}
            </Collapse>
            <Collapse in={expandedGroups[groupRowIdx]}>
              <Group
                group={groupLine.line}
                intl={intl}
                level={level + 1}
                isExpanded={expandedGroups[groupRowIdx]}
                invoiceDetails={invoiceDetails}
                parentGroupNames={levelNames}
                classes={classes}
                settings={settings}
                invoiceGrouping={invoiceGrouping}
              />
            </Collapse>
          </Fragment>
        );
      })}
    </div>
  );
}

export default flowRight(withSettings, withStyles(styles))(Group);

function isLastGroupLine(group) {
  return group.line.length === 0 && isDefined(group.units_charged);
}

function shouldRenderExpandBtnForGroup(
  level,
  subGroupsCount,
  indentLevelArr,
  indentLevel
) {
  if (level === 0 && subGroupsCount > 0) {
    return true;
  } else if (
    level > 0 &&
    indentLevel === indentLevelArr.length - 1 &&
    subGroupsCount > 0
  ) {
    return true;
  }
  return false;
}

function getGroupLevelNames(parentGroupNames, level, groupName) {
  return [...parentGroupNames, level === 0 ? void 0 : groupName].filter(v => v);
}

function formatUnitsCharged(intl, unitsCharged) {
  const unit = Object.keys(unitsCharged[0])[0];
  const value = Object.values(unitsCharged[0])[0];
  return formatUnits(unit, value);
}
