import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Optional from '../../../utils/optional';
import styles from '../styles/summaryStep';
import { actions as reduxActions } from '../reducers';
import { sendOrderData } from '../actions/newSimcardsOrder';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { isStringDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';

function SummaryStep({
  handleNext,
  handleBack,
  intl,
  classes,
  firstStepData,
  secondStepData,
  resetOrderSimcard,
  isLastStep = false,
  client,
  sendOrderData,
  userName
}) {
  const handleConfirm = useCallback(
    async () => {
      await sendOrderData({
        simcardsData: {
          ...firstStepData,
          ...secondStepData,
          client
        },
        nextStep: () => handleNext()
      });
    },
    // eslint-disable-next-line
    []
  );

  let addressToShow = [...secondStepData.shipping_address.split('\n')];

  if (isStringDefined(secondStepData.att.trim())) {
    addressToShow = [...addressToShow, `Att. ${secondStepData.att}`];
  }

  return (
    <div className={classes.root}>
      {isLastStep && (
        <div className={classes.container}>
          {`${intl.formatMessage({
            id: 'DEAR'
          })} ${userName}`}
        </div>
      )}
      <div className={classes.container}>
        {isLastStep
          ? intl.formatMessage({
              id: 'ORDER_SIMCARD.THANKS'
            })
          : intl.formatMessage({
              id: 'ORDER_SIMCARD.ITEMS_IN_BASKET'
            })}
        <div className={classes.centerContainer}>
          <div className={classes.stepDataContainer}>{`${
            firstStepData.provider
          } : ${
            firstStepData.provider === 'TDC IoT'
              ? `${Number(firstStepData.count) * 10} ${translate('SIMCARDS')}`
              : `${firstStepData.count} ${translate('SIMCARDS')}`
          }`}</div>
        </div>
      </div>
      <div className={clsx(classes.container)}>
        {isLastStep
          ? Number(firstStepData?.count) >= 10
            ? intl.formatMessage({
                id: 'ORDER_SIMCARD.SEND_TO_10'
              })
            : intl.formatMessage({
                id: 'ORDER_SIMCARD.SEND_TO'
              })
          : Number(firstStepData?.count) >= 10 ||
            firstStepData.provider === 'TDC IoT'
          ? intl.formatMessage({
              id: 'ORDER_SIMCARD.SEND_10'
            })
          : intl.formatMessage({
              id: 'ORDER_SIMCARD.SEND'
            })}
        <div className={classes.centerContainer}>
          <div className={clsx(classes.stepDataContainer, classes.address)}>
            <div>{secondStepData.name}</div>
            <div>
              {addressToShow.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isLastStep && (
        <div className={classes.container}>
          {intl.formatMessage({
            id: 'ORDER_SIMCARD.CHARGED_ACCOUNT'
          })}
          <div className={classes.centerContainer}>
            <div className={classes.stepDataContainer}>
              main billing account
            </div>
          </div>
        </div>
      )}
      {isLastStep ? (
        <div className={classes.container}>
          {intl
            .formatMessage({
              id: 'ORDER_SIMCARD.LAST_SENTENCE'
            })
            .split(/\.|,/)
            .map((el, i) => (
              <div key={i} className={classes.lastSentence}>
                {el}
              </div>
            ))}
        </div>
      ) : (
        <div className={classes.container}>
          {intl.formatMessage({
            id: 'ORDER_SIMCARD.COMPLETE_ORDER'
          })}
        </div>
      )}
      {isLastStep ? (
        <div className={classes.footer}>
          <SecondaryButton onClick={resetOrderSimcard}>
            {intl.formatMessage({
              id: 'BUTTON.CLOSE'
            })}
          </SecondaryButton>
        </div>
      ) : (
        <div className={classes.footer}>
          <PrimaryButton onClick={() => handleBack(2)} alternativeColors>
            {intl.formatMessage({
              id: 'BUTTON.CHANGE_ORDER'
            })}
          </PrimaryButton>

          <SecondaryButton onClick={resetOrderSimcard}>
            {intl.formatMessage({
              id: 'BUTTON.CANCEL'
            })}
          </SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>
            {intl.formatMessage({
              id: 'BUTTON.PLACE_ORDER'
            })}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
const mapStatesToProps = ({
  orderNewSimcard: { simcardData },
  selectClient,
  auth
}) => {
  return {
    firstStepData: Optional(simcardData.firstStep).or({}),
    secondStepData: Optional(simcardData.secondStep).or({}),
    client: Optional(selectClient.selectedClient.name).or(
      auth.defaultClient.name
    ),
    userName: auth.user.name
  };
};
const mapDispatchToProps = {
  ...reduxActions,
  sendOrderData
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SummaryStep);
