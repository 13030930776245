import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import MultieditForm from './components/MultieditForm';

export function DatalimitMultiedit({
  classes,
  open,
  intl,
  closeDialog,
  datalimits
}) {
  const title = useMemo(
    () =>
      intl.formatMessage({
        id: 'BUTTON.MULTIEDIT'
      }),
    // eslint-disable-next-line
    []
  );

  const dialogClasses = useMemo(
    () => {
      return {
        paper: classes.root
      };
    }, // eslint-disable-next-line
    []
  );

  return (
    <Dialog
      maxWidth="md"
      classes={dialogClasses}
      open={open}
      PaperComponent={DraggableDialog}
      onClose={closeDialog}
    >
      <CustomDialogTitle onCloseClick={closeDialog}>{title}</CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.datalimitsList}>
          <div>
            {`${datalimits.length} ${intl.formatMessage({
              id: 'DATA_LIMITS.DATALIMITS_SELECTED'
            })}`}{' '}
          </div>
          <Tooltip
            placement="bottom"
            title={datalimits.map(datalimit => datalimit.name).join(', ')}
            className={classes.tooltip}
          >
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        </div>
        <MultieditForm />
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ datalimitMultiedit }) => {
  return {
    ...datalimitMultiedit
  };
};

const mapDispatchToProps = {
  ...reduxActions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DatalimitMultiedit);
