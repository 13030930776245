import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import PhoneNumber from './PhoneNumber';

export function Numbers({
  classes,
  intl,
  arrayHelpers: {
    form: {
      values: { phoneNumbers = [] }
    },
    remove,
    push,
    replace
  },
  form
}) {
  const onAddClick = () => {
    push({ type: 'MAIN', phoneNumber: '', preferredNumber: false });
  };
  return (
    <div className={classes.numbersRoot}>
      <div>
        <PrimaryButton onClick={onAddClick} minWidth={'unset'}>
          {intl.formatMessage({
            id: 'ADD_EXTERNAL_CONTACT.ADD_NUMBER'
          })}
        </PrimaryButton>
      </div>
      <div className={classes.container}>
        <div className={classes.customRolesRoot}>
          {phoneNumbers.map((number, i) => {
            return (
              <PhoneNumber
                key={i}
                index={i}
                data={number}
                remove={() => remove(i)}
                replace={data => replace(i, data)}
                {...form}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(Numbers);
