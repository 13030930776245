import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import PrimaryButton from '../../customButtons/PrimaryButton';
import SecondaryButton from '../../customButtons/SecondaryButton';
import history from '../../../../history';

export function ActionsButtons({
  handleSubmit,
  handleClose,
  dirty,
  intl,
  isNotDialog = false,
  isResetPassword = false
}) {
  const onReturnToLoginClick = () => {
    history.push(`${window.location.origin}/auth/login`);
  };

  return (
    <>
      <PrimaryButton onClick={handleSubmit} disabled={!dirty}>
        {intl.formatMessage({
          id: 'BUTTON.CHANGE_PASSWORD'
        })}
      </PrimaryButton>
      {!isNotDialog && !isResetPassword && (
        <SecondaryButton onClick={handleClose}>
          {intl.formatMessage({
            id: 'BUTTON.CANCEL'
          })}
        </SecondaryButton>
      )}
      {isResetPassword && (
        <PrimaryButton onClick={onReturnToLoginClick}>
          {intl.formatMessage({
            id: 'BUTTON.RETURN_TO_LOGIN'
          })}
        </PrimaryButton>
      )}
    </>
  );
}

export default flowRight(injectIntl)(ActionsButtons);
