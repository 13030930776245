import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';
import { isDefined } from '../../../utils/isDefined';

const NOT_NEED_DATA_ARRAY = ['MONTHLY', 'YEARLY'];

export default function() {
  return yup.object().shape({
    name: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    startDate: yup
      .string()
      .when(['startDateSet', 'recurrenceType'], {
        is: (startDateSet, recurrenceType) =>
          startDateSet ||
          isDefined(NOT_NEED_DATA_ARRAY.find(el => el === recurrenceType)),
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
      .nullable(),
    endDate: yup
      .string()
      .when(['endDateSet', 'recurrenceType'], {
        is: (endDateSet, recurrenceType) =>
          endDateSet ||
          isDefined(NOT_NEED_DATA_ARRAY.find(el => el === recurrenceType)),
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
      .nullable(),
    reccurenceEndDate: yup
      .string()
      .when(['reccurenceEndType', 'recurrenceType'], {
        is: (reccurenceEndType, recurrenceType) =>
          reccurenceEndType === 'DATE' &&
          isDefined(NOT_NEED_DATA_ARRAY.find(el => el === recurrenceType)),
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
      .nullable(),
    forwardNumber: yup
      .string()
      .when('type', {
        is: 'FORWARD',
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
      .nullable()
  });
}
