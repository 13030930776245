const styles = () => {
  return {
    root: {
      marginTop: '10px'
    },
    label: {
      backgroundColor: '#FFF',
      width: '22vw',
      fontWeight: 600,
      padding: '10px'
    },
    errorsContainer: {
      width: '45vw',
      backgroundColor: '#C95454',
      borderBottom: '1px solid #FAFAFA',
      paddingLeft: '10px',
      color: '#FFF'
    }
  };
};

export default styles;
