import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_REQUEST_DIALOG = 'OPEN_REQUEST_DIALOG';
const RESET_REQUEST_DIALOG = 'RESET_REQUEST_DIALOG';

export const actionTypes = createReduxActionTypes('REQUEST_DIALOG', [
  OPEN_REQUEST_DIALOG,
  RESET_REQUEST_DIALOG
]);

const DEFAULT_STATE = false

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_REQUEST_DIALOG]: {
      return true
    }
    case actionTypes[RESET_REQUEST_DIALOG]: {
      return DEFAULT_STATE ;
    }
    default:
      return state;
  }
};

export const actions = {
  openRequestUserDialog: () => ({
    type: actionTypes[OPEN_REQUEST_DIALOG]
  }),
  resetRequestUserDialog: () => ({ type: actionTypes[RESET_REQUEST_DIALOG] })
};
