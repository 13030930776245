import { postData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import showSnackbar from '../../../utils/showSnackbar';
import { actions as simcardReduxActions } from '../../../pages/simCards/reducers';
import store from '../../../store/store';

export default async function lockNumberRequest(
  id,
  isLockRequest,
  isCardActive,
  fromTable
) {
  let response = {};
  try {
    if (isLockRequest) {
      response = await postData({
        url: `${URLS.SIM_CARDS}/${id}/suspend`
      });
    } else {
      response = await postData({
        url: `${URLS.SIM_CARDS}/${id}/reopen`
      });
    }

    if (response.status === RESPONSE_CODES.SUCCESS) {
      showSnackbar({
        intlTextKey: isLockRequest
          ? 'MESSAGE.LOCK_REQUEST_SEND'
          : 'MESSAGE.UNLOCK_REQUEST_SEND',
        messageType: 'success'
      });

      if (fromTable) {
        store.dispatch(simcardReduxActions.setRefresh(true));
      }

      return !isCardActive;
    }
    return void 0;
  } catch {
    return void 0;
  }
}
