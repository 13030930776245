import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import ConnectionsTable from './components/ConnectionsTable';
import SelectAccount from './components/SelectAccount';
import styles from './styles';
import { actions as reduxActions } from './reducers';

const Connections = ({ classes, resetConnections }) => {
  useEffect(() => {
    return resetConnections;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <SelectAccount />
      </div>
      <ConnectionsTable />
    </div>
  );
};

const mapDispatchToProps = {
  resetConnections: reduxActions.resetConnections
};

export default flowRight(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(Connections);
