import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import { actions as reduxActions } from './reducers';
import styles, { customStyles } from './styles';
import { injectIntl } from 'react-intl';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { FieldArray, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import Permissions from './components/Permissions';
import ActionsButtons from './components/ActionsButtons';
import { updateOnSubmit } from './actions/updateRole';
import InputRow from '../../partials/dataRows/InputRow';
import SELECT_OPTIONS from '../../consts/selectsOptions';
import SelectRow from '../../partials/dataRows/SelectRow';
import validateSchema from './actions/validateSchema';
import Optional from '../../utils/optional';

export function EditRole({
  classes,
  open,
  closeEditRole,
  intl,
  allPermissions = [],
  permissions = [],
  role = {},
  updateOnSubmit,
  isEdit
}) {
  const onSubmit = useCallback(
    values => {
      updateOnSubmit({
        values,
        role,
        isEdit
      });
    },
    // eslint-disable-next-line
    [updateOnSubmit, role]
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditRole();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditRole}>
        {isEdit
          ? `${intl.formatMessage({
              id: 'TOOLTIP.EDIT_ROLE'
            })} : ${role.name}`
          : intl.formatMessage({
              id: 'TOOLTIP.ADD_ROLE'
            })}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Formik
          initialValues={{
            name: Optional(role.name).or(''),
            context: Optional(role.context).or(''),
            permissions: [...permissions]
          }}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {({
            handleSubmit,
            dirty,
            errors,
            values,
            handleBlur,
            handleChange,
            setFieldValue
          }) => {
            const fieldProps = { errors, values, handleBlur, handleChange };

            return (
              <Form onSubmit={handleSubmit}>
                <div className={classes.container}>
                  <InputRow
                    name="name"
                    label="NAME"
                    customStyles={customStyles.inputRow}
                    {...fieldProps}
                  />
                  <SelectRow
                    name="context"
                    label="CONTEXT"
                    isEditModal={false}
                    options={SELECT_OPTIONS.CONTEXT_OPTIONS}
                    setFieldValue={setFieldValue}
                    customStyles={customStyles.selectRow}
                    {...fieldProps}
                  />
                </div>
                <FieldArray
                  name="permissions"
                  render={arrayHelpers => (
                    <Permissions
                      options={allPermissions}
                      arrayHelpers={arrayHelpers}
                    />
                  )}
                />
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeEditRole}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                    errors={errors}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ editRole }) => {
  return {
    isEdit: editRole.isEdit,
    open: editRole.open,
    role: editRole.role,
    permissions: editRole.role?.permissions,
    allPermissions: editRole.allPermissions
  };
};

const mapDispatchToProps = {
  closeEditRole: reduxActions.closeEditRole,
  updateOnSubmit
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditRole);
