import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles, { checkboxCustomStyles } from '../styles/informationSection';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import PassStrengthMeter from '../../../partials/passStrengthMeter/PassStrengthMeter';
import withUserCheck from '../../../utils/withUserCheck';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';

export function InformationSection({
  classes,
  form,
  selectsOptions = {},
  intl,
  isAdminUser,
  user = {},
  isDomainProvided
}) {
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {intl.formatMessage({
          id: 'EDIT.INFO'
        })}
      </div>
      <div className={classes.container}>
        <div>
          <InputRow label="TABLE.NAME" name="name" {...form} />
          <InputRow
            label="TABLE.USERNAME"
            name="username"
            disabled={isDefined(user.id) ? true : false}
            infoIcon={true}
            infoIconTooltipIntlKey={'TOOLTIP.USERNAME_INFO'}
            {...form}
          />
          <InputRow name="email" label="TABLE.EMAIL" {...form} />
          {isUndefined(user.id) && (
            <CheckboxRow
              label="USER.SEND_INVITATION_EMAIL"
              name="generate_welcome_email"
              customStyles={checkboxCustomStyles}
              infoIcon={true}
              infoIconTooltipIntlKey="TOOLTIP.SEND_INVITATION_EMAIL"
              {...form}
            />
          )}
          <>
            <InputRow
              name="pass"
              label="EDIT.PASS"
              disabled={form.values.generate_welcome_email}
              {...form}
            />
            <PassStrengthMeter pass={form.values.pass} />
          </>
          <SelectRow
            name="language"
            label="LANG"
            options={selectsOptions.language}
            {...form}
          />
          {isDomainProvided && (
            <SelectRow
              name="connect30_user_id"
              label="DOMAIN_NAME"
              options={selectsOptions.telephony_users}
              isDisabled={!isAdminUser}
              menuPlacement={'top'}
              {...form}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStatesToProps = ({ editUser }) => {
  return {
    user: editUser.user
  };
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(InformationSection);
