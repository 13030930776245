import { URLS } from '../../../consts/endpoints';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { deleteData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';

export default async function deleteClientConfig({ templateName, clientId }) {
  try {
    const response = await deleteData({
      url: `${URLS.CLIENTS}/${clientId}/configs/${templateName}`
    });

    if (response.status === RESPONSE_CODES.NO_CONTENT) {
      showSnackbar({
        intlTextKey: 'MESSAGE.SETTINGS_DELETED',
        messageType: 'success'
      });
    }
    return true;
  } catch {
    return false;
  }
}
