import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_QUEUES = 'GET_QUEUES';
export const GET_QUEUE_AGENTS = 'GET_QUEUE_AGENTS';
export const LOG_USER_TO_DISTGROUP = 'LOG_USER_TO_DISTGROUP';

export const actions = createSagaActionTypes('QUEUES', [
  GET_QUEUES,
  GET_QUEUE_AGENTS,
  LOG_USER_TO_DISTGROUP
]);
