import { Dialog, DialogContent, withStyles } from '@material-ui/core';
import React, { cloneElement, useMemo, useState } from 'react';

import styles from '../styles/specification';
import CustomDialogTitle from '../../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../../partials/DraggableDialog';
import { translate } from '../../../i18n/I18nProvider';
import { isObject } from '../../../utils/isDefined';

const Specification = ({ children, classes, specification }) => {
  const [open, setOpen] = useState(false);

  const onOpenDialog = () => {
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const dataToShow = useMemo(() => {
    if (!isObject) {
      return '';
    }
    return Object.keys(specification).map((el, i) => {
      return (
        <div key={i} className={classes.rowContainer}>
          <div>{el}</div>
          <div className={classes.value}>{specification[el]}</div>
        </div>
      );
    });
    // eslint-disable-next-line
  }, [specification]);

  return (
    <>
      {cloneElement(children, { onClick: onOpenDialog })}
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onCloseDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={onCloseDialog}>
          {translate('ORDERS.SPECIFICATION')}
        </CustomDialogTitle>
        <DialogContent className={classes.dialogContent}>
          {dataToShow}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(Specification);
