import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DataLimitsUsageTable from './components/DataLimitsUsageTable';
import { actions as reduxActions } from './reducers';

export function DatalimitsUsage({ resetDatalimitsUsage }) {
  useEffect(() => {
    return resetDatalimitsUsage;
  }, [resetDatalimitsUsage]);

  return (
    <div>
      <DataLimitsUsageTable />
    </div>
  );
}

const mapDispatchToProps = {
  resetDatalimitsUsage: reduxActions.resetDatalimitsUsage
};

export default connect(null, mapDispatchToProps)(DatalimitsUsage);
