import { all, call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_RANGE } from './types';
import { updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import mapRangesToUpdate from './mapRangesToUpdate';
import { fetchExternalNoRanges } from '../../../pages/externalNumberRange/actions/getExternalNoRanges';
import { actions as numberRangereduxActions } from '../../../pages/externalNumberRange/reducers';


export function updateOnSubmit({ ranges, formValues, resetValues, isBeTable }) {
  return {
    type: sagaActions[UPDATE_RANGE],
    ranges,
    formValues,
    resetValues,
    isBeTable
  };
}

export function* updateNumberRanges({
  ranges,
  formValues,
  resetValues,
  isBeTable
}) {
  const mappedRanges = mapRangesToUpdate({ ranges, formValues });
  yield put(reduxActions.setIsSaving(true));
  
  yield all(
    mappedRanges.map(range => {
      return call(updateData, {
        url: `${URLS.EXTERNAL_NO_RANGES}/${range.id}`,
        data: {
          data: range
        },
        successSnackbar: {
          intlTextKey: 'MESSAGE.UPDATE.RANGES'
        }
      });
    })
  );

  isBeTable
        ? yield put(numberRangereduxActions.setRefresh(true))
        : yield call(fetchExternalNoRanges, { ...resetValues });

  yield put(reduxActions.setIsSaving(false));
  yield put(reduxActions.closeDialog());
}

function* watchUpdateNumberRange() {
  yield takeLatest(sagaActions[UPDATE_RANGE], updateNumberRanges);
}

export default watchUpdateNumberRange();
