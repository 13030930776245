import React, { useRef } from 'react';
import Editor from '@monaco-editor/react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/formDataEditor';
import { isBoolean, isString } from '../../../utils/isDefined';

function FormDataEditor({
  classes,
  formData,
  label,
  onChange,
  readOnly = true
}) {
  const editorRef = useRef(null);

  const value =
    isString(formData) || isBoolean(formData)
      ? formData
      : JSON.stringify(formData, null, 2);

  const handleEditorDidMount = editor => (editorRef.current = editor);

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <Editor
        height="30vh"
        width="22vw"
        language="json"
        value={value}
        options={{ readOnly }}
        onChange={onChange}
        onMount={handleEditorDidMount}
      />
    </div>
  );
}

export default withStyles(styles)(FormDataEditor);
