import moment from 'moment';

import { formatDateForSchedule } from '../../../utils/formatDate';
import {
  isDefined,
  isStringDefined,
  isStringEmpty,
  isUndefined
} from '../../../utils/isDefined';

export function checkName({ name, group, initialValues }) {
  if (!isStringDefined(name)) {
    return 'EDIT_SCHEDULE.VALIDATION_NAME';
  }

  const allSchedules = group.schedules
    .map(schedule => schedule.name)
    .filter(schedule => schedule !== initialValues.name);

  const isNameExist = allSchedules.find(schedule => schedule === name);

  if (isDefined(isNameExist)) {
    return 'EDIT_SCHEDULE.VALIDATION_NAME';
  }

  return void 0;
}

export function checkForwardNumber(forwardNumber) {
  if (!isStringDefined(forwardNumber)) {
    return 'EDIT_SCHEDULE.VALIDATION_FORWARD_NUMBER_REQUIRED';
  }

  const digits = /^[+0-9][0-9]+$/;

  if (!forwardNumber.match(digits)) {
    return 'EDIT_SCHEDULE.VALIDATION_FORWARD_NUMBER';
  }

  return void 0;
}

export function checkEndDate(reccurenceEndDate, endDate) {
  if (isUndefined(reccurenceEndDate)) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_RECURRENCE_END_REQUIRED',
      errors: { reccurenceEndDate: ' ' }
    };
  }

  const shortReccurenceEndDate = formatDateForSchedule(reccurenceEndDate);
  const shortEndDate = formatDateForSchedule(endDate);

  if (moment(shortReccurenceEndDate).isBefore(shortEndDate)) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_RECURRENCE_END',
      errors: { reccurenceEndDate: ' ', endDate: ' ' }
    };
  }

  return void 0;
}

export function checkTime(startTime, endTime) {
  if (endTime - startTime <= 0) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_ONE_DATE_TIME',
      errors: { startTime: ' ', endTime: ' ' }
    };
  }
  return void 0;
}

export function checkIfTimeIsCorrect(startTime, endTime) {
  if (isStringEmpty(endTime) || isStringEmpty(startTime)) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_TIME',
      errors: { startTime: ' ', endTime: ' ' }
    };
  }
  return void 0;
}

export function checkDates(values, field) {
  const dateRequiredErrors = checkIfDatesAreSet(values, field);

  if (isDefined(dateRequiredErrors)) {
    return {
      value: dateRequiredErrors.value,
      errors: { ...dateRequiredErrors.errors }
    };
  }

  const dateBeforeErrors = checkIfFromIsBeforeToDates(values);

  if (isDefined(dateBeforeErrors)) {
    return {
      value: dateBeforeErrors.value,
      errors: { ...dateBeforeErrors.errors }
    };
  }

  if (values.recurrenceType !== 'NONE') {
    const compareDatesErrors = compareDates(values, field);

    if (isDefined(compareDatesErrors)) {
      return {
        value: compareDatesErrors.value,
        errors: { ...compareDatesErrors.errors }
      };
    }
  }

  return void 0;
}

export function compareDates(values, field) {
  const { endDate, startDate } = values;

  const dateToCompare = moment(startDate).add(1, field[0]);

  if (moment(endDate).isSameOrAfter(dateToCompare)) {
    return {
      value: `EDIT_SCHEDULE.VALIDATION_${field}_DURATION`,
      errors: { startDate: ' ', endDate: ' ' }
    };
  }

  return void 0;
}

export function checkIfDatesAreSet(values, field) {
  const { endDate, startDate } = values;

  if (isUndefined(startDate) || isUndefined(endDate)) {
    return {
      value: `EDIT_SCHEDULE.VALIDATION_${field}_DURATION`,
      errors: { startDate: ' ', endDate: ' ' }
    };
  }
}

export function checkIfFromIsBeforeToDates(values) {
  const { endDate, startDate } = values;
  if (isUndefined(startDate) || isUndefined(endDate)) {
    return void 0;
  }

  if (moment(endDate).isBefore(startDate)) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_DATES',
      errors: { startDate: ' ', endDate: ' ' }
    };
  }

  return void 0;
}

export function checkStartEndDateSet(values) {
  let errorsArray = [];
  let errors = {};

  if (values.startDateSet && isUndefined(values.startDate)) {
    errorsArray = [...errorsArray, 'EDIT_SCHEDULE.VALIDATION_SET_START_DATE'];
    errors = { ...errors, startDate: ' ' };
  }

  if (values.endDateSet && isUndefined(values.endDate)) {
    errorsArray = [...errorsArray, 'EDIT_SCHEDULE.VALIDATION_SET_STOP_DATE'];
    errors = { ...errors, endDate: ' ' };
  }

  const timeError = checkTime(values.startTime, values.endTime);

  if (isDefined(timeError)) {
    errorsArray = [...errorsArray, timeError.value];
    errors = { ...errors, ...timeError.errors };
  }

  return { errors, errorsArray };
}

export function checkReccurenceDays(values) {
  const {
    reccurence_0,
    reccurence_1,
    reccurence_2,
    reccurence_3,
    reccurence_4,
    reccurence_5,
    reccurence_6
  } = values;

  const reccurenceDaysChecked = [
    reccurence_0,
    reccurence_1,
    reccurence_2,
    reccurence_3,
    reccurence_4,
    reccurence_5,
    reccurence_6
  ].filter(el => el === true);

  if (reccurenceDaysChecked.length === 0) {
    return {
      value: 'EDIT_SCHEDULE.VALIDATION_WEEKLY_DAYS',
      errors: {
        reccurence_0: ' ',
        reccurence_1: ' ',
        reccurence_2: ' ',
        reccurence_3: ' ',
        reccurence_4: ' ',
        reccurence_5: ' ',
        reccurence_6: ' '
      }
    };
  }

  return void 0;
}
