import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { OUTSIDE_API, URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_SELECT_OPTIONS } from './types';
import { getData } from '../../../utils/http';
import {
  doesArrayHasLength,
  isArrayEmpty,
  isObject
} from '../../../utils/isDefined';
import getAvailableNumbersOptions from './getAvailableNumbersOptions';
import mapDefaultAddress from './mapDefaultAddress';

export function getAllSelectOptions({ connect30_domain, clientId }) {
  return {
    type: sagaActions[GET_SELECT_OPTIONS],
    connect30_domain,
    clientId
  };
}

export function* getSelectOptions({ connect30_domain, clientId }) {
  let accountsSelectsOptions = [],
    usersSelectOptions = [],
    publicNumbersSelectOptions = [],
    privateNumbersSelectOptions = [],
    account = '';

  try {
    const response = yield call(
      getData,
      `${OUTSIDE_API.CLIENTS}/${connect30_domain}/users`
    );
    usersSelectOptions = yield call(mapUsersForSelect, response);
  } catch {}

  const {
    publicNumbers,
    privateNumbers
  } = yield call(getAvailableNumbersOptions, { clientId, connect30_domain });

  publicNumbersSelectOptions = publicNumbers;
  privateNumbersSelectOptions = privateNumbers;

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/accounts`
    );

    const { firstAccount, mappedAcounts } = yield call(
      createAccountsOptions,
      data
    );
    account = firstAccount;
    accountsSelectsOptions = mappedAcounts;
  } catch {}

  const fourthStep = isObject(account)
    ? mapDefaultAddress({
        account
      })
    : {};

  yield put(reduxActions.setAccount(account, fourthStep));

  yield put(
    reduxActions.setSelectOptions({
      usersSelectOptions,
      publicNumbersSelectOptions,
      privateNumbersSelectOptions,
      accountsSelectsOptions
    })
  );
}

function* watchGetSelectOptions() {
  yield takeLatest(sagaActions[GET_SELECT_OPTIONS], getSelectOptions);
}

export default watchGetSelectOptions();

export function mapUsersForSelect(users = []) {
  if (!doesArrayHasLength(users)) {
    return [{ value: 'No users to copy', label: 'No users to copy' }];
  }

  const selectOptions = users
    .map(item => {
      return {
        value: `${item.name} - ${item.user_id}`,
        label: `${item.name} - ${item.user_id}`
      };
    })
    .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

  return [{ value: '', label: '' }, ...selectOptions];
}

function createAccountsOptions(data = []) {
  let firstAccount = '';

  if (isArrayEmpty(data)) {
    return { firstAccount, mappedAcounts: [] };
  }

  const mappedAcounts = data.map(el => {
    const { name, accountNo } = el;
    return {
      value: `${name} - ${accountNo}`,
      label: `${name} - ${accountNo}`,
      ...el
    };
  });

  firstAccount = mappedAcounts[0];

  return { firstAccount, mappedAcounts };
}
