import { call, put, select } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions, getters } from '../reducers';
import { actions as sagaActions, GET_SCHEDULES } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { isUndefined } from '../../../utils/isDefined';
import mapSchedules from './mapSchedules';

export function getSchedules(connectClientId) {
  return {
    type: sagaActions[GET_SCHEDULES],
    connectClientId
  };
}

export function* fetchSchedules({ connectClientId }) {
  if (isUndefined(connectClientId)) {
    yield put(reduxActions.setSchedules([]));
  } else {
    yield put(loaderReduxActions.startSpinLoader());
    const prevSchedules = yield select(getters.getSchedules);

    let schedules = [];
    let prompts = [];
    try {
      const newSchedules = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules`
      );
      prompts = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/prompts`
      );
      
      schedules = yield call(mapSchedules, prevSchedules, newSchedules);
    } catch {
    } finally {
      yield put(reduxActions.setSchedules(schedules, prompts));
      yield put(loaderReduxActions.stopSpinLoader());
    }
  }
}

function* watchGetTelephonySchedules() {
  yield takeLatest(sagaActions[GET_SCHEDULES], fetchSchedules);
}

export default watchGetTelephonySchedules();
