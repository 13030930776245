const styles = theme => {
  return {
    stats: {
      display: 'flex'
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'center'
    },
    toolbarTitle: {
      overflow: 'visible',
      width: '70%',
      [theme.breakpoints.down('xs')]: {
        width: 'fit-content'
      }
    },
    toolbarSpacer: { flex: 'unset' },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    },
    toolbarHighlight: {
      backgroundColor: '#E5EAEE',
      color: '#03284F'
    }
  };
};

export default styles;
