import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';

import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import styles from '../styles';
import { isArray, isDefined } from '../../../utils/isDefined';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import { CircularProgress } from '@material-ui/core';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import getInvoice from '../actions/getInvoice';
import withSettings from '../../../utils/withSettings';

export function InvoicesActionButtons({ id, classes, clientId, settings }) {
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState({});
 
  useEffect(() => {
    (async () => {
      if (
        can(
          'read',
          createSubject('Quote::Document', {
            client_id: Number(clientId)
          })
        )
      ) {
        setIsLoading(true);
        let docs = {};

        try {
          const response = await getData(
            `${URLS.QUOTE_CLIENTS}/${clientId}/invoices/${id}/documents`
          );
          docs = mapResponse(response);
        } catch {
        } finally {
          setDocs(docs);
          setIsLoading(false);
        }
      }
    })();
  }, [clientId, id]);

  const onDownloadClick = async doc => {
    const { data, fileName } = await getInvoice({
      invoice: doc,
      clientId
    });

    if (isDefined(data) && isDefined(fileName)) {
      saveAs(data, fileName);
    }
  };
  
  return (
    <div className={classes.actionButtons} >
      {isLoading ? (
        <CircularProgress size={33} style={{ color: settings.colors.links }} />
      ) : (
        <>
          {isDefined(docs.pdfDocs) &&
            docs.pdfDocs.map((pdf, i) => (
              <div
                className={classes.documentContainer}
                onClick={() => onDownloadClick(pdf)}
                key={pdf.id}
              >
                <CustomIconButton
                  key={i}
                  onClick={() => onDownloadClick(pdf)}
                  iconClassName="fal fa-file-pdf"
                />
                {pdf.fileName}
              </div>
            ))}
          {isDefined(docs.xmlDocs) &&
            docs.xmlDocs.map((xml, i) => (
              <div
                className={classes.documentContainer}
                onClick={() => onDownloadClick(xml)}
              >
                <CustomIconButton
                  onClick={() => onDownloadClick(xml)}
                  iconClassName="fal fa-file-alt"
                  key={xml.id}
                />
                {xml.fileName}
              </div>
            ))}
        </>
      )}
    </div>
  );
}

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(InvoicesActionButtons);

function mapResponse(response) {
  if (!isArray(response)) {
    return {};
  }

  const pdfDocs = response.filter(el => el.fileName.indexOf('.pdf') !== -1);

  const xmlDocs = response.filter(el => el.fileName.indexOf('.xml') !== -1);

  return { xmlDocs, pdfDocs };
}
