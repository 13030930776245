import React from 'react';
import { DialogActions, withStyles } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/orderESimcard';
import ESimInfo from './ESimInfo';
import { translate } from '../../../i18n/I18nProvider';
import { toAbsoluteUrl } from '../../../../_metronic';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import closeSwapCard from '../actions/closeSwapCard';

const OrderStep = ({
  settings,
  classes,
  handleConfirm,
  closeSwapCard,
  simcard = {},
  isLoading
}) => {
  return (
    <div className={classes.dialogContent}>
      <div className={classes.titleRow}>{translate('SWAP_SIMCARD.ESIM_1')}</div>
      <div className={classes.italicRow}>
        {`${translate('SWAP_SIMCARD.ESIM_2')}`}{' '}
        <ESimInfo>
          <div
            className={classes.link}
            style={{ color: settings.colors.links }}
          >
            {translate('HERE')}
          </div>
        </ESimInfo>
      </div>
      <div className={classes.row}>{translate('SWAP_SIMCARD.ESIM_3')}</div>
      <div className={classes.row}>{translate('SWAP_SIMCARD.ESIM_PRICE')}</div>
      <div className={classes.infoRow}>
        <div>{translate('SWAP_SIMCARD.ESIM_5')}</div>
        <div>
          {translate('SWAP_SIMCARD.ESIM_5.5', {
            label: simcard.isESimCard
              ? translate('SWAP_SIMCARD.NEW_E_SIMCARD').toUpperCase()
              : translate('SWAP_SIMCARD.ORDER_E_SIMCARD').toUpperCase()
          })}
        </div>
      </div>
      <div className={classes.codeRow}>
        <div className={classes.qrCodeColumContainer}>
          <div
            className={classes.codeText}
            style={{ color: settings.colors.modal_header }}
          >
            {translate('SWAP_SIMCARD.ESIM_5.6')}
          </div>
          <img
            src={toAbsoluteUrl(`/media/ESIM_QR_CODE.png`)}
            alt="icon"
            className={classes.sampleIcon}
          ></img>
        </div>
        <div className={classes.dialogContent}>
          <div className={classes.boldRow}>
            {translate('SWAP_SIMCARD.ESIM_6')}
          </div>
          <div className={classes.row}>{translate('SWAP_SIMCARD.ESIM_7')}</div>
        </div>
      </div>

      <DialogActions className={classes.footer}>
        <PrimaryButton onClick={handleConfirm} disabled={isLoading}>
          {simcard.isESimCard
            ? translate('SWAP_SIMCARD.NEW_E_SIMCARD')
            : translate('SWAP_SIMCARD.ORDER_E_SIMCARD')}
        </PrimaryButton>

        <SecondaryButton onClick={closeSwapCard}>
          {translate('BUTTON.CLOSE')}
        </SecondaryButton>
      </DialogActions>
    </div>
  );
};

const mapStatesToProps = ({ swapSimcard, loader }) => {
  return {
    ...swapSimcard,
    isLoading: loader.isLinearVisible
  };
};

const mapDispatchToProps = {
  closeSwapCard
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(OrderStep);
