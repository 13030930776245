import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import Select from 'react-select';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from '../styles';
import Optional from '../../../utils/optional';

export function DetailsSelect({
  field,
  classes,
  form,
  options,
  fieldOptions,
  intl
}) {
  const { setFieldValue } = form;
  const { onBlur, name, value } = field;

  const customStyles = useMemo(() => {
    return {
      option: styles => ({ ...styles, fontSize: '11px' }),
      control: styles => ({
        ...styles,
        width: fieldOptions.width
      })
    };
  }, [fieldOptions.width]);

  const handleChange = useCallback(
    selected => {
      setFieldValue(name, selected);
    },
    // eslint-disable-next-line
    []
  );

  const inputValue = options.find(option => option.value === value);

  return (
    <div className={classes.fieldContainer}>
      <div className={classes.fieldLabel}>
        {Optional(fieldOptions.intlKey)
          .map(key =>
            intl.formatMessage({
              id: key
            })
          )
          .or(fieldOptions.label)}
      </div>
      <Select
        className={classes.fieldValue}
        styles={customStyles}
        isSearchable
        onBlur={onBlur}
        onChange={handleChange}
        value={inputValue}
        name={name}
        options={options}
        maxMenuHeight={140}
      />
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(DetailsSelect);
