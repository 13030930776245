import orderBy from 'lodash.orderby';
import { isArrayEmpty, isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

const OFFICE_MOBILE = 'OFFICE_MOBILE';
const PRIVATE_MOBILE = 'PRIVATE_MOBILE';
const EMPTY_OPTION = { label: '', value: '' };

export default function mapCellNumbers(user) {
  const { sim_cards = [], mitelData = {} } = user;
  const defaultDomainNumber = Optional(mitelData.phone_numbers)
    .map(numbers =>
      Optional(numbers.find(number => number.number_type === OFFICE_MOBILE)).or(
        numbers.find(number => number.number_type === PRIVATE_MOBILE)
      )
    )
    .or(void 0);

  let numbers = [],
    defaultNumbers = [];

  const simCards = sim_cards.map(simcard => {
    let foundedNumber = null;
    if (isDefined(simcard.msisdn)) {
      const datalimitId = findDatalimitId(simcard.cell_numbers)

      if (isDefined(defaultDomainNumber)) {
        foundedNumber =
          defaultDomainNumber.value.replace(/\+/g, '') === simcard.msisdn;

        if (foundedNumber) {
          defaultNumbers = [
            ...defaultNumbers,
            {
              value: simcard.msisdn,
              label: simcard.msisdn,
              datalimitId
            }
          ];
        } else {
          numbers = [
            ...numbers,
            {
              value: simcard.msisdn,
              label: simcard.msisdn,
              datalimitId
            }
          ];
        }
      } else {
        numbers = [
          ...numbers,
          {
            value: simcard.msisdn,
            label: simcard.msisdn,
            datalimitId
          }
        ];
      }
    }

    return { ...simcard };
  });

  const sortedNumbers = [
    ...orderBy(defaultNumbers, 'label'),
    ...orderBy(numbers, 'label')
  ];
  return {
    sim_cards: simCards,
    selectOptions: {
      numbers: sortedNumbers
    },
    selectedNumber: Optional(sortedNumbers[0]).or(EMPTY_OPTION)
  };
}

const findDatalimitId = cellNumbers => {
  if (isArrayEmpty(cellNumbers)) {
    return void 0;
  }

  return Optional(cellNumbers[0].data_limit?.id).or(void 0);
};
