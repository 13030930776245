import React from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import styles from './styles';


export function TableNoPermission({ classes,intl }) {
  return (
    <div className={classes.root}>{intl.formatMessage({
        id: 'TABLE.NO_PERMISSION'
      })}</div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(TableNoPermission);
