/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { setupAxios } from './_metronic';
import store, { persistor } from './app/store/store';
import App from './App';
import * as Sentry from '@sentry/react';
import './index.scss'; // Standard version
import 'react-toastify/dist/ReactToastify.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import { CONST_VALUES, ENV } from './app/consts/initialValues';
import Optional from './app/utils/optional';
import { isObject } from './app/utils/isDefined';
/**
 * Base URL of the website.
 */

const { PUBLIC_URL, REACT_APP_VERSION, NODE_ENV } = process.env;

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn: CONST_VALUES.SENTRY_DSN,
    environment: Optional(ENV.REACT_APP_CUSTOM_NODE_ENV).or(ENV.NODE_ENV),
    release: REACT_APP_VERSION,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
    beforeSend: (event, hint) => {
      if (isObject(hint?.originalException)) {
        const { response } = hint?.originalException;
        if (response?.status === 401) {
          return null;
        } else {
          return event;
        }
      } else {
        return event;
      }
    }
  });
}

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

setupAxios(axios, store);

ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById('root')
);
