import React, { useState, cloneElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { translate } from '../../../i18n/I18nProvider';
import { actions as activationMessageDialogReduxActions } from '../../../partials/activationMessageDialog/reducers';

const MoreActionsMenu = ({
  children,
  openActivationMessageDialog,
  user
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActivationMessageClick = () => {
    openActivationMessageDialog(user);
    handleClose();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onActivationMessageClick}>
          {translate('TOOLTIP.ACTIVATION_MESSAGE')}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openActivationMessageDialog: activationMessageDialogReduxActions.openDialog
};

export default flowRight(
  withUserCheck,
  connect(null, mapDispatchToProps)
)(MoreActionsMenu);
