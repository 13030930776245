import { Field, FieldArray, Formik, Form } from 'formik';
import React, { useCallback, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

import ActionsButtons from './ActionsButtons';
import styles, { customStyles } from '../styles';
import InfoBox from './InfoBox';
import Vacations from './Vacations';
import SelectRow from '../../../partials/dataRows/SelectRow';
import addSchedules from '../actions/addSchedules';
import translateOptionslLabels from '../../../utils/translateOptionsLabels';
import PromptRow from '../../editSchedule/components/PromptRow';
import validateOnSubmit from '../actions/validateForm';
import SELECTS_OPTIONS from '../../../consts/selectsOptions';
import Optional from '../../../utils/optional';
import { EMPTY_SCHEDULE } from '../../../consts/initialValues';
import { actions as reduxActions } from '../reducers';

const PredefinedDays = ({
  classes,
  addSchedules,
  vacations,
  prompts: promptsOptions,
  customErrors,
  schedules,
  connectClientId,
  closeDialog
}) => {
  const onSubmit = useCallback(
    async values => {
      const isValidated = await validateOnSubmit({
        values
      });

      if (isValidated) {
        addSchedules({ values, schedules, connectClientId });
      }
    },
    // eslint-disable-next-line
    [schedules, connectClientId]
  );

  const typeOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_TYPE_OPTIONS);
  }, []);
  return (
    <Formik
      initialValues={{ ...EMPTY_SCHEDULE }}
      enableReinitialize
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        dirty,
        values,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        const fieldProps = {
          values,
          handleBlur,
          handleChange,
          setFieldValue
        };

        const errors = customErrors;

        return (
          <>
            {Object.keys(errors).length > 0 && <InfoBox />}
            <Form className={classes.content} onSubmit={handleSubmit}>
              <>
                <FieldArray
                  name="vacations"
                  render={arrayHelpers => (
                    <Vacations
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </>
              <Field component={PromptRow} promptsOptions={promptsOptions} />

              <div className={classes.typeContainer}>
                <SelectRow
                  name="type"
                  label="SCHEDULE_TYPE"
                  customStyles={customStyles.select.typeSelect}
                  options={typeOptions}
                  {...fieldProps}
                />
                {values.type === 'FORWARD' && (
                  <div>
                    <TextField
                      name="forwardNumber"
                      value={Optional(values.forwardNumber).or('')}
                      margin="dense"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.forwardNumber)}
                      inputProps={{
                        autoComplete: 'off',
                        'data-lpignore': true
                      }}
                    />
                    <div className={classes.errorMessage}>
                      {errors.forwardNumber}
                    </div>
                  </div>
                )}
              </div>
              <footer className={classes.footer}>
                <ActionsButtons
                  onCloseClick={closeDialog}
                  handleSubmit={handleSubmit}
                  dirty={dirty}
                  errors={errors}
                />
              </footer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStatesToProps = ({ addingSchedules, selectClient }) => {
  return {
    ...addingSchedules,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog,
  addSchedules
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(PredefinedDays);
