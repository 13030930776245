import { isString } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export default function mapDataToUpdate(datalimit) {
  const {
    id,
    report_only_to,
    clientName,
    numbers,
    recharge_count,
    status,
    future_limit,
    future_limit_at,
    ...restData
  } = datalimit;
  const restDataKeys = Object.keys(restData);
  const mappedData = restDataKeys.reduce((obj, key) => {
    if (typeof restData[key] === 'boolean') {
      return { ...obj, [key]: restData[key] ? 'true' : 'false' };
    }

    return {
      ...obj,
      [key]: Optional(restData[key])
        .map(data => data.toString())
        .or(restData[key])
    };
  }, {});

  return {
    id: Number(id),
    type: 'data_limits',
    attributes: {
      ...mappedData,
      fair_usage_limit: restData.no_limit
        ? 0
        : mapBlockLimit(mappedData.fair_usage_limit),
      block_limit_mb: restData.no_limit
        ? 0
        : mapBlockLimit(mappedData.block_limit_mb),
      report_only_to: report_only_to ? 'true' : null
    }
  };
}

function mapBlockLimit(limit) {
  return isString(limit) ? limit.replace(',', '.') * 1000 : 0;
}
