import compareIds from '../../../utils/compareIds';
import { isDefined, isStringEmpty } from '../../../utils/isDefined';

export default function mapDataToUpdate(data, initialUserId) {
  const { id, user_id, data_card, description, client_id } = data;
  const isUserIdChanged = !compareIds(user_id, initialUserId);
  const userToDelete =
    isUserIdChanged && isStringEmpty(user_id)
      ? { type: 'users', id: initialUserId, method: 'disassociate' }
      : void 0;

  const mappedData = {
    id,
    type: 'sim_cards',
    attributes: {
      user_id: !isStringEmpty(user_id) ? user_id : null,
      data_card,
      description,
      client_id
    }
  };

  if (isDefined(userToDelete)) {
    delete mappedData.attributes.user_id;
    mappedData.relationships = {
      owner: {
        data: userToDelete
      }
    };
  }

  return mappedData;
}
