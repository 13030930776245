const styles = theme => {
  return {
    actionButtons: {
      display: 'flex',
      '& i': {
        margin: '5px 10px',
        cursor: 'pointer'
      }
    },
    actionCell: { padding: '0 5px', color: '#93A2DD' },
    dialogTextContainer: {
      display: 'flex',
      margin: '20px',
      justifyContent: 'space-around'
    },
    createdBy: {
      display: 'flex',
      flexDirection: 'column'
    },
    createdByValue: {
      display: 'flex',
      alignItems: 'flex-end',
      cursor: 'help'
    },
    headerRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    icon: {
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex'
      }
    },
    title: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '0 20px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    label: {
      display: 'flex',
      fontSize: '12px',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center'
      }
    }
  };
};

export default styles;
