const styles = theme => {
  return {
    root: {
      minWidth: '100px',
      color: theme.palette.primary.dark,
      borderRadius: '5px',
      backgroundColor: theme.palette.success.light,
      '&:hover': { backgroundColor: theme.palette.success.medium }
    }
  };
};

export default styles;
