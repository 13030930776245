import { isDefined, isUndefined } from './isDefined';
import calculatePercentage from './calculatePercentage';
import createDottedNumber from './createDottedNumber';
import calculateMbToGb from './calculateMbToGb';

export default function findCurrentMonthUsage(
  dataLimit,
  calculateStats = false
) {
  const today = new Date();
  const actualYear = today.getFullYear();
  const actualMonth = today.getMonth() + 1;

  const { accumulated_data_usages = [] } = dataLimit;

  const mappedDataUsageArray = accumulated_data_usages.map(data => {
    const [year, month] = data.month.split('-');
    return { ...data, year: Number(year), month: Number(month) };
  });

  let currentMonthUsage = mappedDataUsageArray.find(
    element => element.year === actualYear && element.month === actualMonth
  );

  if (calculateStats && isDefined(currentMonthUsage)) {
    currentMonthUsage = {
      ...calculateMonthUsage(dataLimit, currentMonthUsage)
    };
  }
  return currentMonthUsage;
}

export function calculateMonthUsage(dataLimit, monthUsage) {
  if (isUndefined(monthUsage)) {
    return {
      total_data_string: '-',
      data_roam_others_string: '-',
      totalPercentageString: '-',
      roamPercentageString: '-',
      roamString: '-'
    };
  }
  const roamPercentage = calculatePercentage(
    monthUsage.data_roam_home,
    dataLimit.fair_usage_limit
  );

  const totalData = monthUsage.data + monthUsage.data_roam_home;

  const totalPercentage = calculatePercentage(
    totalData,
    dataLimit.block_limit_mb
  );

  return {
    ...monthUsage,
    total_data_string: calculateMbToGb(totalData, true),
    data_roam_others_string: calculateMbToGb(monthUsage.data_roam_others, true),
    totalPercentageString: createDottedNumber(totalPercentage),
    roamPercentageString: createDottedNumber(roamPercentage),
    roamString: calculateMbToGb(monthUsage.data_roam_home, true),
    roamPercentage,
    totalPercentage
  };
}
