import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_LICENSES } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';

export function getLicenses(connectClientId) {
  return {
    type: sagaActions[GET_LICENSES],
    connectClientId
  };
}

export function* fetchLicenses({ connectClientId }) {
  yield put(loaderReducActions.startSpinLoader());

  const licenses = yield call(
    getData,
    `${OUTSIDE_API.CLIENTS}/${connectClientId}/licenses`
  );

  yield put(reduxActions.setLicenses(licenses));
  yield put(loaderReducActions.stopSpinLoader());
}

function* watchGetLicenses() {
  yield takeLatest(sagaActions[GET_LICENSES], fetchLicenses);
}

export default watchGetLicenses();
