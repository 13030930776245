import React, { useCallback, useEffect, useState } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import ConfirmDialog from '../confirmationDialog/ConfirmDialog';
import LockDialogText from './components/LockDialogText';
import ErrorButton from '../customButtons/ErrorButton';
import SuccessButton from '../customButtons/SuccessButton';
import UnlockDialogText from './components/UnlockDialogText';
import lockNumberRequest from './actions/lockNumber';
import withUserCheck from '../../utils/withUserCheck';
import { isDefined } from '../../utils/isDefined';
import getPartner from './actions/fetchPartner';
import Optional from '../../utils/optional';
import withSettings from '../../utils/withSettings';
import can from '../../utils/can';
import createSubject from '../../utils/createSubject';

const LockNumber = ({
  intl,
  data = {},
  partnerId,
  settings,
  isSuperAdmin,
  fromTable = false
}) => {
  const [isCardActive, setIsCardActive] = useState(false);
  const [websiteURL, setWebsiteUrl] = useState('');

  useEffect(() => {
    if (isSuperAdmin) {
      if (isDefined(partnerId)) {
        (async function fetchSelectedPartner() {
          const data = await getPartner(partnerId);
          setWebsiteUrl(data);
        })();
      }
    } else {
      if (isDefined(partnerId)) {
        setWebsiteUrl(settings.websiteUrl);
      }
    }
    setIsCardActive(data.active);
    // eslint-disable-next-line
  }, [partnerId, isSuperAdmin, settings, data.active]);

  const onDialogConfirmClick = useCallback(
    async ({ isLockDialog }) => {
      const active = await lockNumberRequest(
        data.id,
        isLockDialog,
        isCardActive,
        fromTable
      );

      if (isDefined(active)) {
        return setIsCardActive(active);
      }
    },
    // eslint-disable-next-line
    [data.id, isCardActive]
  );

  return (
    <>
      {isCardActive
        ? can('lock', createSubject('SimCard', data)) && (
            <ConfirmDialog
              dialogTitleIntlId={
                isDefined(data.primary_card_id)
                  ? 'NUMBER.LOCK_NUMBER_PRIMARY'
                  : 'NUMBER.LOCK_NUMBER'
              }
              dialogContentComponent={
                <LockDialogText
                  isDatasharingCard={isDefined(data.primary_card_id)}
                  isPartnerClient={isDefined(partnerId)}
                  websiteURL={websiteURL}
                  network={data.mobile_network?.brand}
                />
              }
              onConfirmClick={() =>
                onDialogConfirmClick({ isLockDialog: true })
              }
              primaryButtonShown={data.mobile_network?.brand === 'TDC'}
              closeButtonIntlId={
                data.mobile_network?.brand !== 'TDC' ? 'BUTTON.CLOSE' : void 0
              }
            >
              <ErrorButton>
                {intl.formatMessage({
                  id: isDefined(data.primary_card_id)
                    ? 'NUMBER.LOCK_NUMBER_PRIMARY'
                    : 'NUMBER.LOCK_NUMBER'
                })}
              </ErrorButton>
            </ConfirmDialog>
          )
        : can('unlock', createSubject('SimCard', data)) && (
            <ConfirmDialog
              dialogTitleIntlId={
                isDefined(data.primary_card_id)
                  ? 'NUMBER.UNLOCK_NUMBER_PRIMARY'
                  : 'NUMBER.UNLOCK_NUMBER'
              }
              dialogContentComponent={
                <UnlockDialogText
                  isPartnerClient={isDefined(partnerId)}
                  websiteURL={websiteURL}
                />
              }
              onConfirmClick={() =>
                onDialogConfirmClick({ isLockDialog: false })
              }
              primaryButtonShown={data.mobile_network?.brand === 'TDC'}
              closeButtonIntlId={
                data.mobile_network?.brand !== 'TDC' ? 'BUTTON.CLOSE' : void 0
              }
            >
              <SuccessButton>
                {intl.formatMessage({
                  id: isDefined(data.primary_card_id)
                    ? 'NUMBER.UNLOCK_NUMBER_PRIMARY'
                    : 'NUMBER.UNLOCK_NUMBER'
                })}
              </SuccessButton>
            </ConfirmDialog>
          )}
    </>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  const { selectedClient } = selectClient;
  return {
    partnerId: Optional(selectedClient.partner)
      .map(partner => partner.id)
      .or(selectedClient.partner_id)
  };
};

export default flowRight(
  injectIntl,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps)
)(LockNumber);
