import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import Charts from './components/Charts';
import { isDefined } from '../../utils/isDefined';
import getDatalimit from './actions/getDatalimit';
import Info from './components/Info';
import Reporting from './components/Reporting';
import { reactSelectDefaultStyles } from '../../consts/reactSelectDefaultStyles';
import styles, { customSelectStyles } from './styles';
import { translate } from '../../i18n/I18nProvider';
import withSettings from '../../utils/withSettings';
import { CUSTOMER_SERVICE_MAIL } from '../../consts/initialValues';
import { actions as showDatalimitReduxActions } from '../../modals/showDatalimit/reducers';
import { connect } from 'react-redux';

const DatalimitStats = ({
  datalimitId,
  selectOptions,
  selectedNumber,
  onNumberChange,
  classes,
  settings,
  update,
  setUpdate
}) => {
  const [data, setData] = useState(void 0);

  useEffect(() => {
    (async function() {
      if (isDefined(datalimitId)) {
        const response = await getDatalimit(datalimitId);

        setData(response);
      }
    })();

    // eslint-disable-next-line
  }, [selectedNumber.value]);

  useEffect(() => {
    (async function() {
      if (update && isDefined(datalimitId)) {
        const response = await getDatalimit(datalimitId);

        setData(response);
        setUpdate(false);
      }
    })();

    // eslint-disable-next-line
  }, [update]);

  const onChange = useCallback(
    selected => {
      if (selected.value !== selectedNumber.value) {
        setData(void 0);
        return onNumberChange(selected);
      }
    },
    [onNumberChange, selectedNumber.value]
  );

  return (
    isDefined(data) && (
      <>
        <div className={classes.selectRow}>
          <div>{translate('SELECT_NUMBER')}</div>
          <Select
            styles={reactSelectDefaultStyles}
            customStyles={customSelectStyles}
            options={selectOptions.numbers}
            value={selectedNumber}
            onChange={onChange}
          />
        </div>
        {data?.showCharts ? (
          <Charts
            data={data.chartData}
            datalimitId={data.id}
            datalimitName={data.name}
          />
        ) : (
          <div className={classes.info}>
            <span>{translate('DATA_LIMITS.NO_USAGE_DATA_INFO_1')}</span>
            <span
              className={classes.appendixLink}
              style={{ color: settings.colors.links }}
              onClick={() => (window.location = CUSTOMER_SERVICE_MAIL)}
            >
              {CUSTOMER_SERVICE_MAIL}
            </span>
            <span>{translate('DATA_LIMITS.NO_USAGE_DATA_INFO_2')}</span>
          </div>
        )}
        <Info data={data} />
        <Reporting data={data} />
      </>
    )
  );
};

const mapStatesToProps = ({ showDatalimit }) => {
  return {
    update: showDatalimit.update
  };
};

const mapDispatchToProps = {
  setUpdate: showDatalimitReduxActions.setUpdate
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DatalimitStats);
