import moment from 'moment';
import { isDefined } from './isDefined';

export default function formatDate(date) {
  return !isNaN(Date.parse(date)) && moment(date).isValid()
    ? moment(date).format('DD-MM-YYYY HH:mm:ss')
    : '';
}

export function formatDateForSchedule(date) {
  return isDefined(date) && moment(date).isValid()
    ? moment(date).format('YYYY-MM-DD')
    : null;
}

export function formatTimeForSchedule(time) {
  return isDefined(time) && moment(time).isValid()
    ? moment(time).format('HH:mm')
    : null;
}

export function formatDateUTC(date) {
  return !isNaN(Date.parse(date)) && moment(date).isValid()
    ? moment(date)
        .tz('UTC')
        .format('DD-MM-YYYY HH:mm:ss')
    : '';
}
