import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions as reduxActions } from './reducers';
import OrdersTable from './components/OrdersTable';

const Orders = ({ resetOrders }) => {
  useEffect(() => {
    return resetOrders;
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <OrdersTable />
    </>
  );
};

const mapDispatchToProps = {
  resetOrders: reduxActions.resetOrders
};

export default connect(null, mapDispatchToProps)(Orders);
