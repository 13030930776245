import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import GroupsTable from './components/GroupsTable';
import { actions as reduxActions } from './reducers';

function Groups({ resetGroups }) {
  useEffect(() => {
    return resetGroups;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GroupsTable />
    </>
  );
}

const mapDispatchToProps = {
  resetGroups: reduxActions.resetGroups
};

export default connect(null, mapDispatchToProps)(Groups);
