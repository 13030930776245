import { mapCellNumbersToDisplay } from '../../../pages/dataLimits/actions/tableColumnsActions';
import { mapCellNumbers } from '../../../pages/users/actions/tableColumnsActions';
import Optional from '../../../utils/optional';

export default function mapData({ csvData = [], name }) {
  switch (name) {
    case 'simCards': {
      return csvData.map(el => {
        return {
          ...el,
          active: el.active ? 'active' : 'inactive',
          owner: Optional(el.owner?.name).or(''),
          primary_card: Optional(el.primary_card?.msisdn).or(''),
          mobile_network: Optional(el.mobile_network?.brand).or(''),
          msisdn: `\t ${el.msisdn}`,
          icc: `\t ${el.icc}`
        };
      });
    }
    case 'numberRanges': {
      return csvData.map(el => {
        return {
          ...el,
          provider: Optional(el.provider)
            .map(provider => Optional(provider.sane_name).or(provider.name))
            .or(''),
          range_from: `\t${el.range_from}`,
          range_up_to_incl: `\t${el.range_up_to_incl}`
        };
      });
    }
    case 'users': {
      return csvData.map(el => {
        return {
          ...el,
          department: Optional(el.department?.name).or(''),
          cellNumbers: mapCellNumbers(el),
          roles: Optional(el.roles)
            .map(roles => roles.map(role => role.name).join(','))
            .or(''),
          is_super_admin: JSON.stringify(el['is_super_admin?']),
          accessToSelfcare: JSON.stringify(!el.locked)
        };
      });
    }
    case 'dataLimits': {
      return csvData.map(el => {
        return {
          ...el,
          name: `\t${el.name}`,
          client: Optional(el.client?.name).or(''),
          cellNumbers: `\t${mapCellNumbersToDisplay(el, false)}`,
          roaming_disabled: JSON.stringify(el.roaming_disabled),
          allow_recharge: JSON.stringify(el.allow_recharge),
          manual_block: JSON.stringify(el.manual_block),
          no_limit: JSON.stringify(el.no_limit),
          tmp_open: JSON.stringify(el.tmp_open),
          update_mdu: JSON.stringify(el.update_mdu)
        };
      });
    }
    default:
      return csvData;
  }
}
