import { createSagaActionTypes } from '../../../utils/actionFactory';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const UPDATE_SCHEDULE_ORDER = 'UPDATE_SCHEDULE_ORDER'

export const actions = createSagaActionTypes('SCHEDULES', [
  GET_SCHEDULES,
  DELETE_SCHEDULE,
  UPDATE_SCHEDULE_ORDER
]);
