import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../consts/tableOptions';
import { getAllRoles } from './actions/getRoles';
import { actions as reduxActions } from './reducers';
import withDetailsView from '../../utils/withDetailsView';
import RolesPermissions from './components/RolesPerimissions';
import CustomTableTitle from '../../partials/CustomTableTitle';
import FilterButton from '../../partials/tableCustomButtons/FilterButton';
import { doesArrayHasLength } from '../../utils/isDefined';
import tableColumns from './components/tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../utils/localstorageUtils';
import EditRole from '../../modals/editRole/EditRole';
import customTableIcons from '../../partials/tableIcons/customTableIcons';
import { setRole } from '../../modals/editRole/actions/setRole';
import AddButton from '../../partials/tableCustomButtons/AddButton';
import RefreshButton from '../../partials/tableCustomButtons/RefreshButton';

export function Roles({
  roles,
  getAllRoles,
  intl,
  resetRoles,
  isLoading,
  columns,
  setColumns,
  openEditRole
}) {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getAllRoles();
    return resetRoles;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refresh) {
      getAllRoles();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Roles-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => [
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
      },
      {
        icon: () => <AddButton buttonText="BUTTON.ADD" />,
        onClick: () => openEditRole({ role: void 0, isEdit: false }),
        isFreeAction: true,
        tooltip: intl.formatMessage({ id: 'TOOLTIP.ADD_ROLE' })
      },
      {
        icon: () => <RefreshButton tableHeader={true} />,
        onClick: () => setRefresh(true),
        isFreeAction: true
      }
    ],
    // eslint-disable-next-line
    [filtering]
  );

  const options = {
    ...mapTableOptions({ exportData: false }),
    selection: false,
    filtering,
    header: doesArrayHasLength(roles)
  };

  const detailPanel = useCallback(({ rowData }) => {
    return <RolesPermissions row={[...rowData.permissions]} />;
  }, []);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Roles-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <div>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.ROLES"
            icon={<i className="fas fa-user-tag"></i>}
            isLoading={isLoading}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={roles}
        options={options}
        detailPanel={[
          {
            tooltip: 'Show permissions',
            render: detailPanel
          }
        ]}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
      />
      <EditRole />
    </div>
  );
}

const mapStatesToProps = ({ roles, loader }) => {
  return {
    ...roles,
    isLoading: loader.isSpinVisible
  };
};

const mapDispatchToProps = {
  getAllRoles,
  resetRoles: reduxActions.resetRoles,
  setColumns: reduxActions.setColumns,
  openEditRole: setRole
};

export default flowRight(
  injectIntl,
  withDetailsView,
  connect(mapStatesToProps, mapDispatchToProps)
)(Roles);
