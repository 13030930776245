const styles = theme => {
  return {
    root: {
      display: 'flex',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      justifyContent: 'space-between'
    },
    column: {
      display: 'inherit',
      [theme.breakpoints.down(theme.breakpoints.values.sm + 200)]: {
        flexDirection: 'column'
      }
    },
    label: {
      fontSize: '14px',
      fontWeight: 500,
      marginBottom: '20px'
    }
  };
};

export default styles;
