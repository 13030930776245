import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { injectIntl } from 'react-intl';

import { isDefined, isUndefined } from '../../../utils/isDefined';
import DataRow from '../../Datarow/DataRow';
import styles, { CUSTOM_STYLES } from '../styles';
import STATUSES from '../../../consts/statusBoxOptions';
import Optional from '../../../utils/optional';
import formatDate from '../../../utils/formatDate';

const MAX_RECHARGE_COUNT = 4;

function CurrentStatus({ intl, classes, datalimit = {} }) {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'CURRENT_STATUS'
          })}
        </div>
      </div>
      <div className={classes.columnContainer}>
        <DataRow
          label={`${intl.formatMessage({
            id: 'STATUS'
          })} `}
          value={intl.formatMessage({
            id: setIntlDatalimitStatus(datalimit.status)
          })}
          customStyles={CUSTOM_STYLES.statusDataRow}
          dense
          mobileRightAllignValue
        />
        {!datalimit.no_limit && datalimit.allow_recharge && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'NUMBER.RECHARGE_COUNT'
            })} `}
            value={`${datalimit.recharge_count}/${MAX_RECHARGE_COUNT}`}
            customStyles={CUSTOM_STYLES.statusDataRow}
            dense
            mobileRightAllignValue
          />
        )}
        {isDefined(datalimit.warned_at) && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'DATA_LIMITS.WARNED'
            })} :`}
            value={formatDate(datalimit.warned_at)}
            customStyles={CUSTOM_STYLES.statusDataRow}
            dense
            mobileRightAllignValue
          />
        )}
        {isDefined(datalimit.recharged_at) && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'DATA_LIMITS.RECHARGED_AT'
            })} :`}
            value={formatDate(datalimit.recharged_at)}
            customStyles={CUSTOM_STYLES.statusDataRow}
            dense
            mobileRightAllignValue
          />
        )}
        {isDefined(datalimit.blocked_at) && (
          <DataRow
            label={`${intl.formatMessage({
              id: 'DATA_LIMITS.BLOCKED'
            })} :`}
            value={formatDate(datalimit.blocked_at)}
            customStyles={CUSTOM_STYLES.statusDataRow}
            dense
            mobileRightAllignValue
          />
        )}
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(CurrentStatus);

function setIntlDatalimitStatus(status) {
  const statusObj = STATUSES.DATA_LIMITS[status];

  if (isUndefined(status) || isUndefined(statusObj)) {
    return 'EMPTY';
  }

  return Optional(statusObj.intlKey).or('EMPTY');
}
