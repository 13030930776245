import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import MainSelector from './MainSelector';
import withSettings from '../../../utils/withSettings';
import styles from '../styles';
import clsx from 'clsx';

export function RangesTableHeader({
  classes,
  intl,
  isLoading,
  settings,
  showSelector
}) {
  return (
    <div
      className={classes.headerRoot}
      style={{ color: settings.colors.primary }}
    >
      <div className={classes.label}>
        <i className={clsx('fas fa-th-list', classes.icon)}></i>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'MENU.EXTERNAL_NUMBER'
          })}
        </div>
        {isLoading ? <CircularProgress /> : null}
      </div>
      {showSelector && <MainSelector />}
    </div>
  );
}

const mapStatesToProps = ({ loader }) => {
  return {
    isLoading: loader.isSpinVisible
  };
};

export default flowRight(
  withSettings,
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(RangesTableHeader);
