import React from 'react';

import CompletedStep from './CompletedStep';
import NewNumberStep from './NewNumberStep';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/core';

import styles from '../styles/views';

const StandardCardView = ({ completed, classes }) => {
  return (
    <div className={classes.root}>
      {!completed ? <NewNumberStep /> : <CompletedStep />}
    </div>
  );
};

const mapStatesToProps = ({ swapSimcard }) => {
  return {
    ...swapSimcard
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(StandardCardView);
