import { Tooltip, withStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/info';
import { translate } from '../../../i18n/I18nProvider';
import withUserCheck from '../../../utils/withUserCheck';
import DataRow from '../../Datarow/DataRow';
import Optional from '../../../utils/optional';
import { isUndefined } from '../../../utils/isDefined';
import { actions as datalimitLogsReduxActions } from '../../../modals/datalimitLogs/reducers';
import clsx from 'clsx';
import withSettings from '../../../utils/withSettings';

const Reporting = ({ classes, data = {}, openDatalimitLogs, settings }) => {
  const customClasses = useMemo(() => {
    return {
      dataRow: {
        root: classes.dataRow_root,
        value: classes.dataRow_value
      }
    };
  }, [classes]);

  const onLogsClick = useCallback(() => {
    openDatalimitLogs(data?.id);
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <div>Reporting</div>
        <Tooltip title={translate('DATA_LIMITS.SHOW_DATALIMIT_LOGS')}>
          <i
            className={clsx('fa fa-history', classes.icon)}
            aria-hidden="true"
            onClick={onLogsClick}
            style={{ color: settings.colors.links }}
          ></i>
        </Tooltip>
      </div>
      <div className={classes.container}>
        <DataRow
          label={translate('DATA_LIMITS.REPORT_TO')}
          value={data.report_only_to ? translate('YES') : translate('NO')}
          customClasses={customClasses.dataRow}
          dense
        />
        {!data.report_only_to && (
          <DataRow
            label={translate('DATA_LIMITS.DATALIMITS_NUMBERS')}
            value={
              <div>
                {Optional(data.cell_numbers)
                  .map(cellNumbers =>
                    cellNumbers.map((el, i) => {
                      if (isUndefined(el.number)) {
                        return null;
                      }
                      return <div key={i}>{el.number}</div>;
                    })
                  )
                  .or('')}
              </div>
            }
            dense
            customClasses={customClasses.dataRow}
          />
        )}
        <DataRow
          label={translate('DATA_LIMITS.REPORT_EMAIL')}
          value={
            <div>
              {Optional(data.report_to_email)
                .map(report =>
                  report.split(',').map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })
                )
                .or('')}
            </div>
          }
          //   value={data.report_to_email}
          customClasses={customClasses.dataRow}
          dense
        />
        <DataRow
          label={translate('DATA_LIMITS.REPORT_SMS')}
          value={
            <div>
              {Optional(data.report_to_sms)
                .map(report =>
                  report.split(',').map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })
                )
                .or('')}
            </div>
          }
          customClasses={customClasses.dataRow}
          dense
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openDatalimitLogs: datalimitLogsReduxActions.openDatalimitLogs
};

export default flowRight(
  withUserCheck,
  withSettings,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(Reporting);
