import watchSetUser from './setUser';
import watchUpdateUser from './updateUser';
import watchSetClientUsers from './changeClientUsersOptions';
import watchSetTelephonyUser from './setTelephonyUser';
import watchUpdateTelephonyUser from './updateTelephonyUser';

const watchEditUser = [
  watchSetUser,
  watchUpdateUser,
  watchSetClientUsers,
  watchSetTelephonyUser,
  watchUpdateTelephonyUser
];

export default watchEditUser;
