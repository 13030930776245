import React from 'react';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import { actions as reduxActions } from './reducers/index';

const styles = {
  root: { height: '3px', width: '100%', position: 'absolute', top: 0, left: 0 },
  loader: { opacity: 0 },
  loading: { opacity: 1 }
};

export function Loader({ loading, classes }) {
  return (
    <div className={classes.root}>
      <LinearProgress
        className={clsx(classes.loader, { [classes.loading]: loading })}
      />
    </div>
  );
}

const mapStatesToProps = ({ loader }) => {
  return { loading: loader.isLinearVisible };
};

const mapDispatchToProps = {
  toggleLoader: reduxActions.toggleLoader
};

export default flowRight(
  withRouter,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Loader);
