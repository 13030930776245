import React from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/dataSections';
import InputRow from '../../../partials/dataRows/InputRow';

export function NumbersData({ classes, form, intl }) {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <InputRow
            label="REQUEST_NEW_USER.LANDLINE"
            name="landline_number"
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.INTERNAL"
            name="internal_number"
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.MOBILE"
            name="mobile_number"
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.SUBSCRIPTION"
            name="subscription"
            {...form}
          />
          <InputRow
            label="ACTIVATION_DATE"
            name="activation_date"
            fieldType="date"
            {...form}
          />
          <InputRow
            label="REQUEST_NEW_USER.SIMCARD"
            name="has_sim_card"
            {...form}
          />
          <div className={classes.info}>
            {intl.formatMessage({
              id: 'REQUEST_NEW_USER.SIMCARD_INFO'
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default flowRight(injectIntl, withStyles(styles))(NumbersData);
