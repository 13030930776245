import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import styles from '../styles/infoBox';

function InfoBox({ intl, classes, customErrors = {} }) {
  const { errorsArray = [] } = customErrors;
  
  return (
    <div className={classes.root}>
      {errorsArray.map(error => {
        return (
          <div key={error} className={classes.container}>
            {intl.formatMessage({
              id: error
            })}
          </div>
        );
      })}
    </div>
  );
}

const mapStatesToProps = ({ editSchedule }) => {
  const { customErrors } = editSchedule;
  return {
    customErrors
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(InfoBox);
