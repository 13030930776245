import { put, call } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, GET_SELECT_OPTIONS } from './types';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { isArrayEmpty } from '../../../utils/isDefined';

export function getAllSelectOptions(clientId) {
  return {
    type: sagaActions[GET_SELECT_OPTIONS],
    clientId
  };
}

export function* getSelectOptions({ clientId }) {
  let accountsSelectsOptions = [];
  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/accounts`
    );

    const { mappedAcounts } = yield call(createAccountsOptions, data);

    accountsSelectsOptions = mappedAcounts;
  } catch {}

  if (accountsSelectsOptions.length === 1) {
    yield put(
      reduxActions.setSimcardData(
        { account: accountsSelectsOptions[0] },
        'firstStep'
      )
    );
  }

  yield put(reduxActions.setAccountOptions(accountsSelectsOptions));
}

function* watchGetSelectOptions() {
  yield takeLatest(sagaActions[GET_SELECT_OPTIONS], getSelectOptions);
}

export default watchGetSelectOptions();

export function createAccountsOptions(data = []) {
  
  if (isArrayEmpty(data)) {
    return { mappedAcounts: [] };
  }

  let mappedAcounts = [
    ...data.map(el => {
      const { name, accountNo } = el;
      return {
        value: `${name} - ${accountNo}`,
        label: `${name} - ${accountNo}`,
        ...el
      };
    })
  ];

  if (mappedAcounts.length > 1) {
    mappedAcounts = [{ value: '', label: '' }, ...mappedAcounts];
  }
  
  return { mappedAcounts };
}
