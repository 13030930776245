import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Switch } from '@material-ui/core';

const styles = {
  root: { opacity: '0.5' },
  switchBase: {
    color: 'green',
    '&$checked': {
      color: 'green'
    },
    '&$checked + $track': {
      backgroundColor: 'green'
    }
  },
  thumb: {
    color: '#D7D0D3'
  },
  checked: {},
  track: {}
};

export function CustomSwitch({ checked, classes, handleChange }) {
  return (
    <Switch
      color="primary"
      checked={checked}
      onChange={handleChange}
      classes={{
        root: classes.root,
        thumb: classes.thumb,
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track
      }}
    />
  );
}

export default withStyles(styles)(CustomSwitch);
