import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { flowRight } from 'lodash';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import checkNumberData from '../../../utils/checkNumberData';
import { isArray } from '../../../utils/isDefined';
import styles from '../styles/pricePlanInfo';
import CustomTitle from './CustomTitle';

function PricePlanInfo({
  classes,
  pricePlan = {},
  intl,
  fromRightPanel = false,
  rightPanelData = {}
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: pricePlan,
      rightPanelData,
      fromRightPanel
    });
  }, [pricePlan, rightPanelData, fromRightPanel]);

  const isIdToLong = isDataExist && data.serviceTypeId.length > 9;

  return (
    <div className={classes.root}>
      <CustomTitle title="NUMBER.PRICE_PLAN" isDataExist={isDataExist}>
        {isDataExist && (
          <div>
            <div className={classes.pricePlan}>
              <Tooltip title={isIdToLong ? data.serviceTypeId : ''}>
                <div className={classes.pricePlanId}>
                  {isIdToLong
                    ? `${data.serviceTypeId.slice(0, 6)}...`
                    : data.serviceTypeId}
                </div>
              </Tooltip>
              <div>{data.serviceDesc}</div>
            </div>
            {data.activationDate && (
              <div className={classes.featureInfos}>{`${intl.formatMessage({
                id: 'ACTIVATION_DATE'
              })} : ${data.activationDate}`}</div>
            )}
            {isArray(data.featureInfos) &&
              data.featureInfos.map((feature, i) => {
                return (
                  <div
                    className={clsx(classes.featureInfos, {
                      [classes.darkerRow]: i % 2 !== 0
                    })}
                    key={i}
                  >
                    <div>{`${feature.featureDesc} (${feature.featureTypeId})`}</div>
                    {feature.serviceFeatureParameterInfos &&
                      feature.serviceFeatureParameterInfos.map((info, i) => {
                        return (
                          <div className={classes.parameter} key={i}>
                            <div className={classes.parameterId}>
                              {info.parameterName}
                            </div>
                            <div>{`: ${info.parameterValue}`}</div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        )}
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    rightPanelData: rightPanel.number?.price_plan,
    pricePlan: checkNumber.price_plan
  };
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps),
  withStyles(styles)
)(PricePlanInfo);
