import React, { useMemo, useEffect, useState } from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import { Paper } from '@material-ui/core';

import styles from '../styles';
import { setLocalization, TABLE_OPTIONS } from '../../../consts/tableOptions';
import tableColumns from './tableColumns';
import { getDataLimitsWithUsage } from '../actions/getDataLimitsWithUsage';
import UsageCsvDownload from './UsageCsvDownload';
import TableTitle from './TableTitle';
import { translate } from '../../../i18n/I18nProvider';

const DatalimitsUsageTable = ({
  classes,
  getDataLimitsWithUsage,
  clientId,
  datalimits = [],
  lastMonthsCount
}) => {
  const [showAll, setShowAll] = useState(false);

  const columns = useMemo(() => {
    return tableColumns({ showAll, lastMonthsCount });
  }, [showAll, lastMonthsCount]);

  useEffect(() => {
    getDataLimitsWithUsage(clientId);
  }, [getDataLimitsWithUsage, clientId]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, []);

  const actions = useMemo(
    () => [
      {
        icon: () => <UsageCsvDownload showAll={showAll} />,
        isFreeAction: true,
        onClick: () => {},
        tooltip: translate('BUTTON.DOWNLOAD')
      }
    ],
    // eslint-disable-next-line
    [showAll]
  );

  return (
    <MaterialTable
      title={<TableTitle showAll={showAll} setShowAll={setShowAll} />}
      components={{
        Container: props => (
          <Paper classes={{ root: classes.paperRoot }} {...props} />
        )
      }}
      columns={columns}
      data={datalimits}
      actions={actions}
      options={{ ...TABLE_OPTIONS.DEFAULT, columnsButton: false }}
      localization={localization}
    />
  );
};

const mapStatesToProps = ({ selectClient, dataLimitsUsage }) => {
  return {
    datalimits: dataLimitsUsage.datalimits,
    clientId: selectClient.selectedClient.id,
    lastMonthsCount: dataLimitsUsage.lastMonthsCount
  };
};

const mapDispatchToProps = {
  getDataLimitsWithUsage
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DatalimitsUsageTable);
